import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Toolbar, Button, CircularProgress } from 'react-md'
import { bindActionCreators, compose } from 'redux'

import * as companyActions from 'state/company-actions'
import * as notifyActions from 'state/notifyActions'

import Uploader from 'ui/components/Uploader'
import { generateRecipientUUID } from './utils'
import { injectIntl } from 'react-intl'
import {
	FETCHING_ELECTRONIC_CONSENT,
	FETCHING_ELECTRONIC_CONSENT_ERROR,
} from './translations'
import WithDialogs from 'ui/components/dialogs/WithDialogs'
import ViiluDialog from 'ui/components/ViiluDialog'

let XLSX, jschardet, iconv
const init = async () => {
	XLSX = await import('xlsx')
	jschardet = await import('jschardet')
	iconv = await import('iconv-lite')
}
init()

const getInitialState = () => ({
	valid: false,
	processing: false,
	users: null,
})

class ImporterDialogFile extends Component {
	state = getInitialState()

	componentDidUpdate(prevProps, prevState) {
		this.onValidation(prevState)
	}

	onValidation = (prevState) => {
		const valid = this.validate(this.state)
		if (valid !== prevState.valid) {
			this.setState({ valid })
		}
	}

	// onValidation is called every setState to validate the inputs
	validate = () => {
		const { users } = this.state
		if (!users) {
			return false
		}
		if (users.length === 0) {
			return false
		}
		return true
	}

	getInvalidFileMessage = () => {
		const { intl } = this.props
		return intl.formatMessage({
			defaultMessage:
				'Näyttäisi siltä, että tiedostossa ei ole yhtään riviä. Tarkistathan, että se on oikeassa muodossa.',
			description:
				'Alert message shown if the file is invalid or empty in the dialog for importing invitees from a file to the meeting invite table.',
		})
	}

	handleFile(file /*:File*/) {
		const { intl, alert } = this.props

		/* Boilerplate to set up FileReader */
		const reader = new FileReader()
		const rABS = !!reader.readAsBinaryString
		reader.onload = async (e) => {
			/* Parse data */
			let encoding
			try {
				const result = jschardet.detect(reader.result)
				if (!result.encoding) {
					throw new Error('Encoding not recognized')
				}
				encoding = result.encoding
			} catch (ex) {
				console.error(ex)
			}

			let wb
			if (encoding) {
				const utf8string = iconv.decode(reader.result, encoding)
				wb = XLSX.read(utf8string, { type: 'string' })
			} else {
				wb = XLSX.read(reader.result, { type: 'binary' })
			}
			/* Get first worksheet */
			const wsname = wb.SheetNames[0]
			const ws = wb.Sheets[wsname]
			/* Convert array of arrays */
			const data = XLSX.utils.sheet_to_json(ws, { header: 1, raw: false })
			console.log(data)
			if (data.length < 1) {
				await alert(this.getInvalidFileMessage())
				return
			} else {
				if (data[0].length < 2) {
					await alert(this.getInvalidFileMessage())
					return
				} else if (data[0][1] === 'Email') {
					data.shift()
				}
			}

			// Fill in empty array slots that the XLSX lib leaves there
			for (let a = 0; a < data.length; a++) {
				for (let b = 0; b < data[a].length; b++) {
					if (data[a][b] === undefined) {
						data[a][b] = undefined
					}
				}
			}

			const filteredData = data
				.filter((row) => row.length >= 2) // name, email
				.filter((row) => row[0] !== undefined && row[0] !== null) // name should be included

			const typeCheckedData = filteredData
				.filter((row) => row.length >= 2) // name, email
				.filter((row) => typeof row[0] === 'string') // name is a string
				.filter(
					(row) =>
						row[1] === undefined ||
						row[1] === null ||
						typeof row[1] === 'string',
				) // email is a string
				.filter(
					(row) =>
						row[2] === undefined ||
						row[2] === null ||
						typeof row[2] === 'string',
				) // identifiers is a string
				.filter(
					(row) =>
						row[3] === undefined ||
						row[3] === null ||
						typeof row[3] === 'string',
				) // phone number is a string
				.filter(
					(row) =>
						row[4] === undefined ||
						row[4] === null ||
						typeof row[4] === 'string',
				) // address is a string
				.filter(
					(row) =>
						row[5] === undefined ||
						row[5] === null ||
						typeof row[5] === 'string' ||
						typeof row[5] === 'number',
				) // zip is a string or number
				.filter(
					(row) =>
						row[6] === undefined ||
						row[6] === null ||
						typeof row[6] === 'string',
				) // area is a string
				.filter(
					(row) =>
						row[7] === undefined ||
						row[7] === null ||
						typeof row[7] === 'string',
				) // country is a string

			if (typeCheckedData.length === 0) {
				await alert(
					intl.formatMessage({
						defaultMessage:
							'Tiedostossa ei näyttäisi olevan yhtään osallistujaa tai sarakkeet eivät ole oikeassa muodossa.',
						description:
							'Error message shown in the dialog for importing invitees from a file if the file is empty or the columns are in invalid format.',
					}),
				)
				return false
			}

			let invalidCountryCodes = false
			typeCheckedData.forEach((row) => {
				if (row[7] && row[7].toString().length !== 2) {
					invalidCountryCodes = true
				}
			})

			if (invalidCountryCodes) {
				await alert(
					intl.formatMessage({
						defaultMessage:
							'Tiedostossa on maakoodeja, jotka ovat virheellisessä muodossa. Maakoodien tulisi olla kaksi merkkiä pitkiä. Esim. "FI".',
						description:
							'Error message shown in the dialog for importing invitees from a file if the file contains invalid country codes.',
					}),
				)
				return false
			}

			let invalidPhoneNumbers = false
			typeCheckedData.forEach((row) => {
				if (
					row[3] &&
					(row[3].toString().length < 6 || !row[3].toString().includes('+358'))
				) {
					invalidPhoneNumbers = true
				}
			})

			if (invalidPhoneNumbers) {
				await alert(
					intl.formatMessage({
						defaultMessage:
							'Tiedostossa on puhelinnumeroita, jotka ovat virheellisessä muodossa. Numeron tulisi sisältää maakoodi +358.',
						description:
							'Error message shown in the dialog for importing invitees from a file if the file contains invalid phone numbers.',
					}),
				)
				return false
			}

			const reconstructedData = typeCheckedData.map((row) => ({
				displayName: row[0] ? row[0].trim() : row[0],
				email: row[1] ? row[1].trim() : row[1],
				apartments: row[2]
					? row[2]
							.toString()
							.replace(/ /g, '')
							.toUpperCase()
							.split(',')
							.map((identifier) => ({ identifier }))
					: [],
				phoneNumber: row[3]
					? row[3].toString().trim().toLowerCase().replace(/\s+/g, '')
					: row[3],
				address: row[4] ? row[4].toString().trim() : row[4],
				zip: row[5] ? sanitizeZip(row[5]) : row[5],
				area: row[6] ? row[6].toString().trim().toUpperCase() : row[6],
				country: row[7] ? row[7].toString().trim().toUpperCase() : row[7],
			}))

			/* Update state */
			this.setState({ users: reconstructedData })
		}

		if (!file.name.includes('.xlsx') && file.name.includes('.xls')) {
			alert(
				'Tämä tiedosto näyttäisi olevan vanhassa XLS muodossa. Muunna se ensin XLSX tiedostoksi ja yritä sitten uudelleen. Voit tehdä tämän esimerkiksi Excelistä tallentamalla uudempaan .xlsx tiedostoformaattiin.',
			)
			return false
		}

		if (rABS) reader.readAsBinaryString(file)
		else reader.readAsArrayBuffer(file)
	}

	// onFilesChanged will get called with the accepted files or null in case there were rejections
	onFilesChanged = (files) => {
		try {
			this.handleFile(files[0])
		} catch (ex) {
			console.error(ex)
		}
	}

	onHide = () => {
		this.setState(getInitialState())
		this.props.onHide()
	}

	getUsersFile = async () => {
		const {
			company,
			onSuccess,
			_getUserDigitalDeliveryConsentByEmailBatch,
			_showInfoNotification,
			intl,
			alert,
		} = this.props
		const { users } = this.state
		if (!users) {
			await alert(this.getInvalidFileMessage())
			return false
		}

		let transformedUsers = users.map((user) => ({
			...user,
			uuid: generateRecipientUUID(),
			digitalDeliveryConsents: {},
		}))

		_showInfoNotification(FETCHING_ELECTRONIC_CONSENT(intl))

		this.setState({ processing: true })
		const result = await _getUserDigitalDeliveryConsentByEmailBatch(
			company.uuid,
			transformedUsers.map((u) => u.email),
		)
		this.setState({ processing: false })

		if (result && result.data && Array.isArray(result.data)) {
			transformedUsers = transformedUsers.map((user, index) => {
				return {
					...user,
					...result.data[index],
				}
			})
		} else {
			_showInfoNotification(FETCHING_ELECTRONIC_CONSENT_ERROR(intl))
		}

		onSuccess(transformedUsers)
		this.onHide()
	}

	renderToolbar = (dialogTitle) => {
		return (
			<Toolbar
				colored
				title={dialogTitle}
				style={{
					background: 'var(--color-secondary-dark)',
					position: 'fixed',
					width: '100%',
					zIndex: 2,
				}}
				nav={
					<Button icon onClick={this.onHide}>
						close
					</Button>
				}
			/>
		)
	}

	renderHelp() {
		return (
			<div className="margin-top margin-bottom" style={{ overflow: 'auto' }}>
				<table>
					<tbody>
						<tr>
							<td
								style={{
									padding: 4,
									verticalAlign: 'text-top',
									border: '1px solid black',
									background: 'rgba(0,0,0,0.1)',
								}}
							>
								<p>Nimi</p>
							</td>
							<td
								style={{
									padding: 4,
									verticalAlign: 'text-top',
									border: '1px solid black',
									background: 'rgba(0,0,0,0.1)',
								}}
							>
								<p>Email</p>
							</td>
							<td
								style={{
									padding: 4,
									verticalAlign: 'text-top',
									border: '1px solid black',
									background: 'rgba(0,0,0,0.1)',
								}}
							>
								<p>Huoneistojen tunnukset</p>
							</td>
							<td
								style={{
									padding: 4,
									verticalAlign: 'text-top',
									border: '1px solid black',
									background: 'rgba(0,0,0,0.1)',
								}}
							>
								<p>Puhelinnumero</p>
							</td>
							<td
								style={{
									padding: 4,
									verticalAlign: 'text-top',
									border: '1px solid black',
									background: 'rgba(0,0,0,0.1)',
								}}
							>
								<p>Katuosoite</p>
							</td>
							<td
								style={{
									padding: 4,
									verticalAlign: 'text-top',
									border: '1px solid black',
									background: 'rgba(0,0,0,0.1)',
								}}
							>
								<p>Postinumero</p>
							</td>
							<td
								style={{
									padding: 4,
									verticalAlign: 'text-top',
									border: '1px solid black',
									background: 'rgba(0,0,0,0.1)',
								}}
							>
								<p>Postitoimipaikka</p>
							</td>
							<td
								style={{
									padding: 4,
									verticalAlign: 'text-top',
									border: '1px solid black',
									background: 'rgba(0,0,0,0.1)',
								}}
							>
								<p>Maakoodi</p>
							</td>
						</tr>
						<tr>
							<td
								style={{
									padding: 4,
									verticalAlign: 'text-top',
									border: '1px solid black',
									background: 'rgba(0,0,0,0.02)',
								}}
							>
								<p>Matti Meikäläinen</p>
							</td>
							<td
								style={{
									padding: 4,
									verticalAlign: 'text-top',
									border: '1px solid black',
									background: 'rgba(0,0,0,0.02)',
								}}
							>
								<p>matti@example.com</p>
							</td>
							<td
								style={{
									padding: 4,
									verticalAlign: 'text-top',
									border: '1px solid black',
									background: 'rgba(0,0,0,0.02)',
								}}
							>
								<p>A1, AP1</p>
							</td>
							<td
								style={{
									padding: 4,
									verticalAlign: 'text-top',
									border: '1px solid black',
									background: 'rgba(0,0,0,0.02)',
								}}
							>
								<p>+358504087333</p>
							</td>
							<td
								style={{
									padding: 4,
									verticalAlign: 'text-top',
									border: '1px solid black',
									background: 'rgba(0,0,0,0.02)',
								}}
							>
								<p>Kumputie 1</p>
							</td>
							<td
								style={{
									padding: 4,
									verticalAlign: 'text-top',
									border: '1px solid black',
									background: 'rgba(0,0,0,0.02)',
								}}
							>
								<p>00100</p>
							</td>
							<td
								style={{
									padding: 4,
									verticalAlign: 'text-top',
									border: '1px solid black',
									background: 'rgba(0,0,0,0.02)',
								}}
							>
								<p>Helsinki</p>
							</td>
							<td
								style={{
									padding: 4,
									verticalAlign: 'text-top',
									border: '1px solid black',
									background: 'rgba(0,0,0,0.02)',
								}}
							>
								<p>FI</p>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		)
	}

	render() {
		const { users, valid, processing } = this.state
		const { intl } = this.props

		const insertCount = users ? users.length : 0

		const dialogTitle = intl.formatMessage({
			defaultMessage: 'Tuo tiedostolla',
			description:
				'Title of the dialog for importing invitees from a file to the meeting invite table.',
		})

		return (
			<ViiluDialog
				id="responsive-dialog"
				aria-label={dialogTitle}
				visible={true}
				onHide={this.onHide}
				focusOnMount={true}
				containFocus={true}
				dialogClassName="responsive-dialog"
				contentClassName="responsive-dialog-content"
				autosizeContent={false}
				disableScrollLocking
				modal
				paddedContent
				style={{ zIndex: 5000 }}
			>
				{this.renderToolbar(dialogTitle)}
				<section className="md-toolbar-relative" style={{ padding: 24 }}>
					<p>
						{intl.formatMessage({
							defaultMessage:
								'Voit valita vastaanottajia tuomalla Excel tai CSV-tiedoston, jossa on sarakkeet Nimi, Email, Huoneistojen tunnukset.',
							description:
								'Instructions paragraph in the dialog for importing invitees from a file to the meeting invite table.',
						})}
					</p>
					<p className="margin-top">
						{intl.formatMessage({
							defaultMessage:
								'Mikäli haluat käyttää Viilun postituspalvelua lisääthän myös edellisten sarakkeiden perään sarakkeet Katuosoite, Postinumero, Postitoimipaikka, Maakoodi. Maakoodi on standardimuodossa "FI", "SE" jne.',
							description:
								'Instructions paragraph in the dialog for importing invitees from a file to the meeting invite table.',
						})}
					</p>
					<p className="margin-top">
						{intl.formatMessage({
							defaultMessage:
								'Mikäli haluat lähettää tekstiviestejä Viilun kautta lisääthän myös edellisten sarakkeiden perään sarakkeen Puhelinnumero. Huomioi, että numeroiden on sisällettävä maatunnus "+358".',
							description:
								'Instructions paragraph in the dialog for importing invitees from a file to the meeting invite table.',
						})}
					</p>
					<br />
					{this.renderHelp()}
					<Uploader onFilesChanged={this.onFilesChanged} />
					{!users || users.length === 0 ? null : (
						<p>
							{intl.formatMessage(
								{
									defaultMessage:
										'Olet tuomassa {insertCount} osallitujan tiedot.',
									description:
										'Message telling the count of the invitees being imported in the dialog for importing invitees from a file to the meeting invite table.',
								},
								{ insertCount },
							)}
						</p>
					)}
					<div
						className="flex-row margin-top"
						style={{ justifyContent: 'flex-end' }}
					>
						{processing ? (
							<Button raised secondary disabled>
								<CircularProgress id="progress" style={{ marginTop: -4 }} />
							</Button>
						) : (
							<Button
								raised
								secondary
								onClick={this.getUsersFile}
								disabled={!valid}
							>
								{intl.formatMessage({
									defaultMessage: 'Tuo tiedostolla',
									description:
										'Label for the button that imports the invitees from a file to the meeting invite table.',
								})}
							</Button>
						)}
					</div>
				</section>
			</ViiluDialog>
		)
	}
}

// Function to pad zip code with leading zeroes
// to always return zip with a proper length of 5.
// Should not have to do this, but the excel importer
// doesn't always behave as documented (returns number type instead of string as it should)
const sanitizeZip = (input) => {
	const value = input.toString().trim()
	if (value.length === 1) {
		return '0000' + value
	} else if (value.length === 2) {
		return '000' + value
	} else if (value.length === 3) {
		return '00' + value
	} else if (value.length === 4) {
		return '0' + value
	} else if (value.length === 5) {
		return value
	}
	// return a falsy value so it will be treated as invalid
	return ''
}

ImporterDialogFile.propTypes = {
	onHide: PropTypes.func.isRequired,
	company: PropTypes.object.isRequired,
}

const mapState = () => ({})

const mapDispatchToProps = (dispatch) => ({
	_showInfoNotification: bindActionCreators(notifyActions.info, dispatch),
	_getUserDigitalDeliveryConsentByEmailBatch: bindActionCreators(
		companyActions._getUserDigitalDeliveryConsentByEmailBatch,
		dispatch,
	),
})

export default compose(
	injectIntl,
	WithDialogs,
	connect(mapState, mapDispatchToProps),
)(ImporterDialogFile)
