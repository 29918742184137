import type { CreateLoanInput } from '../types'

export const createLoanInputs: CreateLoanInput[] = [
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Lainan numero',
				description: 'Credit ID',
			}),
		key: 'creditId',
		show: true,
		type: 'text',
		rules: (intl) => ({
			required: intl.formatMessage({
				defaultMessage: 'Lainan numero vaaditaan.',
				description: 'Error message for required credit ID',
			}),
		}),
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Lainatyyppi',
				description: 'Loan type',
			}),
		key: 'loanType',
		show: true,
		type: 'text',
		rules: (intl) => ({
			required: intl.formatMessage({
				defaultMessage: 'Lainatyyppi vaaditaan.',
				description: 'Error message for required loan type',
			}),
		}),
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Lainanantaja',
				description: 'Loan granted by',
			}),
		key: 'loanGrantedBy',
		show: true,
		type: 'text',
		rules: (intl) => ({
			required: intl.formatMessage({
				defaultMessage: 'Lainanantaja vaaditaan.',
				description: 'Error message for required loan granted by',
			}),
		}),
	},

	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Jäljellä oleva pääoma',
				description: 'Debt left to pay',
			}),
		key: 'debtLeftToPay',
		show: true,
		type: 'number',
		fullWidth: true,
		rules: (intl) => ({
			required: intl.formatMessage({
				defaultMessage: 'Jäljellä oleva pääoma vaaditaan.',
				description: 'Error message for required debt left to pay',
			}),
		}),
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Lainan alkupäivä',
				description: 'Loan start date',
			}),
		key: 'loanStartDate',
		show: true,
		type: 'date',
		rules: (intl) => ({
			required: intl.formatMessage({
				defaultMessage: 'Lainan alkupäivä vaaditaan.',
				description: 'Error message for required loan start date',
			}),
		}),
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Lainan loppupäivä',
				description: 'Loan end date',
			}),
		key: 'loanEndDate',
		show: true,
		type: 'date',
		rules: (intl) => ({
			required: intl.formatMessage({
				defaultMessage: 'Lainan loppupäivä vaaditaan.',
				description: 'Error message for required loan end date',
			}),
		}),
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Laina-aika',
				description: 'Loan time',
			}),
		key: 'loanTime',
		show: true,
		type: 'text',
		rules: (intl) => ({
			required: intl.formatMessage({
				defaultMessage: 'Laina-aika vaaditaan.',
				description: 'Error message for required loan time',
			}),
		}),
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: '1. nostopäivämäärä',
				description: 'First loan draw date',
			}),
		key: 'loanFirstDrawDate',
		show: true,
		type: 'date',
		rules: (intl) => ({
			required: intl.formatMessage({
				defaultMessage: 'Ensimmäinen nostopäivämäärä vaaditaan.',
				description: 'Error message for required first loan draw date',
			}),
		}),
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Lyhennystiedot',
				description: 'Amortization sub-heading',
			}),
		key: 'sub-heading',
		show: true,
		type: 'text',
		rules: (intl) => ({}),
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: '1. lyhennyspäivä',
				description: 'First loan amortization date',
			}),
		key: 'amortizationFirstDueDate',
		show: true,
		type: 'date',
		rules: (intl) => ({
			required: intl.formatMessage({
				defaultMessage: 'Ensimmäinen lyhennyspäivä vaaditaan.',
				description:
					'Error message for required first loan amortization due date',
			}),
		}),
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Lyhennyserä',
				description: 'Loan amortization amount',
			}),
		key: 'amortizationAmount',
		show: true,
		type: 'number',
		rules: (intl) => ({
			required: intl.formatMessage({
				defaultMessage: 'Lyhennyserä vaaditaan.',
				description: 'Error message for required loan amortization amount',
			}),
		}),
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Lyhennyspäivät',
				description: 'Amortization dates',
			}),
		key: 'amortizationDates',
		show: true,
		type: 'day-month',
		rules: (intl) => ({
			required: intl.formatMessage({
				defaultMessage: 'Lyhennyspäivät vaaditaan.',
				description: 'Error message for required amortization dates',
			}),
		}),
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Korko',
				description: 'Interest sub-heading',
			}),
		key: 'sub-heading',
		show: true,
		type: 'text',
		rules: (intl) => ({}),
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Korkosidos',
				description: 'Interest type',
			}),
		key: 'interestType',
		show: true,
		type: 'text',
		rules: (intl) => ({
			required: intl.formatMessage({
				defaultMessage: 'Korkosidos vaaditaan.',
				description: 'Error message for required interest type',
			}),
		}),
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Korko muuttunut viimeksi',
				description: 'Interest last changed',
			}),
		key: 'interestLastUpdated',
		show: true,
		type: 'date',
		rules: (intl) => ({
			required: intl.formatMessage({
				defaultMessage: 'Koron viimeisin muutospäivä vaaditaan.',
				description: 'Error message for required interest last updated field',
			}),
		}),
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Korko (%)',
				description: 'Interest percentage',
			}),
		key: 'interestPercentage',
		show: true,
		fullWidth: true,
		type: 'number',
		rules: (intl) => ({
			required: intl.formatMessage({
				defaultMessage: 'Korkoprosentti vaaditaan.',
				description: 'Error message for required interest percentage',
			}),
			min: {
				value: 0,
				message: intl.formatMessage({
					defaultMessage: 'Korkoprosentin on oltava vähintään 0%.',
					description: 'Error message for too small interest percentage',
				}),
			},
			max: {
				value: 100,
				message: intl.formatMessage({
					defaultMessage: 'Korkoprosentti voi olla enintään 100%.',
					description: 'Error message for too big interest percentage',
				}),
			},
		}),
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Marginaali (%)',
				description: 'Marginal percentage',
			}),
		key: 'marginalPercentage',
		show: true,
		type: 'number',
		rules: (intl) => ({
			required: intl.formatMessage({
				defaultMessage: 'Marginaali vaaditaan.',
				description: 'Error message for required marginal percentage',
			}),
			min: {
				value: 0,
				message: intl.formatMessage({
					defaultMessage: 'Marginaalin on oltava vähintään 0%.',
					description: 'Error message for too small marginal percentage',
				}),
			},
			max: {
				value: 100,
				message: intl.formatMessage({
					defaultMessage: 'Marginaali voi olla enintään 100%.',
					description: 'Error message for too big marginal percentage',
				}),
			},
		}),
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: '1. koronmaksupäivä',
				description: 'Interest last changed',
			}),
		key: 'interestFirstDueDate',
		show: true,
		type: 'date',
		rules: (intl) => ({
			required: intl.formatMessage({
				defaultMessage: '1. koronmaksupäivä vaaditaan.',
				description: 'Error message for required interest due date field',
			}),
		}),
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Koron tarkastus päivämäärät',
				description: 'Interest inspection dates',
			}),
		key: 'interestInspectionDates',
		show: true,
		type: 'day-month',
		rules: (intl) => ({
			required: intl.formatMessage({
				defaultMessage: 'Koron tarkastuspäivämäärät vaaditaan.',
				description: 'Error message for required interest inspection dates',
			}),
		}),
	},
]
