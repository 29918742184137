import { useDispatch } from 'react-redux'
import { DialogContainer, Toolbar, Button, Checkbox } from 'react-md'
import LoadingState from 'ui/components/LoadingState'
import { generateRecipientUUID } from './utils'
import { GENERIC_ERROR_NOTIFICATION } from 'ui/messages'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import useDialogs from 'ui/components/dialogs/useDialogs'
import { error } from 'state/notifyActions'
import {
	_getHausviseCompanyShareholders,
	_getHausviseCompanyTenants,
	ShareholdersDeliveryConsentsType,
} from 'state/hausvise-actions'

const STEPS = {
	INTRO: 'intro',
	CONSENT_UTILIZATION: 'consent_utilization',
	IMPORT_CONFIRM: 'import_confirm',
}

type ImporterDialogHausviseProps = {
	selectedCompany: any
	onSuccess?: (invitees: any) => any
	onHide?: () => any
}

const ImporterDialogHausvise = ({
	selectedCompany,
	onSuccess,
	onHide,
}: ImporterDialogHausviseProps) => {
	const [isProcessing, setIsProcessing] = useState(false)
	const [step, setStep] = useState(STEPS.INTRO)
	const [fetchShareholders, setFetchShareholders] = useState(true)
	const [fetchTenants, setFetchTenants] = useState(false)
	const [deliveryConsenstType, setDeliveryConsentsType] = useState<
		ShareholdersDeliveryConsentsType | undefined
	>(undefined)

	const resetState = () => {
		setIsProcessing(false)
		setStep(STEPS.INTRO)
		setFetchShareholders(false)
	}

	const intl = useIntl()
	const { alert } = useDialogs()
	const dispatch = useDispatch()

	const missingManagerIntegrationMsg = intl.formatMessage({
		defaultMessage:
			'Tämä toiminto vaatii isännöintitoimiston integraation Hausviseen. Ota tarvittaessa yhteys asiakaspalveluumme asiakaspalvelu@vii.lu.',
		description:
			'Message shown in the dialog for importing from Hausvise to the message service table if the Hausvise integration is not enabled.',
	})

	const missingCompanyIntegrationMsg = intl.formatMessage({
		defaultMessage:
			'Tämä toiminto vaatii kohteen integraation Hausviseen. Ota tarvittaessa yhteys asiakaspalveluumme asiakaspalvelu@vii.lu.',
		description:
			'Message shown in the dialog for importing from Hausvise to the message service table if the Hausvise integration is not enabled.',
	})

	const showErrorNotification = () => {
		dispatch(error('❌ ' + GENERIC_ERROR_NOTIFICATION(intl)))
	}

	const hausviseImport = async () => {
		const managerUUID = selectedCompany.managerUUID
		if (!managerUUID) {
			await alert(missingManagerIntegrationMsg)
			return false
		}

		const hvIntegration = selectedCompany.integrations?.hausvise
		const hvEnabled = hvIntegration
			? hvIntegration[managerUUID] &&
			  hvIntegration[managerUUID].hausviseCompanyId
			: false

		if (!hvEnabled) {
			await alert(missingCompanyIntegrationMsg)
			return false
		}

		setIsProcessing(true)

		let shareholderResults
		if (fetchShareholders) {
			shareholderResults = await _getHausviseCompanyShareholders(
				selectedCompany.uuid,
				managerUUID,
				deliveryConsenstType,
			)
		}

		let tenantResults
		if (fetchTenants) {
			tenantResults = await _getHausviseCompanyTenants(
				selectedCompany.uuid,
				managerUUID,
			)
		}

		setIsProcessing(false)

		if (
			fetchShareholders &&
			(!shareholderResults || !shareholderResults.data)
		) {
			showErrorNotification()
			return false
		}

		if (fetchTenants && (!tenantResults || !tenantResults.data)) {
			showErrorNotification()
			return false
		}

		const combined: any[] = []
		const shareholderEmails = new Set()

		if (fetchShareholders) {
			const shareholders = shareholderResults.data
			shareholders.forEach((sh) => {
				const email = sh.email
				// if email exists, we check the user uniqueness with that
				if (typeof email === 'string' && email !== '') {
					shareholderEmails.add(email)
				}
				combined.push(sh)
			})
		}

		if (fetchTenants) {
			const tenants = tenantResults.data
			tenants.forEach((t) => {
				if (shareholderEmails.has(t.email)) {
					// Some users could be both a tenant and shareholder. In that case, prioritize shareholders
					return
				}
				combined.push(t)
			})
		}

		const invitees = combined.map((user) => ({
			...user,
			uuid: generateRecipientUUID(),
		}))
		if (onSuccess) {
			onSuccess(invitees)
		}
		handleOnHide()
		return true
	}

	const handleOnHide = () => {
		resetState()
		if (onHide) {
			onHide()
		}
	}

	const renderToolbar = (dialogTitle: string) => {
		return (
			<Toolbar
				colored
				title={dialogTitle}
				style={{
					background: 'var(--color-secondary-dark)',
					position: 'fixed',
					width: '100%',
					zIndex: 2,
				}}
				nav={
					<Button icon onClick={handleOnHide}>
						close
					</Button>
				}
			/>
		)
	}

	const renderStep = () => {
		if (step === STEPS.INTRO) {
			return (
				<>
					<div className="flex-column">
						<p>
							{intl.formatMessage({
								defaultMessage:
									'Voit tuoda osakastiedot suoraan Hausvisesta mikäli Hausvise-rajapinta on otettu käyttöön. Tiedot haetaan Hausvise-yhtiötunnuksen perusteella.',
								description:
									'Instructional paragraph in the dialog for importing from Hausvise to the message service table.',
							})}
						</p>
						<Checkbox
							id="hausvise-imported-tenant"
							label={<p style={{ margin: 0 }}>Asukkaat</p>}
							name="Asukkaat"
							checked={fetchTenants}
							onChange={() => {}}
							onClick={() => setFetchTenants(!fetchTenants)}
							style={{ marginTop: 32 }}
						/>
						<Checkbox
							id="hausvise-imported-shareholder"
							label={<p style={{ margin: 0 }}>Osakkaat</p>}
							name="Osakkaat"
							checked={fetchShareholders}
							onChange={() => {}}
							onClick={() => setFetchShareholders(!fetchShareholders)}
						/>
						<div
							className="flex-row margin-top"
							style={{ justifyContent: 'flex-end' }}
						>
							<Button
								raised
								secondary
								onClick={() => {
									if (fetchShareholders) {
										setStep(STEPS.CONSENT_UTILIZATION)
									} else {
										setStep(STEPS.IMPORT_CONFIRM)
									}
								}}
								disabled={!(fetchShareholders || fetchTenants)}
							>
								{intl.formatMessage({
									defaultMessage: 'Seuraava',
									description:
										'Label for the button that proceeds to the next step in the dialog for importing from Hausvise to the message service table.',
								})}
							</Button>
						</div>
					</div>
				</>
			)
		} else if (step === STEPS.CONSENT_UTILIZATION) {
			return (
				<>
					<div className="flex-column">
						<p>
							{intl.formatMessage({
								defaultMessage: 'Haetaanko tietoja yhtiökutsua varten?',
								description:
									'Paragraph asking the user, if the user information being fetched is being used for a housing company meeting or not',
							})}
						</p>
						<div
							className="flex-row margin-top"
							style={{ justifyContent: 'flex-end' }}
						>
							<Button
								raised
								primary
								onClick={() => {
									setDeliveryConsentsType('general')
									setStep(STEPS.IMPORT_CONFIRM)
								}}
							>
								{intl.formatMessage({
									defaultMessage: 'Ei',
									description:
										'Decline button label in the dialog for importing from Hausvise to the message service table.',
								})}
							</Button>
							<Button
								className="margin-left"
								raised
								primary
								onClick={() => {
									setDeliveryConsentsType('company-meeting')
									setStep(STEPS.IMPORT_CONFIRM)
								}}
							>
								{intl.formatMessage({
									defaultMessage: 'Kyllä',
									description:
										'Accept button label in the dialog for importing from Hausvise to the message service table.',
								})}
							</Button>
						</div>
					</div>
				</>
			)
		} else {
			return (
				<>
					<div className="flex-column">
						<p>
							{intl.formatMessage({
								defaultMessage:
									'Tiedot haetaan Hausvise-yhtiötunnuksen perusteella painettuasi TUO TIEDOT HAUSVISESTA -painiketta.',
								description:
									'Instructional paragraph in the dialog for importing from Hausvise to the message service table.',
							})}
						</p>
						<div
							className="flex-row margin-top"
							style={{ justifyContent: 'flex-end' }}
						>
							<Button raised secondary onClick={hausviseImport}>
								{intl.formatMessage({
									defaultMessage: 'Tuo tiedot Hausvisesta',
									description:
										'Label for the button that imports from Hausvise to the message service table.',
								})}
							</Button>
						</div>
					</div>
				</>
			)
		}
	}

	const dialogTitle = intl.formatMessage({
		defaultMessage: 'Tuo Hausvisen henkilörekisteristä',
		description:
			'Title of the dialog for importing invitees from Hausvise to the message service table.',
	})

	return (
		<DialogContainer
			id="responsive-dialog"
			aria-label={dialogTitle}
			visible={true}
			onHide={handleOnHide}
			focusOnMount={true}
			containFocus={true}
			dialogClassName="responsive-dialog"
			contentClassName="responsive-dialog-content"
			autosizeContent={false}
			disableScrollLocking
			modal
			portal
			paddedContent
			onClick={(e) => {
				e.preventDefault()
				e.stopPropagation()
			}}
			style={{ zIndex: 5000 }}
		>
			{renderToolbar(dialogTitle)}
			<section className="md-toolbar-relative" style={{ padding: 24 }}>
				{isProcessing ? (
					<LoadingState />
				) : selectedCompany.managerUUID ? (
					renderStep()
				) : (
					<p>{missingManagerIntegrationMsg}</p>
				)}
			</section>
		</DialogContainer>
	)
}

export default ImporterDialogHausvise
