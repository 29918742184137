import type { Company } from 'types/company'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import Filters from './Filters'
import usePaymentProducts from './usePaymentProducts'
import PaymentProductsTable from './PaymentProductsTable'
import FloatingActionBtn from 'ui/components/FloatingActionBtn'
import { push } from 'connected-react-router'
import styled from 'styled-components'
import PaymentProductCreateDialog from './PaymentProductCreateDialog'
import LoadingState from 'ui/components/LoadingState'
import PaymentProductEditDialog from './PaymentProductEditDialog'
import PaymentProductImportDialog from './PaymentProductImportDialog'
import type { PaymentProduct, PaymentProductsFilters } from './types'
import useValueAddedTaxes from '../ValueAddedTax/useValueAddedTaxes'
import { createVatOptions } from '../utils/vatOptions'

const Container = styled.div`
	padding: 16px;
`

type Props = {
	selectedManagerCompanies: Company[]
}

function PaymentProducts({ selectedManagerCompanies }: Props) {
	const intl = useIntl()
	const dispatch = useDispatch()
	const selectedFinanceCompanyUUID = useSelector(
		({ app }: any) => app?.selectedFinanceCompanyUUID,
	)
	const [filters, setFilters] = useState<PaymentProductsFilters>({
		separateBilling: null,
		vatUUID: null,
		date: new Date(),
		searchText: null,
	})

	const { valueAddedTaxes } = useValueAddedTaxes()
	const vatOptions = createVatOptions(valueAddedTaxes || [])

	const paymentProducts = usePaymentProducts(
		selectedFinanceCompanyUUID,
		filters,
	)
	const queryParamUUID = useSelector(
		(state: any) => state.router?.location?.query?.uuid,
	)
	const [createDialog, setCreateDialog] = useState<{
		visible: boolean
		initialData?: PaymentProduct
	}>({
		visible: false,
	})
	const [importDialog, setImportDialog] = useState(false)

	const renderTable = () => {
		if (paymentProducts.loading) {
			return <LoadingState />
		}

		if (!paymentProducts.data.length) {
			return (
				<p>
					{intl.formatMessage({
						defaultMessage: 'Maksulajeja ei löytynyt.',
						description: 'Message for empty finance payment products list.',
					})}
				</p>
			)
		}

		return (
			<PaymentProductsTable
				paymentProducts={paymentProducts.data}
				refresh={paymentProducts.refresh}
				onCopyPaymentProduct={(paymentProduct: PaymentProduct) => {
					setCreateDialog({ visible: true, initialData: paymentProduct })
				}}
				vatOptions={vatOptions}
			/>
		)
	}

	return (
		<Container>
			<Filters
				filters={filters}
				setFilters={setFilters}
				vatOptions={vatOptions}
			/>

			{renderTable()}

			<FloatingActionBtn
				iconName={'add'}
				text={intl.formatMessage({
					defaultMessage: 'Uusi maksulaji',
					description: 'Label for the create a finance payment product button.',
				})}
				action={() => {
					setCreateDialog({ visible: true })
				}}
				style={{
					bottom: 72,
				}}
			/>

			<FloatingActionBtn
				iconName={'import_export'}
				text={intl.formatMessage({
					defaultMessage: 'Tuo maksulajeja',
					description: 'Label for the import payment products.',
				})}
				action={() => {
					setImportDialog(true)
				}}
			/>

			{createDialog.visible && (
				<PaymentProductCreateDialog
					companyUUID={selectedFinanceCompanyUUID}
					initialData={createDialog.initialData}
					onHide={() => setCreateDialog({ visible: false })}
					refreshPaymentProducts={paymentProducts.refresh}
					paymentProducts={paymentProducts.data}
					vatOptions={vatOptions}
				/>
			)}

			{importDialog && (
				<PaymentProductImportDialog
					selectedFinanceCompanyUUID={selectedFinanceCompanyUUID}
					selectedManagerCompanies={selectedManagerCompanies}
					refreshPaymentProducts={paymentProducts.refresh}
					onHide={() => {
						setImportDialog(false)
					}}
					vatOptions={vatOptions}
				/>
			)}

			{queryParamUUID && (
				<PaymentProductEditDialog
					paymentProductUUID={queryParamUUID}
					onHide={() => {
						dispatch(push('/manager/finance/payment_products'))
					}}
					refreshPaymentProducts={paymentProducts.refresh}
					paymentProducts={paymentProducts.data}
					vatOptions={vatOptions}
				/>
			)}
		</Container>
	)
}

export default PaymentProducts
