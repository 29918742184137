import { Button, TextField } from 'react-md'
import RootDialog from './RootDialog'
import { useState } from 'react'

function PromptDialog({ dialog, resolveDialog }) {
	const [prompt, setPrompt] = useState(dialog.defaultValue || '')

	const handleSubmit = (event) => {
		event?.preventDefault()
		resolveDialog(dialog.key, prompt)
	}

	return (
		<RootDialog
			dialog={dialog}
			focusOnMount
			actions={[
				<Button flat onClick={() => resolveDialog(dialog.key, null)}>
					{dialog.cancelLabel}
				</Button>,
				<Button raised primary onClick={handleSubmit}>
					{dialog.confirmLabel}
				</Button>,
			]}
		>
			<p>{dialog.message}</p>
			<form onSubmit={handleSubmit}>
				<TextField
					id={`root-dialog-prompt-input-${dialog.key}`}
					label={dialog.inputLabel}
					placeholder={dialog.inputPlaceholder}
					value={prompt}
					onFocus={(event) => event.target.select()}
					onChange={(value) => setPrompt(value)}
					rows={dialog.inputMultiline ? 3 : undefined}
					inputStyle={{ fontFamily: 'Lato' }}
				/>
			</form>
		</RootDialog>
	)
}

export default PromptDialog
