import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { firestoreConnect, isEmpty } from 'react-redux-firebase'

import * as notifyActions from 'state/notifyActions'

import { Button, TextField } from 'react-md'
import { FormattedMessage, injectIntl } from 'react-intl'

import illustrationMail from 'svg/illustrations/illustration_mail_address.svg'
import {
	ONBOARDING_NEXT_MESSAGE,
	ONBOARDING_VALIDATION_ERROR,
} from './messages'
import ReactFlagsSelect from 'react-flags-select'

const ALPHANUMERIC = new RegExp('^[a-zA-Z0-9]+$')
const ONLY_DIGITS = new RegExp('^[0-9]+$')
const LENGTH_LIMIT = 100

export class UserOnboardingStepAddress extends Component {
	state = {
		// needed to avoid reassigning values from the user object
		// when erasing input fields (because the default value ('') is falsy)
		valuesAssignedFromUser: false,
	}

	validSpaces = (str) => {
		const initialSpace = str[0] === ' '
		const twoConsecutiveSpaces = str.length > 1 ? / {2}/.test(str) : false
		return !initialSpace && !twoConsecutiveSpaces
	}

	componentDidMount = () => {
		const { onboardingReducer } = this.props
		const { country } = onboardingReducer
		const { onUpdate } = this.props
		// Select finland by default on mount
		if (!country) {
			onUpdate({ country: 'FI' })
		}
	}

	componentDidUpdate = () => {
		const { onboardingReducer, user } = this.props
		const { valuesAssignedFromUser } = this.state
		const { address, zip, area, country } = onboardingReducer
		const { onUpdate } = this.props
		const update = {}
		// existing user = editing profile (as opposed to creating a new one)
		if (user && !valuesAssignedFromUser) {
			// only assign from user once (otherwise erasing the inputs will lead to reassignment)
			this.setState({ valuesAssignedFromUser: true })
			if (!address) {
				update.address = user.address || ''
			}
			if (!zip) {
				update.zip = user.zip || ''
			}
			if (!area) {
				update.area = user.area || ''
			}
			// the user's country supercedes the default value
			if (!country || (user.country && user.country !== country)) {
				update.country = user.country
			}
		}
		if (!isEmpty(update)) {
			onUpdate(update)
		}
	}

	componentWillUnmount = () => {
		const { onUpdate, onboardingReducer } = this.props
		const { address, area } = onboardingReducer

		// we need to trim here in order to be able to type spaces when altering the values
		onUpdate({ address: address.trim(), area: area.trim() })
	}

	onAddressChange = (address) => {
		if (!this.validSpaces(address)) return
		if (address.length > LENGTH_LIMIT) return
		const { onUpdate } = this.props
		onUpdate({ address })
	}

	onZipChange = (zip) => {
		const { onUpdate, onboardingReducer } = this.props
		const { country } = onboardingReducer
		const matchRegExp = country === 'FI' ? ONLY_DIGITS : ALPHANUMERIC
		const maxLength = country === 'FI' ? 5 : 10
		if (!zip.match(matchRegExp) && zip) {
			return
		}
		if (zip?.length > maxLength) {
			return
		}
		onUpdate({ zip })
	}

	onAreaChange = (area) => {
		if (!this.validSpaces(area)) return
		if (area.length > LENGTH_LIMIT) return
		const { onUpdate } = this.props
		onUpdate({ area })
	}

	onCountryChange = (country) => {
		const { onUpdate } = this.props
		const update = { country }
		if (country === 'FI') {
			// empty it as a precaution (different restrictions for a Finnish zip code)
			update.zip = ''
		}
		onUpdate(update)
	}

	validate = () => {
		const { onboardingReducer } = this.props
		const { address, zip, area, country } = onboardingReducer
		if (address === '') {
			return false
		}
		if (zip === '') {
			return false
		}
		if (area === '') {
			return false
		}
		if (country === '') {
			return false
		}
		return true
	}

	next = async () => {
		const { onNext, _showInfoNotification, intl } = this.props
		if (!this.validate()) {
			_showInfoNotification(ONBOARDING_VALIDATION_ERROR(intl))
			return
		}
		onNext()
	}

	render = () => {
		const { onboardingReducer, intl, isDesktop } = this.props
		const { address, zip, area, country } = onboardingReducer

		return (
			<div
				className="flex-column flex-center"
				style={{ height: 'calc(100% - 56px)', justifyContent: 'flex-start' }}
			>
				{isDesktop ? (
					<img
						alt=""
						src={illustrationMail}
						style={{
							width: 200,
							height: 'auto',
							position: 'relative',
							margin: 16,
						}}
					/>
				) : null}
				<h4 className="text-center margin-bottom">
					<FormattedMessage
						defaultMessage="Postiosoitteesi"
						description="Header of the home address section of the user profile update dialog."
					/>
				</h4>

				<div style={{ width: 288, marginTop: 16 }}>
					<p
						style={{
							marginTop: 0,
							color: 'rgba(0,0,0,0.6)',
							fontSize: 12,
						}}
					>
						{intl.formatMessage({
							defaultMessage: 'Maa *',
							description: 'Label of the country select field.',
						})}
					</p>
					<ReactFlagsSelect
						selected={country}
						onSelect={this.onCountryChange}
						selectButtonClassName={'country-select-button'}
						searchable
						placeholder={intl.formatMessage({
							defaultMessage: 'Valitse maa',
							description: 'Placeholder of the country select input.',
						})}
						searchPlaceholder={intl.formatMessage({
							defaultMessage: 'Hae',
							description: 'Placeholder of the country select search bar.',
						})}
					/>
				</div>

				<TextField
					id="floating-center-title-user-zip"
					label={intl.formatMessage({
						defaultMessage: 'Postinumero',
						description: 'Label of the zip code field.',
					})}
					lineDirection="center"
					value={zip}
					onChange={this.onZipChange}
					required={true}
					type="text"
					fullWidth={false}
					style={{ width: 288 }}
					autoComplete="off"
				/>

				<TextField
					id="floating-center-title-user-area"
					label={intl.formatMessage({
						defaultMessage: 'Postitoimipaikka',
						description: 'Label of the postal area field.',
					})}
					lineDirection="center"
					value={area}
					onChange={this.onAreaChange}
					required={true}
					type="text"
					fullWidth={false}
					style={{ width: 288 }}
					autoComplete="off"
				/>

				<TextField
					id="floating-center-title-user-address"
					label={intl.formatMessage({
						defaultMessage: 'Lähiosoite',
						description: 'Label of the home address field.',
					})}
					placeholder={intl.formatMessage({
						defaultMessage: 'Esim. Pähkinätie 8',
						description: 'Placeholder value of the home address field.',
					})}
					lineDirection="center"
					value={address}
					onChange={this.onAddressChange}
					required={true}
					fullWidth={false}
					style={{ width: 288 }}
					autoComplete="off"
				/>

				<Button
					raised
					secondary
					onClick={this.next}
					disabled={!this.validate()}
					style={{ position: 'absolute', right: 24, bottom: 24 }}
				>
					{ONBOARDING_NEXT_MESSAGE(intl)}
				</Button>
			</div>
		)
	}
}

UserOnboardingStepAddress.propTypes = {
	onUpdate: PropTypes.func.isRequired,
	onNext: PropTypes.func.isRequired,
}

const mapState = ({
	firebaseReducer: { auth },
	firestoreReducer: {
		ordered: { onboarding_user },
	},
	ui: { onboarding: onboardingReducer },
	dimensions: { isDesktop },
}) => ({
	user: onboarding_user ? onboarding_user[0] : null,
	onboardingReducer,
	isDesktop,
	auth,
})

const mapDispatchToProps = (dispatch) => ({
	_showInfoNotification: bindActionCreators(notifyActions.info, dispatch),
})

export default compose(
	injectIntl,
	connect(mapState, mapDispatchToProps),
	firestoreConnect(({ auth, user }) => {
		return auth?.uid && !user
			? [
					{
						collection: 'user',
						storeAs: 'onboarding_user',
						where: [['uuid', '==', auth.uid]],
					},
			  ]
			: []
	}),
)(UserOnboardingStepAddress)
