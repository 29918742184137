import { useIntl } from 'react-intl'
import styled from 'styled-components'
import { YES_NO_MESSAGE } from 'ui/messages'
import { formatCurrency, formatDate } from 'util/formatStrings'
import type { ConsolidatedInvoice } from './types'
import type { ApartmentSelectOptions } from '../types'
import { consolidatedInvoiceDetailsFields } from './constants'
import { invoicingMethodMessage } from '../utils/messages'
import { ActiveInvoiceStatus, VoidedInvoiceStatus } from '../enum'

const Table = styled.table`
	border-collapse: collapse;
	border: 1px solid rgba(0, 0, 0, 0.2);

	td {
		padding: 4px 8px;
		white-space: nowrap;
		border-bottom: 1px solid rgba(0, 0, 0, 0.2);
	}

	td:nth-child(2) {
		text-align: right;
	}
`

type Props = {
	invoices: ConsolidatedInvoice[]
	apartmentOptions: ApartmentSelectOptions[]
}

const ConsolidatedInvoiceDetailsTable = ({
	invoices,
	apartmentOptions,
}: Props) => {
	const intl = useIntl()
	const invoice = invoices[0]

	const filteredInvoices = invoices.filter((i) =>
		ActiveInvoiceStatus.includes(i.status as any),
	)

	const filteredVoidedInvoices = invoices.filter(
		(i) => !VoidedInvoiceStatus.includes(i.status as any),
	)

	const getRowValue = (key: string, type: string) => {
		const value = invoice[key]
		switch (type) {
			case 'currency':
				const total = filteredVoidedInvoices.reduce(
					(acc, inv) => acc + inv[key],
					0,
				)
				return formatCurrency(total)
			case 'unpaid':
				const billedAmount = filteredInvoices.reduce(
					(acc, inv) => acc + inv.totalWithVat,
					0,
				)
				const paidAmount = filteredInvoices.reduce(
					(acc, inv) => acc + inv.paymentsTotal,
					0,
				)
				return paidAmount - billedAmount
			case 'boolean':
				return YES_NO_MESSAGE(intl, value)
			case 'dueDates':
				return filteredVoidedInvoices
					.map((inv) => formatDate(inv[key] || '-'))
					.join(', ')
			case 'custom':
				return invoicingMethodMessage(intl, value)
			case 'apartment':
				return (
					apartmentOptions.find((a) => a.identifier === value)?.label ||
					value ||
					'-'
				)
			default:
				return value || '-'
		}
	}
	return (
		<Table>
			<tbody>
				{consolidatedInvoiceDetailsFields.map((field) => {
					const { key, type, title } = field
					let value = getRowValue(key, type)
					let color
					if (type === 'unpaid') {
						if (value < 0) {
							color = 'red'
						}
						if (value > 0) {
							color = 'green'
						}
						value = formatCurrency(value)
					}
					return (
						<tr key={key}>
							<td style={{ color, whiteSpace: 'normal' }}>{title(intl)}</td>
							<td style={{ color, whiteSpace: 'normal' }}>{value}</td>
						</tr>
					)
				})}
			</tbody>
		</Table>
	)
}

export default ConsolidatedInvoiceDetailsTable
