import styled from 'styled-components'
import useLoans from './useLoans'
import LoadingState from 'ui/components/LoadingState'
import { useIntl } from 'react-intl'
import LoansTable from './LoansTable'
import LoanViewDialog from './LoanViewDialog/LoanViewDialog'
import { useSelector } from 'react-redux'
import LoanShareGroupDialog from './LoanShareGroupViewDialog/LoanShareGroupViewDialog'
import { useMemo, useState } from 'react'
import LoanCreateDialog from './LoanCreateDialog/LoanCreateDialog'
import FloatingActionBtn from 'ui/components/FloatingActionBtn'
import WithSelectedManagerCompanies from 'ui/components/WithSelectedManagerCompanies'
import type { Manager } from 'types/manager'
import type { SelectOptions } from '../types'
import type { Loan, LoanFilters } from './types'
import Filters from './Filters'

const Container = styled.div`
	padding: 16px;
`

const LoadingContainer = styled.div`
	margin-top: 64px;
`

const NotFound = styled.p`
	margin-top: 16px;
`

const ITEMS_PER_PAGE = 15

type LoansProps = {
	selectedManagerCompanies: Manager[]
}

function Loans({ selectedManagerCompanies }: LoansProps) {
	const intl = useIntl()
	const [createDialogVisible, setCreateDialogVisible] = useState(false)
	const [selectedDraftLoan, setSelectedDraftLoan] = useState<Loan | undefined>(
		undefined,
	)
	const selectedFinanceCompanyUUID = useSelector(
		({ app }: any) => app?.selectedFinanceCompanyUUID,
	)
	const [filters, setFilters] = useState<LoanFilters>({
		paginationPage: 0,
		paginationPerPage: ITEMS_PER_PAGE,
		companyUUID: selectedFinanceCompanyUUID,
		status: undefined,
		sortBy: {
			column: 'name',
			order: 'asc',
		},
	})
	const location = useSelector((state: any) => state.router?.location)
	const { loansResult, loadingLoans, refreshLoans } = useLoans(filters)
	const params = new URLSearchParams(location.search)
	const loanUUID = params.get('luuid')
	const shareGroupUUID = params.get('sguuid')

	const companyOptions = useMemo(() => {
		let options: SelectOptions[] = []
		if (selectedManagerCompanies && selectedManagerCompanies.length > 0) {
			options = selectedManagerCompanies.map((manager: Manager) => {
				return {
					label: manager.name,
					value: manager.uuid,
				}
			})
		}
		return options
	}, [selectedManagerCompanies])

	const onDraftLoanClick = (loan: Loan) => {
		setSelectedDraftLoan(loan)
		setCreateDialogVisible(true)
	}

	const onLoanCreateDialogHide = () => {
		setCreateDialogVisible(false)
		setSelectedDraftLoan(undefined)
	}

	const renderTable = () => {
		if (loadingLoans) {
			return (
				<LoadingContainer>
					<LoadingState />
				</LoadingContainer>
			)
		}

		if (!loansResult?.loans.length) {
			return (
				<NotFound>
					{intl.formatMessage({
						defaultMessage: 'Lainarivejä ei löytynyt.',
						description: 'Message for empty loan rows',
					})}
				</NotFound>
			)
		}

		return (
			<>
				<LoansTable
					loans={loansResult.loans}
					totalItemCount={loansResult.totalItemCount}
					itemsPerPage={ITEMS_PER_PAGE}
					filters={filters}
					setFilters={setFilters}
					onDraftLoanClick={onDraftLoanClick}
				/>
			</>
		)
	}
	return (
		<>
			<Container>
				<Filters filters={filters} setFilters={setFilters} />
				{renderTable()}
			</Container>
			<FloatingActionBtn
				iconName={'add'}
				text={intl.formatMessage({
					defaultMessage: 'Luo uusi laina',
					description: 'Floating action button label for creating new loan',
				})}
				action={() => setCreateDialogVisible(true)}
			/>
			{loanUUID && <LoanViewDialog loanUUID={loanUUID} />}
			{loanUUID && shareGroupUUID && (
				<LoanShareGroupDialog shareGroupUUID={shareGroupUUID} />
			)}
			{createDialogVisible && (
				<LoanCreateDialog
					onHide={onLoanCreateDialogHide}
					companyOptions={companyOptions}
					refreshLoans={refreshLoans}
					loan={selectedDraftLoan}
				/>
			)}
		</>
	)
}

export default WithSelectedManagerCompanies({ hideLoading: false })(Loans)
