import { TableContainer } from 'ui/StyledComponents/Tables'
import { useIntl } from 'react-intl'
import {
	TableRow,
	TableColumn,
	DataTable,
	TableHeader,
	TableBody,
	FontIcon,
} from 'react-md'
import OverflowGradient from 'ui/components/OverflowGradient'
import type { AccountsLedger, AccountsLedgerFilters } from './types'
import { accountsLedgerHeaders } from './constants'
import AccountsLedgerRow from './AccountsLedgerRow'
import { useBuildings } from 'ui/CompanyBuildings/hooks'
import { useSelector } from 'react-redux'
import useCompanyApartments from 'util/hooks/useCompanyApartments'
import { useState } from 'react'
import naturalSort from 'javascript-natural-sort'
import { AccountsLedgerGroupBy } from '../enum'

type Props = {
	accountsLedger: AccountsLedger[]
	filters: AccountsLedgerFilters
}

const AccountsLedgersTable = ({ accountsLedger, filters }: Props) => {
	const intl = useIntl()

	const [sort, setSort] = useState<{ key: string; order: string }>({
		key: 'name',
		order: 'asc',
	})

	const changeSort = (key: string) => {
		if (sort.key === key) {
			setSort((prevData) => ({
				...prevData,
				order: sort.order === 'asc' ? 'desc' : 'asc',
			}))
		} else {
			setSort({
				key: key,
				order: 'asc',
			})
		}
	}

	const selectedFinanceCompanyUUID = useSelector(
		({ app }: any) => app?.selectedFinanceCompanyUUID,
	)

	const { buildings } = useBuildings(selectedFinanceCompanyUUID)
	const { companyApartments: apartments } = useCompanyApartments(
		selectedFinanceCompanyUUID,
	)

	const renderTableHeaders = () => {
		return (
			<TableRow>
				{accountsLedgerHeaders
					.filter((h) => {
						if (!h.show) return false
						if (
							filters.groupBy === AccountsLedgerGroupBy.APARTMENT &&
							h.key === 'identifier'
						) {
							return false
						}
						return true
					})
					.map((header) => (
						<TableColumn
							key={header.key}
							onClick={() => header.sortable && changeSort(header.key)}
						>
							<div
								style={{ display: 'flex', alignItems: 'center', gap: '4px' }}
							>
								<p className="text-strong text-subtle">{header.title(intl)}</p>
								{sort.key === header.key && (
									<FontIcon style={{ fontSize: 12 }}>
										{sort.order === 'asc' ? 'arrow_upward' : 'arrow_downward'}
									</FontIcon>
								)}
							</div>
						</TableColumn>
					))}
				<TableColumn key={'children-header'}></TableColumn>
			</TableRow>
		)
	}

	return (
		<TableContainer>
			{accountsLedger ? (
				<TableContainer>
					<DataTable
						plain
						style={{
							background: 'white',
							maxHeight: 'calc(100vh - 205px)',
						}}
					>
						<TableHeader
							style={{
								position: 'sticky',
								top: 0,
								zIndex: 1,
								background: 'white',
							}}
						>
							{renderTableHeaders()}
						</TableHeader>
						<TableBody>
							{accountsLedger
								.sort((a, b) => {
									if (sort.order === 'asc') {
										return naturalSort(a[sort.key], b[sort.key])
									} else {
										return naturalSort(b[sort.key], a[sort.key])
									}
								})
								.map((accountLedger) => (
									<AccountsLedgerRow
										key={accountLedger.uuid}
										accountLedger={accountLedger}
										apartments={apartments}
										buildings={buildings}
										menuItems={[]}
										children={null}
										processing={false}
										filters={filters}
									/>
								))
								.concat(
									<tr
										key="empty-row"
										className="full-width"
										style={{ height: 100, background: 'white' }}
									></tr>,
								)}
						</TableBody>
						<OverflowGradient style={{ bottom: 40 }} />
					</DataTable>
				</TableContainer>
			) : null}
		</TableContainer>
	)
}

export default AccountsLedgersTable
