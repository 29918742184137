import { TextField } from 'react-md'
import styled from 'styled-components'
import Uploader from 'ui/components/Uploader'
import WYSIWYGEditor from 'ui/components/WYSIWYGEditor'
import Actions from '../../Actions'
import { StepContainer } from '../styled'
import * as messageServiceActions from 'state/message-service-actions'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import LoadingState from 'ui/components/LoadingState'
import { useState } from 'react'
import useDialogs from 'ui/components/dialogs/useDialogs'
import { useIntl } from 'react-intl'

const Content = styled.div`
	max-width: 600px;
	margin: 0 auto;
`

const Banner = styled.div`
	background: #e19e19;
	border-radius: 8px;
	padding: 8px;
	margin: 8px 0;
	color: #fff;
`

const Label = styled.p`
	font-size: 13px;
	color: rgba(0, 0, 0, 0.65);
	margin: 32px 0 8px 0;
`

const Attachments = styled.div`
	display: flex;
	flex-wrap: wrap;
	opacity: 0.6;
`

const Attachment = styled.div`
	border: 2px dashed #666;
	padding: 8px 32px;
	border-radius: 8px;
	background-color: white;
	text-align: center;
	margin: 4px;
`

function CreateMessage({
	isMassSending,
	messageServiceProcess,
	setMessageServiceProcess,
	onPreviousClick,
	onDraftClick,
	onNextClick,
	_uploadFile,
}) {
	const intl = useIntl()
	const { alert } = useDialogs()
	const [isUploading, setIsUploading] = useState(false)
	const isGeneratorUsed =
		messageServiceProcess.generator &&
		messageServiceProcess.generator !== '' &&
		messageServiceProcess.generator !== 'default'

	const handleInputChange = (input, value) => {
		setMessageServiceProcess((prev) => ({
			...prev,
			[input]: value,
		}))
	}

	const handleFilesChanged = async (files) => {
		if (!files || files.length === 0) {
			handleInputChange('attachments', [])
			return
		}

		// Filter removed files
		const filtered = messageServiceProcess.attachments.filter((a) =>
			files.some((f) => a.filename === f.name),
		)

		const filesToUpload = files.filter(
			(file) =>
				!messageServiceProcess.attachments.some(
					(a) => a.filename === file.name,
				),
		)

		if (filesToUpload.length === 0) {
			handleInputChange('attachments', filtered)
			return
		}

		setIsUploading(true)
		const result = await _uploadFile(
			messageServiceProcess.uuid,
			filesToUpload,
			isMassSending,
		)
		setIsUploading(false)

		if (!result || result.success === false) {
			const errorMessage = intl.formatMessage({
				defaultMessage: 'Liitteen lisäämisessä tapahtui virhe.',
				description:
					'Error message shown in the message service if the attachment upload fails. ',
			})
			await alert(errorMessage)
			return false
		}

		handleInputChange('attachments', [...filtered, ...result])
		return true
	}

	return (
		<>
			<StepContainer>
				<Content>
					{isGeneratorUsed && (
						<Banner>
							{intl.formatMessage({
								defaultMessage:
									'Huomio! Viesti on luotu aiemmin luomastasi sisällöstä eikä sitä voi muokata. Pääset katselemaan tarkempia esikatseluita myöhemmissä lähetysvaiheissa.',
								description:
									'Banner text in the message service create message step if a generator is used.',
							})}
						</Banner>
					)}
					<TextField
						id="message-service-title"
						label={intl.formatMessage({
							defaultMessage: 'Otsikko',
							description:
								'Label for the title input in the message service create message step.',
						})}
						value={messageServiceProcess.title}
						onChange={(value) => handleInputChange('title', value)}
						style={{ marginBottom: 16 }}
						disabled={isGeneratorUsed}
					/>

					<Label>
						{intl.formatMessage({
							defaultMessage: 'Viesti',
							description:
								'Label for the message input in the message service create message step.',
						})}
					</Label>
					<WYSIWYGEditor
						value={messageServiceProcess.body}
						onChange={(value) => {
							handleInputChange('body', value)
						}}
						onChangePlainText={(plainText) => {
							handleInputChange('bodyText', plainText)
						}}
						htmlOutput={true}
						disabled={isGeneratorUsed}
						style={{ opacity: isGeneratorUsed ? 0.6 : 1.0 }}
					/>

					<Label>
						{intl.formatMessage({
							defaultMessage: 'PDF-asiakirja',
							description:
								'Label for the attachments input in the message service create message step.',
						})}
					</Label>

					{isGeneratorUsed ? (
						messageServiceProcess.attachments &&
						messageServiceProcess.attachments.length > 0 ? (
							<Attachments>
								{messageServiceProcess.attachments.map((a) => (
									<Attachment key={a.filename}>{a.filename}</Attachment>
								))}
							</Attachments>
						) : (
							<p>-</p>
						)
					) : (
						<>
							{/* Using 'display: none' here because the Uploader doesn't handle defaultFiles state properly.
					   			https://app.asana.com/0/1197307225790945/1202906148796428/f
							*/}
							<div
								style={{ margin: 32, display: isUploading ? 'block' : 'none' }}
							>
								<LoadingState />
							</div>
							<div style={{ display: isUploading ? 'none' : 'block' }}>
								<Uploader
									defaultFiles={
										messageServiceProcess.attachments
											? messageServiceProcess.attachments.map((a) => ({
													name: a.filename,
													type: a.type,
											  }))
											: []
									}
									acceptedContentTypes="application/pdf"
									multiple={true}
									pluralForm={intl.formatMessage({
										defaultMessage:
											'Voit lisätä PDF-asiakirjoja klikkaamalla tai raahaamalla ne tähän.',
										description:
											'Instructions for the attachments input in the message service create message step.',
									})}
									onFilesChanged={handleFilesChanged}
									maxMB={30}
								/>
							</div>
						</>
					)}
				</Content>
			</StepContainer>

			<Actions
				onPreviousClick={isUploading ? null : onPreviousClick}
				onDraftClick={isUploading ? null : onDraftClick}
				onNextClick={
					isUploading || !messageServiceProcess.title ? null : onNextClick
				}
			/>
		</>
	)
}

const mapState = () => ({})

const mapDispatchToProps = (dispatch) => ({
	_uploadFile: bindActionCreators(messageServiceActions._uploadFile, dispatch),
})

export default compose(connect(mapState, mapDispatchToProps))(CreateMessage)
