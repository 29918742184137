import { TableRow, TableColumn, MenuButton, CircularProgress } from 'react-md'
import { paymentProductHeaders } from './constants'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import type { PaymentProduct } from './types'
import { useIntl } from 'react-intl'
import { VatSelectOptions } from '../types'
import { getPaymentProductRowValue } from './utils'

interface Props {
	paymentProduct: PaymentProduct
	menuItems: any[] | null
	vatOptions: VatSelectOptions[]
	processing: boolean
	isRowClickActive: boolean
}

const PaymentProductRow = ({
	paymentProduct,
	menuItems,
	vatOptions,
	processing,
	isRowClickActive,
}: Props) => {
	const intl = useIntl()
	const dispatch = useDispatch()
	const appendedMenuItems = menuItems ? [...menuItems] : null

	const menu = !appendedMenuItems ? null : (
		<MenuButton
			id={'menu-button'}
			icon
			menuItems={appendedMenuItems}
			centered
			anchor={{
				x: MenuButton.HorizontalAnchors['CENTER'],
				y: MenuButton.VerticalAnchors['CENTER'],
			}}
		>
			more_vert
		</MenuButton>
	)

	const handleOnClick = () => {
		dispatch(push(window.location.pathname + '?uuid=' + paymentProduct.uuid))
	}

	const renderTableColumns = () => {
		return paymentProductHeaders
			.filter((h) => h.show)
			.map((header) => {
				const { key, type } = header
				return (
					<TableColumn
						onClick={() => isRowClickActive && !processing && handleOnClick()}
						key={header.key}
						style={
							processing
								? {
										opacity: 0.5,
								  }
								: {
										cursor: isRowClickActive ? 'pointer' : 'default',
								  }
						}
					>
						<p>
							{getPaymentProductRowValue(
								paymentProduct,
								key,
								vatOptions,
								intl,
								type,
							)}
						</p>
					</TableColumn>
				)
			})
	}

	return (
		<TableRow key={paymentProduct.uuid}>
			{renderTableColumns()}
			<TableColumn key={'children'}>
				<div>
					{processing ? (
						<CircularProgress id="progress" style={{ marginTop: 0 }} />
					) : (
						isRowClickActive && menu
					)}
				</div>
			</TableColumn>
		</TableRow>
	)
}

export default PaymentProductRow
