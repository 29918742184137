import { useIntl } from 'react-intl'
import type { ConsolidatedInvoice } from './types'
import {
	DataTable,
	TableHeader,
	TableBody,
	TableColumn,
	TableRow,
} from 'react-md'
import { TableContainer } from 'ui/StyledComponents/Tables'
import { consolidatedInvoiceHeaders } from './constants'
import styled from 'styled-components'
import { push } from 'connected-react-router'
import { useDispatch } from 'react-redux'
const StyledDataTable = styled(DataTable)`
	height: calc(100vh - 265px);
	max-height: calc(100vh - 265px);
	td {
		white-space: nowrap;
	}
`
type Props = {
	consolidatedInvoices: Map<string, ConsolidatedInvoice[]>
}
const ConsolidatedInvoicesTable = ({ consolidatedInvoices }: Props) => {
	const intl = useIntl()
	const dispatch = useDispatch()
	const handleOnClick = (batchUUID: string) => {
		const currentHash = window.location.hash

		const URLParams = new URLSearchParams(window.location.search)
		URLParams.append('uuid', batchUUID)
		dispatch(push(currentHash + '?' + URLParams.toString()))
	}

	const getRowValue = (
		entities: ConsolidatedInvoice[],
		key: string,
		type: string,
	) => {
		let value = entities[0][key]
		if (type === 'reduce') {
			value = entities.reduce((acc, e) => acc + e[key], 0)
		}
		return value
	}

	const renderTableColumns = (
		batchUUID: string,
		invoices: ConsolidatedInvoice[],
	) => {
		return consolidatedInvoiceHeaders
			.filter((h) => h.show)
			.map((header) => {
				const { key, type } = header
				return (
					<TableColumn onClick={() => handleOnClick(batchUUID)} key={key}>
						<p>{getRowValue(invoices, key, type)}</p>
					</TableColumn>
				)
			})
	}

	const renderTableHeaders = () => {
		return (
			<TableRow>
				{consolidatedInvoiceHeaders
					.filter((h) => h.show)
					.map((header) => (
						<TableColumn key={header.key}>
							<p className="text-strong text-subtle">{header.title(intl)}</p>
						</TableColumn>
					))}
			</TableRow>
		)
	}

	return (
		<TableContainer>
			<StyledDataTable plain>
				<TableHeader
					style={{
						position: 'sticky',
						top: 0,
						zIndex: 1,
					}}
				>
					{renderTableHeaders()}
				</TableHeader>
				<TableBody>
					{[...consolidatedInvoices.entries()].map(([batchUUID, invoices]) => {
						return (
							<TableRow key={batchUUID}>
								{renderTableColumns(batchUUID, invoices)}
							</TableRow>
						)
					})}
				</TableBody>
			</StyledDataTable>
		</TableContainer>
	)
}

export default ConsolidatedInvoicesTable
