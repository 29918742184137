import CreateMessage from 'ui/MessageService/MessageServiceProcess/steps/CreateMessage'
import DeliveryMethods from 'ui/MessageService/MessageServiceProcess/steps/DeliveryMethods'
import Recipients from 'ui/MessageService/MessageServiceProcess/steps/Recipients'
import Bulletins from 'ui/MessageService/MessageServiceProcess/steps/Recipients/steps/Bulletins'
import Infoscreen from 'ui/MessageService/MessageServiceProcess/steps/Recipients/steps/Infoscreen'
import PrintingAndDistribution from 'ui/MessageService/MessageServiceProcess/steps/Sending/steps/postalSettings/PrintingAndDistribution'
import SenderInformation from 'ui/MessageService/MessageServiceProcess/steps/Sending/steps/postalSettings/SenderInformation'
import CompanySelection from './steps/Recipients/CompanySelection'
import Personal from './steps/Recipients/Personal'
import Sending from './steps/Sending'
import EmailPreview from './steps/Sending/steps/EmailPreview'
import SMSPreview from './steps/Sending/steps/SMSPreview'
import Summary from './steps/Sending/steps/Summary'

export const steps = [
	{
		id: 'create',
		label: 'Luo viesti',
		component: (props) => <CreateMessage {...props} isMassSending={true} />,
	},
	{
		id: 'deliveryMethods',
		label: 'Lähetystavat',
		component: (props) => <DeliveryMethods {...props} isMassSending={true} />,
	},
	{
		id: 'recipients',
		label: 'Vastaanottajat',
		component: Recipients,
		childSteps: [
			{
				id: 'companySelection',
				label: 'Taloyhtiöiden valinta',
				component: CompanySelection,
			},
			{
				id: 'personal',
				label: 'Henkilökohtainen',
				component: Personal,
			},
			{
				id: 'bulletins',
				label: 'Viilu-palvelun ilmoitustaulu',
				component: Bulletins,
			},
			{
				id: 'infoscreen',
				label: 'Viilu-porrasnäyttö',
				component: (props) => <Infoscreen {...props} isMassSending={true} />,
			},
		],
	},
	{
		id: 'send',
		label: 'Lähetys',
		component: Sending,
		childSteps: [
			{
				id: 'email',
				label: 'Sähköpostin esikatselu',
				component: EmailPreview,
			},
			{
				id: 'sms',
				label: 'Tekstiviestin esikatselu',
				component: SMSPreview,
			},
			{
				id: 'postalSenderInformation',
				label: 'Kirjeiden lähettäjän tiedot',
				component: SenderInformation,
			},
			{
				id: 'postalPrintingAndDistribution',
				label: 'Tulostus ja jakelu',
				component: PrintingAndDistribution,
			},
			{
				id: 'summary',
				label: 'Yhteenveto',
				component: Summary,
			},
		],
	},
]
