import { useEffect, useState } from 'react'
import { bindActionCreators, compose } from 'redux'

import PDFPreview from 'ui/components/PDFPreview'
import { connect } from 'react-redux'
import { Button, CircularProgress, FontIcon } from 'react-md'
import styled from 'styled-components'
import IllustrationEmail from 'svg/illustrations/illustration_email_alternate.svg'
import IllustrationSMS from 'svg/illustrations/illustration_sms.svg'

import * as postalBatchActions from 'state/postal-batch-actions'
import * as notifyActions from 'state/notifyActions'
import WithSelectedCompanyManager from 'ui/components/WithSelectedCompanyManager'
import WithSelectedCompany from 'ui/components/WithSelectedCompany'
import { injectIntl } from 'react-intl'
import { GENERIC_ERROR_NOTIFICATION } from 'ui/messages'
import BulletinsSummary from 'ui/MessageService/MessageServiceProcess/steps/Sending/steps/Summary/BulletinsSummary'
import InfoscreenSummary from 'ui/MessageService/MessageServiceProcess/steps/Sending/steps/Summary/InfoscreenSummary'
import { Color } from 'ui/MessageService/MessageServiceProcess/steps/Sending/options'
import Layout from 'ui/MessageService/MessageServiceProcess/steps/Sending/steps/Layout'
import SendDialog from './SendDialog'
import { firestoreConnect } from 'react-redux-firebase'
import PostalSummary from 'ui/MessageService/MessageServiceProcess/steps/Sending/steps/Summary/PostalSummary'
import EmailSummary from 'ui/MessageService/MessageServiceProcess/steps/Sending/steps/Summary/EmailSummary'
import SMSSummary from 'ui/MessageService/MessageServiceProcess/steps/Sending/steps/Summary/SMSSummary'

const ALLOWED_PAGE_WIDTH_DIFFERENCE = 1
const ALLOWED_PAGE_HEIGHT_DIFFERENCE = 1

const InfoBox = styled.div`
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	background: var(--color-primary-dark);
	padding-left: 16px;
	padding-right: 16px;
	padding-top: 16px;
	padding-bottom: 16px;
	border-radius: 4px;
	margin-bottom: 16px;
	z-index: 10;
	span {
		color: white;
		font-weight: bold;
	}
	i {
		color: white;
	}
`
const ErrorBox = styled.div`
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	background: rgba(180, 0, 0, 0.87);
	padding-left: 16px;
	padding-right: 16px;
	padding-top: 16px;
	padding-bottom: 16px;
	border-radius: 4px;
	margin-bottom: 16px;
	z-index: 10;
	span {
		color: white;
		font-weight: bold;
	}
	i {
		color: white;
	}
`

const Illustration = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	img {
		width: 100%;
		max-width: 240px;
	}
`

function Summary({
	stepTitle,
	onPreviousClick,
	onDraftClick,
	onHide,
	messageServiceProcess,
	messageServiceProcess: {
		uuid: processUUID,
		title,
		body,
		attachments,
		postalSettings,
		deliveryMethods,
		bulletinsSettings,
		generator,
		generatorDataUUID,
	},
	selectedCompany,
	_generatePreviewMessageService,
	_showInfoNotification,
	intl,
	syncedMassProcess,
}) {
	const hasEmails = deliveryMethods.includes('email')
	const hasLetters = deliveryMethods.includes('letter')
	const hasSMSs = deliveryMethods.includes('sms')
	const hasBulletins = deliveryMethods.includes('bulletins')
	const hasInfoscreen = deliveryMethods.includes('infoscreen')

	const [letterPageCount, setLetterPageCount] = useState(0)
	const [previewURL, setPreviewURL] = useState()
	const [wrongSizedPageIndices, setWrongSizedPageIndices] = useState([])
	const [showPreviewRetry, setShowPreviewRetry] = useState(false)
	const postalModuleEnabled =
		selectedCompany.features && selectedCompany.features['postal']
	const [sendDialogVisible, setSendDialogVisible] = useState(
		syncedMassProcess?.uuid === messageServiceProcess.uuid &&
			(syncedMassProcess?.locked || false),
	)

	const generateLetterPreviewURL = async () => {
		setShowPreviewRetry(false)
		const result = await _generatePreviewMessageService({
			companyUUID: selectedCompany.uuid,
			processUUID,
			title,
			body,
			attachments,
			postalSettings,
			generator: generator || 'default',
			generatorDataUUID,
		})

		if (result && result.data && result.data.success) {
			const wrongSizedPages = []
			if (result.data.pageSizes) {
				result.data.pageSizes.forEach((pageSize, index) => {
					const widthDifference = Math.abs(pageSize.width - 595)
					const heightDifference = Math.abs(pageSize.height - 842)
					if (
						widthDifference > ALLOWED_PAGE_WIDTH_DIFFERENCE ||
						heightDifference > ALLOWED_PAGE_HEIGHT_DIFFERENCE
					) {
						wrongSizedPages.push(index)
					}
				})
			}
			setWrongSizedPageIndices(wrongSizedPages)
			setPreviewURL(result.data.fileURL)
			return true
		}

		setShowPreviewRetry(true)
		_showInfoNotification(`❌ ${GENERIC_ERROR_NOTIFICATION(intl)}`)
		return false
	}

	useEffect(() => {
		if (hasLetters && postalModuleEnabled) {
			generateLetterPreviewURL()
		} else if (hasEmails) {
			setPreviewURL('email-preview')
		} else {
			setPreviewURL('sms-preview')
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const formatLeftSection = () => {
		if (hasLetters && !postalModuleEnabled) {
			return (
				<>
					<h1>
						{intl.formatMessage({
							defaultMessage: 'Postituspalvelu puuttuu',
							description:
								'Heading of the summary if the postal module is not enabled in the message service summary step.',
						})}
					</h1>
					<p>
						{intl.formatMessage({
							defaultMessage:
								'Olet valinnut lähetettäväksi kirjeitä, mutta postituspalvelua ei olla kytketty päälle tälle kohteelle. ' +
								'Postituspalvelu on erillisveloitteinen palvelu, joka kytketään päälle sopimuksen mukaan. Mikäli koet ' +
								'tämän olevan virhe otathan meihin yhteyttä osoitteessa asiakaspalvelu@vii.lu.',
							description:
								'Overview text of the summary if the postal module is not enabled in the message service summary step.',
						})}
					</p>
				</>
			)
		}

		return (
			<>
				<h1>{stepTitle}</h1>
				{hasLetters && (
					<>
						<p>
							{intl.formatMessage({
								defaultMessage:
									'Olet luomassa lähetystä, jonka liitteenä on oikealla näkyvä materiaali. Materiaali on ' +
									'muutettu automaattisesti A4-kokoiseksi. Tarkastathan materiaalin vielä kertaalleen. ' +
									'Huomioi, että esikatselu ja paperille tulostettu versio voivat erota toisistaan jonkin ' +
									'verran esim. värintoiston osalta.',
								description:
									'Overview text of the postal sending summary in the message service summary step.',
							})}
						</p>
						<PostalSummary
							letterCount={
								syncedMassProcess?.recipientMethodsCount?.letter || 0
							}
							letterPageCount={letterPageCount}
							postalSettings={postalSettings}
						/>
					</>
				)}
				{hasEmails && (
					<EmailSummary
						emailCount={syncedMassProcess?.recipientMethodsCount?.email}
					/>
				)}
				{hasSMSs && (
					<SMSSummary
						smsCount={syncedMassProcess?.recipientMethodsCount?.sms || 0}
					/>
				)}

				{hasBulletins && (
					<BulletinsSummary bulletinsSettings={bulletinsSettings} />
				)}
				{hasInfoscreen && (
					<InfoscreenSummary bulletinsSettings={bulletinsSettings} />
				)}
			</>
		)
	}

	let left = formatLeftSection()
	let right
	if (showPreviewRetry) {
		right = (
			<div className="flex-column flex-center">
				<p className="margin-bottom">
					{intl.formatMessage({
						defaultMessage: 'Esikatselun lataaminen epäonnistui.',
						description:
							'Error message shown if the pdf preview failed in the message service summary step.',
					})}
				</p>
				<Button
					secondary
					raised
					onClick={generateLetterPreviewURL}
					style={{ background: 'var(--color-secondary)' }}
				>
					{intl.formatMessage({
						defaultMessage: 'Yritä uudelleen',
						description:
							'Label for the retry pdf preview button in the message service summary step.',
					})}
				</Button>
			</div>
		)
	} else if (!previewURL) {
		right = <CircularProgress id="preview-url-progress" />
	} else if (previewURL === 'email-preview') {
		right = (
			<Illustration>
				<img
					src={IllustrationEmail}
					alt={intl.formatMessage({
						defaultMessage: 'Sähköposti illustraatio',
						description:
							'Alternative text for the email illustration in the message service summary step.',
					})}
				/>
			</Illustration>
		)
	} else if (previewURL === 'sms-preview') {
		right = (
			<Illustration>
				<img
					src={IllustrationSMS}
					alt={intl.formatMessage({
						defaultMessage: 'Tekstiviesti illustraatio',
						description:
							'Alternative text for the sms illustration in the message service summary step.',
					})}
					style={{ borderRadius: 16 }}
				/>
			</Illustration>
		)
	} else {
		right = (
			<div style={{ position: 'relative' }}>
				<InfoBox>
					<FontIcon style={{ marginRight: 16 }}>info</FontIcon>
					<span style={{ display: 'inline' }}>
						{intl.formatMessage({
							defaultMessage:
								'Esikatselu pyrkii näyttämään paperi-version asettelun sinulle niin kuin se olisi sinun kädessäsi. Mahdolliset tyhjät extrasivut ovat paperiarkin kääntöpuolia.',
							description:
								'Message telling the user that the preview is realistic.',
						})}
					</span>
				</InfoBox>
				{wrongSizedPageIndices.length > 0 ? (
					<ErrorBox>
						<FontIcon style={{ marginRight: 16 }}>info</FontIcon>
						<span style={{ display: 'inline' }}>
							{wrongSizedPageIndices.length === 1
								? intl.formatMessage(
										{
											defaultMessage:
												'Sivu {pageNumber} poikkeaa postituspalvelun edellyttämästä sivujen koosta pysty A4.',
											description:
												'Singular version of the wrong sized pages error in the message service summary step.',
										},
										{ pageNumber: wrongSizedPageIndices[0] + 1 },
								  )
								: intl.formatMessage(
										{
											defaultMessage:
												'Sivut {pageNumbersExceptLast} ja {pageNumbersLast} poikkeavat postituspalvelun edellyttämästä sivujen koosta pysty A4.',
											description:
												'Plural version of the wrong sized pages error in the message service summary step.',
										},
										{
											pageNumbersExceptLast: wrongSizedPageIndices
												.slice(0, -1)
												.map((i) => i + 1)
												.join(', '),
											pageNumbersLast: wrongSizedPageIndices.slice(-1)[0] + 1,
										},
								  )}
						</span>
					</ErrorBox>
				) : null}
				<PDFPreview
					fileURL={previewURL}
					hideDownload
					getPageCount={setLetterPageCount}
				/>
				{postalSettings.color === Color.BLACK ? (
					<div
						style={{
							position: 'absolute',
							top: 0,
							left: 0,
							right: 0,
							bottom: 0,
							mixBlendMode: 'saturation',
							background: 'white',
						}}
					></div>
				) : null}
			</div>
		)
	}

	const nextButtonDisabled =
		hasLetters &&
		(!letterPageCount ||
			wrongSizedPageIndices.length > 0 ||
			!postalModuleEnabled)

	return (
		<>
			<Layout
				left={left}
				right={right}
				onPreviousClick={syncedMassProcess?.locked ? null : onPreviousClick}
				onDraftClick={syncedMassProcess?.locked ? null : onDraftClick}
				onNextClick={
					nextButtonDisabled ? null : () => setSendDialogVisible(true)
				}
				nextLabel={intl.formatMessage({
					defaultMessage: 'Lähetykset',
					description:
						'Label for the button than confirms to create message service processes for multiple companies in the message service mass sending summary step.',
				})}
			/>

			<SendDialog
				visible={sendDialogVisible}
				onHide={() => setSendDialogVisible(false)}
				closeSending={onHide}
				messageServiceMassProcess={messageServiceProcess}
				syncedMassProcess={syncedMassProcess}
				letterPageCount={letterPageCount}
			/>
		</>
	)
}

const mapState = ({ firestoreReducer: { ordered } }) => ({
	syncedMassProcess: ordered['synced_message_service_mass_process']?.[0],
})
const mapDispatchToProps = (dispatch) => ({
	_generatePreviewMessageService: bindActionCreators(
		postalBatchActions._generatePreviewMessageService,
		dispatch,
	),
	_showInfoNotification: bindActionCreators(notifyActions.info, dispatch),
})

export default compose(
	injectIntl,
	WithSelectedCompany,
	WithSelectedCompanyManager,
	firestoreConnect(({ messageServiceProcess }) => [
		{
			collection: 'message-service-mass-process',
			doc: messageServiceProcess.uuid,
			storeAs: 'synced_message_service_mass_process',
		},
	]),
	connect(mapState, mapDispatchToProps),
)(Summary)
