type InvocingPeriods = {
	currentInvoicingPeriod: {
		currentStartDate: Date
		currentEndDate: Date
	}
	nextInvoicingPeriod: {
		nextStartDate: Date
		nextEndDate: Date
	}
}

const getLastDayOfMonth = (year: number, month: number) =>
	new Date(year, month + 1, 0, 23, 59, 59, 999)

export const getInvoicingPeriods = (
	invoicingStartMonth: number = 1,
	currentDate: Date,
): InvocingPeriods => {
	const currentYear = new Date(currentDate).getFullYear()

	const startYear =
		currentDate.getMonth() + 1 < invoicingStartMonth
			? currentYear - 1
			: currentYear

	const currentStartDate = new Date(startYear, invoicingStartMonth - 1, 1, 12)
	const currentEndDate = getLastDayOfMonth(
		invoicingStartMonth === 1 ? startYear : startYear + 1,
		invoicingStartMonth === 1 ? 11 : invoicingStartMonth - 2,
	)

	const nextStartDate = new Date(currentStartDate)
	nextStartDate.setFullYear(nextStartDate.getFullYear() + 1)
	const nextEndDate = new Date(currentEndDate)
	nextEndDate.setFullYear(nextEndDate.getFullYear() + 1)

	return {
		currentInvoicingPeriod: { currentStartDate, currentEndDate },
		nextInvoicingPeriod: { nextStartDate, nextEndDate },
	}
}
