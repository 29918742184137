import { useCallback, useEffect, useState } from 'react'
import type { Contract } from './types'
import { _getContract } from 'state/contract-actions'

function useContract(uuid: string | undefined) {
	const [contract, setContract] = useState<Contract>()

	const [loadingContract, setLoading] = useState<boolean>(false)
	const [errorContract, setError] = useState<string | null>(null)

	const doQuery = useCallback(async () => {
		if (!uuid) return
		setLoading(true)
		setError(null)
		let data: Contract | undefined

		try {
			const response = await _getContract(uuid)

			if (response && response.ok) {
				const res = await response.json()
				data = res.contract
			} else {
				throw new Error('Response is not ok.')
			}
		} catch (err) {
			console.error('Error fetching contract:', err)
			setError('An error occurred while fetching contract: ' + uuid)
		}
		setContract(data)
		setLoading(false)
	}, [uuid])

	useEffect(() => {
		doQuery()
	}, [doQuery])

	return { contract, loadingContract, errorContract, refreshParty: doQuery }
}

export default useContract
