import styled from 'styled-components'
import Actions from '../../../../Actions'
import { useIntl } from 'react-intl'
import { StepContainer } from '../../../styled'
import DistributionSelection from './DistributionSelection'

const Content = styled.div`
	max-width: 600px;
	padding: 24px 32px;
	margin: 16px auto;
	background: #f6f7f9;
	border-radius: 8px;
`

const StepTitle = styled.h1`
	font-size: 1.8rem;
	margin: 16px 16px 0px 16px;
`

function Integrations({
	stepTitle,
	messageServiceProcess,
	setMessageServiceProcess,
	onPreviousClick,
	onDraftClick,
	onNextClick,
}) {
	const intl = useIntl()

	const validateNext = () => {
		return messageServiceProcess.integrationsSettings?.distribution.length > 0
	}

	const onSelectDistribution = (checked, option) => {
		let distribution = messageServiceProcess.integrationsSettings?.distribution
			? [...messageServiceProcess.integrationsSettings?.distribution]
			: []

		if (checked) {
			distribution.push(option)
		} else {
			distribution = distribution.filter((i) => i !== option)
		}

		setMessageServiceProcess({
			...messageServiceProcess,
			integrationsSettings: {
				...messageServiceProcess.integrationsSettings,
				distribution,
			},
		})
	}

	return (
		<>
			<StepTitle>{stepTitle}</StepTitle>

			<StepContainer>
				<Content>
					<p style={{ marginBottom: 16 }}>
						{intl.formatMessage({
							defaultMessage: 'Valitse kenelle viesti näkyy integraatioissa.',
							description:
								'Description of the message service integration settings step.',
						})}
					</p>
					<DistributionSelection
						distribution={
							messageServiceProcess.integrationsSettings?.distribution
						}
						onSelectDistribution={onSelectDistribution}
					/>
				</Content>
			</StepContainer>

			<Actions
				onPreviousClick={onPreviousClick}
				onDraftClick={onDraftClick}
				onNextClick={validateNext() ? onNextClick : null}
			/>
		</>
	)
}

export default Integrations
