import React from 'react'
import { Button } from 'react-md'
import bg from 'svg/illustrations/illustration_abstract_bg.svg'

const GenericErrorBoundary = ({ error }) => {
	return (
		<div className="flex-column flex-center full-width full-height">
			<img
				src={bg}
				style={{
					position: 'absolute',
					width: '100%',
					height: '100%',
					objectFit: 'cover',
					opacity: 0.5,
				}}
				alt=""
			/>
			<div
				className="flex-column flex-center"
				style={{ background: '#FAFAFA', borderRadius: 4, padding: 24 }}
			>
				<h1>Jokin meni vikaan</h1>
				<p>
					Ongelma on raportoitu eteenpäin. Voit yrittää päivittää sivun tai
					käynnistää sovelluksen uudestaan.
				</p>
				<Button
					raised
					primary
					onClick={() => window.location.reload(true)}
					className="margin-top margin-bottom"
				>
					Päivitä
				</Button>
			</div>
		</div>
	)
}

export default GenericErrorBoundary
