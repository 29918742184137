import { LOCALES } from './locales'
import moment from 'moment-timezone'
import 'moment-duration-format'
import 'moment/locale/sv'
import 'moment/locale/fi'
moment.tz.setDefault('Europe/Helsinki')

export function setMomentLocale(appLocale: string): void {
	switch (appLocale) {
		case LOCALES.SWEDISH:
			moment.locale('sv')
			break
		case LOCALES.ENGLISH:
			moment.locale('en')
			break
		default:
			moment.locale('fi')
			break
	}
}
