import BaseButton, { Status } from '../BaseButton'

const getStatus = (recipient, emailStatus) => {
	if (!recipient.checked || !recipient.checked['email']) {
		return Status.NOT_SELECTED
	}

	const history = emailStatus?.statusHistory

	if (!history) {
		return Status.UNKNOWN
	}

	const latest = history[history.length - 1]

	if (latest.status === 'Processed') {
		if (latest.message === 'OK') {
			return Status.SENT
		} else {
			return Status.ERROR
		}
	}

	if (latest.status === 'Bounced') {
		return Status.ERROR
	}

	if (latest.status === 'Delivered') {
		return Status.DELIVERED
	}

	return Status.UNKNOWN
}

function EmailStatusButton({ recipient, emailStatus, onClick }) {
	const status = getStatus(recipient, emailStatus)

	return <BaseButton status={status} onClick={onClick} />
}

export default EmailStatusButton
