import { ManagerTypes } from 'types/manager'

export const MISSING_PERMISSIONS_MESSAGE = (intl) =>
	intl.formatMessage({
		defaultMessage: 'Sinulla ei ole tarvittavia käyttöoikeuksia.',
		description:
			"Error message telling the user that they don't have the necessary permissions.",
	})

export const FEATURE_DISABLED_MESSAGE = (intl) =>
	intl.formatMessage({
		defaultMessage:
			'Isännöintitoimisto on poistanut tämän ominaisuuden käytöstä.',
		description:
			'Error message shown if the management company has disabled this feature from board members.',
	})

export const GENERIC_ERROR_NOTIFICATION = (intl) =>
	intl.formatMessage({
		defaultMessage: 'Tapahtui virhe. Ole hyvä ja yritä uudelleen.',
		description: 'Error message asking the user to try again.',
	})

export const GENERIC_SAVED_NOTIFICATION = (intl) =>
	intl.formatMessage({
		defaultMessage: 'Tallennettu onnistuneesti.',
		description:
			'Generic notification shown to the user when something has been saved successfully.',
	})

export const GENERIC_DELETED_NOTIFICATION = (intl) =>
	intl.formatMessage({
		defaultMessage: 'Poistettu onnistuneesti.',
		description:
			'Generic notification shown to the user when something has been deleted successfully.',
	})

export const DELETE_BUTTON_LABEL = (intl) =>
	intl.formatMessage({
		defaultMessage: 'Poista',
		description: 'Label for a generic delete button.',
	})

export const SAVE_BUTTON_LABEL = (intl) =>
	intl.formatMessage({
		defaultMessage: 'Tallenna',
		description: 'Label for a generic save button.',
	})

export const DRAFT_BUTTON_LABEL = (intl) =>
	intl.formatMessage({
		defaultMessage: 'Tallenna luonnos',
		description: 'Label for a generic save draft button.',
	})

export const CANCEL_BUTTON_LABEL = (intl) =>
	intl.formatMessage({
		defaultMessage: 'Peruuta',
		description: 'Label for a generic cancel button.',
	})

export const CLOSE_BUTTON_LABEL = (intl) =>
	intl.formatMessage({
		defaultMessage: 'Sulje',
		description: 'Label for a generic close button.',
	})

export const PREVIOUS_BUTTON_LABEL = (intl) =>
	intl.formatMessage({
		defaultMessage: 'Edellinen',
		description: 'Label for a generic previous button.',
	})

export const NEXT_BUTTON_LABEL = (intl) =>
	intl.formatMessage({
		defaultMessage: 'Seuraava',
		description: 'Label for a generic next button.',
	})

export const UPDATE_BUTTON_LABEL = (intl) =>
	intl.formatMessage({
		defaultMessage: 'Päivitä',
		description: 'Label for a generic update button.',
	})

export const APARTMENT_STATUS = (intl, status) => {
	switch (status) {
		case 'valid':
			return intl.formatMessage({
				defaultMessage: 'Voimassa',
				description: 'Label for a valid apartment status.',
			})
		case 'invalid':
			return intl.formatMessage({
				defaultMessage: 'Lakannut',
				description: 'Label for a invalid apartment status.',
			})
		case 'emerging':
			return intl.formatMessage({
				defaultMessage: 'Syntyvä',
				description: 'Label for a emerging apartmen status.',
			})
		case 'terminating':
			return intl.formatMessage({
				defaultMessage: 'Lakkaava',
				description: 'Label for a terminating apartmen status.',
			})
		default:
			return '-'
	}
}

export const MANAGER_TYPES = (intl, managerType) => {
	switch (managerType) {
		case ManagerTypes.ManagerCompany:
			return intl.formatMessage({
				defaultMessage: 'Isännöintitoimisto',
				description: 'Label for the manager company type.',
			})
		case ManagerTypes.MaintenanceCompany:
			return intl.formatMessage({
				defaultMessage: 'Huoltoyhtiö',
				description: 'Label for the maintenance company type.',
			})
		default:
			return intl.formatMessage({
				defaultMessage: 'Isännöintitoimisto',
				description: 'Label for the manager company type.',
			})
	}
}

export const YES_NO_MESSAGE = (intl, value) => {
	return value
		? intl.formatMessage({
				defaultMessage: 'Kyllä',
				description: 'Message for yes.',
		  })
		: intl.formatMessage({
				defaultMessage: 'Ei',
				description: 'Message for no.',
		  })
}
