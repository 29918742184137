import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import {
	Toolbar,
	Button,
	FontIcon,
	DataTable,
	TableHeader,
	TableBody,
	TableColumn,
	TableRow,
	Grid,
} from 'react-md'
import moment from 'moment-timezone'

import * as tampuuriActions from 'state/tampuuri-actions'
import * as notifyActions from 'state/notifyActions'

import LoadingState from 'ui/components/LoadingState'
import WithFirestoreArrayResultToSingleValue from 'ui/components/WithFirestoreArrayResultToSingleValue'
import TampuuriApartmentReportImportDialog from './TampuuriApartmentReportImportDialog'
import ViiluDialog from 'ui/components/ViiluDialog'

class TampuuriApartmentReportDialog extends Component {
	state = {
		processing: false,
		apartments: [],

		fileImportDialogVisible: false,
	}

	showFileImportDialog = () => this.setState({ fileImportDialogVisible: true })
	hideFileImportDialog = () => this.setState({ fileImportDialogVisible: false })

	pushApartments = (newApartments) => {
		const { apartments } = this.state
		const combinedApartments = [...apartments]
		newApartments.forEach((apt) => {
			const found = combinedApartments.find(
				(a) =>
					a.tampuuriCompanyTunnus === apt.tampuuriCompanyTunnus &&
					a.identifier === apt.identifier,
			)
			if (found) {
				// replace existing one
				const index = combinedApartments.indexOf(found)
				combinedApartments[index] = apt
			} else {
				combinedApartments.push(apt)
			}
		})

		this.setState({ apartments: combinedApartments })
	}

	onHide = () => {
		const { onHide } = this.props
		onHide()
	}

	onSave = async () => {
		const { managerUUID, _apartmentReportSave, _showInfoNotification } =
			this.props
		const { apartments } = this.state

		if (apartments.length === 0) {
			return false
		}

		this.setState({ processing: true })
		const result = await _apartmentReportSave(managerUUID, apartments)
		this.setState({ processing: false })

		if (result && result.data) {
			this.setState({ apartments: [] })
			_showInfoNotification('✅ Tampuurin kohderaportit on tallennettu.')
			return true
		}

		_showInfoNotification('❌ Tapahtui virhe. Ole hyvä ja yritä uudelleen.')
		return false
	}

	onDelete = async () => {
		const { managerUUID, _apartmentReportDelete, _showInfoNotification } =
			this.props

		this.setState({ processing: true })
		const result = await _apartmentReportDelete(managerUUID)
		this.setState({ processing: false })

		if (result && result.data) {
			_showInfoNotification('✅ Tampuurin kohderaportit on poistettu Viilusta.')
			return true
		}

		_showInfoNotification('❌ Tapahtui virhe. Ole hyvä ja yritä uudelleen.')
		return false
	}

	renderTable = () => {
		const { apartments } = this.state
		const { apartmentReport } = this.props

		const finalApartments =
			apartments && apartments.length
				? apartments
				: apartmentReport
				? apartmentReport.apartments
				: []

		return (
			<DataTable
				baseId="invite-table"
				plain
				style={{ paddingBottom: 100 }}
				className="md-toolbar-relative"
			>
				<TableHeader>
					<TableRow>
						<TableColumn>
							<p>Tampuuri tunnus</p>
						</TableColumn>
						<TableColumn>
							<p>Kohde</p>
						</TableColumn>
						<TableColumn>
							<p>Huoneiston tunnus</p>
						</TableColumn>
						<TableColumn>
							<p>Äänimäärä</p>
						</TableColumn>
					</TableRow>
				</TableHeader>
				<TableBody>
					{finalApartments.map((a) => (
						<TableRow
							key={
								a.tampuuriCompanyTunnus + a.tampuuriCompanyName + a.identifier
							}
						>
							<TableColumn>{a.tampuuriCompanyTunnus}</TableColumn>
							<TableColumn>{a.tampuuriCompanyName}</TableColumn>
							<TableColumn>{a.identifier}</TableColumn>
							<TableColumn>{a.voteCount}</TableColumn>
						</TableRow>
					))}
				</TableBody>
			</DataTable>
		)
	}

	renderToolbar = () => {
		const { apartmentReport } = this.props
		const { apartments } = this.state
		return (
			<Toolbar
				style={{
					position: 'fixed',
					background: 'white',
					width: '100%',
					zIndex: 100,
				}}
				actions={[
					<Button
						flat
						onClick={this.showFileImportDialog}
						iconEl={<FontIcon>description</FontIcon>}
					>
						Tuo Tiedostolla
					</Button>,
					apartments.length ? (
						<Button
							flat
							onClick={() => this.setState({ apartments: [] })}
							iconEl={<FontIcon>delete</FontIcon>}
						>
							Tyhjennä lista
						</Button>
					) : apartmentReport &&
					  apartmentReport.apartments &&
					  apartmentReport.apartments.length ? (
						<Button
							flat
							onClick={this.onDelete}
							iconEl={<FontIcon>delete</FontIcon>}
						>
							Poista viilusta
						</Button>
					) : null,
				]}
			/>
		)
	}

	renderContent() {
		const { managerUUID, apartmentReport, isDesktop } = this.props
		const { processing, apartments, fileImportDialogVisible } = this.state

		if (processing) {
			return (
				<div className="flex-column" style={{ padding: 24 }}>
					<LoadingState />
				</div>
			)
		}

		return (
			<div
				className="flex-column"
				style={{
					maxWidth: '100%',
					height: '100%',
					minHeight: 500,
					justifyContent: 'space-between',
				}}
			>
				<div>
					{this.renderToolbar()}
					{this.renderTable()}
				</div>

				<div
					className="flex-row full-width padding-top padding-bottom"
					style={{
						position: 'fixed',
						bottom: 0,
						background: 'white',
						paddingLeft: isDesktop ? 16 : 0,
						paddingRight: isDesktop ? 16 : 0,
					}}
				>
					{apartments.length ? (
						<p className="full-width">
							{apartments.length} riviä. Tallentaminen ylikirjoittaa edellisen
							tiedon.
						</p>
					) : apartmentReport ? (
						<p className="full-width">
							{apartmentReport.apartments
								? apartmentReport.apartments.length
								: 0}{' '}
							riviä. Tuotu Viiluun{' '}
							{moment(new Date(apartmentReport.createdAt)).format(
								'HH:mm DD.MM.YYYY',
							)}
							.
						</p>
					) : (
						<p className="full-width">
							Et ole tuonut Viiluun Tampuurin kohderaportteja.
						</p>
					)}
					<div
						className="flex-row full-width"
						style={{ justifyContent: 'flex-end' }}
					>
						<Button
							raised
							secondary
							onClick={this.onSave}
							disabled={apartments.length === 0}
							style={{
								background:
									apartments.length === 0 ? 'grey' : 'var(--color-secondary)',
							}}
						>
							{isDesktop ? 'TALLENNA RAPORTTI' : 'TALLENNA'}
						</Button>
					</div>
				</div>

				{fileImportDialogVisible ? (
					<TampuuriApartmentReportImportDialog
						onSuccess={this.pushApartments}
						onHide={this.hideFileImportDialog}
						managerUUID={managerUUID}
					/>
				) : null}
			</div>
		)
	}

	render() {
		return (
			<ViiluDialog
				id="responsive-dialog"
				aria-label="Tampuurin kohderaportit"
				visible={true}
				onHide={this.onHide}
				focusOnMount={true}
				containFocus={true}
				dialogClassName="responsive-dialog"
				contentClassName="responsive-dialog-content"
				autosizeContent={false}
				disableScrollLocking
				modal
				portal
				paddedContent
				onClick={(e) => {
					e.preventDefault()
					e.stopPropagation()
				}}
				dialogStyle={{ width: '100%', minWidth: 320 }}
				contentStyle={{ minHeight: 650 }}
			>
				<Toolbar
					colored
					title="Tampuurin kohderaportit"
					style={{
						background: 'var(--color-secondary-dark)',
						position: 'fixed',
						width: '100%',
						zIndex: 2,
					}}
					nav={
						<Button icon onClick={this.onHide}>
							close
						</Button>
					}
				/>
				<section
					className="md-toolbar-relative full-height"
					style={{ minHeight: 650 }}
				>
					<div className="flex-column full-height" style={{ minHeight: 650 }}>
						<Grid
							style={{ margin: 0, padding: 0, minHeight: 650 }}
							className="flex-column full-height"
						>
							{this.renderContent()}
						</Grid>
					</div>
				</section>
			</ViiluDialog>
		)
	}
}

TampuuriApartmentReportDialog.propTypes = {
	onHide: PropTypes.func.isRequired,
	managerUUID: PropTypes.string.isRequired,
}

const mapState = ({ dimensions: { isDesktop } }) => ({
	isDesktop,
})

const mapDispatchToProps = (dispatch) => ({
	_apartmentReportSave: bindActionCreators(
		tampuuriActions._apartmentReportSave,
		dispatch,
	),
	_apartmentReportDelete: bindActionCreators(
		tampuuriActions._apartmentReportDelete,
		dispatch,
	),
	_showInfoNotification: bindActionCreators(notifyActions.info, dispatch),
})

export default compose(
	firestoreConnect(({ managerUUID }) => [
		{
			collection: 'manager-integration-tampuuri-apartment-report',
			storeAs: 'tampuuri_apartments_report',
			where: [['managerUUID', '==', managerUUID]],
			limit: 1,
		},
	]),
	WithFirestoreArrayResultToSingleValue(
		'tampuuri_apartments_report',
		'apartmentReport',
	),
	connect(mapState, mapDispatchToProps),
)(TampuuriApartmentReportDialog)
