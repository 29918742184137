import { useState } from 'react'
import { useIntl } from 'react-intl'
import { Button, CircularProgress, DialogContainer } from 'react-md'
import { CANCEL_BUTTON_LABEL, GENERIC_ERROR_NOTIFICATION } from 'ui/messages'
import { _htjGetCompany, _htjCompanyLink } from 'state/htj-actions'
import * as notifyActions from 'state/notifyActions'
import { useDispatch } from 'react-redux'

type Props = {
	visible: true
	onHide: () => void
	selectedCompany: any
}

type HtjSearchData = {
	htj: { ytunnus: string }
	businessId: string
	name: string
	area: string
}

function HtjCompanyLinkDialog({ visible, selectedCompany, onHide }: Props) {
	const intl = useIntl()
	const dispatch = useDispatch()
	const [loading, setLoading] = useState(false)
	const [htjSearchData, setHtjSearchData] = useState<HtjSearchData>()

	const htjSearch = async () => {
		setLoading(true)
		const searchResult: any = await _htjGetCompany(selectedCompany.uuid)
		setLoading(false)

		if (searchResult?.success === false) {
			let message: string
			if (searchResult.ex?.details?.code === 'missing-authorization-key') {
				message = intl.formatMessage({
					defaultMessage: 'Taloyhtiöltä puuttuu valtuutusavain.',
					description:
						'Error message for a missing htj integration authorization key.',
				})
			} else {
				message = GENERIC_ERROR_NOTIFICATION(intl)
			}
			dispatch(notifyActions.error(message))
			return
		}

		const searchData: HtjSearchData = searchResult.data
		if (!searchData?.htj?.ytunnus) {
			const message = intl.formatMessage({
				defaultMessage: 'Virheellinen hakutulos.',
				description:
					'Error message shown if the htj company search result is invalid.',
			})
			dispatch(notifyActions.error(message))
			return
		}

		setHtjSearchData(searchData)
	}

	const htjLink = async () => {
		if (!htjSearchData?.htj) {
			dispatch(notifyActions.error(GENERIC_ERROR_NOTIFICATION(intl)))
			return
		}

		setLoading(true)
		const linkResult: any = await _htjCompanyLink(
			selectedCompany.uuid,
			htjSearchData.htj,
		)

		if (linkResult?.success === false) {
			dispatch(notifyActions.error(GENERIC_ERROR_NOTIFICATION(intl)))
			setLoading(false)
			return
		}

		const successMessage = intl.formatMessage({
			defaultMessage: 'Kohde on linkitetty.',
			description:
				'Notification shown to the user when a company has been linked.',
		})
		dispatch(notifyActions.success(successMessage))
	}

	const renderContent = () => {
		if (!selectedCompany.businessId) {
			return (
				<p>
					{intl.formatMessage({
						defaultMessage:
							'Kohteen linkittäminen vaatii Y-tunnuksen määrittelemistä. Lisää Y-tunnus kohteen asetuksien kautta ja yritä uudelleen.',
						description:
							'Error message telling the user that the business ID is required before the property can be linked.',
					})}
				</p>
			)
		}
		if (loading) {
			return (
				<CircularProgress
					id="htj-company-link-progress"
					style={{ marginTop: 32 }}
				/>
			)
		}
		if (!htjSearchData) {
			return intl.formatMessage({
				defaultMessage: 'Haetaanko kohde HTJ:stä linkitystä varten?',
				description: 'Confirm message for HTJ company search.',
			})
		}
		return intl.formatMessage(
			{
				defaultMessage: 'Linkitetäänkö HTJ:stä löytynyt kohde: {name}?',
				description:
					'Confirmation asking if the user wants to link this company to the one found from HTJ.',
			},
			{
				name: htjSearchData.name,
			},
		)
	}

	return (
		<DialogContainer
			id="htj-company-link-dialog"
			visible={visible}
			onHide={onHide}
			title={intl.formatMessage({
				defaultMessage: 'Linkitä kohde',
				description: 'Title of the htj company link dialog.',
			})}
			focusOnMount={false}
			actions={[
				<Button flat onClick={onHide}>
					{CANCEL_BUTTON_LABEL(intl)}
				</Button>,
				<Button
					raised
					secondary
					disabled={!selectedCompany.businessId || loading}
					onClick={!htjSearchData ? htjSearch : htjLink}
				>
					Ok
				</Button>,
			]}
			modal
			dialogStyle={{ borderRadius: 8, width: 600 }}
		>
			{renderContent()}
		</DialogContainer>
	)
}

export default HtjCompanyLinkDialog
