import { useCallback, useEffect, useState } from 'react'
import { _getAccountLedgerDetails } from 'state/finance-accounts-ledger-actions'
import type {
	AccountLedgerDetails,
	AccountsLedgerDetailsFilters,
} from './types'

function useAccountsLedgerDetails(
	apartmentUUID: string,
	filters: AccountsLedgerDetailsFilters,
) {
	const [accountsLedgerDetails, setAccountsLedgerDetails] =
		useState<AccountLedgerDetails>()

	const [loading, setLoading] = useState<boolean>(false)
	const [error, setError] = useState<string | null>(null)

	const doQuery = useCallback(async () => {
		setLoading(true)
		setError(null)
		try {
			const response = await _getAccountLedgerDetails(apartmentUUID, filters)

			if (response && response.ok) {
				const res = await response.json()

				if (res.details) setAccountsLedgerDetails(res.details)
			} else {
				console.error('Something went wrong.')
				setError('Failed to fetch Account Ledger')
			}
		} catch (err) {
			console.error('Error fetching Account Ledger:', err)
			setError('An error occurred while fetching Account Ledger.')
		}
		setLoading(false)
	}, [apartmentUUID, filters])
	useEffect(() => {
		doQuery()
	}, [doQuery])

	return {
		accountsLedgerDetails,
		loading,
		error,
		refreshAccountLedger: doQuery,
	}
}

export type useAccountLedgerResult = ReturnType<typeof useAccountsLedgerDetails>

export default useAccountsLedgerDetails
