import { Button, CircularProgress } from 'react-md'
import { useState } from 'react'
import { _htjGetShareholders } from 'state/htj-actions'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import * as notifyActions from 'state/notifyActions'
import { CANCEL_BUTTON_LABEL, GENERIC_ERROR_NOTIFICATION } from 'ui/messages'
import styled from 'styled-components'
import useDialogs from 'ui/components/dialogs/useDialogs'
import ViiluDialog from 'ui/components/ViiluDialog'
import HtjCompanyLinkDialog from 'ui/components/htj/HtjCompanyLinkDialog'

const BusinessId = styled.p`
	margin-top: 16px;
	font-weight: bold;
`

function ImporterDialogHtj({ onHide, selectedCompany, onSuccess }) {
	const intl = useIntl()
	const [isLoading, setIsLoading] = useState(false)
	const dispatch = useDispatch()
	const { alert } = useDialogs()

	const integration =
		selectedCompany.integrations?.htj?.[selectedCompany.managerUUID]

	const searchHtj = async () => {
		setIsLoading(true)
		const result = await _htjGetShareholders(selectedCompany.uuid)
		setIsLoading(false)

		if (result?.success === false) {
			if (result.ex?.details?.code === 'missing-authorization-key') {
				const message = intl.formatMessage({
					defaultMessage: 'Taloyhtiöltä puuttuu valtuutusavain.',
					description:
						'Error message for a missing htj integration authorization key.',
				})
				await alert(message)
			} else {
				dispatch(notifyActions.error(GENERIC_ERROR_NOTIFICATION(intl)))
			}
			return
		}

		if (!result.data || result.data?.length === 0) {
			const message = intl.formatMessage({
				defaultMessage: 'Tietoja ei löytynyt',
				description:
					'Message shown in the htj shareholder import dialog if the search did not return any results.',
			})
			await alert(message)
			return
		}

		onSuccess(result.data)
		onHide()
	}

	const renderDialogContent = () => {
		if (isLoading) {
			return (
				<CircularProgress
					id="htj-import-shareholders-progress"
					style={{ marginTop: 32 }}
				/>
			)
		}

		return (
			<>
				<p>
					{intl.formatMessage({
						defaultMessage:
							'Voit tuoda osakastiedot suoraan huoneistotietojärjestelmästä. Tiedot haetaan yhtiön Y-tunnuksen perusteella.',
						description: 'Description in the htj shareholders import dialog.',
					})}
				</p>
				<BusinessId>
					{intl.formatMessage({
						defaultMessage: 'Y-tunnus',
						description: 'Business id',
					})}
					: {selectedCompany.businessId}
				</BusinessId>
			</>
		)
	}

	if (!integration) {
		return (
			<HtjCompanyLinkDialog
				visible={true}
				onHide={onHide}
				selectedCompany={selectedCompany}
			/>
		)
	}

	return (
		<ViiluDialog
			id="importer-dialog-htj"
			visible={true}
			onHide={onHide}
			title={intl.formatMessage({
				defaultMessage: 'Tuo HTJ:stä',
				description: 'Title of the htj shareholder import dialog.',
			})}
			focusOnMount={false}
			actions={[
				<Button flat disabled={isLoading} onClick={onHide}>
					{CANCEL_BUTTON_LABEL(intl)}
				</Button>,
				<Button raised secondary disabled={isLoading} onClick={searchHtj}>
					{intl.formatMessage({
						defaultMessage: 'Tuo',
						description:
							'Label for the import button in the htj shareholder import dialog.',
					})}
				</Button>,
			]}
			modal
			dialogStyle={{ borderRadius: 8, width: 600 }}
		>
			{renderDialogContent()}
		</ViiluDialog>
	)
}

export default ImporterDialogHtj
