import ReactGA from 'react-ga4'

const options = {}

const trackPage = (page) => {
	ReactGA.set({
		page,
		...options,
	})
	ReactGA.send({ hitType: 'pageview', page })
}

let currentPage = ''

export const googleAnalytics = (store) => (next) => (action) => {
	if (action.type === '@@router/LOCATION_CHANGE') {
		const nextPage = `${action.payload.location.pathname}${action.payload.location.search}`

		if (currentPage !== nextPage) {
			currentPage = nextPage
			trackPage(nextPage)
		}
	}

	return next(action)
}
