import { useIntl } from 'react-intl'
import {
	DataTable,
	TableBody,
	TableColumn,
	TableHeader,
	TableRow,
} from 'react-md'
import { formatCurrency } from 'util/formatStrings'
import { billingBasisMessage, unitMessage } from '../utils/messages'
import type { InvoicePaymentProduct } from './types'

type Props = {
	invoicePaymentProducts: InvoicePaymentProduct[]
}

function InvoicePaymentProductsTable({ invoicePaymentProducts = [] }: Props) {
	const intl = useIntl()

	return (
		<DataTable plain>
			<TableHeader>
				<TableRow>
					<TableColumn>
						{intl.formatMessage({
							defaultMessage: 'Nimike',
							description: 'Table header for invoice payment product name.',
						})}
					</TableColumn>
					<TableColumn>
						{intl.formatMessage({
							defaultMessage: 'Hinta',
							description: 'Table header for invoice payment product price.',
						})}
					</TableColumn>
					<TableColumn>
						{intl.formatMessage({
							defaultMessage: 'Hinta + alv',
							description:
								'Table header for invoice payment product price with alv.',
						})}
					</TableColumn>
					<TableColumn>
						{intl.formatMessage({
							defaultMessage: 'Määrä',
							description: 'Table header for invoice payment product amount.',
						})}
					</TableColumn>
					<TableColumn>
						{intl.formatMessage({
							defaultMessage: 'Veloitusperuste',
							description:
								'Table header for invoice payment product billing basis.',
						})}
					</TableColumn>
					<TableColumn>
						{intl.formatMessage({
							defaultMessage: 'Yksikkö',
							description: 'Table header for invoice payment product unit.',
						})}
					</TableColumn>
					<TableColumn>
						{intl.formatMessage({
							defaultMessage: 'Summa',
							description: 'Table header for invoice payment product total.',
						})}
					</TableColumn>
					<TableColumn>
						{intl.formatMessage({
							defaultMessage: 'Summa + alv',
							description:
								'Table header for invoice payment product total with vat.',
						})}
					</TableColumn>
				</TableRow>
			</TableHeader>
			<TableBody>
				<>
					{invoicePaymentProducts.map(
						({
							uuid,
							name,
							price,
							priceWithVat,
							quantity,
							billingBasis,
							total,
							totalWithVat,
						}) => (
							<TableRow key={uuid}>
								<TableColumn>{name}</TableColumn>
								<TableColumn>{formatCurrency(price)}</TableColumn>
								<TableColumn>{formatCurrency(priceWithVat)}</TableColumn>
								<TableColumn>{quantity}</TableColumn>
								<TableColumn>
									{billingBasisMessage(intl, billingBasis)}
								</TableColumn>
								<TableColumn>{unitMessage(intl, billingBasis)}</TableColumn>
								<TableColumn>{formatCurrency(total)}</TableColumn>
								<TableColumn>{formatCurrency(totalWithVat)}</TableColumn>
							</TableRow>
						),
					)}
					<TableRow key={'total'}>
						<TableColumn>
							<b>
								{intl.formatMessage({
									defaultMessage: 'Yhteensä',
									description: 'Table column for invoice total.',
								})}
							</b>
						</TableColumn>
						<TableColumn></TableColumn>
						<TableColumn></TableColumn>
						<TableColumn></TableColumn>
						<TableColumn></TableColumn>
						<TableColumn></TableColumn>
						<TableColumn>
							<b>
								{formatCurrency(
									invoicePaymentProducts.reduce(
										(acc, { total }) => acc + total,
										0,
									),
								)}
							</b>
						</TableColumn>
						<TableColumn>
							<b>
								{formatCurrency(
									invoicePaymentProducts.reduce(
										(acc, { totalWithVat }) => acc + totalWithVat,
										0,
									),
								)}
							</b>
						</TableColumn>
					</TableRow>
				</>
			</TableBody>
		</DataTable>
	)
}

export default InvoicePaymentProductsTable
