import { useSelector } from 'react-redux'
import {
	isLoaded,
	useFirestoreConnect,
	FirestoreReducer,
} from 'react-redux-firebase'
import type { Apartment } from 'types/apartment'

type FirestoreStateReducer = FirestoreReducer.Reducer

// TODO: Move this to some generic hook folder
export const useApartment = (apartmentUUID_?: string | null) => {
	const apartmentUUID = apartmentUUID_ || 'no-value'
	useFirestoreConnect([
		{
			collection: 'apartment',
			storeAs: `apartment_${apartmentUUID}`,
			where: [['uuid', '==', apartmentUUID]],
			limit: 1,
		},
	])

	return useSelector(
		({ firestoreReducer }: { firestoreReducer: FirestoreStateReducer }) => {
			if (!firestoreReducer.ordered) {
				return { loading: true, apartment: null }
			}

			const apartment = firestoreReducer.ordered[
				`apartment_${apartmentUUID}`
			] as Apartment[]

			if (!isLoaded(apartment)) {
				return { loading: true, apartment: null }
			}
			return { loading: false, apartment: apartment[0] ?? null }
		},
	)
}
