import moment from 'moment-timezone'
import { Invoice } from 'ui/ManagerFinance/Invoices/types'

export const formatDate = (date: moment.MomentInput) => {
	if (!date) {
		return ''
	}
	return moment(date).format('D.M.YYYY')
}

export const formatCurrency = (value: number | undefined | null) => {
	if (value === undefined || value === null) {
		return ''
	}
	return new Intl.NumberFormat('fi', {
		style: 'currency',
		currency: 'EUR',
	}).format(value)
}

export const formatInvoiceNumber = (invoice: Invoice) => {
	if (!invoice.invoiceNumber) {
		return ''
	}
	if (invoice.invoiceNumberPrefix) {
		return `${invoice.invoiceNumberPrefix}-${invoice.invoiceNumber}`
	}
	return invoice.invoiceNumber
}
