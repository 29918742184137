import ViiluDialog from 'ui/components/ViiluDialog'
import { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import {
	TextField,
	TextFieldTypes,
	CircularProgress,
	SelectField,
} from 'react-md'
import { useDispatch } from 'react-redux'
import useDialogs from 'ui/components/dialogs/useDialogs'
import { GENERIC_ERROR_NOTIFICATION } from 'ui/messages'
import * as notifyActions from 'state/notifyActions'
import type { CreateWebInvoiceAddress, WebInvoiceAddress } from './types'
import { webInvoiceHeaders } from './constants'
import {
	_createWebInvoiceAddressSettings,
	_updateWebInvoiceAddressSettings,
} from 'state/settings-actions'
import useWebInvoiceAddress from './useWebInvoiceAddress'
import { Direction, Operator } from '../enum'
import type { UseWebInvoiceAddressesResult } from './useWebInvoiceAddresses'
import type { SelectOptions } from '../types'
import { directionMessage } from '../utils/messages'

type Props = {
	id?: string | null
	useWebInvoiceAddresses: UseWebInvoiceAddressesResult
	visible: boolean
	onHide: () => void
	selectedFinanceCompanyUUID: string
}

const WebInvoiceDialog = ({
	id,
	useWebInvoiceAddresses,
	visible,
	onHide,
	selectedFinanceCompanyUUID,
}: Props) => {
	const { refreshWebInvoiceAddresses } = useWebInvoiceAddresses
	const intl = useIntl()
	const dispatch = useDispatch()
	const { webInvoiceAddress } = useWebInvoiceAddress(id)

	const initialFormData: CreateWebInvoiceAddress | WebInvoiceAddress = {
		companyUUID: selectedFinanceCompanyUUID,
		address: 0,
		operator: '',
		direction: Direction.INVOICE,
	}

	const operatorOptions: SelectOptions[] = Object.keys(Operator).map((key) => ({
		label: Operator[key],
		value: key,
	}))

	const directionOptions: SelectOptions[] = Object.values(Direction).map(
		(value) => ({
			label: directionMessage(intl, value),
			value: value,
		}),
	)

	const [formData, setFormData] = useState<
		CreateWebInvoiceAddress | WebInvoiceAddress
	>(initialFormData)

	const [processing, setProcessing] = useState(false)
	const { alert } = useDialogs()
	const resetFormData = () => {
		setFormData(initialFormData)
	}

	useEffect(() => {
		if (webInvoiceAddress) {
			setFormData({
				uuid: webInvoiceAddress.uuid,
				companyUUID: webInvoiceAddress.companyUUID,
				address: Number(webInvoiceAddress.address),
				operator: webInvoiceAddress.operator,
				direction: webInvoiceAddress.direction,
			})
		}
	}, [webInvoiceAddress])

	useEffect(() => {
		setFormData((prev) => ({
			...prev,
			companyUUID: selectedFinanceCompanyUUID,
		}))
	}, [selectedFinanceCompanyUUID])

	const handleInputChange = (
		value: string | number | boolean,
		field: string,
		type: string,
	) => {
		setFormData((prevData) => ({
			...prevData,
			[field]: type === 'number' ? Number(value) : value,
		}))
	}

	const validate = () => {
		let isValid = true

		webInvoiceHeaders.forEach((header) => {
			if (header.show && header.required) {
				if (isValid) {
					isValid = formData[header.key]
				}
			}
		})

		if (!formData.companyUUID) {
			isValid = false
		}

		return isValid
	}

	const hide = () => {
		resetFormData()
		onHide()
	}

	const handleSave = async () => {
		const result: any = await _createWebInvoiceAddressSettings({
			...(formData as WebInvoiceAddress),
		})
		setProcessing(false)
		if (!result || result.ok === false) {
			dispatch(notifyActions.info('❌ ' + GENERIC_ERROR_NOTIFICATION(intl)))
			return false
		}

		dispatch(
			notifyActions.info(
				'✅ ' +
					intl.formatMessage({
						defaultMessage: 'Verkkolaskuosoite on lisätty.',
						description:
							'Notification text of web invoice address has been added',
					}),
			),
		)
		refreshWebInvoiceAddresses()
		hide()
		return true
	}

	const handleUpdate = async () => {
		const result: any = await _updateWebInvoiceAddressSettings({
			...(formData as WebInvoiceAddress),
		})
		setProcessing(false)
		if (!result || result.ok === false) {
			dispatch(notifyActions.info('❌ ' + GENERIC_ERROR_NOTIFICATION(intl)))
			return false
		}

		dispatch(
			notifyActions.info(
				'✅ ' +
					intl.formatMessage({
						defaultMessage: 'Verkkolaskuosoite on päivitetty.',
						description:
							'Notification text of web invoice address has been updated',
					}),
			),
		)
		refreshWebInvoiceAddresses()
		hide()
		return true
	}

	const onProcess = async () => {
		const isValid = validate()
		if (!isValid) {
			const message = intl.formatMessage({
				defaultMessage: 'Täytä kaikki vaaditut tiedot.',
				description:
					'Error message for missing web invoice address input values.',
			})
			alert(message)
			return
		}

		setProcessing(true)
		if ((formData as WebInvoiceAddress).uuid) {
			return await handleUpdate()
		} else return await handleSave()
	}

	const renderFormFields = () => {
		return webInvoiceHeaders.map((header) => {
			const { show, key, title, type, required } = header
			if (show) {
				if (key === 'operator') {
					return (
						<div key={key} style={{ marginTop: '15px' }}>
							<p className="text-subtle">{title(intl)}</p>
							<SelectField
								id={key}
								key={key}
								placeholder={title(intl)}
								value={formData[key] || ''}
								onChange={(value) => handleInputChange(value, key, type)}
								menuItems={operatorOptions}
								position={SelectField.Positions.BELOW}
								simplifiedMenu={false}
								listHeightRestricted
								style={{
									width: '100%',
									background: '#fafafa',
									marginBottom: '10px',
								}}
							/>
						</div>
					)
				} else if (key === 'direction') {
					return (
						<div key={key}>
							<p className="text-subtle">{title(intl)}</p>
							<SelectField
								id={key}
								key={key}
								placeholder={title(intl)}
								value={formData[key] || ''}
								onChange={(value) => handleInputChange(value, key, type)}
								menuItems={directionOptions}
								position={SelectField.Positions.BELOW}
								simplifiedMenu={false}
								listHeightRestricted
								style={{
									width: '100%',
									background: '#fafafa',
									marginBottom: '10px',
								}}
							/>
						</div>
					)
				} else {
					return (
						<TextField
							key={key}
							id={key}
							label={title(intl)}
							lineDirection="center"
							floating
							fullWidth
							value={formData[key] || ''}
							onChange={(value) => handleInputChange(value, key, type)}
							required={required}
							type={(type as TextFieldTypes) || undefined}
						/>
					)
				}
			}
			return null
		})
	}

	const renderChildren = () => {
		return (
			<div
				className="margin-left--lg margin-right--lg"
				style={{ paddingBottom: 64 }}
			>
				{renderFormFields()}
			</div>
		)
	}

	const actions = [
		{
			id: 'dialog-cancel',
			children: intl.formatMessage({
				defaultMessage: 'Peruuta',
				description: 'Cancel button',
			}),
			onClick: hide,
		},
		{
			id: 'dialog-ok',
			secondary: true,
			children: processing ? (
				<CircularProgress id="progress" style={{ marginTop: 0 }} />
			) : (
				intl.formatMessage({
					defaultMessage: 'Tallenna',
					description: 'Save button',
				})
			),
			onClick: onProcess,
		},
	]

	return (
		<ViiluDialog
			id="responsive-dialog"
			title={
				id
					? intl.formatMessage({
							defaultMessage: 'Muokkaa verkkolaskuosoitetta',
							description: 'Title of edit web invoice address',
					  })
					: intl.formatMessage({
							defaultMessage: 'Lisää verkkolaskuosoite',
							description: 'Title of add web invoice address',
					  })
			}
			visible={visible}
			actions={actions}
			onHide={hide}
			focusOnMount={true}
			containFocus={true}
			dialogClassName="responsive-dialog"
			contentClassName="responsive-dialog-content"
			autosizeContent={false}
			modal
			portal
			paddedContent
		>
			{renderChildren()}
		</ViiluDialog>
	)
}

export default WebInvoiceDialog
