import { injectIntl } from 'react-intl'
import { FontIcon } from 'react-md'
import { compose } from 'redux'
import {
	SummaryList,
	SummaryListItem,
} from 'ui/MessageService/MessageServiceProcess/steps/Sending/steps/Summary/styles'

function BulletinsInfo({ messageServiceMassProcess, intl }) {
	const hasBulletins =
		messageServiceMassProcess.deliveryMethods.includes('bulletins')
	const hasInfoscreen =
		messageServiceMassProcess.deliveryMethods.includes('infoscreen')

	if (!hasBulletins && !hasInfoscreen) {
		return null
	}

	return (
		<>
			<SummaryList>
				{hasBulletins && (
					<SummaryListItem>
						<FontIcon>dashboard</FontIcon>
						<span>
							{intl.formatMessage({
								defaultMessage: 'Ilmoitustauluille',
								description:
									'Bulletinboard info label in the message service summary step.',
							})}
						</span>
					</SummaryListItem>
				)}
				{hasInfoscreen && (
					<SummaryListItem>
						<FontIcon>dashboard</FontIcon>
						<span>
							{intl.formatMessage({
								defaultMessage: 'Porrasnäytöille',
								description:
									'Infoscreen info label in the message service summary step.',
							})}
						</span>
					</SummaryListItem>
				)}
			</SummaryList>
		</>
	)
}

export default compose(injectIntl)(BulletinsInfo)
