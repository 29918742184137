import { injectIntl } from 'react-intl'
import { compose } from 'redux'
import styled from 'styled-components'
import WithSelectedCompanyManager from 'ui/components/WithSelectedCompanyManager'

const Container = styled.div`
	display: flex;
	justify-content: center;
	height: 100%;
`

const Letter = styled.div`
	max-height: 1100px;
	width: 100%;
	max-width: 800px;
	background: #fff;
	border-radius: 2px;
	box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
	padding: 48px 56px;
`

const Window = styled.div`
	background: #fff;
	border-radius: 8px;
	box-shadow: inset 0px 0px 6px 0px rgba(0, 0, 0, 0.5);
	padding: 16px 24px;
	width: 280px;
`

const PostalInfo = styled.ul`
	list-style: none;
	margin: 0;
	padding: 0;
`

const Sender = styled(PostalInfo)`
	margin-bottom: 16px;
	font-size: 0.9rem;
`

const Recipient = styled(PostalInfo)`
	font-size: 1rem;
`

const PreviewText = styled.div`
	display: flex;
	justify-content: center;

	span {
		margin-top: 100px;
		color: #bdbdbd;
		font-size: 4rem;
	}
`

const DUMMY_RECIPIENT = {
	name: 'Ville Vastaanottaja',
	address: 'Kokouskuja 2',
	area: 'HELSINKI',
	zip: '00100',
	country: 'FI',
}

const formatZipArea = (intl, sender) => {
	const zipPreview =
		sender.zip ||
		intl.formatMessage({
			defaultMessage: 'Postinumero',
			description:
				'Placeholder for the postal sender zip code preview in the general sender component.',
		})

	const areaPreview =
		sender.area ||
		intl.formatMessage({
			defaultMessage: 'Postitoimipaikka',
			description:
				'Placeholder for the postal sender area preview in the general sender component.',
		})

	return `${zipPreview} ${areaPreview.toUpperCase()}`
}

function Preview({ sender, intl }) {
	return (
		<Container>
			<Letter>
				<Window>
					<Sender>
						<li>
							{sender.name ||
								intl.formatMessage({
									defaultMessage: 'Lähettäjän nimi',
									description:
										'Placeholder for the postal sender name preview in the general sender component.',
								})}
						</li>
						<li>
							{sender.address ||
								intl.formatMessage({
									defaultMessage: 'Katuosoite',
									description:
										'Placeholder for the postal sender street address preview in the general sender component.',
								})}
						</li>
						<li>{formatZipArea(intl, sender)}</li>
					</Sender>
					<Recipient>
						<li>{DUMMY_RECIPIENT.name}</li>
						<li>{DUMMY_RECIPIENT.address}</li>
						<li>{formatZipArea(intl, DUMMY_RECIPIENT)}</li>
					</Recipient>
				</Window>
				<PreviewText>
					<span>
						{intl.formatMessage({
							defaultMessage: 'Esikatselu',
							description:
								'Overlay text for the postal sender information preview in the general sender component.',
						})}
					</span>
				</PreviewText>
			</Letter>
		</Container>
	)
}

export default compose(injectIntl, WithSelectedCompanyManager)(Preview)
