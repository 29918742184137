import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
	CardTitle,
	CircularProgress,
	CardActions,
	FontIcon,
	CardText,
} from 'react-md'

import * as fivaldiActions from 'state/fivaldi-actions'
import * as notifyActions from 'state/notifyActions'

import WithSelectedManagerRole from 'ui/components/WithSelectedManagerRole'
import WithUserRoles from 'ui/components/userRoles/WithUserRoles'
import { StandardCard } from 'ui/StyledComponents/Cards'
import { ProgressIndicatorButton } from 'ui/StyledComponents/Buttons'
import { GENERIC_ERROR_NOTIFICATION } from 'ui/messages'
import { injectIntl } from 'react-intl'
import WithDialogs from 'ui/components/dialogs/WithDialogs'

class Fivaldi extends Component {
	state = {
		processingEnabled: false,
		processingCredentials: false,
	}

	isMaster = () => {
		const { managerRole, isSuperAdmin } = this.props
		return managerRole === 'manager_master' || isSuperAdmin
	}

	setEnabled = async (enabled) => {
		const { _fivaldiSetEnabled, _showInfoNotification, selectedManager, intl } =
			this.props

		this.setState({ processingEnabled: true })
		const result = await _fivaldiSetEnabled(selectedManager.uuid, enabled)
		this.setState({ processingEnabled: false })

		if (!result || result.success === false) {
			_showInfoNotification('❌ ' + GENERIC_ERROR_NOTIFICATION(intl))
			return false
		}

		_showInfoNotification('✅ Asetukset tallennettu.')
		return true
	}

	setCredentials = async () => {
		const {
			_fivaldiSetCredentials,
			_showInfoNotification,
			selectedManager,
			intl,
			prompt,
		} = this.props

		const account = await prompt('Syötä Fivaldin rajapinnan ASIAKASNIMI.', {
			title: 'Tunnusasetukset',
		})
		if (!account) {
			return
		}

		this.setState({ processingCredentials: true })
		const result = await _fivaldiSetCredentials(selectedManager.uuid, account)
		this.setState({ processingCredentials: false })

		if (!result || result.success === false) {
			_showInfoNotification('❌ ' + GENERIC_ERROR_NOTIFICATION(intl))
			return false
		}

		_showInfoNotification('✅ Asetukset tallennettu.')
		return true
	}

	render() {
		const { selectedManager } = this.props
		const { processingEnabled, processingCredentials } = this.state

		const enabled =
			selectedManager.integrations &&
			selectedManager.integrations.fivaldi &&
			selectedManager.integrations.fivaldi.enabled

		return (
			<div className="full-width">
				<StandardCard
					className="margin-bottom full-width"
					style={{
						background: enabled ? 'rgb(240,255,240)' : undefined,
					}}
				>
					<CardTitle title={'Fivaldi-rajapinta'} />
					<CardText>
						Voit ottaa Fivaldi-integraation käyttöön, joka mahdollistaa
						esimerkiksi kohde- ja huoneistotietojen tuonnin suoraan rajapinnan
						kautta.
					</CardText>
					<CardActions>
						<ProgressIndicatorButton
							flat
							secondary
							onClick={() => this.setEnabled(!enabled)}
							disabled={processingEnabled || !this.isMaster()}
							style={{ color: 'var(--color-secondary)' }}
						>
							{processingEnabled ? (
								<CircularProgress
									id="progress"
									style={{ paddingLeft: 0, marginTop: 0 }}
								/>
							) : enabled ? (
								'POISTA KÄYTÖSTÄ'
							) : (
								'OTA KÄYTTÖÖN'
							)}
						</ProgressIndicatorButton>
						{enabled ? (
							<ProgressIndicatorButton
								flat
								secondary
								onClick={this.setCredentials}
								disabled={processingCredentials || !this.isMaster()}
								iconEl={
									processingCredentials ? null : (
										<FontIcon>manage_accounts</FontIcon>
									)
								}
								style={{ color: 'var(--color-secondary)' }}
							>
								{processingCredentials ? (
									<CircularProgress
										id="progress"
										style={{ paddingLeft: 0, marginTop: 0 }}
									/>
								) : (
									'TUNNUSASETUKSET'
								)}
							</ProgressIndicatorButton>
						) : null}
					</CardActions>
				</StandardCard>
			</div>
		)
	}
}

Fivaldi.propTypes = {}

const mapState = ({ firebaseReducer: { auth } }) => ({
	auth,
})

const mapDispatchToProps = (dispatch) => ({
	_fivaldiSetEnabled: bindActionCreators(fivaldiActions._setEnabled, dispatch),
	_fivaldiSetCredentials: bindActionCreators(
		fivaldiActions._setCredentials,
		dispatch,
	),
	_showInfoNotification: bindActionCreators(notifyActions.info, dispatch),
})

export default compose(
	injectIntl,
	WithDialogs,
	WithSelectedManagerRole,
	WithUserRoles,
	connect(mapState, mapDispatchToProps),
)(Fivaldi)
