import styled from 'styled-components'

const StatusContainer = styled.div`
	display: flex;
	justify-content: stretch;
	margin-bottom: 16px;
`

const Icon = styled.div`
	margin: 16px;
	width: 12px;
	height: 12px;
	border-radius: 50%;
	background-color: ${(props) => props.iconColor || 'black'};
`

const Details = styled.div`
	padding: 8px;
	flex: 1;
`

const Header = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 4px;
	color: ${(props) => props.iconColor || 'black'};
`

function SMSStatusItem({ details }) {
	return (
		<StatusContainer>
			<Icon iconColor={details.iconColor} />
			<Details>
				<Header>
					<p
						style={{ fontWeight: 'bold', color: details.iconColor || 'black' }}
					>
						{details.title}
					</p>
					<p style={{ opacity: 0.5 }}>{details.timestamp}</p>
				</Header>
				<p>{details.message}</p>
				{details.errorMessage && (
					<p style={{ marginTop: 8 }}>{details.errorMessage}</p>
				)}
			</Details>
		</StatusContainer>
	)
}

export default SMSStatusItem
