import { MenuButton, TableColumn, TableRow, CircularProgress } from 'react-md'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import type { AccountsLedger, AccountsLedgerFilters } from './types'
import { accountsLedgerHeaders } from './constants'
import { Building } from 'types/building'
import { makeAparmentLabel } from '../utils/apartmentLabel'
import type { Apartment } from 'types/apartment'
import { AccountsLedgerGroupBy } from '../enum'

type Props = {
	accountLedger: AccountsLedger
	menuItems: any[] | null
	children: any
	apartments: Apartment[]
	buildings: Building[]
	processing: any
	filters: AccountsLedgerFilters
}

const AccountsLedgerRow = ({
	accountLedger,
	menuItems,
	children,
	apartments,
	buildings,
	processing,
	filters,
}: Props) => {
	const dispatch = useDispatch()
	const appendedMenuItems = menuItems ? [...menuItems] : null

	const menu = !appendedMenuItems ? null : (
		<MenuButton
			id={'menu-button'}
			icon
			menuItems={appendedMenuItems}
			centered
			anchor={{
				x: MenuButton.HorizontalAnchors['CENTER'],
				y: MenuButton.VerticalAnchors['CENTER'],
			}}
		>
			more_vert
		</MenuButton>
	)
	const getRowValue = (key: string, type: string | undefined) => {
		let value = accountLedger[key]

		switch (type) {
			case 'number':
				return Number(value.toFixed(2))
			case 'apartmentIdentifier':
				const { apartmentIdentifier, apartmentUUID } = accountLedger
				const buildingUUID = apartments?.find(
					(a) => a.uuid === apartmentUUID,
				)?.buildingUUID
				const buildingName = buildings?.find(
					(b) => b.uuid === buildingUUID,
				)?.name
				return makeAparmentLabel(apartmentIdentifier, buildingName)
			case 'balance':
				if (value < 0) {
					return {
						value,
						color: 'red',
					}
				}
				if (value > 0) {
					return {
						value,
						color: 'green',
					}
				}
				return value
			case 'string':
				return value ?? ''
			case 'identifier':
				if (filters.groupBy === AccountsLedgerGroupBy.CONTRACT) {
					return accountLedger.uuid
				} else if (filters.groupBy === AccountsLedgerGroupBy.PARTY) {
					return accountLedger.partyName
				} else return
			default:
				return value
		}
	}

	const handleOnClick = () => {
		const params = new URLSearchParams()
		params.set('uuid', accountLedger.uuid)
		params.set('view', filters.view)
		params.set('groupBy', filters.groupBy)

		if (filters.startDate) {
			params.set('startDate', filters.startDate)
		}

		if (filters.endDate) {
			params.set('endDate', filters.endDate)
		}

		dispatch(push(`${window.location.pathname}?${params.toString()}`))
	}

	const renderTableColumns = () => {
		return accountsLedgerHeaders
			.filter((h) => h.show)
			.map((header) => {
				let value = getRowValue(header.key, header?.type)
				let color = undefined
				if (typeof value === 'object') {
					color = value.color
					value = value.value
				}
				return (
					<TableColumn onClick={handleOnClick} key={header.key}>
						<p style={{ color }}>{value}</p>
					</TableColumn>
				)
			})
	}
	return (
		<TableRow key={accountLedger.uuid}>
			{renderTableColumns()}
			<TableColumn key={'children'}>
				<div>
					{processing ? (
						<CircularProgress id="progress" style={{ marginTop: 0 }} />
					) : (
						menu
					)}
					{children}
				</div>
			</TableColumn>
		</TableRow>
	)
}

export default AccountsLedgerRow
