import { backendFunction } from './actions-util'
import { getAuth, getIdToken } from 'firebase/auth'
import ReactGA from 'react-ga4'
import { firebaseApp } from './store'
import config from '../config'

export const _createProcess = async ({
	companyUUID,
	title,
	body,
	bodyText,
	attachments,
	deliveryMethods,
	generator,
	generatorDataUUID,
	reportingDocumentType,
	recipients,
}) =>
	await backendFunction('messageServiceProcessCreate', {
		companyUUID,
		title,
		body,
		bodyText,
		attachments,
		deliveryMethods,
		generator,
		generatorDataUUID,
		reportingDocumentType,
		recipients,
	})

export const _createMassProcess =
	({ managerUUID }) =>
	async (dispatch, getState) =>
		backendFunction('messageServiceMassProcessCreate', {
			managerUUID,
		})

export const _createMassJobs =
	(messageServiceMassProcess, letterPageCount = null) =>
	async (dispatch, getState) =>
		backendFunction(
			'messageServiceMassJobsCreate',
			{
				messageServiceMassProcess,
				letterPageCount,
			},
			{
				timeout: 360000,
			},
		)

export const _sendMassJobs =
	(messageServiceMassProcessUUID) => async (dispatch, getState) =>
		backendFunction(
			'messageServiceMassJobsSend',
			{
				messageServiceMassProcessUUID,
			},
			{
				timeout: 360000,
			},
		)

export const _updateProcess =
	(messageServiceProcess) => async (dispatch, getState) =>
		backendFunction('messageServiceProcessUpdate', {
			messageServiceProcess,
		})

export const _updateMassProcess =
	(messageServiceMassProcess) => async (dispatch, getState) =>
		backendFunction('messageServiceMassProcessUpdate', {
			messageServiceMassProcess,
		})

export const _deleteProcess =
	(companyUUID, processUUID) => async (dispatch, getState) =>
		backendFunction('messageServiceProcessDelete', {
			companyUUID,
			processUUID,
		})

export const _deleteMassProcess =
	(managerUUID, processUUID) => async (dispatch, getState) =>
		backendFunction('messageServiceMassProcessDelete', {
			managerUUID,
			processUUID,
		})

export const _sendAll =
	(companyUUID, processUUID, letterPageCount) => async (dispatch, getState) =>
		backendFunction('messageServiceSendAll', {
			companyUUID,
			processUUID,
			letterPageCount,
		})

export const _uploadFile = (processUUID, files, isMassSending = false) =>
	async function (dispatch, getState, getFirebase) {
		ReactGA.event({
			category: 'feature-usage',
			action: 'message-process-upload-file',
		})

		try {
			// Done with fetch and FormData because firebase callable functions don't support file uploads
			const formData = new FormData()
			formData.append('processUUID', processUUID)
			files.forEach((file) => {
				formData.append(file.name, file)
			})
			formData.append('isMassSending', isMassSending)

			const token = await getIdToken(getAuth(firebaseApp).currentUser)

			const fetchOptions = {
				functionURL:
					'https://messageserviceuploadfile-ekw3uy5dmq-ew.a.run.app/',
				credentials: 'include',
			}

			if (config.functions.baseURL.includes('localhost')) {
				fetchOptions.functionURL = `${config.functions.baseURL}messageserviceuploadfile/`
				fetchOptions.credentials = 'same-origin'
			}

			const response = await fetch(fetchOptions.functionURL, {
				method: 'POST',
				body: formData,
				credentials: fetchOptions.credentials,
				headers: {
					Authorization: 'Bearer ' + token,
				},
			})

			const resJson = await response.json()

			if (response.ok) {
				return resJson
			} else {
				throw new Error(resJson.error)
			}
		} catch (ex) {
			console.log(ex)
			if (window.sentry) window.sentry.captureException(ex)
			return { success: false, ex }
		}
	}

export const _createIntegration = async (companyUUID, integration) =>
	backendFunction('messageServiceIntegrationCreate', {
		companyUUID,
		integration,
	})

export const _updateIntegration = async (integration) =>
	backendFunction('messageServiceIntegrationUpdate', {
		integration,
	})

export const _deleteIntegration = async (uuid) =>
	backendFunction('messageServiceIntegrationDelete', {
		uuid,
	})
