import {
	Button,
	DataTable,
	FontIcon,
	TableBody,
	TableColumn,
	TableHeader,
	TableRow,
	TextField,
} from 'react-md'
import type { Invoice, InvoiceAllocations } from './types'
import { useIntl } from 'react-intl'
import { _updateInvoicePaymentAllocations } from 'state/finance-invoice-actions'
import { error } from 'state/notifyActions'
import { useState } from 'react'
import type { CreateAllocations } from '../Payments/types'
import { removeLeadingZero } from '../utils/utils'
import {
	CANCEL_BUTTON_LABEL,
	GENERIC_ERROR_NOTIFICATION,
	SAVE_BUTTON_LABEL,
} from 'ui/messages'
import useDialogs from 'ui/components/dialogs/useDialogs'
import { useDispatch } from 'react-redux'
import { formatCurrency } from 'util/formatStrings'

type Props = {
	invoice: Invoice
	invoiceAllocations: InvoiceAllocations[]
	refreshInvoice: () => void
	onStopEditing: () => void
}

function InvoicePaymentAllocationEditDialog({
	invoice,
	invoiceAllocations,
	refreshInvoice,
	onStopEditing,
}: Props) {
	const intl = useIntl()

	const { alert } = useDialogs()
	const dispatch = useDispatch()
	const showErrorNotification = () => {
		dispatch(error('❌ ' + GENERIC_ERROR_NOTIFICATION(intl)))
	}

	const [processing, setProcessing] = useState(false)

	const initialAllocations = invoiceAllocations.map((ia) => ({
		uuid: ia.uuid,
		invoice: invoice,
		invoiceUUID: ia.invoiceUUID,
		paymentUUID: ia.paymentUUID,
		total: ia.total,
	}))

	const [allocations, setAllocations] =
		useState<CreateAllocations[]>(initialAllocations)

	const allocationsTotal = allocations.reduce((acc, cur) => acc + cur.total, 0)

	const paymentsByInvoiceUUID = invoiceAllocations.reduce(
		(acc, cur) => acc.set(cur.uuid, cur.payment),
		new Map(),
	)

	const removeAllocation = (uuid: string) => {
		setAllocations((prev) => {
			const newAllocations = prev.filter((a) => a.uuid !== uuid)
			return newAllocations
		})
	}
	const setAllocationTotal = (uuid: string, total: number) => {
		setAllocations((prev) =>
			prev.map((a) => {
				if (a.uuid === uuid) {
					return {
						...a,
						total,
					}
				}
				return a
			}),
		)
	}

	const validateAllocationTotal = () => {
		if (allocationsTotal > invoice.total) {
			const message = intl.formatMessage({
				defaultMessage:
					'Kohdistettujen laskujen summa on suurempi kuin laskun summa.',
				description:
					'Error message for invalid values in the finance invoice payment allocation edit form.',
			})
			alert(message)
			return false
		}
		return true
	}

	const handleUpdate = async () => {
		if (!validateAllocationTotal()) {
			return
		}
		const allocationsUpdate = allocations.map((a) => {
			return {
				...a,
				total: Number(a.total),
			}
		})
		setProcessing(true)
		try {
			const result = await _updateInvoicePaymentAllocations(
				invoice.uuid,
				allocationsUpdate,
			)
			if (result?.ok) {
				refreshInvoice()
				onStopEditing()
			} else {
				throw new Error('Response is not ok.')
			}
		} catch (error) {
			console.error(error)
			showErrorNotification()
			setProcessing(false)
		}
	}

	return (
		<div>
			<DataTable plain>
				<TableHeader>
					<TableRow>
						<TableColumn>
							{intl.formatMessage({
								defaultMessage: 'Maksaja',
								description:
									'Table header for "payer name" in invoice payment allocation edit dialog',
							})}
						</TableColumn>
						<TableColumn>
							{intl.formatMessage({
								defaultMessage: 'Arkistotunnus',
								description:
									'Table header for "archive id" in invoice payment allocation edit dialog',
							})}
						</TableColumn>
						<TableColumn>
							{intl.formatMessage({
								defaultMessage: 'Kohdistettavissa oleva summa',
								description:
									'Table header for "allocateable amount" in invoice payment allocation edit dialog',
							})}
						</TableColumn>
						<TableColumn>
							{intl.formatMessage({
								defaultMessage: 'Kohdistettu summa',
								description:
									'Table header for "allocated amount" in invoice payment allocation edit dialog',
							})}
						</TableColumn>
					</TableRow>
				</TableHeader>
				<TableBody>
					{allocations.map((allocation) => {
						const { uuid, total } = allocation
						const {
							archiveId,
							partyName,
							total: paymentTotal,
						} = paymentsByInvoiceUUID.get(uuid)
						return (
							<TableRow key={uuid}>
								<TableColumn>{partyName}</TableColumn>
								<TableColumn>{archiveId}</TableColumn>
								<TableColumn>{formatCurrency(paymentTotal)}</TableColumn>
								<TableColumn>
									<TextField
										id={'allocation-total-input' + uuid}
										type="number"
										disabled={processing}
										min={0}
										required
										value={removeLeadingZero(total)}
										onChange={(value) =>
											setAllocationTotal(
												uuid!,
												Number(removeLeadingZero(value)),
											)
										}
									/>
								</TableColumn>
								<TableColumn>
									<Button
										disabled={processing}
										onClick={() => removeAllocation(uuid!)}
									>
										<FontIcon>delete</FontIcon>
									</Button>
								</TableColumn>
							</TableRow>
						)
					})}
				</TableBody>
			</DataTable>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					width: '100%',
					gap: '0.5rem',
				}}
			>
				<div
					className="text-subtle"
					style={{
						display: 'flex',
						width: '100%',
						gap: '1rem',
						justifyContent: 'flex-end',
					}}
				>
					<div>
						{intl.formatMessage({
							defaultMessage: 'Kohdistettu yhteensä',
							description:
								'Label for total of invoice allocations in invoice allocaiton edit dialog',
						})}
						: {formatCurrency(allocationsTotal)}
					</div>
				</div>
				<div
					style={{
						display: 'flex',
						width: '100%',
						justifyContent: 'flex-end',
					}}
				>
					<Button disabled={processing} flat onClick={() => onStopEditing()}>
						{CANCEL_BUTTON_LABEL(intl)}
					</Button>
					<Button
						disabled={processing}
						flat
						secondary
						onClick={() => handleUpdate()}
					>
						{SAVE_BUTTON_LABEL(intl)}
					</Button>
				</div>
			</div>
		</div>
	)
}

export default InvoicePaymentAllocationEditDialog
