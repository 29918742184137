import { backendFunction } from './actions-util'

export const _update =
	({
		uuid,
		name,
		account,
		businessId,
		email,
		logoURL,
		provider,
		commission,
		forceStaging,
		providerGroupWhitelist,
		providerGroupMinimumOrder,
		invoicingProvider,
		invoicingCreditorId,
		invoicingPaymentPeriod,
		invoicingFee,
		invoicingSettlementAccount,
		invoicingSettlementBic,
		invoicingFactoringProduct,
	}) =>
	async (dispatch, getState) =>
		backendFunction('merchantUpdate', {
			uuid,
			name,
			account,
			businessId,
			email,
			logoURL,
			provider,
			commission,
			forceStaging,
			providerGroupWhitelist,
			providerGroupMinimumOrder,
			invoicingProvider,
			invoicingCreditorId,
			invoicingPaymentPeriod,
			invoicingFee,
			invoicingSettlementAccount,
			invoicingSettlementBic,
			invoicingFactoringProduct,
		})

export const _delete =
	({ uuid }) =>
	async (dispatch, getState) =>
		backendFunction('merchantDelete', {
			uuid,
		})
