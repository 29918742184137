import React from 'react'
import { bindActionCreators, compose } from 'redux'
import { SelectField } from 'react-md'
import { push } from 'connected-react-router'
import { connect } from 'react-redux'

import * as appActions from 'state/appActions'

import WithSelectedManagerCompanies from './components/WithSelectedManagerCompanies'
import SearchFieldDropdown from './components/SearchFieldDropdown'
import WithSelectedCompany from './components/WithSelectedCompany'

const ManagerCompanyQuickSwitch = ({
	selectedManagerCompanies,
	selectedCompany,
	_push,
	_setSelectedCompany,
}) => {
	const companies = selectedManagerCompanies.filter((c) => !c.hiddenFromListing)

	const onChange = (uuid) => {
		const found = companies.find((c) => c.uuid === uuid)
		if (found) {
			_push('/' + found.urlName)
		}
	}

	return (
		<SearchFieldDropdown
			id="select-field-company"
			options={companies ? companies : []}
			position={SelectField.Positions.BELOW}
			_onOptionSelect={onChange}
			itemLabel={'name'}
			itemValue={'uuid'}
			reduxStoreValue={selectedCompany ? selectedCompany.uuid : null}
			containerStyle={{
				borderLeftWidth: 0,
				borderRightWidth: 0,
				borderBottomWidth: 0,
				borderColor: 'rgba(0,0,0,0.1)',
				backgroundColorUnfocused: 'transparent',
				backgroundColorFocused: 'rgba(255,255,255,0.2)',
			}}
			searchInputStyle={{
				color: 'white',
				fontWeight: 'bold',
			}}
		/>
	)
}

const mapState = () => ({})

const mapDispatchToProps = (dispatch) => ({
	_push: bindActionCreators(push, dispatch),
	_setSelectedCompany: bindActionCreators(
		appActions._setSelectedCompany,
		dispatch,
	),
})

export default compose(
	WithSelectedManagerCompanies({ hideLoading: true }),
	WithSelectedCompany,
	connect(mapState, mapDispatchToProps),
)(ManagerCompanyQuickSwitch)
