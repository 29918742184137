import MessageServiceProcess from '../MessageServiceProcess'
import ViiluDialog from 'ui/components/ViiluDialog'

function MessageServiceDialog({ toolbarTitle, messageServiceProcess, onHide }) {
	return (
		<ViiluDialog
			id="message-service-create-dialog"
			visible={true}
			fullPage
			onHide={onHide}
			closeOnEsc={false}
			focusOnMount={false}
			dialogStyle={{
				overflow: 'hidden',
			}}
		>
			<MessageServiceProcess
				toolbarTitle={toolbarTitle}
				messageServiceProcess={messageServiceProcess}
				onHide={onHide}
			/>
		</ViiluDialog>
	)
}

export default MessageServiceDialog
