import { Component } from 'react'
import { compose } from 'redux'

import WithSelectedManager from 'ui/components/WithSelectedManager'
import LoadingState from 'ui/components/LoadingState'
import WithAuth from 'ui/components/WithAuth'
import Tampuuri from './Tampuuri'
import Fivaldi from './Fivaldi'
import Viilu from './Viilu'
import MerchantSettings from 'ui/components/merchant/MerchantSettings'
import { ManagerSettingsContainer } from '../styles'
import HtjIntegrationCard from './Htj/HtjIntegrationCard'
import HausviseIntegrationCard from './HausviseIntegrationCard'

export const HTJ_READY_FOR_PROD = true
export const HAUSVISE_READY_FOR_PROD = true

class Integrations extends Component {
	render() {
		const { selectedManager } = this.props

		if (!selectedManager) {
			return <LoadingState />
		}

		return (
			<ManagerSettingsContainer>
				<div className="flex-column">
					<Viilu selectedManager={selectedManager} />
					<Fivaldi selectedManager={selectedManager} />
					<Tampuuri selectedManager={selectedManager} />
					{HTJ_READY_FOR_PROD && (
						<HtjIntegrationCard selectedManager={selectedManager} />
					)}
					{HAUSVISE_READY_FOR_PROD && (
						<HausviseIntegrationCard selectedManager={selectedManager} />
					)}
					<MerchantSettings
						merchantUUID={selectedManager.uuid}
						paymentsEnabled={
							selectedManager.featuresManager &&
							selectedManager.featuresManager['payments']
						}
					/>
				</div>
			</ManagerSettingsContainer>
		)
	}
}

export default compose(
	WithAuth,
	WithSelectedManager({ hideLoading: false }),
)(Integrations)
