import { useCallback, useEffect, useState } from 'react'
import type { Settings } from './types'
import { _getSettingsByCompanyUUID } from 'state/settings-actions'

function useSettings(uuid: string | undefined, disabled?: boolean) {
	const [settings, setSettings] = useState<Settings | null>(null)

	const [loadingSettings, setLoading] = useState<boolean>(true)
	const [errorSettings, setError] = useState<string | null>(null)

	const doQuery = useCallback(async () => {
		if (!uuid || disabled) return
		setLoading(true)
		setError(null)
		let data: Settings | null = null

		try {
			const response = await _getSettingsByCompanyUUID(uuid)

			if (response && response.ok) {
				const res = await response.json()
				data = res.settings
			} else {
				throw new Error('Response is not ok.')
			}
		} catch (err) {
			console.error('Error fetching settings:', err)
			setError('An error occurred while fetching settings: ' + uuid)
		}
		setSettings(data)
		setLoading(false)
	}, [uuid, disabled])

	useEffect(() => {
		doQuery()
	}, [doQuery])

	return { settings, loadingSettings, errorSettings, refreshSettings: doQuery }
}

export default useSettings
