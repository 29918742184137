import ViiluDialog from 'ui/components/ViiluDialog'
import { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { TextField, TextFieldTypes, CircularProgress } from 'react-md'
import { useDispatch } from 'react-redux'
import useDialogs from 'ui/components/dialogs/useDialogs'
import YesNoSwitch from 'ui/components/YesNoSwitch'
import { GENERIC_ERROR_NOTIFICATION } from 'ui/messages'
import * as notifyActions from 'state/notifyActions'
import type { ChartOfAccount, CreateChartOfAccount } from './types'
import { chartOfAccountsHeaders } from './constants'
import {
	_createChartOfAccountSettings,
	_updateChartOfAccountSettings,
} from 'state/settings-actions'
import useChartOfAccount from './useChartOfAccount'
import type { UseChartOfAccountsResult } from './useChartOfAccounts'

type Props = {
	id?: string | null
	useChartOfAccounts: UseChartOfAccountsResult
	visible: boolean
	onHide: () => void
	selectedFinanceCompanyUUID: string
}

const ChartOfAccountDialog = ({
	id,
	useChartOfAccounts,
	visible,
	onHide,
	selectedFinanceCompanyUUID,
}: Props) => {
	const { refreshChartOfAccounts } = useChartOfAccounts
	const intl = useIntl()
	const dispatch = useDispatch()
	const { chartOfAccount } = useChartOfAccount(id)

	const initialFormData: CreateChartOfAccount | ChartOfAccount = {
		companyUUID: selectedFinanceCompanyUUID,
		number: 0,
		name: '',
	}

	const [formData, setFormData] = useState<
		CreateChartOfAccount | ChartOfAccount
	>(initialFormData)

	const [processing, setProcessing] = useState(false)
	const { alert } = useDialogs()
	const resetFormData = () => {
		setFormData(initialFormData)
	}

	useEffect(() => {
		if (chartOfAccount) {
			setFormData({
				uuid: chartOfAccount.uuid,
				companyUUID: chartOfAccount.companyUUID,
				number: Number(chartOfAccount.number),
				name: chartOfAccount.name,
			})
		}
	}, [chartOfAccount])

	useEffect(() => {
		setFormData((prev) => ({
			...prev,
			companyUUID: selectedFinanceCompanyUUID,
		}))
	}, [selectedFinanceCompanyUUID])

	const handleInputChange = (
		value: string | number | boolean,
		field: string,
		type: string,
	) => {
		setFormData((prevData) => ({
			...prevData,
			[field]: type === 'number' ? Number(value) : value,
		}))
	}

	const validate = () => {
		let isValid = true

		chartOfAccountsHeaders.forEach((header) => {
			if (header.show && header.required) {
				if (isValid) {
					isValid = formData[header.key]
				}
			}
		})

		if (!formData.companyUUID) {
			isValid = false
		}

		return isValid
	}

	const hide = () => {
		resetFormData()
		onHide()
	}

	const handleSave = async () => {
		const result: any = await _createChartOfAccountSettings({
			...(formData as ChartOfAccount),
		})
		setProcessing(false)
		if (!result || result.ok === false) {
			dispatch(notifyActions.info('❌ ' + GENERIC_ERROR_NOTIFICATION(intl)))
			return false
		}

		dispatch(
			notifyActions.info(
				'✅ ' +
					intl.formatMessage({
						defaultMessage: 'Pankkitili on lisätty.',
						description: 'Notification text of bank account has been added',
					}),
			),
		)
		refreshChartOfAccounts()
		hide()
		return true
	}

	const handleUpdate = async () => {
		const result: any = await _updateChartOfAccountSettings({
			...(formData as ChartOfAccount),
		})
		setProcessing(false)
		if (!result || result.ok === false) {
			dispatch(notifyActions.info('❌ ' + GENERIC_ERROR_NOTIFICATION(intl)))
			return false
		}

		dispatch(
			notifyActions.info(
				'✅ ' +
					intl.formatMessage({
						defaultMessage: 'Tili on päivitetty.',
						description: 'Notification text of account has been updated',
					}),
			),
		)
		refreshChartOfAccounts()
		hide()
		return true
	}

	const onProcess = async () => {
		const isValid = validate()
		if (!isValid) {
			const message = intl.formatMessage({
				defaultMessage: 'Täytä kaikki vaaditut tiedot.',
				description: 'Error message for missing input values.',
			})
			alert(message)
			return
		}

		setProcessing(true)
		if ((formData as ChartOfAccount).uuid) {
			return await handleUpdate()
		} else return await handleSave()
	}

	const renderFormFields = () => {
		return chartOfAccountsHeaders.map((header) => {
			const { show, key, title, type, required } = header
			if (show) {
				if (type === 'boolean') {
					return (
						<YesNoSwitch
							key={key}
							label={title(intl)}
							name={key}
							checked={formData[key]}
							onChange={(checked: boolean) => {
								handleInputChange(checked, key, type)
							}}
							style={{ marginTop: '10px' }}
						/>
					)
				}
				return (
					<TextField
						key={key}
						id={key}
						label={title(intl)}
						lineDirection="center"
						floating
						fullWidth
						value={formData[key] || ''}
						onChange={(value) => handleInputChange(value, key, type)}
						required={required}
						type={(type as TextFieldTypes) || undefined}
					/>
				)
			}
			return null
		})
	}

	const renderChildren = () => {
		return (
			<div
				className="margin-left--lg margin-right--lg"
				style={{ paddingBottom: 64 }}
			>
				{renderFormFields()}
			</div>
		)
	}

	const actions = [
		{
			id: 'dialog-cancel',
			children: intl.formatMessage({
				defaultMessage: 'Peruuta',
				description: 'Cancel button',
			}),
			onClick: hide,
		},
		{
			id: 'dialog-ok',
			secondary: true,
			children: processing ? (
				<CircularProgress id="progress" style={{ marginTop: 0 }} />
			) : (
				intl.formatMessage({
					defaultMessage: 'Tallenna',
					description: 'Save button',
				})
			),
			onClick: onProcess,
		},
	]

	return (
		<ViiluDialog
			id="responsive-dialog"
			title={
				id
					? intl.formatMessage({
							defaultMessage: 'Muokkaa tiliä',
							description: 'Title of edit bank account',
					  })
					: intl.formatMessage({
							defaultMessage: 'Uusi tili',
							description: 'Title of add bank account',
					  })
			}
			visible={visible}
			actions={actions}
			onHide={hide}
			focusOnMount={true}
			containFocus={true}
			dialogClassName="responsive-dialog"
			contentClassName="responsive-dialog-content"
			autosizeContent={false}
			modal
			portal
			paddedContent
		>
			{renderChildren()}
		</ViiluDialog>
	)
}

export default ChartOfAccountDialog
