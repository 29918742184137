import { useCallback, useEffect, useState } from 'react'
import type { WebInvoiceAddress } from './types'
import { _getWebInvoiceAddressSettingsByUUID } from 'state/settings-actions'

function useWebInvoiceAddress(uuid: string | undefined | null) {
	const [webInvoiceAddress, setWebInvoiceAddress] =
		useState<WebInvoiceAddress>()

	const [loadingWebInvoiceAddress, setLoading] = useState<boolean>(false)
	const [errorWebInvoiceAddress, setError] = useState<string | null>(null)

	const doQuery = useCallback(async () => {
		if (!uuid) return
		setLoading(true)
		setError(null)
		let data: WebInvoiceAddress | undefined

		try {
			const response = await _getWebInvoiceAddressSettingsByUUID(uuid)

			if (response && response.ok) {
				const res = await response.json()
				data = res.webInvoiceAddress
			} else {
				throw new Error('Response is not ok.')
			}
		} catch (err) {
			console.error('Error fetching web invoice addresses:', err)
			setError(
				'An error occurred while fetching web invoice addresses: ' + uuid,
			)
		}
		setWebInvoiceAddress(data)
		setLoading(false)
	}, [uuid])

	useEffect(() => {
		doQuery()
	}, [doQuery])

	return {
		webInvoiceAddress,
		loadingWebInvoiceAddress,
		errorWebInvoiceAddress,
		refreshWebInvoiceAddress: doQuery,
	}
}

export default useWebInvoiceAddress
