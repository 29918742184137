import React from 'react'
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'
import images from 'remark-images'
import './MarkdownText.css' // classnames needed

const MarkdownText = ({ text, className, style }) => {
	const LinkRenderer = (props) => {
		return (
			<a
				href={props.href}
				onClick={(e) => {
					e.preventDefault()
					e.stopPropagation()
					let finalURL = props.href
					if (
						!props.href.includes('http://') &&
						!props.href.includes('https://')
					) {
						finalURL = 'https://' + props.href
					}
					window.open(finalURL, '_blank')
				}}
			>
				{props.children}
			</a>
		)
	}

	return (
		<ReactMarkdown
			className={className ? className + ' markdown-text' : 'markdown-text'}
			style={style}
			children={text}
			plugins={[gfm, images]}
			renderers={{
				link: LinkRenderer,
			}}
		/>
	)
}

export default MarkdownText
