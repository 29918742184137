import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { Toolbar, Button, Checkbox } from 'react-md'

import * as tampuuriActions from 'state/tampuuri-actions'
import * as notifyActions from 'state/notifyActions'
import LoadingState from 'ui/components/LoadingState'
import { generateRecipientUUID } from './utils'
import { injectIntl } from 'react-intl'
import { GENERIC_ERROR_NOTIFICATION } from 'ui/messages'
import WithDialogs from 'ui/components/dialogs/WithDialogs'
import ViiluDialog from 'ui/components/ViiluDialog'

const STEPS = {
	INTRO: 'intro',
	IMPORT_CONFIRM: 'import_confirm',
}

const getInitialState = () => ({
	processing: false,
	step: STEPS.INTRO,
	getShareholders: true,
	getTenants: true,
	getBoardMembers: true,
})

class ImporterDialogTampuuri extends Component {
	state = getInitialState()

	getMissingManagerIntegrationMessage = () => {
		const { intl } = this.props
		return intl.formatMessage({
			defaultMessage:
				'Tämä toiminto vaatii isännöintitoimiston integraation Tampuuriin.',
			description:
				'Message shown in the dialog for importing from Tampuuri to the meeting invite table if the Tampuuri integration is not enabled.',
		})
	}

	getRecipientsTampuuri = async () => {
		const { getShareholders, getTenants, getBoardMembers } = this.state
		const {
			company,
			onSuccess,
			_getCompanyShareholders,
			_getCompanyTenants,
			_getCompanyBoardMembersAll,
			_showInfoNotification,
			intl,
			alert,
		} = this.props
		const companyUUID = company.uuid
		const managerUUID = company.managerUUID
		if (!managerUUID) {
			await alert(this.getMissingManagerIntegrationMessage())
			return false
		}

		this.setState({ processing: true })
		const GET_CONSENTS = true
		const GET_COUNTRIES = true
		const GET_VIILU_CONSENTS = true
		const [shareholderResult, tenantResult, boardMemberResult] =
			await Promise.all([
				getShareholders
					? _getCompanyShareholders(
							companyUUID,
							managerUUID,
							GET_CONSENTS,
							GET_COUNTRIES,
							GET_VIILU_CONSENTS,
							true,
					  )
					: Promise.resolve({ data: [] }),
				getTenants
					? _getCompanyTenants(
							companyUUID,
							managerUUID,
							GET_CONSENTS,
							GET_COUNTRIES,
							GET_VIILU_CONSENTS,
							false,
					  )
					: Promise.resolve({ data: [] }),
				getBoardMembers
					? _getCompanyBoardMembersAll(
							companyUUID,
							managerUUID,
							GET_CONSENTS,
							GET_COUNTRIES,
							GET_VIILU_CONSENTS,
							true,
					  )
					: Promise.resolve({ data: [] }),
			])
		this.setState({ processing: false })
		if (
			shareholderResult &&
			shareholderResult.data &&
			tenantResult &&
			tenantResult.data &&
			boardMemberResult &&
			boardMemberResult.data
		) {
			const userMap = {}
			tenantResult &&
				tenantResult.data.forEach((tenant) => {
					if (tenant.tampuuriPersonId) {
						userMap[tenant.tampuuriPersonId] = tenant
					}
				})
			shareholderResult &&
				shareholderResult.data.forEach((shareholder) => {
					if (shareholder.tampuuriPersonId) {
						userMap[shareholder.tampuuriPersonId] = shareholder
					}
				})
			boardMemberResult &&
				boardMemberResult.data.forEach((boardMember) => {
					if (boardMember.tampuuriPersonId) {
						if (
							userMap[boardMember.tampuuriPersonId] &&
							userMap[boardMember.tampuuriPersonId].apartments
						) {
							const apartments =
								userMap[boardMember.tampuuriPersonId].apartments
							userMap[boardMember.tampuuriPersonId] = {
								...boardMember,
								apartments,
							}
						} else {
							userMap[boardMember.tampuuriPersonId] = boardMember
						}
					}
				})

			const transformedUsers = Object.values(userMap).map((user) => ({
				...user,
				uuid: generateRecipientUUID(),
			}))

			onSuccess(transformedUsers)
			this.onHide()
			return true
		}
		_showInfoNotification(GENERIC_ERROR_NOTIFICATION(intl))
		return false
	}

	onHide = () => {
		this.setState(getInitialState())
		this.props.onHide()
	}

	renderToolbar = (dialogTitle) => {
		return (
			<Toolbar
				colored
				title={dialogTitle}
				style={{
					background: 'var(--color-secondary-dark)',
					position: 'fixed',
					width: '100%',
					zIndex: 2,
				}}
				nav={
					<Button icon onClick={this.onHide}>
						close
					</Button>
				}
			/>
		)
	}

	renderStep = () => {
		const { step, getTenants, getShareholders, getBoardMembers } = this.state
		const { intl } = this.props

		if (step === STEPS.INTRO) {
			return (
				<>
					<div className="flex-column">
						<p>
							{intl.formatMessage({
								defaultMessage:
									'Voit tuoda henkilötiedot suoraan Tampuurista mikäli Tampuuri-rajapinta on otettu käyttöön.',
								description:
									'Instructional paragraph in the dialog for importing from Tampuuri to the meeting invite table.',
							})}
						</p>
						<Checkbox
							id="tampuuri-imported-tenant"
							label={<p style={{ margin: 0 }}>Asukkaat</p>}
							name="Asukkaat"
							checked={getTenants}
							onChange={() => {}}
							onClick={() =>
								this.setState({ getTenants: !this.state.getTenants })
							}
							style={{ marginTop: 32 }}
						/>
						<Checkbox
							id="tampuuri-imported-shareholder"
							label={<p style={{ margin: 0 }}>Osakkaat</p>}
							name="Osakkaat"
							checked={getShareholders}
							onChange={() => {}}
							onClick={() =>
								this.setState({
									getShareholders: !this.state.getShareholders,
								})
							}
						/>
						<Checkbox
							id="tampuuri-imported-board"
							label={<p style={{ margin: 0 }}>Hallitus</p>}
							name="Hallitus"
							checked={getBoardMembers}
							onChange={() => {}}
							onClick={() =>
								this.setState({
									getBoardMembers: !this.state.getBoardMembers,
								})
							}
						/>
						<div
							className="flex-row margin-top"
							style={{ justifyContent: 'flex-end' }}
						>
							<Button
								raised
								secondary
								onClick={() => this.setState({ step: STEPS.IMPORT_CONFIRM })}
							>
								{intl.formatMessage({
									defaultMessage: 'Seuraava',
									description:
										'Label for the button that proceeds to the next step in the dialog for importing from Tampuuri to the meeting invite table.',
								})}
							</Button>
						</div>
					</div>
				</>
			)
		} else {
			return (
				<>
					<div className="flex-column">
						<p>
							{intl.formatMessage({
								defaultMessage:
									'Henkilötiedot ja sähköisen osakaspostituksen suostumukset haetaan Tampuurista painettuasia TUO TIEDOT TAMPUURISTA -painiketta.',
								description:
									'Instructional paragraph in the dialog for importing from Tampuuri to the meeting invite table.',
							})}
						</p>
						<div
							className="flex-row margin-top"
							style={{ justifyContent: 'flex-end' }}
						>
							<Button raised secondary onClick={this.getRecipientsTampuuri}>
								{intl.formatMessage({
									defaultMessage: 'Tuo tiedot Tampuurista',
									description:
										'Label for the button that imports from Tampuuri to the meeting invite table.',
								})}
							</Button>
						</div>
					</div>
				</>
			)
		}
	}

	render() {
		const { company, intl } = this.props
		const { processing } = this.state

		const dialogTitle = intl.formatMessage({
			defaultMessage: 'Tuo Tampuurin henkilörekisteristä',
			description:
				'Title of the dialog for importing invitees from Tampuuri to the meeting invite table.',
		})

		let integrationMissing = false
		if (
			!company.integrations ||
			!company.integrations.tampuuri ||
			!company.integrations.tampuuri[company.managerUUID]
		) {
			integrationMissing = true
		}

		return (
			<ViiluDialog
				id="responsive-dialog"
				aria-label={dialogTitle}
				visible={true}
				onHide={this.onHide}
				focusOnMount={true}
				containFocus={true}
				dialogClassName="responsive-dialog"
				contentClassName="responsive-dialog-content"
				autosizeContent={false}
				disableScrollLocking
				modal
				paddedContent
				onClick={(e) => {
					e.preventDefault()
					e.stopPropagation()
				}}
				style={{ zIndex: 5000 }}
			>
				{this.renderToolbar(dialogTitle)}
				<section className="md-toolbar-relative" style={{ padding: 24 }}>
					{processing ? (
						<div className="flex-column flex-center">
							<LoadingState />
							<p className="text-subtle margin-top">Odotetaan Tampuuria...</p>
						</div>
					) : company.managerUUID && !integrationMissing ? (
						this.renderStep()
					) : (
						<p>{this.getMissingManagerIntegrationMessage()}</p>
					)}
				</section>
			</ViiluDialog>
		)
	}
}

ImporterDialogTampuuri.propTypes = {
	onHide: PropTypes.func.isRequired,
	onSuccess: PropTypes.func.isRequired,
	company: PropTypes.object.isRequired,
}

const mapState = () => ({})

const mapDispatchToProps = (dispatch) => ({
	_showInfoNotification: bindActionCreators(notifyActions.info, dispatch),
	_getCompanyShareholders: bindActionCreators(
		tampuuriActions._getCompanyShareholders,
		dispatch,
	),
	_getCompanyTenants: bindActionCreators(
		tampuuriActions._getCompanyTenants,
		dispatch,
	),
	_getCompanyBoardMembersAll: bindActionCreators(
		tampuuriActions._getCompanyBoardMembersAll,
		dispatch,
	),
})

export default compose(
	injectIntl,
	WithDialogs,
	connect(mapState, mapDispatchToProps),
)(ImporterDialogTampuuri)
