import { FormattedMessage } from 'react-intl'
import { Checkbox, List, ListItem } from 'react-md'
import styled from 'styled-components'
import { compose } from 'redux'

import Layout from '../Layout'
import DefaultTemplate from './DefaultTemplate'
import WithSelectedCompany from 'ui/components/WithSelectedCompany'

const Title = styled.h1`
	margin-bottom: 24px;
`

const StyledList = styled(List)`
	flex: 1;
	margin: 16px 0;
	overflow: auto;
`

const TemplateContainer = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
`

const Attachments = styled.div`
	display: flex;
	flex-wrap: wrap;
`
const Attachment = styled.div`
	border: 2px dashed #666;
	padding: 8px 32px;
	border-radius: 8px;
	background-color: white;
	text-align: center;
	margin: 4px;
`

function EmailPreview({
	stepTitle,
	onPreviousClick,
	onDraftClick,
	onNextClick,
	messageServiceProcess: {
		generator,
		recipients,
		title,
		body,
		attachments,
		viewUrl,
		emailSettings = {},
	},
	setMessageServiceProcess,
	selectedCompany,
}) {
	const updateEmailSettings = (settings) => {
		setMessageServiceProcess((prev) => ({
			...prev,
			emailSettings: {
				...prev.emailSettings,
				...settings,
			},
		}))
	}

	const handleInputChange = (name, value) => {
		updateEmailSettings({ [name]: value })
	}

	const emailRecipients = recipients.filter((r) => r.checked?.['email'])

	const left = (
		<>
			<Title>{stepTitle}</Title>
			<p>
				<FormattedMessage
					defaultMessage={
						'Olet lähettämässä sähköpostia alla oleviin osoitteisiin. Näet sähköpostin sisällön ' +
						'oikealla. Huomioi, että oikealla on pelkkä esikatselu eivätkä sen sisältämät linkit johda minnekään.'
					}
					description="Informational description of the email preview step in the general sender component."
				/>
			</p>
			{generator === 'meetingInvite' ? (
				<>
					<p className="margin-top">
						<FormattedMessage
							defaultMessage="Vastaanottajille lähetetään sähköpostin liitteenä kalenterikutsu."
							description="Informational text telling the user that the email will include a calendar invite."
						/>
					</p>
					<Checkbox
						id="enableRSVP"
						name="enableRSVP"
						label="Pyydä vastaanottajia vastaamaan kalenterikutsuun (RSVP)"
						value={emailSettings.enableRSVP}
						onChange={(value) => handleInputChange('enableRSVP', value)}
						checked={emailSettings.enableRSVP}
					/>
				</>
			) : null}
			<StyledList>
				{emailRecipients.map((i) => (
					<ListItem key={i.uuid} primaryText={i.email} />
				))}
			</StyledList>
		</>
	)

	let right = (
		<TemplateContainer>
			<DefaultTemplate
				title={title}
				body={body}
				viewUrl={viewUrl}
				companyName={selectedCompany && selectedCompany.name}
			/>
			{attachments?.length > 0 && (
				<>
					<h3 style={{ fontSize: 18 }}>Liitteet</h3>
					<Attachments>
						{attachments.map((a) => (
							<Attachment key={a.filename}>{a.filename}</Attachment>
						))}
					</Attachments>
				</>
			)}
		</TemplateContainer>
	)

	return (
		<>
			<Layout
				left={left}
				right={right}
				onPreviousClick={onPreviousClick}
				onDraftClick={onDraftClick}
				onNextClick={onNextClick}
			/>
		</>
	)
}

export default compose(WithSelectedCompany)(EmailPreview)
