import { Button, Toolbar } from 'react-md'
import { compose } from 'redux'
import styled from 'styled-components'
import { PropTypes } from 'prop-types'

import YouMenu from './index.js'
import ViiluLogo from 'svg/viilu-logo-black.svg'
import WithLoggedInUser from 'ui/components/WithLoggedInUser.js'
import { maxScreenWidth } from 'ui/StyledComponents/MediaBreakpoints.js'
import ViiluDialog from 'ui/components/ViiluDialog'

const LogoContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 60px;
	margin: 0;
`
const YouDialog = ({ loggedInUser, mentions, onHide, visible }) => {
	if (!loggedInUser) {
		return null
	}
	const user = loggedInUser
	const showFullPageDialog = window.innerWidth <= maxScreenWidth.phone
	return (
		<ViiluDialog
			id="responsive-dialog"
			aria-label="Sinä"
			visible={visible}
			onHide={onHide}
			containFocus={true}
			focusOnMount={true}
			fullPage={showFullPageDialog}
			autosizeContent={false}
			paddedContent={false}
			modal
			portal
			onClick={(e) => {
				e.preventDefault()
				e.stopPropagation()
			}}
			onDoubleClick={(e) => {
				e.preventDefault()
				e.stopPropagation()
			}}
			dialogStyle={{
				borderRadius: 8,
			}}
		>
			<Toolbar
				title={
					<LogoContainer>
						<img
							src={ViiluLogo}
							alt="viilu"
							width={48}
							height={24}
							style={
								window.isNativeApp
									? {
											marginTop: 48,
											opacity: 0.87,
									  }
									: { marginTop: -4, opacity: 0.87 }
							}
						/>
					</LogoContainer>
				}
				nav={
					<Button icon onClick={onHide} style={{ zIndex: 2 }}>
						close
					</Button>
				}
				style={
					window.isNativeApp ? { paddingTop: 32, marginBottom: 32 } : undefined
				}
			/>
			<YouMenu user={user} onHide={onHide} />
		</ViiluDialog>
	)
}

YouDialog.propTypes = {
	onHide: PropTypes.func.isRequired,
	visible: PropTypes.bool.isRequired,
}

export default compose(WithLoggedInUser)(YouDialog)
