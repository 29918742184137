import { IntlShape } from 'react-intl'
import type { PaymentProductHeader } from './types'

export const paymentProductHeaders: PaymentProductHeader[] = [
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Kohdistusjärjestys',
				description:
					'Table column header for finance payment product priority.',
			}),
		key: 'priority',
		show: true,
		sortable: true,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Numero',
				description: 'Table column header for finance payment product number.',
			}),
		key: 'number',
		show: true,
		sortable: true,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Nimi',
				description: 'Table column header for finance payment product name.',
			}),
		key: 'name',
		show: true,
		sortable: true,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Selite',
				description:
					'Table column header for finance payment product description.',
			}),
		key: 'description',
		show: true,
		sortable: true,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Alkupäivämäärä',
				description:
					'Table column header for finance payment product startDate.',
			}),
		key: 'startDate',
		type: 'date',
		show: true,
		sortable: true,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Loppupäivämäärä',
				description: 'Table column header for finance payment product endDate.',
			}),
		key: 'endDate',
		type: 'date',
		show: true,
		sortable: true,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Erillislaskutus',
				description:
					'Table column header for finance payment product separate billing.',
			}),
		key: 'separateBilling',
		type: 'boolean',
		show: true,
		sortable: true,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'ALV',
				description: 'Table column header for finance payment product VAT.',
			}),
		key: 'vatUUID',
		type: 'vat',
		show: true,
		sortable: true,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Hinta',
				description: 'Table column header for finance payment product price.',
			}),
		key: 'price',
		type: 'price',
		show: true,
		sortable: true,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Hinta + alv',
				description:
					'Table column header for finance payment product price with vat.',
			}),
		key: 'priceWithVat',
		type: 'priceWithVat',
		show: true,
		sortable: true,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Veloitusperuste',
				description:
					'Table column header for finance payment product billing basis.',
			}),
		key: 'billingBasis',
		type: 'billingBasisMessage',
		show: true,
		sortable: true,
	},
]
