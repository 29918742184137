import { addObjectFieldIfNotExist } from 'state/reducer-utils'

const initialState = {
	threadMsgFieldState: {}, // item: { threadId: { msgFieldValue: string, msgFieldDisabled: boolean } }
}

function reducer(state = initialState, action) {
	// users might have stale state in their localStorage
	const migratedState = addObjectFieldIfNotExist(
		state,
		'threadMsgFieldState',
		{},
	)

	if (action.type === 'UI_THREAD_MESSAGE_FIELD_VALUE') {
		return updatedMsgFieldState(migratedState, action, 'message')
	}
	if (action.type === 'UI_THREAD_MESSAGE_FIELD_DISABLED') {
		return updatedMsgFieldState(migratedState, action, 'disabled')
	}
	return migratedState
}

const updatedMsgFieldState = (state, action, fieldName) => {
	const objToUpdate = state.threadMsgFieldState[action.topic]
	const updatedObj = {
		...objToUpdate,
		[fieldName]: action[fieldName],
	}
	const newState = { ...state.threadMsgFieldState }
	newState[action.topic] = updatedObj

	return {
		...state,
		threadMsgFieldState: newState,
	}
}

export default reducer
