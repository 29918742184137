// users may have stale Redux state in their localStorage; this ensures that a correctly shaped state object is used
export const addObjectFieldIfNotExist = (
	state: any,
	newFieldKey: string,
	newFieldValue: any = null,
) => {
	if (!state) return null
	if (!state[newFieldKey]) {
		const newState = JSON.parse(JSON.stringify(state)) // deep copy to account for nested objects
		newState[newFieldKey] = newFieldValue
		return newState
	}
	return state
}

// users may have stale Redux state in their localStorage; this ensures that a correctly shaped state object is used
export const removeObjectField = (state: any, fieldKey: string) => {
	if (!state) return null
	delete state[fieldKey]
	return state
}
