import { Button, FontIcon, Badge } from 'react-md'
import styled, { keyframes } from 'styled-components'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { firestoreConnect, isEmpty, isLoaded } from 'react-redux-firebase'
import * as appUIActions from 'state/ui/app-ui-actions'

const StyledButton = styled(Button)`
	background: rgba(0, 0, 0, 0.1);
	padding: 0;
	height: 34px;
	width: 34px;
`
const pulse = keyframes`
	0% {
		transform: translate(-8px, 10px) scale(0.7);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}

	70% {
		transform: translate(-8px, 10px) scale(0.75);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
		transform: translate(-8px, 10px) scale(0.7);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
`

const badgeClass = 'badge-class'
const PulsingBadge = styled(Badge).attrs({
	badgeClassName: badgeClass,
})`
	.${badgeClass} {
		transform: translate(-8px, 10px) scale(0.75);
		pointer-events: none;
		user-select: none;
		border-color: rgba(255, 255, 255, 1);
		border-style: solid;
		border-width: 1px;

		box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
		animation: ${pulse} 2s infinite;
		background: var(--color-secondary) !important;
	}
`

function NotificationCenterButton({
	mentions,
	beamerUnreadPostsCount,
	isDesktop,
	_push,
	_setNavDrawerVisible,
}) {
	let notificationCount = 0
	if (isLoaded(mentions) && !isEmpty(mentions)) {
		notificationCount = mentions.filter((m) => !m.read).length
	}
	if (beamerUnreadPostsCount) {
		notificationCount += beamerUnreadPostsCount
	}

	return (
		<PulsingBadge
			badgeContent={notificationCount}
			primary
			invisibleOnZero
			badgeId="user-notifications"
		>
			<div
				onClick={() => {
					if (!isDesktop) {
						_setNavDrawerVisible(false)
					}
				}}
				style={{
					cursor: 'pointer',
					padding: 8,
					userSelect: 'none',
				}}
			>
				<div className="flex-center">
					<StyledButton
						id="beamer-button"
						icon
						onClick={() => {
							if (window.location.pathname.includes('/manager')) {
								return _push('/manager/notifications')
							}
							_push('/internal/notifications')
						}}
					>
						<FontIcon
							style={{
								marginTop: 2,
								fontSize: 24,
							}}
						>
							notifications
						</FontIcon>
					</StyledButton>
				</div>
			</div>
		</PulsingBadge>
	)
}

const mapState = ({
	ui: {
		app: { beamerUnreadPostsCount },
	},
	firestoreReducer: {
		ordered: { mention },
	},
	firebaseReducer: { auth },
	dimensions: { isDesktop },
}) => ({
	mentions: mention,
	beamerUnreadPostsCount,
	isDesktop,
	auth,
})

const mapDispatchToProps = (dispatch) => ({
	_push: bindActionCreators(push, dispatch),
	_setNavDrawerVisible: bindActionCreators(
		appUIActions._setNavDrawerVisible,
		dispatch,
	),
})

export default compose(
	connect(mapState, mapDispatchToProps),
	firestoreConnect(({ auth }) =>
		auth.uid
			? [
					{
						collection: 'mention',
						where: [['mentionedUserUID', '==', auth.uid]],
						orderBy: ['createdAt', 'desc'],
						limit: 100,
					},
			  ]
			: [],
	),
)(NotificationCenterButton)
