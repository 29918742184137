declare var Cookiebot: any

export function registerRunAfterCookieConsentAcceptCallback(
	loadFunc: () => void,
) {
	const isOnInfoscreenPage =
		window.location.hostname === 'viilu.tv' ||
		window.location.pathname === '/internal/infoscreen' ||
		window.location.pathname === '/internal/infoscreen/'
	if (isOnInfoscreenPage) {
		loadFunc()
		return () => {}
	} else {
		const acceptCb = () => {
			loadFunc()
		}
		if (
			typeof Cookiebot !== 'undefined' &&
			// eslint-disable-next-line no-undef
			typeof Cookiebot.consent !== 'undefined' &&
			// eslint-disable-next-line no-undef
			Cookiebot.consent.consented
		) {
			loadFunc()
		} else {
			window.addEventListener('CookiebotOnAccept', acceptCb)
		}
		return () => window.removeEventListener('CookiebotOnAccept', acceptCb)
	}
}
