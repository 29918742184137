export const _setAppToolbarVisible = (visible) => ({
	type: 'UI_APP_SET_APP_TOOLBAR_VISIBLE',
	visible,
})

export const _setNavDrawerVisible = (visible) => ({
	type: 'UI_APP_SET_NAV_DRAWER_VISIBLE',
	visible,
})

export const _dismissBrandBanner = () => ({
	type: 'UI_APP_HIDE_BRAND_BANNER',
})

export const _setHasSwitchedToCompanyTaskManager = (hasSwitched) => ({
	type: 'UI_APP_SET_HAS_SWITCHED_TO_COMPANY_TASK_MANAGER',
	hasSwitched,
})

export const _setHasScrolledDown = (hasScrolledDown) => ({
	type: 'UI_APP_SET_HAS_SCROLLED_DOWN',
	hasScrolledDown,
})

export const _setLanguageDialogVisible = (visible) => ({
	type: 'UI_APP_SET_LANGUAGE_DIALOG_VISIBLE',
	visible,
})

export const _setBeamerUnreadPostsCount = (count) => ({
	type: 'UI_APP_SET_BEAMER_UNREAD_POSTS_COUNT',
	count,
})
