import { useMemo } from 'react'

const initSteps = (
	childSteps,
	deliveryMethods,
	deliveryMethodsIntegrations,
) => {
	return childSteps.filter((s) => {
		if (s.id === 'companySelection') {
			// This step is used in the manager mass sending.
			return true
		}
		if (s.id === 'personal') {
			return deliveryMethods.some(
				(m) => m === 'email' || m === 'letter' || m === 'sms',
			)
		}
		if (s.id === 'integrations') {
			return deliveryMethodsIntegrations?.length > 0
		}
		return deliveryMethods.includes(s.id)
	})
}

function Recipients({
	messageServiceProcess,
	setMessageServiceProcess,
	onPreviousClick,
	onDraftClick,
	onNextClick,
	onHide,
	childSteps,
}) {
	const filteredSteps = useMemo(
		() =>
			initSteps(
				childSteps,
				messageServiceProcess.deliveryMethods,
				messageServiceProcess.deliveryMethodsIntegrations,
			),
		[
			childSteps,
			messageServiceProcess.deliveryMethods,
			messageServiceProcess.deliveryMethodsIntegrations,
		],
	)

	if (filteredSteps.length === 0) {
		return <p style={{ margin: 32 }}>No steps.</p>
	}

	const id = messageServiceProcess.step.childId || filteredSteps[0].id
	const activeChildIndex = filteredSteps.findIndex((s) => s.id === id)

	const handlePreviousClick = () => {
		const previousChild = filteredSteps[activeChildIndex - 1]

		if (previousChild) {
			onPreviousClick(previousChild.id)
		} else {
			onPreviousClick()
		}
	}

	const handleNextClick = () => {
		const nextChild = filteredSteps[activeChildIndex + 1]

		if (nextChild) {
			onNextClick(nextChild.id)
		} else {
			onNextClick()
		}
	}

	const formatStepTitle = (title) => {
		const progress = `(${activeChildIndex + 1}/${filteredSteps.length})`
		return `${title} ${progress}`
	}

	const step = filteredSteps[activeChildIndex]
	const Component = step.component

	return (
		<Component
			stepTitle={formatStepTitle(step.label)}
			onPreviousClick={handlePreviousClick}
			onDraftClick={onDraftClick}
			onNextClick={handleNextClick}
			onHide={onHide}
			messageServiceProcess={messageServiceProcess}
			setMessageServiceProcess={setMessageServiceProcess}
		/>
	)
}

export default Recipients
