const initialState = {
	selectedParent: {
		uuid: null,
		parentUUID: null,
		name: '',
	},
}

function reducer(state = initialState, action) {
	if (action.type === 'UI_DOCUMENTS_PARENT_VALUE') {
		return { ...state, selectedParent: action.parent }
	}
	return state
}

export default reducer
