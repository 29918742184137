import { IntlShape } from 'react-intl'
import type {
	PaymentProduct,
	PaymentProductFormData,
	PaymentProductKey,
	PaymentProductPrice,
} from './types'
import { PaymentProductBillingBasis } from '../enum'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
import { YES_NO_MESSAGE } from 'ui/messages'
import { VatSelectOptions } from '../types'
import { billingBasisMessage } from '../utils/messages'
import { calculateSumWithVat } from '../utils/utils'

const formatDate = (isoDate?: string | null) => {
	if (!isoDate) return ''
	return moment(isoDate).format('D.M.YYYY')
}

const getPrice = (prices: PaymentProductPrice[]): number | string => {
	const price = prices.find(
		(price) =>
			new Date(price.startDate).getTime() <= new Date().getTime() &&
			(!price.endDate ||
				new Date(price.endDate).getTime() >= new Date().getTime()),
	)
	if (!price) {
		return ''
	}
	return price.price
}

export const getPaymentProductRowValue = (
	paymentProduct: PaymentProduct,
	key: PaymentProductKey,
	vatOptions: VatSelectOptions[],
	intl: IntlShape,
	type?: string,
) => {
	const value = paymentProduct[key]
	const price = getPrice(paymentProduct.prices)
	const vat = vatOptions.find((v) => v.value === paymentProduct.vatUUID)

	switch (type) {
		case 'date':
			return formatDate(value as string)
		case 'price':
			return price
		case 'priceWithVat':
			return price
				? calculateSumWithVat((price as number) || 0, vat?.percentage || 0)
				: ''
		case 'boolean':
			return YES_NO_MESSAGE(intl, value)
		case 'vat':
			return vat?.label || value
		case 'billingBasisMessage':
			return billingBasisMessage(intl, value)
		default:
			return value || ''
	}
}

export const getPaymentProductErrorMessage = (
	errorCode: string,
	intl: IntlShape,
) => {
	if (errorCode === 'price-date-conflict') {
		return intl.formatMessage({
			defaultMessage:
				'Virhe maksulajin hintojen päivämäärissä, varmista ettei päivissä ole päällekäisyyksiä ja vain yhdessä hinnassa voi olla tyhjä loppupäivämäärä',
			description:
				'ERROR: Payment product price dates, ensure that there is no overlaps on the date ranges and only one price has empty end date',
		})
	} else if (errorCode === 'price-end-date-conflict') {
		return intl.formatMessage({
			defaultMessage:
				'Virhe maksulajin hintojen päivämäärissä, varmista että alkupäivämäärät ovat ennen loppupäivämääriä',
			description:
				'ERROR: Payment product price dates, ensure that start dates are before the end dates',
		})
	} else if (errorCode === 'payment-date-conflict') {
		return intl.formatMessage({
			defaultMessage:
				'Virhe maksulajin loppupäivämäärä on ennen alkupäivämäärää',
			description: 'ERROR: Payment product end date is before the start date',
		})
	} else if (errorCode === 'payment-price-date-conflict') {
		return intl.formatMessage({
			defaultMessage:
				'Virhe maksulajin hintojen päivämäärissä, varmista että hintojen alkupäivämäärät eivät ole ennen maksulajin alkupäivämäärää ja hintojen loppupäivämäärät eivät ole maksulajin loppupäivämäärän jälkeen',
			description:
				'ERROR: in the payment product price dates, ensure that the start dates of the prices are not before the payment product start date and the end dates of the prices are not after the payment product end date.',
		})
	}
	return false
}

export const initFormData = (
	paymentProduct: PaymentProduct | undefined,
): PaymentProductFormData => {
	return {
		uuid: paymentProduct?.uuid ?? '',
		name: paymentProduct?.name ?? '',
		description: paymentProduct?.description ?? '',
		number: paymentProduct?.number ?? '',
		priority: paymentProduct?.priority ?? '',
		separateBilling: paymentProduct?.separateBilling ?? false,
		vatUUID: paymentProduct?.vatUUID ?? null,
		billingBasis:
			paymentProduct?.billingBasis ?? PaymentProductBillingBasis.AREA,
		billingBasisDescription: paymentProduct?.billingBasisDescription ?? '',
		startDate: paymentProduct?.startDate ?? '',
		endDate: paymentProduct?.endDate ?? '',
		prices: paymentProduct?.prices.map((p) => ({
			...p,
			endDate: p.endDate || '',
		})) ?? [
			{
				uuid: 'new' + uuidv4(),
				price: '',
				startDate: '',
				endDate: '',
				deleted: false,
			},
		],
	}
}
