import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Button, CircularProgress } from 'react-md'
import {
	_deletePaymentProduct,
	_getPaymentProduct,
	_updatePaymentProduct,
} from 'state/finance-payment-product-actions'
import useDialogs from 'ui/components/dialogs/useDialogs'
import {
	CANCEL_BUTTON_LABEL,
	DELETE_BUTTON_LABEL,
	GENERIC_ERROR_NOTIFICATION,
	SAVE_BUTTON_LABEL,
} from 'ui/messages'
import PaymentProductForm from './PaymentProductForm'
import type { PaymentProduct, PaymentProductFormData } from './types'
import { getPaymentProductErrorMessage, initFormData } from './utils'
import { UPDATE_INVOICES_REMINDER_MESSAGE } from '../utils/messages'
import { validateFormData } from './validate'
import type { VatSelectOptions } from '../types'
import ViiluDialog from 'ui/components/ViiluDialog'

type Props = {
	paymentProductUUID: string
	onHide: () => void
	refreshPaymentProducts: () => void
	paymentProducts: PaymentProduct[]
	vatOptions: VatSelectOptions[]
}

function PaymentProductEditDialog({
	paymentProductUUID,
	onHide,
	refreshPaymentProducts,
	paymentProducts,
	vatOptions,
}: Props) {
	const intl = useIntl()
	const { alert, confirm } = useDialogs()
	const [loading, setLoading] = useState(true)
	const [paymentProduct, setPaymentProduct] = useState<PaymentProduct>()
	const [formData, setFormData] = useState<PaymentProductFormData>(
		initFormData(paymentProduct),
	)

	useEffect(() => {
		const getPaymentProduct = async () => {
			setLoading(true)
			try {
				const result = await _getPaymentProduct(paymentProductUUID)

				if (!result?.ok) {
					throw new Error('Response is not ok.')
				}

				const jsonResult = await result.json()
				const paymentProduct: PaymentProduct = jsonResult.paymentProduct
				setPaymentProduct(paymentProduct)
				setFormData(initFormData(paymentProduct))
			} catch (error) {
				console.error(error)
			}
			setLoading(false)
		}
		getPaymentProduct()
	}, [paymentProductUUID])

	const handleSave = async () => {
		if (!paymentProduct || !formData) {
			return
		}

		const isValid = await validateFormData(
			formData,
			alert,
			intl,
			paymentProducts,
		)

		if (!isValid) {
			return
		}

		setLoading(true)
		try {
			const response = await _updatePaymentProduct({
				...paymentProduct,
				number: Number(formData.number),
				priority: Number(formData.priority),
				name: formData.name,
				description: formData.description,
				startDate: formData.startDate,
				endDate: formData.endDate || null,
				separateBilling: formData.separateBilling,
				vatUUID: formData.vatUUID,
				billingBasis: formData.billingBasis,
				billingBasisDescription: formData.billingBasisDescription,
				prices: formData.prices.map((p) => ({
					...p,
					price: Number(p.price),
					endDate: p.endDate || null,
				})),
			})

			const result = await response?.json()
			if (!response?.ok) {
				const errorMessage = getPaymentProductErrorMessage(
					result.errorCode,
					intl,
				)
				if (errorMessage) {
					alert(errorMessage)
					setLoading(false)
					return
				} else {
					throw new Error('Response is not ok.')
				}
			}

			refreshPaymentProducts()
			onHide()
			alert(UPDATE_INVOICES_REMINDER_MESSAGE(intl))
		} catch (error) {
			console.error(error)
			alert(GENERIC_ERROR_NOTIFICATION(intl))
			setLoading(false)
		}
	}

	const handleDelete = async () => {
		const confirmMessage = intl.formatMessage({
			defaultMessage: 'Haluatko varmasti poistaa maksulajin?',
			description: 'Confirm message before deleting a payment product.',
		})

		if (!(await confirm(confirmMessage))) {
			return
		}

		setLoading(true)
		try {
			const result = await _deletePaymentProduct(paymentProductUUID)
			if (!result?.ok) {
				throw new Error('Delete failed.')
			}
			refreshPaymentProducts()
			onHide()
		} catch (error) {
			console.error(error)
			alert(GENERIC_ERROR_NOTIFICATION(intl))
		}
	}

	const renderContent = () => {
		if (loading) {
			return <CircularProgress id="payment-product-edit-progress" />
		}

		if (!formData) {
			return (
				<p>
					{intl.formatMessage({
						defaultMessage: 'Maksulajia ei löytynyt.',
						description: 'Error message if payment product is not found.',
					})}
				</p>
			)
		}

		return (
			<PaymentProductForm
				uuid={paymentProduct?.uuid}
				formData={formData}
				setFormData={setFormData}
				vatOptions={vatOptions}
			/>
		)
	}

	return (
		<ViiluDialog
			id="payment-product-edit-dialog"
			visible={true}
			onHide={onHide}
			title={intl.formatMessage({
				defaultMessage: 'Muokkaa maksulajia',
				description: 'Title for the finance payment edit product dialog.',
			})}
			actions={[
				<Button
					flat
					disabled={loading}
					style={{
						marginRight: 'auto',
					}}
					onClick={handleDelete}
				>
					{DELETE_BUTTON_LABEL(intl)}
				</Button>,
				<Button flat onClick={onHide}>
					{CANCEL_BUTTON_LABEL(intl)}
				</Button>,
				<Button raised secondary disabled={loading} onClick={handleSave}>
					{SAVE_BUTTON_LABEL(intl)}
				</Button>,
			]}
			modal
			dialogStyle={{ borderRadius: 8, width: 'auto' }}
			focusOnMount={false}
		>
			{renderContent()}
		</ViiluDialog>
	)
}

export default PaymentProductEditDialog
