import { useCallback, useEffect, useState } from 'react'
import type { BankAccount } from './types'
import { _getBankAccountsSettingsByCompanyUUID } from 'state/settings-actions'

function useBankAccounts(uuid: string | undefined) {
	const [bankAccounts, setBankAccounts] = useState<BankAccount[]>([])

	const [loadingBankAccounts, setLoading] = useState<boolean>(false)
	const [errorBankAccounts, setError] = useState<string | null>(null)

	const doQuery = useCallback(async () => {
		if (!uuid) return
		setLoading(true)
		setError(null)
		let data: BankAccount[] = []

		try {
			const response = await _getBankAccountsSettingsByCompanyUUID(uuid)

			if (response && response.ok) {
				const res = await response.json()
				data = res.bankAccounts
			} else {
				throw new Error('Response is not ok.')
			}
		} catch (err) {
			console.error('Error fetching bank accounts:', err)
			setError('An error occurred while fetching bank accounts: ' + uuid)
		}
		setBankAccounts(data)
		setLoading(false)
	}, [uuid])

	useEffect(() => {
		doQuery()
	}, [doQuery])

	return {
		bankAccounts,
		loadingBankAccounts,
		errorBankAccounts,
		refreshBankAccounts: doQuery,
	}
}

export type UseBankAccountsResult = ReturnType<typeof useBankAccounts>

export default useBankAccounts
