export const statusOptions = [
	{
		label: 'Uusi',
		value: 'new',
		color: 'brown',
		icon: 'inbox',
		planColor: 'rgb(240, 200, 100)',
	},
	{
		label: 'Työn alla',
		value: 'ongoing',
		color: 'orange',
		icon: 'hourglass_top',
		planColor: 'rgb(240, 240, 100)',
	},
	{
		label: 'Valmis',
		value: 'complete',
		color: 'green',
		icon: 'done',
		planColor: 'rgb(100, 200, 100)',
	},
	{
		label: 'Hyväksytty',
		value: 'approved',
		color: 'grey',
		icon: 'done_all',
		planColor: 'rgb(100, 200, 100)',
	},
]
