import { injectIntl } from 'react-intl'
import { compose } from 'redux'
import SummaryItems from '../SummaryItems'

function EmailSummary({ emailCount, intl }) {
	const items = [
		{
			icon: 'email',
			text:
				emailCount === 1
					? intl.formatMessage({
							defaultMessage: '1 sähköposti',
							description:
								'Singular version of the email recipients count in the message service summary step.',
					  })
					: intl.formatMessage(
							{
								defaultMessage: '{recipientsCount} sähköpostia',
								description:
									'Plural version of the email recipients count in the message service summary step.',
							},
							{
								recipientsCount: emailCount,
							},
					  ),
		},
	]

	return (
		<div>
			<h2>
				{intl.formatMessage({
					defaultMessage: 'Sähköpostit (maksuton)',
					description:
						'Heading for the email section in the message service summary step.',
				})}
			</h2>
			<SummaryItems items={items} />
		</div>
	)
}

export default compose(injectIntl)(EmailSummary)
