import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { push } from 'connected-react-router'
import {
	DataTable,
	TableHeader,
	TableRow,
	TableColumn,
	TableBody,
	Checkbox,
} from 'react-md'
import { firestoreConnect, isEmpty } from 'react-redux-firebase'
import moment from 'moment-timezone'

import EmptyState from 'ui/components/EmptyState'
import LoadingState from 'ui/components/LoadingState'
import surveysEmptyIcon from 'svg/surveys-empty.svg'
import SignatureRequestRow from './SignatureRequestRowManager'
import RouteHeader from 'ui/components/RouteHeader'
import WithoutAuthShowLoginDialog from 'ui/components/WithoutAuthShowLoginDialog'
import WithSelectedManagerCompanies from 'ui/components/WithSelectedManagerCompanies'
import OverflowGradient from 'ui/components/OverflowGradient'
import WithQueryParams from 'ui/components/WithQueryParams'
import SearchFieldDropdown from 'ui/components/SearchFieldDropdown'
import styled from 'styled-components'
import naturalSort from 'javascript-natural-sort'
import { FormattedMessage, injectIntl } from 'react-intl'
import DateSelect from 'ui/components/DateSelect'

const FiltersContainer = styled.div`
	display: flex;
	width: 100%;
	justify-content: flex-start;
	align-items: flex-end;
	padding-left: 24px;
	padding-right: 24px;
	padding-bottom: 16px;
`

class ManagerSignatureRequests extends Component {
	state = {
		loading: true,
		sortBy: 'ts',
		sortAscending: false,
	}

	componentWillMount = () => {
		window.setTimeout(() => {
			this.setState({ loading: false })
		}, 2000)
	}

	sortBy = (field) => {
		const { sortBy, sortAscending } = this.state
		let ascending = true
		if (sortBy === field) {
			ascending = !sortAscending
		}
		this.setState({ sortBy: field, sortAscending: ascending })
	}

	setCompanyFilter = (uuid) => {
		const { queryParams, _push } = this.props
		_push(
			`/manager/signature_requests?companyUUID=${
				uuid || 'not-chosen'
			}&includeComplete=${queryParams.includeComplete || false}&minTs=${
				parseInt(queryParams.minTs, 10) || getMinTs()
			}&maxTs=${parseInt(queryParams.maxTs) || getMaxTs()}`,
		)
	}

	setCompleteFilter = (includeComplete) => {
		const { queryParams, _push } = this.props
		_push(
			`/manager/signature_requests?companyUUID=${
				queryParams.companyUUID || 'not-chosen'
			}&includeComplete=${includeComplete}&minTs=${
				parseInt(queryParams.minTs, 10) || getMinTs()
			}&maxTs=${parseInt(queryParams.maxTs) || getMaxTs()}`,
		)
	}

	setMinTs = (minTs) => {
		const { queryParams, _push } = this.props
		_push(
			`/manager/signature_requests?companyUUID=${
				queryParams.companyUUID || 'not-chosen'
			}&includeComplete=${
				queryParams.includeComplete || false
			}&minTs=${minTs}&maxTs=${parseInt(queryParams.maxTs) || getMaxTs()}`,
		)
	}

	setMaxTs = (maxTs) => {
		const { queryParams, _push } = this.props
		_push(
			`/manager/signature_requests?companyUUID=${
				queryParams.companyUUID || 'not-chosen'
			}&includeComplete=${queryParams.includeComplete || false}&minTs=${
				parseInt(queryParams.minTs, 10) || getMinTs()
			}&maxTs=${maxTs}`,
		)
	}

	render() {
		const { loading, sortBy, sortAscending } = this.state
		const {
			ordered,
			selectedManager,
			selectedManagerCompanies,
			queryParams,
			intl,
			correlationCollectionOverride,
		} = this.props

		if (loading) {
			return <LoadingState />
		}

		if (!selectedManagerCompanies) {
			return <LoadingState />
		}

		let signatureRequests = []
		if (queryParams.companyUUID && queryParams.companyUUID !== 'not-chosen') {
			const initializedSignatureRequests =
				ordered[`signature-request-initialized-${queryParams.companyUUID}`]
			if (initializedSignatureRequests && initializedSignatureRequests.length) {
				signatureRequests = signatureRequests.concat(
					initializedSignatureRequests,
				)
			}

			const signingSignatureRequests =
				ordered[`signature-request-signing-${queryParams.companyUUID}`]
			if (signingSignatureRequests && signingSignatureRequests.length) {
				signatureRequests = signatureRequests.concat(signingSignatureRequests)
			}

			const completedSignatureRequests =
				ordered[`signature-request-complete-${queryParams.companyUUID}`]
			if (completedSignatureRequests && completedSignatureRequests.length) {
				signatureRequests = signatureRequests.concat(completedSignatureRequests)
			}
		} else {
			const initializedSignatureRequests =
				ordered[`signature-request-manager-initialized-${selectedManager.uuid}`]
			if (initializedSignatureRequests && initializedSignatureRequests.length) {
				signatureRequests = signatureRequests.concat(
					initializedSignatureRequests,
				)
			}

			const signingSignatureRequests =
				ordered[`signature-request-manager-signing-${selectedManager.uuid}`]
			if (signingSignatureRequests && signingSignatureRequests.length) {
				signatureRequests = signatureRequests.concat(signingSignatureRequests)
			}

			const completedSignatureRequests =
				ordered[`signature-request-manager-complete-${selectedManager.uuid}`]
			if (completedSignatureRequests && completedSignatureRequests.length) {
				signatureRequests = signatureRequests.concat(completedSignatureRequests)
			}
		}

		if (queryParams.includeComplete !== 'true') {
			signatureRequests = signatureRequests.filter(
				(sr) => sr.status !== 'complete',
			)
		}

		signatureRequests = signatureRequests.filter(
			(sr) => !sr.enabledSignMethods.includes('paper'),
		)

		const renderFilters = () => (
			<FiltersContainer>
				<div style={{ maxWidth: 400, marginRight: 16 }}>
					<SearchFieldDropdown
						label={intl.formatMessage({
							defaultMessage: 'Kohde',
							description: 'Label of the company dropdown menu.',
						})}
						options={selectedManagerCompanies.concat({
							name: intl.formatMessage({
								defaultMessage: 'Ei valittu',
								description:
									'Label of the "not chosen" value in the company dropdown menu.',
							}),
							uuid: 'not-chosen',
						})}
						_onOptionSelect={this.setCompanyFilter}
						hasSelectAll={false}
						nullSelectAll={false}
						itemLabel={'name'}
						itemValue={'uuid'}
						icon={'home'}
						value={queryParams.companyUUID || 'not-chosen'}
					/>
				</div>
				<div style={{ width: 128, marginRight: 16 }}>
					<Checkbox
						id={'manager-signature-request-include-complete'}
						name={'manager-signature-request-include-complete'}
						label={intl.formatMessage({
							defaultMessage: 'Näytä valmiit',
							description:
								'Label of the checkbox that allows the user to show or hide the completed signature requests.',
						})}
						checked={queryParams.includeComplete === 'true'}
						onChange={this.setCompleteFilter}
						style={{ marginBottom: 6 }}
					/>
				</div>
				<div style={{ marginBottom: 8, marginRight: 16 }}>
					<p style={{ fontSize: 11, marginLeft: 8 }}>Hakuvälin alkupäivä</p>
					<DateSelect
						dateValue={parseInt(queryParams.minTs, 10) || getMinTs()}
						emptyText={'Aseta alkupäivä'}
						onDateUpdate={this.setMinTs}
					/>
				</div>
				<div style={{ marginBottom: 8 }}>
					<p style={{ fontSize: 11, marginLeft: 8 }}>Hakuvälin loppupäivä</p>
					<DateSelect
						dateValue={parseInt(queryParams.maxTs) || getMaxTs()}
						emptyText={'Aseta loppupäivä'}
						onDateUpdate={this.setMaxTs}
					/>
				</div>
			</FiltersContainer>
		)

		const headerMessage = intl.formatMessage({
			defaultMessage: 'Allekirjoituspyynnöt',
			description: 'Header of the manager signature request listing view.',
		})
		const emptyMessage = intl.formatMessage({
			defaultMessage: 'Ei allekirjoituspyyntöjä',
			description:
				'Paragraph telling the user that there are no signature requests',
		})

		if (isEmpty(signatureRequests) || signatureRequests.length === 0) {
			return (
				<div className="flex-column flex-one full-height">
					<RouteHeader header={headerMessage} />
					{renderFilters()}
					<EmptyState text={emptyMessage} icon={surveysEmptyIcon} />
				</div>
			)
		}

		signatureRequests = signatureRequests.map((sr) => {
			const company = selectedManagerCompanies.find(
				(c) => c.uuid === sr.companyUUID,
			)
			const result = {
				...sr,
				companyName: company?.name || 'SIIRTYNYT KOHDE',
				companyUrlName: company?.urlName || null,
			}
			return result
		})

		if (correlationCollectionOverride && correlationCollectionOverride !== '') {
			signatureRequests = signatureRequests.filter(
				(sr) =>
					sr.correlationCollectionOverride === correlationCollectionOverride,
			)
		}
		let sortFunc
		if (sortBy === 'companyName') {
			sortFunc = byCompanyName
		} else if (sortBy === 'status') {
			sortFunc = byStatus
		} else if (sortBy === 'ts') {
			sortFunc = byTs
		} else if (sortBy === 'name') {
			sortFunc = byName
		} else if (sortBy === 'author') {
			sortFunc = byAuthor
		}

		let sortedSignatureRequests = [...signatureRequests].sort(sortFunc)
		if (sortAscending) {
			sortedSignatureRequests = sortedSignatureRequests.reverse()
		}

		return (
			<div className="full-width">
				<RouteHeader header={headerMessage} />
				{renderFilters()}
				<DataTable plain fixedHeader style={{ background: 'white' }}>
					<TableHeader>
						<TableRow>
							<TableColumn
								sorted={sortBy === 'status' ? sortAscending : undefined}
								onClick={() => this.sortBy('status')}
								style={{ paddingRight: 8 }}
							>
								<p className="text-strong text-subtle">
									<FormattedMessage
										defaultMessage="Tila"
										description="Header of the signature request status column."
									/>
								</p>
							</TableColumn>
							<TableColumn style={{ paddingRight: 8 }}>
								<p className="text-strong text-subtle"></p>
							</TableColumn>
							<TableColumn
								sorted={sortBy === 'ts' ? sortAscending : undefined}
								onClick={() => this.sortBy('ts')}
								style={{ paddingRight: 8 }}
							>
								<p className="text-strong text-subtle">
									<FormattedMessage
										defaultMessage="Luotu"
										description="Header of the signature request creation date column."
									/>
								</p>
							</TableColumn>
							<TableColumn
								sorted={sortBy === 'author' ? sortAscending : undefined}
								onClick={() => this.sortBy('author')}
								style={{ paddingRight: 8 }}
							>
								<p className="text-strong text-subtle">
									<FormattedMessage
										defaultMessage="Luoja"
										description="Header of the signature request author column."
									/>
								</p>
							</TableColumn>
							<TableColumn
								sorted={sortBy === 'companyName' ? sortAscending : undefined}
								onClick={() => this.sortBy('companyName')}
								style={{ paddingRight: 8 }}
							>
								<p className="text-strong text-subtle">
									<FormattedMessage
										defaultMessage="Kohde"
										description="Header of the signature request company column."
									/>
								</p>
							</TableColumn>
							<TableColumn
								sorted={sortBy === 'name' ? sortAscending : undefined}
								onClick={() => this.sortBy('name')}
								style={{ paddingRight: 8 }}
							>
								<p className="text-strong text-subtle">
									<FormattedMessage
										defaultMessage="Asiakirjan nimi"
										description="Header of the signature request file name column."
									/>
								</p>
							</TableColumn>
							<TableColumn style={{ paddingRight: 8 }}>
								<p className="text-strong text-subtle">
									<FormattedMessage
										defaultMessage="Toiminnot"
										description="Header of the signature request actions column."
									/>
								</p>
							</TableColumn>
						</TableRow>
					</TableHeader>
					<TableBody>
						{sortedSignatureRequests
							.map((sr) => (
								<SignatureRequestRow key={sr.uuid} signatureRequest={sr} />
							))
							.concat(
								// Concat one empty row to indicate list ending to the user
								<tr
									key="empty-row"
									className="full-width"
									style={{ height: 64, background: 'white' }}
								></tr>,
							)}
					</TableBody>
					<OverflowGradient />
				</DataTable>
			</div>
		)
	}
}

const getMinTs = () => {
	return moment(new Date()).subtract(3, 'months').valueOf()
}

const getMaxTs = () => {
	return moment(new Date()).valueOf()
}

const byCompanyName = (a, b) => {
	if (
		(a.companyName || '').toLowerCase() > (b.companyName || '').toLowerCase()
	) {
		return 1
	}
	if (
		(a.companyName || '').toLowerCase() < (b.companyName || '').toLowerCase()
	) {
		return -1
	}
	return 0
}

const byName = (a, b) => {
	const aLatestVersionName =
		a.versions[a.versions.length - 1].fileName.toLowerCase()
	const bLatestVersionName =
		b.versions[b.versions.length - 1].fileName.toLowerCase()

	return naturalSort(aLatestVersionName, bLatestVersionName)
}

const byAuthor = (a, b) => {
	const aAuthorName = a.author.name.toLowerCase()
	const bAuthorName = b.author.name.toLowerCase()

	return naturalSort(aAuthorName, bAuthorName)
}

const byStatus = (a, b) => {
	if ((a.status || '').toLowerCase() > (b.status || '').toLowerCase()) {
		return 1
	}
	if ((a.status || '').toLowerCase() < (b.status || '').toLowerCase()) {
		return -1
	}
	return 0
}

const byTs = (a, b) => {
	return b.ts - a.ts
}

const mapState = ({
	firestoreReducer: { ordered },
	ui,
	dimensions: { isDesktop },
}) => ({
	ordered,
	ui,
	isDesktop,
})

const mapDispatchToProps = (dispatch) => ({
	_push: bindActionCreators(push, dispatch),
})

export default compose(
	injectIntl,
	WithoutAuthShowLoginDialog,
	WithSelectedManagerCompanies({ hideLoading: false }),
	WithQueryParams,
	firestoreConnect(({ selectedManager, queryParams }) => {
		const minTs = parseInt(queryParams.minTs, 10) || getMinTs()
		const maxTs = parseInt(queryParams.maxTs, 10) || getMaxTs()
		if (queryParams.companyUUID && queryParams.companyUUID !== 'not-chosen') {
			const queries = [
				{
					collection: 'signature-request',
					storeAs: `signature-request-initialized-${queryParams.companyUUID}`,
					where: [
						['companyUUID', '==', queryParams.companyUUID],
						['ts', '>', minTs],
						['ts', '<', maxTs],
						['status', '==', 'initialized'],
					],
				},
				{
					collection: 'signature-request',
					storeAs: `signature-request-signing-${queryParams.companyUUID}`,
					where: [
						['companyUUID', '==', queryParams.companyUUID],
						['ts', '>', minTs],
						['ts', '<', maxTs],
						['status', '==', 'signing'],
					],
				},
			]

			if (queryParams.includeComplete) {
				queries.push({
					collection: 'signature-request',
					storeAs: `signature-request-complete-${queryParams.companyUUID}`,
					where: [
						['companyUUID', '==', queryParams.companyUUID],
						['ts', '>', minTs],
						['ts', '<', maxTs],
						['status', '==', 'complete'],
					],
				})
			}

			return queries
		}

		const queries = [
			{
				collection: 'signature-request',
				storeAs: `signature-request-manager-initialized-${
					selectedManager ? selectedManager.uuid : 'empty-value'
				}`,
				where: [
					[
						'managerUUID',
						'==',
						selectedManager ? selectedManager.uuid : 'empty-value',
					],
					['ts', '>', minTs],
					['ts', '<', maxTs],
					['status', '==', 'initialized'],
				],
			},
			{
				collection: 'signature-request',
				storeAs: `signature-request-manager-signing-${
					selectedManager ? selectedManager.uuid : 'empty-value'
				}`,
				where: [
					[
						'managerUUID',
						'==',
						selectedManager ? selectedManager.uuid : 'empty-value',
					],
					['ts', '>', minTs],
					['ts', '<', maxTs],
					['status', '==', 'signing'],
				],
			},
		]

		if (queryParams.includeComplete) {
			queries.push({
				collection: 'signature-request',
				storeAs: `signature-request-manager-complete-${
					selectedManager ? selectedManager.uuid : 'empty-value'
				}`,
				where: [
					[
						'managerUUID',
						'==',
						selectedManager ? selectedManager.uuid : 'empty-value',
					],
					['ts', '>', minTs],
					['ts', '<', maxTs],
					['status', '==', 'complete'],
				],
			})
		}

		return queries
	}),
	connect(mapState, mapDispatchToProps),
)(ManagerSignatureRequests)
