import config from 'config'
import { FontIcon, Tab, Tabs } from 'react-md'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import { useIntl } from 'react-intl'
import InvoiceView from './InvoiceView'
import InvoiceActions from './InvoiceActions'
import ConsolidatedInvoice from '../ConsolidatedInvoices'

const HASH_INVOICES = config.routeHashes.invoices
const HASH_INVOICE_ACTIONS = config.routeHashes.invoiceActions
const HASH_CONSOLIDATED_INVOICE = config.routeHashes.consolidatedInvoice

const Invoices = () => {
	const dispatch = useDispatch()
	const intl = useIntl()

	const setPageInvoices = () => {
		dispatch(push(HASH_INVOICES))
	}

	const setPageConsolidatedInvoice = () => {
		dispatch(push(HASH_CONSOLIDATED_INVOICE))
	}

	const setPageInvoiceActions = () => {
		dispatch(push(HASH_INVOICE_ACTIONS))
	}

	const getTabIndex = () => {
		if (
			window.location.hash &&
			window.location.hash.includes(HASH_INVOICE_ACTIONS)
		) {
			return 2
		}
		if (
			window.location.hash &&
			window.location.hash.includes(HASH_CONSOLIDATED_INVOICE)
		) {
			return 1
		}
		return 0
	}

	const getTabs = () => {
		const tabIndex = getTabIndex()

		return [
			<Tab
				key={1}
				style={
					tabIndex === 0
						? {
								color: 'var(--color-primary-dark)',
								fontWeight: '700',
						  }
						: {
								color: 'var(--color-text-subtle)',
						  }
				}
				label={intl.formatMessage({
					defaultMessage: 'Laskut',
					description: 'Title of invoices',
				})}
				icon={<FontIcon>group</FontIcon>}
				onClick={setPageInvoices}
			/>,
			<Tab
				key={2}
				style={
					tabIndex === 1
						? {
								color: 'var(--color-primary-dark)',
								fontWeight: '700',
						  }
						: {
								color: 'var(--color-text-subtle)',
						  }
				}
				label={intl.formatMessage({
					defaultMessage: 'Koontilaskut',
					description: 'Title of consolitad invoice',
				})}
				icon={<FontIcon>group</FontIcon>}
				onClick={setPageConsolidatedInvoice}
			/>,
			<Tab
				key={3}
				style={
					tabIndex === 2
						? {
								color: 'var(--color-primary-dark)',
								fontWeight: '700',
						  }
						: {
								color: 'var(--color-text-subtle)',
						  }
				}
				label={intl.formatMessage({
					defaultMessage: 'Toiminnot',
					description: 'Title of invoice actions',
				})}
				icon={<FontIcon>group</FontIcon>}
				onClick={setPageInvoiceActions}
			/>,
		]
	}
	const tabIndex = getTabIndex()

	const renderPage = () => {
		let pageComponent
		if (tabIndex === 0) {
			pageComponent = <InvoiceView />
		} else if (tabIndex === 1) {
			pageComponent = <ConsolidatedInvoice />
		} else if (tabIndex === 2) {
			pageComponent = <InvoiceActions />
		}

		return (
			<div
				className="flex-column flex-grow"
				style={{ overflowY: 'auto', height: 'auto', minHeight: '400px' }}
			>
				{pageComponent}
			</div>
		)
	}

	return (
		<div className="flex-column" style={{ flexGrow: 1, overflow: 'hidden' }}>
			<Tabs
				tabId="users-mobile-tab"
				mobile
				style={{
					flexShrink: 0,
					borderBottomStyle: 'solid',
					borderBottomColor: 'rgba(0,0,0,0.2)',
					borderBottomWidth: 1,
				}}
				alignToKeyline={false}
				defaultTabIndex={tabIndex}
				activeTabIndex={tabIndex}
				indicatorHeight={3}
			>
				{getTabs()}
			</Tabs>
			{renderPage()}
		</div>
	)
}

export default Invoices
