import styled from 'styled-components'
import { useIntl } from 'react-intl'
import DistributionSelection from 'ui/MessageService/MessageServiceProcess/steps/Recipients/steps/Integrations/DistributionSelection'
import { StepContainer } from 'ui/MessageService/MessageServiceProcess/steps/styled'

const Content = styled.div`
	max-width: 600px;
	padding: 24px 32px;
	margin: 16px auto;
	background: #f6f7f9;
	border-radius: 8px;
`

function Integrations({ messageServiceProcess }) {
	const intl = useIntl()

	return (
		<StepContainer>
			<Content>
				<p style={{ marginBottom: 16 }}>
					{intl.formatMessage({
						defaultMessage: 'Valitse kenelle viesti näkyy integraatioissa.',
						description:
							'Description of the message service integration settings step.',
					})}
				</p>
				<DistributionSelection
					distribution={
						messageServiceProcess.integrationsSettings?.distribution
					}
				/>
			</Content>
		</StepContainer>
	)
}

export default Integrations
