import { useEffect, useState } from 'react'
import { injectIntl } from 'react-intl'
import { compose, bindActionCreators } from 'redux'
import { Button, TextField, FontIcon } from 'react-md'
import styled from 'styled-components'
import { CircularProgress } from 'react-md'

import * as notifyActions from 'state/notifyActions'
import * as userActions from 'state/user-actions'
import { connect } from 'react-redux'
import PhoneInput from 'react-phone-number-input'
import WithSelectedCompanyManager from 'ui/components/WithSelectedCompanyManager'
import WithLoggedInUser from 'ui/components/WithLoggedInUser'
import WithSelectedCompanyRole from 'ui/components/WithSelectedCompanyRole'
import ReactFlagsSelect from 'react-flags-select'
import { StandardToolbar } from 'ui/StyledComponents/BasicComponents'
import bg from 'svg/illustrations/illustration_abstract_bg.svg'
import { getAuth, signOut } from 'firebase/auth'
import { firebaseApp } from 'state/store'
import { ONBOARDING_UPDATE_ERROR } from '../messages'
import FB_ERRORS from '../../../util/firebaseErrors'
import useDialogs from 'ui/components/dialogs/useDialogs'
import ViiluDialog from 'ui/components/ViiluDialog'

const InfoText = styled.p`
	font-size: 16px;
	margin: 16px 0 8px 0;
`

const TextInput = styled(TextField)`
	label {
		font-size: 16px;
		font-family: 'Lato', sans-serif;
	}

	input,
	textarea {
		padding-top: 8px;
		font-family: 'Lato', sans-serif;
		font-size: 18px;
	}
`

const FixedContainer = styled.div`
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: white;
`

const DoneText = styled.p`
	font-size: 20px;
	margin-top: 16px;
	text-align: center;
`

const Error = styled.p`
	color: red;
	margin-top: 16px;
	text-align: right;
`

const inputInit = {
	value: '',
	error: false,
}

const formInit = {
	firstName: inputInit,
	lastName: inputInit,
	email: inputInit,
	phoneNumber: inputInit,
	address: inputInit,
	area: inputInit,
	zip: inputInit,
	country: 'FI',
}

const FormStatus = {
	INPUT: 'input',
	SUBMITTING: 'submitting',
	DONE: 'done',
}

const ONLY_DIGITS = new RegExp('^[0-9]+$')

function UserUpdateContactDetails({
	intl,
	loggedInUser,
	selectedCompanyRole,
	selectedCompanyManager,
	location,
	_updateProfile,
	_showInfoNotification,
}) {
	const [formValues, setFormValues] = useState(formInit)
	const [status, setStatus] = useState(FormStatus.INPUT)
	const [errorMessage, setErrorMessage] = useState('')
	const [visible, setVisible] = useState(false)
	const { alert } = useDialogs()

	const hasCompletedOnboarding = () => {
		const hasCompleted = loggedInUser && loggedInUser.displayName
		return hasCompleted
	}

	const isInfoscreenRoute = () => {
		const isInfoscreenRoute =
			location &&
			location.pathname &&
			location.pathname.includes('/internal/infoscreen')
		return isInfoscreenRoute
	}

	const isSysAdmin = () => {
		// Strict equality comparison by design
		return selectedCompanyRole && selectedCompanyRole === 'admin'
	}

	useEffect(() => {
		if (visible) {
			return
		}

		if (isSysAdmin()) {
			return
		}

		if (isInfoscreenRoute()) {
			return
		}

		if (!hasCompletedOnboarding()) {
			return
		}

		if (!loggedInUser) {
			return
		}

		if (!selectedCompanyManager) {
			return
		}

		let shouldShow = false

		// Phone number forcing
		if (
			selectedCompanyManager.forceCustomerPhoneNumberCollection &&
			!loggedInUser.phoneNumber
		) {
			shouldShow = true
		}

		// Address forcing
		if (
			selectedCompanyManager.forceCustomerAddressCollection &&
			(!loggedInUser.address ||
				!loggedInUser.zip ||
				!loggedInUser.area ||
				!loggedInUser.country)
		) {
			shouldShow = true
		}

		if (shouldShow) {
			setVisible(true)

			const splits = loggedInUser.displayName
				? loggedInUser.displayName.split(' ')
				: null
			const firstName = splits ? splits[0] : ''
			const lastName =
				splits && splits.length > 1 ? splits.slice(1).join(' ') : '' // last name can have spaces

			setFormValues({
				...formValues,
				firstName: {
					value: firstName,
					error: false,
				},
				lastName: {
					value: lastName,
					error: false,
				},
				email: {
					value: loggedInUser.email ? loggedInUser.email : '',
					error: false,
				},
				phoneNumber: {
					value: loggedInUser.phoneNumber ? loggedInUser.phoneNumber : '',
					error: false,
				},
				address: {
					value: loggedInUser.address ? loggedInUser.address : '',
					error: false,
				},
				zip: {
					value: loggedInUser.zip ? loggedInUser.zip : '',
					error: false,
				},
				area: {
					value: loggedInUser.area ? loggedInUser.area : '',
					error: false,
				},
				country: {
					value: loggedInUser.country ? loggedInUser.country : 'FI',
					error: false,
				},
			})
		} else {
			setVisible(false)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [visible, selectedCompanyManager, loggedInUser])

	const isValidPhonenumber = (value) => {
		if (!value) {
			return false
		}
		if (value && value[0] !== '+') {
			return false
		}
		return /^\d{7,}$/.test(value.replace(/[\s()+\-.]|ext/gi, ''))
	}

	const validateForm = () => {
		const updatedForm = {
			...formValues,
		}

		let isValid = true

		if (!formValues.firstName.value) {
			updatedForm.firstName.error = true
			isValid = false
		}

		if (!formValues.lastName.value) {
			updatedForm.lastName.error = true
			isValid = false
		}

		if (!formValues.email.value) {
			updatedForm.email.error = true
			isValid = false
		}

		const phoneNumberRequired =
			selectedCompanyManager &&
			selectedCompanyManager.forceCustomerPhoneNumberCollection

		if (phoneNumberRequired) {
			if (!formValues.phoneNumber.value) {
				updatedForm.phoneNumber.error = true
				isValid = false
			}

			// Normally the phone number field is optional
			if (
				formValues.phoneNumber.value &&
				!isValidPhonenumber(formValues.phoneNumber.value)
			) {
				updatedForm.phoneNumber.error = true
				isValid = false
			}
		}

		const addressRequired =
			selectedCompanyManager &&
			selectedCompanyManager.forceCustomerAddressCollection

		if (addressRequired) {
			if (!formValues.address.value) {
				updatedForm.address.error = true
				isValid = false
			}

			if (!formValues.area.value) {
				updatedForm.area.error = true
				isValid = false
			}

			if (!formValues.zip.value) {
				updatedForm.zip.error = true
				isValid = false
			}

			if (!formValues.zip.value.match(ONLY_DIGITS)) {
				updatedForm.zip.error = true
				isValid = false
			}

			// Zip code is 5 numbers in finland
			if (
				formValues.country.value === 'FI' &&
				formValues.zip.value.length !== 5
			) {
				updatedForm.zip.error = true
				isValid = false
			}

			if (!formValues.country.value) {
				updatedForm.country.error = true
				isValid = false
			}
		}

		setFormValues(updatedForm)

		return isValid
	}

	const handleSubmit = async () => {
		if (!validateForm()) {
			const message = intl.formatMessage({
				defaultMessage: 'Täytä tarvittavat tiedot.',
				description:
					'Error message telling the user to fill in all the required fields.',
			})
			setErrorMessage(message)
			return
		}

		setStatus(FormStatus.SUBMITTING)

		const displayName =
			formValues.firstName.value + ' ' + formValues.lastName.value

		const result = await _updateProfile(
			displayName.trim(),
			formValues.email.value.trim(),
			formValues.phoneNumber.value.trim(),
			undefined,
			formValues.address.value.trim(),
			formValues.zip.value.trim(),
			formValues.area.value.trim(),
			formValues.country.value.trim(),
		)
		if (!result || result.success === false) {
			setStatus(FormStatus.INPUT)
			if (result?.ex?.code === FB_ERRORS.FUNCTIONS_ALREADY_EXISTS) {
				if (result?.ex?.message && result.ex.message.includes('email')) {
					const message = intl.formatMessage({
						defaultMessage:
							'Tapahtui virhe. Syöttämäsi sähköpostiosoite on jo toisen käyttäjän käytössä. Vinkki: onko mahdollisesti käynyt niin, että olet jo aiemmin luonut tunnuksen tällä sähköpostiosoitteella? Mikäli näin on käynyt suosittelemme sinua kirjautumaan sisään aiemmin luodulla tunnuksella.',
						description:
							'Error message telling the user that the email address is already in use by another user.',
					})
					await alert(message)
				} else if (
					result?.ex?.message &&
					result.ex.message.includes('phoneNumber')
				) {
					const message = intl.formatMessage({
						defaultMessage:
							'Tapahtui virhe. Syöttämäsi puhelinnumero on jo toisen käyttäjän käytössä. Vinkki: onko mahdollisesti käynyt niin, että olet jo aiemmin luonut tunnuksen tällä puhelinnumerolla? Mikäli näin on käynyt suosittelemme sinua kirjautumaan sisään aiemmin luodulla tunnuksella.',
						description:
							'Error message telling the user that the phone number is already in use by another user.',
					})
					await alert(message)
				} else {
					const message = intl.formatMessage({
						defaultMessage:
							'Tapahtui virhe. Syöttämäsi tieto on jo toisen käyttäjän käytössä.',
						description:
							'Error message telling the user that the information is already in use by another user.',
					})
					await alert(message)
				}
			} else {
				const message = intl.formatMessage({
					defaultMessage:
						'Tapahtui virhe. Tarkistathan sähköpostiosoitteen ja puhelinnumeron muodon. Muista maatunnus puhelinnumerossa.',
					description:
						'Error message telling the user that there was an error creating their account.',
				})
				await alert(message)
			}
			_showInfoNotification('❌ ' + ONBOARDING_UPDATE_ERROR(intl))
			return false
		}
		setStatus(FormStatus.DONE)
	}

	const logout = () => {
		signOut(getAuth(firebaseApp))
		window.location.reload(true)
	}

	const handleInputChange = (input, value) => {
		setFormValues({
			...formValues,
			[input]: {
				value,
				error: false,
			},
		})
		setErrorMessage('')
	}

	const renderDialogContent = () => {
		const phoneNumberRequired =
			selectedCompanyManager &&
			selectedCompanyManager.forceCustomerPhoneNumberCollection

		const addressRequired =
			selectedCompanyManager &&
			selectedCompanyManager.forceCustomerAddressCollection

		if (status === FormStatus.INPUT) {
			return (
				<>
					<StandardToolbar
						title={'Tarkista henkilökohtaiset tietosi'}
						style={{ width: '100%' }}
					/>
					<section style={{ padding: 24 }} className="md-toolbar-relative">
						<InfoText>
							Tarkistathan, että tietosi ovat ajan tasalla. Tietoja käytetään
							isännöintiin ja taloyhtiöasioiden hoitoon.
						</InfoText>

						<div
							className="flex-row margin-top--xl"
							style={{ alignItems: 'flex-end' }}
						>
							<FontIcon
								style={{ marginRight: 20, marginBottom: 16, fontSize: 32 }}
							>
								person
							</FontIcon>
							<TextInput
								id="contact-first-name"
								label={intl.formatMessage({
									defaultMessage: 'Etunimi',
									description: 'Placeholder value of the first name field.',
								})}
								value={formValues.firstName.value}
								onChange={(value) => handleInputChange('firstName', value)}
								error={formValues.firstName.error}
								required
							/>
						</div>

						<div
							className="flex-row margin-top--xl"
							style={{ alignItems: 'flex-end' }}
						>
							<FontIcon
								style={{ marginRight: 20, marginBottom: 16, fontSize: 32 }}
							>
								person
							</FontIcon>
							<TextInput
								id="contact-last-name"
								label={intl.formatMessage({
									defaultMessage: 'Sukunimi',
									description: 'Placeholder value of the last name field.',
								})}
								value={formValues.lastName.value}
								onChange={(value) => handleInputChange('lastName', value)}
								error={formValues.lastName.error}
								required
							/>
						</div>

						<div
							className="flex-row margin-top--xl"
							style={{ alignItems: 'flex-end' }}
						>
							<FontIcon
								style={{ marginRight: 20, marginBottom: 16, fontSize: 32 }}
							>
								alternate_email
							</FontIcon>
							<TextInput
								id="contact-email"
								label={intl.formatMessage({
									defaultMessage: 'Sähköpostiosoite',
									description: 'Placeholder value of the email field.',
								})}
								value={formValues.email.value}
								onChange={(value) => handleInputChange('email', value)}
								error={formValues.email.error}
								required
							/>
						</div>

						<div
							style={{
								borderBottomColor: formValues.phoneNumber.error
									? 'rgba(255,0,0,0.87)'
									: undefined,
								borderBottomStyle: 'solid',
								borderBottomWidth: formValues.phoneNumber.error ? 2 : 0,
								width: '100%',
								marginTop: 54,
							}}
						>
							<div className="full-width">
								<p
									style={{
										marginTop: 0,
										marginBottom: 8,
										marginLeft: 50,
										color: 'rgba(0,0,0,0.6)',
									}}
								>
									{intl.formatMessage({
										defaultMessage: 'Puhelinnumero',
										description: 'Placeholder value of the phone number field.',
									}) + (phoneNumberRequired ? ' *' : '')}
								</p>
								<PhoneInput
									placeholder={''}
									value={formValues.phoneNumber.value || ''}
									onChange={(value) => handleInputChange('phoneNumber', value)}
									defaultCountry={'FI'}
									style={{
										fontSize: 'var(--text-md)',
									}}
								/>
							</div>
						</div>

						<div
							className="flex-row margin-top--xl full-width"
							style={{ alignItems: 'flex-end' }}
						>
							<FontIcon
								style={{ marginRight: 20, marginBottom: 16, fontSize: 32 }}
							>
								public
							</FontIcon>
							<div className="full-width" style={{ marginBottom: 4 }}>
								<p
									style={{
										marginTop: 0,
										marginBottom: 8,
										color: 'rgba(0,0,0,0.6)',
									}}
								>
									{'Maa' + (addressRequired ? ' *' : '')}
								</p>
								<ReactFlagsSelect
									selected={formValues.country.value}
									onSelect={(value) => handleInputChange('country', value)}
									selectButtonClassName={'country-select-button'}
									searchable
									placeholder={intl.formatMessage({
										defaultMessage: 'Valitse maa',
										description: 'Placeholder of the country select input.',
									})}
									searchPlaceholder={intl.formatMessage({
										defaultMessage: 'Hae',
										description:
											'Placeholder of the country select search bar.',
									})}
								/>
							</div>
						</div>

						<div
							className="flex-row margin-top--xl"
							style={{ alignItems: 'flex-end' }}
						>
							<FontIcon
								style={{ marginRight: 20, marginBottom: 16, fontSize: 32 }}
							>
								pin_drop
							</FontIcon>
							<TextInput
								id="contact-zip"
								label={intl.formatMessage({
									defaultMessage: 'Postinumero',
									description: 'Placeholder value of the zip code field.',
								})}
								value={formValues.zip.value}
								onChange={(value) => handleInputChange('zip', value)}
								error={formValues.zip.error}
								required={addressRequired}
							/>
						</div>

						<div
							className="flex-row margin-top--xl"
							style={{ alignItems: 'flex-end' }}
						>
							<FontIcon
								style={{ marginRight: 20, marginBottom: 16, fontSize: 32 }}
							>
								pin_drop
							</FontIcon>
							<TextInput
								id="contact-area"
								label={intl.formatMessage({
									defaultMessage: 'Postitoimipaikka',
									description: 'Placeholder value of the postal area field.',
								})}
								value={formValues.area.value}
								onChange={(value) => handleInputChange('area', value)}
								error={formValues.area.error}
								required={addressRequired}
							/>
						</div>

						<div
							className="flex-row margin-top--xl"
							style={{ alignItems: 'flex-end' }}
						>
							<FontIcon
								style={{ marginRight: 20, marginBottom: 16, fontSize: 32 }}
							>
								home
							</FontIcon>
							<TextInput
								id="contact-address"
								label={intl.formatMessage({
									defaultMessage: 'Lähiosoite',
									description: 'Placeholder value of the postal address field.',
								})}
								value={formValues.address.value}
								onChange={(value) => handleInputChange('address', value)}
								error={formValues.address.error}
								required={addressRequired}
							/>
						</div>

						{errorMessage && <Error>{errorMessage}</Error>}
					</section>
				</>
			)
		}

		if (status === FormStatus.SUBMITTING) {
			return (
				<FixedContainer>
					<CircularProgress
						id="submit-contact-me-progress"
						style={{ marginTop: 64, marginBottom: 64 }}
					/>
				</FixedContainer>
			)
		}

		if (status === FormStatus.DONE) {
			return (
				<FixedContainer>
					<FontIcon style={{ color: 'green', fontSize: 64 }}>
						check_circle
					</FontIcon>
					<DoneText>
						{intl.formatMessage({
							defaultMessage: 'Kiitos tietojen vahvistamisesta!',
							description:
								'Thank you message shown to the user after validating their contact details.',
						})}
					</DoneText>
					<Button
						raised
						primary
						onClick={() => setVisible(false)}
						style={{ background: 'var(--color-primary-dark)', marginTop: 32 }}
					>
						{intl.formatMessage({
							defaultMessage: 'Sulje',
							description:
								'Button that allows the user to close the contact details validation dialog once completed.',
						})}
					</Button>
				</FixedContainer>
			)
		}

		return <p>Unknown form status.</p>
	}

	const renderActions = () => {
		if (status === FormStatus.INPUT) {
			return [
				<Button flat onClick={logout} key="logout">
					{intl.formatMessage({
						defaultMessage: 'Kirjaudu ulos',
						description: 'Button that allows the user to sign out.',
					})}
				</Button>,
				<Button
					raised
					primary
					onClick={handleSubmit}
					style={{ background: 'var(--color-primary-dark)' }}
					key="save"
				>
					{intl.formatMessage({
						defaultMessage: 'Tallenna',
						description:
							'Button that allows the user to submit the validated contact details.',
					})}
				</Button>,
			]
		}

		return []
	}

	return (
		<ViiluDialog
			id="contact-details-dialog"
			focusOnMount={status === FormStatus.INPUT}
			visible={visible}
			onHide={() => {}}
			aria-label="Päivitä yhteystietosi"
			dialogStyle={{
				width: '100vw',
				height: '100vh',
				margin: 0,
				padding: 0,
				maxWidth: '100vw',
				maxHeight: '100vh',
			}}
			paddedContent={false}
		>
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'flex-end',
					alignItems: 'flex-end',
				}}
			>
				<div
					style={{
						background: 'white',
						position: 'relative',
						maxWidth: 540,
					}}
				>
					{renderDialogContent()}
					<div
						style={{
							display: 'flex',
							justifyContent: 'flex-end',
							marginTop: 32,
							marginBottom: 16,
							marginRight: 16,
						}}
					>
						{renderActions()}
					</div>
				</div>
				<div
					style={{
						background: '#FAFAFA',
						position: 'fixed',
						height: '100vh',
						width: '100%',
						left: 0,
						top: 0,
						zIndex: -1,
					}}
				>
					<div
						style={{
							width: '100%',
							height: '100%',
							position: 'relative',
						}}
					>
						<img
							src={bg}
							alt="illustraatio"
							style={{
								objectFit: 'cover',
								width: '100%',
								height: '100%',
							}}
						/>
						<div
							style={{
								position: 'absolute',
								top: 0,
								left: 0,
								right: 0,
								bottom: 0,
								background: 'rgba(0,0,0,0.33)',
							}}
						></div>
					</div>
				</div>
			</div>
		</ViiluDialog>
	)
}

const mapState = ({ router: { location } }) => ({
	location,
})

const mapDispatchToProps = (dispatch) => ({
	_showInfoNotification: bindActionCreators(notifyActions.info, dispatch),
	_updateProfile: bindActionCreators(userActions._updateProfile, dispatch),
})

export default compose(
	injectIntl,
	WithLoggedInUser,
	WithSelectedCompanyRole,
	WithSelectedCompanyManager,
	connect(mapState, mapDispatchToProps),
)(UserUpdateContactDetails)
