import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { compose, bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { Toolbar, Button } from 'react-md'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { FormattedMessage, injectIntl } from 'react-intl'

import illustrationPhoneNumber from 'svg/illustrations/illustration_sms.svg'
import LoginDialogPhoneVerification from './LoginDialogPhoneVerification'
import ViiluDialog from 'ui/components/ViiluDialog'

const isValidPhonenumber = (value) => {
	if (value && value[0] !== '+') {
		return false
	}
	return /^\d{7,}$/.test(value.replace(/[\s()+\-.]|ext/gi, ''))
}

class LoginDialogPhone extends Component {
	state = {
		phoneNumber: null,
		stepIndex: 0,
	}

	onPhoneNumberChange = (phoneNumber) => {
		if (phoneNumber === '') {
			phoneNumber = null
		}
		this.setState({ phoneNumber })
	}

	validate = () => {
		const { phoneNumber } = this.state
		if (!phoneNumber) {
			return false
		}
		if (!isValidPhonenumber(phoneNumber)) {
			return false
		}
		return true
	}

	componentDidMount() {
		const { auth } = this.props
		if (auth && auth.uid) {
			this.onHide()
		}
	}

	componentWillReceiveProps(nextProps) {
		const { auth } = this.props
		if (!auth && nextProps.auth && nextProps.auth.uid) {
			this.onHide()
		}
	}

	onHide = () => {
		const { onHide } = this.props
		window.URLBeforeLogin = null
		this.setState({
			phoneNumber: null,
			stepIndex: 0,
		})
		onHide()
	}

	next = async () => {
		const { stepIndex } = this.state
		if (stepIndex === 0) {
			this.setState({ stepIndex: 1 })
		}
	}

	renderToolbar = (title) => {
		return (
			<Toolbar
				title={title}
				style={{
					display: 'flex',
					alignItems: 'center',
					position: 'fixed',
					width: '100%',
					zIndex: 51,
				}}
				nav={
					<Button icon onClick={this.onHide}>
						close
					</Button>
				}
			/>
		)
	}

	renderPhoneNumberStep = () => {
		const { intl, isDesktop } = this.props
		const { phoneNumber } = this.state
		return (
			<div className="flex-column flex-center">
				{isDesktop ? (
					<img
						alt=""
						src={illustrationPhoneNumber}
						style={{
							width: 100,
							height: 'auto',
							position: 'relative',
							margin: 16,
						}}
					/>
				) : null}
				<p className="text-center margin-bottom">
					<FormattedMessage
						defaultMessage="Syötä puhelinnumero, jonka olet aiemmin liittänyt käyttäjäprofiiliisi."
						description="Instructional paragraph in SMS code based login asking for a phone number that has been previously attached to an user profile."
					/>
				</p>
				<PhoneInput
					placeholder={intl.formatMessage({
						defaultMessage: 'Puhelinnumerosi',
						description:
							"Placeholder value of a field asking for the user's phone number",
					})}
					value={phoneNumber || ''}
					onChange={this.onPhoneNumberChange}
					defaultCountry={'FI'}
					style={{ fontSize: 'var(--text-md)' }}
				/>
				<Button
					raised={phoneNumber}
					flat={!phoneNumber}
					secondary
					onClick={this.next}
					className="margin-top--xl"
					disabled={!this.validate()}
					style={
						phoneNumber
							? { alignSelf: 'center', background: 'var(--color-secondary)' }
							: { alignSelf: 'center' }
					}
				>
					<FormattedMessage
						defaultMessage="Seuraava"
						description="Button that takes the user to the next step in the SMS code based login."
					/>
				</Button>
			</div>
		)
	}

	render() {
		const { stepIndex, phoneNumber } = this.state
		const { visible, intl } = this.props
		const dialogTitle = intl.formatMessage({
			defaultMessage: 'Kirjaudu tekstiviestillä',
			description: 'Title of the SMS code login dialog',
		})

		let stepElement = null
		if (stepIndex === 0) {
			stepElement = this.renderPhoneNumberStep()
		} else if (stepIndex === 1) {
			stepElement = (
				<LoginDialogPhoneVerification
					phoneNumber={phoneNumber}
					onHide={this.onHide}
				/>
			)
		}
		return (
			<ViiluDialog
				id="responsive-dialog"
				aria-label={dialogTitle}
				visible={visible}
				onHide={this.onHide}
				focusOnMount={false}
				containFocus={true}
				dialogClassName="responsive-dialog-widthless"
				contentClassName="responsive-dialog-content"
				autosizeContent={false}
				modal
				portal
				fullPage={!this.props.isDesktop}
				paddedContent
				onClick={(e) => {
					e.preventDefault()
					e.stopPropagation()
				}}
				style={{ zIndex: 101 }}
				dialogStyle={{ minWidth: 320, overflowX: 'hidden' }}
			>
				{this.renderToolbar(dialogTitle)}
				<section className="md-toolbar-relative" style={{ padding: 24 }}>
					{stepElement}
				</section>
			</ViiluDialog>
		)
	}
}

LoginDialogPhone.propTypes = {
	visible: PropTypes.bool.isRequired,
	onHide: PropTypes.func.isRequired,
}

const mapState = ({
	firebaseReducer: { auth },
	dimensions: { isDesktop },
}) => ({
	auth,
	isDesktop,
})

const mapDispatchToProps = (dispatch) => ({
	_push: bindActionCreators(push, dispatch),
})

export default compose(
	injectIntl,
	connect(mapState, mapDispatchToProps),
)(LoginDialogPhone)
