import styled from 'styled-components'
import StatusItem from '../../StatusItem'
import { Button } from 'react-md'
import { useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'

import * as postalBatchActions from 'state/postal-batch-actions'
import LoadingState from 'ui/components/LoadingState'
import { PostalBatchStatus } from '../status'
import { StatusUl, Title } from '../../styles'
import useDialogs from 'ui/components/dialogs/useDialogs'

const NoLetters = styled.p`
	margin: 0 0 16px 16px;
`

const BatchContainer = styled.div`
	background: #f6f7f9;
	padding: 16px;
	border-radius: 8px;
	margin-bottom: 16px;
`

const TitleRow = styled.div`
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
`

const StatusButtonContainer = styled.div`
	display: flex;
	align-items: center;

	span {
		font-size: 14px;
	}
`

function LetterStatus({ postalBatch, recipient, _statusRefresh }) {
	const { alert } = useDialogs()
	const [fetchingStatus, setFetchingStatus] = useState(false)
	const showHistory =
		postalBatch &&
		postalBatch.letters.some((letter) =>
			letter.recipients.some((r) => r.uuid === recipient.uuid),
		)

	let sortedStatusHistory
	let latestUpdateTs

	if (showHistory) {
		// Latest first
		const sorted = [...postalBatch.statusHistory].sort((a, b) => b.ts - a.ts)
		latestUpdateTs = sorted[0].ts
		sortedStatusHistory = sorted.filter((item, index, self) => {
			if (item.status.includes('watchdog')) {
				return false
			}
			// Remove duplicates
			return index === self.findIndex((x) => x.status === item.status)
		})
	}

	const fetchStatus = async (uuid) => {
		setFetchingStatus(true)
		const result = await _statusRefresh({ uuid })
		setFetchingStatus(false)

		if (!result || result.success === false) {
			await alert('Tapahtui virhe tilatietoa haettaessa.')
			return false
		}

		return true
	}

	return (
		<>
			{!showHistory ? (
				<BatchContainer>
					<Title>Kirje</Title>
					<NoLetters>Ei lähetettyjä kirjeitä.</NoLetters>
				</BatchContainer>
			) : (
				<BatchContainer>
					<TitleRow>
						<Title>Kirje</Title>
						<StatusButtonContainer>
							<span>
								Päivitetty{' '}
								{new Date(latestUpdateTs).toLocaleString('fi-FI', {
									dateStyle: 'medium',
									timeStyle: 'short',
								})}
							</span>

							<Button
								raised
								disabled={fetchingStatus}
								onClick={() => fetchStatus(postalBatch.uuid)}
								style={{ margin: '0 0 0 16px', backgroundColor: '#fff' }}
							>
								Päivitä tilatieto
							</Button>
						</StatusButtonContainer>
					</TitleRow>

					{fetchingStatus ? (
						<div style={{ margin: 16 }}>
							<LoadingState small />
						</div>
					) : null}

					<StatusUl>
						{sortedStatusHistory.map((status, index) =>
							getStatusItem(`letter-status-key-${index}`, status, index === 0),
						)}
					</StatusUl>
				</BatchContainer>
			)}
		</>
	)
}

const StatusIcon = {
	DEFAULT: {
		icon: 'circle',
		color: 'black',
	},
	WARNING: {
		icon: 'circle',
		color: 'orange',
	},
	ERROR: {
		icon: 'error',
		color: 'red',
	},
	SUCCESS: {
		icon: 'check_circle',
		color: 'green',
	},
}

function getStatusItem(key, status, isFirstItem) {
	let labelText
	let timestamp = status.ts
	let statusIcon = StatusIcon.DEFAULT

	switch (status.status) {
		case PostalBatchStatus.CREATED:
			labelText = 'Lähetys alustettu postituspalveluun.'
			break
		case PostalBatchStatus.UPLOAD_STARTED:
			labelText = 'Aineiston lähettäminen postituspalveluun aloitettu.'
			break
		case PostalBatchStatus.UPLOAD_SUCCESS:
			labelText = 'Aineisto lähetetty onnistuneesti postituspalveluun.'
			break
		case PostalBatchStatus.UPLOAD_FAILURE:
			labelText =
				'Aineiston lähettäminen postituspalveluun epäonnistui. Yritetään automaattisesti uudelleen myöhemmin.'
			statusIcon = StatusIcon.WARNING
			break
		case PostalBatchStatus.WATCHDOG_FAILED:
			labelText = 'Tilatietoa ei saatu haettua.'
			statusIcon = StatusIcon.WARNING
			break
		case PostalBatchStatus.WATCHDOG_WAITING:
			labelText = 'Odotetaan tilatietojen päivittymistä.'
			break
		case PostalBatchStatus.REGISTERED_SUCCESS:
			labelText = 'Postituspalvelu on ottanut aineiston vastaan.'
			timestamp = status.statusDetails?.arrivalTs || status.ts
			break
		case PostalBatchStatus.REGISTERED_FAILURE:
			labelText = 'Kirjaaminen postituspalvelun järjestelmään epäonnistui.'
			statusIcon = StatusIcon.ERROR
			break
		case PostalBatchStatus.PROCESSING_SUCCESS:
			labelText = 'Aineisto odottaa käsittelyä (tulostus, kuoritus).'
			break
		case PostalBatchStatus.PROCESSING_FAILURE:
			labelText = 'Aineiston käsittely epäonnistui.'
			statusIcon = StatusIcon.ERROR
			break
		case PostalBatchStatus.DELIVERED_SUCCESS:
			labelText = 'Kirjeet on toimitettu onnistuneesti jakeluyhtiölle.'
			timestamp = status.statusDetails?.deliveredTs || status.ts
			statusIcon = StatusIcon.SUCCESS
			break
		case PostalBatchStatus.DELIVERED_FAILURE:
			labelText = 'Kirjeiden toimittaminen jakeluyhtiölle epäonnistui.'
			statusIcon = StatusIcon.ERROR
			break
		case PostalBatchStatus.ABORTED:
			labelText = 'Keskeytetty.'
			statusIcon = StatusIcon.ERROR
			break
		default:
			labelText = 'Tuntematon tila'
			break
	}

	return (
		<StatusItem
			key={key}
			labelText={labelText}
			icon={statusIcon.icon}
			iconColor={statusIcon.color}
			timestamp={timestamp}
			isFirstItem={isFirstItem}
		/>
	)
}

const mapState = () => ({})

const mapDispatchToProps = (dispatch) => ({
	_statusRefresh: bindActionCreators(
		postalBatchActions._statusRefresh,
		dispatch,
	),
})

export default compose(connect(mapState, mapDispatchToProps))(LetterStatus)
