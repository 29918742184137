import ViiluDialog from 'ui/components/ViiluDialog'
import { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import {
	TextField,
	TextFieldTypes,
	CircularProgress,
	FontIcon,
	MenuButtonColumn,
	Button,
} from 'react-md'
import { useDispatch } from 'react-redux'
import useDialogs from 'ui/components/dialogs/useDialogs'
import { GENERIC_ERROR_NOTIFICATION } from 'ui/messages'
import * as notifyActions from 'state/notifyActions'
import useValueAddedTax from './useValueAddedTax'
import type { CreateValueAddedTax, ValueAddedTax } from './types'
import { valueAddedTaxHeaders } from './constants'
import {
	_createValueAddedTax,
	_updateValueAddedTax,
} from 'state/value-added-tax-actions'
import styled from 'styled-components'
import DatePickerISO from 'ui/components/DatePickerISO'
import { v4 as uuidv4 } from 'uuid'
import { validateValueAddedTaxForm } from './validate'
import { removeLeadingZero } from '../utils/utils'

const Header = styled.h3`
	font-size: 1.5rem;
`

const Horizontal = styled.div`
	display: flex;
	gap: 16px;
	margin-bottom: 16px;
`

type Props = {
	id?: string | null
	refresh: () => void
	visible: boolean
	onHide: () => void
}

const ValueAddedTaxDialog = ({ id, refresh, visible, onHide }: Props) => {
	const intl = useIntl()
	const dispatch = useDispatch()
	const { valueAddedTax } = useValueAddedTax(id)

	const initialFormData: CreateValueAddedTax | ValueAddedTax = {
		name: '',
		percentages: [
			{
				uuid: 'new' + uuidv4(),
				percentage: 0,
				startDate: null,
				endDate: null,
				deleted: false,
			},
		],
	}

	const [formData, setFormData] = useState<CreateValueAddedTax | ValueAddedTax>(
		initialFormData,
	)

	const [processing, setProcessing] = useState(false)
	const { alert } = useDialogs()
	const resetFormData = () => {
		setFormData(initialFormData)
	}

	useEffect(() => {
		if (valueAddedTax) {
			setFormData({
				uuid: valueAddedTax.uuid,
				name: valueAddedTax.name,
				percentages: valueAddedTax.percentages,
			})
		}
	}, [valueAddedTax])

	const handleInputChange = (
		value: string | number | boolean,
		field: string,
		type: string,
	) => {
		setFormData((prevData) => ({
			...prevData,
			[field]: type === 'number' ? Number(value) : value,
		}))
	}

	const hide = () => {
		resetFormData()
		onHide()
	}

	const handleSave = async (formData: ValueAddedTax) => {
		const result: any = await _createValueAddedTax(formData)
		setProcessing(false)
		if (!result || result.ok === false) {
			dispatch(notifyActions.info('❌ ' + GENERIC_ERROR_NOTIFICATION(intl)))
			return false
		}

		dispatch(
			notifyActions.info(
				'✅ ' +
					intl.formatMessage({
						defaultMessage: 'Arvonlisävero on lisätty.',
						description: 'Notification text of vat has been added',
					}),
			),
		)
		refresh()
		hide()
		return true
	}

	const handleUpdate = async (formData: ValueAddedTax) => {
		const result: any = await _updateValueAddedTax(formData)
		setProcessing(false)
		if (!result || result.ok === false) {
			dispatch(notifyActions.info('❌ ' + GENERIC_ERROR_NOTIFICATION(intl)))
			return false
		}

		dispatch(
			notifyActions.info(
				'✅ ' +
					intl.formatMessage({
						defaultMessage: 'Arvonlisävero on päivitetty.',
						description: 'Notification text of vat has been updated',
					}),
			),
		)
		refresh()
		hide()
		return true
	}

	const onProcess = async () => {
		setProcessing(true)

		const updatedFormData = {
			...formData,
			percentages: formData.percentages.map((p) => ({
				...p,
				percentage: Number(p.percentage) ?? 0,
			})),
		}

		const isValid = await validateValueAddedTaxForm(
			updatedFormData,
			alert,
			intl,
		)
		if (!isValid) {
			setProcessing(false)
			return
		}

		if ((updatedFormData as ValueAddedTax).uuid) {
			return await handleUpdate(updatedFormData as ValueAddedTax)
		} else {
			return await handleSave(updatedFormData as ValueAddedTax)
		}
	}

	const renderFormFields = () => {
		return valueAddedTaxHeaders.map((header) => {
			const { show, key, title, type, required } = header
			if (show) {
				return (
					<TextField
						key={key}
						id={key}
						label={title(intl)}
						lineDirection="center"
						floating
						fullWidth
						value={formData[key] || ''}
						onChange={(value) => handleInputChange(value, key, type)}
						required={required}
						type={(type as TextFieldTypes) || undefined}
					/>
				)
			}
			return null
		})
	}

	const renderPercentageFields = () => {
		return (
			<>
				<Header>
					{intl.formatMessage({
						defaultMessage: 'Prosentit',
						description: 'Header for the percentages.',
					})}
				</Header>
				{formData.percentages
					.sort((a, b) => {
						if (!a.startDate) return -1
						if (!b.startDate) return 1
						return (
							new Date(b.startDate).getTime() - new Date(a.startDate).getTime()
						)
					})
					.filter((p) => !p.deleted)
					.map((formPercentage) => {
						const { uuid, percentage, startDate, endDate } = formPercentage
						return (
							<Horizontal key={uuid}>
								<TextField
									id={`value-added-tax-percentage-${uuid}-input`}
									label={intl.formatMessage({
										defaultMessage: 'Prosentti',
										description:
											'Label for the percentage field in the vat form.',
									})}
									type="number"
									min={0}
									value={removeLeadingZero(percentage)}
									onChange={(value) => {
										setFormData((prev) => {
											const updated = prev.percentages.map((p) =>
												p.uuid === uuid
													? {
															...p,
															percentage:
																value === ''
																	? 0
																	: (removeLeadingZero(
																			value,
																	  ) as unknown as number),
													  }
													: p,
											)
											return { ...prev, percentages: updated }
										})
									}}
									required
								/>
								<DatePickerISO
									id={`value-added-tax-percentage-start-date-${uuid}-input`}
									label={intl.formatMessage({
										defaultMessage: 'Alkupäivämäärä',
										description:
											'Label for the start date field in the vat form.',
									})}
									value={startDate ?? ''}
									onChange={(value) => {
										setFormData((prev) => {
											const updated = prev.percentages.map((p) =>
												p.uuid === uuid
													? { ...p, startDate: value || null }
													: p,
											)
											return { ...prev, percentages: updated }
										})
									}}
									required
								/>
								<DatePickerISO
									id={`value-added-tax-percentage-end-date-${uuid}-input`}
									label={intl.formatMessage({
										defaultMessage: 'Loppupäivämäärä',
										description:
											'Label for the end date field in the vat form.',
									})}
									value={endDate ?? ''}
									onChange={(value) => {
										setFormData((prev) => {
											const updated = prev.percentages.map((p) =>
												p.uuid === uuid ? { ...p, endDate: value || null } : p,
											)
											return { ...prev, percentages: updated }
										})
									}}
								/>
								{formData.percentages.length > 1 && (
									<MenuButtonColumn
										style={{ marginTop: 16 }}
										icon
										menuItems={[
											formData.percentages.length > 1 && {
												leftIcon: <FontIcon>delete</FontIcon>,
												primaryText: intl.formatMessage({
													defaultMessage: 'Poista',
													description: 'Label for the delete vat button.',
												}),
												onClick: () => {
													if (uuid.includes('new')) {
														setFormData((prev) => {
															const updated = prev.percentages.filter(
																(p) => p.uuid !== uuid,
															)
															return { ...prev, percentages: updated }
														})
													} else {
														setFormData((prev) => {
															const updated = prev.percentages.map((p) =>
																p.uuid === uuid ? { ...p, deleted: true } : p,
															)
															return { ...prev, percentages: updated }
														})
													}
												},
											},
										]}
									>
										<FontIcon>more_vert</FontIcon>
									</MenuButtonColumn>
								)}
							</Horizontal>
						)
					})}
				<Button
					raised
					primary
					onClick={() => {
						setFormData((prev) => ({
							...prev,
							percentages: [
								...prev.percentages,
								{
									uuid: 'new' + uuidv4(),
									percentage: 0,
									startDate: null,
									endDate: null,
									deleted: false,
								},
							],
						}))
					}}
				>
					{intl.formatMessage({
						defaultMessage: 'Lisää prosentti',
						description:
							'Label for the add vat percentage button in the vat form.',
					})}
				</Button>
			</>
		)
	}

	const renderChildren = () => {
		return (
			<div
				className="margin-left--lg margin-right--lg"
				style={{ paddingBottom: 64 }}
			>
				{renderFormFields()}
				{renderPercentageFields()}
			</div>
		)
	}

	const actions = [
		{
			id: 'dialog-cancel',
			children: intl.formatMessage({
				defaultMessage: 'Peruuta',
				description: 'Cancel button',
			}),
			onClick: hide,
		},
		{
			id: 'dialog-ok',
			secondary: true,
			children: processing ? (
				<CircularProgress id="progress" style={{ marginTop: 0 }} />
			) : (
				intl.formatMessage({
					defaultMessage: 'Tallenna',
					description: 'Save button',
				})
			),
			onClick: onProcess,
		},
	]

	return (
		<ViiluDialog
			id="responsive-dialog"
			title={
				id
					? intl.formatMessage({
							defaultMessage: 'Muokkaa arvonlisäveroa',
							description: 'Title of edit vat',
					  })
					: intl.formatMessage({
							defaultMessage: 'Lisää arvonlisävero',
							description: 'Title of add vat',
					  })
			}
			visible={visible}
			actions={actions}
			onHide={hide}
			focusOnMount={true}
			containFocus={true}
			dialogClassName="responsive-dialog"
			contentClassName="responsive-dialog-content"
			autosizeContent={false}
			modal
			portal
			paddedContent
		>
			{renderChildren()}
		</ViiluDialog>
	)
}

export default ValueAddedTaxDialog
