export const _onUpdate = (update) => ({
	type: 'UI_USER_ONBOARDING_STATE_SET',
	update,
})

export const _decrementStepIndex = () => ({
	type: 'UI_USER_ONBOARDING_DECREMENT_STEP_INDEX',
})

export const _incrementStepIndex = () => ({
	type: 'UI_USER_ONBOARDING_INCREMENT_STEP_INDEX',
})

export const _resetStepIndex = () => ({
	type: 'UI_USER_ONBOARDING_RESET_STEP_INDEX',
})
