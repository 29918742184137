import { useCallback, useEffect, useState } from 'react'
import type { Loan } from './types'
import { _getLoan } from 'state/finance-loan.actions'

function useLoan(loanUUID: string) {
	const [loan, setLoan] = useState<Loan | null>(null)

	const [loadingLoan, setLoadingLoan] = useState<boolean>(false)
	const [errorLoan, setErrorLoan] = useState<string | null>(null)

	const doQuery = useCallback(async () => {
		setLoadingLoan(true)
		setErrorLoan(null)
		let data: Loan | null = null

		try {
			let response: Response | undefined = undefined
			response = await _getLoan(loanUUID)

			if (response && response.ok) {
				const res = await response.json()
				if (res.loan && typeof res.loan === 'object') data = res.loan
			} else {
				setErrorLoan('Failed to fetch loan')
			}
		} catch (err) {
			console.error('Error fetching loan:', err)
			setErrorLoan('An error occurred while fetching loan')
		}

		setLoan(data)
		setLoadingLoan(false)
	}, [loanUUID])

	useEffect(() => {
		doQuery()
	}, [doQuery])

	return {
		loan,
		loadingLoan,
		errorLoan,
		refreshLoan: doQuery,
	}
}

export default useLoan
