import { useCallback, useEffect, useState } from 'react'
import { _getContracts } from 'state/contract-actions'
import type { Contract, ContractFilters } from './types'
import naturalSort from 'javascript-natural-sort'

function useContracts(filters: ContractFilters) {
	const [contracts, setContracts] = useState<Contract[]>([])

	const [loadingContracts, setLoading] = useState<boolean>(false)
	const [errorContracts, setError] = useState<string | null>(null)

	const doQuery = useCallback(async () => {
		setLoading(true)
		setError(null)
		let data: Contract[] = []

		try {
			const response = await _getContracts(filters)

			if (response && response.ok) {
				const res = await response.json()

				if (res.contracts && Array.isArray(res.contracts))
					data = res.contracts.sort((a: Contract, b: Contract) =>
						naturalSort(a.apartmentIdentifier, b.apartmentIdentifier),
					)
			} else {
				console.error('Something went wrong.')
				setError('Failed to fetch contracts')
			}
		} catch (err) {
			console.error('Error fetching contracts:', err)
			setError('An error occurred while fetching contracts.')
		}
		setContracts(data)
		setLoading(false)
	}, [filters])
	useEffect(() => {
		doQuery()
	}, [doQuery])

	return {
		contracts,
		loadingContracts,
		errorContracts,
		refreshContracts: doQuery,
	}
}

export type UseContractsResult = ReturnType<typeof useContracts>

export default useContracts
