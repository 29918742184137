import {
	CreateValueAddedTax,
	ValueAddedTax,
} from 'ui/ManagerFinance/ValueAddedTax/types'
import { callApi } from './api/finance-api'

export const _getValueAddedTax = async (uuid: string) => {
	const url = `/v1/value-added-tax/${uuid}`

	return callApi(url, {
		method: 'GET',
	})
}

export const _getValueAddedTaxes = async () => {
	const url = `/v1/value-added-tax`

	return callApi(url, {
		method: 'GET',
	})
}

export const _createValueAddedTax = async (data: CreateValueAddedTax) => {
	return callApi(`/v1/value-added-tax`, {
		method: 'POST',
		body: {
			...data,
		},
	})
}

export const _updateValueAddedTax = async (data: ValueAddedTax) => {
	return callApi(`/v1/value-added-tax`, {
		method: 'PUT',
		body: { ...data },
	})
}
