import type { IntlShape } from 'react-intl'
import type {
	Contract,
	CreateContractPaymentProductLink,
	EditContractPaymentProductLink,
} from './types'
import { InvoicingMethod } from '../enum'

export const validateContractForm = async (
	linkFormData:
		| CreateContractPaymentProductLink[]
		| EditContractPaymentProductLink[],
	formData: Contract,
	alert: (message: string) => Promise<void>,
	intl: IntlShape,
) => {
	const {
		companyUUID,
		apartmentUUID,
		partyUUID,
		customInvoicing,
		startDate,
		endDate,
		invoicingMethod,
		invoicingAddress,
	} = formData

	if (!companyUUID) {
		await alert(
			intl.formatMessage({
				defaultMessage: 'Kohde puuttuu.',
				description:
					'Error message for missing company uuid in the contract form.',
			}),
		)
		return false
	}

	if (!apartmentUUID) {
		await alert(
			intl.formatMessage({
				defaultMessage: 'Huoneisto puuttuu.',
				description:
					'Error message for missing apartment uuid in the contract form.',
			}),
		)
		return false
	}

	if (!partyUUID) {
		await alert(
			intl.formatMessage({
				defaultMessage: 'Maksaja puuttuu.',
				description:
					'Error message for missing party uuid in the contract form.',
			}),
		)
		return false
	}

	if (customInvoicing) {
		if (!invoicingMethod) {
			await alert(
				intl.formatMessage({
					defaultMessage: 'Laskutustapa puuttuu.',
					description:
						'Error message for missing invoicing method in the contract form.',
				}),
			)
			return false
		}
		if (invoicingMethod === InvoicingMethod.WEB_INVOICE && !invoicingAddress) {
			await alert(
				intl.formatMessage({
					defaultMessage: 'Verkkolaskuosoite puuttuu.',
					description:
						'Error message for missing invoicing address in the contract form.',
				}),
			)
			return false
		}
	}

	if (startDate && endDate && endDate < startDate) {
		await alert(
			intl.formatMessage({
				defaultMessage: 'Sopimuksen loppupäivämäärä on ennen alkupäivämäärä.',
				description:
					'Error message for contract endDate is before the startDate in the contract form.',
			}),
		)
		return false
	}

	const groupedData = new Map<string, CreateContractPaymentProductLink[]>()

	linkFormData.forEach((link) => {
		const { paymentProductUUID } = link

		if (!groupedData.has(paymentProductUUID)) {
			groupedData.set(paymentProductUUID, [])
		}

		groupedData.get(paymentProductUUID)!.push({ ...link })
	})

	for (const [, group] of groupedData) {
		for (const link of group) {
			const { startDate, endDate, quantity } = link
			if (quantity && quantity < 0) {
				await alert(
					intl.formatMessage({
						defaultMessage: 'Maksulajin määrän on oltava yli 0',
						description:
							'Error message for payment product quantity is less than 0 in the contract form.',
					}),
				)
				return false
			}
			if (startDate && endDate && new Date(endDate) < new Date(startDate)) {
				await alert(
					intl.formatMessage({
						defaultMessage: 'Maksulajin loppupäivä on ennen alkupäivää',
						description:
							'Error message for payment product endDate is before the startDate in the contract form.',
					}),
				)
				return false
			}

			if (
				startDate &&
				formData.startDate &&
				new Date(startDate) < new Date(formData.startDate)
			) {
				await alert(
					intl.formatMessage({
						defaultMessage:
							'Tarkista että maksulajiien alkupäivät eivät ole ennen sopimuksen alkupäivää.',
						description:
							'Error message for payment product startDate is before the contract startDate in the contract form.',
					}),
				)
				return false
			}

			if (
				endDate &&
				formData.endDate &&
				new Date(endDate) > new Date(formData.endDate)
			) {
				await alert(
					intl.formatMessage({
						defaultMessage:
							'Tarkista että maksulajiien loppupäivät eivät ole sopimuksen loppupäivän jälkeen.',
						description:
							'Error message for payment product endDate is after the contract endDate in the contract form.',
					}),
				)
				return false
			}

			if (group.filter((link) => !link.endDate).length > 1) {
				await alert(
					intl.formatMessage({
						defaultMessage:
							'Tarkista että vain yhdellä maksulajilla on tyhjä loppupäivämäärä',
						description:
							'Error message for over one payment product without end date in the contract form.',
					}),
				)
				return false
			}

			if (group && Array.isArray(group) && group.length > 0) {
				group.sort(
					(a, b) =>
						new Date(a.startDate as Date).getTime() -
						new Date(b.startDate as Date).getTime(),
				)

				let previousLink

				for (const current of group) {
					// Check for overlapping dates
					if (
						previousLink &&
						(new Date(current.startDate as Date) <=
							new Date(previousLink.endDate as Date) ||
							(current.endDate && current.endDate <= previousLink.startDate))
					) {
						alert(
							intl.formatMessage({
								defaultMessage:
									'Samojen maksulajien päivämäärissä ei saa olla päällekkäisyyksiä',
								description:
									'Error message for overlapping price dates in the contract form.',
							}),
						)
						return false
					}
					previousLink = current
				}

				if (
					!link.endDate &&
					group.find(
						(g) =>
							new Date(g.startDate as unknown as string).getTime() >
							new Date(link.startDate as unknown as string).getTime(),
					)
				) {
					alert(
						intl.formatMessage({
							defaultMessage:
								'Samojen maksulajien aikaväleissä ei saa olla päällekkyyksiä.',
							description:
								'Error message for overlapping price dates in the contract form.',
						}),
					)
					return false
				}
			}
		}
	}
	return true
}
