import type { IntlShape } from 'react-intl'
import type { CreateValueAddedTax, ValueAddedTax } from './types'

export const validateValueAddedTaxForm = async (
	valueAddedTax: CreateValueAddedTax | ValueAddedTax,
	alert: (message: string) => Promise<void>,
	intl: IntlShape,
) => {
	const { name } = valueAddedTax
	let { percentages } = valueAddedTax
	percentages = percentages
		.filter((p) => !p.deleted)
		.sort(
			(a, b) =>
				new Date(a.startDate as Date).getTime() -
				new Date(b.startDate as Date).getTime(),
		)

	if (!name) {
		await alert(
			intl.formatMessage({
				defaultMessage: 'Nimi puuttuu.',
				description:
					'Error message for missing name in the value added tax form.',
			}),
		)
		return false
	}

	if (!percentages.length) {
		await alert(
			intl.formatMessage({
				defaultMessage: 'Prosentti puuttuu.',
				description:
					'Error message for missing percentage in the value added tax form.',
			}),
		)
		return false
	}

	if (percentages.filter((p) => !p.endDate).length > 1) {
		await alert(
			intl.formatMessage({
				defaultMessage:
					'Tarkista että vain yhdellä prosentilla on tyhjä loppupäivämäärä',
				description:
					'Error message for over one percentage without end date in the vat form.',
			}),
		)
		return false
	}

	let previousPercentage

	const isValid = percentages.some((percentage) => {
		if (!percentage.startDate) {
			alert(
				intl.formatMessage({
					defaultMessage: 'Alkupäivämäärä puuttuu.',
					description:
						'Error message for missing start date in the value added tax form.',
				}),
			)
			return true
		}

		if (percentage.percentage < 0) {
			alert(
				intl.formatMessage({
					defaultMessage: 'Prosentti ei voi olla negatiivinen.',
					description:
						'Error message for negative percentage in the value added tax form.',
				}),
			)
			return true
		}
		if (percentage.percentage > 100) {
			alert(
				intl.formatMessage({
					defaultMessage: 'Prosenttit ei voi olla yli 100.',
					description:
						'Error message for over 100% percentage in the value added tax form.',
				}),
			)
			return true
		}

		if (
			percentage.endDate &&
			new Date(percentage.endDate) < new Date(percentage.startDate)
		) {
			alert(
				intl.formatMessage({
					defaultMessage: 'Loppupäivä ei voi olla ennen alkupäivää.',
					description:
						'Error message for end date before start date in the value added tax form.',
				}),
			)
			return true
		}

		if (
			!percentage.endDate &&
			percentages.find(
				(p) =>
					new Date(p.startDate as string).getTime() >
					new Date(percentage.startDate as string).getTime(),
			)
		) {
			alert(
				intl.formatMessage({
					defaultMessage:
						'Prosenttien aikaväleissä ei saa olla päällekkyyksiä.',
					description:
						'Error message for start date after null end date in the value added tax form.',
				}),
			)
			return true
		}

		if (
			previousPercentage &&
			(new Date(percentage.startDate) <= new Date(previousPercentage.endDate) ||
				(percentage.endDate &&
					percentage.endDate <= previousPercentage.startDate))
		) {
			alert(
				intl.formatMessage({
					defaultMessage: 'Päällekkäiset päivämäärät eivät ole sallittu.',
					description:
						'Error message for overlapping dates in the value added tax form.',
				}),
			)
			return true
		}
		previousPercentage = percentage
		return false
	})

	return !isValid
}
