import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
	CardTitle,
	CircularProgress,
	CardActions,
	FontIcon,
	CardText,
} from 'react-md'

import * as tampuuriActions from 'state/tampuuri-actions'
import * as notifyActions from 'state/notifyActions'

import WithSelectedManagerRole from 'ui/components/WithSelectedManagerRole'
import WithUserRoles from 'ui/components/userRoles/WithUserRoles'
import { StandardCard } from 'ui/StyledComponents/Cards'
import { ProgressIndicatorButton } from 'ui/StyledComponents/Buttons'
import TampuuriApartmentReportDialog from './TampuuriApartmentReportDialog'
import { GENERIC_ERROR_NOTIFICATION } from 'ui/messages'
import { injectIntl } from 'react-intl'
import WithDialogs from 'ui/components/dialogs/WithDialogs'

class Tampuuri extends Component {
	state = {
		processingEnabled: false,
		processingCredentials: false,
		processingCompanyImport: false,
		processingRoleUpdate: false,
		apartmentReportDialogVisible: false,
	}

	showApartmentReportDialog = () =>
		this.setState({ apartmentReportDialogVisible: true })
	hideApartmentReportDialog = () =>
		this.setState({ apartmentReportDialogVisible: false })

	isMaster = () => {
		const { managerRole, isSuperAdmin } = this.props
		return managerRole === 'manager_master' || isSuperAdmin
	}

	setEnabled = async (enabled) => {
		const {
			_tampuuriSetEnabled,
			_showInfoNotification,
			selectedManager,
			intl,
		} = this.props

		this.setState({ processingEnabled: true })
		const result = await _tampuuriSetEnabled(selectedManager.uuid, enabled)
		this.setState({ processingEnabled: false })

		if (!result || result.success === false) {
			_showInfoNotification('❌ ' + GENERIC_ERROR_NOTIFICATION(intl))
			return false
		}

		_showInfoNotification('✅ Asetukset tallennettu.')
		return true
	}

	setCredentials = async () => {
		const {
			_tampuuriSetCredentials,
			_showInfoNotification,
			selectedManager,
			intl,
			prompt,
		} = this.props
		const title = 'Tunnusasetukset'

		const name = await prompt(
			'Syötä Tampuurin rajapinnan ASIAKASNIMI. Tämä on asiakkaan nimi Tampuurissa.',
			{ title },
		)
		if (!name) {
			return
		}
		const account = await prompt(
			'Syötä Tampuurin rajapinnan TILINIMI. Tämä on asiakkaan Tampuuri-tilin nimi.',
			{ title },
		)
		if (!account) {
			return
		}
		const key = await prompt('Syötä Tampuurin rajapinnan TAMPUURIAVAIN.', {
			title,
		})
		if (!key) {
			return
		}

		const viiluAccount = await prompt(
			'Syötä Tampuurin rajapinnan KÄYTTÄJÄTUNNUS. Tämä on Viilulle luotu käyttäjätunnus.',
			{ title },
		)
		if (!viiluAccount) {
			return
		}
		const viiluPassword = await prompt(
			'Syötä Tampuurin rajapinnan SALASANA. Tämä on Viilulle luodun käyttäjätunnuksen salasana.',
			{ title },
		)
		if (!viiluPassword) {
			return
		}

		this.setState({ processingCredentials: true })
		const result = await _tampuuriSetCredentials(
			selectedManager.uuid,
			name,
			account,
			key,
			viiluAccount,
			viiluPassword,
		)
		this.setState({ processingCredentials: false })

		if (!result || result.success === false) {
			_showInfoNotification('❌ ' + GENERIC_ERROR_NOTIFICATION(intl))
			return false
		}

		_showInfoNotification('✅ Asetukset tallennettu.')
		return true
	}

	companyMassImport = async () => {
		const {
			_tampuuriCompanyImportMass,
			_showInfoNotification,
			selectedManager,
			intl,
			confirm,
			prompt,
		} = this.props

		if (!(await confirm('Tuodaan kaikki Tampuurista löytyvät kohteet?'))) {
			return false
		}
		const billingEmail = await prompt(
			'Syötä laskutusyhteyshenkilön sähköpostiosoite.',
			{ title: 'Laskutusyhteyshenkilö' },
		)
		if (!billingEmail) {
			return
		}

		const features = selectedManager.features || {}
		const featuresHidden = selectedManager.featuresHidden || {}
		const featureSettings = selectedManager.featureSettings || {}
		const serviceLevel = selectedManager.defaultServiceLevel || 'MODULE'

		this.setState({ processingCompanyImport: true })
		const result = await _tampuuriCompanyImportMass(
			selectedManager.uuid,
			features,
			featuresHidden,
			featureSettings,
			billingEmail,
			serviceLevel,
		)
		this.setState({ processingCompanyImport: false })

		if (!result || result.success === false) {
			_showInfoNotification('❌ ' + GENERIC_ERROR_NOTIFICATION(intl))
			return false
		}

		_showInfoNotification('✅ Kohteiden massatuonti on käynnistetty.')
		return true
	}

	companyRoleUpdateMass = async () => {
		const {
			_updateCompanyManagerRolesMass,
			_showInfoNotification,
			selectedManager,
			intl,
			confirm,
		} = this.props

		if (
			!(await confirm(
				'Päivitetään kaikki Tampuurista löytyvien kohteiden roolit?',
			))
		) {
			return false
		}

		this.setState({ processingRoleUpdate: true })
		const result = await _updateCompanyManagerRolesMass(selectedManager.uuid)
		this.setState({ processingRoleUpdate: false })

		if (!result || result.success === false) {
			_showInfoNotification('❌ ' + GENERIC_ERROR_NOTIFICATION(intl))
			return false
		}

		_showInfoNotification('✅ Kohteiden roolit on päivitetty.')
		return true
	}

	render() {
		const { selectedManager } = this.props
		const {
			processingEnabled,
			processingCredentials,
			processingCompanyImport,
			processingRoleUpdate,
			apartmentReportDialogVisible,
		} = this.state

		const enabled =
			selectedManager.integrations &&
			selectedManager.integrations.tampuuri &&
			selectedManager.integrations.tampuuri.enabled

		return (
			<div className="full-width">
				<StandardCard
					className="margin-bottom full-width"
					style={{
						background: enabled ? 'rgb(240,255,240)' : undefined,
					}}
				>
					<CardTitle title={'Tampuuri-rajapinta'} />
					<CardText>
						Voit ottaa Tampuuri-integraation käyttöön, joka mahdollistaa
						esimerkiksi kohde- ja huoneistotietojen tuonnin suoraan rajapinnan
						kautta.
					</CardText>
					<CardActions>
						<ProgressIndicatorButton
							flat
							secondary
							onClick={() => this.setEnabled(!enabled)}
							disabled={processingEnabled || !this.isMaster()}
							style={{ color: 'var(--color-secondary)' }}
						>
							{processingEnabled ? (
								<CircularProgress
									id="progress"
									style={{ paddingLeft: 0, marginTop: 0 }}
								/>
							) : enabled ? (
								'POISTA KÄYTÖSTÄ'
							) : (
								'OTA KÄYTTÖÖN'
							)}
						</ProgressIndicatorButton>
						{enabled ? (
							<ProgressIndicatorButton
								flat
								secondary
								onClick={this.setCredentials}
								disabled={processingCredentials || !this.isMaster()}
								iconEl={
									processingCredentials ? null : (
										<FontIcon>manage_accounts</FontIcon>
									)
								}
								style={{ color: 'var(--color-secondary)' }}
							>
								{processingCredentials ? (
									<CircularProgress
										id="progress"
										style={{ paddingLeft: 0, marginTop: 0 }}
									/>
								) : (
									'TUNNUSASETUKSET'
								)}
							</ProgressIndicatorButton>
						) : null}
						{enabled ? (
							<ProgressIndicatorButton
								flat
								secondary
								onClick={this.companyMassImport}
								disabled={!this.isMaster()}
								iconEl={
									processingCompanyImport ? null : <FontIcon>home</FontIcon>
								}
								style={{ color: 'var(--color-secondary)' }}
							>
								{processingCompanyImport ? (
									<CircularProgress
										id="progress"
										style={{ paddingLeft: 0, marginTop: 0 }}
									/>
								) : (
									'MASSATUONTI'
								)}
							</ProgressIndicatorButton>
						) : null}
						{enabled ? (
							<ProgressIndicatorButton
								flat
								secondary
								onClick={this.companyRoleUpdateMass}
								disabled={!this.isMaster()}
								iconEl={
									processingRoleUpdate ? null : <FontIcon>person</FontIcon>
								}
								style={{ color: 'var(--color-secondary)' }}
							>
								{processingRoleUpdate ? (
									<CircularProgress
										id="progress"
										style={{ paddingLeft: 0, marginTop: 0 }}
									/>
								) : (
									'PÄIVITÄ ROOLIT'
								)}
							</ProgressIndicatorButton>
						) : null}
					</CardActions>
				</StandardCard>
				{apartmentReportDialogVisible ? (
					<TampuuriApartmentReportDialog
						managerUUID={selectedManager.uuid}
						onHide={this.hideApartmentReportDialog}
					/>
				) : null}
			</div>
		)
	}
}

Tampuuri.propTypes = {}

const mapState = ({ firebaseReducer: { auth } }) => ({
	auth,
})

const mapDispatchToProps = (dispatch) => ({
	_tampuuriSetEnabled: bindActionCreators(
		tampuuriActions._setEnabled,
		dispatch,
	),
	_tampuuriSetCredentials: bindActionCreators(
		tampuuriActions._setCredentials,
		dispatch,
	),
	_tampuuriCompanyImportMass: bindActionCreators(
		tampuuriActions._importCompanyMass,
		dispatch,
	),
	_updateCompanyManagerRolesMass: bindActionCreators(
		tampuuriActions._updateCompanyManagerRolesMass,
		dispatch,
	),
	_showInfoNotification: bindActionCreators(notifyActions.info, dispatch),
})

export default compose(
	injectIntl,
	WithDialogs,
	WithSelectedManagerRole,
	WithUserRoles,
	connect(mapState, mapDispatchToProps),
)(Tampuuri)
