import { injectIntl } from 'react-intl'
import {
	Button,
	CircularProgress,
	DataTable,
	TableBody,
	TableColumn,
	TableHeader,
	TableRow,
} from 'react-md'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { bindActionCreators, compose } from 'redux'
import moment from 'moment-timezone'
import { useEffect, useRef, useState } from 'react'
import naturalSort from 'javascript-natural-sort'

import * as messageServiceActions from 'state/message-service-actions'

import LoadingState from 'ui/components/LoadingState'
import WithSelectedCompany from 'ui/components/WithSelectedCompany'
import EmptyState from 'ui/components/EmptyState'
import documentsEmptyIcon from 'svg/documents-empty.svg'
import MessageServiceProcessType from '../MessageServiceProcessType'
import useDialogs from 'ui/components/dialogs/useDialogs'

const renderTs = (ts) => {
	if (!ts) {
		return '-'
	}

	const m = moment(new Date(ts))

	return (
		<div className="flex-row">
			<p>{m.format('DD.MM.YYYY')}</p>
			<p style={{ color: 'var(--color-text-subtle)', marginLeft: 4 }}>
				{m.format('HH:mm')}
			</p>
		</div>
	)
}

function Drafts({
	openDialog,
	drafts,
	selectedCompany,
	_deleteProcess,
	draftUUIDToOpen,
	replacePath,
}) {
	const { confirm, alert } = useDialogs()
	const [deletingUUID, setDeletingUUID] = useState()
	const [sortBy, setSortBy] = useState('createdAt')
	const [sortAscending, setSortAscending] = useState(false)
	const opened = useRef(false)

	useEffect(() => {
		if (draftUUIDToOpen && !opened.current) {
			const draft = drafts?.find((d) => d.uuid === draftUUIDToOpen)
			if (draft) {
				openDialog(draft)
				opened.current = true
			}
		} else {
			opened.current = false
		}
	}, [draftUUIDToOpen, drafts, openDialog])

	const deleteProcess = async (processUUID) => {
		if (!(await confirm('Oletko varma, että haluat poistaa luonnoksen?'))) {
			return
		}

		setDeletingUUID(processUUID)
		const result = await _deleteProcess(selectedCompany.uuid, processUUID)
		setDeletingUUID(null)

		if (!result || result.success === false) {
			await alert('Jokin meni pieleen.')
			return false
		}
		return true
	}

	if (!drafts) {
		return <LoadingState />
	}

	if (drafts.length === 0) {
		return (
			<EmptyState
				text="Et ole vielä luonnostellut lähetyksiä"
				icon={documentsEmptyIcon}
			/>
		)
	}

	const renderActions = (draft) => {
		if (draft.uuid === deletingUUID) {
			return (
				<CircularProgress
					id={`delete-draft-progress-${draft.uuid}`}
					centered={false}
					style={{ marginLeft: 12 }}
					scale={0.6}
				/>
			)
		}

		return (
			<TableColumn>
				<Button
					icon
					onClick={(event) => {
						event.stopPropagation()
						deleteProcess(draft.uuid)
					}}
				>
					delete
				</Button>
			</TableColumn>
		)
	}

	const sortedDrafts = [...drafts]
	if (sortBy === 'createdAt') {
		if (sortAscending) {
			sortedDrafts.sort(byCreatedAtAsc)
		} else {
			sortedDrafts.sort(byCreatedAtDesc)
		}
	} else if (sortBy === 'title') {
		if (sortAscending) {
			sortedDrafts.sort(byTitleAsc)
		} else {
			sortedDrafts.sort(byTitleDesc)
		}
	} else if (sortBy === 'editedAt') {
		if (sortAscending) {
			sortedDrafts.sort(byEditedAtAsc)
		} else {
			sortedDrafts.sort(byEditedAtDesc)
		}
	}

	const renderSortableTableHeaderColumn = (text, columnSortKey) => {
		return (
			<TableColumn
				key={columnSortKey}
				onClick={() => {
					if (sortBy !== columnSortKey) {
						setSortBy(columnSortKey)
						setSortAscending(false)
					} else {
						setSortAscending(!sortAscending)
					}
				}}
				sorted={sortBy === columnSortKey ? sortAscending : undefined}
				style={{ cursor: 'pointer' }}
			>
				{text}
			</TableColumn>
		)
	}

	return (
		<DataTable plain>
			<TableHeader>
				<TableRow>
					{renderSortableTableHeaderColumn('Otsikko', 'title')}
					{renderSortableTableHeaderColumn('Muokattu', 'editedAt')}
					{renderSortableTableHeaderColumn('Luotu', 'createdAt')}
					<TableColumn>Tyyppi</TableColumn>
					<TableColumn>Toiminnot</TableColumn>
				</TableRow>
			</TableHeader>
			<TableBody>
				{sortedDrafts.map((draft) => (
					<TableRow
						key={draft.uuid}
						onClick={() => {
							replacePath(draft, 'draft')
						}}
						style={{ cursor: 'pointer' }}
					>
						<TableColumn>{draft.title || '-'}</TableColumn>
						<TableColumn>{renderTs(draft.modified?.at)}</TableColumn>
						<TableColumn>{renderTs(draft.created.at)}</TableColumn>
						<TableColumn>
							<MessageServiceProcessType generator={draft.generator} />
						</TableColumn>
						{renderActions(draft)}
					</TableRow>
				))}
			</TableBody>
		</DataTable>
	)
}

const byTitleAsc = (a, b) => {
	if (!a.title && !b.title) return 0
	if (!a.title) return 1
	if (!b.title) return -1
	return naturalSort(b.title, a.title)
}
const byTitleDesc = (a, b) => {
	if (!a.title && !b.title) return 0
	if (!a.title) return 1
	if (!b.title) return -1
	return naturalSort(a.title, b.title)
}

const byEditedAtAsc = (a, b) => {
	if (!a.modified?.at && !b.modified?.at) return 0
	if (!a.modified?.at) return 1
	if (!b.modified?.at) return -1
	return a.modified.at - b.modified.at
}
const byEditedAtDesc = (a, b) => {
	if (!a.modified?.at && !b.modified?.at) return 0
	if (!a.modified?.at) return 1
	if (!b.modified?.at) return -1
	return b.modified.at - a.modified.at
}

const byCreatedAtAsc = (a, b) => {
	if (!a.created?.at && !b.created?.at) return 0
	if (!a.created?.at) return 1
	if (!b.created?.at) return -1
	return a.created.at - b.created.at
}
const byCreatedAtDesc = (a, b) => {
	if (!a.created?.at && !b.created?.at) return 0
	if (!a.created?.at) return 1
	if (!b.created?.at) return -1
	return b.created.at - a.created.at
}

const mapState = ({
	firestoreReducer: {
		ordered: { message_service_process_drafts },
	},
}) => ({
	drafts: message_service_process_drafts?.filter((d) => !d.deleted),
})

const mapDispatchToProps = (dispatch) => ({
	_deleteProcess: bindActionCreators(
		messageServiceActions._deleteProcess,
		dispatch,
	),
})

export default compose(
	injectIntl,
	WithSelectedCompany,
	firestoreConnect(({ selectedCompany, generator, generatorDataUUID }) => {
		const query = [
			['companyUUID', '==', selectedCompany.uuid],
			['status', '==', 'draft'],
		]

		if (generator) {
			query.push(['generator', '==', generator])
		}

		if (generatorDataUUID) {
			query.push(['generatorDataUUID', '==', generatorDataUUID])
		}

		return [
			{
				collection: 'message-service-process',
				storeAs: 'message_service_process_drafts',
				where: query,
				orderBy: ['created.at', 'desc'],
			},
		]
	}),
	connect(mapState, mapDispatchToProps),
)(Drafts)
