import type { IntlShape } from 'react-intl'
import type { Loan } from './types'

export const loanTableHeaders: Array<{
	title: (intl: IntlShape) => string
	key: keyof Loan | 'companyLocation'
	sortable?: boolean
}> = [
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Kohde',
				description: 'Table header for company location',
			}),
		key: 'companyLocation',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Nimi',
				description: 'Table header for loan name',
			}),
		key: 'name',
		sortable: true,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Luoton numero',
				description: 'Table header for credit ID',
			}),
		key: 'creditId',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Tila',
				description: 'Table header for loan status',
			}),
		key: 'status',
		sortable: true,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Jakoperuste',
				description: 'Table header for billing basis',
			}),
		key: 'billingBasis',
		sortable: true,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Lainamäärä',
				description: 'Table header for loan amount',
			}),
		key: 'originalLoanAmount',
		sortable: true,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Velkamäärä',
				description: 'Table header for debt amount',
			}),
		key: 'debtLeftToPay',
		sortable: true,
	},
]
