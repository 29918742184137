import { injectIntl } from 'react-intl'
import { FontIcon } from 'react-md'
import { compose } from 'redux'
import { SummaryList, SummaryListItem } from './styles'

function InfoscreenSummary({ bulletinsSettings, intl }) {
	const formatTs = (ts) => {
		if (!ts) {
			return null
		}

		return new Date(ts).toLocaleString('fi', {
			dateStyle: 'medium',
			timeStyle: 'short',
		})
	}

	const formatPublishTs = () => {
		const tsString = formatTs(bulletinsSettings.publishTsInfoscreen)

		if (!tsString) {
			return 'Julkaistaan heti'
		}

		return `Julkaisuaika ${tsString}`
	}

	const formatExpirationTs = () => {
		const tsString = formatTs(bulletinsSettings.expirationTsInfoscreen)

		if (!tsString) {
			return 'Ei poistumisaikaa'
		}

		return `Poistumisaika ${tsString}`
	}

	return (
		<>
			<h2>Porrasnäyttö (maksuton)</h2>
			<SummaryList>
				<SummaryListItem>
					<FontIcon>event</FontIcon>
					<span>{formatPublishTs()}</span>
				</SummaryListItem>
				<SummaryListItem>
					<FontIcon>event</FontIcon>
					<span>{formatExpirationTs()}</span>
				</SummaryListItem>
			</SummaryList>
		</>
	)
}

export default compose(injectIntl)(InfoscreenSummary)
