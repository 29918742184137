import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Chip, FontIcon, Avatar } from 'react-md'
import { injectIntl } from 'react-intl'
import { compose } from 'redux'

class SignatureRequestStatusChip extends Component {
	render = () => {
		const { status, intl } = this.props
		if (status === 'complete') {
			return (
				<Chip
					label={intl.formatMessage({
						defaultMessage: 'Valmis',
						description: 'Label of the status "complete".',
					})}
					avatar={
						<Avatar
							icon={<FontIcon key="check">done</FontIcon>}
							suffix="green"
						/>
					}
				/>
			)
		} else if (status === 'initialized') {
			return (
				<Chip
					label={intl.formatMessage({
						defaultMessage: 'Kesken',
						description: 'Label of the status "initialized".',
					})}
					avatar={
						<Avatar
							icon={<FontIcon key="check">edit</FontIcon>}
							suffix="orange"
						/>
					}
				/>
			)
		} else if (status === 'signing') {
			return (
				<Chip
					label={intl.formatMessage({
						defaultMessage: 'Kesken',
						description: 'Label of the status "signing".',
					})}
					avatar={
						<Avatar
							icon={<FontIcon key="check">edit</FontIcon>}
							suffix="orange"
						/>
					}
				/>
			)
		}

		return (
			<Chip
				label={intl.formatMessage({
					defaultMessage: 'Tuntematon tila',
					description: 'Label of the status "unknown".',
				})}
				avatar={
					<Avatar icon={<FontIcon key="check">help</FontIcon>} suffix="grey" />
				}
			/>
		)
	}
}

SignatureRequestStatusChip.propTypes = {
	status: PropTypes.string.isRequired,
}

export default compose(injectIntl)(SignatureRequestStatusChip)
