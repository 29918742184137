import styled, { css, keyframes } from 'styled-components'
import { Paper as MDPaper, Card as MDCard } from 'react-md'

const tall = css`
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`

const rounded = css`
	border-radius: 8px;
	overflow: hidden;
`

export const StandardCard = styled(MDCard)`
	${rounded}
`

export const StandardCardTall = styled(StandardCard)`
	${tall}
`

const imgSkeleton = css`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`

export const CardImgSkeleton = styled.div`
	${imgSkeleton}
	position: relative;
	width: 100%;
	height: 100%;
`

export const CardImgSkeletonAbsolute = styled.div`
	${imgSkeleton}
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
`
const shimmer = keyframes`
	100% {
		transform: translateX(100%);
	}
`
const loading = css`
	overflow: hidden;
	background-color: #dddbdd;

	&:after {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		transform: translateX(-100%);
		background-image: linear-gradient(
			90deg,
			rgba(255, 255, 255, 0) 0%,
			rgba(255, 255, 255, 0.2) 20%,
			rgba(255, 255, 255, 0.5) 60%,
			rgba(255, 255, 255, 0)
		);
		animation: ${shimmer} 2s infinite;
		content: '';
	}
`

export const CardImgSkeletonLoading = styled(CardImgSkeleton)`
	${loading}
`

export const CardImgSkeletonAbsoluteLoading = styled(CardImgSkeletonAbsolute)`
	${loading}
`

const deblurAnimation = keyframes`
	to {
		filter: blur(0) grayscale(0.5);
	}
`

const blurred = css`
	filter: blur(2px) grayscale(1);
	animation-delay: 3s;
	animation-name: ${deblurAnimation};
	animation-duration: 1.5s;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
`

// Used when opening an URL that highlights a single card and blurs others
export const Paper = styled(MDPaper)`
	${rounded}
`
export const PaperTall = styled(Paper)`
	${tall}
`

export const PaperBlurred = styled(Paper)`
	${blurred}
`

export const PaperTallBlurred = styled(PaperTall)`
	${blurred}
`
