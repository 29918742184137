import { compose } from 'redux'
import { PropTypes } from 'prop-types'

import LanguageMenu from './LanguageMenu.js'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { maxScreenWidth } from 'ui/StyledComponents/MediaBreakpoints.js'
import ViiluDialog from 'ui/components/ViiluDialog'

const LanguageDialogContainer = styled(ViiluDialog)`
	.language-dialog {
		border-radius: 8px;
	}
`
const LanguageDialog = ({ languageDialogVisible, intl }) => {
	const showFullPageDialog = window.innerWidth <= maxScreenWidth.phone
	return (
		<LanguageDialogContainer
			id="responsive-dialog"
			aria-label="Language"
			dialogClassName="language-dialog"
			visible={languageDialogVisible}
			onHide={() => {}}
			containFocus={true}
			focusOnMount={true}
			fullPage={showFullPageDialog}
			autosizeContent={false}
			paddedContent={false}
			modal
			portal
			onClick={(e) => {
				e.preventDefault()
				e.stopPropagation()
			}}
			onDoubleClick={(e) => {
				e.preventDefault()
				e.stopPropagation()
			}}
			style={{ zIndex: 100 }}
		>
			<LanguageMenu />
		</LanguageDialogContainer>
	)
}

LanguageDialog.propTypes = {
	languageDialogVisible: PropTypes.bool.isRequired,
}

const mapState = ({
	ui: {
		app: { languageDialogVisible },
	},
}) => ({
	languageDialogVisible,
})

const mapDispatchToProps = (dispatch) => ({})

export default compose(
	injectIntl,
	connect(mapState, mapDispatchToProps),
)(LanguageDialog)
