import { useIntl } from 'react-intl'
import { Checkbox, CircularProgress } from 'react-md'
import { isEmpty, isLoaded } from 'react-redux-firebase'
import useMessageServiceIntegrations from 'ui/MessageService/hooks/useMessageServiceIntegrations'
import styled from 'styled-components'

const Title = styled.h2`
	font-size: 1.3rem;
	margin-bottom: 8px;
`

function MessageServiceIntegrations({
	deliveryMethodsIntegrations = [],
	onSelectIntegration,
}) {
	const intl = useIntl()
	const messageServiceIntegrations = useMessageServiceIntegrations()

	if (!isLoaded(messageServiceIntegrations)) {
		return (
			<CircularProgress
				id={'message-service-integrations-progress'}
				style={{ marginTop: 32 }}
			/>
		)
	}

	if (isEmpty(messageServiceIntegrations)) {
		return null
	}

	return (
		<>
			<Title>
				{intl.formatMessage({
					defaultMessage: 'Integraatiot',
					description:
						'Title of the message service integrations section in the message service delivery methods.',
				})}
			</Title>
			{messageServiceIntegrations.map((integration) => (
				<Checkbox
					key={integration.uuid}
					id={'delivery-methods-integration-' + integration.uuid}
					name={'deliveryMethodsIntegrations'}
					label={integration.title}
					checked={deliveryMethodsIntegrations?.includes(integration.uuid)}
					onChange={(checked) => onSelectIntegration(checked, integration.uuid)}
					style={{ marginBottom: 8 }}
				/>
			))}
		</>
	)
}

export default MessageServiceIntegrations
