import { useCallback, useEffect, useState } from 'react'
import { _getBond } from 'state/finance-party-actions'
import type { Bond } from './types'

function useBond(uuid: string) {
	const [bond, setBond] = useState<Bond>()

	const [loadingBond, setLoading] = useState<boolean>(false)
	const [errorBond, setError] = useState<string | null>(null)

	const doQuery = useCallback(async () => {
		setLoading(true)
		setError(null)
		let data: Bond | undefined

		try {
			const response = await _getBond(uuid)

			if (response && response.ok) {
				const res = await response.json()
				data = res.bond
			} else {
				console.error('Something went wrong getting bond: ', uuid)
				setError('Failed to fetch bond: ' + uuid)
			}
		} catch (err) {
			console.error('Error fetching bond:', err)
			setError('An error occurred while fetching bond: ' + uuid)
		}
		setBond(data)
		setLoading(false)
	}, [uuid])

	useEffect(() => {
		doQuery()
	}, [doQuery])

	return { bond, loadingBond, errorBond, refreshBond: doQuery }
}

export default useBond
