import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { ThemeButton } from 'ui/StyledComponents/Buttons'
import { dismissNotification, removeNotification } from 'state/notifyActions'

let displayed = []

function NotificationListener() {
	const dispatch = useDispatch()
	const notifications = useSelector((store) => store.notifications || [])
	const { enqueueSnackbar, closeSnackbar } = useSnackbar()

	const storeDisplayed = (id) => {
		displayed = [...displayed, id]
	}

	const removeDisplayed = (id) => {
		displayed = [...displayed.filter((key) => id !== key)]
	}

	useEffect(() => {
		notifications.forEach(({ key, text, options = {}, dismissed = false }) => {
			if (dismissed) {
				closeSnackbar(key)
				return
			}

			// Do nothing if snackbar is already displayed.
			if (displayed.includes(key)) {
				return
			}

			// Display snackbar using notistack.
			enqueueSnackbar(text, {
				key,
				action: (key) => (
					// Default dismiss button.
					<ThemeButton
						flat
						primary
						onClick={() => {
							dispatch(dismissNotification(key))
						}}
					>
						OK
					</ThemeButton>
				),
				...options,
				onClose: (event, reason, key) => {
					if (options.onClose) {
						options.onClose(event, reason, key)
					}
				},
				onExited: (event, key) => {
					dispatch(removeNotification(key))
					removeDisplayed(key)
				},
			})

			// Keep track of snackbars that we've displayed.
			storeDisplayed(key)
		})
	}, [notifications, closeSnackbar, enqueueSnackbar, dispatch])

	return null
}

export default NotificationListener
