import React from 'react'
import styled from 'styled-components'
import RouterLink from 'ui/components/RouterLink'
import { footerData } from './footerData'

const FooterContainer = styled.footer`
	width: 100vw;
	padding: 56px 24px;
	margin-top: 32px;
	a {
		text-decoration: none;
		color: #253039;
		padding: 8px 0;
		font-size: 15px;
		font-weight: 600;
	}
	background: white;
	color: #4a4a4a;
	box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 33%);
`
const ColumnsContainer = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-evenly;
	@media only screen and (max-width: 568px) {
		flex-direction: column;
		gap: 24px;
		a {
			font-size: 1.2em;
		}
	}
`
const ColumnTitle = styled.div`
	font-size: 20px;
	color: #6e0b8d;
	font-weight: bold;
	@media only screen and (max-width: 568px) {
		font-size: 1.5em;
	}
`
const ColumnItems = styled.div`
	display: flex;
	flex-direction: column;
`
export default function Footer() {
	const renderFooterColumn = (column, index) => {
		return (
			<div key={index}>
				<ColumnTitle>{column.columnTitle}</ColumnTitle>
				<ColumnItems>
					{column.items.map((item, index) => (
						<RouterLink key={index} href={item.href}>
							{item.label}
						</RouterLink>
					))}
				</ColumnItems>
			</div>
		)
	}
	return (
		<FooterContainer>
			<ColumnsContainer>{footerData.map(renderFooterColumn)}</ColumnsContainer>
		</FooterContainer>
	)
}
