import BaseButton, { Status } from '../BaseButton'

const getStatus = (recipient, smsStatus) => {
	if (!recipient.checked || !recipient.checked['sms']) {
		return Status.NOT_SELECTED
	}

	const history = smsStatus?.statusHistory

	if (!history) {
		return Status.UNKNOWN
	}

	const latest = history[history.length - 1]

	if (latest.status === 'Processed') {
		if (latest.message === 'OK') {
			return Status.SENT
		} else {
			return Status.ERROR
		}
	}

	return Status.UNKNOWN
}

function SMSStatusButton({ recipient, smsStatus, onClick }) {
	const status = getStatus(recipient, smsStatus)

	return <BaseButton status={status} onClick={onClick} />
}

export default SMSStatusButton
