import {
	TextField,
	CircularProgress,
	SelectField,
	TextFieldTypes,
	Button,
} from 'react-md'
import { GENERIC_ERROR_NOTIFICATION } from 'ui/messages'
import ViiluDialog from 'ui/components/ViiluDialog'
import { useEffect, useRef, useState } from 'react'
import { ListValue } from 'react-md/lib/SelectFields/SelectField'
import { createPartyHeaders, updatePartyHeaders } from './constants'
import { useIntl } from 'react-intl'
import * as notifyActions from 'state/notifyActions'
import { useDispatch } from 'react-redux'
import {
	_createPartyWithBonds,
	_updatePartyWithBonds,
} from 'state/finance-party-actions'
import type {
	BondUpdate,
	CreatePartyBondLink,
	EditPartyBondLink,
	CreateParty,
	UpdateParty,
} from './types'
import useDialogs from 'ui/components/dialogs/useDialogs'
import DatePickerISO from 'ui/components/DatePickerISO'
import YesNoSwitch from 'ui/components/YesNoSwitch'
import moment from 'moment'
import type { SelectOptions, ApartmentSelectOptions } from '../types'
import { CountryCode } from '../enum'
import {
	countryCodeMessage,
	UPDATE_INVOICES_REMINDER_MESSAGE,
} from '../utils/messages'
import { isValidPhoneNumber } from '../utils/validation'
import useParty from './useParty'
import { useCompany } from 'ui/components/useCompany'
import BondDialog from './BondDialog'
import capitalizeFirstLetter from 'util/capitalizeFirstLetter'

interface Props {
	id?: string
	visible: boolean
	onHide: () => void
	refreshParties: () => void
	selectedFinanceCompanyUUID: string
	apartmentOptions: ApartmentSelectOptions[]
	invoicingOptions: SelectOptions[]
	companyOptions: SelectOptions[]
	bondNameOptions: SelectOptions[]
	refreshBonds: () => void
}

const PartyDialog = ({
	id,
	visible,
	onHide,
	refreshParties,
	selectedFinanceCompanyUUID,
	apartmentOptions,
	invoicingOptions,
	companyOptions,
	bondNameOptions,
	refreshBonds,
}: Props) => {
	const intl = useIntl()
	const dispatch = useDispatch()
	const { party } = useParty(id)
	const { company } = useCompany(selectedFinanceCompanyUUID)
	const streetAddressInputRef = useRef<HTMLInputElement>()

	const initialFormData = {
		companyUUID: selectedFinanceCompanyUUID,
		name: '',
		streetAddress: '',
		zipCode: '',
		city: '',
		country: CountryCode.FI,
		personalIdentityCode: '',
		businessId: '',
		email: '',
		phoneNumber: '',
		invoicingMethod: 'paperInvoice',
		invoicingAddress: '',
		apartmentIdentifier: '',
		apartmentUUID: '',
		startDate: moment().format('YYYY-MM-DD'),
		endDate: null,
		active: true,
	}

	const [bondFormData, setBondFormData] = useState<Map<string, BondUpdate>>(
		new Map(),
	)

	const [formData, setFormData] = useState<CreateParty | UpdateParty>(
		initialFormData,
	)
	const [processing, setProcessing] = useState(false)
	const { alert, confirm } = useDialogs()
	const resetFormData = () => {
		setFormData(initialFormData)
		setBondFormData(new Map())
	}

	useEffect(() => {
		if (party) {
			setFormData({
				uuid: party.uuid,
				companyUUID: party.companyUUID || selectedFinanceCompanyUUID,
				name: party.name || '',
				streetAddress: party.streetAddress || '',
				zipCode: party.zipCode || '',
				city: party.city || '',
				country: party.country || CountryCode.FI,
				personalIdentityCode: party.personalIdentityCode || '',
				businessId: party.businessId || '',
				email: party.email || '',
				phoneNumber: party.phoneNumber || '',
				invoicingMethod: party.invoicingMethod || 'paperInvoice',
				invoicingAddress: party.invoicingAddress || '',
				apartmentIdentifier: party.apartmentIdentifier || '',
				apartmentUUID: party.apartmentUUID || '',
				startDate: party.startDate || new Date(),
				endDate: party.endDate || null,
				active: party.active || false,
				updateRoleEndDates: null,
			})
		}

		setFormData((prev) => ({
			...prev,
			companyUUID: selectedFinanceCompanyUUID,
		}))
	}, [party, selectedFinanceCompanyUUID])

	const handleInputChange = async (
		value: string | number | boolean | Date,
		field: string,
	) => {
		if (id && field === 'endDate') {
			if (!value) {
				setFormData((prevData) => ({
					...prevData,
					[field]: null,
					updateRoleEndDates: null,
				}))
			} else {
				const confirmEndDateMessage = intl.formatMessage({
					defaultMessage:
						'Haluatko varmasti asettaa osapuolen loppupäivän, tällöin osapuolen kaikki sopimukset päätetään loppupäivän mukaan ja laskut mitätöidään loppupäivästä alkaen?',
					description:
						'Confirm message when changing setting party end date, that all contracts will be set to end at the same date and all invoices onwards will be voided',
				})
				if (await confirm(confirmEndDateMessage)) {
					const confirmMessage = intl.formatMessage({
						defaultMessage:
							'Olet asettamassa osapuolelle päättymispäivämäärän, asetetaanko rooleille myös sama päättymispäivä?',
						description:
							'Confirm message when changing the parties end date, that do you want to update the end dates of the bonds also',
					})
					if (await confirm(confirmMessage)) {
						setFormData((prevData) => ({
							...prevData,
							[field]: value as Date,
							updateRoleEndDates: true,
						}))
					} else {
						setFormData((prevData) => ({
							...prevData,
							[field]: value as Date,
							updateRoleEndDates: null,
						}))
					}
				}
			}
		} else if (id && field === 'active' && value === false) {
			const confirmEndActivityMessage = intl.formatMessage({
				defaultMessage:
					'Haluatko varmasti asettaa osapuolen ei-aktiiviseksi, tällöin osapuolen kaikki sopimukset päätetään heti ja laskut mitätöidään tästä hetkestä alkaen?',
				description:
					'Confirm message when changing setting party active to false, that all contracts will be set to end current date and all invoices onwards will be voided',
			})
			if (await confirm(confirmEndActivityMessage)) {
				setFormData((prevData) => ({
					...prevData,
					[field]: value as boolean,
				}))
			}
		} else
			setFormData((prevData) => ({
				...prevData,
				[field]: value,
			}))
	}

	const handleSelectChange = (value: ListValue, field: string) => {
		if (field === 'apartmentUUID') {
			const apartmentIdentifier = apartmentOptions.find(
				(opt) => opt.value === value,
			)?.identifier
			setFormData((prevData) => ({
				...prevData,
				[field]: value as string,
				apartmentIdentifier: apartmentIdentifier as string,
			}))
		} else
			setFormData((prevData) => ({
				...prevData,
				[field]: value,
			}))
	}

	const validate = () => {
		let isValid = true
		let isEmailValid = true
		let isPhoneValid = true

		const headers = id ? updatePartyHeaders : createPartyHeaders

		headers.forEach((header) => {
			const { show, required, type, key } = header
			if (
				(show && required) ||
				(key === 'invoicingAddress' &&
					formData.invoicingMethod === 'webInvoice')
			) {
				if (isValid) {
					isValid = !!formData[key]
				}
			}

			if (type === 'tel') {
				if (isPhoneValid && formData[key])
					isPhoneValid = isValidPhoneNumber(formData[key])
			}

			if (type === 'email') {
				if (isEmailValid && formData[key])
					isEmailValid = formData[key].indexOf('@') !== -1
			}
		})

		if (!formData.companyUUID) {
			isValid = false
		}

		if (!formData.apartmentUUID) {
			isValid = false
		}

		return [isValid, isEmailValid, isPhoneValid]
	}

	const hide = () => {
		resetFormData()
		onHide()
	}

	const getUpdateBondFormData = () => {
		return Array.from(bondFormData.entries())
			.filter(([name, bond]) => {
				if (bond.uuid) {
					return !(bond.updated === false && bond.deleted === false)
				}
				return true
			})
			.map(([uuid, bond]) => {
				if (bond.uuid) {
					return {
						partyUUID: id || null,
						companyUUID: formData.companyUUID,
						uuid: bond.uuid,
						startDate: bond.startDate,
						endDate: bond.endDate,
						create: false,
						updated: bond.updated || false,
						deleted: bond.deleted || false,
					}
				} else {
					return {
						partyUUID: id || null,
						companyUUID: formData.companyUUID,
						name: bond.name,
						startDate: bond.startDate,
						endDate: bond.endDate,
						create: true,
						updated: false,
						deleted: false,
					}
				}
			})
	}

	const handleUpdate = async () => {
		const bondFormData: EditPartyBondLink[] = getUpdateBondFormData()
		const result = await _updatePartyWithBonds(
			formData as UpdateParty,
			bondFormData,
		)
		setProcessing(false)

		if (!result || result.ok === false) {
			dispatch(notifyActions.info('❌ ' + GENERIC_ERROR_NOTIFICATION(intl)))
			return false
		}

		dispatch(
			notifyActions.info(
				'✅ ' +
					intl.formatMessage({
						defaultMessage: 'Osapuoli on päivitetty.',
						description: 'Notification text of party has been updated',
					}),
			),
		)
		refreshParties()
		refreshBonds()
		hide()
		alert(UPDATE_INVOICES_REMINDER_MESSAGE(intl))
		return true
	}

	const getCreateBondFormData = () => {
		return Array.from(bondFormData.entries()).map(([name, bond]) => {
			return {
				name: bond.name,
				startDate: bond.startDate,
				endDate: bond.endDate,
			}
		})
	}
	const handleSave = async () => {
		const bondFormData: CreatePartyBondLink[] = getCreateBondFormData()
		const result: any = await _createPartyWithBonds(formData, bondFormData)
		setProcessing(false)
		if (!result || result.ok === false) {
			dispatch(notifyActions.info('❌ ' + GENERIC_ERROR_NOTIFICATION(intl)))
			return false
		}

		dispatch(
			notifyActions.info(
				'✅ ' +
					intl.formatMessage({
						defaultMessage: 'Osapuoli on lisätty.',
						description: 'Notification text of party has been added',
					}),
			),
		)
		refreshParties()
		refreshBonds()
		hide()
		return true
	}

	const onProcess = async () => {
		const [isValid, isEmailValid, isPhoneValid] = validate()
		if (!isValid) {
			const message = intl.formatMessage({
				defaultMessage: 'Täytä kaikki vaaditut tiedot.',
				description: 'Error message for missing party create input values.',
			})
			alert(message)
			return
		}

		if (!isEmailValid) {
			const message = intl.formatMessage({
				defaultMessage: 'Sähköposti ei ole oikeassa muodossa.',
				description: 'Error message for email not being valid.',
			})
			alert(message)
			return
		}

		if (!isPhoneValid) {
			const message = intl.formatMessage({
				defaultMessage: 'Puhelinnmero ei ole oikeassa muodossa.',
				description: 'Error message for phonenumber not being valid.',
			})
			alert(message)
			return
		}

		setProcessing(true)
		if ((formData as UpdateParty).uuid) {
			return await handleUpdate()
		} else return await handleSave()
	}

	const setCompanyAddressInfo = async () => {
		if (company)
			setFormData((prevData) => ({
				...prevData,
				streetAddress: capitalizeFirstLetter(company.address),
				zipCode: company.zip,
				city: capitalizeFirstLetter(company.area),
			}))
		const alertMessage = intl.formatMessage({
			defaultMessage: 'Muista täydentää osoite tarvittaessa',
			description:
				'Alert message when using the button to fill in company based address data, so user remembers to complete it if needed',
		})
		await alert(alertMessage)

		if (streetAddressInputRef.current) {
			streetAddressInputRef.current.focus()
		}
	}

	const renderFormFields = () => {
		const headers = id ? updatePartyHeaders : createPartyHeaders
		return headers.map((header) => {
			const { show, key, title, type, required } = header
			if (show) {
				if (key === 'invoicingAddress') {
					if (formData.invoicingMethod === 'webInvoice')
						return (
							<TextField
								key={key}
								id={key}
								label={title(intl)}
								lineDirection="center"
								floating
								fullWidth
								value={formData[key] || ''}
								onChange={(value) => handleInputChange(value, key)}
								required={true}
								type={(type as TextFieldTypes) || undefined}
								disabled={false}
							/>
						)
					return null
				} else if (type === 'date') {
					return (
						<DatePickerISO
							style={{ width: '100%', paddingTop: '5px' }}
							key={key}
							id={key}
							label={title(intl)}
							value={formData[key]}
							onChange={(value) => handleInputChange(value, key)}
						/>
					)
				} else if (type === 'boolean') {
					return (
						<YesNoSwitch
							key={key}
							label={title(intl)}
							name={key}
							checked={formData[key]}
							onChange={(checked: boolean) => {
								handleInputChange(checked, key)
							}}
							style={{ marginTop: '10px' }}
						/>
					)
				} else if (type === 'country') {
					return (
						<TextField
							key={key}
							id={key}
							label={title(intl)}
							lineDirection="center"
							fullWidth
							value={countryCodeMessage(intl, formData[key]) || ''}
							onChange={(value) => handleInputChange(value, key)}
							disabled={!header.editable}
							required={false}
							type={'text'}
						/>
					)
				} else if (key === 'useCompanyAddressInfo') {
					return (
						<div
							style={{
								width: '100%',
								minHeight: '55px',
								alignContent: 'center',
							}}
							key={'div-' + key}
						>
							<Button
								key={key}
								id={key}
								lineDirection="center"
								fullWidth
								primary
								raised
								onClick={() => setCompanyAddressInfo()}
							>
								{title(intl)}
							</Button>
						</div>
					)
				} else
					return (
						<TextField
							key={key}
							id={key}
							ref={
								key === 'streetAddress' ? (streetAddressInputRef as any) : null
							}
							label={title(intl)}
							lineDirection="center"
							floating
							fullWidth
							value={formData[key]}
							onChange={(value) => handleInputChange(value, key)}
							required={required}
							type={(type as TextFieldTypes) || undefined}
							//@ts-ignore
							autoComplete="never-autocomplete"
						/>
					)
			}
			return null
		})
	}

	const renderBonds = () => {
		return (
			<BondDialog
				party={party}
				formData={bondFormData}
				setFormData={setBondFormData}
				bondNameOptions={bondNameOptions}
			/>
		)
	}

	const renderChildren = () => {
		return (
			<div
				className="margin-left--lg margin-right--lg"
				style={{ paddingBottom: 64 }}
			>
				<p className="text-subtle">
					{intl.formatMessage({
						defaultMessage: 'Kohde',
						description: 'Title of company',
					})}
				</p>
				<SelectField
					key="company-s"
					id="company"
					placeholder={intl.formatMessage({
						defaultMessage: 'Kohde',
						description: 'Placeholder of company',
					})}
					value={formData.companyUUID}
					onChange={(value) => handleSelectChange(value, 'companyUUID')}
					menuItems={companyOptions}
					position={SelectField.Positions.BELOW}
					simplifiedMenu={false}
					listHeightRestricted
					disabled
					style={{
						width: '100%',
						background: '#fafafa',
						marginBottom: '10px',
					}}
				/>
				<p className="text-subtle">
					{intl.formatMessage({
						defaultMessage: 'Huoneisto',
						description: 'Title of apartment',
					})}
				</p>
				<SelectField
					key="apartment"
					id="apartment"
					placeholder={intl.formatMessage({
						defaultMessage: 'Huoneisto',
						description: 'Placeholder of apartment',
					})}
					value={formData.apartmentUUID}
					onChange={(value) => handleSelectChange(value, 'apartmentUUID')}
					menuItems={apartmentOptions}
					position={SelectField.Positions.BELOW}
					simplifiedMenu={false}
					listHeightRestricted
					style={{
						width: '100%',
						background: '#fafafa',
						marginBottom: '10px',
					}}
				/>

				<p className="text-subtle">
					{intl.formatMessage({
						defaultMessage: 'Oletus laskutustapa',
						description: 'Title of default invoicing method',
					})}
				</p>
				<SelectField
					key="invoicingMethod"
					id="invoicingMethod"
					placeholder={intl.formatMessage({
						defaultMessage: 'Oletus laskutustapa',
						description: 'Placeholder of default invoicing method',
					})}
					value={formData.invoicingMethod}
					onChange={(value) => handleSelectChange(value, 'invoicingMethod')}
					menuItems={invoicingOptions}
					position={SelectField.Positions.BELOW}
					simplifiedMenu={false}
					listHeightRestricted
					style={{
						width: '100%',
						background: '#fafafa',
						marginBottom: '10px',
					}}
				/>
				{renderFormFields()}
			</div>
		)
	}

	const actions = [
		{
			id: 'dialog-cancel',
			children: intl.formatMessage({
				defaultMessage: 'Peruuta',
				description: 'Cancel button',
			}),
			onClick: hide,
		},
		{
			id: 'dialog-ok',
			secondary: true,
			children: processing ? (
				<CircularProgress id="progress" style={{ marginTop: 0 }} />
			) : (
				intl.formatMessage({
					defaultMessage: 'Tallenna',
					description: 'Save button',
				})
			),
			onClick: onProcess,
		},
	]

	return (
		<ViiluDialog
			id="responsive-dialog"
			title={
				id
					? intl.formatMessage({
							defaultMessage: 'Päivitä osapuoli',
							description: 'Title of update party',
					  })
					: intl.formatMessage({
							defaultMessage: 'Lisää osapuoli',
							description: 'Title of add party',
					  })
			}
			visible={visible}
			actions={actions}
			onHide={hide}
			focusOnMount={true}
			containFocus={true}
			dialogClassName="responsive-dialog"
			contentClassName="responsive-dialog-content"
			autosizeContent={false}
			modal
			portal
			paddedContent
		>
			{renderChildren()}
			{renderBonds()}
		</ViiluDialog>
	)
}

export default PartyDialog
