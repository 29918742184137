export const footerData = [
	{
		columnTitle: 'Tuotetiedot',
		items: [
			{
				label: 'Hinnoittelu',
				href: '/pricing',
			},
			{
				label: 'Porrasnäytöt',
				href: '/infoscreens',
			},
			{
				label: 'Kokoustyökalut',
				href: '/meetings',
			},
			{
				label: 'Kokonaispalvelu',
				href: '/suite',
			},
			{
				label: 'Isännöitsijälle',
				href: '/managers',
			},
		],
	},
	{
		columnTitle: 'Apua',
		items: [
			{
				label: 'Asiakaspalvelu',
				href: '/support',
			},
			{
				label: 'Oppaat',
				href: 'https://docs.vii.lu/',
			},
			{
				label: 'Status',
				href: 'https://status.vii.lu/',
			},
		],
	},
	{
		columnTitle: 'Muuta',
		items: [
			{
				label: 'Lataa sovellus',
				href: '/download',
			},
			{
				label: 'Tietoturva ja yksityisyys',
				href: '/security',
			},
			{
				label: 'Medialle',
				href: '/brand-guideline',
			},
		],
	},
]
