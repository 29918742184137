import {
	TextField,
	CardTitle,
	CardText,
	TextFieldTypes,
	SelectField,
} from 'react-md'
import { useIntl } from 'react-intl'
import { StandardCard } from 'ui/StyledComponents/Cards'
import {
	eInvoiceHeaders,
	invoicingAdditionalInformationHeaders,
	invoicingHeaders,
} from './constants'
import type { Header, Settings } from './types'
import styled from 'styled-components'
import useDialogs from 'ui/components/dialogs/useDialogs'
import DatePickerISO from 'ui/components/DatePickerISO'
import FloatingActionBtn from 'ui/components/FloatingActionBtn'
import LoadingState from 'ui/components/LoadingState'
import type { NumberSelectOptions } from '../types'
import { InvoicingMonth } from '../enum'
import { InvoicingMonthMessage } from '../utils/messages'
import { removeLeadingZero } from '../utils/utils'

const InvoicingContainer = styled.div`
	display: flex;
	flex-direction: column;
	flex-shrink: 0;
	flex-grow: 1;
	align-items: center;
	justify-content: flex-start;
	padding-top: 24px;
	padding-bottom: 24px;
`

const Horizontal = styled.div`
	display: flex;
	gap: 16px;
	margin-bottom: 16px;
`

type Props = {
	settings: Settings
	setSettings: React.Dispatch<React.SetStateAction<Settings>>
	processing: boolean
	saveSettings: () => void
	loading: boolean
}

function Invoicing({
	settings,
	setSettings,
	processing,
	saveSettings,
	loading,
}: Props) {
	const intl = useIntl()
	const { alert } = useDialogs()

	const invoicingStartMonthOptions: NumberSelectOptions[] = Object.values(
		InvoicingMonth,
	)
		.filter((value) => Number(value))
		.map((value) => {
			return {
				label: InvoicingMonthMessage(intl, value),
				value: Number(value),
			}
		})

	const handleInputChange = (
		value: string | number | boolean | null,
		field: string,
	) => {
		setSettings((prevData) => ({
			...prevData,
			[field]: value,
		}))
	}
	const renderTextField = (headers: Header[]) => {
		return headers.map((header: Header) => {
			const { title, key, type, editable, required } = header
			if (key === 'invoicingStartMonth') {
				return (
					<div key={key} style={{ marginTop: '15px' }}>
						<p className="text-subtle">{title(intl)}</p>
						<SelectField
							id={key}
							key={key}
							placeholder={title(intl)}
							value={settings[key] || ''}
							onChange={(value) => handleInputChange(value, key)}
							menuItems={invoicingStartMonthOptions}
							position={SelectField.Positions.BELOW}
							simplifiedMenu={false}
							listHeightRestricted
							style={{
								width: '100%',
								background: '#fafafa',
								marginBottom: '10px',
							}}
							required={required}
						/>
					</div>
				)
			} else if (type === 'number') {
				return (
					<TextField
						key={key}
						id={key}
						label={title(intl)}
						lineDirection="center"
						fullWidth
						value={removeLeadingZero(settings[key]) ?? ''}
						onChange={(value) => {
							const numericValue = Number(value)
							if (
								!isNaN(numericValue) &&
								numericValue >= (header.minValue || 0) &&
								(header?.maxValue === undefined ||
									numericValue <= header.maxValue)
							) {
								handleInputChange(numericValue, key)
							} else if (value === '') {
								handleInputChange(header.minValue || 0, key)
							} else {
								const message = intl.formatMessage({
									defaultMessage: 'Syötä numero joka on 0 tai suurempi.',
									description: 'Error message for number value 0 or greater.',
								})
								alert(header.errorMessage ? header.errorMessage(intl) : message)
							}
						}}
						disabled={!editable}
						required={required}
						type="number"
						min={header.minValue || 0}
						max={header.maxValue || undefined}
					/>
				)
			} else {
				return (
					<TextField
						key={key}
						id={key}
						label={title(intl)}
						lineDirection="center"
						fullWidth
						value={settings[key] || ''}
						onChange={(value) => handleInputChange(value, key)}
						disabled={!editable}
						required={required}
						type={(type as TextFieldTypes) || undefined}
					/>
				)
			}
		})
	}

	const invoicingInformation = () => {
		return (
			<StandardCard className="margin-bottom" style={{ maxWidth: 768 }}>
				<CardTitle
					title={intl.formatMessage({
						defaultMessage: 'Laskutus',
						description: 'Invoicing',
					})}
				/>
				<CardText>{renderTextField(invoicingHeaders)}</CardText>
			</StandardCard>
		)
	}

	const invoicingAdditionalInformation = () => {
		return (
			<StandardCard className="margin-bottom" style={{ maxWidth: 768 }}>
				<CardTitle
					title={intl.formatMessage({
						defaultMessage: 'Lisätiedot',
						description: 'Additional information',
					})}
				/>
				<CardText>
					{renderTextField(invoicingAdditionalInformationHeaders)}
				</CardText>
			</StandardCard>
		)
	}
	const eInvoiceInformation = () => {
		return (
			<StandardCard className="margin-bottom" style={{ maxWidth: 768 }}>
				<CardTitle
					title={intl.formatMessage({
						defaultMessage: 'Verkkolaskut',
						description: 'E-Invoices',
					})}
				/>
				<CardText>
					<p className="text-subtle">
						{intl.formatMessage({
							defaultMessage: 'Verkkolaskutuksen voimassaoloaika',
							description: 'E-invocing validity period',
						})}
					</p>
					<Horizontal>
						<DatePickerISO
							id="eInvoiceStartDate"
							label={intl.formatMessage({
								defaultMessage: 'Alkupäivämäärä',
								description: 'Label for the start date',
							})}
							value={settings.eInvoiceStartDate || ''}
							onChange={(value) => {
								handleInputChange(value, 'eInvoiceStartDate')
							}}
						/>
						<DatePickerISO
							id="eInvoiceEndDate"
							label={intl.formatMessage({
								defaultMessage: 'Loppupäivämäärä',
								description: 'Label for the end date.',
							})}
							value={settings.eInvoiceEndDate || ''}
							onChange={(value) => {
								handleInputChange(value, 'eInvoiceEndDate')
							}}
						/>
					</Horizontal>
				</CardText>
				<CardText>{renderTextField(eInvoiceHeaders)}</CardText>
			</StandardCard>
		)
	}

	const validate = () => {
		let isValid = true
		invoicingHeaders.forEach((header) => {
			if (header.show && header.required && isValid) {
				isValid = Boolean(settings[header.key])
			}
		})

		return isValid
	}

	const handleSave = () => {
		const isValid = validate()
		if (!isValid) {
			const message = intl.formatMessage({
				defaultMessage: 'Täytä kaikki vaaditut tiedot.',
				description:
					'Error message for missing finance bond create input values.',
			})
			alert(message)
			return
		}

		saveSettings()
	}

	const renderFAB = () => {
		return (
			<FloatingActionBtn
				iconName={processing ? '' : 'save'}
				processing={processing}
				text={
					processing
						? intl.formatMessage({
								defaultMessage: 'Tallennetaan',
								description: 'Saving',
						  })
						: intl.formatMessage({
								defaultMessage: 'Tallenna',
								description: 'Save',
						  })
				}
				action={handleSave}
			/>
		)
	}

	if (loading) {
		return <LoadingState />
	}

	return (
		<InvoicingContainer>
			<div className="flex-column" style={{ minWidth: '50vw' }}>
				{invoicingInformation()}
				{invoicingAdditionalInformation()}
				{eInvoiceInformation()}
			</div>
			{renderFAB()}
		</InvoicingContainer>
	)
}

export default Invoicing
