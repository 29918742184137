import type {
	Loan,
	LoanCreateFormInputs,
	LoanShareholder,
	SelectableSharegroup,
} from './types'
import { PaymentProductBillingBasis } from '../enum'
import type { Apartment } from 'types/apartment'
import { LoanInterestDaysMonth, LoanInterestDaysYear, LoanStatus } from './enum'

export const isValidBillingBasis = (
	value: any,
): value is PaymentProductBillingBasis => {
	return Object.values(PaymentProductBillingBasis).includes(value)
}

export const mapShareholdersToSelectableApartments = (
	apartments: Apartment[],
	shareholders?: LoanShareholder[],
): SelectableSharegroup => {
	const selectableApartments: SelectableSharegroup = {}
	if (!shareholders) return selectableApartments
	shareholders.forEach((shareholder) => {
		const correspondingApartment = apartments.find(
			(apt) => apt.uuid === shareholder.shareGroupUUID,
		)
		if (correspondingApartment) {
			selectableApartments[correspondingApartment.uuid] = {
				...correspondingApartment,
				companyName: shareholder.companyName,
				companyUUID: shareholder.companyUUID,
				units: shareholder.units,
				loanShareAmount: shareholder.loanShareAmount,
				debtShareAmount: shareholder.debtShareAmount,
				billingBasis: shareholder.billingBasis,
			}
		}
	})
	return selectableApartments
}

export const mapLoanToFormInputs = (loan: Loan) => {
	// Need to set default values so form states don't change from uncontrolled to controlled
	const formInputs: LoanCreateFormInputs = {
		name: loan.name || '',
		companyUUID: loan.companyUUID || '',
		companyName: loan.companyName || '',
		loanFinancingType: loan.loanFinancingType,
		loanStartDateEstimated: loan.loanStartDateEstimated || '',
		loanEndDateEstimated: loan.loanEndDateEstimated || '',
		originalLoanAmount: loan.originalLoanAmount ?? 0,
		loanSharesCanBePaidOff: loan.loanSharesCanBePaidOff ?? false,
		loanSharePaymentDates: loan.loanSharePaymentDates || [],
		usableOnBuildingManagerCertificate:
			loan.usableOnBuildingManagerCertificate ?? false,
		interestDaysMonth:
			loan.interestDaysMonth || LoanInterestDaysMonth.THIRTY_DAYS,
		interestDaysYear: loan.interestDaysYear || LoanInterestDaysYear.THREE_SIXTY,
		creditId: loan.creditId || '',
		status: loan.status ?? LoanStatus.DRAFT,
		currency: loan.currency || 'EUR',
		loanStartDate: loan.loanStartDate || '',
		loanEndDate: loan.loanEndDate || '',
		loanType: loan.loanType || '',
		loanGrantedBy: loan.loanGrantedBy || '',
		loanTime: loan.loanTime || '',
		loanDrawnAmount: loan.loanDrawnAmount ?? 0,
		loanFirstDrawDate: loan.loanFirstDrawDate || '',
		debtLeftToPay: loan.debtLeftToPay ?? 0,
		loanDrawn: loan.loanDrawn ?? false,
		interestType: loan.interestType || '',
		interestPercentage: loan.interestPercentage ?? 0,
		interestLastUpdated: loan.interestLastUpdated || '',
		interestFirstDueDate: loan.interestFirstDueDate || '',
		interestInspectionDates: loan.interestInspectionDates || [],
		marginalPercentage: loan.marginalPercentage ?? 0,
		amortizationInterval: loan.amortizationInterval || '',
		amortizationAmount: loan.amortizationAmount ?? 0,
		amortizationFirstDueDate: loan.amortizationFirstDueDate || '',
		amortizationDates: loan.amortizationDates || [],
		units: loan.units ?? 0,
		billingBasis: loan.billingBasis || PaymentProductBillingBasis.AREA,
		currentFormStep: loan.currentFormStep ?? 0,
		reachedFormStep: loan.reachedFormStep ?? 0,
		shareholders: loan.shareholders || [],
		selectedSharegroups: {},
	}
	return formInputs
}

/**
 * Recursive function that converts empty string values in an object (even nested object) into null
 */
export const convertEmptyStringsToNull = <T extends Record<string, any>>(
	obj: T,
): T => {
	if (obj === null || obj === undefined) return obj
	return Object.fromEntries(
		Object.entries(obj).map(([key, value]) => {
			if (value === '') return [key, null]
			if (Array.isArray(value))
				return [key, value.map(convertEmptyStringsToNull)]
			if (typeof value === 'object' && value !== null)
				return [key, convertEmptyStringsToNull(value)]
			return [key, value]
		}),
	) as T
}
