import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { Button, TextField } from 'react-md'

import * as notifyActions from 'state/notifyActions'

import illustrationEmail from 'svg/illustrations/illustration_email_alternate.svg'
import {
	ONBOARDING_NEXT_MESSAGE,
	ONBOARDING_VALIDATION_ERROR,
} from './messages'
import { FormattedMessage, injectIntl } from 'react-intl'

export class UserOnboardingStepEmail extends Component {
	onEmailChange = (email) => {
		if (typeof email === 'string') {
			this.props.onUpdate({ email: email.trim() })
			return true
		}
		this.props.onUpdate({ email })
	}

	validate = () => {
		const { onboardingReducer } = this.props
		const { email } = onboardingReducer
		if (email === '') {
			return false
		}
		if (!email.includes('@')) {
			return false
		}
		return true
	}

	next = async () => {
		const { onNext, _showInfoNotification, intl } = this.props
		if (!this.validate()) {
			_showInfoNotification(ONBOARDING_VALIDATION_ERROR(intl))
			return
		}

		onNext()
	}

	render = () => {
		const { onboardingReducer, intl } = this.props
		const { email } = onboardingReducer

		return (
			<div
				className="flex-column flex-center"
				style={{ height: 'calc(100% - 56px)', justifyContent: 'flex-start' }}
			>
				<img
					alt=""
					src={illustrationEmail}
					style={{
						width: 160,
						height: 'auto',
						position: 'relative',
						margin: 16,
						marginBottom: 0,
					}}
				/>
				<h4 className="text-center margin-bottom">
					<FormattedMessage
						defaultMessage="Sähköpostiosoitteesi tunnuksesi hallinnointiin"
						description="Header of the email address section of the user profile update dialog."
					/>
				</h4>
				<TextField
					id="floating-center-title-user-email"
					placeholder="Sähköpostiosoitteesi"
					lineDirection="center"
					value={email}
					onChange={this.onEmailChange}
					autoComplete="off"
					required={true}
					fullWidth={false}
					style={{ width: 288 }}
				/>
				<Button
					raised
					secondary
					onClick={this.next}
					disabled={!email}
					style={{ position: 'absolute', right: 24, bottom: 24 }}
				>
					{ONBOARDING_NEXT_MESSAGE(intl)}
				</Button>
			</div>
		)
	}
}

UserOnboardingStepEmail.propTypes = {
	onUpdate: PropTypes.func.isRequired,
	onNext: PropTypes.func.isRequired,
}

const mapState = ({ ui: { onboarding: onboardingReducer } }) => ({
	onboardingReducer,
})

const mapDispatchToProps = (dispatch) => ({
	_showInfoNotification: bindActionCreators(notifyActions.info, dispatch),
})

export default compose(
	injectIntl,
	connect(mapState, mapDispatchToProps),
)(UserOnboardingStepEmail)
