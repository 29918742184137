import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import * as notifyActions from 'state/notifyActions'
import { Button } from 'react-md'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { FormattedMessage, injectIntl } from 'react-intl'

import illustrationPhoneNumber from 'svg/illustrations/illustration_sms.svg'
import { ONBOARDING_NEXT_MESSAGE } from './messages'
import WithSelectedCompanyManager from 'ui/components/WithSelectedCompanyManager'

const isValidPhonenumber = (value) => {
	if (!value) {
		return false
	}
	if (value && value[0] !== '+') {
		return false
	}
	return /^\d{7,}$/.test(value.replace(/[\s()+\-.]|ext/gi, ''))
}

export class UserOnboardingStepPhoneNumber extends Component {
	onPhoneNumberChange = (phoneNumber) => {
		if (phoneNumber === '') {
			phoneNumber = null
		}
		this.props.onUpdate({ phoneNumber })
	}

	validate = () => {
		const { onboardingReducer, selectedCompanyManager } = this.props
		const { phoneNumber } = onboardingReducer

		const phoneNumberRequired =
			selectedCompanyManager &&
			selectedCompanyManager.forceCustomerPhoneNumberCollection

		if (phoneNumberRequired) {
			if (!isValidPhonenumber(phoneNumber)) {
				return false
			}
		}
		// Normally the phone number field is optional
		if (phoneNumber && !isValidPhonenumber(phoneNumber)) {
			return false
		}
		return true
	}

	next = async () => {
		const { onNext, _showInfoNotification, intl } = this.props
		if (!this.validate()) {
			const errorMessage = intl.formatMessage({
				defaultMessage:
					'Tarkistathan numeron oikeellisuuden. Muistithan maakoodin?',
				description:
					'Error message asking the user to check the phone number they typed in.',
			})
			_showInfoNotification(errorMessage)
			return
		}

		onNext()
	}

	render = () => {
		const { onboardingReducer, intl } = this.props
		const { phoneNumber } = onboardingReducer

		return (
			<div
				className="flex-column flex-center"
				style={{ height: 'calc(100% - 56px)', justifyContent: 'flex-start' }}
			>
				<img
					alt=""
					src={illustrationPhoneNumber}
					style={{
						width: 100,
						height: 'auto',
						position: 'relative',
						margin: 16,
					}}
				/>
				<h4 className="text-center">
					<FormattedMessage
						defaultMessage="Puhelinnumerosi"
						description="Header of the phone number section of the user profile update dialog."
					/>
				</h4>
				<p className="text-center margin-bottom">
					<FormattedMessage
						defaultMessage="Puhelinnumeroa käytetään vain taloyhtiön asioiden hoitoon."
						description="Informational paragraph telling the user what the phone number is used for."
					/>
				</p>
				<PhoneInput
					placeholder={intl.formatMessage({
						defaultMessage: 'Puhelinnumerosi',
						description: 'Placeholder value of the phone number field.',
					})}
					value={phoneNumber || ''}
					onChange={this.onPhoneNumberChange}
					defaultCountry={'FI'}
					style={{ fontSize: 'var(--text-md)' }}
				/>
				<Button
					flat={!phoneNumber}
					raised={phoneNumber}
					secondary
					onClick={this.next}
					disabled={!this.validate()}
					style={{ position: 'absolute', right: 24, bottom: 24 }}
				>
					{ONBOARDING_NEXT_MESSAGE(intl)}
				</Button>
			</div>
		)
	}
}

UserOnboardingStepPhoneNumber.propTypes = {
	onUpdate: PropTypes.func.isRequired,
	onNext: PropTypes.func.isRequired,
}

const mapState = ({ ui: { onboarding: onboardingReducer } }) => ({
	onboardingReducer,
})

const mapDispatchToProps = (dispatch) => ({
	_showInfoNotification: bindActionCreators(notifyActions.info, dispatch),
})

export default compose(
	injectIntl,
	WithSelectedCompanyManager,
	connect(mapState, mapDispatchToProps),
)(UserOnboardingStepPhoneNumber)
