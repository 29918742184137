import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import { compose } from 'redux'
import {
	CardActions,
	CardText,
	CardTitle,
	CircularProgress,
	FontIcon,
} from 'react-md'
import { ProgressIndicatorButton } from 'ui/StyledComponents/Buttons'
import { StandardCard } from 'ui/StyledComponents/Cards'
import { useIsSuperAdmin } from 'ui/components/userRoles/hooks'
import useDialogs from 'ui/components/dialogs/useDialogs'
import WithSelectedManagerRole from 'ui/components/WithSelectedManagerRole'
import {
	_setHausviseEnabled,
	_setHausviseCredentials,
} from 'state/hausvise-actions'
import { error, success } from 'state/notifyActions'
import {
	GENERIC_ERROR_NOTIFICATION,
	GENERIC_SAVED_NOTIFICATION,
} from 'ui/messages'

type Props = {
	selectedManager: any
	managerRole: any
}

const HausviseIntegrationCard = ({ selectedManager, managerRole }: Props) => {
	const [isProcessingEnabled, setIsProcessingEnabled] = useState(false)
	const [isProcessingCredentials, setIsProcessingCredentials] = useState(false)
	const isSuperAdmin = useIsSuperAdmin()
	const dispatch = useDispatch()
	const intl = useIntl()
	const { prompt } = useDialogs()

	const isMaster = () => {
		return managerRole === 'manager_master' || isSuperAdmin
	}

	const showErrorNotification = () => {
		dispatch(error('❌ ' + GENERIC_ERROR_NOTIFICATION(intl)))
	}
	const showSuccessNotification = () => {
		dispatch(success('✅' + GENERIC_SAVED_NOTIFICATION(intl)))
	}

	const toggleEnabled = async (enabled: boolean) => {
		setIsProcessingEnabled(true)
		const result: any = await _setHausviseEnabled(selectedManager.uuid, enabled)
		setIsProcessingEnabled(false)

		if (!result || result.success === false) {
			showErrorNotification()
			return false
		}
		showSuccessNotification()
		return true
	}

	const setCredentials = async () => {
		const apiKey = await prompt(
			'Syötä Hausvise-rajapinnan VALTUUTUSAVAIN (API key).',
			{
				title: 'Valtuutusavain',
			},
		)
		if (!apiKey) {
			return
		}
		setIsProcessingCredentials(true)
		const result: any = await _setHausviseCredentials(
			selectedManager.uuid,
			apiKey,
		)
		setIsProcessingCredentials(false)

		if (!result || result.success === false) {
			showErrorNotification()
			return false
		}
		showSuccessNotification()
		return true
	}

	const infoCardTitle = intl.formatMessage({
		defaultMessage: 'Hausvise-rajapinta',
		description: 'Title for the info card about the Hausvise API.',
	})
	const infoCardText = intl.formatMessage({
		defaultMessage: `Voit ottaa käyttöön Hausvise-integraation, joka mahdollistaa esimerkiksi kohde- ja huoneistotietojen tuonnin suoraan rajapinnan kautta.`,
		description:
			'Informational paragraph about enabling Hausvise API integration.',
	})
	const enableText = intl.formatMessage({
		defaultMessage: 'Ota käyttöön',
		description:
			'Label for a button that enables an integration in the manager settings.',
	})
	const disableText = intl.formatMessage({
		defaultMessage: 'Poista käytöstä',
		description:
			'Label for a button that disables an integration in the manager settings.',
	})
	const enterApiKeyText = intl.formatMessage({
		defaultMessage: 'VALTUUTUSAVAIN',
		description:
			'Label for a button that prompts the user to enter a Hausvise API key.',
	})

	const isEnabled = selectedManager.integrations?.hausvise?.enabled

	return (
		<div className="full-width">
			<StandardCard
				className="margin-bottom full-width"
				style={{
					background: isEnabled ? 'rgb(240,255,240)' : undefined,
				}}
			>
				<CardTitle title={infoCardTitle} />
				<CardText>{infoCardText}</CardText>
				<CardActions>
					<ProgressIndicatorButton
						flat
						secondary
						onClick={() => toggleEnabled(!isEnabled)}
						disabled={isProcessingEnabled || !isMaster()}
						style={{ color: 'var(--color-secondary)' }}
					>
						{isProcessingEnabled ? (
							<CircularProgress
								id="progress"
								style={{ paddingLeft: 0, marginTop: 0 }}
							/>
						) : isEnabled ? (
							<p>{disableText}</p>
						) : (
							<p>{enableText}</p>
						)}
					</ProgressIndicatorButton>
					{isEnabled ? (
						<ProgressIndicatorButton
							flat
							secondary
							onClick={setCredentials}
							disabled={isProcessingCredentials || !isMaster()}
							iconEl={
								isProcessingCredentials ? null : (
									<FontIcon>manage_accounts</FontIcon>
								)
							}
							style={{ color: 'var(--color-secondary)' }}
						>
							{isProcessingCredentials ? (
								<CircularProgress
									id="progress"
									style={{ paddingLeft: 0, marginTop: 0 }}
								/>
							) : (
								<p>{enterApiKeyText}</p>
							)}
						</ProgressIndicatorButton>
					) : null}
				</CardActions>
			</StandardCard>
		</div>
	)
}

export default compose(WithSelectedManagerRole)(HausviseIntegrationCard)
