import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FontIcon, Button } from 'react-md'
import { push } from 'connected-react-router'
import { compose, bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Avatar from 'react-avatar'
import { isLoaded, isEmpty } from 'react-redux-firebase'
import styled from 'styled-components'

import * as loginUIActions from 'state/ui/login-ui-actions'
import * as appUIActions from 'state/ui/app-ui-actions'

import WithSelectedCompany from './components/WithSelectedCompany'
import WithQueryParams from './components/WithQueryParams'
import YouDialog from './YouMenu/YouDialog'
import DeploymentUpdateButton from './components/DeploymentUpdateButton'
import NotificationCenterButton from './components/NotificationCenterButton'

const StyledButton = styled(Button)`
	border-radius: 32px;
	color: rgba(0, 0, 0, 0.87);
	background: #fafafa;
	border-color: rgba(0, 0, 0, 0.6);
	border-width: 2px;
	border-style: solid;
	margin-left: 8px;
`
const StyledIconButton = styled(Button)`
	color: rgba(0, 0, 0, 0.87);
	margin-right: -8px;
`

const AppToolbar = ({
	auth,
	location,
	isDesktop,
	dimensions,
	queryParams: { experience, e },
	drawerVisible,
	selectedCompany,
	hasScrolledDown,
	beamerUnreadPostsCount,
	_redirectLogin,
	_setNavDrawerVisible,
	_setLanguageDialogVisible,
	appToolbarVisible,
}) => {
	const [youDialogVisible, setYouDialogVisible] = useState(false)

	const isInfoscreenRoute = () => {
		const isInfoscreenRoute =
			location &&
			location.pathname &&
			location.pathname.includes('/internal/infoscreen')
		return isInfoscreenRoute
	}

	const isPaymentRoute = () => {
		const isPaymentRoute =
			location &&
			location.pathname &&
			location.pathname.includes('/internal/payment')
		return isPaymentRoute
	}

	const isSiteRoute = () => {
		if (!selectedCompany) {
			return false
		}

		const numOfChars = (str, char) => str.split(char).length - 1

		const isSiteRoute =
			location &&
			location.pathname &&
			location.pathname.includes(selectedCompany.urlName) &&
			numOfChars(location.pathname, '/') === 1
		return isSiteRoute
	}

	const renderUserUnauthenticated = () => {
		return (
			<StyledButton
				flat
				onClick={_redirectLogin}
				iconEl={
					<FontIcon style={{ marginLeft: -8, marginRight: -8 }}>
						account_circle
					</FontIcon>
				}
			>
				{isDesktop ? 'Kirjaudu tai luo tunnus' : 'Kirjaudu'}
			</StyledButton>
		)
	}

	const renderLanguageButton = () => {
		const { width } = dimensions
		if (width < 400) {
			return (
				<StyledIconButton icon onClick={() => _setLanguageDialogVisible(true)}>
					language
				</StyledIconButton>
			)
		}
		return (
			<StyledButton
				flat
				iconEl={
					<FontIcon style={{ marginLeft: -8, marginRight: -8 }}>
						language
					</FontIcon>
				}
				onClick={() => _setLanguageDialogVisible(true)}
			>
				Kieli
			</StyledButton>
		)
	}

	const renderUser = () => {
		const hide = isInfoscreenRoute()

		if (hide) {
			return null
		}

		if (!isLoaded(auth)) {
			return (
				<div
					style={{
						width: 48,
						height: 48,
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<div
						style={{
							width: 32,
							height: 32,
							borderRadius: 32,
							background: 'rgba(0,0,0,0.2)',
						}}
					></div>
				</div>
			)
		}

		if (isEmpty(auth)) {
			return (
				<div className="flex-row flex-center">
					{renderUserUnauthenticated()}
					{renderLanguageButton()}
				</div>
			)
		}

		const { displayName } = auth
		const avatarName = (displayName || '?').toUpperCase()

		return (
			<div
				onClick={() => {
					if (!isDesktop) {
						_setNavDrawerVisible(false)
					}
					setYouDialogVisible(true)
				}}
				style={{
					width: 48,
					height: 48,
					cursor: 'pointer',
					padding: 8,
					userSelect: 'none',
				}}
			>
				<Avatar name={avatarName} size={32} round />
			</div>
		)
	}

	const renderNotifications = () => {
		const hide = isInfoscreenRoute()

		if (hide) {
			return null
		}

		if (!isLoaded(auth)) {
			return (
				<div
					style={{
						width: 48,
						height: 48,
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<div
						style={{
							width: 32,
							height: 32,
							borderRadius: 32,
							background: 'rgba(0,0,0,0.2)',
						}}
					></div>
				</div>
			)
		}

		if (isEmpty(auth)) {
			return null
		}

		return <NotificationCenterButton />
	}

	const renderNav = () => {
		if (window.isNativeApp) {
			return <div />
		}
		if (isDesktop && drawerVisible) {
			return <div />
		}

		const hide = isInfoscreenRoute()
		if (hide) {
			return null
		}

		if (experience === 'lean' || e === 'l') {
			return <div></div>
		}

		return (
			<Button
				icon
				style={{
					color: 'white',
					background: 'rgba(0,0,0,0.2)',
					transform: 'scale(0.75)',
				}}
				onClick={() => {
					_setNavDrawerVisible(true)
				}}
			>
				menu
			</Button>
		)
	}

	const renderActions = () => {
		return (
			<div
				className="flex-row"
				style={{ marginRight: isDesktop ? undefined : 16 }}
			>
				<DeploymentUpdateButton />
				{renderNotifications()}
				{renderUser()}
			</div>
		)
	}

	if (window.location.href.includes('id_callback')) {
		return null
	}

	if (isInfoscreenRoute()) {
		return null
	}

	if (isPaymentRoute()) {
		return null
	}

	if (!appToolbarVisible) {
		return null
	}

	// TODO: figure out a way to have bg that works on all platforms
	const bgVisible = !isSiteRoute() && hasScrolledDown

	return (
		<div
			style={{
				background: bgVisible ? 'white' : 'transparent',
				borderBottomColor: bgVisible ? 'rgba(0,0,0,0.2)' : undefined,
				borderBottomStyle: bgVisible ? 'solid' : undefined,
				borderBottomWidth: bgVisible ? 1 : undefined,
				position: 'absolute',
				display: 'flex',
				justifyContent: 'space-between',
				paddingLeft: isDesktop || window.isNativeApp ? 16 : 0,
				paddingRight: isDesktop || window.isNativeApp ? 16 : 0,
				paddingTop: window.isNativeApp ? 40 : 8,
				zIndex: 2,
				right: 0,
				width: '100%',
				alignItems: 'center',
			}}
		>
			{renderNav()}
			{renderActions()}
			<YouDialog
				onHide={() => setYouDialogVisible(false)}
				visible={youDialogVisible}
			/>
		</div>
	)
}

AppToolbar.propTypes = {
	auth: PropTypes.object,
}

const mapState = ({
	ui: {
		app: { drawerVisible, appToolbarVisible, hasScrolledDown },
	},
	firebaseReducer: { auth },
	dimensions,
	router: { location },
}) => ({
	drawerVisible,
	appToolbarVisible,
	auth,
	isDesktop: dimensions.isDesktop,
	dimensions,
	location,
	hasScrolledDown,
})

const mapDispatchToProps = (dispatch) => ({
	_push: bindActionCreators(push, dispatch),
	_redirectLogin: bindActionCreators(loginUIActions._redirectLogin, dispatch),
	_setNavDrawerVisible: bindActionCreators(
		appUIActions._setNavDrawerVisible,
		dispatch,
	),
	_setLanguageDialogVisible: bindActionCreators(
		appUIActions._setLanguageDialogVisible,
		dispatch,
	),
})

export default compose(
	WithQueryParams,
	WithSelectedCompany,
	connect(mapState, mapDispatchToProps),
)(AppToolbar)
