import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'

import { StyledFontIcon, BaseButton } from './styles'

const StyledButton = styled(BaseButton)`
	margin-top: 16px;
	background: #fafafa;
	color: rgba(0, 0, 0, 0.87);
	font-size: 13px;
`

const SignUpButton = ({ onClick }) => {
	return (
		<StyledButton
			raised
			onClick={onClick}
			iconEl={<StyledFontIcon>add_circle</StyledFontIcon>}
		>
			<FormattedMessage
				defaultMessage="Luo uusi tunnus"
				description="Button that selects the signup flow"
			/>
		</StyledButton>
	)
}

export default SignUpButton
