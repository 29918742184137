const initialState = {
	drawerVisible: null, // boolean field but will get it's default based on screensize
	//TODO: REMOVE AFTER FULL RELEASE
	hasSwitchedToCompanyTaskManager: false,
	hasScrolledDown: false,
	beamerUnreadPostsCount: 0,
}

function reducer(state = initialState, action) {
	if (action.type === 'UI_APP_SET_LANGUAGE_DIALOG_VISIBLE') {
		return { ...state, languageDialogVisible: action.visible }
	} else if (action.type === 'UI_APP_SET_APP_TOOLBAR_VISIBLE') {
		return { ...state, appToolbarVisible: action.visible }
	} else if (action.type === 'UI_APP_SET_NAV_DRAWER_VISIBLE') {
		return { ...state, drawerVisible: action.visible }
	} else if (action.type === 'UI_APP_SET_HAS_SCROLLED_DOWN') {
		return { ...state, hasScrolledDown: action.hasScrolledDown }
	} else if (
		action.type === 'UI_APP_SET_HAS_SWITCHED_TO_COMPANY_TASK_MANAGER'
	) {
		//TODO: REMOVE AFTER FULL RELEASE
		return { ...state, hasSwitchedToCompanyTaskManager: action.hasSwitched }
	} else if (action.type === 'UI_APP_SET_BEAMER_UNREAD_POSTS_COUNT') {
		return { ...state, beamerUnreadPostsCount: action.count }
	}
	return state
}

export default reducer
