import type {
	PaymentProductsFilters,
	PaymentProduct,
	PaymentProductPrice,
} from 'ui/ManagerFinance/PaymentProducts/types'
import { generateQueryParams } from 'util/queryUtils'
import { callApi } from './api/finance-api'

export const _getPaymentProduct = async (uuid: string) => {
	return callApi(`/v1/payment-products/${uuid}`, {
		method: 'GET',
	})
}

type GetPaymentProductsFilters = {
	companyUUID: string
} & PaymentProductsFilters

export const _getPaymentProducts = async (
	filters: GetPaymentProductsFilters,
) => {
	return callApi(`/v1/payment-products${generateQueryParams(filters)}`, {
		method: 'GET',
	})
}

export type CreatePaymentProductData = Omit<
	PaymentProduct,
	'uuid' | 'prices'
> & {
	prices: Array<Omit<PaymentProductPrice, 'priceWithVat' | 'vatPercentage'>>
}

export const _createPaymentProduct = async (data: CreatePaymentProductData) => {
	return callApi(`/v1/payment-products`, {
		method: 'POST',
		body: {
			...data,
		},
	})
}

export type ImportPaymentProductsData = {
	companyUUID: string
	paymentProducts: PaymentProduct[]
}

export const _importPaymentProducts = async (
	data: ImportPaymentProductsData,
) => {
	return callApi(`/v1/payment-products/import`, {
		method: 'POST',
		body: {
			...data,
		},
	})
}

export type UpdatePaymentProductData = Omit<PaymentProduct, 'prices'> & {
	prices: Array<Omit<PaymentProductPrice, 'priceWithVat' | 'vatPercentage'>>
}

export const _updatePaymentProduct = async (data: UpdatePaymentProductData) => {
	return callApi(`/v1/payment-products/${data.uuid}`, {
		method: 'PUT',
		body: {
			...data,
		},
	})
}

export const _deletePaymentProduct = async (paymentProductUUID: string) => {
	return callApi(`/v1/payment-products/${paymentProductUUID}`, {
		method: 'DELETE',
	})
}
