export const removeLeadingZero = (value: string | number) => {
	return String(value).replace(/^0+(?=\d)/, '')
}

export const calculateSumWithVat = (
	value: number,
	vatPercentage: number,
): number => {
	if (vatPercentage < 0 || vatPercentage > 100) {
		throw new Error('VAT percentage must be between 0 and 100')
	}
	return parseFloat((value * (1 + vatPercentage / 100)).toFixed(2))
}

export const calculateSumWithoutVat = (
	value: number,
	vatPercentage: number,
): number => {
	if (vatPercentage < 0 || vatPercentage > 100) {
		throw new Error('VAT percentage must be between 0 and 100')
	}
	return parseFloat((value / (1 + vatPercentage / 100)).toFixed(2))
}

export const formatTimestamp = (timestamp: Date, locale?: string) => {
	return new Date(timestamp).toLocaleString(locale || 'fi', {
		dateStyle: 'medium',
		timeStyle: 'short',
	})
}
