import { Chip, Avatar, FontIcon } from 'react-md'
import { invoiceStatusMessage } from '../utils/messages'
import { useIntl } from 'react-intl'
import { InvoiceStatusConfig } from '../enum'

type Props = {
	status: string
}

const InvoiceStatusChip = ({ status }: Props) => {
	const intl = useIntl()
	const { icon, color } = InvoiceStatusConfig[status]

	return (
		<Chip
			label={invoiceStatusMessage(intl, status)}
			style={{
				marginTop: 10,
				marginBottom: 10,
				backgroundColor: color,
				color: 'white',
			}}
			avatar={<Avatar icon={<FontIcon>{icon}</FontIcon>} suffix={color} />}
		/>
	)
}

export default InvoiceStatusChip
