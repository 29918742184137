import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { firestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase'
import LoadingState from './LoadingState'
import WithCompanyNameFromURL from './WithCompanyNameFromURL'
import * as appActions from 'state/appActions'
import WithSelectedCompanyUUID from './WithSelectedCompanyUUID'

export default (ComponentToWrap) => {
	class WithURLCompanyUpdater extends Component {
		componentWillMount = () => {
			const { selectedCompanyUUID, url_company, _setSelectedCompany } =
				this.props

			if (!isLoaded(url_company) || isEmpty(url_company)) {
				return
			}

			if (!url_company[0].uuid) {
				return
			}

			if (selectedCompanyUUID !== url_company[0].uuid) {
				_setSelectedCompany(url_company[0])
				return
			}
		}

		componentDidUpdate = () => {
			const { selectedCompanyUUID, url_company, _setSelectedCompany } =
				this.props

			if (!isLoaded(url_company) || isEmpty(url_company)) {
				return
			}

			if (!url_company[0].uuid) {
				return
			}

			if (selectedCompanyUUID !== url_company[0].uuid) {
				_setSelectedCompany(url_company[0])
				return
			}
		}

		render() {
			const { selectedCompanyUUID, url_company, urlName } = this.props

			if (!urlName) {
				return <ComponentToWrap {...this.props} />
			}
			if (!isLoaded(url_company)) {
				return <LoadingState />
			}
			if (isEmpty(url_company)) {
				return <ComponentToWrap {...this.props} />
			}
			if (selectedCompanyUUID !== url_company[0].uuid) {
				return <LoadingState />
			}

			return <ComponentToWrap {...this.props} />
		}
	}

	const mapState = ({
		firestoreReducer: {
			ordered: { url_company },
		},
	}) => ({ url_company })

	const mapDispatchToProps = (dispatch) => ({
		_setSelectedCompany: bindActionCreators(
			appActions._setSelectedCompany,
			dispatch,
		),
	})

	return compose(
		WithSelectedCompanyUUID,
		WithCompanyNameFromURL,
		firestoreConnect(({ urlName }) =>
			urlName
				? [
						{
							collection: 'company',
							storeAs: 'url_company',
							where: [['urlName', '==', urlName]],
							limit: 1,
						},
				  ]
				: [],
		),
		connect(mapState, mapDispatchToProps),
	)(WithURLCompanyUpdater)
}
