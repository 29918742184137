import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Button, CircularProgress } from 'react-md'

import * as userActions from 'state/user-actions'
import * as notifyActions from 'state/notifyActions'

import illustrationEmail from 'svg/illustrations/illustration_email.svg'
import { FormattedMessage, injectIntl } from 'react-intl'
import { firebaseApp } from 'state/store'
import { getAuth, signOut } from 'firebase/auth'
import WithDialogs from './dialogs/WithDialogs'
import FB_ERRORS from 'util/firebaseErrors'

class EmailVerificationDialog extends Component {
	state = {
		processing: false,
		showButtons: false,
	}

	componentWillMount = () => {
		this.authReloadInterval = window.setInterval(
			getAuth(firebaseApp).reloadAuth,
			2000,
		)
	}

	componentWillUnmount = () => {
		if (this.authReloadInterval) {
			window.clearInterval(this.authReloadInterval)
		}
	}

	logout = () => {
		if (window.isNativeApp && window.ReactNativeWebView) {
			// The native app will handle the logout
			const command = { command: 'logOut', args: [] }
			const commandString = JSON.stringify(command)
			window.ReactNativeWebView.postMessage(commandString)
			return
		}

		signOut(getAuth(firebaseApp))
		window.location.reload(true)
	}

	sendEmailVerification = async () => {
		const { _sendVerificationEmail, _showInfoNotification, intl, alert } =
			this.props
		this.setState({ processing: true })
		const redirectURL = window.location.href
		const result = await _sendVerificationEmail(redirectURL)
		this.setState({ processing: false, showButtons: false })

		if (!result || result.success === false) {
			if (result?.ex?.code === FB_ERRORS.FUNCTIONS_FAILED_PRECONDITION) {
				const message = intl.formatMessage({
					defaultMessage:
						'Tapahtui virhe. Käyttämäsi sähköposti saattaa olla sulkulistalla. Otathan meihin yhteyttä asiakaspalvelu@vii.lu niin hoidetaan homma kuntoon.',
					description:
						'Error message telling the user that their email address might be blocked.',
				})
				await alert(message)
			} else if (result?.ex?.code === FB_ERRORS.FUNCTIONS_RESOURCE_EXHAUSTED) {
				const message = intl.formatMessage({
					defaultMessage:
						'Olet lähettänyt useita vahvistusviestejä pienessä ajassa. Odota hetki ja yritä uudelleen.',
					description:
						'Error message telling the user that they have attempted to send too many verification emails in a short timespan.',
				})
				await alert(message)
			} else {
				const message = intl.formatMessage({
					defaultMessage: 'Vahvistussähköpostin lähettämisessä tapahtui virhe.',
					description:
						'Error message telling the user that there was an error while sending the verification email.',
				})
				await alert(message)
			}
			return false
		}

		const successMessage = intl.formatMessage({
			defaultMessage: 'Vahvistussähköposti lähetetty.',
			description:
				'Notification shown to the user when the verification email has been sent.',
		})
		_showInfoNotification('✅ ' + successMessage)
		return false
	}

	render() {
		const { processing, showButtons } = this.state
		const { auth } = this.props
		const processButtonEnabled = !processing

		const strongEmail = <strong>{auth.email}</strong>

		return (
			<div
				style={{
					zIndex: 101,
					position: 'fixed',
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
					background: 'white',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					overflowX: 'hidden',
					overflowY: 'auto',
				}}
			>
				<section style={{ padding: 24, maxWidth: 480 }}>
					<div className="flex-center flex-column">
						<img
							alt=""
							src={illustrationEmail}
							style={{
								maxWidth: 120,
								width: '50%',
								height: 'auto',
								position: 'relative',
								margin: 16,
							}}
						/>
						<h3 className="text-center">
							<FormattedMessage
								defaultMessage="Tarkista sähköpostilaatikkosi"
								description="Title of the email verification dialog."
							/>
						</h3>
						<p className="text-subtle text--md text-center">
							<FormattedMessage
								defaultMessage="Lähetimme sinulle vahvistusviestin osoitteeseen {email}"
								description="Informational paragraph telling the user that we sent the verification email to the address they typed in."
								values={{
									email: strongEmail,
								}}
							/>
						</p>
						{showButtons ? (
							<>
								<Button
									raised
									secondary
									disabled={!processButtonEnabled}
									onClick={this.sendEmailVerification}
									className="margin-top--xl"
								>
									{processing ? (
										<CircularProgress
											id="progress"
											className="responsive-dialog-circular-progress-white"
											style={{ marginTop: -4 }}
										/>
									) : (
										<FormattedMessage
											defaultMessage="Lähetä uudelleen"
											description="Button that allows the user to resend the verification email to themselves."
										/>
									)}
								</Button>
								<Button
									flat
									secondary
									onClick={this.logout}
									className="margin-top"
								>
									<FormattedMessage
										defaultMessage="Kirjaudu ulos"
										description="Button that allows the user to log out."
									/>
								</Button>
							</>
						) : (
							<Button
								flat
								onClick={() => this.setState({ showButtons: true })}
								className="margin-top"
							>
								<FormattedMessage
									defaultMessage="Etkö saanut vahvistusviestiä?"
									description="Button that allows the user to read instructions on how to proceed if they did not receive the verification message."
								/>
							</Button>
						)}
						<p
							className="text-subtle text-center margin-top--xl"
							style={{ fontSize: 13 }}
						>
							<FormattedMessage
								defaultMessage="Ongelmia kirjautumisessa? Lähetä sähköpostia osoitteeseen asiakaspalvelu@vii.lu tai soita numeroon 020 765 6070."
								description="Instructional paragraph asking the user to contact our customer support if needed."
							/>
						</p>
					</div>
				</section>
			</div>
		)
	}
}

EmailVerificationDialog.propTypes = {
	auth: PropTypes.object.isRequired,
	onHide: PropTypes.func.isRequired,
}

const mapState = () => ({})

const mapDispatchToProps = (dispatch) => ({
	_sendVerificationEmail: bindActionCreators(
		userActions._sendVerificationEmail,
		dispatch,
	),
	_showInfoNotification: bindActionCreators(notifyActions.info, dispatch),
})

export default compose(
	injectIntl,
	WithDialogs,
	connect(mapState, mapDispatchToProps),
)(EmailVerificationDialog)
