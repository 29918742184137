import { useMemo, useState } from 'react'
import { injectIntl } from 'react-intl'
import {
	Button,
	Checkbox,
	DataTable,
	FontIcon,
	TableBody,
	TableColumn,
	TableHeader,
	TableRow,
} from 'react-md'
import { compose } from 'redux'
import styled from 'styled-components'
import alpha2ToCountryName from 'util/alpha2ToCountryName'
import { getFullAddress, getLetters } from 'util/recipientsToLetters'
import stringToColour from 'util/stringToColor'
import naturalSort from 'javascript-natural-sort'
import cap from 'util/capitalizeFirstLetter'
import useDialogs from 'ui/components/dialogs/useDialogs'
import { validateEmail } from 'util/validation'

const byFullAddress = (a, b) => {
	const aFullAddress = getFullAddress(a)
	const bFullAddress = getFullAddress(b)

	return aFullAddress.localeCompare(bFullAddress, 'fi')
}

const joinIdentifiers = (invitee) =>
	[...invitee.apartments]
		.map((apt) => apt.identifier)
		.sort((a, b) => a.localeCompare(b, 'fi', { numeric: true }))
		.join(', ')

const byApartment = (a, b) => {
	const aIdentifiers = joinIdentifiers(a)
	const bIdentifiers = joinIdentifiers(b)

	return aIdentifiers.localeCompare(bIdentifiers, 'fi', { numeric: true })
}

const getConsentAllSources = (invitees) => {
	const consentSourceMap = {}
	invitees.forEach((invitee) => {
		if (invitee.digitalDeliveryConsents) {
			Object.keys(invitee.digitalDeliveryConsents).forEach((source) => {
				consentSourceMap[source] = true
			})
		}
	})
	return Object.keys(consentSourceMap)
}

const filterColumns = (deliveryMethods) => {
	const columns = [
		'address',
		'displayName',
		'apartments',
		'email',
		'phoneNumber',
		'digitalDeliveryConsents',
		'letterCheckbox',
		'emailCheckbox',
		'smsCheckbox',
		'actions',
	]
	return columns.filter((column) => {
		if (column === 'email' || column === 'emailCheckbox') {
			return deliveryMethods.includes('email')
		}
		if (column === 'address' || column === 'letterCheckbox') {
			return deliveryMethods.includes('letter')
		}
		if (column === 'phoneNumber' || column === 'smsCheckbox') {
			return deliveryMethods.includes('sms')
		}
		return true
	})
}

const PostalAddressContainer = styled.div`
	display: flex;
	flex-direction: column;
	p {
		font-size: 11px;
		white-space: nowrap;
		margin-right: 4px;
	}
`

function RecipientsTable({
	intl,
	recipients = [],
	deliveryMethods = [],
	setMessageServiceProcess,
}) {
	const columns = useMemo(
		() => filterColumns(deliveryMethods),
		[deliveryMethods],
	)
	const [sort, setSort] = useState({
		column: columns.find((c) => c === 'address') || 'apartments',
		ascending: true,
	})
	const [selectAllLetter, setSelectAllLetter] = useState(false)
	const [selectAllEmail, setSelectAllEmail] = useState(false)
	const [selectAllSMS, setSelectAllSMS] = useState(false)
	const { confirm } = useDialogs()

	const getSortedStatus = (column) => {
		return sort.column === column ? sort.ascending : undefined
	}

	const setSortedStatus = (column) => {
		const newSort = {
			column,
		}

		if (column === sort.column) {
			newSort.ascending = !sort.ascending
		} else {
			newSort.ascending = true
		}

		setSort(newSort)
	}

	const toggleSelectAllLetter = (value) => {
		const updated = recipients.map((r) => {
			if (r.address && r.zip && r.area && r.country) {
				return {
					...r,
					checked: {
						...r.checked,
						letter: value,
					},
				}
			}
			return r
		})
		setMessageServiceProcess((prev) => ({ ...prev, recipients: updated }))
		setSelectAllLetter(value)
	}

	const toggleLetterSelection = (recipient, value) => {
		const selectedFullAddress = getFullAddress(recipient)
		const updated = recipients.map((r) => {
			const fullAddress = getFullAddress(r)
			if (fullAddress === selectedFullAddress) {
				return {
					...r,
					checked: {
						...r.checked,
						letter: value,
					},
				}
			}
			return r
		})

		setMessageServiceProcess((prev) => ({ ...prev, recipients: updated }))

		if (selectAllLetter) {
			setSelectAllLetter(false)
		}
	}

	const phoneNumberIsValid = (sms) => {
		return sms && sms.includes('+358') && sms.length > 6 // We only support sending to finnish number at the moment
	}

	const toggleSelectAllEmail = (value) => {
		const updated = recipients.map((r) => {
			if (validateEmail(r.email)) {
				return {
					...r,
					checked: {
						...r.checked,
						email: value,
					},
				}
			}
			return r
		})
		setMessageServiceProcess((prev) => ({ ...prev, recipients: updated }))
		setSelectAllEmail(value)
	}

	const toggleEmailSelection = (uuid, value) => {
		const updated = recipients.map((r) => {
			if (uuid === r.uuid) {
				return {
					...r,
					checked: {
						...r.checked,
						email: value,
					},
				}
			}
			return r
		})

		setMessageServiceProcess((prev) => ({ ...prev, recipients: updated }))

		if (selectAllEmail) {
			setSelectAllEmail(false)
		}
	}

	const toggleSelectAllSMS = (value) => {
		const updated = recipients.map((r) => {
			if (phoneNumberIsValid(r.phoneNumber)) {
				return {
					...r,
					checked: {
						...r.checked,
						sms: value,
					},
				}
			}
			return r
		})
		setMessageServiceProcess((prev) => ({ ...prev, recipients: updated }))
		setSelectAllSMS(value)
	}

	const toggleSMSSelection = (uuid, value) => {
		const updated = recipients.map((r) => {
			if (uuid === r.uuid) {
				return {
					...r,
					checked: {
						...r.checked,
						sms: value,
					},
				}
			}
			return r
		})

		setMessageServiceProcess((prev) => ({ ...prev, recipients: updated }))

		if (selectAllSMS) {
			setSelectAllSMS(false)
		}
	}

	const deleteRow = async (uuid) => {
		if (!(await confirm('Poistetaanko vastaanottaja?'))) {
			return
		}

		const updated = recipients.filter((r) => r.uuid !== uuid)
		setMessageServiceProcess((prev) => ({ ...prev, recipients: updated }))
	}

	const recipientsWithValidAddresses = recipients.filter(
		(r) => r.address && r.zip && r.area && r.country,
	)
	const [, addressMap] = getLetters(recipientsWithValidAddresses)
	const digitalDeliveryConsentSources =
		getConsentAllSources(recipients).sort(naturalSort)

	let sortFunc
	switch (sort.column) {
		case 'address':
			sortFunc = byFullAddress
			break
		case 'apartment':
			sortFunc = byApartment
			break
		default:
			sortFunc = byFullAddress
			break
	}
	let sortedRecipients = [...recipients].sort(sortFunc)
	if (!sort.ascending) {
		sortedRecipients.reverse()
	}

	const renderHeaderRow = () => {
		const headerColumns = {
			address: {
				content: intl.formatMessage({
					defaultMessage: 'Osoite',
					description:
						'Label for the meeting invitee table address column in the meeting invite dialog.',
				}),
				style: { cursor: 'pointer' },
				sorted: getSortedStatus('address'),
				onClick: () => setSortedStatus('address'),
			},
			displayName: {
				content: intl.formatMessage({
					defaultMessage: 'Nimi',
					description:
						'Label for the meeting invitee table name column in the meeting invite dialog.',
				}),
			},
			apartments: {
				content: intl.formatMessage({
					defaultMessage: 'Huoneistot',
					description:
						'Label for the meeting invitee table apartment column in the meeting invite dialog.',
				}),
				style: { cursor: 'pointer' },
				sorted: getSortedStatus('apartments'),
				onClick: () => setSortedStatus('apartments'),
			},
			email: {
				content: intl.formatMessage({
					defaultMessage: 'Sähköpostiosoite',
					description:
						'Label for the meeting invitee table email column in the meeting invite dialog.',
				}),
			},
			phoneNumber: {
				content: intl.formatMessage({
					defaultMessage: 'Puhelinnumero',
					description:
						'Label for the meeting invitee table phone number column in the meeting invite dialog.',
				}),
			},
			digitalDeliveryConsents: {
				content: intl.formatMessage({
					defaultMessage: 'Sähköisen os. pos. suostumus',
					description:
						'Label for the meeting invitee table electronic mailing agreement column in the meeting invite dialog.',
				}),
			},
			letterCheckbox: {
				content: (
					<Checkbox
						id="checkbox-recipients-select-all-letter"
						label={intl.formatMessage({
							defaultMessage: 'Kirje',
							description:
								'Label for the meeting invitee table letter column in the meeting invite dialog.',
						})}
						name="Lähetä kirjeitse"
						checked={selectAllLetter}
						onChange={(value) => {
							toggleSelectAllLetter(value)
						}}
					/>
				),
			},
			emailCheckbox: {
				content: (
					<Checkbox
						id="checkbox-invitees-selectAllEmail"
						aria-label="select-all-email"
						label={intl.formatMessage({
							defaultMessage: 'Email',
							description:
								'Label for the meeting invitee table email column in the meeting invite dialog.',
						})}
						name="Lähetä sähköpostitse"
						checked={selectAllEmail}
						onChange={(value) => {
							toggleSelectAllEmail(value)
						}}
					/>
				),
			},
			smsCheckbox: {
				content: (
					<Checkbox
						id="checkbox-invitees-selectAllSMS"
						aria-label="select-all-sms"
						label={intl.formatMessage({
							defaultMessage: 'SMS',
							description:
								'Label for the meeting invitee table sms column in the meeting invite dialog.',
						})}
						name="Lähetä tekstiviestitse"
						checked={selectAllSMS}
						onChange={(value) => {
							toggleSelectAllSMS(value)
						}}
					/>
				),
			},
			actions: {
				content: intl.formatMessage({
					defaultMessage: 'Toiminnot',
					description:
						'Label for the meeting invitee table actions column in the meeting invite dialog.',
				}),
			},
		}
		return (
			<TableRow>
				{columns.map((c) => {
					const headerColumn = headerColumns[c]
					return (
						<TableColumn
							{...headerColumn}
							key={c}
							style={{ paddingRight: 16, ...headerColumn.style }}
						>
							{headerColumn.content}
						</TableColumn>
					)
				})}
			</TableRow>
		)
	}

	const renderBodyRow = (r) => {
		const bodyColumns = {
			address: {
				content: (
					<PostalAddressContainer
						style={
							addressMap[getFullAddress(r)]?.length > 1
								? {
										background:
											'linear-gradient(90deg, ' +
											stringToColour(getFullAddress(r)) +
											'44 0%, rgba(0,0,0,0) 100%)',
										paddingLeft: 20,
										marginLeft: -24,
										borderLeftWidth: 4,
										borderLeftStyle: 'solid',
										borderLeftColor: stringToColour(getFullAddress(r)),
								  }
								: undefined
						}
					>
						{r.address ? (
							<p>{r.address}</p>
						) : (
							<p style={{ color: 'red' }}>
								{intl.formatMessage({
									defaultMessage: 'Katuosoite',
									description:
										'Placeholder for the meeting invitee table address value in the meeting invite dialog.',
								})}
							</p>
						)}
						<div className="flex-row">
							{r.zip ? (
								<p>{r.zip}</p>
							) : (
								<p style={{ color: 'red' }}>
									{intl.formatMessage({
										defaultMessage: 'Postinumero',
										description:
											'Placeholder for the meeting invitee table zip code value in the meeting invite dialog.',
									})}
								</p>
							)}
							{r.area ? (
								<p>{r.area}</p>
							) : (
								<p style={{ color: 'red' }}>
									{intl.formatMessage({
										defaultMessage: 'Postitoimipaikka',
										description:
											'Placeholder for the meeting invitee table area value in the meeting invite dialog.',
									})}
								</p>
							)}
						</div>
						{r.country && alpha2ToCountryName(r.country) ? (
							<p>{alpha2ToCountryName(r.country)}</p>
						) : (
							<p style={{ color: 'red' }}>
								{intl.formatMessage({
									defaultMessage: 'Maakoodi',
									description:
										'Placeholder for the meeting invitee table country code value in the meeting invite dialog.',
								})}
							</p>
						)}
					</PostalAddressContainer>
				),
			},
			displayName: {
				content: r.displayName || '-',
			},
			apartments: {
				content: r.apartments && r.apartments.length ? joinIdentifiers(r) : '-',
			},
			email: {
				content: r.email ? (
					<span
						style={validateEmail(r.email) ? {} : { backgroundColor: '#ffaaaa' }}
					>
						{r.email}
					</span>
				) : (
					'-'
				),
			},
			phoneNumber: {
				content: r.phoneNumber || '-',
			},
			digitalDeliveryConsents: {
				content: (
					<div className="flex-row">
						{digitalDeliveryConsentSources.map((source) => {
							const cappedSource = cap(source)
							const hasConsent =
								r.digitalDeliveryConsents && r.digitalDeliveryConsents[source]
							return (
								<div key={source} className="flex-row flex-center">
									<FontIcon
										style={{
											color: hasConsent ? 'green' : '#DD0000',
											marginRight: 8,
										}}
									>
										{hasConsent ? 'check_circle' : 'do_not_disturb_on'}
									</FontIcon>
									<p style={{ marginRight: 32 }}>{cappedSource}</p>
								</div>
							)
						})}
					</div>
				),
			},
			letterCheckbox: {
				content: (
					<div
						style={
							addressMap[getFullAddress(r)]?.length > 1
								? {
										background: stringToColour(getFullAddress(r)) + '55',
										height: '100%',
										width: 32,
										marginLeft: 4,
										marginTop:
											addressMap[getFullAddress(r)].indexOf(r) === 0
												? 8
												: addressMap[getFullAddress(r)].indexOf(r) ===
												  addressMap[getFullAddress(r)].length - 1
												? -8
												: 0,
										display: 'flex',
										alignItems: 'center',
										borderTopLeftRadius:
											addressMap[getFullAddress(r)].indexOf(r) === 0 ? 8 : 0,
										borderTopRightRadius:
											addressMap[getFullAddress(r)].indexOf(r) === 0 ? 8 : 0,
										borderBottomLeftRadius:
											addressMap[getFullAddress(r)].indexOf(r) ===
											addressMap[getFullAddress(r)].length - 1
												? 8
												: 0,
										borderBottomRightRadius:
											addressMap[getFullAddress(r)].indexOf(r) ===
											addressMap[getFullAddress(r)].length - 1
												? 8
												: 0,
								  }
								: {
										marginLeft: 4,
								  }
						}
					>
						<Checkbox
							id={`checkbox-recipient-letter-${r.uuid}`}
							aria-label="select-letter"
							name="select-letter"
							checked={r.checked?.letter}
							value={r.checked?.letter}
							onChange={(value) => toggleLetterSelection(r, value)}
							disabled={!r.address || !r.area || !r.zip || !r.country}
							style={{
								marginLeft: -4,
								marginTop:
									addressMap[getFullAddress(r)]?.length > 1
										? addressMap[getFullAddress(r)].indexOf(r) === 0
											? -8
											: addressMap[getFullAddress(r)].indexOf(r) ===
											  addressMap[getFullAddress(r)].length - 1
											? 8
											: 0
										: 0,
							}}
						/>
					</div>
				),
			},
			emailCheckbox: {
				content: (
					<Checkbox
						id={`checkbox-recipient-email-${r.uuid}`}
						aria-label="select-email"
						name="select-email"
						checked={r.checked?.email}
						value={r.checked?.email}
						onChange={(value) => toggleEmailSelection(r.uuid, value)}
						disabled={!validateEmail(r.email)}
					/>
				),
			},
			smsCheckbox: {
				content: (
					<Checkbox
						id={`checkbox-recipient-sms-${r.uuid}`}
						aria-label="select-sms"
						name="select-sms"
						checked={r.checked?.sms}
						value={r.checked?.sms}
						onChange={(value) => toggleSMSSelection(r.uuid, value)}
						disabled={!phoneNumberIsValid(r.phoneNumber)}
					/>
				),
			},
			actions: {
				content: (
					<Button
						icon
						onClick={() => {
							deleteRow(r.uuid)
						}}
					>
						delete
					</Button>
				),
			},
		}
		return (
			<TableRow key={r.uuid}>
				{columns.map((c) => {
					const bodyColumn = bodyColumns[c]
					return (
						<TableColumn
							{...bodyColumn}
							key={c}
							style={{ paddingRight: 16, ...bodyColumn.style }}
						>
							{bodyColumn.content}
						</TableColumn>
					)
				})}
			</TableRow>
		)
	}

	return (
		<DataTable plain style={{ flex: 1 }}>
			<TableHeader>{renderHeaderRow()}</TableHeader>
			<TableBody>{sortedRecipients.map((r) => renderBodyRow(r))}</TableBody>
		</DataTable>
	)
}

export default compose(injectIntl)(RecipientsTable)
