import React from 'react'
import { Button } from 'react-md'
import styled from 'styled-components'
import { breakpoint } from 'ui/StyledComponents/MediaBreakpoints'

const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex-grow: 1;
	max-width: 100vw;
`
const StyledButton = styled(Button)`
	margin-top: 16px;
`
const EmptyStateImage = styled.img`
	max-width: 8vw;
	padding-bottom: 42px;
	user-select: none;
	@media ${breakpoint.tablet} {
		max-width: 12vw;
	}
	@media ${breakpoint.phone} {
		max-width: 24vw;
	}
`
const EmptyStateImageBig = styled.img`
	max-width: 16vw;
	padding-bottom: 42px;
	user-select: none;
	@media ${breakpoint.tablet} {
		max-width: 24vw;
	}
	@media ${breakpoint.phone} {
		max-width: 48vw;
	}
`

type Props = {
	text?: string
	buttonText?: string
	buttonOnClick?: Function
	buttonStyle?: object
	icon?: any
	bigIcon?: boolean
	children?: any
}

function EmptyState({
	text,
	buttonText,
	buttonOnClick,
	buttonStyle,
	icon,
	bigIcon,
	children,
}: Props) {
	return (
		<Container>
			{icon ? (
				bigIcon ? (
					<EmptyStateImageBig src={icon} alt="tyhjä" />
				) : (
					<EmptyStateImage src={icon} alt="tyhjä" />
				)
			) : null}
			<p
				className="text-subtle text-center text--lg"
				style={{ userSelect: 'none', overflowWrap: 'anywhere' }}
			>
				{text}
			</p>
			{buttonText ? (
				<StyledButton
					raised
					primary
					onClick={buttonOnClick}
					style={buttonStyle ? buttonStyle : undefined}
				>
					{buttonText}
				</StyledButton>
			) : null}
			{children}
		</Container>
	)
}

export default EmptyState
