import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { Button, TextField } from 'react-md'
import { FormattedMessage, injectIntl } from 'react-intl'

import * as notifyActions from 'state/notifyActions'

import illustrationContacts from 'svg/illustrations/illustration_contacts.svg'
import {
	ONBOARDING_NEXT_MESSAGE,
	ONBOARDING_VALIDATION_ERROR,
} from './messages'

const LENGTH_LIMIT = 70

export class UserOnboardingStepName extends Component {
	state = {
		firstName: null,
		lastName: null,
	}

	componentDidMount = () => {
		const { onboardingReducer } = this.props
		const { displayName } = onboardingReducer
		const splits = (displayName || '').split(' ')
		const firstName = splits[0]
		const lastName = splits.length > 1 ? splits.slice(1).join(' ') : ''
		this.setState({ firstName, lastName })
	}

	componentWillUnmount = () => {
		const { firstName, lastName } = this.state
		const { onUpdate } = this.props

		if (this.validate(firstName) && this.validate(lastName)) {
			// we need to trim here in order to be able to type spaces when altering the last name
			onUpdate({ displayName: firstName.trim() + ' ' + lastName.trim() })
		}
	}

	onFirstNameChange = (firstName) => {
		if (firstName.includes(' ')) return // do not allow spaces
		if (firstName.length > LENGTH_LIMIT) return
		this.setState((prevState) => {
			return { ...prevState, firstName }
		})
	}

	onLastNameChange = (lastName) => {
		if (lastName[0] === ' ') return // do not allow initial space
		if (lastName.length > 1 && / {2}/.test(lastName)) {
			return // do not allow 2 consecutive spaces
		}
		if (lastName.length > LENGTH_LIMIT) return

		this.setState((prevState) => {
			return { ...prevState, lastName }
		})
	}

	validate = (name) => {
		return name && name !== '' && name !== ' ' // latter clause just in case
	}

	next = async () => {
		const { onNext, _showInfoNotification, intl } = this.props
		const { firstName, lastName } = this.state

		if (this.validate(firstName) && this.validate(lastName)) {
			onNext()
		} else {
			_showInfoNotification(ONBOARDING_VALIDATION_ERROR(intl))
		}
	}

	render = () => {
		const { intl } = this.props
		const { firstName, lastName } = this.state

		return (
			<div
				className="flex-column flex-center"
				style={{ height: 'calc(100% - 56px)', justifyContent: 'flex-start' }}
			>
				<img
					alt=""
					src={illustrationContacts}
					style={{
						width: 288,
						height: 'auto',
						position: 'relative',
						margin: 16,
					}}
				/>
				<h4 className="text-center margin-bottom">
					<FormattedMessage
						defaultMessage="Etu- ja sukunimesi"
						description="Header of the name section of the user profile update dialog."
					/>
				</h4>
				<TextField
					id="floating-center-title-user-firstname"
					label={intl.formatMessage({
						defaultMessage: 'Etunimesi',
						description: 'Label of the first name field.',
					})}
					lineDirection="center"
					value={firstName}
					onChange={this.onFirstNameChange}
					autoComplete="off"
					required={true}
					fullWidth={false}
					style={{ width: 288 }}
				/>
				<TextField
					id="floating-center-title-user-lastname"
					label={intl.formatMessage({
						defaultMessage: 'Sukunimesi',
						description: 'Label of the last name field.',
					})}
					lineDirection="center"
					value={lastName}
					onChange={this.onLastNameChange}
					autoComplete="off"
					required={true}
					fullWidth={false}
					style={{ width: 288 }}
				/>
				<Button
					raised
					secondary
					onClick={this.next}
					style={{ position: 'absolute', right: 24, bottom: 24 }}
				>
					{ONBOARDING_NEXT_MESSAGE(intl)}
				</Button>
			</div>
		)
	}
}

UserOnboardingStepName.propTypes = {
	onUpdate: PropTypes.func.isRequired,
	onNext: PropTypes.func.isRequired,
}

const mapState = ({ ui: { onboarding: onboardingReducer } }) => ({
	onboardingReducer,
})

const mapDispatchToProps = (dispatch) => ({
	_showInfoNotification: bindActionCreators(notifyActions.info, dispatch),
})

export default compose(
	injectIntl,
	connect(mapState, mapDispatchToProps),
)(UserOnboardingStepName)
