import { TableContainer } from 'ui/StyledComponents/Tables'
import FloatingActionBtn from 'ui/components/FloatingActionBtn'
import { useIntl } from 'react-intl'
import {
	TableRow,
	TableColumn,
	DataTable,
	TableHeader,
	TableBody,
	ListItem,
	FontIcon,
} from 'react-md'
import OverflowGradient from 'ui/components/OverflowGradient'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import config from 'config'
import useDialogs from 'ui/components/dialogs/useDialogs'
import * as notifyActions from 'state/notifyActions'
import { GENERIC_ERROR_NOTIFICATION } from 'ui/messages'
import ValueAddedTaxTableRow from './ValueAddedTaxTableRow'
import useValueAddedTaxes from './useValueAddedTaxes'
import ValueAddedTaxDialog from './ValueAddedTaxDialog'
import { valueAddedTaxTableHeaders } from './constants'

const HASH_VALUE_ADDED_TAX = config.routeHashes.valueAddedTax

const ValueAddedTax = () => {
	const intl = useIntl()
	const dispatch = useDispatch()
	const { confirm } = useDialogs()
	const [processing, setProcessing] = useState<boolean>(false)

	const hash = window.location.hash
	const queryParams = new URLSearchParams(hash.split('?')[1])
	const vatUUID = queryParams.get('uuid')

	const [addDialogVisible, setDialogVisible] = useState<boolean>(false)

	const { valueAddedTaxes, refresh } = useValueAddedTaxes()

	const showAddValueAddedTaxDialog = () => {
		setDialogVisible(true)
	}

	const hideAddValueAddedTaxDialog = () => {
		if (vatUUID) {
			dispatch(push(window.location.pathname + HASH_VALUE_ADDED_TAX))
		}
		setDialogVisible(false)
	}

	const renderFAB = () => {
		return (
			<FloatingActionBtn
				iconName={'add'}
				text={intl.formatMessage({
					defaultMessage: 'UUSI ALV',
					description: 'Add new vat',
				})}
				action={showAddValueAddedTaxDialog}
			/>
		)
	}
	const deleteValueAddedTax = async (uuid: string) => {
		const confirmMessage = intl.formatMessage({
			defaultMessage: 'Oletko varma, että haluat poistaa arvolisäveron?',
			description: 'Confirm message for the delete vat.',
		})
		if (!(await confirm(confirmMessage))) {
			return
		}

		setProcessing(true)

		const result = { ok: true } //await _delete(uuid) TODO: Do we need to delete these?
		setProcessing(false)

		if (!result || result.ok === false) {
			dispatch(notifyActions.info('❌ ' + GENERIC_ERROR_NOTIFICATION(intl)))
			return false
		}

		dispatch(
			notifyActions.info(
				'✅ ' +
					intl.formatMessage({
						defaultMessage: 'Arvonlisävero on poistettu.',
						description: 'Notification text vat has been deleted',
					}),
			),
		)
		refresh()
		return true
	}

	const renderTableHeaders = () => {
		return (
			<TableRow>
				{valueAddedTaxTableHeaders
					.filter((h) => h.show)
					.map((header) => (
						<TableColumn key={header.key}>
							<p className="text-strong text-subtle">{header.title(intl)}</p>
						</TableColumn>
					))}
				<TableColumn key={'children-header'}></TableColumn>
			</TableRow>
		)
	}

	const handleOnClick = (uuid: string) => {
		dispatch(
			push(window.location.pathname + HASH_VALUE_ADDED_TAX + '?uuid=' + uuid),
		)
	}

	return (
		<TableContainer>
			{valueAddedTaxes ? (
				<TableContainer>
					<DataTable
						plain
						style={{
							background: 'white',
							maxHeight: 'calc(100vh - 205px)',
						}}
					>
						<TableHeader
							style={{
								position: 'sticky',
								top: 0,
								zIndex: 1,
								background: 'white',
							}}
						>
							{renderTableHeaders()}
						</TableHeader>
						<TableBody>
							{valueAddedTaxes
								.map((valueAddedTax) => (
									<ValueAddedTaxTableRow
										key={valueAddedTax.uuid}
										entity={valueAddedTax}
										menuItems={[
											<ListItem
												key={1}
												primaryText={intl.formatMessage({
													defaultMessage: 'Poista arvonlisävero',
													description: 'Delete vat',
												})}
												leftIcon={<FontIcon>delete</FontIcon>}
												onClick={() => deleteValueAddedTax(valueAddedTax.uuid)}
												className="md-divider-border md-divider-border--right"
											/>,
										]}
										children={null}
										processing={processing}
										handleOnClick={() => handleOnClick(valueAddedTax.uuid)}
									/>
								))
								.concat(
									<tr
										key="empty-row"
										className="full-width"
										style={{ height: 100, background: 'white' }}
									></tr>,
								)}
						</TableBody>
						<OverflowGradient style={{ bottom: 40 }} />
					</DataTable>
				</TableContainer>
			) : null}

			<ValueAddedTaxDialog
				id={vatUUID}
				refresh={refresh}
				visible={typeof vatUUID === 'string' || addDialogVisible}
				onHide={hideAddValueAddedTaxDialog}
			/>

			{renderFAB()}
		</TableContainer>
	)
}

export default ValueAddedTax
