import { useEffect, useState } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
	CardTitle,
	CardActions,
	CardText,
	Button,
	TextField,
	Checkbox,
	ExpansionList,
	ExpansionPanel,
} from 'react-md'
import { push } from 'connected-react-router'

import * as notifyActions from 'state/notifyActions'
import * as merchantActions from 'state/merchant-actions'

import WithUserRoles from 'ui/components/userRoles/WithUserRoles'
import { StandardCard } from 'ui/StyledComponents/Cards'
import { firestoreConnect } from 'react-redux-firebase'
import LoadingState from 'ui/components/LoadingState'
import { GENERIC_ERROR_NOTIFICATION } from 'ui/messages'
import { injectIntl } from 'react-intl'
import DropdownField from '../DropdownField'
import { DestructiveButton } from 'ui/StyledComponents/Buttons'
import useDialogs from '../dialogs/useDialogs'

const PAYMENT_FEATURES = {
	FORM: 'FORM',
	SHOP: 'SHOP',
	RESERVATION: 'RESERVATION',
}

const PAYMENT_PROVIDER_GROUPS = {
	BANK: 'bank',
	CREDIT: 'credit',
	CREDITCARD: 'creditcard',
	MOBILE: 'mobile',
}

const translatePaymentProviderGroup = (input) => {
	if (input === PAYMENT_PROVIDER_GROUPS.BANK) {
		return 'Pankkimaksutavat'
	} else if (input === PAYMENT_PROVIDER_GROUPS.CREDIT) {
		return 'Lasku- ja osamaksutavat'
	} else if (input === PAYMENT_PROVIDER_GROUPS.CREDITCARD) {
		return 'Korttimaksutavat'
	} else if (input === PAYMENT_PROVIDER_GROUPS.MOBILE) {
		return 'Mobiilimaksutavat'
	}
	return 'Muut maksutavat'
}

const MerchantSettings = ({
	paymentsEnabled,
	intl,
	merchantUUID,
	merchant,
	isSuperAdmin,
	_update,
	_delete,
	_showInfoNotification,
	_push,
}) => {
	const [processing, setProcessing] = useState(false)
	const [showForm, setShowForm] = useState(false)
	const [provider, setProvider] = useState('none')
	const [forceStaging, setForceStaging] = useState(false)
	const [account, setAccount] = useState(null)
	const [businessId, setBusinessId] = useState(null)
	const [email, setEmail] = useState(null)
	const [logoURL, setLogoURL] = useState(null)
	const [name, setName] = useState(null)
	const [formFee, setFormFee] = useState(null)
	const [formPercentage, setFormPercentage] = useState(null)
	const [formMinimumOrder, setFormMinimumOrder] = useState(null)
	const [shopFee, setShopFee] = useState(null)
	const [shopPercentage, setShopPercentage] = useState(null)
	const [shopMinimumOrder, setShopMinimumOrder] = useState(null)
	const [reservationFee, setReservationFee] = useState(null)
	const [reservationPercentage, setReservationPercentage] = useState(null)
	const [reservationMinimumOrder, setReservationMinimumOrder] = useState(null)
	const [providerGroupWhitelist, setProviderGroupWhitelist] = useState([])
	const [providerGroupMinimumOrder, setProviderGroupMinimumOrder] = useState({})
	const [invoicingProvider, setInvoicingProvider] = useState('none')
	const [invoicingCreditorId, setInvoicingCreditorId] = useState(null)
	const [invoicingPaymentPeriod, setInvoicingPaymentPeriod] = useState(0)
	const [invoicingFee, setInvoicingFee] = useState(0)
	const [invoicingSettlementAccount, setInvoicingSettlementAccount] =
		useState(null)
	const [invoicingSettlementBic, setInvoicingSettlementBic] = useState(null)
	const [invoicingFactoringProduct, setInvoicingFactoringProduct] =
		useState(null)

	const { confirm } = useDialogs()

	useEffect(() => {
		if (merchant && isSuperAdmin) {
			setShowForm(true)
			setProvider(merchant.provider)
			setForceStaging(merchant.forceStaging || false)
			setAccount(merchant.account)
			setBusinessId(merchant.businessId)
			setEmail(merchant.email)
			setLogoURL(merchant.logoURL)
			setName(merchant.name)
			setFormFee(merchant.commission ? merchant.commission.FORM.fee : 0)
			setFormPercentage(
				merchant.commission ? merchant.commission.FORM.percentage : 0,
			)
			setFormMinimumOrder(
				merchant.commission ? merchant.commission.FORM.minimumOrder : 0,
			)
			setShopFee(merchant.commission ? merchant.commission.SHOP.fee : 0)
			setShopPercentage(
				merchant.commission ? merchant.commission.SHOP.percentage : 0,
			)
			setShopMinimumOrder(
				merchant.commission ? merchant.commission.SHOP.minimumOrder : 0,
			)
			setReservationFee(
				merchant.commission ? merchant.commission.RESERVATION.fee : 0,
			)
			setReservationPercentage(
				merchant.commission ? merchant.commission.RESERVATION.percentage : 0,
			)
			setReservationMinimumOrder(
				merchant.commission ? merchant.commission.RESERVATION.minimumOrder : 0,
			)
			setProviderGroupWhitelist(
				merchant.providerGroupWhitelist ? merchant.providerGroupWhitelist : [],
			)
			setProviderGroupMinimumOrder(
				merchant.providerGroupMinimumOrder
					? merchant.providerGroupMinimumOrder
					: {},
			)
			setInvoicingProvider(
				merchant.invoicingProvider ? merchant.invoicingProvider : 'none',
			)
			setInvoicingCreditorId(
				merchant.invoicingCreditorId ? merchant.invoicingCreditorId : null,
			)
			setInvoicingPaymentPeriod(
				merchant.invoicingPaymentPeriod ? merchant.invoicingPaymentPeriod : 0,
			)
			setInvoicingFee(merchant.invoicingFee ? merchant.invoicingFee : 0)
			setInvoicingSettlementAccount(
				merchant.invoicingSettlementAccount
					? merchant.invoicingSettlementAccount
					: null,
			)
			setInvoicingSettlementBic(
				merchant.invoicingSettlementBic
					? merchant.invoicingSettlementBic
					: null,
			)
			setInvoicingFactoringProduct(
				merchant.invoicingFactoringProduct
					? merchant.invoicingFactoringProduct
					: null,
			)
		}
	}, [merchant, isSuperAdmin])

	if (processing) {
		return <LoadingState />
	}

	const onUpdate = async () => {
		const transformCommissionFee = (fee) => {
			if (typeof fee === 'string') {
				fee = fee.replace(',', '.')
			}
			const numericFee = Number.parseFloat(fee)
			if (Number.isNaN(numericFee)) {
				_showInfoNotification('❌ Kiinteä komissio on virheellinen.')
				return false
			}

			if (numericFee < 0) {
				_showInfoNotification('❌ Kiinteä komissio ei voi olla negatiivinen.')
				return false
			}

			return numericFee
		}

		const transformCommissionPercentage = (percentage) => {
			if (typeof percentage === 'string') {
				percentage = percentage.replace(',', '.')
			}
			const numericPercentage = Number.parseFloat(percentage)
			if (Number.isNaN(numericPercentage)) {
				_showInfoNotification('❌ Komissioprosentti on virheellinen.')
				return false
			}

			if (numericPercentage < 0) {
				_showInfoNotification('❌ Komissioprosentti ei voi olla negatiivinen.')
				return false
			}

			return numericPercentage
		}

		const transformCommissionMinimumOrder = (minimumOrder) => {
			if (typeof minimumOrder === 'string') {
				minimumOrder = minimumOrder.replace(',', '.')
			}
			const numericMinimumOrder = Number.parseFloat(minimumOrder)
			if (Number.isNaN(numericMinimumOrder)) {
				_showInfoNotification('❌ Minimitilaus on virheellinen.')
				return false
			}

			if (numericMinimumOrder < 0) {
				_showInfoNotification('❌ Minimitilaus ei voi olla negatiivinen.')
				return false
			}

			return numericMinimumOrder
		}

		const commission = {
			[PAYMENT_FEATURES.FORM]: {
				fee: transformCommissionFee(formFee),
				percentage: transformCommissionPercentage(formPercentage),
				minimumOrder: transformCommissionMinimumOrder(formMinimumOrder),
			},
			[PAYMENT_FEATURES.SHOP]: {
				fee: transformCommissionFee(shopFee),
				percentage: transformCommissionPercentage(shopPercentage),
				minimumOrder: transformCommissionMinimumOrder(shopMinimumOrder),
			},
			[PAYMENT_FEATURES.RESERVATION]: {
				fee: transformCommissionFee(reservationFee),
				percentage: transformCommissionPercentage(reservationPercentage),
				minimumOrder: transformCommissionMinimumOrder(reservationMinimumOrder),
			},
		}

		let invalidProviderGroupMinimumOrder = false
		const sanitizedProviderGroupMinimumOrder = {}

		Object.entries(providerGroupMinimumOrder).forEach(([key, value]) => {
			const numericMinimumOrder = Number.parseFloat(value)

			if (Number.isNaN(numericMinimumOrder)) {
				invalidProviderGroupMinimumOrder = true
			}

			if (numericMinimumOrder < 0) {
				invalidProviderGroupMinimumOrder = true
			}

			sanitizedProviderGroupMinimumOrder[key] = numericMinimumOrder
		})

		if (invalidProviderGroupMinimumOrder) {
			_showInfoNotification('❌ Maksutavan minimitilaus on virheellinen.')
			return false
		}

		const sanitizedInvoicingPaymentPeriod = Number.parseInt(
			invoicingPaymentPeriod,
			10,
		)
		if (isNaN(sanitizedInvoicingPaymentPeriod)) {
			_showInfoNotification('❌ Laskutuksen maksuehto on virheellinen.')
			return false
		}

		const sanitizedInvoicingFee = Number.parseFloat(
			typeof invoicingFee === 'string'
				? invoicingFee.replace(',', '.')
				: invoicingFee,
		)
		if (isNaN(sanitizedInvoicingFee)) {
			_showInfoNotification('❌ Laskutuksen laskutuslisä on virheellinen.')
			return false
		}

		if (invoicingProvider && invoicingProvider !== 'none') {
			if (!invoicingSettlementAccount) {
				_showInfoNotification('❌ Laskutuksen pankkitili on virheellinen.')
				return false
			}

			if (!invoicingSettlementBic) {
				_showInfoNotification(
					'❌ Laskutuksen pankkitilin BIC koodi on virheellinen.',
				)
				return false
			}

			if (!invoicingFactoringProduct) {
				_showInfoNotification(
					'❌ Laskutuksen rahoitustuotteen koodi on virheellinen.',
				)
				return false
			}
		}

		setProcessing(true)
		const result = await _update({
			uuid: merchantUUID,
			name,
			account,
			businessId,
			email,
			logoURL,
			provider,
			commission,
			forceStaging,
			providerGroupWhitelist,
			providerGroupMinimumOrder: sanitizedProviderGroupMinimumOrder,
			invoicingProvider,
			invoicingCreditorId,
			invoicingPaymentPeriod: sanitizedInvoicingPaymentPeriod,
			invoicingFee: sanitizedInvoicingFee,
			invoicingSettlementAccount,
			invoicingSettlementBic,
			invoicingFactoringProduct,
		})
		setProcessing(false)

		if (!result || result.success === false) {
			_showInfoNotification('❌ ' + GENERIC_ERROR_NOTIFICATION(intl))
			return false
		}

		_showInfoNotification('✅ Asetukset tallennettu')
		window.location.reload(true)
	}

	const onProducts = () => {
		if (window.location.pathname.includes('/manager')) {
			_push('/manager/products')
		} else {
			_push('/' + window.location.pathname.split('/')[1] + '/products')
		}
	}

	const onPayments = () => {
		if (window.location.pathname.includes('/manager')) {
			_push('/manager/payments')
		} else {
			_push('/' + window.location.pathname.split('/')[1] + '/payments')
		}
	}

	const onDelete = async () => {
		if (
			!(await confirm(
				'Poistetaan maksupalvelun tiedot? Tätä ei voi peruuttaa.',
			))
		) {
			return false
		}
		setProcessing(true)
		const result = await _delete({
			uuid: merchantUUID,
		})
		setProcessing(false)

		if (!result || result.success === false) {
			_showInfoNotification('❌ ' + GENERIC_ERROR_NOTIFICATION(intl))
			return false
		}

		_showInfoNotification('✅ Tiedot poistettu')
		window.location.reload(true)
	}

	if (!paymentsEnabled) {
		return (
			<div className="full-width">
				<StandardCard className="margin-bottom full-width">
					<CardTitle title={'Maksupalvelu'} />
					<CardText>
						Voit ottaa maksupalvelun käyttöön, joka mahdollistaa esimerkiksi
						asiakirjatilausten ja vuorojen varausten maksuliikenteen hoitamisen.
					</CardText>
					<CardText>Maksupalvelu-moduulia ei olla kytketty päälle.</CardText>
				</StandardCard>
			</div>
		)
	}

	const integrationEnabled = Boolean(merchant)

	return (
		<div className="full-width">
			<StandardCard
				className="margin-bottom full-width"
				style={integrationEnabled ? { background: 'rgb(240,255,240)' } : {}}
			>
				<CardTitle title={'Maksupalvelu'} />
				<CardText>
					Voit ottaa maksupalvelun käyttöön, joka mahdollistaa esimerkiksi
					asiakirjatilausten ja vuorojen varausten maksuliikenteen hoitamisen.
				</CardText>
				{showForm ? (
					<CardText>
						<Checkbox
							id="forceStaging"
							name="forceStaging"
							label="Demotunnus"
							value={forceStaging}
							onChange={(value) => setForceStaging(value)}
							checked={forceStaging}
							style={{ marginBottom: 16, marginTop: 16, marginLeft: -10 }}
						/>
						<TextField
							id="floating-center-name"
							label="Kauppiaan nimi"
							lineDirection="center"
							fullWidth
							value={name || ''}
							onChange={setName}
							required={true}
							autoComplete="off"
						/>
						<TextField
							id="floating-center-businessId"
							label="Kauppiaan Y-tunnus"
							lineDirection="center"
							fullWidth
							value={businessId || ''}
							onChange={setBusinessId}
							required={true}
							autoComplete="off"
						/>
						<TextField
							id="floating-center-email"
							label="Kauppiaan email"
							lineDirection="center"
							fullWidth
							value={email || ''}
							onChange={setEmail}
							required={true}
							autoComplete="off"
						/>
						<TextField
							id="floating-center-logoURL"
							label="Kauppiaan logon URL"
							lineDirection="center"
							fullWidth
							value={logoURL || ''}
							onChange={setLogoURL}
							required={false}
							autoComplete="off"
						/>

						<ExpansionList style={{ marginTop: 32 }}>
							<ExpansionPanel label="Maksunvälitys" footer={null}>
								<div>
									<DropdownField
										label={'Maksunvälittäjä'}
										options={[
											{
												label: 'Paytrail Oyj',
												value: 'paytrail',
											},
											{
												label: 'Ei valittu',
												value: 'none',
											},
										]}
										_onOptionSelect={setProvider}
										hasSelectAll={false}
										nullSelectAll={false}
										itemLabel={'label'}
										itemValue={'value'}
										icon={'euro'}
										value={provider}
									/>
								</div>

								<TextField
									id="floating-center-account"
									label="Kauppiaan maksunvälitys-tili"
									lineDirection="center"
									fullWidth
									value={account || ''}
									onChange={setAccount}
									required={true}
									autoComplete="off"
									disabled={forceStaging}
								/>

								<div style={{ marginTop: 32 }}>
									<h4>Viilun komissio</h4>
									<div style={{ marginTop: 16 }}>
										<strong>Lomaketilaus</strong>
										<div style={{ display: 'flex', flexDirection: 'row' }}>
											<TextField
												id="floating-center-commission-form-fee"
												label="Kiinteä (€ per transaktio)"
												lineDirection="center"
												fullWidth
												value={
													formFee === undefined || formFee === null
														? ''
														: formFee
												}
												onChange={setFormFee}
												required={true}
												autoComplete="off"
												style={{ marginRight: 16 }}
											/>
											<TextField
												id="floating-center-commission-form-percentage"
												label="Prosentti (% per transaktio)"
												lineDirection="center"
												fullWidth
												value={
													formPercentage === undefined ||
													formPercentage === null
														? ''
														: formPercentage
												}
												onChange={setFormPercentage}
												required={true}
												autoComplete="off"
											/>
											<TextField
												id="floating-center-commission-form-min-amount"
												label="Minimitilaus"
												lineDirection="center"
												fullWidth
												value={
													formMinimumOrder === undefined ||
													formMinimumOrder === null
														? ''
														: formMinimumOrder
												}
												onChange={setFormMinimumOrder}
												required={true}
												autoComplete="off"
											/>
										</div>
									</div>
									<div style={{ marginTop: 16 }}>
										<strong>Verkkokauppatilaus</strong>
										<div style={{ display: 'flex', flexDirection: 'row' }}>
											<TextField
												id="floating-center-commission-shop-fee"
												label="Kiinteä (€ per transaktio)"
												lineDirection="center"
												fullWidth
												value={
													shopFee === undefined || shopFee === null
														? ''
														: shopFee
												}
												onChange={setShopFee}
												required={true}
												autoComplete="off"
												style={{ marginRight: 16 }}
											/>
											<TextField
												id="floating-center-commission-shop-percentage"
												label="Prosentti (% per transaktio)"
												lineDirection="center"
												fullWidth
												value={
													shopPercentage === undefined ||
													shopPercentage === null
														? ''
														: shopPercentage
												}
												onChange={setShopPercentage}
												required={true}
												autoComplete="off"
											/>
											<TextField
												id="floating-center-commission-shop-min-amount"
												label="Minimitilaus"
												lineDirection="center"
												fullWidth
												value={
													shopMinimumOrder === undefined ||
													shopMinimumOrder === null
														? ''
														: shopMinimumOrder
												}
												onChange={setShopMinimumOrder}
												required={true}
												autoComplete="off"
											/>
										</div>
									</div>
									<div style={{ marginTop: 16 }}>
										<strong>Varaus</strong>
										<div style={{ display: 'flex', flexDirection: 'row' }}>
											<TextField
												id="floating-center-commission-reservation-fee"
												label="Kiinteä (€ per transaktio)"
												lineDirection="center"
												fullWidth
												value={
													reservationFee === undefined ||
													reservationFee === null
														? ''
														: reservationFee
												}
												onChange={setReservationFee}
												required={true}
												autoComplete="off"
												style={{ marginRight: 16 }}
											/>
											<TextField
												id="floating-center-commission-reservation-percentage"
												label="Prosentti (% per transaktio)"
												lineDirection="center"
												fullWidth
												value={
													reservationPercentage === undefined ||
													reservationPercentage === null
														? ''
														: reservationPercentage
												}
												onChange={setReservationPercentage}
												required={true}
												autoComplete="off"
											/>
											<TextField
												id="floating-center-commission-reservation-min-amount"
												label="Minimitilaus"
												lineDirection="center"
												fullWidth
												value={
													reservationMinimumOrder === undefined ||
													reservationMinimumOrder === null
														? ''
														: reservationMinimumOrder
												}
												onChange={setReservationMinimumOrder}
												required={true}
												autoComplete="off"
											/>
										</div>
									</div>
								</div>
								<div style={{ marginTop: 32 }}>
									<h4>Käytettävissä olevat maksutavat</h4>
									<div
										style={{
											marginTop: 16,
											display: 'flex',
											flexDirection: 'row',
										}}
									>
										<div>
											{Object.values(PAYMENT_PROVIDER_GROUPS).map((pg) => (
												<Checkbox
													id={pg}
													name={pg}
													label={translatePaymentProviderGroup(pg)}
													value={
														providerGroupWhitelist &&
														providerGroupWhitelist.includes(pg)
													}
													onChange={() => {
														let whitelist = providerGroupWhitelist
															? [...providerGroupWhitelist]
															: []
														if (whitelist.includes(pg)) {
															whitelist = whitelist.filter((p) => p !== pg)
														} else {
															whitelist.push(pg)
														}
														setProviderGroupWhitelist(whitelist)
													}}
													checked={
														providerGroupWhitelist &&
														providerGroupWhitelist.includes(pg)
													}
													style={{
														marginBottom: 16,
														marginTop: 16,
														marginLeft: -10,
													}}
												/>
											))}
										</div>
										<div
											style={{
												background: 'white',
												padding: 24,
												marginLeft: 32,
												borderRadius: 8,
												minWidth: 320,
											}}
										>
											<strong>Järjestyksen esikatselu</strong>
											<div>
												{providerGroupWhitelist
													? providerGroupWhitelist.map((pg, index) => (
															<div>
																<p>
																	{index + 1}.{' '}
																	{translatePaymentProviderGroup(pg)}
																</p>
																<div
																	style={{
																		display: 'flex',
																		flexDirection: 'row',
																	}}
																>
																	<div
																		style={{
																			width: 32,
																			height: 20,
																			borderRadius: 4,
																			background: '#DADADA',
																			marginRight: 4,
																			marginBottom: 4,
																		}}
																	></div>
																	<div
																		style={{
																			width: 32,
																			height: 20,
																			borderRadius: 4,
																			background: '#DADADA',
																			marginRight: 4,
																			marginBottom: 4,
																		}}
																	></div>
																	<div
																		style={{
																			width: 32,
																			height: 20,
																			borderRadius: 4,
																			background: '#DADADA',
																			marginRight: 4,
																			marginBottom: 4,
																		}}
																	></div>
																	<div
																		style={{
																			width: 32,
																			height: 20,
																			borderRadius: 4,
																			background: '#DADADA',
																			marginRight: 4,
																			marginBottom: 4,
																		}}
																	></div>
																</div>
															</div>
													  ))
													: null}
											</div>
										</div>
									</div>
								</div>
								<div style={{ marginTop: 32 }}>
									<h4>Maksutapojen minimitilaukset</h4>
									<div
										style={{
											marginTop: 16,
											display: 'flex',
											flexDirection: 'row',
										}}
									>
										<div>
											{Object.values(PAYMENT_PROVIDER_GROUPS).map((pg) => (
												<TextField
													id={`floating-center-payment-provider-${pg}-min-amount`}
													label={`${translatePaymentProviderGroup(
														pg,
													)} minimitilaus`}
													lineDirection="center"
													fullWidth
													value={
														providerGroupMinimumOrder &&
														providerGroupMinimumOrder[pg] !== undefined
															? providerGroupMinimumOrder[pg]
															: '0'
													}
													onChange={(value) => {
														const minimumOrders = providerGroupMinimumOrder
															? { ...providerGroupMinimumOrder }
															: {}
														if (typeof value === 'string') {
															value = value.replace(',', '.')
														}
														minimumOrders[pg] = value
														setProviderGroupMinimumOrder(minimumOrders)
													}}
													required={true}
													autoComplete="off"
												/>
											))}
										</div>
									</div>
								</div>
							</ExpansionPanel>
							<ExpansionPanel label="Laskutus" footer={null}>
								<div>
									<DropdownField
										label={'Laskutuspalvelu'}
										options={[
											{
												label: 'KTI Laskutus Oy',
												value: 'kti',
											},
											{
												label: 'Ei valittu',
												value: 'none',
											},
										]}
										_onOptionSelect={setInvoicingProvider}
										hasSelectAll={false}
										nullSelectAll={false}
										itemLabel={'label'}
										itemValue={'value'}
										icon={'euro'}
										value={invoicingProvider}
									/>
									<TextField
										id="floating-center-invoicing-creditor-id"
										label="Laskuttajan tunnus"
										lineDirection="center"
										fullWidth
										value={
											invoicingCreditorId === undefined ||
											invoicingCreditorId === null
												? ''
												: invoicingCreditorId
										}
										onChange={setInvoicingCreditorId}
										autoComplete="off"
										disabled
									/>
									<TextField
										id="floating-center-invoicing-payment-period"
										label="Maksuehto (päivää)"
										lineDirection="center"
										fullWidth
										value={
											invoicingPaymentPeriod === undefined ||
											invoicingPaymentPeriod === null
												? ''
												: invoicingPaymentPeriod
										}
										onChange={setInvoicingPaymentPeriod}
										required={invoicingProvider && invoicingProvider !== 'none'}
										autoComplete="off"
									/>
									<TextField
										id="floating-center-invoicing-fee"
										label="Laskutuslisä (meidän veloitus)"
										lineDirection="center"
										fullWidth
										value={
											invoicingFee === undefined || invoicingFee === null
												? ''
												: invoicingFee
										}
										onChange={setInvoicingFee}
										required={invoicingProvider && invoicingProvider !== 'none'}
										autoComplete="off"
									/>
									<TextField
										id="floating-center-invoicing-settlement-account"
										label="Pankkitili tilityksille"
										lineDirection="center"
										fullWidth
										value={
											invoicingSettlementAccount === undefined ||
											invoicingSettlementAccount === null
												? ''
												: invoicingSettlementAccount
										}
										onChange={setInvoicingSettlementAccount}
										required={invoicingProvider && invoicingProvider !== 'none'}
										autoComplete="off"
									/>
									<TextField
										id="floating-center-invoicing-settlement-bic"
										label="Pankkitilin BIC koodi"
										lineDirection="center"
										fullWidth
										value={
											invoicingSettlementBic === undefined ||
											invoicingSettlementBic === null
												? ''
												: invoicingSettlementBic
										}
										onChange={setInvoicingSettlementBic}
										required={invoicingProvider && invoicingProvider !== 'none'}
										autoComplete="off"
									/>
									<TextField
										id="floating-center-invoicing-factoring-product"
										label="Rahoitustuotteen koodi"
										lineDirection="center"
										fullWidth
										value={
											invoicingFactoringProduct === undefined ||
											invoicingFactoringProduct === null
												? ''
												: invoicingFactoringProduct
										}
										onChange={setInvoicingFactoringProduct}
										required={invoicingProvider && invoicingProvider !== 'none'}
										autoComplete="off"
										helpText={'Viilu30 tai Viilu50'}
									/>
								</div>
							</ExpansionPanel>
						</ExpansionList>
					</CardText>
				) : null}
				{showForm ? (
					<CardActions>
						<Button flat secondary onClick={onUpdate} disabled={!isSuperAdmin}>
							Tallenna
						</Button>
						<Button
							flat
							secondary
							onClick={onProducts}
							disabled={!isSuperAdmin}
						>
							Avaa tuotteet
						</Button>
						<Button
							flat
							secondary
							onClick={onPayments}
							disabled={!isSuperAdmin}
						>
							Avaa maksutapahtumat
						</Button>
						<DestructiveButton flat onClick={onDelete} disabled={!isSuperAdmin}>
							Poista käytöstä
						</DestructiveButton>
					</CardActions>
				) : integrationEnabled ? null : (
					<CardActions>
						<Button
							flat
							secondary
							onClick={() => setShowForm(true)}
							disabled={!isSuperAdmin}
						>
							Ota käyttöön
						</Button>
					</CardActions>
				)}
			</StandardCard>
		</div>
	)
}

MerchantSettings.propTypes = {}

const mapState = (
	{ firebaseReducer: { auth }, firestoreReducer: { ordered } },
	{ merchantUUID },
) => ({
	auth,
	merchant:
		ordered[`merchant_${merchantUUID}`] &&
		ordered[`merchant_${merchantUUID}`].length
			? ordered[`merchant_${merchantUUID}`][0]
			: null,
})

const mapDispatchToProps = (dispatch) => ({
	_showInfoNotification: bindActionCreators(notifyActions.info, dispatch),
	_update: bindActionCreators(merchantActions._update, dispatch),
	_delete: bindActionCreators(merchantActions._delete, dispatch),
	_push: bindActionCreators(push, dispatch),
})

export default compose(
	injectIntl,
	WithUserRoles,
	firestoreConnect(({ merchantUUID }) => [
		{
			collection: 'merchant',
			storeAs: `merchant_${merchantUUID}`,
			where: [['uuid', '==', merchantUUID || 'empty-value']],
			limit: 1,
		},
	]),
	connect(mapState, mapDispatchToProps),
)(MerchantSettings)
