import RouterLink from 'ui/components/RouterLink'
import styled from 'styled-components'
import Logo from './Logo'

const LogoWrapper = styled.div`
	display: flex;
	align-items: center;
	width: 80px;
	position: relative;
	z-index: 100;
`

function NavLogo({ fill, afterOnClick }) {
	return (
		<RouterLink href="/" afterOnClick={afterOnClick}>
			<LogoWrapper>
				<Logo fill={fill} />
			</LogoWrapper>
		</RouterLink>
	)
}

export default NavLogo
