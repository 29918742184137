import { useCallback, useEffect, useState } from 'react'
import type { ValueAddedTax } from './types'
import { _getValueAddedTax } from 'state/value-added-tax-actions'

function useValueAddedTax(uuid: string | undefined | null) {
	const [valueAddedTax, setVat] = useState<ValueAddedTax>()

	const [loading, setLoading] = useState<boolean>(false)
	const [error, setError] = useState<string | null>(null)

	const doQuery = useCallback(async () => {
		if (!uuid) return
		setLoading(true)
		setError(null)
		let data: ValueAddedTax | undefined

		try {
			const response = await _getValueAddedTax(uuid)

			if (response && response.ok) {
				const res = await response.json()
				data = res.vat
			} else {
				throw new Error('Response is not ok.')
			}
		} catch (err) {
			console.error('Error fetching vat:', err)
			setError('An error occurred while fetching vat')
		}
		setVat(data)
		setLoading(false)
	}, [uuid])

	useEffect(() => {
		doQuery()
	}, [doQuery])

	return {
		valueAddedTax,
		loading,
		error,
		refresh: doQuery,
	}
}

export default useValueAddedTax
