import { FormattedMessage, useIntl } from 'react-intl'
import DataTable, {
	TableBody,
	TableColumn,
	TableHeader,
	TableRow,
} from 'react-md/lib/DataTables'
import OverflowGradient from 'ui/components/OverflowGradient'
import { TableContainer } from 'ui/StyledComponents/Tables'
import type {
	LoanApartmentWithShareholderFields,
	LoanShareholder,
} from '../types'
import { Fragment, useMemo } from 'react'
import styled from 'styled-components'
import { formatCurrency } from 'util/formatStrings'
import { billingBasisMessage } from '../../utils/messages'
import useCompanyApartments from 'util/hooks/useCompanyApartments'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import { Button } from 'react-md'
import { TableRowContainer, TableRowsNotFound } from '../components'
import naturalSort from 'javascript-natural-sort'

const ShareGroupLink = styled.button`
	background: none;
	border: none;
	color: var(--color-primary);
	text-decoration: underline;
	cursor: pointer;
	padding: 0;
	font: inherit;
`

const LoanShareGroupTableContainer = styled.div`
	display: flex;
	flex-flow: column nowrap;
	gap: 1rem;
`

const ActionButtonContainer = styled.div`
	display: flex;
	flex-flow: row nowrap;
	justify-content: right;
	gap: 1rem;
`

type LoanShareholdersTableProps = {
	shareholders: LoanShareholder[]
}

function LoanShareholdersTable(props: LoanShareholdersTableProps) {
	const { shareholders } = props
	const intl = useIntl()
	const selectedFinanceCompanyUUID = useSelector(
		({ app }: any) => app?.selectedFinanceCompanyUUID,
	)
	const { companyApartments } = useCompanyApartments(selectedFinanceCompanyUUID)
	const dispatch = useDispatch()

	const loanSharegroups: LoanApartmentWithShareholderFields[] | undefined =
		useMemo(() => {
			if (companyApartments) {
				const sharegroups = shareholders.reduce<
					LoanApartmentWithShareholderFields[]
				>((acc, shareholder) => {
					const correspondingApartment = companyApartments.find(
						(apartment) => apartment.uuid === shareholder.shareGroupUUID,
					)
					if (correspondingApartment) {
						acc.push({
							...correspondingApartment,
							loanShareAmount: shareholder.loanShareAmount,
							debtShareAmount: shareholder.debtShareAmount,
							units: shareholder.units,
							billingBasis: shareholder.billingBasis,
						})
					}
					return acc
				}, [])
				const sortedSharegroups = sharegroups.sort(
					(
						a: LoanApartmentWithShareholderFields,
						b: LoanApartmentWithShareholderFields,
					) => naturalSort(a.identifier, b.identifier),
				)
				return sortedSharegroups
			}
			return undefined
		}, [shareholders, companyApartments])

	const onShareGroupClick = (shareGroupUUID: string) => {
		const searchParams = new URLSearchParams(window.location.search)

		searchParams.set('sguuid', shareGroupUUID)
		const newUrl = `${window.location.pathname}?${searchParams.toString()}`

		dispatch(push(newUrl))
	}

	const renderTableHeaders = () => {
		return (
			<TableRow>
				<TableColumn>
					<p className="text-strong text-subtle">
						{intl.formatMessage({
							defaultMessage: 'Osakeryhmä',
							description: 'Table header for identifier',
						})}
					</p>
				</TableColumn>
				<TableColumn>
					<p className="text-strong text-subtle">
						{intl.formatMessage({
							defaultMessage: 'Yksiköt',
							description: 'Table header for units',
						})}
					</p>
				</TableColumn>
				<TableColumn>
					<p className="text-strong text-subtle">
						{intl.formatMessage({
							defaultMessage: 'Jakoperuste',
							description: 'Table header for billing basis',
						})}
					</p>
				</TableColumn>
				<TableColumn>
					<p className="text-strong text-subtle">
						{intl.formatMessage({
							defaultMessage: 'Lainaosuus',
							description: 'Table header for loan share amount',
						})}
					</p>
				</TableColumn>
				<TableColumn>
					<p className="text-strong text-subtle">
						{intl.formatMessage({
							defaultMessage: 'Velkaosuus',
							description: 'Table header for loan debt left amount',
						})}
					</p>
				</TableColumn>
			</TableRow>
		)
	}

	const renderTableRows = () => {
		if (!loanSharegroups) return <></>
		return loanSharegroups
			.map((sharegroup, index) => {
				return (
					<Fragment key={sharegroup.uuid}>
						<TableRowContainer $filledBackground={index % 2 === 0}>
							<TableColumn>
								{sharegroup?.identifier ? (
									<ShareGroupLink
										onClick={() => onShareGroupClick(sharegroup.uuid)}
									>
										{sharegroup.identifier}
									</ShareGroupLink>
								) : (
									<p>{sharegroup.identifier}</p>
								)}
							</TableColumn>
							<TableColumn>{sharegroup.units}</TableColumn>
							<TableColumn>
								{billingBasisMessage(intl, sharegroup.billingBasis)}
							</TableColumn>
							<TableColumn>
								{formatCurrency(sharegroup.loanShareAmount)}
							</TableColumn>
							<TableColumn>
								{formatCurrency(sharegroup.debtShareAmount)}
							</TableColumn>
						</TableRowContainer>
					</Fragment>
				)
			})
			.concat(
				<tr
					key="empty-row"
					className="full-width"
					style={{ height: 100, background: 'white' }}
				></tr>,
			)
	}

	return (
		<>
			{shareholders?.length > 0 ? (
				<LoanShareGroupTableContainer>
					<ActionButtonContainer>
						<Button raised secondary>
							<FormattedMessage
								defaultMessage="Muodosta ilmoitukset"
								description="Button label for forming notifications"
							/>
						</Button>
						<Button raised secondary>
							<FormattedMessage
								defaultMessage="Muodosta laskut"
								description="Button label for forming bills"
							/>
						</Button>
					</ActionButtonContainer>
					<TableContainer>
						<DataTable
							baseId="loan-shareholders-table"
							selectableRows
							className="md-paper--1"
							style={{
								background: 'white',
								maxHeight: 'calc(100vh - 205px)',
							}}
						>
							<TableHeader
								style={{
									position: 'sticky',
									top: 0,
									zIndex: 1,
									background: 'white',
									borderBottom: 'rgba(0, 0, 0, 0.12) solid 2px',
								}}
							>
								{renderTableHeaders()}
							</TableHeader>
							<TableBody>{renderTableRows()}</TableBody>
							<OverflowGradient style={{ bottom: 40 }} />
						</DataTable>
					</TableContainer>
				</LoanShareGroupTableContainer>
			) : (
				<TableRowsNotFound>
					{intl.formatMessage({
						defaultMessage: 'Osakeryhmiä ei löytynyt',
						description: 'Error text when no loan sharegroups were found',
					})}
				</TableRowsNotFound>
			)}
		</>
	)
}

export default LoanShareholdersTable
