import { useCallback, useEffect, useState } from 'react'
import { _getParty } from 'state/finance-party-actions'
import type { Party } from './types'

function useParty(uuid: string | undefined) {
	const [party, setParty] = useState<Party>()

	const [loadingParty, setLoading] = useState<boolean>(false)
	const [errorParty, setError] = useState<string | null>(null)

	const doQuery = useCallback(async () => {
		if (!uuid) return
		setLoading(true)
		setError(null)
		let data: Party | undefined

		try {
			const response = await _getParty(uuid)

			if (response && response.ok) {
				const res = await response.json()
				data = res.party
			} else {
				throw new Error('Response is not ok.')
			}
		} catch (err) {
			console.error('Error fetching finance party:', err)
			setError('An error occurred while fetching finance party: ' + uuid)
		}
		setParty(data)
		setLoading(false)
	}, [uuid])

	useEffect(() => {
		doQuery()
	}, [doQuery])

	return { party, loadingParty, errorParty, refreshParty: doQuery }
}

export default useParty
