import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { isLoaded } from 'react-redux-firebase'
import LoadingState from './LoadingState'

export default (storeAs, hideLoading, uuidProp) => (ComponentToWrap) => {
	class WithLoadingUntilFirestoreLoaded extends Component {
		render() {
			const {
				firestoreReducer: { ordered },
			} = this.props
			if (
				this.props.firestoreReducer.errors &&
				this.props.firestoreReducer.errors.allIds &&
				this.props.firestoreReducer.errors.allIds.length > 0
			) {
				console.error(this.props.firestoreReducer)
			}
			if (!ordered) {
				return hideLoading ? null : <LoadingState />
			}

			if (uuidProp) {
				if (uuidProp.includes('.')) {
					const elements = uuidProp.split('.')
					if (elements.length === 2) {
						if (
							!elements ||
							!elements[0] ||
							!elements[1] ||
							!this.props[elements[0]] ||
							!this.props[elements[0]][elements[1]] ||
							!isLoaded(ordered[storeAs + this.props[elements[0]][elements[1]]])
						) {
							return hideLoading ? null : <LoadingState />
						}
					} else if (elements.length === 3) {
						if (
							!elements ||
							!elements[0] ||
							!elements[1] ||
							!elements[2] ||
							!this.props[elements[0]] ||
							!this.props[elements[0]][elements[1]] ||
							!this.props[elements[0]][elements[1]][elements[2]] ||
							!isLoaded(
								ordered[
									storeAs + this.props[elements[0]][elements[1]][elements[2]]
								],
							)
						) {
							return hideLoading ? null : <LoadingState />
						}
					} else {
						// Shouldn't happen?
						if (!isLoaded(ordered[storeAs + this.props[uuidProp]])) {
							return hideLoading ? null : <LoadingState />
						}
					}
				} else {
					if (!isLoaded(ordered[storeAs + this.props[uuidProp]])) {
						return hideLoading ? null : <LoadingState />
					}
				}
			} else {
				if (!isLoaded(ordered[storeAs])) {
					return hideLoading ? null : <LoadingState />
				}
			}

			return <ComponentToWrap {...this.props} />
		}
	}

	const mapState = ({ firestoreReducer }) => ({
		firestoreReducer,
	})

	const mapDispatchToProps = (dispatch) => ({})

	return compose(connect(mapState, mapDispatchToProps))(
		WithLoadingUntilFirestoreLoaded,
	)
}
