export const PostalClass = {
	ECONOMY: 'E',
	PRIORITY: 'P',
}

export const Color = {
	BLACK: 'B',
	COLOR: 'C',
}

export const Plex = {
	SIMPLEX: 'S',
	DUPLEX: 'D',
}

export const Binding = {
	BOOKLET: 'B',
	NO_BINDING: 'N',
}

export const plexOptions = (intl) => [
	{
		label: intl.formatMessage({
			defaultMessage: 'Kaksipuolinen tulostus',
			description:
				'Label for the duplex postal printing option in the general sender component.',
		}),
		value: Plex.DUPLEX,
	},
	{
		label: intl.formatMessage({
			defaultMessage: 'Yksipuolinen tulostus',
			description:
				'Label for the simplex postal printing option in the general sender component.',
		}),
		value: Plex.SIMPLEX,
	},
]

export const bindingOptions = (intl) => [
	{
		label: intl.formatMessage({
			defaultMessage: 'Ei vihkosidontaa',
			description: 'Label for the "no binding" postal printing option.',
		}),
		value: Binding.NO_BINDING,
	},
	{
		label: intl.formatMessage({
			defaultMessage: 'Vihkosidonta',
			description: 'Label for the "booklet" postal printing option.',
		}),
		value: Binding.BOOKLET,
	},
]

export const colorOptions = (intl) => [
	{
		label: intl.formatMessage({
			defaultMessage: 'Mustavalkoinen tulostus',
			description:
				'Label for the black and white postal printing option in the general sender component.',
		}),
		value: Color.BLACK,
	},
	{
		label: intl.formatMessage({
			defaultMessage: 'Värillinen tulostus (premium)',
			description:
				'Label for the colored postal printing option in the general sender component.',
		}),
		value: Color.COLOR,
	},
]

export const postalClassOptions = (intl) => [
	{
		label: intl.formatMessage({
			defaultMessage: 'Economy (suositeltu*)',
			description:
				'Label for the economy postal class option in the general sender component.',
		}),
		value: PostalClass.ECONOMY,
	},
	{
		label: intl.formatMessage({
			defaultMessage: 'Priority',
			description:
				'Label for the priority postal class option in the general sender component.',
		}),
		value: PostalClass.PRIORITY,
	},
]
