export const consolidatedInvoiceHeaders = [
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Maksaja',
				description:
					'Table column header for finance consolidated invoice payer.',
			}),
		key: 'partyName',
		show: true,
		type: 'text',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Viitenumero',
				description:
					'Table column header for finance consolidated invoice reference number.',
			}),
		key: 'referenceNumber',
		show: true,
		type: 'text',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Summa',
				description:
					'Table column header for finance consolidated invoice total.',
			}),
		key: 'total',
		show: true,
		type: 'reduce',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Summa + alv',
				description:
					'Table column header for finance consolidated invoice total with vat.',
			}),
		key: 'totalWithVat',
		show: true,
		type: 'reduce',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Suoritukset',
				description:
					'Table column header for finance consolidated invoice date.',
			}),
		key: 'paymentsTotal',
		show: true,
		type: 'reduce',
	},
]

export const consolidatedInvoiceDetailsFields = [
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Sopimus',
				description: 'Table column label for invoice contract name.',
			}),
		key: 'contractUUID',
		type: 'text',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Kohde',
				description: 'Table column label for invoice company name.',
			}),
		key: 'companyUUID',
		type: 'text',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Huoneisto',
				description: 'Table column label for invoice apartment id.',
			}),
		key: 'apartmentIdentifier',
		type: 'apartment',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Maksaja',
				description: 'Table column label for invoice payer name.',
			}),
		key: 'partyName',
		type: 'text',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Toinen maksaja',
				description: 'Table column label for invoice second payer name.',
			}),
		key: 'secondPartyName',
		type: 'text',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Viitenumero',
				description:
					'Table column header for finance consolidated invoice reference number.',
			}),
		key: 'referenceNumber',
		type: 'text',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Eräpäivät',
				description: 'Table column label for invoice due dates.',
			}),
		key: 'dueDate',
		type: 'dueDates',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Autokohdistuksen esto',
				description: 'Table column label for invoice automatic matching block.',
			}),
		key: 'automaticMatchingBlock',
		type: 'boolean',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Laskutustapa',
				description: 'Table column label for invoice method.',
			}),
		key: 'invoicingMethod',
		type: 'custom',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Summa',
				description: 'Table column label for invoice total.',
			}),
		key: 'total',
		type: 'currency',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Summa + alv',
				description: 'Table column label for invoice total with vat.',
			}),
		key: 'totalWithVat',
		type: 'currency',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Suoritukset',
				description: 'Table column label for invoice payments total.',
			}),
		key: 'paymentsTotal',
		type: 'currency',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Maksamatta',
				description: 'Table column label for invoices unpaid amount.',
			}),
		key: 'unpaid',
		type: 'unpaid',
	},
]
