import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase'
import WithSelectedCompany from './WithSelectedCompany'

export default (ComponentToWrap) => {
	class WithSelectedCompanyManager extends Component {
		render() {
			const { selectedCompany, manager } = this.props
			if (selectedCompany && !isLoaded(manager)) {
				return (
					<ComponentToWrap {...this.props} selectedCompanyManager={undefined} />
				)
			} else if (selectedCompany === null || isEmpty(manager)) {
				return <ComponentToWrap {...this.props} selectedCompanyManager={null} />
			}
			return (
				<ComponentToWrap {...this.props} selectedCompanyManager={manager[0]} />
			)
		}
	}

	const mapState = ({
		firestoreReducer: {
			ordered: { manager },
		},
	}) => ({
		manager,
	})

	const mapDispatchToProps = (dispatch) => ({})

	return compose(
		WithSelectedCompany,
		firestoreConnect(({ selectedCompany }) => [
			{
				collection: 'manager',
				where: [
					[
						'uuid',
						'==',
						selectedCompany ? selectedCompany.managerUUID || null : null,
					],
				],
				limit: 1,
			},
		]),
		connect(mapState, mapDispatchToProps),
	)(WithSelectedCompanyManager)
}
