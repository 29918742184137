import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { Button, TextField, FontIcon } from 'react-md'
import { FormattedMessage, injectIntl } from 'react-intl'
import styled from 'styled-components'

import * as userActions from 'state/user-actions'

import {
	MALFORMED_EMAIL_MESSAGE,
	WEAK_PASSWORD_MESSAGE,
	GENERIC_ERROR,
	EMAIL_ALREADY_IN_USE,
	OPERATION_NOT_ALLOWED,
} from './messages'
import LoadingState from '../../LoadingState'
import { firebaseApp } from 'state/store'
import { createUserWithEmailAndPassword, getAuth } from 'firebase/auth'
import useDialogs from 'ui/components/dialogs/useDialogs'
import FB_ERRORS from 'util/firebaseErrors'

const CenteredText = styled.p`
	text-align: center;
`
const WarningIcon = styled(FontIcon)`
	font-size: ${({ $isDesktop }) =>
		$isDesktop ? '128px !important' : '96px !important'};
	color: var(--color-warning);
	margin-bottom: 16px;
	opacity: 0.87;
	&:after {
		content: 'warning';
	}
`
const PasswordIcon = styled(FontIcon)`
	font-size: ${({ $isDesktop }) =>
		$isDesktop ? '128px !important' : '96px !important'};
	margin-bottom: 16px;
	opacity: 0.87;
	&:after {
		content: 'lock';
	}
`

const PASSWORD_FIELD_ID = 'email-login-dialog-textfield-pass'

const LoginDialogEmailSignUp = ({
	email,
	onHide,
	intl,
	isDesktop,
	_sendVerificationEmail,
	_push,
}) => {
	const [password, setPassword] = useState('')
	const [errorText, setErrorText] = useState('')
	const [processing, setProcessing] = useState(false)
	const { alert } = useDialogs()

	useEffect(() => {
		let element = window.document.getElementById(PASSWORD_FIELD_ID)
		if (element) {
			element.focus()
		} else {
			window.setTimeout(() => {
				element = window.document.getElementById(PASSWORD_FIELD_ID)
				if (element) {
					element.focus()
				}
			}, 500)
		}
	}, [])

	const handleSuccess = async (authResult) => {
		try {
			const user = authResult.user
			if (user?.providerData?.find((p) => p.providerId === 'password')) {
				if (user.email && !user.emailVerified) {
					const redirectURL = window.location.href
					const result = await _sendVerificationEmail(redirectURL)

					if (!result || result.success === false) {
						if (result?.ex?.code === FB_ERRORS.FUNCTIONS_FAILED_PRECONDITION) {
							const message = intl.formatMessage({
								defaultMessage:
									'Tapahtui virhe. Käyttämäsi sähköposti saattaa olla sulkulistalla. Otathan meihin yhteyttä asiakaspalvelu@vii.lu niin hoidetaan homma kuntoon.',
								description:
									'Error message telling the user that their email address might be blocked.',
							})
							await alert(message)
						} else if (
							result?.ex?.code === FB_ERRORS.FUNCTIONS_RESOURCE_EXHAUSTED
						) {
							const message = intl.formatMessage({
								defaultMessage:
									'Olet lähettänyt useita vahvistusviestejä pienessä ajassa. Odota hetki ja yritä uudelleen.',
								description:
									'Error message telling the user that they have attempted to send too many verification emails in a short timespan.',
							})
							await alert(message)
						} else {
							const message = intl.formatMessage({
								defaultMessage:
									'Vahvistussähköpostin lähettämisessä tapahtui virhe.',
								description:
									'Error message telling the user that there was an error while sending the verification email.',
							})
							await alert(message)
						}
						return false
					}
				}
			}
			if (window.URLBeforeLogin) {
				const redirectURL = window.URLBeforeLogin
				window.setTimeout(() => _push(redirectURL), 1000)
			}
			onHide()
		} catch (ex) {
			if (window.sentry) window.sentry.captureException(ex)
			console.error(ex)
		}
	}

	const onSubmit = async () => {
		if (!firebaseApp) {
			return false
		}
		setProcessing(true)
		try {
			const userCredential = await createUserWithEmailAndPassword(
				getAuth(firebaseApp),
				email,
				password,
			)
			if (!userCredential || !userCredential.user) {
				setErrorText(GENERIC_ERROR(intl))
				return false
			}
			handleSuccess(userCredential)
			return true
		} catch (ex) {
			if (ex.code === 'auth/invalid-email') {
				setErrorText(MALFORMED_EMAIL_MESSAGE(intl))
				return true
			} else if (ex.code === 'auth/email-already-in-use') {
				setErrorText(EMAIL_ALREADY_IN_USE(intl))
				return true
			} else if (ex.code === 'auth/operation-not-allowed') {
				setErrorText(OPERATION_NOT_ALLOWED(intl))
				return true
			} else if (ex.code === 'auth/weak-password') {
				setErrorText(WEAK_PASSWORD_MESSAGE(intl))
				return true
			}
			await alert(GENERIC_ERROR(intl))
			return false
		} finally {
			setProcessing(false)
		}
	}

	const validate = () => {
		if (!password) {
			return false
		}
		return true
	}

	const onKeyPress = (event) => {
		if (processing) {
			return false
		}
		if (event.charCode === 13) {
			// enter key pressed
			event.preventDefault()
			if (validate()) {
				onSubmit()
			}
		}
	}

	const getPasswordMessage = () => {
		return intl.formatMessage({
			defaultMessage: 'Salasana',
			description: 'Label of the password address field',
		})
	}

	const reset = () => {
		setPassword('')
		setErrorText('')
	}

	if (processing) {
		return (
			<div className="flex-column flex-center">
				<LoadingState />
			</div>
		)
	}

	if (errorText) {
		return (
			<div className="flex-column flex-center">
				<WarningIcon $isDesktop={isDesktop} />
				<CenteredText>{errorText}</CenteredText>
				<Button
					raised
					secondary
					onClick={reset}
					className="margin-top--xl"
					style={{
						alignSelf: 'center',
						background: 'var(--color-primary)',
						color: 'white',
					}}
				>
					<FormattedMessage
						defaultMessage="Yritä uudelleen"
						description="Button that allows the user to retry the sign up process."
					/>
				</Button>
			</div>
		)
	}

	return (
		<div className="flex-column flex-center">
			<PasswordIcon $isDesktop={isDesktop} />
			<CenteredText>
				<FormattedMessage
					defaultMessage="Olet luomassa uutta tunnusta. Keksi kirjautumista varten uusi vahva salasana."
					description="Instructional label that tells the user that they need to come up with a password."
				/>
			</CenteredText>
			<TextField
				id={PASSWORD_FIELD_ID}
				label={getPasswordMessage()}
				lineDirection="center"
				fullWidth
				onChange={setPassword}
				autoComplete="off"
				onKeyPress={onKeyPress}
				type="password"
			/>
			<Button
				raised={validate()}
				flat={!validate()}
				secondary
				onClick={onSubmit}
				className="margin-top--xl"
				disabled={processing}
				style={
					validate()
						? {
								alignSelf: 'center',
								background: 'var(--color-primary)',
								color: 'white',
						  }
						: { alignSelf: 'center' }
				}
			>
				<FormattedMessage
					defaultMessage="Luo tunnus"
					description="Button that completes the sign up process and creates the new user account."
				/>
			</Button>
		</div>
	)
}

LoginDialogEmailSignUp.propTypes = {
	email: PropTypes.string.isRequired,
	onHide: PropTypes.func.isRequired,
}

const mapState = ({ dimensions: { isDesktop } }) => ({ isDesktop })

const mapDispatchToProps = (dispatch) => ({
	_push: bindActionCreators(push, dispatch),
	_sendVerificationEmail: bindActionCreators(
		userActions._sendVerificationEmail,
		dispatch,
	),
})

export default compose(
	injectIntl,
	connect(mapState, mapDispatchToProps),
)(LoginDialogEmailSignUp)
