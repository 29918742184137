import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { Button, Cell, SelectionControl } from 'react-md'
import { FormattedMessage, injectIntl } from 'react-intl'

import * as notifyActions from 'state/notifyActions'

import illustrationDocuments from 'svg/illustrations/illustration_documents.svg'
import { ONBOARDING_NEXT_MESSAGE } from '../messages'

export class ShareholderOnboardingStepConsent extends Component {
	render = () => {
		const { digitalDeliveryConsent, onNext, intl } = this.props

		const acceptMessage = intl.formatMessage({
			defaultMessage:
				'Annan suostumukseni sähköiseen huoneistokohtaiseen osakaspostitukseen.',
			description:
				'Label of the radiobutton that signifies that the user does give a consent for the electronical only delivery of shareholder notices.',
		})
		const denyMessage = intl.formatMessage({
			defaultMessage:
				'En anna suostumustani sähköiseen huoneistokohtaiseen osakaspostitukseen, vaan haluan saada osakaspostituksen postitse.',
			description:
				'Label of the radiobutton that signifies that the user DOES NOT give a consent for the electronical only delivery of shareholder notices.',
		})

		return (
			<div className="flex-column flex-center">
				<img
					alt=""
					src={illustrationDocuments}
					style={{
						width: 160,
						height: 'auto',
						position: 'relative',
						margin: 16,
					}}
				/>
				<h4 className="text-center">
					<FormattedMessage
						defaultMessage="Suostumus sähköiseen huoneistokohtaiseen osakaspostitukseen"
						description="Header of the electronic shareholder messaging consent dialog."
					/>
				</h4>
				<p className="text-center margin-bottom">
					<FormattedMessage
						defaultMessage="Tämän suostumuksen annettuasi mm. osakastiedotteet, yhtiökokouskutsut ja vastikemaksut toimitetaan vain sähköisesti."
						description="Informational paragraph telling what giving the consent means."
					/>
				</p>
				<Cell
					size={12}
					style={{ display: 'flex', alignItems: 'flex-end', marginBottom: 16 }}
				>
					<SelectionControl
						id="radio-select-digital"
						label={acceptMessage}
						name={acceptMessage}
						type="radio"
						checked={digitalDeliveryConsent}
						onChange={() => {}}
						onClick={() => this.props.onUpdate(true)}
						controlClassName="selection-control-consent"
					/>
				</Cell>
				<Cell
					size={12}
					style={{ display: 'flex', alignItems: 'flex-end', marginBottom: 16 }}
				>
					<SelectionControl
						id="radio-select-paper"
						label={denyMessage}
						name={denyMessage}
						type="radio"
						checked={!digitalDeliveryConsent}
						onChange={() => {}}
						onClick={() => this.props.onUpdate(false)}
						controlClassName="selection-control-consent"
					/>
				</Cell>
				<Button
					raised
					secondary
					onClick={onNext}
					className="margin-top"
					style={{
						alignSelf: 'flex-end',
						background: 'var(--color-secondary)',
					}}
				>
					{ONBOARDING_NEXT_MESSAGE(intl)}
				</Button>
			</div>
		)
	}
}

ShareholderOnboardingStepConsent.propTypes = {
	onUpdate: PropTypes.func.isRequired,
	onNext: PropTypes.func.isRequired,
}

const mapState = ({ ui: { onboarding: onboardingReducer } }) => ({
	onboardingReducer,
})

const mapDispatchToProps = (dispatch) => ({
	_showInfoNotification: bindActionCreators(notifyActions.info, dispatch),
})

export default compose(
	injectIntl,
	connect(mapState, mapDispatchToProps),
)(ShareholderOnboardingStepConsent)
