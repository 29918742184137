import { useEffect, useState } from 'react'
import {
	DataTable,
	TableHeader,
	TableRow,
	TableColumn,
	TableBody,
	ListItem,
	FontIcon,
} from 'react-md'
import EmptyState from 'ui/components/EmptyState'
import documentsEmptyIcon from 'svg/documents-empty.svg'
import OverflowGradient from 'ui/components/OverflowGradient'
import { TableContainer } from 'ui/StyledComponents/Tables'
import PartyRow from './PartyRow'
import FloatingActionBtn from 'ui/components/FloatingActionBtn'
import { partyHeaders } from './constants'
import DropdownField from 'ui/components/DropdownField'
import { GENERIC_ERROR_NOTIFICATION } from 'ui/messages'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import * as notifyActions from 'state/notifyActions'
import type { PartyFilters, Party, PartyKey } from './types'
import type { SelectOptions, ApartmentSelectOptions } from '../types'
import useDialogs from 'ui/components/dialogs/useDialogs'
import { _deleteParty } from 'state/finance-party-actions'
import LoadingState from 'ui/components/LoadingState'
import DatePickerISO from 'ui/components/DatePickerISO'
import { YES_NO_MESSAGE } from 'ui/messages'
import PartyDialog from './PartyDialog'
import naturalSort from 'javascript-natural-sort'
import { getPartyRowValue } from './utils'

interface Props {
	parties: Party[]
	refreshParties: () => void
	loadingParties: boolean
	filters: PartyFilters
	setFilters: React.Dispatch<React.SetStateAction<PartyFilters>>
	selectedFinanceCompanyUUID: string
	apartmentOptions: ApartmentSelectOptions[]
	invoicingOptions: SelectOptions[]
	bondNameOptions: SelectOptions[]
	companyOptions: SelectOptions[]
	refreshBonds: () => void
}

const PartiesView = ({
	parties,
	refreshParties,
	loadingParties,
	filters,
	setFilters,
	selectedFinanceCompanyUUID,
	apartmentOptions,
	invoicingOptions,
	bondNameOptions,
	companyOptions,
	refreshBonds,
}: Props) => {
	const intl = useIntl()
	const dispatch = useDispatch()
	const { confirm } = useDialogs()
	const [addPartyDialogVisible, setAddPartyDialogVisible] =
		useState<boolean>(false)
	const [selectedParty, setSelectedParty] = useState<Party | null>(null)
	const [processing, setProcessing] = useState<boolean>(false)

	const [sort, setSort] = useState<{
		key: PartyKey
		type: string | null
		order: string
	}>({
		key: 'apartmentUUID',
		type: 'apartmentOptions',
		order: 'asc',
	})

	const changeSort = (key: PartyKey, type?: string) => {
		if (sort.key === key) {
			setSort((prevData) => ({
				...prevData,
				order: sort.order === 'asc' ? 'desc' : 'asc',
			}))
		} else {
			setSort({
				key: key,
				type: type || null,
				order: 'asc',
			})
		}
	}

	const yesNoOptions = [
		{ label: YES_NO_MESSAGE(intl, true), value: true },
		{ label: YES_NO_MESSAGE(intl, false), value: false },
	]

	useEffect(() => {
		if (selectedParty) {
			const party = parties.find((party) => party.uuid === selectedParty.uuid)
			if (party) {
				setSelectedParty(party)
			} else {
				setSelectedParty(null)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [parties])

	const deleteParty = async (uuid: string) => {
		const confirmMessage = intl.formatMessage({
			defaultMessage: 'Oletko varma, että haluat poistaa osapuolen?',
			description: 'Confirm message for the delete finance party.',
		})
		if (!(await confirm(confirmMessage))) {
			return
		}

		setProcessing(true)

		const result = await _deleteParty({ uuid: uuid })
		setProcessing(false)

		if (!result || result.ok === false) {
			dispatch(notifyActions.info('❌ ' + GENERIC_ERROR_NOTIFICATION(intl)))
			return false
		}

		dispatch(
			notifyActions.info(
				'✅ ' +
					intl.formatMessage({
						defaultMessage: 'Osapuoli on poistettu.',
						description: 'Notification text of party has been deleted',
					}),
			),
		)
		refreshParties()
		return true
	}

	const renderEmpty = () => {
		return (
			<div className="flex-center flex-one full-height">
				<EmptyState
					text={intl.formatMessage({
						defaultMessage: 'Osapuolilista näyttäisi olevan tyhjä.',
						description: 'Party list is empty text.',
					})}
					icon={documentsEmptyIcon}
				/>
			</div>
		)
	}

	if (!parties) {
		renderEmpty()
	}

	if (loadingParties) {
		return <LoadingState />
	}

	const showAddPartyDialog = () => {
		setAddPartyDialogVisible(true)
	}

	const hideAddPartyDialog = () => {
		setAddPartyDialogVisible(false)
	}

	const renderFAB = () => {
		return (
			<FloatingActionBtn
				iconName={'add'}
				text={intl.formatMessage({
					defaultMessage: 'LISÄÄ OSAPUOLI',
					description: 'Add new party',
				})}
				action={showAddPartyDialog}
			/>
		)
	}

	const renderFilterSelectField = (filterProps) => {
		if (filterProps.type === 'date') {
			return (
				<DatePickerISO
					key={filterProps.label}
					style={{ marginLeft: '20px' }}
					id={filterProps.label}
					label={filterProps.label}
					value={filterProps.value}
					onChange={filterProps._onOptionSelect}
				/>
			)
		}
		if (filterProps.type === 'dropdown' && Array.isArray(filterProps.options)) {
			return (
				<div style={{ width: 180, marginRight: 5 }} key={filterProps.label}>
					<DropdownField {...filterProps} />
				</div>
			)
		}
	}

	const renderActionBar = () => {
		const filterFields = [
			{
				label: intl.formatMessage({
					defaultMessage: 'Huoneisto',
					description: 'Label of apartment',
				}),
				value: filters.apartmentUUID,
				options: apartmentOptions,
				_onOptionSelect: (value) =>
					setFilters((prevData) => ({
						...prevData,
						apartmentUUID: value,
					})),
				hasSelectAll: true,
				nullSelectAll: true,
				itemLabel: 'label',
				itemValue: 'value',
				type: 'dropdown',
			},
			{
				label: intl.formatMessage({
					defaultMessage: 'Rooli',
					description: 'Label of bond',
				}),
				value: filters.bondName,
				options: bondNameOptions,
				_onOptionSelect: (value) =>
					setFilters((prevData) => ({
						...prevData,
						bondName: value,
					})),
				hasSelectAll: true,
				nullSelectAll: true,
				itemLabel: 'label',
				itemValue: 'value',
				type: 'dropdown',
			},
			{
				label: intl.formatMessage({
					defaultMessage: 'Oletus laskutustapa',
					description: 'Label of default billing method',
				}),
				value: filters.invoicingMethod,
				options: invoicingOptions,
				_onOptionSelect: (value) =>
					setFilters((prevData) => ({
						...prevData,
						invoicingMethod: value,
					})),
				hasSelectAll: true,
				nullSelectAll: true,
				itemLabel: 'label',
				itemValue: 'value',
				type: 'dropdown',
			},
			{
				label: intl.formatMessage({
					defaultMessage: 'Aktiivinen',
					description: 'Label of active',
				}),
				value: filters.active,
				options: yesNoOptions,
				_onOptionSelect: (value) =>
					setFilters((prevData) => ({
						...prevData,
						active: value,
					})),
				hasSelectAll: true,
				nullSelectAll: true,
				itemLabel: 'label',
				itemValue: 'value',
				type: 'dropdown',
			},
			{
				label: intl.formatMessage({
					defaultMessage: 'Tarkastelupäivämäärä',
					description: 'Label of review date',
				}),
				_onOptionSelect: (value) => {
					if (!value) {
						setFilters((prevData) => ({
							...prevData,
							reviewDate: null,
						}))
					} else {
						setFilters((prevData) => ({
							...prevData,
							reviewDate: value,
						}))
					}
				},
				value: filters.reviewDate,
				nullSelectAll: true,
				itemLabel: 'label',
				itemValue: 'value',
				type: 'date',
			},
		]

		return (
			<div
				className="flex-row margin-bottom--sm margin-right"
				style={{
					justifyContent: 'space-between',
					alignItems: 'flex-end',
				}}
			>
				<div
					className="flex-row full-width"
					style={{ alignItems: 'center', marginLeft: 16, flexWrap: 'wrap' }}
				>
					{filterFields.map(renderFilterSelectField)}
				</div>
			</div>
		)
	}

	const renderTableHeaders = () => {
		return (
			<TableRow>
				{partyHeaders
					.filter((h) => h.show)
					.map((header) => (
						<TableColumn
							key={header.key}
							onClick={() =>
								header.sortable && changeSort(header.key, header.type)
							}
						>
							<div
								style={{ display: 'flex', alignItems: 'center', gap: '4px' }}
							>
								<p className="text-strong text-subtle">{header.title(intl)}</p>
								{sort.key === header.key && (
									<FontIcon style={{ fontSize: 12 }}>
										{sort.order === 'asc' ? 'arrow_upward' : 'arrow_downward'}
									</FontIcon>
								)}
							</div>
						</TableColumn>
					))}
				<TableColumn key={'children-header'}></TableColumn>
			</TableRow>
		)
	}

	const renderTable = () => {
		if (!parties || !parties.length) {
			return (
				<p style={{ marginLeft: '1.5rem' }}>
					{intl.formatMessage({
						defaultMessage: 'Osapuolia ei löytynyt.',
						description: 'Message for empty finance parties list.',
					})}
				</p>
			)
		}
		return (
			<TableContainer>
				<TableContainer>
					<DataTable
						plain
						style={{
							background: 'white',
							maxHeight: 'calc(100vh - 205px)',
						}}
					>
						<TableHeader
							style={{
								position: 'sticky',
								top: 0,
								zIndex: 1,
								background: 'white',
							}}
						>
							{renderTableHeaders()}
						</TableHeader>
						<TableBody>
							{parties
								.sort((a, b) => {
									const aValue =
										sort.type && sort.type !== 'boolean'
											? getPartyRowValue(
													a,
													sort.key,
													apartmentOptions,
													intl,
													sort.type,
											  )
											: a[sort.key]

									const bValue =
										sort.type && sort.type !== 'boolean'
											? getPartyRowValue(
													b,
													sort.key,
													apartmentOptions,
													intl,
													sort.type,
											  )
											: b[sort.key]

									return sort.order === 'asc'
										? naturalSort(aValue, bValue)
										: naturalSort(bValue, aValue)
								})
								.map((party) => (
									<PartyRow
										key={party.uuid}
										party={party}
										menuItems={[
											<ListItem
												key={1}
												primaryText={intl.formatMessage({
													defaultMessage: 'Poista osapuoli',
													description: 'Delete party',
												})}
												leftIcon={<FontIcon>delete</FontIcon>}
												onClick={() => deleteParty(party.uuid)}
												className="md-divider-border md-divider-border--right"
											/>,
										]}
										children={null}
										processing={processing}
										apartmentOptions={apartmentOptions}
									/>
								))
								.concat(
									<tr
										key="empty-row"
										className="full-width"
										style={{ height: 100, background: 'white' }}
									></tr>,
								)}
						</TableBody>
						<OverflowGradient style={{ bottom: 40 }} />
					</DataTable>
				</TableContainer>
			</TableContainer>
		)
	}

	return (
		<div>
			{renderActionBar()}
			{renderTable()}
			<PartyDialog
				visible={addPartyDialogVisible}
				onHide={hideAddPartyDialog}
				refreshParties={refreshParties}
				selectedFinanceCompanyUUID={selectedFinanceCompanyUUID}
				apartmentOptions={apartmentOptions}
				invoicingOptions={invoicingOptions}
				companyOptions={companyOptions}
				bondNameOptions={bondNameOptions}
				refreshBonds={refreshBonds}
			/>

			{renderFAB()}
		</div>
	)
}

export default PartiesView
