import { useIntl } from 'react-intl'
import DataTable, {
	TableBody,
	TableColumn,
	TableHeader,
	TableRow,
} from 'react-md/lib/DataTables'
import OverflowGradient from 'ui/components/OverflowGradient'
import { TableContainer } from 'ui/StyledComponents/Tables'
import type { Loan, LoanFilters } from './types'
import { loanTableHeaders } from './loanHeaders'
import LoansTableRow from './LoansTableRow'
import Pagination from 'ui/components/Pagination'
import FontIcon from 'react-md/lib/FontIcons'
import { TableHeaderNameSortable } from './components'

type LoansTableProps = {
	loans: Loan[]
	totalItemCount: number
	itemsPerPage: number
	filters: LoanFilters
	setFilters: React.Dispatch<React.SetStateAction<LoanFilters>>
	onDraftLoanClick: (loan: Loan) => void
}

function LoansTable(props: LoansTableProps) {
	const {
		loans,
		totalItemCount,
		itemsPerPage,
		filters,
		setFilters,
		onDraftLoanClick,
	} = props
	const intl = useIntl()

	const handlePaginationBack = () => {
		const paginationPage = filters.paginationPage
		if (paginationPage === 0) {
			return
		}
		const pageIndex = paginationPage - 1
		setFilters((prevData) => ({
			...prevData,
			paginationPage: pageIndex,
		}))
	}
	const handlePaginationNext = () => {
		const lastPageIndex = Math.ceil(totalItemCount / itemsPerPage)
		const paginationPage = filters.paginationPage
		if (paginationPage === lastPageIndex) {
			return
		}
		const pageIndex = paginationPage + 1
		setFilters((prevData) => ({
			...prevData,
			paginationPage: pageIndex,
		}))
	}

	const changeSort = (key: keyof Loan, type?: string) => {
		setFilters((prevData) => {
			const existingSort = prevData.sortBy

			return {
				...prevData,
				sortBy:
					existingSort?.column === key
						? {
								column: key,
								order: existingSort.order === 'asc' ? 'desc' : 'asc',
						  }
						: { column: key, order: 'asc' },
			}
		})
	}

	const renderTableHeaders = () => {
		return (
			<TableRow>
				{loanTableHeaders.map((header) => {
					return (
						<TableColumn
							key={header.key}
							onClick={() =>
								header.sortable &&
								changeSort(header.key as keyof Loan, undefined)
							}
							style={{ cursor: header.sortable ? 'pointer' : '' }}
						>
							<TableHeaderNameSortable>
								<p className="text-strong text-subtle">{header.title(intl)}</p>
								{filters.sortBy.column === header.key && (
									<FontIcon style={{ fontSize: 12 }}>
										{filters.sortBy.order === 'asc'
											? 'arrow_upward'
											: 'arrow_downward'}
									</FontIcon>
								)}
							</TableHeaderNameSortable>
						</TableColumn>
					)
				})}
				<TableColumn key={'children-header'}></TableColumn>
			</TableRow>
		)
	}

	return (
		<TableContainer>
			{loans ? (
				<TableContainer>
					<DataTable
						plain
						style={{
							background: 'white',
							maxHeight: 'calc(100vh - 205px)',
						}}
					>
						<TableHeader
							style={{
								position: 'sticky',
								top: 0,
								zIndex: 1,
								background: 'white',
							}}
						>
							{renderTableHeaders()}
						</TableHeader>
						<TableBody>
							{loans
								.map((loan) => (
									<LoansTableRow
										key={loan.uuid}
										loan={loan}
										onDraftLoanClick={onDraftLoanClick}
									/>
								))
								.concat(
									<tr
										key="empty-row"
										className="full-width"
										style={{ height: 100, background: 'white' }}
									></tr>,
								)}
						</TableBody>
						<OverflowGradient style={{ bottom: 40 }} />
						<Pagination
							rowCount={totalItemCount}
							pageIndex={filters.paginationPage}
							rowsPerPage={itemsPerPage}
							onBack={handlePaginationBack}
							onNext={handlePaginationNext}
						/>
					</DataTable>
				</TableContainer>
			) : null}
		</TableContainer>
	)
}

export default LoansTable
