import { Button, CircularProgress } from 'react-md'
import styled from 'styled-components'
import InvoicePaymentsTable from '../Invoices/InvoicePaymentsTable'
import { useIntl } from 'react-intl'
import useConsolidatedInvoice from './useConsolidatedInvoice'
import InvoicesTable from '../Invoices/InvoicesTable'
import ConsolidatedInvoiceDetailsTable from './ConsolidatedInvoiceDetailsTable'
import type { ApartmentSelectOptions } from '../types'
import ViiluFullPageDialog from 'ui/components/ViiluFullPageDialog'

const NotFound = styled.p`
	margin: 16px;
`

const Content = styled.div`
	flex: 1;
	display: flex;
	overflow: auto;
`

const Left = styled.div`
	padding: 16px;
	border-right: 1px solid rgba(0, 0, 0, 0.2);
`

const Right = styled.div`
	padding: 16px;
	flex: 1;
`

const Heading = styled.h2`
	margin: 0;
`
type Props = {
	companyUUID: string
	batchUUID: string
	onHide: () => void
	apartmentOptions: ApartmentSelectOptions[]
}
const ConsolidatedInvoiceDialog = ({
	companyUUID,
	batchUUID,
	onHide,
	apartmentOptions,
}: Props) => {
	const intl = useIntl()
	const { data, loading, refresh } = useConsolidatedInvoice(
		companyUUID,
		batchUUID,
	)

	const invoiceAllocations = data.flatMap(
		(invoice) => invoice.invoiceAllocations,
	)
	const renderContent = () => {
		if (loading) {
			return (
				<CircularProgress
					id="consolidated-invoice-dialog-progress"
					style={{ marginTop: 64 }}
				/>
			)
		}

		if (!data) {
			return (
				<NotFound>
					{intl.formatMessage({
						defaultMessage: 'Laskua ei löytynyt.',
						description: 'Message for invoice not found.',
					})}
				</NotFound>
			)
		}

		return (
			<Content>
				<Left>
					<ConsolidatedInvoiceDetailsTable
						invoices={data}
						apartmentOptions={apartmentOptions ?? []}
					/>
				</Left>

				<Right>
					<Heading>
						{intl.formatMessage({
							defaultMessage: 'Laskut',
							description: 'Heading for invoices.',
						})}
					</Heading>
					<InvoicesTable
						invoices={data}
						refreshInvoices={refresh}
						apartmentOptions={[]}
						consolidatedUUID={batchUUID}
					/>

					<Heading style={{ marginTop: 32 }}>
						{intl.formatMessage({
							defaultMessage: 'Suoritukset',
							description: 'Heading for invoice payments.',
						})}
					</Heading>
					<InvoicePaymentsTable
						invoiceAllocations={invoiceAllocations}
						consolidatedUUID={batchUUID}
					/>
				</Right>
			</Content>
		)
	}

	const title = intl.formatMessage({
		defaultMessage: 'Koontilasku',
		description: 'Title for the invoice dialog.',
	})

	return (
		<ViiluFullPageDialog
			id="consolidated-invoice-dialog"
			title={title}
			visible
			onHide={onHide}
			focusOnMount={false}
			toolbarStyle={{
				background: 'var(--color-secondary-dark)',
			}}
			nav={
				<Button icon onClick={onHide}>
					close
				</Button>
			}
		>
			{renderContent()}
		</ViiluFullPageDialog>
	)
}

export default ConsolidatedInvoiceDialog
