import { injectIntl } from 'react-intl'
import { compose } from 'redux'
import styled from 'styled-components'
import Actions from '../../../Actions'

const TwoColumns = styled.div`
	flex: 1;
	display: flex;
	overflow: hidden;
`

const Column = styled.div`
	height: 100%;
	width: 50%;
	min-width: 500px;
	padding: 16px;
	overflow-y: auto;

	h1 {
		margin-top: 0;
		font-size: 1.8rem;
	}

	h2 {
		font-size: 1.5rem;
	}

	p {
		line-height: 1.6;
	}
`

const Left = styled(Column)`
	display: flex;
	flex-direction: column;
`

const Right = styled(Column)`
	background: #ececec;
`

function Layout({
	left,
	right,
	onPreviousClick,
	onDraftClick,
	onNextClick,
	nextLabel,
	intl,
}) {
	return (
		<>
			<TwoColumns>
				<Left>{left}</Left>
				<Right>{right}</Right>
			</TwoColumns>
			<Actions
				onPreviousClick={onPreviousClick}
				onDraftClick={onDraftClick}
				onNextClick={onNextClick}
				nextLabel={
					nextLabel ||
					intl.formatMessage({
						defaultMessage: 'Seuraava',
						description:
							'Label for the next button in the general sender component.',
					})
				}
			/>
		</>
	)
}

export default compose(injectIntl)(Layout)
