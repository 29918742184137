import styled from 'styled-components'

const Container = styled.div`
	height: 100%;
	display: flex;
	background: linear-gradient(var(--color-secondary-dark) 50%, white 50%);
`

const bottomRight = '0 0 8px 0'
const bottomLeft = '0 0 0 8px'

const RoundedCorner = styled.div`
	background-color: var(--color-secondary-dark);
	width: 8px;
	border-radius: ${(props) => (props.active ? props.borderRadius : '0 0 0 0')};
`

const TabButton = styled.button`
	cursor: pointer;
	border: none;
	margin-top: 8px;
	padding: 8px 32px;
	font-size: 18px;
	background-color: var(--color-secondary-dark);
	color: #fff;

	/* Rounded corners */
	&.active {
		border-radius: 8px 8px 0px 0px;
		background-color: #fff;
		color: #000;
	}
	&.previous {
		border-radius: ${bottomRight};
	}
	&.next {
		border-radius: ${bottomLeft};
	}

	/* Separator lines */
	position: relative;
	&::after {
		content: '';
		position: absolute;
		width: 1px;
		top: 12px;
		bottom: 12px;
		left: 0;
		background-color: #fff;
	}
	&.active::after {
		content: none;
	}
	&.next::after {
		content: none;
	}
`

function Tabs({ activeTabIndex, setActiveTabIndex, tabs }) {
	const getClassName = (index) => {
		if (index === activeTabIndex) {
			return 'active'
		}

		if (index === activeTabIndex - 1) {
			return 'previous'
		}

		if (index === activeTabIndex + 1) {
			return 'next'
		}
	}
	return (
		<Container>
			<RoundedCorner active={activeTabIndex === 0} borderRadius={bottomRight} />
			{tabs.map((tab, index) => (
				<TabButton
					key={tab.label}
					className={getClassName(index)}
					onClick={() => {
						setActiveTabIndex(index)
					}}
				>
					{tab.label}
				</TabButton>
			))}
			<RoundedCorner
				active={activeTabIndex === tabs.length - 1}
				borderRadius={bottomLeft}
			/>
		</Container>
	)
}

export default Tabs
