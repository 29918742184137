import { TableRow, TableColumn } from 'react-md'
import type { Contract } from '../Contracts/types'
import { invoiceCreateHeaders } from './constants'
import type { ApartmentSelectOptions } from '../types'
import { bondNameMessage } from '../utils/messages'
import { useIntl } from 'react-intl'

interface Props {
	contract: Contract
	selected: boolean
	handleOnClick: () => void
	apartmentOptions: ApartmentSelectOptions[]
	preview: boolean
	singleInvoice: boolean
}

const InvoiceCreateRow = ({
	contract,
	selected,
	handleOnClick,
	apartmentOptions,
	preview,
	singleInvoice,
}: Props) => {
	const intl = useIntl()
	const getRowValue = (key: string, type: string | undefined) => {
		const value = contract[key]
		if (key === 'apartmentUUID') {
			return apartmentOptions.find((a) => a.value === value)?.label
		} else if (type === 'partyBonds') {
			let bonds = ''
			if (Array.isArray(value) && value.length > 0) {
				bonds = value.map((bond) => bondNameMessage(intl, bond.name)).join(', ')
			}
			return bonds
		}
		return value
	}

	const renderTableColumns = () => {
		return invoiceCreateHeaders
			.filter((h) => h.show)
			.map((header) => {
				return (
					<TableColumn key={header.key}>
						<p>{getRowValue(header.key, header.type)}</p>
					</TableColumn>
				)
			})
	}

	return (
		<TableRow onClick={() => !preview && handleOnClick()}>
			{!preview && !singleInvoice && (
				<TableColumn key={`checkbox-${contract.uuid}`}>
					<input type="checkbox" checked={selected} onChange={() => {}} />
				</TableColumn>
			)}
			{renderTableColumns()}
		</TableRow>
	)
}

export default InvoiceCreateRow
