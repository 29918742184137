export const accountsLedgerHeaders = [
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Huoneisto',
				description: 'Apartment',
			}),
		key: 'apartmentIdentifier',
		type: 'apartmentIdentifier',
		show: true,
		sortable: true,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Tunniste',
				description: 'Identifier',
			}),
		key: 'identifier',
		type: 'identifier',
		show: true,
		sortable: false,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Alkusaldo',
				description: 'Opening balance',
			}),
		key: 'openingBalance',
		type: 'number',
		show: true,
		sortable: true,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Laskutettu',
				description: 'Billed',
			}),
		key: 'invoicedTotal',
		type: 'number',
		show: true,
		sortable: true,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Suoritukset',
				description: 'Payments',
			}),
		key: 'paidTotal',
		type: 'number',
		show: true,
		sortable: true,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Saldo',
				description: 'Balance',
			}),
		key: 'balance',
		type: 'balance',
		show: true,
		sortable: true,
	},
]

export const accountLedgerEntryHeaders = [
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Päivämäärä',
				description: 'Date',
			}),
		key: 'createdAt',
		type: 'date',
		show: true,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Nimi',
				description: 'Payer',
			}),
		key: 'partyName',
		type: 'string',
		show: true,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Tyyppi',
				description: 'Type',
			}),
		key: 'type',
		type: 'string',
		show: true,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Tunniste',
				description: 'Identifier',
			}),
		key: 'identifier',
		type: 'string',
		show: true,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Summa',
				description: 'Sum',
			}),
		key: 'sum',
		type: 'number',
		show: true,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Tila',
				description: 'Status',
			}),
		key: 'status',
		type: 'string',
		show: true,
	},
]
