import { useCallback, useEffect, useState } from 'react'
import { _getInvoice } from 'state/finance-invoice-actions'
import type { Invoice } from './types'

type State = {
	loading: boolean
	data?: Invoice
}

function useInvoice(invoiceUUID: string) {
	const [state, setState] = useState<State>({ loading: true })

	const doQuery = useCallback(async () => {
		const fetchInvoice = async () => {
			if (!invoiceUUID) {
				return
			}
			setState({ loading: true })
			let data: Invoice | undefined
			try {
				const response = await _getInvoice(invoiceUUID)
				if (response?.ok) {
					const result = await response.json()
					data = result.invoice
				} else {
					throw new Error('Response is not ok.')
				}
			} catch (error) {
				console.error(error)
			}
			setState({
				loading: false,
				data,
			})
		}

		fetchInvoice()
	}, [invoiceUUID])

	useEffect(() => {
		doQuery()
	}, [doQuery])

	return { ...state, refresh: doQuery }
}

export default useInvoice
