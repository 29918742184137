const initialState = {
	accessabilityModeEnabled: {},
	showReservations: {},
}

function reducer(state = initialState, action) {
	if (action.type === 'UI_INFOSCREEN_ACCESSABILITY_MODE_ENABLED') {
		return {
			...state,
			accessabilityModeEnabled: {
				[action.infoscreenUUID]: action.enabled,
			},
		}
	} else if (action.type === 'UI_INFOSCREEN_SHOW_RESERVATIONS') {
		return {
			...state,
			showReservations: {
				[action.infoscreenUUID]: action.showReservations,
			},
		}
	}

	return state
}

export default reducer
