import {
	addObjectFieldIfNotExist,
	removeObjectField,
} from 'state/reducer-utils'

const initialState = {
	messageFieldState: {}, // item: { channelTopic: { disabled: boolean, message: string } }
	// messageFieldValue: '', // DEPRECATED
	// messageFieldDisabled: false, // DEPRECATED
}

function reducer(state = initialState, action) {
	// the state object has changed shape and users might have stale state in their localStorage
	let migratedState = addObjectFieldIfNotExist(state, 'messageFieldState', {})
	migratedState = removeObjectField(migratedState, 'messageFieldValue')
	migratedState = removeObjectField(migratedState, 'messageFieldDisabled')

	if (action.type === 'UI_MESSAGES_MESSAGE_FIELD_VALUE') {
		return updatedMsgFieldState(migratedState, action, 'message')
	}
	if (action.type === 'UI_MESSAGES_MESSAGE_FIELD_DISABLED') {
		return updatedMsgFieldState(migratedState, action, 'disabled')
	}
	return migratedState
}

const updatedMsgFieldState = (state, action, fieldName) => {
	const objToUpdate = state.messageFieldState[action.topic]
	const updatedObj = {
		...objToUpdate,
		[fieldName]: action[fieldName],
	}

	const newMsgFieldState = { ...state.messageFieldState }
	newMsgFieldState[action.topic] = updatedObj

	return {
		...state,
		messageFieldState: newMsgFieldState,
	}
}

export default reducer
