import { backendFunction } from './actions-util'

export const _getCompanyBySearch = (managerUUID, searchTerm) => async () =>
	backendFunction('tampuuriCompanyBySearch', {
		managerUUID,
		searchTerm,
	})

export const _importCompany =
	({
		name,
		apartmentCount,
		address,
		zip,
		area,
		businessId,
		managerUUID,
		tampuuriCompanyId,
		tampuuriCompanyTunnus,
		importApartments,
		billingEmail,
		features,
		featuresHidden,
		featureSettings,
		serviceLevel,
	}) =>
	async () =>
		backendFunction('tampuuriCompanyImport', {
			name,
			apartmentCount,
			address,
			zip,
			area,
			businessId,
			managerUUID,
			tampuuriCompanyId,
			tampuuriCompanyTunnus,
			importApartments,
			billingEmail,
			features,
			featuresHidden,
			featureSettings,
			serviceLevel,
		})

export const _importCompanyMass =
	(
		managerUUID,
		features,
		featuresHidden,
		featureSettings,
		billingEmail,
		serviceLevel,
	) =>
	async () =>
		backendFunction('tampuuriCompanyImportMass', {
			managerUUID,
			billingEmail,
			features,
			featuresHidden,
			featureSettings,
			serviceLevel,
		})

export const _setEnabled = (managerUUID, enabled) => async () =>
	backendFunction('tampuuriEnabledSet', {
		managerUUID,
		enabled,
	})

export const _setCredentials =
	(managerUUID, name, account, key, viiluAccount, viiluPassword) => async () =>
		backendFunction('tampuuriCredentialsSet', {
			managerUUID,
			name,
			account,
			key,
			viiluAccount,
			viiluPassword,
		})

export const _companyLink =
	(managerUUID, tampuuriCompanyId, tampuuriCompanyTunnus) =>
	async (dispatch, getState) =>
		backendFunction('tampuuriCompanyLink', {
			managerUUID,
			companyUUID: getState().app.selectedCompanyUUID,
			tampuuriCompanyId,
			tampuuriCompanyTunnus,
		})

export const _getCompanyApartments = (companyUUID, managerUUID) => async () =>
	backendFunction('tampuuriCompanyApartments', {
		companyUUID,
		managerUUID,
	})

export const _getCompanyShareholdersByTampuuriCompanyId =
	(companyUUID, managerUUID, getConsents, getCountries, getViiluConsents) =>
	async () =>
		backendFunction('tampuuriCompanyShareholdersByTampuuriCompanyId', {
			companyUUID,
			managerUUID,
			getConsents,
			getCountries,
			getViiluConsents,
		})

export const _getCompanyShareholders =
	(
		companyUUID,
		managerUUID,
		getConsents,
		getCountries,
		getViiluConsents,
		getApartments,
	) =>
	async () =>
		backendFunction(
			'tampuuriCompanyShareholders',
			{
				companyUUID,
				managerUUID,
				getConsents,
				getCountries,
				getViiluConsents,
				getApartments,
			},
			{
				timeout: 3600000,
			},
		)

export const _getCompanyTenants =
	(
		companyUUID,
		managerUUID,
		getConsents,
		getCountries,
		getViiluConsents,
		getApartments,
	) =>
	async () =>
		backendFunction(
			'tampuuriCompanyTenants',
			{
				companyUUID,
				managerUUID,
				getConsents,
				getCountries,
				getViiluConsents,
				getApartments,
			},
			{
				timeout: 3600000,
			},
		)

export const _getCompanyBoardMembers =
	(
		companyUUID,
		managerUUID,
		getConsents,
		getCountries,
		getViiluConsents,
		getApartments,
	) =>
	async () =>
		backendFunction(
			'tampuuriCompanyBoardMembers',
			{
				companyUUID,
				managerUUID,
				getConsents,
				getCountries,
				getViiluConsents,
				getApartments,
			},
			{
				timeout: 3600000,
			},
		)

export const _getCompanyBoardMembersAll =
	(
		companyUUID,
		managerUUID,
		getConsents,
		getCountries,
		getViiluConsents,
		getApartments,
	) =>
	async () =>
		backendFunction(
			'tampuuriCompanyBoardMembersAll',
			{
				companyUUID,
				managerUUID,
				getConsents,
				getCountries,
				getViiluConsents,
				getApartments,
			},
			{
				timeout: 3600000,
			},
		)

export const _getCompanyBoardDeputyMembers =
	(
		companyUUID,
		managerUUID,
		getConsents,
		getCountries,
		getViiluConsents,
		getApartments,
	) =>
	async () =>
		backendFunction(
			'tampuuriCompanyBoardDeputyMembers',
			{
				companyUUID,
				managerUUID,
				getConsents,
				getCountries,
				getViiluConsents,
				getApartments,
			},
			{
				timeout: 3600000,
			},
		)

export const _getCompanyBoardDirectors =
	(
		companyUUID,
		managerUUID,
		getConsents,
		getCountries,
		getViiluConsents,
		getApartments,
	) =>
	async () =>
		backendFunction(
			'tampuuriCompanyBoardDirectors',
			{
				companyUUID,
				managerUUID,
				getConsents,
				getCountries,
				getViiluConsents,
				getApartments,
			},
			{
				timeout: 3600000,
			},
		)

export const _getCompanyManagers =
	(
		companyUUID,
		managerUUID,
		getConsents,
		getCountries,
		getViiluConsents,
		getApartments,
	) =>
	async () =>
		backendFunction(
			'tampuuriCompanyManagers',
			{
				companyUUID,
				managerUUID,
				getConsents,
				getCountries,
				getViiluConsents,
				getApartments,
			},
			{
				timeout: 3600000,
			},
		)

export const _updateCompanyManagerRoles = (companyUUID) => async () =>
	backendFunction('tampuuriCompanyRolesUpdate', {
		companyUUID,
	})

window._updateCompanyManagerRoles = _updateCompanyManagerRoles

export const _updateCompanyManagerRolesMass = (managerUUID) => async () =>
	backendFunction(
		'tampuuriCompanyRolesUpdateMass',
		{
			managerUUID,
		},
		{
			timeout: 3600000,
		},
	)

export const _apartmentReportSave = (managerUUID, apartments) => async () =>
	backendFunction('tampuuriApartmentReportSave', {
		managerUUID,
		apartments,
	})

export const _apartmentReportDelete = (managerUUID) => async () =>
	backendFunction('tampuuriApartmentReportDelete', {
		managerUUID,
	})

export const _apartmentFinancials =
	(companyUUID, managerUUID, apartmentUUID, userUUID) => async () =>
		backendFunction('tampuuriApartmentFinancials', {
			companyUUID,
			managerUUID,
			apartmentUUID,
			userUUID,
		})

export const _tampuuriSyncManual = (companyUUID) => async () =>
	backendFunction('tampuuriSyncManual', {
		companyUUID,
	})

export const _tampuuriSyncCreate = (companyUUID) => async () =>
	backendFunction('tampuuriSyncCreate', {
		companyUUID,
	})

export const _tampuuriSyncDelete = (uuid) => async () =>
	backendFunction('tampuuriSyncDelete', {
		uuid,
	})
