import config from '../../../config'
import { bindActionCreators, compose } from 'redux'
import WithSelectedCompanyRole from 'ui/components/WithSelectedCompanyRole'
import { connect } from 'react-redux'
import { useEffect } from 'react'
import * as appUIActions from 'state/ui/app-ui-actions'

const BeamerUnreadCountFetcher = ({
	selectedCompanyRole,
	auth,
	_setBeamerUnreadPostsCount,
}) => {
	useEffect(() => {
		const getUnreadCount = async () => {
			if (!auth || !auth.uid) {
				_setBeamerUnreadPostsCount(0)
				return 0
			}

			const splits = auth.displayName ? auth.displayName.split(' ') : null
			const firstName = splits ? splits[0] : ''
			const lastName =
				splits && splits.length > 1 ? splits.slice(1).join(' ') : '' // last name can have spaces
			const key = config.beamer.key
			const endpoint = `https://api.getbeamer.com/v0/unread/count?filter=${selectedCompanyRole}&userId=${auth.uid}&userFirstName=${firstName}&userLastName=${lastName}&userEmail=${auth.email}&category=announcement`
			let result
			try {
				result = await window.fetch(endpoint, {
					headers: {
						'Beamer-Api-Key': key,
					},
				})
			} catch (ex) {
				// Tight wrapping of the fetch is required to avoid crash
				// on crappy networks
				console.log(ex)
			}

			if (result && result.ok) {
				const json = await result.json()
				const count = json.count
				_setBeamerUnreadPostsCount(count)
			}
		}
		try {
			getUnreadCount()
		} catch (ex) {
			console.error('failed to fetch beamer unread post count')
		}
	}, [auth, selectedCompanyRole, _setBeamerUnreadPostsCount])

	return null
}

const mapState = ({ firebaseReducer: { auth } }) => ({
	auth,
})

const mapDispatchToProps = (dispatch) => ({
	_setBeamerUnreadPostsCount: bindActionCreators(
		appUIActions._setBeamerUnreadPostsCount,
		dispatch,
	),
})

export default compose(
	connect(mapState, mapDispatchToProps),
	WithSelectedCompanyRole,
)(BeamerUnreadCountFetcher)
