import { Button } from 'react-md'
import TimePicker from 'react-time-picker'
import styled from 'styled-components'
import DateSelect from 'ui/components/DateSelect'

const Inputs = styled.div`
	display: flex;
	align-items: center;
	background: #fafafa;
	border-radius: 8px;
`

const Label = styled.div`
	margin-bottom: 4px;
`

const StyledTimePicker = styled(TimePicker)`
	background-color: transparent;
	.react-time-picker__wrapper {
		height: 32px;
		font-size: 13px;
		text-align: center;
		border: none;
		border-radius: 16px;
		background: #e0e0e0;
	}
`

function DateAndTimePicker({
	label = '',
	emptyText,
	ts,
	updateTs,
	hideDeleteButton = false,
	disabled,
}) {
	const handleTimeChange = (time) => {
		// time is string "00:00" or null
		if (!time) {
			return
		}
		const updated = new Date(ts)
		const splitted = time.split(':')
		updated.setHours(splitted[0], splitted[1])
		updateTs(updated.getTime())
	}

	return (
		<>
			<Label>{label}</Label>
			<Inputs>
				<DateSelect
					dateValue={ts}
					emptyText={emptyText}
					onDateUpdate={updateTs}
					disabled={disabled}
				/>
				{ts && (
					<>
						<StyledTimePicker
							onChange={handleTimeChange}
							value={new Date(ts)}
							locale={'fi-FI'}
							format={'HH:mm'}
							disableClock
							clearIcon={null}
							disabled={disabled}
						/>
						{!hideDeleteButton && (
							<Button
								icon
								onClick={() => {
									updateTs(null)
								}}
							>
								cancel
							</Button>
						)}
					</>
				)}
			</Inputs>
		</>
	)
}

export default DateAndTimePicker
