import { useIntl } from 'react-intl'
import DataTable, {
	TableBody,
	TableColumn,
	TableHeader,
	TableRow,
} from 'react-md/lib/DataTables'
import OverflowGradient from 'ui/components/OverflowGradient'
import { TableContainer } from 'ui/StyledComponents/Tables'
import type { LoanEvent } from '../types'
import { Fragment } from 'react'
import { loanEventTypeMessage } from '../messages'
import styled from 'styled-components'
import { formatCurrency } from 'util/formatStrings'

const LoanEventRow = styled(TableRow)<{ $filledBackground: boolean }>`
	background-color: ${(props) => (props.$filledBackground ? '#FAFAFA' : '')};
`

const LoanEventsNotFoundContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 200px;
`

type LoanEventsTableProps = {
	events: LoanEvent[]
}

function LoanEventsTable(props: LoanEventsTableProps) {
	const { events } = props
	const intl = useIntl()

	const renderTableHeaders = () => {
		return (
			<TableRow>
				<TableColumn>
					<p className="text-strong text-subtle">
						{intl.formatMessage({
							defaultMessage: 'Päivämäärä',
							description: 'Table header for date',
						})}
					</p>
				</TableColumn>
				<TableColumn>
					<p className="text-strong text-subtle">
						{intl.formatMessage({
							defaultMessage: 'Tyyppi',
							description: 'Table header for type',
						})}
					</p>
				</TableColumn>
				<TableColumn>
					<p className="text-strong text-subtle">
						{intl.formatMessage({
							defaultMessage: 'Summa',
							description: 'Table header for sum',
						})}
					</p>
				</TableColumn>
			</TableRow>
		)
	}

	return (
		<>
			{events?.length > 0 ? (
				<TableContainer>
					<DataTable
						baseId="loan-events-table"
						plain
						className="md-paper--1"
						style={{
							background: 'white',
							maxHeight: 'calc(100vh - 205px)',
						}}
					>
						<TableHeader
							style={{
								position: 'sticky',
								top: 0,
								zIndex: 1,
								background: 'white',
								borderBottom: 'rgba(0, 0, 0, 0.12) solid 2px',
							}}
						>
							{renderTableHeaders()}
						</TableHeader>
						<TableBody>
							{events
								.map((event, index) => (
									<Fragment key={event.uuid}>
										<LoanEventRow $filledBackground={index % 2 === 0}>
											<TableColumn>{event.date}</TableColumn>
											<TableColumn>
												{loanEventTypeMessage(intl, event.type)}
											</TableColumn>
											<TableColumn>{formatCurrency(event.amount)}</TableColumn>
										</LoanEventRow>
									</Fragment>
								))
								.concat(
									<tr
										key="empty-row"
										className="full-width"
										style={{ height: 100, background: 'white' }}
									></tr>,
								)}
						</TableBody>
						<OverflowGradient style={{ bottom: 40 }} />
					</DataTable>
				</TableContainer>
			) : (
				<LoanEventsNotFoundContainer>
					{intl.formatMessage({
						defaultMessage: 'Tapahtumia ei löytynyt',
						description: 'Error text when no loan events were found',
					})}
				</LoanEventsNotFoundContainer>
			)}
		</>
	)
}

export default LoanEventsTable
