import { useState } from 'react'
import { isEmpty } from 'react-redux-firebase'
import {
	DataTable,
	TableHeader,
	TableRow,
	TableColumn,
	TableBody,
	ListItem,
	FontIcon,
} from 'react-md'
import { AutoSizer } from 'react-virtualized'
import EmptyState from 'ui/components/EmptyState'
import documentsEmptyIcon from 'svg/documents-empty.svg'
import OverflowGradient from 'ui/components/OverflowGradient'
import { TableContainer } from 'ui/StyledComponents/Tables'
import FloatingActionBtn from 'ui/components/FloatingActionBtn'
import AddBondDialog from './AddBondDialog'
import { bondHeaders } from './constants'
import BondsRow from './BondsRow'
import { GENERIC_ERROR_NOTIFICATION } from 'ui/messages'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import * as notifyActions from 'state/notifyActions'
import type { Bond } from './types'
import { _deleteBond } from 'state/finance-party-actions'
import useDialogs from 'ui/components/dialogs/useDialogs'
import LoadingState from 'ui/components/LoadingState'
import usePartyNamesWithBonds from './usePartyNamesWithBonds'
import type { ApartmentSelectOptions, SelectOptions } from '../types'
import naturalSort from 'javascript-natural-sort'

interface Props {
	bonds: Bond[]
	refreshBonds: () => void
	loadingBonds: boolean
	selectedFinanceCompanyUUID: string
	bondNameOptions: SelectOptions[]
	apartmentOptions: ApartmentSelectOptions[]
	companyOptions: SelectOptions[]
	refreshParties: () => void
}

const BondsView = ({
	bonds,
	refreshBonds,
	loadingBonds,
	selectedFinanceCompanyUUID,
	bondNameOptions,
	apartmentOptions,
	companyOptions,
	refreshParties,
}: Props) => {
	const intl = useIntl()
	const dispatch = useDispatch()
	const { confirm } = useDialogs()
	const [processing, setProcessing] = useState<boolean>(false)
	const { partyNames, refreshPartyNames } = usePartyNamesWithBonds()

	const [sort, setSort] = useState<{ key: string; order: string }>({
		key: 'name',
		order: 'asc',
	})

	const changeSort = (key: string) => {
		if (sort.key === key) {
			setSort((prevData) => ({
				...prevData,
				order: sort.order === 'asc' ? 'desc' : 'asc',
			}))
		} else {
			setSort({
				key: key,
				order: 'asc',
			})
		}
	}

	const [addBondsDialogVisible, setBondsDialogVisible] =
		useState<boolean>(false)

	const deleteBond = async (uuid: string) => {
		const confirmMessage = intl.formatMessage({
			defaultMessage: 'Oletko varma, että haluat poistaa roolin?',
			description: 'Confirm message for the delete bond.',
		})
		if (!(await confirm(confirmMessage))) {
			return
		}

		setProcessing(true)

		const result = await _deleteBond({ uuid: uuid })
		setProcessing(false)

		if (!result || result.ok === false) {
			dispatch(notifyActions.info('❌ ' + GENERIC_ERROR_NOTIFICATION(intl)))
			return false
		}

		dispatch(
			notifyActions.info(
				'✅ ' +
					intl.formatMessage({
						defaultMessage: 'Rooli on poistettu.',
						description: 'Notification text of bond has been deleted',
					}),
			),
		)
		refreshBonds()
		return true
	}

	const renderEmpty = () => {
		return (
			<div className="flex-center flex-one full-height">
				<EmptyState
					text={intl.formatMessage({
						defaultMessage: 'Roolilista näyttäisi olevan tyhjä.',
						description: 'Bond list is empty text.',
					})}
					icon={documentsEmptyIcon}
				/>
			</div>
		)
	}

	if (!bonds) {
		renderEmpty()
	}

	if (loadingBonds) {
		return <LoadingState />
	}

	const existingBonds = bonds && bonds.filter((t) => t)

	if (isEmpty(existingBonds)) {
		renderEmpty()
	}

	const showAddBondsDialog = () => {
		setBondsDialogVisible(true)
	}

	const hideAddBondsDialog = () => {
		setBondsDialogVisible(false)
	}

	const renderFAB = () => {
		return (
			<FloatingActionBtn
				iconName={'add'}
				text={intl.formatMessage({
					defaultMessage: 'Lisää rooli osapuolille',
					description: 'Add new bond for parties',
				})}
				action={showAddBondsDialog}
			/>
		)
	}

	const renderTableHeaders = () => {
		return (
			<TableRow>
				{bondHeaders
					.filter((h) => h.show)
					.map((header) => (
						<TableColumn
							key={header.key}
							onClick={() => header.sortable && changeSort(header.key)}
						>
							<div
								style={{ display: 'flex', alignItems: 'center', gap: '4px' }}
							>
								<p className="text-strong text-subtle">{header.title(intl)}</p>
								{sort.key === header.key && (
									<FontIcon style={{ fontSize: 12 }}>
										{sort.order === 'asc' ? 'arrow_upward' : 'arrow_downward'}
									</FontIcon>
								)}
							</div>
						</TableColumn>
					))}
			</TableRow>
		)
	}
	const renderTable = () => {
		if (!bonds || !bonds.length) {
			return (
				<p style={{ marginLeft: '1.5rem', marginTop: '1.5rem' }}>
					{intl.formatMessage({
						defaultMessage: 'Rooleja ei löytynyt.',
						description: 'Message for empty bonds list.',
					})}
				</p>
			)
		}

		return (
			<TableContainer>
				<AutoSizer disableHeight>
					{({ height, width }) => {
						return (
							<DataTable
								plain
								style={{ background: 'white' }}
								fixedHeight={height}
								fixedWidth={width}
							>
								<TableHeader>{renderTableHeaders()}</TableHeader>
								<TableBody>
									{bonds
										.sort((a, b) => {
											if (sort.order === 'asc') {
												return naturalSort(a[sort.key], b[sort.key])
											} else {
												return naturalSort(b[sort.key], a[sort.key])
											}
										})
										.map((bond) => (
											<BondsRow
												key={bond.uuid}
												bond={bond}
												menuItems={[
													<ListItem
														key={1}
														primaryText={intl.formatMessage({
															defaultMessage: 'Poista rooli',
															description: 'Delete bond',
														})}
														leftIcon={<FontIcon>delete</FontIcon>}
														onClick={() => deleteBond(bond.uuid)}
														className="md-divider-border md-divider-border--right"
													/>,
												]}
												children={null}
												processing={processing}
											/>
										))
										.concat(
											// Concat one empty row to indicate list ending to the user
											<tr
												key="empty-row"
												className="full-width"
												style={{ height: 256, background: 'white' }}
											></tr>,
										)}
								</TableBody>
								<OverflowGradient />
							</DataTable>
						)
					}}
				</AutoSizer>
			</TableContainer>
		)
	}

	return (
		<div>
			{renderTable()}
			<AddBondDialog
				visible={addBondsDialogVisible}
				onHide={hideAddBondsDialog}
				refreshBonds={refreshBonds}
				selectedFinanceCompanyUUID={selectedFinanceCompanyUUID}
				bondNameOptions={bondNameOptions}
				companyOptions={companyOptions}
				refreshParties={refreshParties}
				apartmentOptions={apartmentOptions}
				partyNames={partyNames}
				refreshPartyNames={refreshPartyNames}
			/>

			{renderFAB()}
		</div>
	)
}

export default BondsView
