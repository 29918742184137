import { useIntl } from 'react-intl'
import {
	Button,
	FontIcon,
	MenuButtonColumn,
	SelectField,
	TextField,
} from 'react-md'
import styled from 'styled-components'
import DatePickerISO from 'ui/components/DatePickerISO'
import { billingBasisMessage } from '../utils/messages'
import type { PaymentProductFormData } from './types'
import { PaymentProductBillingBasis } from '../enum'
import { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import {
	calculateSumWithoutVat,
	calculateSumWithVat,
	removeLeadingZero,
} from '../utils/utils'
import type { VatSelectOptions } from '../types'
import YesNoSwitch from 'ui/components/YesNoSwitch'

const Header = styled.h3`
	font-size: 1.5rem;
`

const Horizontal = styled.div`
	display: flex;
	gap: 16px;
	margin-bottom: 16px;
`

type Props = {
	uuid?: string
	formData: PaymentProductFormData
	setFormData: React.Dispatch<React.SetStateAction<PaymentProductFormData>>
	vatOptions: VatSelectOptions[]
}

function PaymentProductForm({
	uuid,
	formData,
	setFormData,
	vatOptions,
}: Props) {
	const intl = useIntl()
	const [editBillingBasis, setEditBillingBasis] = useState(
		typeof uuid === 'string',
	)

	const vatPercentage =
		vatOptions.find((v) => v.value === formData.vatUUID)?.percentage || 0

	return (
		<>
			<TextField
				id="payment-product-name-input"
				label={intl.formatMessage({
					defaultMessage: 'Nimi',
					description:
						'Label for the name field in the finance payment product form.',
				})}
				value={formData.name}
				onChange={(value) => {
					setFormData((prev) => ({
						...prev,
						name: value.toString(),
					}))
				}}
				required
			/>

			<TextField
				id="payment-product-description-input"
				label={intl.formatMessage({
					defaultMessage: 'Selite',
					description:
						'Label for the desctipion field in the finance payment product form.',
				})}
				value={formData.description}
				onChange={(value) => {
					setFormData((prev) => ({
						...prev,
						description: value.toString(),
					}))
				}}
				required
			/>

			<TextField
				id="payment-product-name-input"
				label={intl.formatMessage({
					defaultMessage: 'Kohdistusjärjestys',
					description:
						'Label for the priority field in the finance payment product form.',
				})}
				type="number"
				min={1}
				value={formData.priority}
				onChange={(value) => {
					setFormData((prev) => ({
						...prev,
						priority: value === '' ? value : Number(value),
					}))
				}}
				required
			/>

			<TextField
				id="payment-product-name-input"
				label={intl.formatMessage({
					defaultMessage: 'Numero',
					description:
						'Label for the number field in the finance payment product form.',
				})}
				type="number"
				min={0}
				value={formData.number}
				onChange={(value) => {
					setFormData((prev) => ({
						...prev,
						number: value === '' ? value : Number(value),
					}))
				}}
				required
			/>

			<Horizontal>
				<SelectField
					id="payment-product-billing-basis-input"
					label={intl.formatMessage({
						defaultMessage: 'Veloitusperuste',
						description:
							'Label for the billing basis field in the finance payment product form.',
					})}
					style={{ width: '100%', maxWidth: 240 }}
					menuItems={Object.values(PaymentProductBillingBasis).map(
						(basisOption) => ({
							label: billingBasisMessage(intl, basisOption),
							value: basisOption,
						}),
					)}
					value={formData.billingBasis}
					onChange={(value) => {
						setFormData((prev) => ({
							...prev,
							billingBasis: value as PaymentProductBillingBasis,
						}))
					}}
					disabled={editBillingBasis}
				/>
				{uuid && (
					<Button
						icon
						style={{ marginTop: 16 }}
						onClick={() => setEditBillingBasis(!editBillingBasis)}
					>
						<FontIcon>edit</FontIcon>
					</Button>
				)}
			</Horizontal>

			<TextField
				id="payment-product-billing-basis-description-input"
				label={intl.formatMessage({
					defaultMessage: 'Veloitusperusteen selite',
					description:
						'Label for the billing basis description field in the finance payment product form.',
				})}
				value={formData.billingBasisDescription}
				onChange={(value) => {
					setFormData((prev) => ({
						...prev,
						billingBasisDescription: value.toString(),
					}))
				}}
				required
			/>

			<Horizontal>
				<DatePickerISO
					id="payment-product-start-date-input"
					label={intl.formatMessage({
						defaultMessage: 'Alkupäivämäärä',
						description:
							'Label for the start date field in the finance payment product form.',
					})}
					value={formData.startDate}
					onChange={(value) => {
						setFormData((prev) => ({
							...prev,
							startDate: value,
						}))
					}}
					required
				/>
				<DatePickerISO
					id="payment-product-end-date-input"
					label={intl.formatMessage({
						defaultMessage: 'Loppupäivämäärä',
						description:
							'Label for the end date field in the finance payment product form.',
					})}
					value={formData.endDate}
					onChange={(value) => {
						setFormData((prev) => ({
							...prev,
							endDate: value,
						}))
					}}
				/>
			</Horizontal>

			<YesNoSwitch
				label={intl.formatMessage({
					defaultMessage: 'Erillislaskutus',
					description:
						'Label for the separate billing field in the finance payment product form.',
				})}
				name="separateBilling"
				checked={formData.separateBilling}
				onChange={(checked: boolean) => {
					setFormData((prev) => ({ ...prev, separateBilling: checked }))
				}}
			/>

			<SelectField
				id="payment-product-vat-select-field"
				label={intl.formatMessage({
					defaultMessage: 'ALV',
					description:
						'Label for the vat field in the finance payment product form.',
				})}
				style={{ width: '100%', maxWidth: 240 }}
				menuItems={vatOptions}
				value={formData.vatUUID || ''}
				onChange={(value) => {
					setFormData((prev) => ({
						...prev,
						vatUUID: (value as string) || null,
					}))
				}}
			/>

			<Header>
				{intl.formatMessage({
					defaultMessage: 'Hinnat',
					description:
						'Header for the prices section in the finance payment product form.',
				})}
			</Header>
			{formData.prices
				.sort((a, b) => {
					if (!a.startDate) return -1
					if (!b.startDate) return 1
					return (
						new Date(b.startDate).getTime() - new Date(a.startDate).getTime()
					)
				})
				.filter((p) => !p.deleted)
				.map((formPrice) => {
					const { uuid, price, startDate, endDate } = formPrice
					return (
						<Horizontal key={uuid}>
							<TextField
								id={`payment-product-price-${uuid}-input`}
								label={intl.formatMessage({
									defaultMessage: 'Hinta',
									description:
										'Label for the price field in the finance payment product form.',
								})}
								type="number"
								min={0}
								value={removeLeadingZero(price)}
								style={{ minWidth: '85px' }}
								onChange={(value) => {
									const price =
										value === '' ? 0 : Number(removeLeadingZero(value))
									setFormData((prev) => {
										const updated = prev.prices.map((p) =>
											p.uuid === uuid
												? {
														...p,
														price: price,
												  }
												: p,
										)
										return { ...prev, prices: updated }
									})
								}}
								required
							/>
							<TextField
								id={`payment-product-price-with-vat-${uuid}-input`}
								label={intl.formatMessage({
									defaultMessage: 'Hinta + alv',
									description:
										'Label for the price field in the finance payment product form.',
								})}
								type="number"
								min={0}
								style={{ minWidth: '85px' }}
								value={calculateSumWithVat(price || 0, vatPercentage)}
								onChange={(value) => {
									const price =
										value === '' ? 0 : Number(removeLeadingZero(value))
									setFormData((prev) => {
										const updated = prev.prices.map((p) =>
											p.uuid === uuid
												? {
														...p,
														price: calculateSumWithoutVat(price, vatPercentage),
												  }
												: p,
										)
										return { ...prev, prices: updated }
									})
								}}
								required
							/>
							<DatePickerISO
								id={`payment-product-price-start-date-${uuid}-input`}
								label={intl.formatMessage({
									defaultMessage: 'Alkupäivämäärä',
									description:
										'Label for the start date field in the finance payment product form.',
								})}
								value={startDate}
								onChange={(value) => {
									setFormData((prev) => {
										const updated = prev.prices.map((p) =>
											p.uuid === uuid ? { ...p, startDate: value } : p,
										)
										return { ...prev, prices: updated }
									})
								}}
								required
							/>
							<DatePickerISO
								id={`payment-product-price-end-date-${uuid}-input`}
								label={intl.formatMessage({
									defaultMessage: 'Loppupäivämäärä',
									description:
										'Label for the end date field in the finance payment product form.',
								})}
								value={endDate}
								onChange={(value) => {
									setFormData((prev) => {
										const updated = prev.prices.map((p) =>
											p.uuid === uuid ? { ...p, endDate: value } : p,
										)
										return { ...prev, prices: updated }
									})
								}}
							/>
							{formData.prices.length > 1 && (
								<MenuButtonColumn
									style={{ marginTop: 16 }}
									icon
									menuItems={[
										formData.prices.length > 1 && {
											leftIcon: <FontIcon>delete</FontIcon>,
											primaryText: intl.formatMessage({
												defaultMessage: 'Poista',
												description:
													'Label for the delete payment product button.',
											}),
											onClick: () => {
												if (uuid.includes('new')) {
													setFormData((prev) => {
														const updated = prev.prices.filter(
															(p) => p.uuid !== uuid,
														)
														return { ...prev, prices: updated }
													})
												} else {
													setFormData((prev) => {
														const updated = prev.prices.map((p) =>
															p.uuid === uuid ? { ...p, deleted: true } : p,
														)
														return { ...prev, prices: updated }
													})
												}
											},
										},
									]}
								>
									<FontIcon>more_vert</FontIcon>
								</MenuButtonColumn>
							)}
						</Horizontal>
					)
				})}

			<Button
				raised
				primary
				onClick={() => {
					setFormData((prev) => ({
						...prev,
						prices: [
							...prev.prices,
							{
								uuid: 'new' + uuidv4(),
								price: '',
								startDate: '',
								endDate: '',
								deleted: false,
							},
						],
					}))
				}}
			>
				{intl.formatMessage({
					defaultMessage: 'Lisää hinta',
					description:
						'Label for the add price button in the finance payment product form.',
				})}
			</Button>
		</>
	)
}

export default PaymentProductForm
