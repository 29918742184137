import { useIntl } from 'react-intl'
import { Switch } from 'react-md'
import styled from 'styled-components'
import { YES_NO_MESSAGE } from 'ui/messages'

type YesNoSwitchProps = {
	label: string
	name: string
	checked: boolean
	onChange: (checked: boolean) => void
	style?: object
	disabled?: boolean
}

const YesNoContainer = styled.div`
	margin-bottom: 16px;

	.md-switch-track {
		margin-left: 0;
	}
`

const YesNoSwitch = ({
	label,
	name,
	checked,
	onChange,
	style,
	disabled,
}: YesNoSwitchProps) => {
	const intl = useIntl()
	return (
		<YesNoContainer style={{ ...style }}>
			<label className="md-selection-control-label">{label}</label>
			<Switch
				id={`yes-no-switch-${name}-input`}
				name={name}
				label={YES_NO_MESSAGE(intl, checked)}
				style={{ margin: 0 }}
				checked={checked}
				onChange={onChange}
				disabled={disabled}
			/>
		</YesNoContainer>
	)
}

export default YesNoSwitch
