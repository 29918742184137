import naturalSort from 'javascript-natural-sort'
import type { VatSelectOptions } from '../types'
import { ValueAddedTax } from '../ValueAddedTax/types'

export const createVatOptions = (vat: ValueAddedTax[]): VatSelectOptions[] => {
	const options: VatSelectOptions[] = []

	vat.forEach(({ uuid, name, percentages }) => {
		const currentDate = new Date().getTime()

		const percentage = percentages
			.filter((p) => !p.deleted)
			.find(
				(p) =>
					new Date(p.startDate as Date).getTime() <= currentDate &&
					(!p.endDate || new Date(p.endDate).getTime() >= currentDate),
			)?.percentage

		if (percentage !== undefined) {
			options.push({
				label: name,
				value: uuid,
				percentage,
			})
		}
	})

	return options.sort((a, b) => naturalSort(a.label, b.label))
}
