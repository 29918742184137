import { useCallback, useEffect, useState } from 'react'
import { _getPartyNamesWithBonds } from 'state/finance-party-actions'
import type { PartyName } from './types'

function usePartyNamesWithBonds() {
	const [partyNames, setPartyNames] = useState<PartyName[]>()

	const [loadingPartyNames, setLoading] = useState<boolean>(false)
	const [errorPartyNames, setError] = useState<string | null>(null)

	const doQuery = useCallback(async () => {
		setLoading(true)
		setError(null)
		let data: PartyName[] = []

		try {
			const response = await _getPartyNamesWithBonds()

			if (response && response.ok) {
				const res = await response.json()

				if (res.partyNames && Array.isArray(res.partyNames))
					data = res.partyNames
			} else {
				throw new Error('Response is not ok.')
			}
		} catch (err) {
			console.error('Error fetching finance party names with bonds:', err)
			setError(
				'An error occurred while fetching finance party names with bonds',
			)
		}
		setPartyNames(data)
		setLoading(false)
	}, [])

	useEffect(() => {
		doQuery()
	}, [doQuery])

	return {
		partyNames,
		loadingPartyNames,
		errorPartyNames,
		refreshPartyNames: doQuery,
	}
}

export default usePartyNamesWithBonds
