import { useEffect, useState } from 'react'
import { injectIntl } from 'react-intl'
import { TextField } from 'react-md'
import { compose } from 'redux'
import styled from 'styled-components'
import WithSelectedCompanyManager from 'ui/components/WithSelectedCompanyManager'
import Layout from '../../Layout'
import Preview from './Preview'
import { getSetting } from 'util/companyModuleSettings'
import WithSelectedCompany from 'ui/components/WithSelectedCompany'

const Form = styled.form`
	margin: 24px 0;
`

const SideBySide = styled.div`
	display: flex;
`

const initErrors = {
	name: false,
	country: false,
	area: false,
	zip: false,
	address: false,
}

const initSender = (selectedCompany, selectedCompanyManager) => {
	let senderName = selectedCompanyManager?.postalSenderName || ''

	const companyPostalSenderPrependCompanyName = getSetting(
		selectedCompany,
		'postal',
		'companyPostalSenderPrependCompanyName',
	)
	if (companyPostalSenderPrependCompanyName) {
		senderName = `${selectedCompany?.name} / ${
			selectedCompanyManager?.postalSenderName || ''
		}`
	}

	return {
		name: senderName,
		address: selectedCompanyManager?.postalSenderAddress || '',
		zip: selectedCompanyManager?.postalSenderZip || '',
		area: selectedCompanyManager?.postalSenderArea || '',
		country: selectedCompanyManager?.postalSenderCountry || 'FI',
	}
}

function SenderInformation({
	stepTitle,
	onPreviousClick,
	onDraftClick,
	onNextClick,
	selectedCompany,
	selectedCompanyManager,
	messageServiceProcess,
	setMessageServiceProcess,
	intl,
}) {
	const { sender } = messageServiceProcess.postalSettings || {}
	const [errors, setErrors] = useState({ ...initErrors })

	useEffect(() => {
		if (!sender) {
			setMessageServiceProcess((prev) => ({
				...prev,
				postalSettings: {
					...prev.postalSettings,
					sender: initSender(selectedCompany, selectedCompanyManager),
				},
			}))
		}
	}, [
		selectedCompany,
		selectedCompanyManager,
		sender,
		setMessageServiceProcess,
	])

	// Has to be inited first.
	if (!sender) {
		return null
	}

	const updateSender = (sender) => {
		setMessageServiceProcess((prev) => ({
			...prev,
			postalSettings: {
				...prev.postalSettings,
				sender,
			},
		}))
	}

	const handleInputChange = (name, value) => {
		updateSender({ ...sender, [name]: value })

		if (errors[name]) {
			setErrors({ ...errors, [name]: false })
		}
	}

	const validateInputs = () => {
		const newErrors = { ...initErrors }

		if (!sender.name) {
			newErrors.name = true
		}

		if (!sender.country) {
			newErrors.country = true
		}

		if (!sender.area) {
			newErrors.area = true
		}

		if (!sender.zip) {
			newErrors.zip = true
		}

		if (!sender.address) {
			newErrors.address = true
		}

		setErrors(newErrors)

		return !Object.values(newErrors).some((isError) => isError)
	}

	const handleNext = () => {
		if (validateInputs()) {
			onNextClick()
		}
	}

	const left = (
		<>
			<h1 style={{ marginBottom: '32px' }}>{stepTitle}</h1>
			<p>
				{intl.formatMessage({
					defaultMessage:
						'Lähettäjän tietoja käytetään postituksessa. Oletusarvoisesti nämä otetaan isännöintitoimiston asetuksista.',
					description:
						'Informational description of the postal sender information step in the general sender component.',
				})}
			</p>

			<Form onSubmit={handleNext} autoComplete="off">
				<TextField
					id="sender-name"
					style={{ marginBottom: '24px' }}
					label={intl.formatMessage({
						defaultMessage: 'Lähettäjän nimi',
						description:
							'Label for the postal sender name input in the general sender component.',
					})}
					value={sender.name}
					onChange={(value) => handleInputChange('name', value)}
					error={errors.name}
				/>

				<TextField
					id="sender-address"
					style={{ marginBottom: '24px' }}
					label={intl.formatMessage({
						defaultMessage: 'Katuosoite',
						description:
							'Label for the postal sender street address input in the general sender component.',
					})}
					value={sender.address}
					onChange={(value) => handleInputChange('address', value)}
					error={errors.address}
				/>

				<SideBySide style={{ marginBottom: '24px' }}>
					<TextField
						id="sender-country"
						label={intl.formatMessage({
							defaultMessage: 'Maakoodi',
							description:
								'Label for the postal sender country code input in the general sender component.',
						})}
						value={sender.country}
						onChange={(value) => handleInputChange({ country: value })}
						disabled
						error={errors.country}
					/>

					<TextField
						id="sender-zip"
						style={{ marginLeft: 16, marginRight: 16 }}
						label={intl.formatMessage({
							defaultMessage: 'Postinumero',
							description:
								'Label for the postal sender zip code input in the general sender component.',
						})}
						value={sender.zip}
						onChange={(value) => handleInputChange('zip', value)}
						error={errors.zip}
					/>

					<TextField
						id="sender-area"
						label={intl.formatMessage({
							defaultMessage: 'Postitoimipaikka',
							description:
								'Label for the postal sender area input in the general sender component.',
						})}
						value={sender.area}
						onChange={(value) => handleInputChange('area', value)}
						error={errors.area}
					/>
				</SideBySide>
			</Form>
		</>
	)

	const right = <Preview sender={sender} />

	return (
		<Layout
			left={left}
			right={right}
			onPreviousClick={onPreviousClick}
			onDraftClick={onDraftClick}
			onNextClick={handleNext}
		/>
	)
}

export default compose(
	injectIntl,
	WithSelectedCompany,
	WithSelectedCompanyManager,
)(SenderInformation)
