import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase'
import LoadingState from 'ui/components/LoadingState'
import WithSelectedCompanyUUID from './WithSelectedCompanyUUID'

export default (ComponentToWrap) => {
	class WithSelectedCompany extends Component {
		render() {
			const { companies } = this.props
			if (!isLoaded(companies)) {
				return <LoadingState />
			}
			if (isEmpty(companies)) {
				return <ComponentToWrap {...this.props} selectedCompany={null} />
			}
			return <ComponentToWrap {...this.props} selectedCompany={companies[0]} />
		}
	}

	const mapState = ({
		firestoreReducer: {
			ordered: { company },
		},
	}) => ({ companies: company })

	const mapDispatchToProps = (dispatch) => ({})

	return compose(
		WithSelectedCompanyUUID,
		firestoreConnect(({ selectedCompanyUUID }) => {
			return [
				{
					collection: 'company',
					where: [['uuid', '==', selectedCompanyUUID || '']],
					limit: 1,
				},
			]
		}),
		connect(mapState, mapDispatchToProps),
	)(WithSelectedCompany)
}
