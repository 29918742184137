import { useCallback, useEffect, useState } from 'react'
import type { LoanFilters, LoanGetData } from './types'
import { _getLoans } from 'state/finance-loan.actions'

function useLoans(filters: LoanFilters) {
	const [loansResult, setLoansResult] = useState<LoanGetData | null>(null)

	const [loadingLoans, setLoadingLoans] = useState<boolean>(false)
	const [errorLoans, setErrorLoans] = useState<string | null>(null)

	const doQuery = useCallback(async () => {
		setLoadingLoans(true)
		setErrorLoans(null)
		let data: LoanGetData | null = null

		try {
			let response: Response | undefined = undefined
			response = await _getLoans(filters)

			if (response && response.ok) {
				const res = await response.json()
				if (res.result.loans && Array.isArray(res.result.loans))
					data = res.result
			} else {
				setErrorLoans('Failed to fetch loans')
			}
		} catch (err) {
			console.error('Error fetching loans:', err)
			setErrorLoans('An error occurred while fetching loans')
		}
		setLoansResult(data)
		setLoadingLoans(false)
	}, [filters])

	useEffect(() => {
		doQuery()
	}, [doQuery])

	return {
		loansResult,
		loadingLoans,
		errorLoans,
		refreshLoans: doQuery,
	}
}

export default useLoans
