import { useCallback, useEffect, useState } from 'react'
import type { ChartOfAccount } from './types'
import { _getChartOfAccountsSettingsByCompanyUUID } from 'state/settings-actions'

function useChartOfAccounts(uuid: string | undefined) {
	const [chartOfAccounts, setChartOfAccounts] = useState<ChartOfAccount[]>([])

	const [loadingChartOfAccounts, setLoading] = useState<boolean>(false)
	const [errorChartOfAccounts, setError] = useState<string | null>(null)

	const doQuery = useCallback(async () => {
		if (!uuid) return
		setLoading(true)
		setError(null)
		let data: ChartOfAccount[] = []

		try {
			const response = await _getChartOfAccountsSettingsByCompanyUUID(uuid)

			if (response && response.ok) {
				const res = await response.json()
				data = res.chartOfAccounts
			} else {
				throw new Error('Response is not ok.')
			}
		} catch (err) {
			console.error('Error fetching billing accounts:', err)
			setError('An error occurred while fetching billing accounts: ' + uuid)
		}
		setChartOfAccounts(data)
		setLoading(false)
	}, [uuid])

	useEffect(() => {
		doQuery()
	}, [doQuery])

	return {
		chartOfAccounts,
		loadingChartOfAccounts,
		errorChartOfAccounts,
		refreshChartOfAccounts: doQuery,
	}
}
export type UseChartOfAccountsResult = ReturnType<typeof useChartOfAccounts>

export default useChartOfAccounts
