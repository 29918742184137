import CreateMessage from './steps/CreateMessage'
import DeliveryMethods from './steps/DeliveryMethods'
import Recipients from './steps/Recipients'
import Bulletins from './steps/Recipients/steps/Bulletins'
import Infoscreen from './steps/Recipients/steps/Infoscreen'
import Personal from './steps/Recipients/steps/Personal'
import Sending from './steps/Sending'
import EmailPreview from './steps/Sending/steps/EmailPreview'
import SMSPreview from './steps/Sending/steps/SMSPreview'
import PrintingAndDistribution from './steps/Sending/steps/postalSettings/PrintingAndDistribution'
import SenderInformation from './steps/Sending/steps/postalSettings/SenderInformation'
import Summary from './steps/Sending/steps/Summary'
import Integrations from './steps/Recipients/steps/Integrations/Integrations'

export const steps = [
	{
		id: 'create',
		label: 'Luo viesti',
		component: CreateMessage,
	},
	{
		id: 'deliveryMethods',
		label: 'Lähetystavat',
		component: DeliveryMethods,
	},
	{
		id: 'recipients',
		label: 'Vastaanottajat',
		component: Recipients,
		childSteps: [
			{
				id: 'personal',
				label: 'Henkilökohtainen',
				component: Personal,
			},
			{
				id: 'bulletins',
				label: 'Viilu-palvelun ilmoitustaulu',
				component: Bulletins,
			},
			{
				id: 'infoscreen',
				label: 'Viilu-porrasnäyttö',
				component: Infoscreen,
			},
			{
				id: 'integrations',
				label: 'Integraatiot',
				component: Integrations,
			},
		],
	},
	{
		id: 'send',
		label: 'Lähetys',
		component: Sending,
		childSteps: [
			{
				id: 'email',
				label: 'Sähköpostin esikatselu',
				component: EmailPreview,
			},
			{
				id: 'sms',
				label: 'Tekstiviestin esikatselu',
				component: SMSPreview,
			},
			{
				id: 'postalSenderInformation',
				label: 'Kirjeiden lähettäjän tiedot',
				component: SenderInformation,
			},
			{
				id: 'postalPrintingAndDistribution',
				label: 'Tulostus ja jakelu',
				component: PrintingAndDistribution,
			},
			{
				id: 'summary',
				label: 'Yhteenveto',
				component: Summary,
			},
		],
	},
]
