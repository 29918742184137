import styled from 'styled-components'
import { Checkbox, Radio } from 'react-md'
import { StepContainer } from 'ui/MessageService/MessageServiceProcess/steps/styled'

const Content = styled.div`
	padding: 0px 32px;
`

function Recipients({ messageServiceProcess }) {
	const showDigitalDeliverySetting =
		messageServiceProcess.deliveryMethods.includes('email') &&
		messageServiceProcess.deliveryMethods.includes('letter')

	return (
		<>
			<StepContainer>
				<Content>
					{showDigitalDeliverySetting && (
						<>
							<p>Sähköinen osakaspostitus</p>
							<Checkbox
								id="checkbox-digital-delivery-consents"
								label={
									'Käytä sähköisen osakaspostituksen suostumusta vähentämään kirjepostin määrää.'
								}
								name="respect-digital-delivery-consent"
								checked={messageServiceProcess.respectDigitalDeliveryConsent}
								disabled
							/>
						</>
					)}

					<p style={{ marginTop: 16 }}>Mistä haluat tuoda vastaanottajat?</p>
					<Radio
						id="radio-recipient-source-viilu"
						label={'Viilu'}
						name="Viilu"
						value="Viilu"
						checked={messageServiceProcess.recipientSource === 'Viilu'}
						disabled
					/>
					<Radio
						id="radio-recipient-source-fivaldi"
						label={'Fivaldi'}
						name="Fivaldi"
						value="Fivaldi"
						checked={messageServiceProcess.recipientSource === 'Fivaldi'}
						disabled
					/>

					<p style={{ marginTop: 16 }}>Kenelle lähetetään?</p>
					<Checkbox
						id="checkbox-recipient-roles-tenants"
						label={'Asukkaat'}
						name="Asukkaat"
						checked={messageServiceProcess.recipientRoles?.user_tenant}
						disabled
					/>
					<Checkbox
						id="checkbox-recipient-roles-shareholders"
						label={'Osakkaat'}
						name="Osakkaat"
						checked={messageServiceProcess.recipientRoles?.user_shareholder}
						disabled
					/>
					{messageServiceProcess.recipientSource === 'Viilu' && (
						<>
							<Checkbox
								id="checkbox-recipient-roles-board"
								label={'Hallitus'}
								name="Hallitus"
								checked={
									messageServiceProcess.recipientRoles.admin_board_member &&
									messageServiceProcess.recipientRoles.admin_board_deputy &&
									messageServiceProcess.recipientRoles.admin_board_director
								}
								disabled
							/>
							<Checkbox
								id="checkbox-recipient-roles-board-director"
								label={'Hallituksen puheenjohtajat'}
								name="Hallituksen puheenjohtajat"
								checked={
									messageServiceProcess.recipientRoles.admin_board_director
								}
								disabled
							/>
							<Checkbox
								id="checkbox-recipient-roles-auditor-operations"
								label={'Toiminnantarkastajat'}
								name="Toiminnantarkastajat"
								checked={
									messageServiceProcess.recipientRoles?.auditor_operations
								}
								disabled
							/>
							<Checkbox
								id="checkbox-recipient-roles-auditor-accounting"
								label={'Tilintarkastajat'}
								name="Tilintarkastajat"
								checked={
									messageServiceProcess.recipientRoles?.auditor_accounting
								}
								disabled
							/>
							<Checkbox
								id="checkbox-recipient-roles-assigned-manager-employee"
								label={'Kohdeisännöitsijä'}
								name="Kohdeisännöitsijä"
								checked={
									messageServiceProcess.recipientRoles
										?.assigned_manager_employee
								}
								disabled
							/>
							<Checkbox
								id="checkbox-recipient-roles-maintenance-company"
								label={'Kohteen huoltoyhtiö'}
								name="Kohteen huoltoyhtiö"
								checked={
									messageServiceProcess.recipientRoles?.maintenance_company
								}
								disabled
							/>
						</>
					)}
				</Content>
			</StepContainer>
		</>
	)
}

export default Recipients
