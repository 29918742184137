import React from 'react'

// Define colors with all six values, e.g. #FFFFFF not #FFF
export const themes = {
	ViiluColorTheme: {
		primary: '#4596EC',
		get primaryLight() {
			return this.primary
		},
		primaryDark: '#096ED6',
		secondary: '#9C3FB9',
		secondaryDark: '#6E0B8D',
		error: '#f5414f',
		destructive: '#c90115',
		lightGrey: '#e6e6e6',
		lightBackground: '#FAFAFA',
	},
}

export const ThemeContext = React.createContext({
	theme: themes.ViiluColorTheme,
	switchTheme: () => {},
})
