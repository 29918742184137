import { IntlShape } from 'react-intl'
import type { LoanShareGroupDetailRow } from '../types'

export const loanShareGroupDetailRow: LoanShareGroupDetailRow[] = [
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Kohde',
				description: 'Title for company location',
			}),
		key: 'companyName',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Huoneiston tunnus',
				description: 'Title for apartment identifier',
			}),
		key: 'identifier',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Osakemäärä',
				description: 'Title for shareholder share count',
			}),
		key: 'shareCount',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Neliöt',
				description: 'Title for squares amount',
			}),
		key: 'squaresAmount',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Jyvitetyt neliöt',
				description: 'Title for weighted squares amount',
			}),
		key: 'weightedSquaresAmount',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Äänimäärä',
				description: 'Title for vote count',
			}),
		key: 'voteCount',
	},
]
