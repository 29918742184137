import { useIntl } from 'react-intl'
import {
	DataTable,
	TableBody,
	TableColumn,
	TableHeader,
	TableRow,
} from 'react-md'
import { formatCurrency, formatDate } from 'util/formatStrings'
import type { InvoiceAllocations } from './types'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'

type Props = {
	invoiceAllocations: InvoiceAllocations[]
	consolidatedUUID?: string
}

function InvoicePaymentsTable({
	invoiceAllocations = [],
	consolidatedUUID,
}: Props) {
	const intl = useIntl()
	const dispatch = useDispatch()
	const currentPath: string | undefined = useSelector(
		({ router }: any) => router?.location?.pathname,
	)

	const navigateToPayment = (uuid: string) => {
		if (!currentPath) {
			return
		}
		let url = `/manager/finance/payments?puuid=${uuid}`
		if (consolidatedUUID) {
			url += `&consolidatedUUID=${consolidatedUUID}`
		}
		dispatch(push(url))
	}

	return (
		<DataTable plain>
			<TableHeader>
				<TableRow>
					<TableColumn>
						{intl.formatMessage({
							defaultMessage: 'Maksaja',
							description: 'Table header for invoice payment payer name.',
						})}
					</TableColumn>
					<TableColumn>
						{intl.formatMessage({
							defaultMessage: 'Arkistotunnus',
							description: 'Table header for invoice payment archive id.',
						})}
					</TableColumn>
					<TableColumn>
						{intl.formatMessage({
							defaultMessage: 'Maksupäivä',
							description: 'Table header for invoice payment date.',
						})}
					</TableColumn>
					<TableColumn>
						{intl.formatMessage({
							defaultMessage: 'Viitenumero',
							description: 'Table header for invoice payment reference number.',
						})}
					</TableColumn>
					<TableColumn>
						{intl.formatMessage({
							defaultMessage: 'Maksajan viite',
							description: 'Table header for invoice payment payer reference.',
						})}
					</TableColumn>
					<TableColumn>
						{intl.formatMessage({
							defaultMessage: 'Summa',
							description: 'Table header for invoice payment total.',
						})}
					</TableColumn>
				</TableRow>
			</TableHeader>
			<TableBody>
				<>
					{invoiceAllocations.map((allocation) => {
						const { uuid, payment, total } = allocation
						const {
							uuid: paymentUUID,
							partyName,
							archiveId,
							paymentDate,
							referenceNumber,
							payerReference,
						} = payment
						return (
							<TableRow
								key={uuid}
								onClick={() => navigateToPayment(paymentUUID)}
							>
								<TableColumn>{partyName}</TableColumn>
								<TableColumn>{archiveId}</TableColumn>
								<TableColumn>{formatDate(paymentDate)}</TableColumn>
								<TableColumn>{referenceNumber}</TableColumn>
								<TableColumn>{payerReference || '-'}</TableColumn>
								<TableColumn>{formatCurrency(total)}</TableColumn>
							</TableRow>
						)
					})}
					<TableRow key={'total'}>
						<TableColumn>
							<b>
								{intl.formatMessage({
									defaultMessage: 'Yhteensä',
									description: 'Table column for invoice payment total.',
								})}
							</b>
						</TableColumn>
						<TableColumn></TableColumn>
						<TableColumn></TableColumn>
						<TableColumn></TableColumn>
						<TableColumn></TableColumn>
						<TableColumn>
							<b>
								{formatCurrency(
									invoiceAllocations.reduce((acc, { total }) => acc + total, 0),
								)}
							</b>
						</TableColumn>
					</TableRow>
				</>
			</TableBody>
		</DataTable>
	)
}

export default InvoicePaymentsTable
