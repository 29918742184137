import React, { useState, useEffect, useRef, useCallback } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { FontIcon } from 'react-md'

const ComponentContainer = styled.div`
	width: 100%;
	position: relative;
	margin: 2px;
	font-size: 1rem;
`
const ItemWidthAndPadding = styled.div`
	width: 100%;
	padding: 10px 15px;
`
const FieldContainer = styled(ItemWidthAndPadding)`
	cursor: pointer;
	border: 1px solid ${(props) => props.theme.lightGrey};
	background-color: ${(props) => props.theme.lightGrey};
	display: grid;
	grid-template-columns: 1fr minmax(20px, auto);
`
const OptionItem = styled(ItemWidthAndPadding)`
	cursor: ${({ readOnly }) => (readOnly ? 'default' : 'pointer')};
	background-color: ${(props) =>
		props.readOnly ? props.theme.lightGrey : 'transparent'};
	&:hover {
		background-color: ${(props) => props.theme.lightGrey};
	}
	padding: ${(props) => (props.noPadding ? 0 : '10px 15px')};
`

const DropdownOptionsContainer = styled.form`
	width: 100%;
	max-height: 240px;
	background-color: white;
	position: absolute;
	overflow: auto;
	z-index: 10;
	border: 1px solid ${(props) => props.theme.lightGrey};
	box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.25);
	visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
`

function useOutsideClickListener(ref, _onOutsideClick) {
	useEffect(() => {
		const handleClickOutside = (event) => {
			if (ref.current && !ref.current.contains(event.target)) {
				_onOutsideClick()
			}
		}

		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [ref, _onOutsideClick])
}

export default function DropdownField(props) {
	const {
		label, // field label
		icon, // icon name string
		options, // array of objects with label and value for dropdown options
		itemLabel = 'label', // string that is used as a dynamic key in each option. This is for the label of the dropdown option
		itemValue = 'value', // string that is used as a dynamic key in each option. This is for the value of the dropdown option
		_onOptionSelect, // call back function on option select. option value is passed
		value, // field value for when redux store is not in use
		reduxStoreValue, // The default value of the field with be set to what is stored in the redux store. _onStoreSelect should then be passed with an action to update the value.
		hasSelectAll, // Boolean. If true, a select all option will be made as first item in options
		nullSelectAll, // Boolean. If true, show all value will be null
	} = props

	const initFieldOptions = useCallback(() => {
		const defaultDropdownOptions = []
		const allValues = []
		const validOptions = options && Array.isArray(options)

		if (validOptions) {
			options.forEach((option) => {
				defaultDropdownOptions.push(option)
				if (hasSelectAll && !nullSelectAll) {
					allValues.push(option[itemValue])
				}
			})
		}

		if (validOptions && hasSelectAll) {
			defaultDropdownOptions.unshift({
				[itemLabel]: 'Näytä kaikki',
				[itemValue]: nullSelectAll ? null : allValues,
				key: 0,
			})
		}

		return defaultDropdownOptions
	}, [hasSelectAll, itemLabel, itemValue, nullSelectAll, options])

	const getDefaultSelectOptionValue = () => {
		if (fieldOptions) {
			if (reduxStoreValue) {
				const optionWithReduxStoreValue = fieldOptions.find((option) => {
					if (typeof reduxStoreValue === 'string')
						return option[itemValue] === reduxStoreValue
					if (Array.isArray(reduxStoreValue)) {
						if (reduxStoreValue.length === 1)
							return option[itemValue] === reduxStoreValue[0]
					}
					return false
				})
				if (optionWithReduxStoreValue) {
					return optionWithReduxStoreValue
				}
			}
			if (value !== null && value !== undefined && value !== '') {
				const matchingValue = fieldOptions.find(
					(option) => option[itemValue] === value,
				)
				return matchingValue
			}

			return fieldOptions[0]
		}
	}

	const [fieldOptions, setFieldOptions] = useState(() => {
		const initialState = initFieldOptions()
		return initialState
	})
	const [selectedOption, setSelectedOption] = useState(() => {
		const initialState = getDefaultSelectOptionValue()
		return initialState
	})
	const [dropdownVisibility, setDropdownVisibility] = useState(false)
	const fieldRef = useRef()
	useOutsideClickListener(fieldRef, () => setDropdownVisibility(false))

	useEffect(() => {
		const newOptions = initFieldOptions()
		setFieldOptions(newOptions)
	}, [options, initFieldOptions])

	const renderOptionItem = (item) => {
		if (!item) return

		return (
			<OptionItem
				onClick={() => {
					setSelectedOption(item)
					_onOptionSelect(item[itemValue])
					setDropdownVisibility(false)
				}}
				key={item[itemValue]}
			>
				{itemLabel ? item[itemLabel] : item.label}
			</OptionItem>
		)
	}

	const renderDropdownOptions = () => {
		const optionsToRender = fieldOptions
		return optionsToRender && optionsToRender.map(renderOptionItem)
	}

	return (
		<ComponentContainer ref={fieldRef}>
			{label && (
				<div className="flex-row">
					<FontIcon>{icon}</FontIcon>
					<p className="text--xs">{label}</p>
				</div>
			)}
			<FieldContainer
				onClick={() => setDropdownVisibility(true)}
				focused={dropdownVisibility}
			>
				<OptionItem readOnly noPadding>
					{selectedOption ? selectedOption[itemLabel] : ' '}
				</OptionItem>
				<FontIcon>arrow_drop_down</FontIcon>
			</FieldContainer>
			<DropdownOptionsContainer visible={dropdownVisibility}>
				{dropdownVisibility && renderDropdownOptions()}
			</DropdownOptionsContainer>
		</ComponentContainer>
	)
}

DropdownField.propTypes = {
	options: PropTypes.array.isRequired,
	itemLabel: PropTypes.string.isRequired,
	itemValue: PropTypes.string.isRequired,
	label: PropTypes.string,
	_onOptionSelect: PropTypes.func,
	hasSelectAll: PropTypes.bool,
	nullSelectAll: PropTypes.bool,
	icon: PropTypes.string,
	reduxStoreValue: PropTypes.string,
	value: PropTypes.any,
}
