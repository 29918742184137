import AwesomeDebouncePromise from 'awesome-debounce-promise'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Button, TextField } from 'react-md'
import styled from 'styled-components'
import DatePickerISO from 'ui/components/DatePickerISO'
import DropdownField from 'ui/components/DropdownField'
import type { InvoiceFilters } from './types'
import type { ApartmentSelectOptions, SelectOptions } from '../types'

const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 16px;
	margin-bottom: 16px;
`

const SearchTextInput = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`

const Filter = styled.div`
	min-width: 160px;
`

type Props = {
	filters: InvoiceFilters
	setFilters: React.Dispatch<React.SetStateAction<InvoiceFilters>>
	apartmentOptions: ApartmentSelectOptions[]
	invoicingOptions: SelectOptions[]
	statusOptions: SelectOptions[]
}

const searchTextDebounced = AwesomeDebouncePromise((func) => {
	func()
}, 1000)

function Filters({
	filters,
	setFilters,
	apartmentOptions,
	invoicingOptions,
	statusOptions,
}: Props) {
	const intl = useIntl()
	const [searchText, setSearchText] = useState<string>()

	useEffect(() => {
		if (searchText !== undefined) {
			searchTextDebounced(() => {
				setFilters((prev) => ({ ...prev, searchText }))
			})
		}
	}, [searchText, setFilters])

	return (
		<Container>
			<Filter>
				<DropdownField
					label={intl.formatMessage({
						defaultMessage: 'Huoneisto',
						description: 'Label for the invoice apartment filter.',
					})}
					itemLabel={'label'}
					itemValue={'value'}
					options={apartmentOptions}
					hasSelectAll={true}
					nullSelectAll={true}
					value={filters.apartmentUUID}
					_onOptionSelect={(value) => {
						setFilters((prev) => ({ ...prev, apartmentUUID: value }))
					}}
				/>
			</Filter>

			<Filter>
				<DatePickerISO
					id="invoices-bill-date-filter"
					label={intl.formatMessage({
						defaultMessage: 'Laskupäivä',
						description: 'Label for the invoice bill date filter.',
					})}
					value={filters.invoiceDate || ''}
					onChange={(isoDate: string) => {
						setFilters((prev) => ({ ...prev, invoiceDate: isoDate }))
					}}
				/>
			</Filter>
			<Filter>
				<DatePickerISO
					id="invoices-due-date-filter"
					label={intl.formatMessage({
						defaultMessage: 'Eräpäivä',
						description: 'Label for the invoice due date filter.',
					})}
					value={filters.dueDate || ''}
					onChange={(isoDate: string) => {
						setFilters((prev) => ({ ...prev, dueDate: isoDate }))
					}}
				/>
			</Filter>

			<Filter>
				<DropdownField
					label={intl.formatMessage({
						defaultMessage: 'Laskutustapa',
						description: 'Label for the invoicing method filter.',
					})}
					itemLabel={'label'}
					itemValue={'value'}
					options={invoicingOptions}
					hasSelectAll={true}
					nullSelectAll={true}
					value={filters.invoicingMethod}
					_onOptionSelect={(value) => {
						setFilters((prev) => ({ ...prev, invoicingMethod: value }))
					}}
				/>
			</Filter>
			<Filter>
				<DropdownField
					label={intl.formatMessage({
						defaultMessage: 'Tila',
						description: 'Label for the invoice status filter.',
					})}
					itemLabel={'label'}
					itemValue={'value'}
					options={statusOptions}
					hasSelectAll={true}
					nullSelectAll={true}
					value={filters.status}
					_onOptionSelect={(value) => {
						setFilters((prev) => ({ ...prev, status: value }))
					}}
				/>
			</Filter>
			<Filter>
				<SearchTextInput>
					<TextField
						id="invoice-search-text-input"
						label={intl.formatMessage({
							defaultMessage: 'Hakusana',
							description: 'Label for the invoice search text filter.',
						})}
						value={searchText || ''}
						onChange={(value) => {
							setSearchText(value.toString())
						}}
					/>
					<Button
						icon
						style={{ marginTop: 16 }}
						onClick={() => {
							setSearchText('')
						}}
					>
						clear
					</Button>
				</SearchTextInput>
			</Filter>
		</Container>
	)
}

export default Filters
