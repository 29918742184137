import React from 'react'
import { List, ListItem, FontIcon, Badge } from 'react-md'
import { PropTypes } from 'prop-types'
import { compose, bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { isLoaded, isEmpty, firestoreConnect } from 'react-redux-firebase'
import Avatar from 'react-avatar'
import { push } from 'connected-react-router'
import styled from 'styled-components'

import * as appUIActions from 'state/ui/app-ui-actions'

const ChevronIcon = () => <FontIcon>chevron_right</FontIcon>

const Footer = styled.div`
	display: flex;
	flex-direction: row;
	padding: 16px;
	align-items: center;
	justify-content: center;
`
const FooterLink = styled.a`
	font-size: 10px;
	text-decoration: none;
	color: rgba(0, 0, 0, 0.6);
	cursor: pointer;
	:visited {
		color: rgba(0, 0, 0, 0.6);
	}
`
const FooterBulletin = styled.p`
	font-size: 10px;
	margin-left: 8px;
	margin-right: 8px;
	color: lightgray;
`

const YouMenu = ({
	user,
	mentions,
	intl,
	onHide,
	beamerUnreadPostsCount,
	_push,
	_setLanguageDialogVisible,
}) => {
	const name =
		user.displayName ||
		intl.formatMessage({
			defaultMessage: 'NIMI PUUTTUU',
			description: 'Placeholder text displayed when user name is not found',
		})
	const email = user.email
	const avatarName = (user.displayName || '?').toUpperCase()

	const openProfile = () => {
		onHide()
		if (window.location.pathname.includes('/manager')) {
			return _push('/manager/usersettings#profile')
		}
		_push('/internal/usersettings#profile')
	}

	const openNotificationCenter = () => {
		onHide()
		if (window.location.pathname.includes('/manager')) {
			return _push('/manager/notifications')
		}
		_push('/internal/notifications')
	}

	const openApartments = () => {
		onHide()
		if (window.location.pathname.includes('/manager')) {
			return _push('/manager/usersettings#apartments')
		}
		_push('/internal/usersettings#apartments')
	}

	const openCompanies = () => {
		onHide()
		if (window.location.pathname.includes('/manager')) {
			return _push('/manager/usersettings#company')
		}
		_push('/internal/usersettings#company')
	}

	const openLanguages = () => {
		onHide()
		_setLanguageDialogVisible(true)
	}

	const openInfo = () => {
		onHide()
		_push('/internal/info')
	}

	let notificationCount = 0
	if (isLoaded(mentions) && !isEmpty(mentions)) {
		notificationCount = mentions.filter((m) => !m.read).length
	}
	if (beamerUnreadPostsCount) {
		notificationCount += beamerUnreadPostsCount
	}

	return (
		<div className="flex-column full-height">
			<List>
				<ListItem
					primaryText={name}
					secondaryText={email}
					leftIcon={
						<Avatar
							name={avatarName}
							size={24}
							round
							style={{ marginTop: -8, marginRight: -20 }}
						/>
					}
					rightIcon={<ChevronIcon />}
					onClick={openProfile}
				/>
			</List>
			<List style={{ overflowY: 'auto', height: '100%', maxHeight: '48vh' }}>
				<ListItem
					primaryText={
						<FormattedMessage
							defaultMessage="Ilmoituskeskus"
							description="Notification center button displayed in the user menu"
						/>
					}
					leftIcon={
						<Badge
							badgeContent={notificationCount}
							primary
							invisibleOnZero
							badgeId="user-notifications"
							badgeStyle={{
								transform: 'translate(20px, 1px) scale(0.65)',
								pointerEvents: 'none',
								userSelect: 'none',
								background: 'var(--color-secondary)',
							}}
							text
						>
							<FontIcon style={{ marginRight: -16 }}>notifications</FontIcon>
						</Badge>
					}
					onClick={openNotificationCenter}
					className="md-divider-border md-divider-border--top"
				/>
				<ListItem
					primaryText={
						<FormattedMessage
							defaultMessage="Huoneistosi"
							description="Button that takes the user to their apartment page"
						/>
					}
					leftIcon={<FontIcon style={{ marginRight: -16 }}>apartment</FontIcon>}
					onClick={openApartments}
					className="md-divider-border md-divider-border--top"
				/>
				<ListItem
					primaryText={
						<FormattedMessage
							defaultMessage="Valitse taloyhtiö"
							description="Button that takes the user to the housing company selection page"
						/>
					}
					leftIcon={<FontIcon style={{ marginRight: -16 }}>home</FontIcon>}
					onClick={openCompanies}
					className="md-divider-border md-divider-border--top"
				/>
				{
					// Language selection is handled by the native app
					!window.isNativeApp ? (
						<ListItem
							primaryText={
								<FormattedMessage
									defaultMessage="Kieliasetukset"
									description="Button that takes the user to the language selection page"
								/>
							}
							leftIcon={
								<FontIcon style={{ marginRight: -16 }}>language</FontIcon>
							}
							onClick={openLanguages}
							className="md-divider-border md-divider-border--top"
						/>
					) : null
				}
				<ListItem
					primaryText={
						<FormattedMessage
							defaultMessage="Palvelun versiotiedot"
							description="Button that takes the user to the version page"
						/>
					}
					leftIcon={<FontIcon style={{ marginRight: -16 }}>info</FontIcon>}
					onClick={openInfo}
					className="md-divider-border md-divider-border--top"
				/>
			</List>
			<Footer>
				<FooterLink
					href="https://vii.lu/privacy"
					target="_blank"
					onClick={() => window.open('https://vii.lu/privacy', 'blank_')}
				>
					<FormattedMessage
						defaultMessage="Tietosuojakäytäntö"
						description="Link that takes the user to the privacy policy page"
					/>
				</FooterLink>
				<FooterBulletin>•</FooterBulletin>
				<FooterLink
					href="https://vii.lu/tos"
					target="_blank"
					onClick={() => window.open('https://vii.lu/tos', 'blank_')}
				>
					<FormattedMessage
						defaultMessage="Käyttöehdot"
						description="Link that takes the user to the terms of service page"
					/>
				</FooterLink>
			</Footer>
		</div>
	)
}

YouMenu.propTypes = {
	user: PropTypes.object.isRequired,
	onHide: PropTypes.func.isRequired,
	_push: PropTypes.func.isRequired,
	_setLanguageDialogVisible: PropTypes.func.isRequired,
}

const mapState = ({
	ui: {
		app: { beamerUnreadPostsCount },
	},
	firebaseReducer: { auth },
	firestoreReducer: {
		ordered: { mention },
	},
}) => ({
	beamerUnreadPostsCount,
	auth,
	mentions: mention,
})

const mapDispatchToProps = (dispatch) => ({
	_push: bindActionCreators(push, dispatch),
	_setLanguageDialogVisible: bindActionCreators(
		appUIActions._setLanguageDialogVisible,
		dispatch,
	),
})

export default compose(
	injectIntl,
	connect(mapState, mapDispatchToProps),
	firestoreConnect(({ auth }) =>
		auth.uid
			? [
					{
						collection: 'mention',
						where: [['mentionedUserUID', '==', auth.uid]],
						orderBy: ['createdAt', 'desc'],
						limit: 100,
					},
			  ]
			: [],
	),
)(YouMenu)
