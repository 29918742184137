import styled from 'styled-components'
import { Toolbar, Button } from 'react-md'
import { StandardCard } from './Cards'
import { breakpoint } from './MediaBreakpoints'

export const AdjustedPaddingMdButton = styled(Button)`
	padding: 5px 15px;
`
export const FullWidthRoundedCard = styled(StandardCard)`
	width: 100%;
	overflow: hidden;
	padding: 10px;
`
export const FullWidthCenteredContent = styled.div`
	width: 100%;
	display: grid;
	place-items: center;
`
export const DividedListItem = styled.div`
	border-bottom: 1px solid ${(props) => props.theme.lightGrey};
	&:last-child {
		border-bottom: none;
	}
`
export const NoWrapTextSpan = styled.span`
	max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : '100%')};
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`
export const StandardDialogContent = styled.section`
	padding: 24px;
	.md-panel-header {
		padding: 0;
	}
	@media ${breakpoint.phone} {
		padding: 16px;
	}
`
export const StandardLabel = styled.p`
	font-size: 16.8px;
	color: rgba(0, 0, 0, 0.6);
`
export const Subtext = styled.span`
	font-size: 0.7em;
	color: rgba(0, 0, 0, 0.5);
`
export const StandardToolbar = styled(Toolbar)`
	display: flex;
	align-items: center;
	background: ${(props) =>
		props.secondary
			? 'var(--color-secondary-dark)'
			: 'var(--color-primary-dark)'};
	z-index: 50;
	position: fixed;
	width: 100%;
	top: 0;
	h2 {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		margin-left: 0;
		padding: 0 15px;
		color: white;
	}
	i {
		color: white !important;
	}
`

export const StyledAnchor = styled.a`
	${(props) => !props.disabled && 'cursor: pointer'};
	color: ${(props) =>
		props.disabled ? 'rgba(0,0,0,0.2)' : props.theme.primary};
`
