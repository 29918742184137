import { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import {
	Button,
	CircularProgress,
	DataTable,
	TableBody,
	TableColumn,
	TableHeader,
	TableRow,
} from 'react-md'
import { useSelector } from 'react-redux'
import { _updateInvoicesByCompanyUUID } from 'state/finance-invoice-actions'
import styled from 'styled-components'
import useDialogs from 'ui/components/dialogs/useDialogs'
import useInvoiceLocks from './useInvoiceLocks'
import { YES_NO_MESSAGE } from 'ui/messages'
import { formatTimestamp } from '../utils/utils'

const Container = styled.div`
	padding: 16px;
`

const Horizontal = styled.div`
	display: flex;
	gap: 16px;
	margin-bottom: 16px;
	align-items: flex-end;
`

const InvoiceActions = () => {
	const intl = useIntl()
	const { alert } = useDialogs()
	const selectedFinanceCompanyUUID = useSelector(
		({ app }: any) => app?.selectedFinanceCompanyUUID,
	)
	const locale = useSelector(({ app }: any) => app?.selectedLocale)

	const { data: invoiceLocks } = useInvoiceLocks(selectedFinanceCompanyUUID)
	const [processing, setProcessing] = useState(false)

	const updateInvoices = async () => {
		setProcessing(true)
		try {
			const response = await _updateInvoicesByCompanyUUID(
				selectedFinanceCompanyUUID,
			)
			if (!response?.ok) {
				const errorData = await response?.json()
				if (errorData.message === 'Invoices by company uuid is locked.') {
					const errorMSG = intl.formatMessage({
						defaultMessage: 'Laskujen päivitys on jo käynnissä.',
						description: 'Invoice update is already in progress.',
					})
					alert(errorMSG)
				} else {
					const errorMSG = intl.formatMessage({
						defaultMessage: 'Jotain meni pieleen päivittäessä.',
						description: 'Something went wrong while updating invoices.',
					})
					alert(errorMSG)
				}

				throw new Error('Response is not ok.')
			}
			const successMSG = intl.formatMessage({
				defaultMessage: 'Laskut on päivitetty',
				description: 'Invoices has been successfully updated.',
			})
			alert(successMSG)
		} catch (error) {
			console.error(error)
		}
		setProcessing(false)
	}

	const renderInvoiceLocksTable = () => {
		if (!invoiceLocks || !invoiceLocks.length) {
			return (
				<p>
					{intl.formatMessage({
						defaultMessage: 'Laskujen päivitys historiaa ei löytynyt.',
						description: 'Message for empty invoice update history list.',
					})}
				</p>
			)
		}

		return (
			<Container>
				<DataTable plain>
					<TableHeader>
						<TableRow>
							<TableColumn>
								{intl.formatMessage({
									defaultMessage: 'Aloitettu',
									description: 'Table column header update start time.',
								})}
							</TableColumn>
							<TableColumn>
								{intl.formatMessage({
									defaultMessage: 'Valmistunut',
									description: 'Table column header for update end time.',
								})}
							</TableColumn>
							<TableColumn>
								{intl.formatMessage({
									defaultMessage: 'Käynnissä',
									description: 'Table column header for update ongoing.',
								})}
							</TableColumn>
						</TableRow>
					</TableHeader>
					<TableBody>
						{invoiceLocks
							.sort((a, b) => {
								if (a.locked && !b.locked) return -1
								if (!a.locked && b.locked) return 1
								return (
									new Date(b.updatedAt).getTime() -
									new Date(a.updatedAt).getTime()
								)
							})
							.map((invoiceLock) => {
								const { uuid, createdAt, updatedAt, locked } = invoiceLock
								return (
									<TableRow key={uuid}>
										<TableColumn>
											{formatTimestamp(createdAt, locale)}
										</TableColumn>
										<TableColumn>
											{locked ? '' : formatTimestamp(updatedAt, locale)}
										</TableColumn>
										<TableColumn>{YES_NO_MESSAGE(intl, locked)}</TableColumn>
									</TableRow>
								)
							})}
					</TableBody>
				</DataTable>
			</Container>
		)
	}

	return (
		<Container>
			<Horizontal>
				<Button raised primary onClick={() => !processing && updateInvoices()}>
					<FormattedMessage
						defaultMessage="Päivitä laskut"
						description="Button text to update invoices"
					/>
				</Button>
				{processing && (
					<CircularProgress
						style={{ margin: 0 }}
						id="update-invoices-progress"
					/>
				)}
			</Horizontal>
			{renderInvoiceLocksTable()}
		</Container>
	)
}

export default InvoiceActions
