import MassSendingProcess from '../MassSendingProcess'
import ViiluDialog from 'ui/components/ViiluDialog'

function MassSendingDialog({ messageServiceMassProcess, onHide }) {
	return (
		<ViiluDialog
			id="message-service-mass-dialog"
			visible={true}
			fullPage
			onHide={onHide}
			closeOnEsc={false}
			focusOnMount={false}
		>
			<MassSendingProcess
				onHide={onHide}
				messageServiceMassProcess={messageServiceMassProcess}
			/>
		</ViiluDialog>
	)
}

export default MassSendingDialog
