export const generateQueryParams = (filters: Record<string, any>): string => {
	const searchParams = new URLSearchParams()

	Object.entries(filters).forEach(([key, value]) => {
		if (value === '' || value === undefined || value === null) {
			return
		}
		let paramValue = ''
		if (value instanceof Date) paramValue = value.toISOString()
		else if (typeof value === 'object') paramValue = JSON.stringify(value)
		else paramValue = String(value)

		searchParams.append(key, paramValue)
	})

	const queryString = searchParams.toString()

	return queryString ? `?${queryString}` : ''
}
