import { TableRow, TableColumn, MenuButton, CircularProgress } from 'react-md'
import { invoiceHeaders } from './constants'
import type { Invoice } from './types'
import { useIntl } from 'react-intl'
import { getInvoiceRowValue, getPaymentsTotalStyle } from './utils'
import type { ApartmentSelectOptions } from '../types'

interface Props {
	invoice: Invoice
	menuItems: any[] | null
	processing: boolean
	navigateToInvoice: (uuid: string) => void
	apartmentOptions: ApartmentSelectOptions[]
}

const InvoiceRow = ({
	invoice,
	menuItems,
	processing,
	navigateToInvoice,
	apartmentOptions,
}: Props) => {
	const { uuid } = invoice
	const intl = useIntl()
	const appendedMenuItems = menuItems ? [...menuItems] : null

	const menu = !appendedMenuItems ? null : (
		<MenuButton
			id={'menu-button'}
			icon
			menuItems={appendedMenuItems}
			centered
			anchor={{
				x: MenuButton.HorizontalAnchors['CENTER'],
				y: MenuButton.VerticalAnchors['CENTER'],
			}}
		>
			more_vert
		</MenuButton>
	)

	const renderTableColumns = () => {
		return invoiceHeaders
			.filter((h) => h.show)
			.map((header) => {
				const { key, type, notClickable, style } = header
				return (
					<TableColumn
						onClick={() =>
							!notClickable && !processing && navigateToInvoice(uuid)
						}
						key={key}
						style={{
							...(style === 'getPaymentsTotalStyle'
								? getPaymentsTotalStyle(invoice)
								: {}),
							...(processing
								? { opacity: 0.5 }
								: { cursor: !notClickable ? 'pointer' : 'default' }),
						}}
					>
						<p>
							{getInvoiceRowValue(invoice, key, apartmentOptions, intl, type)}
						</p>
					</TableColumn>
				)
			})
	}

	return (
		<TableRow key={uuid}>
			{renderTableColumns()}
			<TableColumn key={'children'}>
				<div>
					{processing ? (
						<CircularProgress id="progress" style={{ marginTop: 0 }} />
					) : (
						menu
					)}
				</div>
			</TableColumn>
		</TableRow>
	)
}

export default InvoiceRow
