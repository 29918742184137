import moment from 'moment'
import { MenuButton, TableColumn, TableRow, CircularProgress } from 'react-md'
import type { ValueAddedTax } from './types'
import { valueAddedTaxTableHeaders } from './constants'

type Props = {
	entity: ValueAddedTax
	menuItems: any[] | null
	children: any
	processing: any
	handleOnClick: () => void
}

const ValueAddedTaxTableRow = ({
	entity,
	menuItems,
	children,
	processing,
	handleOnClick,
}: Props) => {
	const appendedMenuItems = menuItems ? [...menuItems] : null

	const menu = !appendedMenuItems ? null : (
		<MenuButton
			id={'menu-button'}
			icon
			menuItems={appendedMenuItems}
			centered
			anchor={{
				x: MenuButton.HorizontalAnchors['CENTER'],
				y: MenuButton.VerticalAnchors['CENTER'],
			}}
		>
			more_vert
		</MenuButton>
	)
	const getRowValue = (key: string, type: string | undefined) => {
		let value = entity[key]
		if (type && type === 'date') {
			value =
				entity.percentages.find(
					(p) =>
						new Date(p.startDate as string).getTime() <= new Date().getTime() &&
						(!p.endDate ||
							new Date(p.endDate as string).getTime() >= new Date().getTime()),
				)?.[key] ?? ''
			const parsedDate = moment(value)
			if (!parsedDate.isValid()) return ''
			return parsedDate.format('DD.MM.YYYY')
		}
		if (key && key === 'percentage') {
			value =
				entity.percentages.find(
					(p) =>
						new Date(p.startDate as string).getTime() <= new Date().getTime() &&
						(!p.endDate ||
							new Date(p.endDate as string).getTime() >= new Date().getTime()),
				)?.[key] ?? ''
		}
		return value
	}

	const renderTableColumns = () => {
		return valueAddedTaxTableHeaders
			.filter((h) => h.show)
			.map((header) => {
				return (
					<TableColumn onClick={handleOnClick} key={header.key}>
						<p>{getRowValue(header.key, header?.type)}</p>
					</TableColumn>
				)
			})
	}

	return (
		<TableRow key={entity.uuid}>
			{renderTableColumns()}
			<TableColumn key={'children'}>
				<div>
					{processing ? (
						<CircularProgress id="progress" style={{ marginTop: 0 }} />
					) : (
						menu
					)}
					{children}
				</div>
			</TableColumn>
		</TableRow>
	)
}

export default ValueAddedTaxTableRow
