import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import 'styles.css'
import * as serviceWorker from './serviceWorker'
import { Provider } from 'react-redux'
import store, { firebaseApp, history } from './state/store'
import { Route, Switch } from 'react-router'
import { ConnectedRouter } from 'connected-react-router'
import ReactGA from 'react-ga4'
import * as notifyActions from './state/notifyActions'
import * as Sentry from '@sentry/browser'
import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react'
import FallbackComponent from './ui/components/ErrorBoundary/GenericErrorBoundary'
import matchAll from 'string.prototype.matchall'
//import initRemoteConfig from './remoteConfig'
import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import { createFirestoreInstance } from 'redux-firestore'
import firebase from 'firebase/compat/app' // react-redux-firebase doesn't support firebase sdk v9

import routes from './routes'
import App from './App'
import GlobalStyles from './ui/StyledComponents/GlobalStyles'
import ChunkErrorBoundary from './ui/components/ErrorBoundary/ChunkErrorBoundary'
import LoadingState from './ui/components/LoadingState'
import NetworkErrorBoundary from './ui/components/ErrorBoundary/NetworkErrorBoundary'
import { SnackbarProvider } from 'notistack'
import Site from 'site/Site'
import { getAuth } from 'firebase/auth'
import DialogProvider from './ui/components/dialogs/DialogProvider'
import { registerRunAfterCookieConsentAcceptCallback } from 'util/cookieConsent'
import { isIosDevice } from 'util/platform'

// Redirect from old domain
if (window.location.hostname === 'app.taloyht.io') {
	window.location.href = window.location.href.replace('taloyht.io', 'vii.lu')
}

// Redirect to correct route if infoscreen
if (
	window.location.hostname === 'viilu.tv' &&
	!window.location.pathname.includes('/internal/infoscreen')
) {
	window.location.href = 'https://viilu.tv/internal/infoscreen'
}

const isOnInfoscreenPage =
	window.location.hostname === 'viilu.tv' ||
	window.location.pathname === '/internal/infoscreen' ||
	window.location.pathname === '/internal/infoscreen/'

// Enable cookiebot's cookie-consent banner only if this client is not an infoscreen
// EDIT 09/2024: disabling also on iOS due to an issue where the user is redirected to a blank,
// non-interactive Safari page (https://consentcdn.cookiebot.com/sdk/bc-v4.min.html]).
// We should implement a proper fix asap.
if (!isOnInfoscreenPage && !isIosDevice()) {
	const cookieBotScriptEl = document.createElement('script')

	cookieBotScriptEl.setAttribute('id', 'Cookiebot')
	cookieBotScriptEl.setAttribute('src', 'https://consent.cookiebot.com/uc.js')
	cookieBotScriptEl.setAttribute(
		'data-cbid',
		'e6eeeb2e-c752-4329-936f-b92a3fbcf7e8',
	)
	cookieBotScriptEl.setAttribute('type', 'text/javascript')

	document.body.appendChild(cookieBotScriptEl)
}

// Guard from IE
const checkIE = () => {
	var ua = window.navigator.userAgent
	var msie = ua.indexOf('MSIE ')

	// If Internet Explorer, return version number
	if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./)) {
		// eslint-disable-line
		alert(
			'Viilu-palvelu ei tue Internet Explorer selainta! Käytä Microsoft Edgeä, Google Chromea, Mozilla Firefoxia, tai Apple Safaria.',
		)
	}
}

checkIE()

// Shim string.matchAll
matchAll.shim()

function registerAnalytics() {
	console.log('Registering analytics')

	// Analytics
	ReactGA.initialize('G-17XM6MYRJ6', {})

	window.addEventListener('beforeinstallprompt', (event) => {
		ReactGA.event({
			category: 'pwa',
			action: 'install-prompt',
		})

		event.userChoice.then(function (platform, outcome) {
			ReactGA.event({
				category: 'pwa',
				action: 'install-prompt-outcome',
				value: outcome,
			})
		}, console.log)
	})

	// Crash reporting
	Sentry.init({
		dsn: 'https://86e1803eafc446f7913b53d0f3032114@sentry.io/2381150',
	})
	window.sentry = Sentry
}

registerRunAfterCookieConsentAcceptCallback(() => registerAnalytics())

// Remote config
// Disabled for now (24.11.20) because the doubts of crashes
//initRemoteConfig()

// react-redux-firebase config
const rrfProps = {
	firebase,
	config: {
		useFirestoreForProfile: true, // Firestore for Profile instead of Realtime DB
		attachAuthIsReady: true, // attaches auth is ready promise to store
		firebaseStateName: 'firebase', // should match the reducer name ('firebase' is default)
		preserveOnDelete: null,
	},
	dispatch: store.dispatch,
	createFirestoreInstance,
}

const createRouteElement = (route) => (
	<Route
		key={route.path}
		exact={route.exact}
		path={route.path}
		component={route.component}
	/>
)
const routeElements = routes.map(createRouteElement)

serviceWorker.register({
	onRegister: (registration) => {},
	onUpdate: (_, newWorker) => {
		let refreshing
		// The event listener that is fired when the service worker updates
		// Here we reload the page
		navigator.serviceWorker.addEventListener('controllerchange', function () {
			if (refreshing) return
			window.location.reload(true)
			refreshing = true
		})
		notifyActions.info('Otetaan päivitystä käyttöön...')(store.dispatch)
		newWorker.postMessage({ action: 'skipWaiting' })
	},
	onDisconnected: () => {
		notifyActions.info('Tarkista internetyhteytesi.')(store.dispatch)
	},
})

const renderSite =
	window.location.hostname === 'vii.lu' ||
	window.location.hostname === 'localhost.vii.lu' ||
	window.location.hostname === 'taloyht.io' ||
	window.location.hostname === 'viilu.com' ||
	window.location.hostname === 'viilu.fi'

ReactDOM.render(
	<SentryErrorBoundary
		fallback={FallbackComponent}
		showDialog
		dialogOptions={{
			dsn: 'https://86e1803eafc446f7913b53d0f3032114@sentry.io/2381150',
			user: getAuth(firebaseApp).currentUser || { name: '', email: '' },
			lang: 'fi',
			title: 'Näyttää siltä että meillä on tekninen ongelma',
			subtitle: 'Tieto ongelmasta on välitetty eteen päin tiimillemme.',
			subtitle2: 'Kerrothan meille ihan lyhyesti mikä johti virheeseen?',
			labelSubmit: 'Lähetä',
			labelClose: 'Sulje',
			successMessage: 'Lisätiedot on lähetetty tiimillemme. Kiitos!',
			errorFormEntry: 'Jotkin lomakkeen kentät ovat puutteellisia.',
			errorGeneric: 'Lisätietoja lähettäessä tapahtui tuntematon virhe.',
		}}
	>
		<NetworkErrorBoundary>
			<ChunkErrorBoundary>
				<Provider store={store}>
					<ReactReduxFirebaseProvider {...rrfProps}>
						<SnackbarProvider
							maxSnack={3}
							preventDuplicate
							autoHideDuration={3000}
						>
							<ConnectedRouter history={history}>
								<Suspense fallback={<LoadingState />}>
									<DialogProvider>
										{renderSite ? (
											<Site />
										) : (
											<App>
												<GlobalStyles />
												<Switch>{routeElements}</Switch>
											</App>
										)}
									</DialogProvider>
								</Suspense>
							</ConnectedRouter>
						</SnackbarProvider>
					</ReactReduxFirebaseProvider>
				</Provider>
			</ChunkErrorBoundary>
		</NetworkErrorBoundary>
	</SentryErrorBoundary>,
	document.getElementById('root'),
)
