import { FormattedMessage } from 'react-intl'
import { Checkbox } from 'react-md'
import styled from 'styled-components'
import { StepContainer } from 'ui/MessageService/MessageServiceProcess/steps/styled'

const Content = styled.div`
	max-width: 600px;
	margin: 40px auto;
`

const DatePickerContainer = styled.div`
	margin-bottom: 32px;
	display: flex;
	align-items: center;
`

function Bulletins({
	messageServiceProcess: {
		bulletinsSettings: { publishTs, expirationTs, distributionOptions },
	},
}) {
	const formatTs = (ts) => {
		if (!ts) {
			return ''
		}

		return new Date(ts).toLocaleString('fi', {
			dateStyle: 'medium',
			timeStyle: 'short',
		})
	}

	return (
		<>
			<StepContainer>
				<Content>
					<p style={{ marginBottom: 16 }}>
						<FormattedMessage
							defaultMessage="Julkaisupäivä"
							description="Header of a section in the message service where the user can select the bulletin publish time."
						/>
					</p>
					<DatePickerContainer>
						<p style={{ fontSize: 14 }}>
							{publishTs ? (
								formatTs(publishTs)
							) : (
								<FormattedMessage
									defaultMessage="Julkaistaan heti."
									description="Label in the message service for bulletin publish time if it has not been selected."
								/>
							)}
						</p>
					</DatePickerContainer>
					<p style={{ marginBottom: 16 }}>
						<FormattedMessage
							defaultMessage="Poistumispäivä"
							description="Header of a section in the message service where the user can select the bulletin expiration time."
						/>
					</p>
					<DatePickerContainer>
						<p style={{ fontSize: 14 }}>
							{expirationTs ? (
								formatTs(expirationTs)
							) : (
								<FormattedMessage
									defaultMessage="Ei poistumispäivää."
									description="Label in the message service for bulletin expiration time if it has not been selected."
								/>
							)}
						</p>
					</DatePickerContainer>

					<p style={{ marginBottom: 16 }}>
						<FormattedMessage
							defaultMessage="Näkyvyys ilmoitustaululla"
							description="Header of a section in the message service where the user can select which user groups can see the bulletin on the bulletin board."
						/>
					</p>
					<Checkbox
						id="checkbox-bulletins-tenants"
						label={
							<FormattedMessage
								defaultMessage="Asukkaille"
								description="Checkbox allowing the bulletin to be targeted to tenants."
							/>
						}
						name="Asukkaille"
						checked={distributionOptions.user_tenant}
						value={distributionOptions.user_tenant}
						onChange={() => {}}
						style={{ marginBottom: 16 }}
						disabled
					/>
					<Checkbox
						id="checkbox-bulletins-shareholders"
						label={
							<FormattedMessage
								defaultMessage="Osakkaille"
								description="Checkbox allowing the bulletin to be targeted to shareholders."
							/>
						}
						name="Osakkaille"
						checked={distributionOptions.user_shareholder}
						onChange={() => {}}
						style={{ marginBottom: 16 }}
						disabled
					/>
					<Checkbox
						id="checkbox-bulletins-board"
						label={
							<FormattedMessage
								defaultMessage="Hallitukselle"
								description="Checkbox allowing the bulletin to be targeted to the board members."
							/>
						}
						name="Hallitukselle"
						checked={distributionOptions.admin}
						onChange={() => {}}
						style={{ marginBottom: 16 }}
						disabled
					/>
				</Content>
			</StepContainer>
		</>
	)
}

export default Bulletins
