import { injectIntl } from 'react-intl'
import { FontIcon } from 'react-md'
import { compose } from 'redux'
import styled from 'styled-components'
import {
	Binding,
	colorOptions,
	plexOptions,
	postalClassOptions,
} from '../../options'
import { SummaryList, SummaryListItem } from './styles'

const Banner = styled.div`
	display: flex;
	align-items: center;
	background: #e19e19;
	border-radius: 8px;
	padding: 8px;
	margin: 16px 0;

	.md-icon  {
		color: #fff;
	}

	span {
		color: #fff;
		margin-left: 8px;
		font-size: 1rem;
	}
`
const findOptionLabel = (value, options) => {
	return options.find((o) => o.value === value).label
}

function PostalSummary({ letterCount, letterPageCount, postalSettings, intl }) {
	const pagesToPrint = letterCount * letterPageCount

	const sheetsAfterPrinting = Math.ceil(pagesToPrint / 2)

	const afterPageCountCalculated = (text) => {
		return letterCount === 0 || letterPageCount ? (
			<span>{text}</span>
		) : (
			<span>
				<i>
					{intl.formatMessage({
						defaultMessage: 'Lasketaan sivumäärää...',
						description:
							'Loading indicator text shown until the page count has been calculated in the message service summary step.',
					})}
				</i>
			</span>
		)
	}

	return (
		<>
			<h2>
				{intl.formatMessage({
					defaultMessage: 'Postituspalvelu (maksullinen)',
					description:
						'Heading for the postal section in the message service summary step.',
				})}
			</h2>

			<SummaryList>
				{letterCount > 0 && (
					<>
						<SummaryListItem>
							<FontIcon>email</FontIcon>
							<span>
								{letterCount === 1
									? intl.formatMessage(
											{
												defaultMessage: '{letterCount} kirje',
												description:
													'Singular version of the letter count label in the message service summary step.',
											},
											{
												letterCount,
											},
									  )
									: intl.formatMessage(
											{
												defaultMessage: '{letterCount} kirjettä',
												description:
													'Plural version of the letter count label in the message service summary step.',
											},
											{
												letterCount,
											},
									  )}
							</span>
						</SummaryListItem>

						<SummaryListItem>
							<FontIcon>description</FontIcon>
							{afterPageCountCalculated(
								pagesToPrint === 1
									? intl.formatMessage(
											{
												defaultMessage: '{pagesToPrint} tulostettava sivu',
												description:
													'Singular version of the pages to print label in the message service summary step.',
											},
											{
												pagesToPrint,
											},
									  )
									: intl.formatMessage(
											{
												defaultMessage: '{pagesToPrint} tulostettavaa sivua',
												description:
													'Plural version of the pages to print label in the message service summary step.',
											},
											{
												pagesToPrint,
											},
									  ),
							)}
						</SummaryListItem>
					</>
				)}
				<SummaryListItem>
					<FontIcon>print</FontIcon>
					{postalSettings.binding === Binding.BOOKLET ? (
						<span>
							{intl.formatMessage({
								defaultMessage: 'Vihkosidonta',
								description:
									'Label for the postal printing binding selection input.',
							})}
						</span>
					) : (
						afterPageCountCalculated(
							`${findOptionLabel(postalSettings.plex, plexOptions(intl))} ${
								letterCount > 0
									? `(${
											sheetsAfterPrinting === 1
												? intl.formatMessage(
														{
															defaultMessage: '{sheetsAfterPrinting} arkki',
															description:
																'Singular version of the sheets after printing label in the message service summary step.',
														},
														{
															sheetsAfterPrinting,
														},
												  )
												: intl.formatMessage(
														{
															defaultMessage: '{sheetsAfterPrinting} arkkia',
															description:
																'Plural version of the sheets after printing label in the message service summary step.',
														},
														{
															sheetsAfterPrinting,
														},
												  )
									  })`
									: ''
							}`,
						)
					)}
				</SummaryListItem>

				<SummaryListItem>
					<FontIcon>palette</FontIcon>
					<span>
						{findOptionLabel(postalSettings.color, colorOptions(intl))}
					</span>
				</SummaryListItem>

				<SummaryListItem>
					<FontIcon>local_shipping</FontIcon>
					<span>
						{findOptionLabel(
							postalSettings.postalClass,
							postalClassOptions(intl),
						).replace('*', '')}
					</span>
				</SummaryListItem>
			</SummaryList>

			<p>
				{intl.formatMessage({
					defaultMessage:
						'Postitse lähetettävät kirjeet siirtyvät postituspalveluun heti vahvistettuasi lähetyksen. ' +
						'Postituksen tilaa voi seurata myöhemmin tästä näkymästä.',
					description:
						'Informational text about the postal process in the message service summary step.',
				})}
			</p>

			<Banner>
				<FontIcon>priority_high</FontIcon>
				<span>
					{intl.formatMessage({
						defaultMessage:
							'Kirjeet lähetetään Viilun maksullisella postituspalvelulla. Käyttö veloitetaan sopimuksen mukaisesti.',
						description:
							'Banner text about the postal pricing in the message service summary step.',
					})}
				</span>
			</Banner>
		</>
	)
}

export default compose(injectIntl)(PostalSummary)
