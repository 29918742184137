export default function notifications(state = [], action) {
	switch (action && action.type) {
		case 'ADD_NOTIFICATION':
			return state.concat(action.notification)
		case 'DISMISS_NOTIFICATION':
			return state.map((notification) =>
				action.dismissAll || notification.key === action.key
					? { ...notification, dismissed: true }
					: { ...notification },
			)
		case 'REMOVE_NOTIFICATION':
			return state.filter((notification) => notification.key !== action.key)
		default:
			return state
	}
}
