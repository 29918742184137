// eslint-disable-next-line no-unused-vars
import { injectIntl } from 'react-intl' // Needs to be imported for it to be picked up by the tooling

export const MALFORMED_EMAIL_MESSAGE = (intl) =>
	intl.formatMessage({
		defaultMessage: 'Syöttämäsi sähköpostiosoite on muodoltaan virheellinen.',
		description:
			'Error message telling the user that the typed email is malfomed.',
	})

export const USER_DISABLED_MESSAGE = (intl) =>
	intl.formatMessage({
		defaultMessage:
			'Tämä käyttäjätunnus on jäädytetty. Ota yhteys asiakaspalveluun.',
		description: 'Error message telling the user that the accound is disabled.',
	})

export const USER_NOT_FOUND_MESSAGE = (intl) =>
	intl.formatMessage({
		defaultMessage: 'Käyttäjätunnusta ei löytynyt.',
		description:
			"Error message telling the user that the accound wasn't found.",
	})

export const WRONG_PASSWORD_MESSAGE = (intl) =>
	intl.formatMessage({
		defaultMessage: 'Syöttämäsi salasana ei täsmännyt tietoihimme.',
		description:
			'Error message telling the user that the typed password was incorrect.',
	})

export const GENERIC_ERROR = (intl) =>
	intl.formatMessage({
		defaultMessage: 'Tapahtui virhe. Ole hyvä ja yritä uudelleen.',
		description:
			'Generic error message telling the user that something went wrong.',
	})

export const EMAIL_ALREADY_IN_USE = (intl) =>
	intl.formatMessage({
		defaultMessage:
			'Tämä sähköpostiosoite on jo käytössä toisella tunnuksella. Ethän ole aiemmin jo luonut tunnusta?',
		description:
			'Error message telling the user that the email address is already in use on some other account.',
	})

export const OPERATION_NOT_ALLOWED = (intl) =>
	intl.formatMessage({
		defaultMessage:
			'Sähköpostitunnuksien luonti on poissa käytöstä. Ota yhteyttä asiakaspalveluun.',
		description:
			"Error message telling the user that it's currently not possible to create a new account using an email address.",
	})

export const WEAK_PASSWORD_MESSAGE = (intl) =>
	intl.formatMessage({
		defaultMessage:
			'Syöttämäsi salasana on liian heikko tai helposti arvattavissa.',
		description:
			'Error message telling the user that the typed password is too weak.',
	})
