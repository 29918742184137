import React from 'react'
import loadingIndicator from 'svg/loading-indicator.svg'
import styled, { keyframes } from 'styled-components'

const LoadingStateContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex-grow: 1;
	height: 100%;
	position: relative;
`
const spin = keyframes`
0% {
	transform: rotate(0deg);
}

100% {
	transform: rotate(359deg);
}
`
const LoadingIndicator = styled.img`
	animation-duration: 1s;
	animation-iteration-count: infinite;
	animation-name: ${spin};
	height: 48px;
	width: 48px;
`
const LoadingIndicatorSmall = styled.img`
	animation-duration: 1s;
	animation-iteration-count: infinite;
	animation-name: ${spin};
	height: 24px;
	width: 24px;
`
const Percentage = styled.p`
	position: absolute;
	top: 33%;
	left: 30%;
	font-size: 11px;
`

const LoadingState = ({ small = false, percentage = 0 }) => {
	return (
		<LoadingStateContainer>
			{small ? (
				<LoadingIndicatorSmall alt="lataa" src={loadingIndicator} />
			) : (
				<LoadingIndicator alt="lataa" src={loadingIndicator} />
			)}
			{percentage && !small ? <Percentage>{percentage}%</Percentage> : null}
		</LoadingStateContainer>
	)
}

export default LoadingState
