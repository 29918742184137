import { FormattedMessage, useIntl } from 'react-intl'
import DataTable, {
	TableBody,
	TableColumn,
	TableHeader,
	TableRow,
} from 'react-md/lib/DataTables'
import OverflowGradient from 'ui/components/OverflowGradient'
import { TableContainer } from 'ui/StyledComponents/Tables'
import { TableRowContainer, TableRowsNotFound } from '../components'
import Button from 'react-md/lib/Buttons'
import styled from 'styled-components'
import type { Party } from 'ui/ManagerFinance/Parties/types'

const ActionButtonContainer = styled.div`
	margin-top: 1rem;
`

type LoanShareGroupPartiesTableProps = {
	parties: Party[]
}

function LoanShareGroupPartiesTable(props: LoanShareGroupPartiesTableProps) {
	const { parties } = props
	const intl = useIntl()

	const renderTableHeaders = () => {
		return (
			<TableRow>
				<TableColumn key="role">
					<p className="text-strong text-subtle">
						<FormattedMessage
							defaultMessage="Rooli"
							description="Table header for: role"
						/>
					</p>
				</TableColumn>
				<TableColumn key="name">
					<p className="text-strong text-subtle">
						<FormattedMessage
							defaultMessage="Nimi"
							description="Table header for: name"
						/>
					</p>
				</TableColumn>
				<TableColumn key="email">
					<p className="text-strong text-subtle">
						<FormattedMessage
							defaultMessage="Sähköposti"
							description="Table header for: email"
						/>
					</p>
				</TableColumn>
				<TableColumn key="address">
					<p className="text-strong text-subtle">
						<FormattedMessage
							defaultMessage="Postiosoite"
							description="Table header for: address"
						/>
					</p>
				</TableColumn>
			</TableRow>
		)
	}

	const renderTableRows = () => {
		const rows = parties
			.map((party, index) => {
				const shareholderBond = party.bonds?.find(
					(bond) => bond.name === 'shareholder',
				)
				return (
					<TableRowContainer
						key={party.uuid}
						$filledBackground={index % 2 === 0}
					>
						<TableColumn>
							{shareholderBond
								? intl.formatMessage({
										defaultMessage: 'Osakas',
										description: 'Label for shareholder',
								  })
								: null}
						</TableColumn>
						<TableColumn>{party.name}</TableColumn>
						<TableColumn>{party.email}</TableColumn>
						<TableColumn>{party.streetAddress}</TableColumn>
					</TableRowContainer>
				)
			})
			.concat(
				<tr
					key="empty-row"
					className="full-width"
					style={{ height: 100, background: 'white' }}
				></tr>,
			)
		return rows
	}

	return (
		<div>
			{parties.length > 0 ? (
				<TableContainer>
					<DataTable
						selectableRows
						className="md-paper--1"
						style={{
							background: 'white',
							maxHeight: 'calc(100vh - 205px)',
						}}
					>
						<TableHeader
							style={{
								position: 'sticky',
								top: 0,
								zIndex: 1,
								background: 'white',
							}}
						>
							{renderTableHeaders()}
						</TableHeader>
						<TableBody>{renderTableRows()}</TableBody>
						<OverflowGradient style={{ bottom: 40 }} />
					</DataTable>
					<ActionButtonContainer>
						<Button
							raised
							secondary
							onClick={() =>
								console.log('TODO: Placeholder clicks for adding parties')
							}
						>
							<FormattedMessage
								defaultMessage="Lisää osapuoli"
								description="Button label for add party"
							/>
						</Button>
					</ActionButtonContainer>
				</TableContainer>
			) : (
				<TableRowsNotFound>
					{intl.formatMessage({
						defaultMessage: 'Osapuolia ei löytynyt',
						description: 'Error text when no parties were found',
					})}
					<Button
						raised
						secondary
						onClick={() =>
							console.log('TODO: Placeholder click for adding parties')
						}
					>
						<FormattedMessage
							defaultMessage="Lisää osapuoli"
							description="Button label for add party"
						/>
					</Button>
				</TableRowsNotFound>
			)}
		</div>
	)
}

export default LoanShareGroupPartiesTable
