import { PropTypes } from 'prop-types'
import { Toolbar, Button } from 'react-md'

const LoginDialogToolbar = ({ onHide, title }) => {
	const hide = window.loginDialogHideCloseButton
	return (
		<Toolbar
			title={title}
			titleStyle={{
				display: 'flex',
				whiteSpace: 'normal',
				lineHeight: '100%',
				marginLeft: hide ? 28 : -2,
			}}
			style={{
				display: 'flex',
				alignItems: 'center',
				position: 'relative',
				width: '100%',
				zIndex: 50,
			}}
			nav={
				hide ? null : (
					<Button icon onClick={onHide}>
						close
					</Button>
				)
			}
		/>
	)
}

LoginDialogToolbar.propTypes = {
	onHide: PropTypes.func.isRequired,
	title: PropTypes.string.isRequired,
}

export default LoginDialogToolbar
