import { useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import FloatingActionBtn from 'ui/components/FloatingActionBtn'
import LoadingState from 'ui/components/LoadingState'
import PaymentDialog from './PaymentDialog'
import PaymentsTable from './PaymentsTable'
import usePayments from './usePayments'
import type { PaymentFilters } from './types'
import Filters from './Filters'
import useCompanyApartments from 'util/hooks/useCompanyApartments'
import { useBuildings } from 'ui/CompanyBuildings/hooks'
import { createApartmentOptions } from '../utils/apartmentOptions'
import { createPartyOptions } from '../utils/partyOptions'
import useParties from '../Parties/useParties'
import useContracts from '../Contracts/useContracts'
import { push } from 'connected-react-router'
import { createContractOptions } from '../utils/contractOptions'
import config from 'config'

const Container = styled.div`
	padding: 16px;
`

const LoadingContainer = styled.div`
	margin-top: 64px;
`

const NotFound = styled.p`
	margin-top: 16px;
`
const HASH_CONSOLIDATED_INVOICES = config.routeHashes.consolidatedInvoice

function Payments() {
	const intl = useIntl()
	const [createDialog, setCreateDialog] = useState(false)
	const dispatch = useDispatch()
	const selectedFinanceCompanyUUID = useSelector(
		({ app }: any) => app?.selectedFinanceCompanyUUID,
	)

	const [filters, setFilters] = useState<PaymentFilters>({
		companyUUID: selectedFinanceCompanyUUID || '',
		apartmentUUID: null,
		paymentDate: null,
		recordedDate: null,
		contractUUID: null,
		partyUUID: null,
		matched: null,
		referenceNumber: null,
		message: null,
	})

	useEffect(() => {
		setFilters((prev) => ({ ...prev, companyUUID: selectedFinanceCompanyUUID }))
	}, [selectedFinanceCompanyUUID])

	const { parties } = useParties(selectedFinanceCompanyUUID)
	const { buildings } = useBuildings(selectedFinanceCompanyUUID)
	const { companyApartments } = useCompanyApartments(selectedFinanceCompanyUUID)
	const apartmentOptions = createApartmentOptions(companyApartments, buildings)

	const location = useSelector((state: any) => state.router?.location)
	const params = new URLSearchParams(location.search)
	const paymentUUID = params.get('puuid')
	const consolidatedUUID = params.get('consolidatedUUID')

	const contractFilters = useMemo(
		() => ({
			companyUUID: selectedFinanceCompanyUUID || '',
			apartmentUUID: '',
			reviewDate: new Date(),
			invoicingMethod: '',
			searchWord: '',
		}),
		[selectedFinanceCompanyUUID],
	)
	const { contracts } = useContracts(contractFilters)

	const contractOptions = createContractOptions(contracts)

	const partyOptions = createPartyOptions(parties, intl)

	const payments = usePayments(filters)

	const renderTable = () => {
		if (payments.loading) {
			return (
				<LoadingContainer>
					<LoadingState />
				</LoadingContainer>
			)
		}

		if (!payments?.data?.length) {
			return (
				<NotFound>
					{intl.formatMessage({
						defaultMessage: 'Suorituksia ei löytynyt.',
						description: 'Message for empty payments list.',
					})}
				</NotFound>
			)
		}

		return (
			<PaymentsTable
				payments={payments.data}
				refreshPayments={payments.refresh}
				apartmentOptions={apartmentOptions}
			/>
		)
	}

	return (
		<Container>
			<Filters
				filters={filters}
				setFilters={setFilters}
				apartmentOptions={apartmentOptions}
				contractOptions={contractOptions}
				partyOptions={partyOptions}
			/>

			{renderTable()}

			<FloatingActionBtn
				iconName={'add'}
				text={intl.formatMessage({
					defaultMessage: 'Uusi suoritus',
					description: 'Label for the create a finance payment button.',
				})}
				action={() => {
					setCreateDialog(true)
				}}
			/>

			{createDialog && (
				<PaymentDialog
					id={null}
					onHide={() => setCreateDialog(false)}
					refreshPayments={payments.refresh}
					contractOptions={contractOptions}
				/>
			)}

			{paymentUUID && (
				<PaymentDialog
					id={paymentUUID}
					onHide={() => {
						let url = '/manager/finance/payments'

						if (consolidatedUUID) {
							url =
								'/manager/finance/invoices' +
								HASH_CONSOLIDATED_INVOICES +
								`?uuid=${consolidatedUUID}`
						}

						dispatch(push(url))
					}}
					refreshPayments={() => payments.refresh()}
					contractOptions={contractOptions}
				/>
			)}
		</Container>
	)
}

export default Payments
