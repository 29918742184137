import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import WithSelectedCompanyManager from './WithSelectedCompanyManager'
import WithUserRoles from './userRoles/WithUserRoles'
import config from 'config'

export default (ComponentToWrap) => {
	class WithSelectedCompanyManagerRole extends Component {
		render() {
			const { selectedCompanyManager, uid, isSuperAdmin } = this.props
			let role = config.userRoles.none
			if (selectedCompanyManager) {
				if (selectedCompanyManager.userRoles) {
					role = selectedCompanyManager.userRoles[uid] || config.userRoles.none
				} else {
					console.error('manager userRoles is falsy!')
				}
			}

			if (isSuperAdmin) {
				role = 'manager_master'
			}

			return (
				<ComponentToWrap {...this.props} selectedCompanyManagerRole={role} />
			)
		}
	}

	const mapState = ({
		firebaseReducer: {
			auth: { uid },
		},
	}) => ({
		uid,
	})

	const mapDispatchToProps = (dispatch) => ({})

	return compose(
		WithSelectedCompanyManager,
		WithUserRoles,
		connect(mapState, mapDispatchToProps),
	)(WithSelectedCompanyManagerRole)
}
