import { _updateUserSelectedLocaleDebounced } from './user-actions'
import { setMomentLocale } from 'i18n'

export const _setSelectedCompany = ({ uuid, urlName }) => ({
	type: 'APP_SET_SELECTED_COMPANY',
	company: { uuid, urlName },
})

export const _setSelectedCustomerCompany = ({ uuid, urlName }) => ({
	type: 'APP_SET_SELECTED_CUSTOMER_COMPANY',
	company: { uuid, urlName },
})

export const _setSelectedApartmentUUID = (companyUUID, apartmentUUID) => ({
	type: 'APP_SET_SELECTED_APARTMENT_UUID',
	companyUUID,
	apartmentUUID,
})

export const _setSelectedManagerUUID = (managerUUID) => ({
	type: 'APP_SET_SELECTED_MANAGER',
	managerUUID,
})

export const _setSelectedLocale = (selectedLocale) => {
	setMomentLocale(selectedLocale)
	_updateUserSelectedLocaleDebounced(selectedLocale)
	return {
		type: 'APP_SET_SELECTED_LOCALE',
		selectedLocale,
	}
}

export const _hideMessagingTokenBannerScope = (scope) => ({
	type: 'APP_HIDE_MESSAGING_TOKEN_BANNER_SCOPE',
	scope,
})

export const _setLocalMessagingToken = (token) => ({
	type: 'APP_SET_LOCAL_MESSAGING_TOKEN',
	token,
})

export const _setTaskManagementFiltersManager = (filters) => ({
	type: 'APP_SET_TASK_MANAGEMENT_FILTERS_MANAGER',
	filters,
})

export const _setTaskManagementFiltersCompany = (filters) => ({
	type: 'APP_SET_TASK_MANAGEMENT_FILTERS_COMPANY',
	filters,
})

export const _setTaskManagementSettings = (settings) => ({
	type: 'APP_SET_TASK_MANAGEMENT_SETTINGS',
	settings,
})

export const _setTaskManagementFiltersVisible = (visible) => ({
	type: 'APP_SET_TASK_MANAGEMENT_FILTERS_VISIBLE',
	visible,
})

export const _setSelectedFinanceCompany = ({ uuid }) => ({
	type: 'APP_SET_SELECTED_FINANCE_COMPANY',
	company: { uuid },
})
