import { injectIntl } from 'react-intl'
import { compose } from 'redux'
import SummaryItems from '../SummaryItems'

function SMSSummary({ smsCount, intl }) {
	const items = [
		{
			icon: 'email',
			text:
				smsCount === 1
					? intl.formatMessage({
							defaultMessage: '1 tekstiviesti',
							description:
								'Singular version of the sms recipients count in the message service summary step.',
					  })
					: intl.formatMessage(
							{
								defaultMessage: '{recipientsCount} tekstiviestiä',
								description:
									'Plural version of the email recipients count in the message service summary step.',
							},
							{
								recipientsCount: smsCount,
							},
					  ),
		},
	]

	return (
		<div>
			<h2>
				{intl.formatMessage({
					defaultMessage: 'Tekstiviestit (maksullinen)',
					description:
						'Heading for the sms section in the message service summary step.',
				})}
			</h2>
			<SummaryItems items={items} />
		</div>
	)
}

export default compose(injectIntl)(SMSSummary)
