import {
	BankAccount,
	ChartOfAccount,
	Settings,
	WebInvoiceAddress,
} from 'ui/ManagerFinance/Settings/types'
import { callApi } from './api/finance-api'

export const _getSettingsByCompanyUUID = async (companyUUID: string) => {
	const url = `/v1/settings/${companyUUID}`

	return callApi(url, {
		method: 'GET',
	})
}

export const _updateSettings = async (data: Settings) => {
	return callApi('/v1/settings', {
		method: 'PUT',
		body: {
			...data,
		},
	})
}

export const _getBankAccountsSettingsByCompanyUUID = async (
	companyUUID: string,
) => {
	const url = `/v1/bank-accounts-settings/${companyUUID}`

	return callApi(url, {
		method: 'GET',
	})
}
export const _getBankAccountByUUID = async (uuid: string) => {
	const url = `/v1/bank-account-settings/${uuid}`

	return callApi(url, {
		method: 'GET',
	})
}

export const _createBankAccountSetting = async (data: BankAccount) => {
	return callApi('/v1/bank-account-settings', {
		method: 'POST',
		body: {
			...data,
		},
	})
}

export const _updateBankAccountSetting = async (data: BankAccount) => {
	return callApi('/v1/bank-account-settings', {
		method: 'PUT',
		body: {
			...data,
		},
	})
}

export const _deleteBankAccountSetting = async (uuid: string) => {
	return callApi(`/v1/bank-account-settings/${uuid}`, {
		method: 'DELETE',
	})
}

export const _getChartOfAccountsSettingsByCompanyUUID = async (
	companyUUID: string,
) => {
	const url = `/v1/chart-of-accounts-settings/${companyUUID}`

	return callApi(url, {
		method: 'GET',
	})
}

export const _getChartOfAccountSettingsByUUID = async (uuid: string) => {
	const url = `/v1/chart-of-account-settings/${uuid}`

	return callApi(url, {
		method: 'GET',
	})
}

export const _createChartOfAccountSettings = async (data: ChartOfAccount) => {
	return callApi('/v1/chart-of-account-settings', {
		method: 'POST',
		body: {
			...data,
		},
	})
}

export const _updateChartOfAccountSettings = async (data: ChartOfAccount) => {
	return callApi('/v1/chart-of-account-settings', {
		method: 'PUT',
		body: {
			...data,
		},
	})
}

export const _deleteChartOfAccountSetting = async (uuid: string) => {
	return callApi(`/v1/chart-of-account-settings/${uuid}`, {
		method: 'DELETE',
	})
}

export const _getWebInvoiceAddressSettingsByCompanyUUID = async (
	companyUUID: string,
) => {
	const url = `/v1/web-invoice-addresses-settings/${companyUUID}`

	return callApi(url, {
		method: 'GET',
	})
}

export const _getWebInvoiceAddressSettingsByUUID = async (
	companyUUID: string,
) => {
	const url = `/v1/web-invoice-address-settings/${companyUUID}`

	return callApi(url, {
		method: 'GET',
	})
}

export const _createWebInvoiceAddressSettings = async (
	data: WebInvoiceAddress,
) => {
	return callApi('/v1/web-invoice-address-settings', {
		method: 'POST',
		body: {
			...data,
		},
	})
}

export const _updateWebInvoiceAddressSettings = async (
	data: WebInvoiceAddress,
) => {
	return callApi('/v1/web-invoice-address-settings', {
		method: 'PUT',
		body: {
			...data,
		},
	})
}

export const _deleteWebInvoiceAddressSetting = async (uuid: string) => {
	return callApi(`/v1/web-invoice-address-settings/${uuid}`, {
		method: 'DELETE',
	})
}
