import { useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'

function useMessageServiceIntegrations() {
	const selectedCompanyUUID = useSelector(({ app }) => app.selectedCompanyUUID)
	useFirestoreConnect([
		{
			collection: 'message-service-integration',
			storeAs: 'messageServiceIntegrations',
			orderBy: ['title'],
			where: ['companyUUID', '==', selectedCompanyUUID],
		},
	])
	const messageServiceIntegrations = useSelector(
		({ firestoreReducer: { ordered } }) => ordered.messageServiceIntegrations,
	)
	return messageServiceIntegrations || []
}

export default useMessageServiceIntegrations
