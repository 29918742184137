export function copyMessageContent(item) {
	const title = `${item.title} - Kopio`
	const data = {
		...item,
		title,
		uuid: null,
		status: 'draft',
		sentTs: null,
		sendingStatus: null,
		modified: {
			by: {
				uid: null,
				name: null,
				email: null,
			},
			at: null,
		},
		created: null,
		id: null,
		step: {
			id: 'create',
			childId: 'create',
			reachedId: 'create',
		},
	}
	return data
}
