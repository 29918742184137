import { IntlShape } from 'react-intl'
import type { Party } from '../Parties/types'
import type { PartySelectOptions } from '../types'
import { bondNameMessage } from './messages'

export const createPartyOptions = (
	parties: Party[],
	intl: IntlShape,
): PartySelectOptions[] => {
	return parties.map((party: Party) => {
		let bonds = ''
		if (party.bonds && party.bonds.length > 0) {
			bonds = party.bonds
				.map((bond) => bondNameMessage(intl, bond.name))
				.join(', ')
		}

		return {
			label: party.name + (bonds ? ' \u2013 ' + bonds : ''),
			value: party.uuid,
			apartmentUUID: party.apartmentUUID,
		}
	})
}
