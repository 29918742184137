import {
	CompanyColor,
	CompanyFeatureSettings,
	CompanyFeatures,
	CompanyPicture,
	CompanyServiceLevel,
} from 'types/company'
import { Person, UID } from 'types/common'

export const ManagerTypes = {
	ManagerCompany: 'manager-company',
	MaintenanceCompany: 'maintenance-company',
} as const

export type ManagerType = typeof ManagerTypes[keyof typeof ManagerTypes]

export type Manager = {
	// default fields
	author: Person
	name: string
	uuid: string
	userRoles: ManagerUserRoles
	userUUIDs: UID[]
	whitelabelManager: boolean // if true, use default Viilu company colors
	ts: number
	workstationSyncEnabled: boolean
	active: boolean
	collection?: 'manager'

	// can exist on manager objects
	address?: string
	addressInfo?: any
	addressLowerCase?: string
	area?: string // e.g. 'porvoo'
	apartmentRepresentationClaimEmail?: string
	businessId: string | null
	colorPrimary?: CompanyColor | null
	colorPrimaryDark?: CompanyColor | null
	colorSecondary?: CompanyColor | null
	colorSecondaryDark?: CompanyColor | null
	drawerLogo?: CompanyPicture
	daysToAcceptParkingSpace?: number
	daysToAcceptStorageUnit?: number
	defaultServiceLevel?: CompanyServiceLevel
	email?: string
	featureSettings?: CompanyFeatureSettings
	features?: CompanyFeatures
	featuresHidden?: CompanyFeatures
	featuresManager: ManagerFeatures
	featuresHiddenManager: ManagerFeatures
	forceCustomerAddressCollection?: boolean
	forceCustomerPhoneNumberCollection?: boolean
	invoiceableProducts: InvoiceableProduct[]
	groupUUID?: string | null
	meetingProcessDefaultForwardEmails?: string[]
	modifiedAt?: number
	modifiedBy?: Person
	parkingSpaceApplicationTaskTemplateUUID?: string
	parkingSpaceTerminateTaskTemplateUUID?: string
	parkingSpaceContractsDisabled?: boolean
	storageUnitApplicationTaskTemplateUUID?: string
	storageUnitTerminateTaskTemplateUUID?: string
	storageUnitContractsDisabled?: boolean
	postalSenderAddress?: string | null
	postalSenderArea?: string | null
	postalSenderCountry?: string | null
	postalSenderName?: string | null
	postalSenderZip?: string | null
	site?: string | null
	siteEmail?: string | null
	siteLogo?: CompanyPicture
	siteName?: string | null
	siteTel?: string | null
	siteTelInfo?: string | null
	tel?: string | null
	type?: ManagerType
	userInviteRequestEmail?: string | null
	zip?: string | null
}

export type ManagerFeatures = {
	employees: boolean
	tasks: boolean
	settings: boolean
	propertyManagersCertificate?: boolean
}

// TODO: once the convenience hook PR (VIIL-95) has been merged,
// add proper type for the value (ManagerRole)
export type ManagerUserRoles = Record<UID, string> // value: 'manager_master' etc

export type InvoiceableProduct = {
	invoicingAllowed: boolean
	uuid: string
	comment?: string
}
