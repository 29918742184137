import AwesomeDebouncePromise from 'awesome-debounce-promise'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Button, TextField } from 'react-md'
import styled from 'styled-components'
import DatePickerISO from 'ui/components/DatePickerISO'
import DropdownField from 'ui/components/DropdownField'
import { YES_NO_MESSAGE } from 'ui/messages'
import type { PaymentProductsFilters } from './types'
import { VatSelectOptions } from '../types'

const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 16px;
	margin-bottom: 16px;
`

const SearchTextInput = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`

const Filter = styled.div`
	min-width: 160px;
`

type Props = {
	filters: PaymentProductsFilters
	setFilters: React.Dispatch<React.SetStateAction<PaymentProductsFilters>>
	vatOptions: VatSelectOptions[]
}

const searchTextDebounced = AwesomeDebouncePromise((func) => {
	func()
}, 1000)

function Filters({ filters, setFilters, vatOptions }: Props) {
	const intl = useIntl()
	const [searchText, setSearchText] = useState<string>()

	useEffect(() => {
		if (searchText !== undefined) {
			searchTextDebounced(() => {
				setFilters((prev) => ({ ...prev, searchText }))
			})
		}
	}, [searchText, setFilters])

	const yesNoOptions = [
		{ label: YES_NO_MESSAGE(intl, true), value: true },
		{ label: YES_NO_MESSAGE(intl, false), value: false },
	]

	return (
		<Container>
			<Filter>
				<DropdownField
					label={intl.formatMessage({
						defaultMessage: 'Erillislaskutus',
						description:
							'Finance payment product filter label for separate billing.',
					})}
					itemLabel={'label'}
					itemValue={'value'}
					options={yesNoOptions}
					hasSelectAll={true}
					nullSelectAll={true}
					value={filters.separateBilling}
					_onOptionSelect={(value) => {
						setFilters((prev) => ({ ...prev, separateBilling: value }))
					}}
				/>
			</Filter>

			<Filter>
				<DropdownField
					label={intl.formatMessage({
						defaultMessage: 'ALV',
						description: 'Finance payment product filter label for vat.',
					})}
					itemLabel={'label'}
					itemValue={'value'}
					options={vatOptions}
					hasSelectAll={true}
					nullSelectAll={true}
					value={filters.vatUUID}
					_onOptionSelect={(value) => {
						setFilters((prev) => ({ ...prev, vatUUID: value }))
					}}
				/>
			</Filter>

			<Filter>
				<DatePickerISO
					id="payment-product-date-filter"
					label={intl.formatMessage({
						defaultMessage: 'Tarkastelupäivämäärä',
						description: 'Label for the payment product date filter.',
					})}
					value={filters.date || ''}
					onChange={(isoDate: string) => {
						setFilters((prev) => ({ ...prev, date: isoDate }))
					}}
				/>
			</Filter>

			<Filter>
				<SearchTextInput>
					<TextField
						id="payment-product-search-text-input"
						label={intl.formatMessage({
							defaultMessage: 'Hakusana',
							description: 'Label for the payment product search text filter.',
						})}
						value={searchText || ''}
						onChange={(value) => {
							setSearchText(value.toString())
						}}
					/>
					<Button
						icon
						style={{ marginTop: 16 }}
						onClick={() => {
							setSearchText('')
						}}
					>
						clear
					</Button>
				</SearchTextInput>
			</Filter>
		</Container>
	)
}

export default Filters
