import { IntlShape, useIntl } from 'react-intl'
import styled from 'styled-components'
import { YES_NO_MESSAGE } from 'ui/messages'
import {
	formatCurrency,
	formatDate,
	formatInvoiceNumber,
} from 'util/formatStrings'
import type { Invoice, UpdateInvoice } from './types'
import { invoicingMethodMessage } from '../utils/messages'
import type { ApartmentSelectOptions } from '../types'
import type { Contract } from '../Contracts/types'
import { invoiceContractDetailsLabels, invoiceDetailsLabels } from './constants'
import { TextField, TextFieldTypes } from 'react-md'
import DatePickerISO from 'ui/components/DatePickerISO'
import { InvoiceStatus } from '../enum'

const Table = styled.table`
	border-collapse: collapse;
	border: 1px solid rgba(0, 0, 0, 0.2);

	td {
		padding: 4px 8px;
		white-space: nowrap;
		border-bottom: 1px solid rgba(0, 0, 0, 0.2);
	}

	td:nth-child(2) {
		text-align: right;
	}
`

type Props = {
	apartmentOptions: ApartmentSelectOptions[]
	entity: Invoice | Contract
	setContract?: React.Dispatch<React.SetStateAction<Contract>>
	setEntity?: React.Dispatch<React.SetStateAction<UpdateInvoice>>
}

function InvoiceDetailsTable({
	entity,
	apartmentOptions,
	setContract,
	setEntity,
}: Props) {
	const intl = useIntl()

	const handleInputChange = (value: string | number, key: string) => {
		if (setContract) {
			setContract((prevData) => ({
				...prevData,
				[key]: value,
			}))
		}
		if (setEntity) {
			setEntity((prevData) => ({
				...prevData,
				[key]: value,
			}))
		}
	}

	const renderInput = (
		key: string,
		type: string,
		title?: (intl: IntlShape) => string,
	) => {
		const value = entity[key]
		if (type === 'date' || type === 'formatDate') {
			return (
				<DatePickerISO
					style={{ width: '100%' }}
					key={key}
					id={key}
					label={title ? title(intl) : ''}
					required
					value={value}
					onChange={(value) => handleInputChange(value, key)}
				/>
			)
		} else {
			return (
				<TextField
					key={key}
					id={key}
					floating
					lineDirection="center"
					fullWidth
					value={value}
					onChange={(value) => handleInputChange(value, key)}
					type={(type as TextFieldTypes) || undefined}
				/>
			)
		}
	}

	const getValue = (
		key: string,
		editable: boolean,
		type?: string,
		title?: (intl: IntlShape) => string,
		secondaryKey?: string,
	) => {
		let value = entity[key]

		if (
			(type === 'text' || type === 'date' || type === 'formatDate') &&
			editable
		) {
			return renderInput(key, type, title)
		} else if (type === 'formatCurrency') {
			if (key === 'openBalance') {
				value = entity['totalWithVat'] - entity['paymentsTotal']
			}
			return formatCurrency(value)
		} else if (type === 'YES_NO_MESSAGE') {
			return YES_NO_MESSAGE(intl, value)
		}
		//This type should be used only with invoice
		else if (type === 'formatInvoiceNumber') {
			return formatInvoiceNumber(entity as Invoice)
		} else if (type === 'formatDate') {
			return formatDate(value)
		} else if (type === 'apartmentIdentifier') {
			return (
				apartmentOptions.find((a) => a.identifier === value)?.label || value
			)
		} else if (type === 'invoicingMethodMessage') {
			return (
				invoicingMethodMessage(
					intl,
					entity[key] || entity[secondaryKey || ''] || '',
				) || '-'
			)
		} else if (secondaryKey) {
			return value || entity[secondaryKey] || ''
		} else {
			return value || ''
		}
	}

	if (setContract) {
		return (
			<Table>
				<tbody>
					{invoiceContractDetailsLabels.map((header) => {
						const { key, title, type, secondaryKey } = header
						return (
							<tr key={key}>
								<td>{title(intl)}</td>
								<td>{getValue(key, true, type, title, secondaryKey)}</td>
							</tr>
						)
					})}
				</tbody>
			</Table>
		)
	}

	const editable =
		entity['status'] === InvoiceStatus.SAVED && setEntity ? true : false
	return (
		<Table>
			<tbody>
				{invoiceDetailsLabels.map((header) => {
					const { key, title, type } = header
					return (
						<tr key={key}>
							<td>{title(intl)}</td>
							<td>{getValue(key, editable, type) || '-'}</td>
						</tr>
					)
				})}
			</tbody>
		</Table>
	)
}

export default InvoiceDetailsTable
