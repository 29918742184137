export const loanApartmentShareGroupHeaders = [
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Osakeryhmän tunnus',
				description: 'Table header for company location',
			}),
		key: 'shareGroupId',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Velalliset yksiköt',
				description: 'Table header for loan name',
			}),
		key: 'units',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Arvioitu lainaosuus',
				description: 'Table header for loan name',
			}),
		key: 'shareAmount',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Arvioitu velkaosuus',
				description: 'Table header for loan name',
			}),
		key: 'debtAmount',
	},
]
