import { IntlShape } from 'react-intl'
import { PartyHeader } from './types'

export const partyHeaders: PartyHeader[] = [
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Kohde',
				description: 'Company',
			}),
		key: 'companyUUID',
		show: false,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Huoneisto',
				description: 'Apartment',
			}),
		key: 'apartmentUUID',
		type: 'apartmentOptions',
		show: true,
		sortable: true,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Rooli',
				description: 'Bond',
			}),
		key: 'bondName',
		type: 'bondName',
		show: true,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Nimi',
				description: 'Name',
			}),
		key: 'name',
		show: true,
		sortable: true,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Alkupäivä',
				description: 'Start date',
			}),
		key: 'startDate',
		show: true,
		type: 'date',
		sortable: true,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Loppupäivä',
				description: 'End date',
			}),
		key: 'endDate',
		show: true,
		type: 'date',
		sortable: true,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Sähköposti',
				description: 'Email',
			}),
		key: 'email',
		show: true,
		sortable: true,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Puhelin',
				description: 'Phone number',
			}),
		key: 'phoneNumber',
		show: true,
		sortable: true,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Osoite',
				description: 'Address',
			}),
		key: 'streetAddress',
		show: true,
		sortable: true,
	},
]

export const createPartyHeaders = [
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'ID',
				description: 'ID',
			}),
		key: 'uuid',
		show: false,
		required: false,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Oletus verkkolaskuosoite',
				description: 'Invoicing address',
			}),
		key: 'invoicingAddress',
		show: true,
		required: false,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Alkupäivä',
				description: 'Start date',
			}),
		key: 'startDate',
		show: true,
		type: 'date',
		required: false,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Loppupäivä',
				description: 'End date',
			}),
		key: 'endDate',
		show: true,
		type: 'date',
		required: false,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Aktiivinen',
				description: 'Active',
			}),
		key: 'active',
		show: true,
		type: 'boolean',
		required: false,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Nimi',
				description: 'Name',
			}),
		key: 'name',
		type: 'text',
		show: true,
		required: true,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Käytä kohteen osoitetietoja',
				description: 'Use company addressInfo',
			}),
		key: 'useCompanyAddressInfo',
		type: 'button',
		show: true,
		required: false,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Osoite',
				description: 'Street address',
			}),
		key: 'streetAddress',
		type: 'text',
		show: true,
		required: true,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Postinumero',
				description: 'Zip code',
			}),
		key: 'zipCode',
		type: 'text',
		show: true,
		required: true,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Kaupunki',
				description: 'City',
			}),
		key: 'city',
		type: 'text',
		show: true,
		required: true,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Maa',
				description: 'Country',
			}),
		key: 'country',
		type: 'country',
		show: true,
		required: true,
		editable: false,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Hetu',
				description: 'Personal Identity Code',
			}),
		key: 'personalIdentityCode',
		type: 'text',
		show: true,
		required: false,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Y-tunnus',
				description: 'business ID',
			}),
		key: 'businessId',
		type: 'text',
		show: true,
		required: false,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Sähköposti',
				description: 'Email',
			}),
		key: 'email',
		type: 'email',
		show: true,
		required: false,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Puhelinnumero',
				description: 'Phone number',
			}),
		key: 'phoneNumber',
		type: 'tel',
		show: true,
		required: false,
	},
]

export const updatePartyHeaders = [
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'ID',
				description: 'ID',
			}),
		key: 'uuid',
		show: false,
		required: true,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Oletus verkkolaskuosoite',
				description: 'Invoicing address',
			}),
		key: 'invoicingAddress',
		show: true,
		required: false,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Alkupäivä',
				description: 'Start date',
			}),
		key: 'startDate',
		show: true,
		type: 'date',
		required: false,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Loppupäivä',
				description: 'End date',
			}),
		key: 'endDate',
		show: true,
		type: 'date',
		required: false,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Aktiivinen',
				description: 'Active',
			}),
		key: 'active',
		show: true,
		type: 'boolean',
		required: false,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Nimi',
				description: 'Name',
			}),
		key: 'name',
		type: 'text',
		show: true,
		required: true,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Osoite',
				description: 'Street address',
			}),
		key: 'streetAddress',
		type: 'text',
		show: true,
		required: true,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Postinumero',
				description: 'Zip code',
			}),
		key: 'zipCode',
		type: 'text',
		show: true,
		required: true,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Kaupunki',
				description: 'City',
			}),
		key: 'city',
		type: 'text',
		show: true,
		required: true,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Maa',
				description: 'Country',
			}),
		key: 'country',
		type: 'country',
		show: true,
		required: true,
		editable: false,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Hetu',
				description: 'Personal Identity Code',
			}),
		key: 'personalIdentityCode',
		type: 'text',
		show: true,
		required: false,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Y-tunnus',
				description: 'business ID',
			}),
		key: 'businessId',
		type: 'text',
		show: true,
		required: false,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Sähköposti',
				description: 'Email',
			}),
		key: 'email',
		type: 'email',
		show: true,
		required: false,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Puhelinnumero',
				description: 'Phone number',
			}),
		key: 'phoneNumber',
		type: 'tel',
		show: true,
		required: false,
	},
]

export const bondHeaders = [
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'ID',
				description: 'ID',
			}),
		key: 'uuid',
		show: false,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Nimi',
				description: 'Name',
			}),
		key: 'name',
		type: 'enum',
		show: true,
		sortable: true,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Luotu',
				description: 'Created at',
			}),
		key: 'createdAt',
		type: 'date',
		show: true,
		sortable: true,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Osapuoli',
				description: 'Party',
			}),
		key: 'partyName',
		type: 'text',
		show: true,
		sortable: true,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Alkupäivä',
				description: 'Start Date',
			}),
		key: 'startDate',
		type: 'date',
		show: true,
		sortable: true,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Loppupäivä',
				description: 'End Date',
			}),
		key: 'endDate',
		type: 'date',
		show: true,
		sortable: true,
	},
]

export const createBondHeaders = [
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Nimi',
				description: 'Name',
			}),
		key: 'name',
		type: 'text',
		show: false,
		required: true,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Alkupäivä',
				description: 'Start Date',
			}),
		key: 'startDate',
		type: 'date',
		show: true,
		required: false,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Loppupäivä',
				description: 'End Date',
			}),
		key: 'endDate',
		type: 'date',
		show: true,
		required: false,
	},
]

export const editBondHeaders = [
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Nimi',
				description: 'Name',
			}),
		key: 'name',
		type: 'text',
		show: false,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Alkupäivä',
				description: 'Start Date',
			}),
		key: 'startDate',
		type: 'date',
		show: true,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Loppupäivä',
				description: 'End Date',
			}),
		key: 'endDate',
		type: 'date',
		show: true,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Poista',
				description: 'Delete',
			}),
		key: 'delete',
		type: 'button',
		show: false,
	},
]
