import { bindActionCreators, compose } from 'redux'
import styled from 'styled-components'

import { connect } from 'react-redux'
import * as notifyActions from 'state/notifyActions'
import Actions from 'ui/MessageService/MessageServiceProcess/Actions'
import CompanyTable from './CompanyTable'

const Container = styled.div`
	flex: 1;
	overflow: hidden;
	display: flex;
	flex-direction: column;
`

const StepTitle = styled.h1`
	font-size: 1.8rem;
	margin: 16px 16px 0px 16px;
`

function CompanySelection({
	stepTitle,
	messageServiceProcess,
	setMessageServiceProcess,
	onPreviousClick,
	onDraftClick,
	onNextClick,
}) {
	const setSelectedCompanies = (companies) => {
		setMessageServiceProcess((prev) => ({
			...prev,
			companies: companies,
		}))
	}

	return (
		<>
			<StepTitle>{stepTitle}</StepTitle>

			<Container>
				<CompanyTable
					selectedProcessCompanies={messageServiceProcess.companies || {}}
					setSelectedProcessCompanies={setSelectedCompanies}
				/>
			</Container>

			<Actions
				onPreviousClick={onPreviousClick}
				onDraftClick={onDraftClick}
				onNextClick={
					Object.values(messageServiceProcess?.companies || {}).some(
						(checked) => checked,
					)
						? onNextClick
						: null
				}
			/>
		</>
	)
}

const mapState = () => ({})

const mapDispatchToProps = (dispatch) => ({
	_showInfoNotification: bindActionCreators(notifyActions.info, dispatch),
})

export default compose(connect(mapState, mapDispatchToProps))(CompanySelection)
