import { createContext, useContext, useState, useMemo } from 'react'
import useWindowSize from '../hooks/useWindowSize'

const UIContext = createContext(null)

export const useUIContext = () => useContext(UIContext)

export const UIContextProvider = ({ children }) => {
	const dimensions = useWindowSize()
	const [state, setState] = useState({
		navbarVisible: true,
		hasScrolledDown: false,
		dimensions,
	})

	const value = useMemo(() => {
		const setNavbarVisible = (navbarVisible) => {
			setState((prevState) => ({
				...prevState,
				navbarVisible: navbarVisible,
			}))
		}

		const setHasScrolledDown = (hasScrolled) => {
			setState((prevState) => ({
				...prevState,
				hasScrolledDown: hasScrolled,
			}))
		}

		return {
			...state,
			setNavbarVisible,
			setHasScrolledDown,
			dimensions,
		}
	}, [state, dimensions])

	return <UIContext.Provider value={value}>{children}</UIContext.Provider>
}
