import ViiluDialog from 'ui/components/ViiluDialog'
import { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { TextField, TextFieldTypes, CircularProgress } from 'react-md'
import { useDispatch } from 'react-redux'
import useDialogs from 'ui/components/dialogs/useDialogs'
import YesNoSwitch from 'ui/components/YesNoSwitch'
import { GENERIC_ERROR_NOTIFICATION } from 'ui/messages'
import * as notifyActions from 'state/notifyActions'
import type { BankAccount, CreateBankAccount } from './types'
import { bankAccountHeaders } from './constants'
import useBankAccount from './useBankAccount'
import {
	_createBankAccountSetting,
	_updateBankAccountSetting,
} from 'state/settings-actions'
import type { UseBankAccountsResult } from './useBankAccounts'

type Props = {
	id?: string | null
	useBankAccounts: UseBankAccountsResult
	visible: boolean
	onHide: () => void
	selectedFinanceCompanyUUID: string
}

const BankAccountDialog = ({
	id,
	useBankAccounts,
	visible,
	onHide,
	selectedFinanceCompanyUUID,
}: Props) => {
	const { refreshBankAccounts } = useBankAccounts
	const intl = useIntl()
	const dispatch = useDispatch()
	const { bankAccount } = useBankAccount(id)

	const initialFormData: CreateBankAccount | BankAccount = {
		companyUUID: selectedFinanceCompanyUUID,
		name: '',
		iban: '',
		bic: '',
		primary: false,
	}

	const [formData, setFormData] = useState<CreateBankAccount | BankAccount>(
		initialFormData,
	)

	const [processing, setProcessing] = useState(false)
	const { alert } = useDialogs()
	const resetFormData = () => {
		setFormData(initialFormData)
	}

	useEffect(() => {
		if (bankAccount) {
			setFormData({
				uuid: bankAccount.uuid,
				companyUUID: bankAccount.companyUUID,
				name: bankAccount.name,
				iban: bankAccount.iban,
				bic: bankAccount.bic,
				primary: bankAccount.primary,
			})
		}
	}, [bankAccount])

	useEffect(() => {
		setFormData((prev) => ({
			...prev,
			companyUUID: selectedFinanceCompanyUUID,
		}))
	}, [selectedFinanceCompanyUUID])

	const handleInputChange = (
		value: string | number | boolean,
		field: string,
	) => {
		setFormData((prevData) => ({
			...prevData,
			[field]: value,
		}))
	}

	const validate = () => {
		let isValid = true

		bankAccountHeaders.forEach((header) => {
			if (header.show && header.required) {
				if (isValid) {
					isValid = formData[header.key]
				}
			}
		})

		if (!formData.companyUUID) {
			isValid = false
		}

		return isValid
	}

	const hide = () => {
		resetFormData()
		onHide()
	}

	const handleSave = async () => {
		const result: any = await _createBankAccountSetting({
			...(formData as BankAccount),
		})
		setProcessing(false)
		if (!result || result.ok === false) {
			dispatch(notifyActions.info('❌ ' + GENERIC_ERROR_NOTIFICATION(intl)))
			return false
		}

		dispatch(
			notifyActions.info(
				'✅ ' +
					intl.formatMessage({
						defaultMessage: 'Pankkitili on lisätty.',
						description: 'Notification text of bank account has been added',
					}),
			),
		)
		refreshBankAccounts()
		hide()
		return true
	}

	const handleUpdate = async () => {
		const result: any = await _updateBankAccountSetting({
			...(formData as BankAccount),
		})
		setProcessing(false)
		if (!result || result.ok === false) {
			dispatch(notifyActions.info('❌ ' + GENERIC_ERROR_NOTIFICATION(intl)))
			return false
		}

		dispatch(
			notifyActions.info(
				'✅ ' +
					intl.formatMessage({
						defaultMessage: 'Pankkitili on päivitetty.',
						description: 'Notification text of bank account has been updated',
					}),
			),
		)
		refreshBankAccounts()
		hide()
		return true
	}

	const onProcess = async () => {
		const isValid = validate()
		if (!isValid) {
			const message = intl.formatMessage({
				defaultMessage: 'Täytä kaikki vaaditut tiedot.',
				description: 'Error message for missing contract create input values.',
			})
			alert(message)
			return
		}

		setProcessing(true)
		if ((formData as BankAccount).uuid) {
			return await handleUpdate()
		} else return await handleSave()
	}

	const renderFormFields = () => {
		return bankAccountHeaders.map((header) => {
			const { show, key, title, type, required } = header
			if (show) {
				if (type === 'boolean') {
					return (
						<YesNoSwitch
							key={key}
							label={title(intl)}
							name={key}
							checked={formData[key]}
							onChange={(checked: boolean) => {
								handleInputChange(checked, key)
							}}
							style={{ marginTop: '10px' }}
						/>
					)
				}
				return (
					<TextField
						key={key}
						id={key}
						label={title(intl)}
						lineDirection="center"
						floating
						fullWidth
						value={formData[key] || ''}
						onChange={(value) => handleInputChange(value, key)}
						required={required}
						type={(type as TextFieldTypes) || undefined}
					/>
				)
			}
			return null
		})
	}

	const renderChildren = () => {
		return (
			<div
				className="margin-left--lg margin-right--lg"
				style={{ paddingBottom: 64 }}
			>
				{renderFormFields()}
			</div>
		)
	}

	const actions = [
		{
			id: 'dialog-cancel',
			children: intl.formatMessage({
				defaultMessage: 'Peruuta',
				description: 'Cancel button',
			}),
			onClick: hide,
		},
		{
			id: 'dialog-ok',
			secondary: true,
			children: processing ? (
				<CircularProgress id="progress" style={{ marginTop: 0 }} />
			) : (
				intl.formatMessage({
					defaultMessage: 'Tallenna',
					description: 'Save button',
				})
			),
			onClick: onProcess,
		},
	]

	return (
		<ViiluDialog
			id="responsive-dialog"
			title={
				id
					? intl.formatMessage({
							defaultMessage: 'Muokkaa pankkitiliä',
							description: 'Title of edit bank account',
					  })
					: intl.formatMessage({
							defaultMessage: 'Lisää pankkitili',
							description: 'Title of add bank account',
					  })
			}
			visible={visible}
			actions={actions}
			onHide={hide}
			focusOnMount={true}
			containFocus={true}
			dialogClassName="responsive-dialog"
			contentClassName="responsive-dialog-content"
			autosizeContent={false}
			modal
			portal
			paddedContent
		>
			{renderChildren()}
		</ViiluDialog>
	)
}

export default BankAccountDialog
