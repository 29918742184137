import { combineReducers } from 'redux'

import app from './app-ui-reducer'
import messages from './messages-ui-reducer'
import threads from './threads-ui-reducer'
import documents from './documents-ui-reducer'
import documentsV2 from './documents-v2-ui-reducer'
import register from './company-register-ui-reducer'
import onboarding from './user-onboarding-ui-reducer'
import login from './login-ui-reducer'
import meeting from './meeting-ui-reducer'
import managerCompanies from './manager-companies-ui-reducer'
import site from './site-ui-reducer'
import infoscreen from './infoscreen-ui-reducer'

const ui = combineReducers({
	app,
	messages,
	threads,
	documents,
	documentsV2,
	register,
	onboarding,
	login,
	meeting,
	managerCompanies,
	site,
	infoscreen,
})

export default ui
