import React from 'react'
import { Button } from 'react-md'
import LoadingState from '../LoadingState'

const isChunkError = (error) => {
	if (!error) {
		return false
	}
	if (error.toString().includes('ChunkLoadError')) {
		return true
	}
	if (error.toString().includes('Loading CSS chunk')) {
		return true
	}
	if (error.toString().toLowerCase().includes('chunk')) {
		return true
	}
	return false
}

class ChunkErrorBoundary extends React.Component {
	constructor(props) {
		super(props)
		this.state = { error: null }
	}

	static getDerivedStateFromError(error) {
		if (isChunkError(error)) {
			if (window.navigator.onLine) {
				console.error(error)
				console.log('Chunk load error occured and we are online. Reloading...')
				window.setTimeout(() => {
					window.location.reload(true)
				}, 2000)
			} else {
				console.log(
					'Chunk load error occured and we are offline. Displaying error...',
				)
				if (!window.chunkErrorBoundaryOnlineListener) {
					window.chunkErrorBoundaryOnlineListener = window.addEventListener(
						'online',
						() => {
							window.setTimeout(() => {
								window.location.reload(true)
							}, 2000)
							window.removeEventListener(
								'online',
								window.chunkErrorBoundaryOnlineListener,
							)
						},
					)
				}
			}
		}
		return { error }
	}

	render() {
		const { error } = this.state

		if (!error) {
			return this.props.children
		}

		if (isChunkError(error)) {
			if (window.navigator.onLine) {
				return (
					<div className="flex-column flex-center full-width full-height">
						<div className="margin-bottom">
							<LoadingState />
						</div>
						<p>Ladataan uutta sovellusversiota...</p>
					</div>
				)
			}
			return (
				<div
					className="flex-column flex-center full-width full-height"
					style={{ background: 'lightgray', borderRadius: 4, padding: 24 }}
				>
					<h1>Ei internetyhteyttä</h1>
					<p>
						Internetyhteytesi on poikki tai se katkesi juuri kun yritimme ladata
						tätä osiota. Tarkasta yhteys ja yritä sitten uudelleen.
					</p>
					<Button
						raised
						primary
						onClick={() => window.location.reload(true)}
						className="margin-top margin-bottom"
					>
						Päivitä
					</Button>
				</div>
			)
		}

		// Throw the error so the next boundary can handle it
		throw error
	}
}

export default ChunkErrorBoundary
