import { FormattedMessage, injectIntl } from 'react-intl'
import { useEffect, useState } from 'react'
import moment from 'moment-timezone'
import {
	DataTable,
	TableBody,
	TableColumn,
	TableHeader,
	TableRow,
} from 'react-md'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import naturalSort from 'javascript-natural-sort'

import EmptyState from 'ui/components/EmptyState'
import documentsEmptyIcon from 'svg/documents-empty.svg'
import WithSelectedCompany from 'ui/components/WithSelectedCompany'
import DetailsDialog from './DetailsDialog'
import MessageServiceProcessType from '../MessageServiceProcessType'
import { Button } from 'react-md'
import { _createProcess } from 'state/message-service-actions'
import { copyMessageContent } from './index.service'

function History({ history, sentUUIDToOpen, replacePath }) {
	const [statusDialog, setStatusDialog] = useState({
		visible: false,
		messageServiceProcess: null,
	})
	const [sortBy, setSortBy] = useState('sentTs')
	const [sortAscending, setSortAscending] = useState(false)

	useEffect(() => {
		if (sentUUIDToOpen) {
			const process = history?.find((d) => d.uuid === sentUUIDToOpen)
			if (process) {
				setStatusDialog({
					visible: true,
					messageServiceProcess: process,
				})
			}
		}
	}, [sentUUIDToOpen, history])

	if (!history) {
		return null
	}

	if (history.length === 0) {
		return (
			<EmptyState
				text={
					<FormattedMessage
						defaultMessage="Et ole vielä lähettänyt mitään"
						description="Message service empty state text."
					/>
				}
				icon={documentsEmptyIcon}
			/>
		)
	}

	const renderTs = (ts) => {
		if (!ts) {
			return '-'
		}

		const m = moment(new Date(ts))

		return (
			<div className="flex-row">
				<p>{m.format('DD.MM.YYYY')}</p>
				<p style={{ color: 'var(--color-text-subtle)', marginLeft: 4 }}>
					{m.format('HH:mm')}
				</p>
			</div>
		)
	}

	const sortedHistory = [...history]
	if (sortBy === 'sentTs') {
		if (sortAscending) {
			sortedHistory.sort(bySentTsAsc)
		} else {
			sortedHistory.sort(bySentTsDesc)
		}
	} else if (sortBy === 'title') {
		if (sortAscending) {
			sortedHistory.sort(byTitleAsc)
		} else {
			sortedHistory.sort(byTitleDesc)
		}
	}

	const renderSortableTableHeaderColumn = (text, columnSortKey) => {
		return (
			<TableColumn
				key={columnSortKey}
				onClick={() => {
					if (sortBy !== columnSortKey) {
						setSortBy(columnSortKey)
						setSortAscending(false)
					} else {
						setSortAscending(!sortAscending)
					}
				}}
				sorted={sortBy === columnSortKey ? sortAscending : undefined}
				style={{ cursor: 'pointer' }}
			>
				{text}
			</TableColumn>
		)
	}

	const copyMessage = async (item) => {
		const data = copyMessageContent(item)
		const r = await _createProcess(data)
		replacePath(r?.data, 'draft')
	}

	return (
		<>
			<DataTable plain>
				<TableHeader>
					<TableRow>
						{renderSortableTableHeaderColumn('Otsikko', 'title')}
						{renderSortableTableHeaderColumn('Lähetetty', 'sentTs')}
						<TableColumn>
							<FormattedMessage
								defaultMessage="Tyyppi"
								description="Type of message"
							/>
						</TableColumn>
						<TableColumn>
							<FormattedMessage
								defaultMessage="Toiminnot"
								description="Actions that can be executed for a message"
							/>
						</TableColumn>
					</TableRow>
				</TableHeader>
				<TableBody>
					{sortedHistory.map((item) => (
						<TableRow
							key={item.uuid}
							onClick={() => {
								replacePath(item, 'sent')
							}}
							style={{ cursor: 'pointer' }}
						>
							<TableColumn>{item.title}</TableColumn>
							<TableColumn>{renderTs(item.sentTs)}</TableColumn>
							<TableColumn>
								<MessageServiceProcessType generator={item.generator} />
							</TableColumn>
							<TableColumn>
								<Button
									icon
									onClick={(event) => {
										event.stopPropagation()
										copyMessage(item)
									}}
								>
									content_copy
								</Button>
							</TableColumn>
						</TableRow>
					))}
				</TableBody>
			</DataTable>

			{statusDialog.visible && (
				<DetailsDialog
					visible={statusDialog.visible}
					messageServiceProcess={statusDialog.messageServiceProcess}
					onHide={() => {
						setStatusDialog({
							visible: false,
							messageServiceProcess: null,
						})
						replacePath(statusDialog?.messageServiceProcess)
					}}
				/>
			)}
		</>
	)
}

const byTitleAsc = (a, b) => {
	if (!a.title && !b.title) return 0
	if (!a.title) return 1
	if (!b.title) return -1
	return naturalSort(b.title, a.title)
}
const byTitleDesc = (a, b) => {
	if (!a.title && !b.title) return 0
	if (!a.title) return 1
	if (!b.title) return -1
	return naturalSort(a.title, b.title)
}

const bySentTsAsc = (a, b) => {
	if (!a.sentTs && !b.sentTs) return 0
	if (!a.sentTs) return 1
	if (!b.sentTs) return -1
	return a.sentTs - b.sentTs
}
const bySentTsDesc = (a, b) => {
	if (!a.sentTs && !b.sentTs) return 0
	if (!a.sentTs) return 1
	if (!b.sentTs) return -1
	return b.sentTs - a.sentTs
}

const mapState = ({
	firestoreReducer: {
		ordered: { message_service_process_history },
	},
}) => ({
	history: message_service_process_history,
})

const mapDispatchToProps = (dispatch) => ({})

export default compose(
	injectIntl,
	WithSelectedCompany,
	firestoreConnect(({ selectedCompany, generator, generatorDataUUID }) => {
		const query = [
			['companyUUID', '==', selectedCompany.uuid],
			['status', '==', 'sent'],
		]

		if (generator) {
			query.push(['generator', '==', generator])
		}

		if (generatorDataUUID) {
			query.push(['generatorDataUUID', '==', generatorDataUUID])
		}

		return [
			{
				collection: 'message-service-process',
				storeAs: 'message_service_process_history',
				where: query,
			},
		]
	}),
	connect(mapState, mapDispatchToProps),
)(History)
