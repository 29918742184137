import config from '../config'
import { getAuth, getIdToken } from 'firebase/auth'
import { backendFunction } from './actions-util'
import ReactGA from 'react-ga4'
import { httpsCallable } from 'firebase/functions'
import { firebaseApp, functions } from './store'

export const _register =
	({
		name,
		apartmentCount,
		address,
		zip,
		area,
		serviceLevel,
		billingEmail,
		paymentInterval,
		campaign,
		managerUUID,
		features,
		featuresHidden,
		featureSettings,
		businessId,
	}) =>
	async () =>
		backendFunction('companyRegister', {
			name,
			apartmentCount: Number(apartmentCount),
			address,
			zip,
			area,
			serviceLevel,
			billingEmail,
			paymentInterval,
			campaign,
			managerUUID,
			features,
			featuresHidden,
			featureSettings,
			businessId,
		})

export const _registerBatch =
	(companies, managerUUID, features, featuresHidden, featureSettings) =>
	async () =>
		backendFunction('companyRegisterBatch', {
			companies,
			managerUUID,
			features,
			featuresHidden,
			featureSettings,
		})

export const _acceptContract =
	(uuid, serviceLevel, contactName, contactEmail, paymentInterval) =>
	async () =>
		backendFunction('companyAcceptContract', {
			uuid,
			contract: {
				serviceLevel,
				contactName,
				contactEmail,
				paymentInterval,
			},
		})

export const _serviceLevelUpdate = (companyUUID, serviceLevel) => async () =>
	backendFunction('companyServiceLevelUpdate', {
		companyUUID,
		serviceLevel,
	})

export const _updateInfo = (uuid, name, businessId, color, file) =>
	async function (dispatch, getState, getFirebase) {
		ReactGA.event({
			category: 'feature-usage',
			action: 'company-info-update',
		})

		try {
			// Done with fetch and FormData because firebase callable functions don't support file uploads
			const formData = new FormData()
			if (name) {
				formData.append('name', name)
			}
			if (businessId) {
				formData.append('businessId', businessId)
			}
			if (color) {
				formData.append('color', color)
			}
			if (file) {
				formData.append(file.name, file)
			}

			const token = await getToken(getState)
			const functionURL = config.functions.baseURL + 'companyUpdateInfo/' + uuid
			const response = await fetch(functionURL, {
				method: 'POST',
				body: formData,
				credentials: 'include',
				headers: {
					Authorization: 'Bearer ' + token,
				},
			})
			return response.ok
		} catch (ex) {
			console.log(ex)
			if (window.sentry) window.sentry.captureException(ex)
			return { success: false, ex }
		}
	}

export const _updatePromo = (uuid, title, text) => async () =>
	backendFunction('companyUpdatePromo', {
		uuid,
		title,
		text,
	})

export const _updateInfoList =
	(uuid, foundingYear, buildingType, floorsCount, apartmentCount) => async () =>
		backendFunction('companyUpdateInfoList', {
			uuid,
			foundingYear,
			buildingType,
			floorsCount,
			apartmentCount: Number(apartmentCount),
		})

export const _updateContact = (uuid, name, email, tel) => async () =>
	backendFunction('companyUpdateContact', {
		uuid,
		name,
		email,
		tel,
	})

export const _updateGeo = (uuid, address, area, zip) => async () =>
	backendFunction('companyUpdateGeo', {
		uuid,
		address,
		area,
		zip,
	})

export const _updateManagerInfo = (uuid, name, file) =>
	async function (dispatch, getState, getFirebase) {
		ReactGA.event({
			category: 'feature-usage',
			action: 'company-managerinfo-update',
		})

		try {
			// Done with fetch and FormData because firebase callable functions don't support file uploads
			const formData = new FormData()
			if (name) {
				formData.append('name', name)
			}
			if (file) {
				formData.append(file.name, file)
			}

			const token = await getToken(getState)
			const functionURL =
				config.functions.baseURL + 'companyUpdateManagerInfo/' + uuid
			const response = await fetch(functionURL, {
				method: 'POST',
				body: formData,
				credentials: 'include',
				headers: {
					Authorization: 'Bearer ' + token,
				},
			})
			return response.ok
		} catch (ex) {
			console.log(ex)
			if (window.sentry) window.sentry.captureException(ex)
			return { success: false, ex }
		}
	}

export const _updateManagerContact =
	(uuid, name, email, tel, site) => async () =>
		backendFunction('companyUpdateManagerContact', {
			uuid,
			name,
			email,
			tel,
			site,
		})

export const _updateManagerSettings =
	(uuid, issueEmail, messageEmail, formEmail) => async () =>
		backendFunction('companyUpdateManagerSettings', {
			uuid,
			issueEmail,
			messageEmail,
			formEmail,
		})

export const _updateMaintenanceSettings =
	(uuid, name, formEmail, tel, telOnCall, address, maintenancePartner) =>
	async () =>
		backendFunction('companyUpdateMaintenanceSettings', {
			uuid,
			name,
			formEmail,
			tel,
			telOnCall,
			address,
			maintenancePartner,
		})

export const _updateManagerGeo = (uuid, address, area, zip) => async () =>
	backendFunction('companyUpdateManagerGeo', {
		uuid,
		address,
		area,
		zip,
	})

export const _updateManagerAccess = (uuid, managerUUID) => async () =>
	backendFunction('companyUpdateManagerAccess', {
		uuid,
		managerUUID,
	})

export const _scheduleManagerAccess = (companyUUID, managerUUID, ts) =>
	backendFunction('companyScheduleManagerAccess', {
		companyUUID,
		managerUUID,
		ts,
	})

export const _deleteScheduledManagerAccess = (cronUUID) =>
	backendFunction('companyDeleteScheduledManagerAccess', {
		cronUUID,
	})

export const _requestInvite =
	(companyUUID, displayName, email, role, apartmentUUIDs, phoneNumber) =>
	async () =>
		backendFunction('companyInviteRequestSend', {
			companyUUID,
			displayName,
			email,
			role,
			apartmentUUIDs,
			phoneNumber,
		})

export const _inviteUser =
	(companyUUID, displayName, email, role, apartmentUUIDs) => async () =>
		backendFunction('companyInviteSend', {
			companyUUID,
			displayName,
			email,
			role,
			apartmentUUIDs,
		})

export const _inviteUserBatch = (users, managerUUID) => async () =>
	backendFunction('companyInviteSendBatch', {
		users,
		managerUUID,
	})

export const _importUsers = (users) =>
	async function (dispatch, getState, getFirebase) {
		ReactGA.event({
			category: 'feature-usage',
			action: 'company-user-import',
		})

		try {
			const {
				app: { selectedCompanyUUID: companyUUID },
			} = getState()
			const callable = httpsCallable(functions, 'companyInviteSend')
			const operations = users.map((u) => {
				const request = {
					companyUUID,
					apartmentUUIDs: u.apartmentUUIDs,
					displayName: u.name,
					email: u.email,
					role: u.role || config.userRoles.userShareholder, //TODO: fix
				}
				if (u.tampuuriPersonId) {
					request.tampuuriPersonId = u.tampuuriPersonId
				}
				if (u.htjPersonId) {
					request.htjPersonId = u.htjPersonId
				}
				if (u.hausvisePartyId) {
					request.hausvisePartyId = u.hausvisePartyId
				}
				return callable(request)
			})

			const result = await Promise.all(operations)
			const errored = result.some((r) => !r)
			if (errored) {
				console.error('failed importing users')
				return { success: false }
			}
			return result
		} catch (ex) {
			console.log(ex)
			if (window.sentry) window.sentry.captureException(ex)
			return { success: false, ex }
		}
	}

export const _redeemInvite = (uuid) => async () =>
	backendFunction('companyInviteRedeem', {
		uuid,
	})

export const _deleteInvite = (uuid) => async () =>
	backendFunction('companyInviteDelete', {
		uuid,
	})

export const _resendInvite = (uuid) => async () =>
	backendFunction('companyInviteResend', {
		uuid,
	})

export const _resendInviteBatch = () => async (dispatch, getState) =>
	backendFunction('companyInviteResendBatch', {
		companyUUID: getState().app.selectedCompanyUUID,
	})

export const _kickUser = (userUUID, companyUUID) => async () =>
	backendFunction('companyKickUser', {
		userUUID,
		companyUUID,
	})

export const _kickAllUsers = (companyUUID) => async () =>
	backendFunction('companyKickAllUsers', {
		companyUUID,
	})

export const _setUserRole = (userUUID, companyUUID, role) => async () =>
	backendFunction('companySetUserRole', {
		userUUID,
		companyUUID,
		role,
	})

export const _setInviteRole = (inviteUUID, companyUUID, role) => async () =>
	backendFunction('companyInviteSetRole', {
		inviteUUID,
		companyUUID,
		role,
	})

export const _inviteRequestApprove = (uuid) => async () =>
	backendFunction('companyInviteRequestApprove', {
		uuid,
	})

export const _inviteRequestApproveTampuuri =
	(uuid, tampuuriPersonId) => async () =>
		backendFunction('companyInviteRequestApproveTampuuri', {
			uuid,
			tampuuriPersonId,
		})

export const _inviteRequestReject = (uuid, message) => async () =>
	backendFunction('companyInviteRequestReject', {
		uuid,
		message,
	})

export const _inviteRequestDelete = (uuid) => async () =>
	backendFunction('companyInviteRequestDelete', {
		uuid,
	})

export const _startTrial = (uuid) => async () =>
	backendFunction('companyStartTrial', {
		uuid,
	})

export const _fetchContacts = () => async (dispatch, getState) =>
	backendFunction('companyFetchContacts', {
		companyUUID: getState().app.selectedCompanyUUID,
	})

export const _workstationSyncIdUpdate =
	(workstationSyncId, documentAddNotificationsDisabled) =>
	async (dispatch, getState) =>
		backendFunction('companyWorkstationSyncIdUpdate', {
			companyUUID: getState().app.selectedCompanyUUID,
			workstationSyncId,
			documentAddNotificationsDisabled,
		})

export const _desktopSyncForce = () => async (dispatch, getState) =>
	backendFunction(
		'companyDesktopSyncForce',
		{
			companyUUID: getState().app.selectedCompanyUUID,
		},
		{
			timeout: 360000,
		},
	)

export const _desktopSyncForceReverse = () => async (dispatch, getState) =>
	backendFunction(
		'companyDesktopSyncForceReverse',
		{
			companyUUID: getState().app.selectedCompanyUUID,
		},
		{
			timeout: 360000,
		},
	)

export const _desktopSyncForceUnpublishedFiles =
	() => async (dispatch, getState) =>
		backendFunction(
			'companyDesktopSyncForceUnpublishedFiles',
			{
				companyUUID: getState().app.selectedCompanyUUID,
			},
			{
				timeout: 360000,
			},
		)

export const _desktopSyncForceDelete = () => async (dispatch, getState) =>
	backendFunction(
		'companyDesktopSyncForceDelete',
		{
			companyUUID: getState().app.selectedCompanyUUID,
		},
		{
			timeout: 360000,
		},
	)

export const _companyApartmentRepresentationApproverUpdate =
	(approver) => async (dispatch, getState) =>
		backendFunction('companyApartmentRepresentationApproverUpdate', {
			uuid: getState().app.selectedCompanyUUID,
			approver,
		})

export const _updateFeatures =
	(features, featuresHidden) => async (dispatch, getState) =>
		backendFunction('companyUpdateFeatures', {
			uuid: getState().app.selectedCompanyUUID,
			features,
			featuresHidden,
		})

export const _updateFeatureSettings =
	({ featureName, featureSettings }) =>
	async (dispatch, getState) =>
		backendFunction('companyUpdateFeatureSettings', {
			companyUUID: getState().app.selectedCompanyUUID,
			featureName,
			featureSettings,
		})

export const _updateTrial = (trialStartTs) => async (dispatch, getState) =>
	backendFunction('companyUpdateTrial', {
		uuid: getState().app.selectedCompanyUUID,
		trialStartTs,
	})

export const _managerOptionsUpdate =
	(
		companyUUID,
		assignedManagerEmployeeUID,
		assignedManagerSecretaryUID,
		assignedManagerAccountantUID,
		assignedManagerChargingAccountantUID,
		hiddenFromSearch,
		referenceId,
	) =>
	async (dispatch, getState) =>
		backendFunction('companyManagerOptionsUpdate', {
			uuid: companyUUID,
			assignedManagerEmployeeUID,
			assignedManagerSecretaryUID,
			assignedManagerAccountantUID,
			assignedManagerChargingAccountantUID,
			hiddenFromSearch,
			referenceId,
		})

export const _usersByRoles =
	(roles, getApartments, getConsents) => async (dispatch, getState) =>
		backendFunction('companyUsersByRoles', {
			companyUUID: getState().app.selectedCompanyUUID,
			roles,
			getApartments,
			getConsents,
		})

export const _userByPIN = (companyUUID, pin) => async () =>
	backendFunction('companyUserByPIN', {
		companyUUID,
		pin,
	})

export const _generatePIN = (companyUUID, userUUID) => async () =>
	backendFunction('companyGeneratePinCode', {
		companyUUID,
		userUUID,
	})

export const _generatePINForAll = (companyUUID) => async () =>
	backendFunction('companyGeneratePinCodeForAll', {
		companyUUID,
	})

export const _sendPIN = (companyUUID, userUUID) => async () =>
	backendFunction('companySendPinCode', {
		companyUUID,
		userUUID,
	})

export const _autogeneratePINsUpdate =
	(companyUUID, autogenerate) => async () =>
		backendFunction('companyAutogeneratePINsUpdate', {
			companyUUID,
			autogenerate,
		})

export const _billingTypeUpdate = (companyUUID, billingType) => async () =>
	backendFunction('companyBillingTypeUpdate', {
		companyUUID,
		billingType,
	})

export const _billingPayerTypeUpdate =
	(companyUUID, billingPayerType) => async () =>
		backendFunction('companyBillingPayerTypeUpdate', {
			companyUUID,
			billingPayerType,
		})

export const _billingBusinessIdUpdate = (companyUUID, businessId) => async () =>
	backendFunction('companyBillingBusinessIdUpdate', {
		companyUUID,
		businessId,
	})

export const _userManagementClaim = (companyUUID) => async () =>
	backendFunction('companyUserManagementClaim', {
		uuid: companyUUID,
	})

export const _userManagementClaimClear = () => async () =>
	backendFunction('companyUserManagementClaimClear', {})

export const _getUserDigitalDeliveryConsentByEmail =
	(companyUUID, email) => async () =>
		backendFunction('companyUserDigitalDeliveryConsentByEmail', {
			companyUUID,
			email,
		})

export const _getUserDigitalDeliveryConsentByEmailBatch =
	(companyUUID, emails) => async () =>
		backendFunction('companyUserDigitalDeliveryConsentByEmailBatch', {
			companyUUID,
			emails,
		})

export const _updateSharedBoardEmailAddress =
	(companyUUID, sharedBoardEmailAddress) => async () =>
		backendFunction('companyUpdateSharedBoardEmailAddress', {
			uuid: companyUUID,
			sharedBoardEmailAddress,
		})

export const _delete = async (companyUUID) =>
	backendFunction('companyDelete', { companyUUID })

export const _testDataCreate = async (managerUUID, count) =>
	backendFunction('companyTestDataCreate', { managerUUID, count })

export const _testDataDelete = async (managerUUID) =>
	backendFunction('companyTestDataDelete', { managerUUID })

const getToken = async () => {
	const token = await getIdToken(getAuth(firebaseApp).currentUser)
	return token
}
