import LetterStatus from './LetterStatus'
import BaseDialog from '../BaseDialog'

function LetterStatusDialog({ visible, onHide, recipient, postalBatch }) {
	return (
		<BaseDialog visible={visible} onHide={onHide} title={recipient.displayName}>
			<LetterStatus postalBatch={postalBatch} recipient={recipient} />
		</BaseDialog>
	)
}

export default LetterStatusDialog
