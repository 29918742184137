import { useIntl } from 'react-intl'
import { CircularProgress, SelectionControlGroup } from 'react-md'
import { isEmpty, isLoaded } from 'react-redux-firebase'
import useMessageServiceIntegrations from 'ui/MessageService/hooks/useMessageServiceIntegrations'
import styled from 'styled-components'

const Title = styled.h2`
	font-size: 1.3rem;
	margin-bottom: 8px;
`

function MessageServiceIntegrations({ deliveryMethodsIntegrations = [] }) {
	const intl = useIntl()
	const messageServiceIntegrations = useMessageServiceIntegrations()

	if (!isLoaded(messageServiceIntegrations)) {
		return (
			<CircularProgress
				id={'message-service-integrations-progress'}
				style={{ marginTop: 32 }}
			/>
		)
	}

	if (
		isEmpty(deliveryMethodsIntegrations) ||
		isEmpty(messageServiceIntegrations)
	) {
		return null
	}

	return (
		<>
			<Title>
				{intl.formatMessage({
					defaultMessage: 'Integraatiot',
					description:
						'Title of the message service integrations section in the message service delivery methods.',
				})}
			</Title>
			<SelectionControlGroup
				id="message-service-delivery-methods"
				name="message-service-delivery-methods"
				type="checkbox"
				controls={deliveryMethodsIntegrations.map((uuid) => ({
					label:
						messageServiceIntegrations.find((i) => i.uuid === uuid)?.title ||
						intl.formatMessage({
							defaultMessage: 'Poistettu',
							description:
								'Error message if an integration is not found in the message service summary.',
						}),
					value: uuid,
					disabled: true,
				}))}
				value={deliveryMethodsIntegrations?.join(',') || ''}
				onChange={() => {}}
			/>
		</>
	)
}

export default MessageServiceIntegrations
