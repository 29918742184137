import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { Toolbar, Button, Checkbox } from 'react-md'

import * as fivaldiActions from 'state/fivaldi-actions'
import * as notifyActions from 'state/notifyActions'
import LoadingState from 'ui/components/LoadingState'
import { generateRecipientUUID } from './utils'
import { injectIntl } from 'react-intl'
import { GENERIC_ERROR_NOTIFICATION } from 'ui/messages'
import WithDialogs from 'ui/components/dialogs/WithDialogs'
import ViiluDialog from 'ui/components/ViiluDialog'

const STEPS = {
	INTRO: 'intro',
	IMPORT_CONFIRM: 'import_confirm',
}

const getInitialState = () => ({
	processing: false,
	step: STEPS.INTRO,
	fetchTenants: false,
	fetchShareholders: false,
})

class ImporterDialogFivaldi extends Component {
	state = getInitialState()

	getMissingManagerIntegrationMessage = () => {
		const { intl } = this.props
		return intl.formatMessage({
			defaultMessage:
				'Tämä toiminto vaatii isännöintitoimiston integraation Fivaldiin. Ota tarvittaessa yhteys asiakaspalveluumme asiakaspalvelu@vii.lu.',
			description:
				'Message shown in the dialog for importing from Fivaldi to the message service table if the Fivaldi integration is not enabled.',
		})
	}

	getMissingCompanyIntegrationMessage = () => {
		const { intl } = this.props
		return intl.formatMessage({
			defaultMessage:
				'Tämä toiminto vaatii kohteen integraation Fivaldiin. Ota tarvittaessa yhteys asiakaspalveluumme asiakaspalvelu@vii.lu.',
			description:
				'Message shown in the dialog for importing from Fivaldi to the message service table if the Fivaldi integration is not enabled.',
		})
	}

	getMissingBusinessIdMessage = () => {
		const { intl } = this.props
		return intl.formatMessage({
			defaultMessage:
				'Tämä tominto vaatii Y-tunnuksen syöttämistä talosivun asetuksiin.',
			description:
				'Message shown for missing business id in the dialog for importing from Fivaldi to the message service table.',
		})
	}

	fivaldiImport = async () => {
		const { fetchTenants, fetchShareholders } = this.state
		const { _showInfoNotification, intl, company, onSuccess, alert } =
			this.props

		const managerUUID = company.managerUUID
		const businessId = company.businessId
		if (!managerUUID) {
			await alert(this.getMissingManagerIntegrationMessage())
			return false
		}
		if (!businessId) {
			await alert(this.getMissingBusinessIdMessage())
			return false
		}

		let fivaldiEnabled = false
		if (
			company &&
			company.integrations &&
			company.integrations.fivaldi &&
			company.integrations.fivaldi[managerUUID] &&
			company.integrations.fivaldi[managerUUID].id
		) {
			fivaldiEnabled = true
		}
		if (!fivaldiEnabled) {
			await alert(this.getMissingCompanyIntegrationMessage())
			return false
		}

		this.setState({ processing: true })

		let shareholderResults
		let tenantResults
		const GET_VIILU_CONSENTS = true

		if (fetchShareholders && fetchTenants) {
			const result = await fivaldiActions._getShareholdersAndTenants(
				managerUUID,
				company.uuid,
				GET_VIILU_CONSENTS,
			)
			if (!result || !result.data) {
				_showInfoNotification('❌ ' + GENERIC_ERROR_NOTIFICATION(intl))
				return false
			}
			shareholderResults = {
				data: result.data.shareholders,
			}
			tenantResults = {
				data: result.data.tenants,
			}
		} else if (fetchShareholders) {
			shareholderResults = await fivaldiActions._getShareholders(
				managerUUID,
				company.uuid,
				GET_VIILU_CONSENTS,
			)
		} else if (fetchTenants) {
			tenantResults = await fivaldiActions._getTenants(
				managerUUID,
				company.uuid,
			)
		}

		this.setState({ processing: false })

		if (
			fetchShareholders &&
			(!shareholderResults || !shareholderResults.data)
		) {
			_showInfoNotification('❌ ' + GENERIC_ERROR_NOTIFICATION(intl))
			return false
		}

		if (fetchTenants && (!tenantResults || !tenantResults.data)) {
			_showInfoNotification('❌ ' + GENERIC_ERROR_NOTIFICATION(intl))
			return false
		}

		const combined = []

		if (fetchShareholders && fetchTenants) {
			// The role 'user_shareholder' will override 'user_tenant' in case the user is both
			const shareholders = shareholderResults.data
			const tenants = tenantResults.data
			// Push shareholders and merge in any tenant role apartments if a shareholder is also a tenant
			shareholders.forEach((s) => {
				// Match by ID or email
				const matchingTenantFound = tenants.find(
					(t) => t.fivaldiPersonId === s.fivaldiPersonId,
				)
				if (matchingTenantFound) {
					const mergedApartments = [...s.apartments]
					matchingTenantFound.apartments.forEach((ta) => {
						const found = s.apartments.find(
							(sa) => sa.identifier === ta.identifier,
						)
						if (!found) {
							mergedApartments.push(ta)
						}
					})
					const merged = { ...s, apartments: mergedApartments }
					combined.push(merged)
					return
				}
				combined.push(s)
			})

			// Push any remaining tenants
			tenants.forEach((t) => {
				const matchingShareholderFound = shareholders.find(
					(s) => s.fivaldiPersonId === t.fivaldiPersonId,
				)
				if (!matchingShareholderFound) {
					combined.push(t)
				}
			})
		} else if (fetchShareholders) {
			const shareholders = shareholderResults.data
			shareholders.forEach((s) => combined.push(s))
		} else if (fetchTenants) {
			const tenants = tenantResults.data
			tenants.forEach((t) => combined.push(t))
		}

		const invitees = combined.map((user) => ({
			...user,
			uuid: generateRecipientUUID(),
		}))
		onSuccess(invitees)
		this.onHide()
		return true
	}

	onHide = () => {
		this.setState(getInitialState())
		this.props.onHide()
	}

	renderToolbar = (dialogTitle) => {
		return (
			<Toolbar
				colored
				title={dialogTitle}
				style={{
					background: 'var(--color-secondary-dark)',
					position: 'fixed',
					width: '100%',
					zIndex: 2,
				}}
				nav={
					<Button icon onClick={this.onHide}>
						close
					</Button>
				}
			/>
		)
	}

	renderStep = () => {
		const { step, fetchTenants, fetchShareholders } = this.state
		const { company, intl } = this.props

		if (step === STEPS.INTRO) {
			return (
				<>
					<div className="flex-column">
						<p>
							{intl.formatMessage(
								{
									defaultMessage:
										'Voit tuoda asukas ja/tai osakastiedot suoraan Fivaldista mikäli Fivaldi-rajapinta on otettu käyttöön. Tiedot haetaan Y-tunnuksen {businessId} tai kohteen Fivaldi-tunnuksen perusteella.',
									description:
										'Instructional paragraph in the dialog for importing from Fivaldi to the message service table.',
								},
								{ businessId: <strong>{company.businessId}</strong> },
							)}
						</p>
						<Checkbox
							id="fivaldi-imported-tenant"
							label={<p style={{ margin: 0 }}>Asukkaat</p>}
							name="Asukkaat"
							checked={fetchTenants}
							onChange={() => {}}
							onClick={() =>
								this.setState({ fetchTenants: !this.state.fetchTenants })
							}
							style={{ marginTop: 32 }}
						/>
						<Checkbox
							id="fivaldi-imported-shareholder"
							label={<p style={{ margin: 0 }}>Osakkaat</p>}
							name="Osakkaat"
							checked={fetchShareholders}
							onChange={() => {}}
							onClick={() =>
								this.setState({
									fetchShareholders: !this.state.fetchShareholders,
								})
							}
						/>
						<div
							className="flex-row margin-top"
							style={{ justifyContent: 'flex-end' }}
						>
							<Button
								raised
								secondary
								onClick={() => {
									this.setState({ step: STEPS.IMPORT_CONFIRM })
								}}
								disabled={!fetchTenants && !fetchShareholders}
							>
								{intl.formatMessage({
									defaultMessage: 'Seuraava',
									description:
										'Label for the button that proceeds to the next step in the dialog for importing from Fivaldi to the message service table.',
								})}
							</Button>
						</div>
					</div>
				</>
			)
		} else {
			return (
				<>
					<div className="flex-column">
						<p>
							{intl.formatMessage(
								{
									defaultMessage:
										'Tiedot haetaan Y-tunnuksen {businessId} tai kohteen Fivaldi-tunnuksen perusteella painettuasia TUO TIEDOT FIVALDISTA -painiketta.',
									description:
										'Instructional paragraph in the dialog for importing from Fivaldi to the message service table.',
								},
								{ businessId: <strong>{company.businessId}</strong> },
							)}
						</p>
						<div
							className="flex-row margin-top"
							style={{ justifyContent: 'flex-end' }}
						>
							<Button raised secondary onClick={this.fivaldiImport}>
								{intl.formatMessage({
									defaultMessage: 'Tuo tiedot Fivaldista',
									description:
										'Label for the button that imports from Fivaldi to the message service table.',
								})}
							</Button>
						</div>
					</div>
				</>
			)
		}
	}

	render() {
		const { company, intl } = this.props
		const { processing } = this.state

		const dialogTitle = intl.formatMessage({
			defaultMessage: 'Tuo Fivaldin henkilörekisteristä',
			description:
				'Title of the dialog for importing invitees from Fivaldi to the message service table.',
		})

		return (
			<ViiluDialog
				id="responsive-dialog"
				aria-label={dialogTitle}
				visible={true}
				onHide={this.onHide}
				focusOnMount={true}
				containFocus={true}
				dialogClassName="responsive-dialog"
				contentClassName="responsive-dialog-content"
				autosizeContent={false}
				disableScrollLocking
				modal
				paddedContent
				onClick={(e) => {
					e.preventDefault()
					e.stopPropagation()
				}}
				style={{ zIndex: 5000 }}
			>
				{this.renderToolbar(dialogTitle)}
				<section className="md-toolbar-relative" style={{ padding: 24 }}>
					{processing ? (
						<div className="flex-column flex-center">
							<LoadingState />
							<p>Odotetaan Fivaldia...</p>
						</div>
					) : company.managerUUID ? (
						company.businessId ? (
							this.renderStep()
						) : (
							<p>{this.getMissingBusinessIdMessage()}</p>
						)
					) : (
						<p>{this.getMissingManagerIntegrationMessage()}</p>
					)}
				</section>
			</ViiluDialog>
		)
	}
}

ImporterDialogFivaldi.propTypes = {
	onHide: PropTypes.func.isRequired,
	onSuccess: PropTypes.func.isRequired,
	company: PropTypes.object.isRequired,
}

const mapState = () => ({})

const mapDispatchToProps = (dispatch) => ({
	_showInfoNotification: bindActionCreators(notifyActions.info, dispatch),
})

export default compose(
	injectIntl,
	WithDialogs,
	connect(mapState, mapDispatchToProps),
)(ImporterDialogFivaldi)
