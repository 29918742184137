import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'

import { StyledFontIcon, BaseButton } from './styles'

const StyledButton = styled(BaseButton)`
	background: rgba(220, 80, 80, 1);
	color: rgba(255, 255, 255, 0.87);
	font-size: 13px;
`

const EmailButton = ({ onClick }) => {
	return (
		<StyledButton
			raised
			onClick={onClick}
			iconEl={<StyledFontIcon>email</StyledFontIcon>}
		>
			<FormattedMessage
				defaultMessage="Sähköpostiosoitteella"
				description="Button that selects the email authentication flow"
			/>
		</StyledButton>
	)
}

export default EmailButton
