import { Button, Toolbar } from 'react-md'
import ViiluDialog from 'ui/components/ViiluDialog'

function BaseDialog({ visible, onHide, title, children }) {
	return (
		<ViiluDialog
			id="message-service-process-status-dialog"
			dialogClassName="responsive-dialog"
			contentClassName="responsive-dialog-content"
			visible={visible}
			onHide={onHide}
			focusOnMount={false}
			actions={null}
		>
			<Toolbar
				colored
				title={title}
				fixed
				style={{
					background: 'var(--color-secondary-dark)',
					boxShadow: 'none',
				}}
				nav={
					<Button icon onClick={onHide}>
						close
					</Button>
				}
			/>

			<section className="md-toolbar-relative" style={{ padding: 16 }}>
				{children}
			</section>
		</ViiluDialog>
	)
}

export default BaseDialog
