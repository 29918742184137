import {
	Button,
	CardActions,
	CardText,
	CardTitle,
	CircularProgress,
	FontIcon,
} from 'react-md'
import { StandardCard } from 'ui/StyledComponents/Cards'
import AuthorizationKeysDialog from './AuthorizationKeysDialog'
import { useState } from 'react'
import { _htjSetEnabled, _htjCompanyLinkMass } from 'state/htj-actions'
import { useDispatch } from 'react-redux'
import * as notifyActions from 'state/notifyActions'
import { useIntl } from 'react-intl'
import {
	GENERIC_ERROR_NOTIFICATION,
	GENERIC_SAVED_NOTIFICATION,
} from 'ui/messages'
import WithSelectedManagerRole from 'ui/components/WithSelectedManagerRole'

function HtjIntegrationCard({ selectedManager, managerRole }) {
	const intl = useIntl()
	const dispatch = useDispatch()
	const [loading, setIsLoading] = useState(false)
	const [keysDialogVisible, setKeysDialogVisible] = useState(false)
	const integrationEnabled = selectedManager?.integrations?.htj?.enabled
	const isManagerMaster = managerRole === 'manager_master'

	const toggleEnabled = async () => {
		setIsLoading(true)
		const result = await _htjSetEnabled(
			selectedManager.uuid,
			!integrationEnabled,
		)
		setIsLoading(false)
		if (result?.success === false) {
			dispatch(notifyActions.error(GENERIC_ERROR_NOTIFICATION(intl)))
		} else {
			dispatch(notifyActions.success(GENERIC_SAVED_NOTIFICATION(intl)))
		}
	}

	const massLink = async () => {
		setIsLoading(true)
		const result = await _htjCompanyLinkMass(selectedManager.uuid)
		setIsLoading(false)
		if (result?.success === false) {
			dispatch(notifyActions.error(GENERIC_ERROR_NOTIFICATION(intl)))
		} else {
			dispatch(notifyActions.success(GENERIC_SAVED_NOTIFICATION(intl)))
		}
	}

	const renderActions = () => {
		if (loading) {
			return <CircularProgress id="htj-progress" style={{ marginLeft: 32 }} />
		}

		const actionsDisabled = !isManagerMaster

		if (integrationEnabled) {
			return (
				<>
					<Button
						flat
						secondary
						disabled={actionsDisabled}
						onClick={toggleEnabled}
					>
						{intl.formatMessage({
							defaultMessage: 'Poista käytöstä',
							description:
								'Label for a button that disables an integration in the manager settings.',
						})}
					</Button>
					<Button
						flat
						secondary
						disabled={actionsDisabled}
						onClick={() => setKeysDialogVisible(true)}
						iconEl={<FontIcon>key</FontIcon>}
					>
						{intl.formatMessage({
							defaultMessage: 'Valtuutusavaimet',
							description: 'Label for HTJ authorization keys.',
						})}
					</Button>
					<Button
						flat
						secondary
						disabled={actionsDisabled}
						onClick={() => massLink()}
						iconEl={<FontIcon>apartment</FontIcon>}
					>
						{intl.formatMessage({
							defaultMessage: 'Massalinkitä',
							description: 'Label for HTJ mass linking button.',
						})}
					</Button>
				</>
			)
		}

		return (
			<Button flat secondary disabled={actionsDisabled} onClick={toggleEnabled}>
				{intl.formatMessage({
					defaultMessage: 'Ota käyttöön',
					description:
						'Label for a button that enables an integration in the manager settings.',
				})}
			</Button>
		)
	}

	return (
		<div className="full-width">
			<StandardCard
				className="margin-bottom full-width"
				style={integrationEnabled ? { background: 'rgb(240,255,240)' } : {}}
			>
				<CardTitle
					title={intl.formatMessage({
						defaultMessage: 'Huoneistotietojärjestelmä',
						description: 'Title for HTJ integration.',
					})}
				/>
				<CardText>
					{intl.formatMessage({
						defaultMessage:
							'Voit ottaa HTJ-integraation käyttöön, joka mahdollistaa esimerkiksi kohde- ja huoneistotietojen tuonnin suoraan rajapinnan kautta.',
						description:
							'Description for the HTJ integration in the manager settings.',
					})}
				</CardText>
				<CardActions>{renderActions()}</CardActions>
			</StandardCard>

			{integrationEnabled && (
				<AuthorizationKeysDialog
					selectedManagerUUID={selectedManager.uuid}
					visible={keysDialogVisible}
					onHide={() => setKeysDialogVisible(false)}
				/>
			)}
		</div>
	)
}

export default WithSelectedManagerRole(HtjIntegrationCard)
