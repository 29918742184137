import { CSSProperties, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { TextField } from 'react-md'
import styled from 'styled-components'

type MonthInfo = {
	key: string
	days: number
}

const months: Record<number, MonthInfo> = {
	1: { key: 'jan', days: 31 },
	// Disregard leap year extra day for february to make things simple
	2: { key: 'feb', days: 28 },
	3: { key: 'mar', days: 31 },
	4: { key: 'apr', days: 30 },
	5: { key: 'may', days: 31 },
	6: { key: 'june', days: 30 },
	7: { key: 'july', days: 31 },
	8: { key: 'aug', days: 31 },
	9: { key: 'sep', days: 30 },
	10: { key: 'oct', days: 31 },
	11: { key: 'nov', days: 30 },
	12: { key: 'dec', days: 31 },
}

const Container = styled.div`
	display: flex;
	flex-flow: row nowrap;
	gap: 0.75rem;
`

export type DayMonthValue = {
	month: number
	day: number
}

type DayMonthPickerProps = {
	id: string
	value: DayMonthValue
	onChange: (value: DayMonthValue) => void
	hideLabels?: boolean
	style?: CSSProperties
	disabled?: boolean
	required?: boolean
}

function DayMonthPicker(props: DayMonthPickerProps) {
	const { id, value, onChange, hideLabels, style, disabled, required } = props
	const intl = useIntl()
	const [maxDays, setMaxDays] = useState(months[value.month]?.days ?? 28)

	useEffect(() => {
		// When changing the month, we don't want the selected days to exceed the max
		// possible days in the new selected month, therefore change the days value
		// if new selected month has less max days than previously selected
		const currentMaxDays = months[value.month]?.days ?? 28
		if (value.day > currentMaxDays) {
			onChange({
				...value,
				day: currentMaxDays,
			})
		}
		setMaxDays(currentMaxDays)
	}, [value, onChange])

	return (
		<Container style={style}>
			<TextField
				id={`${id}-day-input`}
				inputStyle={{ marginTop: 0 }}
				label={
					hideLabels
						? null
						: intl.formatMessage({
								defaultMessage: 'Päivä',
								description: 'Label for day',
						  })
				}
				type="number"
				min={1}
				max={maxDays}
				value={value.day}
				disabled={disabled}
				required={required}
				onChange={(dayValue) => {
					const day = Number(dayValue)
					if (!isNaN(day) && day > 0 && day <= maxDays) {
						onChange({ ...value, day })
					}
				}}
			/>
			<TextField
				id={`${id}-month-input`}
				inputStyle={{ marginTop: 0 }}
				label={
					hideLabels
						? null
						: intl.formatMessage({
								defaultMessage: 'Kuukausi',
								description: 'Label for month',
						  })
				}
				type="number"
				min={1}
				max={12}
				value={value.month}
				disabled={disabled}
				required={required}
				onChange={(monthValue) => {
					const month = Number(monthValue)
					if (!isNaN(month) && month > 0 && month <= 12) {
						onChange({ ...value, month })
					}
				}}
			/>
		</Container>
	)
}

export default DayMonthPicker
