const initialState = {
	sortBy: 'name',
	sortAscending: false,
}

function reducer(state = initialState, action) {
	if (action.type === 'UI_MANAGER_COMPANIES_SORTBY_SET') {
		return { ...state, sortBy: action.sortBy }
	} else if (action.type === 'UI_MANAGER_COMPANIES_SORTASCENDING_SET') {
		return { ...state, sortAscending: action.sortAscending }
	}
	return state
}

export default reducer
