import { IntlShape } from 'react-intl'
import type { PaymentProduct, PaymentProductFormData } from './types'

export const validateFormData = async (
	formData: PaymentProductFormData,
	alert: (message: string) => Promise<void>,
	intl: IntlShape,
	paymentProducts: PaymentProduct[],
) => {
	const {
		uuid,
		name,
		description,
		priority,
		number,
		billingBasisDescription,
		startDate,
		endDate,
	} = formData

	let { prices } = formData
	prices = prices
		.filter((p) => !p.deleted)
		.sort(
			(a, b) =>
				new Date(a.startDate).getTime() - new Date(b.startDate).getTime(),
		)

	if (!name) {
		await alert(
			intl.formatMessage({
				defaultMessage: 'Nimi puuttuu.',
				description:
					'Error message for missing name in the payment product form.',
			}),
		)
		return false
	}

	if (!description) {
		await alert(
			intl.formatMessage({
				defaultMessage: 'Selite puuttuu.',
				description:
					'Error message for missing description in the payment product form.',
			}),
		)
		return false
	}

	if (!priority || priority < 1) {
		await alert(
			intl.formatMessage({
				defaultMessage: 'Kohdistusjärjestys puuttuu (oltava yli nollan).',
				description:
					'Error message for missing priority in the payment product form.',
			}),
		)
		return false
	}

	if (
		paymentProducts
			.filter((pp) => pp.uuid !== uuid)
			.some((pp) => pp.priority === priority)
	) {
		await alert(
			intl.formatMessage({
				defaultMessage: 'Kohdistusjärjestyksen on oltava uniikki',
				description:
					'Error message for unique priority in the payment product form.',
			}),
		)
		return false
	}

	if (!number || number < 1) {
		await alert(
			intl.formatMessage({
				defaultMessage: 'Numero puuttuu (oltava yli nollan).',
				description:
					'Error message for missing number in the payment product form.',
			}),
		)
		return false
	}

	if (
		paymentProducts
			.filter((pp) => pp.uuid !== uuid)
			.some((pp) => pp.number === number)
	) {
		await alert(
			intl.formatMessage({
				defaultMessage: 'Numeron on oltava uniikki',
				description:
					'Error message for unique number in the payment product form.',
			}),
		)
		return false
	}

	if (!billingBasisDescription) {
		await alert(
			intl.formatMessage({
				defaultMessage: 'Veloitusperusteen selite puuttuu.',
				description:
					'Error message for missing billingBasisDescription in the payment product form.',
			}),
		)
		return false
	}

	if (!startDate) {
		await alert(
			intl.formatMessage({
				defaultMessage: 'Alkupäivämäärä puuttuu.',
				description:
					'Error message for missing startDate in the payment product form.',
			}),
		)
		return false
	}

	if (startDate && endDate && endDate < startDate) {
		await alert(
			intl.formatMessage({
				defaultMessage: 'Maksulajin loppupäivämäärä on ennen alkupäivämäärää.',
				description:
					'Error message for endDate before startDate in the payment product form.',
			}),
		)
		return false
	}

	if (
		prices.some((price) => !price.price || price.price < 0 || !price.startDate)
	) {
		await alert(
			intl.formatMessage({
				defaultMessage: 'Lisää hinnat ja niiden alkupäivämäärät.',
				description:
					'Error message for missing price details in the payment product form.',
			}),
		)
		return false
	}

	if (
		prices.some(
			(price) =>
				price.startDate && price.endDate && price.endDate < price.startDate,
		)
	) {
		await alert(
			intl.formatMessage({
				defaultMessage:
					'Tarkista että kaikkien hintojen alkupäivämäärä on ennen loppupäivämäärää.',
				description: 'Error message for invalid price date ranges.',
			}),
		)
		return false
	}

	const pricesWithNullEndDate = prices.filter((price) => !price.endDate)
	if (pricesWithNullEndDate.length > 1) {
		await alert(
			intl.formatMessage({
				defaultMessage:
					'Tarkista että vain yhdellä hinnalla on tyhjä loppupäivämäärä',
				description: 'Error message for multiple prices with null end date.',
			}),
		)
		return false
	}

	if (pricesWithNullEndDate.length === 1) {
		const nullEndDatePrice = pricesWithNullEndDate[0]
		const nullEndDatePriceStartDate = new Date(nullEndDatePrice.startDate)

		const pricesStartingAfter = prices.filter(
			(price) => new Date(price.startDate) > nullEndDatePriceStartDate,
		)

		if (pricesStartingAfter.length > 0) {
			await alert(
				intl.formatMessage({
					defaultMessage:
						'Tarkista että hinnat alkavat ja päättyvät ennen kuin hinta, jonka loppupäivämäärä on tyhjä.',
					description:
						'Error message for prices starting after a null end date price.',
				}),
			)
			return false
		}
	}

	let previousPrice
	for (const current of prices) {
		if (
			previousPrice &&
			(current.startDate <= previousPrice.endDate ||
				(current.endDate && current.endDate <= previousPrice.startDate))
		) {
			await alert(
				intl.formatMessage({
					defaultMessage:
						'Hintojen päivämäärissä ei saa olla päällekkäisyyksiä.',
					description: 'Error message for overlapping price dates.',
				}),
			)
			return false
		}
		previousPrice = current
	}

	if (endDate) {
		const endDateMatch = prices.some((price) =>
			price.endDate
				? new Date(price.endDate).getTime() === new Date(endDate).getTime()
				: true,
		)

		if (!endDateMatch) {
			await alert(
				intl.formatMessage({
					defaultMessage: 'Hintojen tulee kattaa tuotteen päättymispäivämäärä.',
					description:
						'Error message for missing price coverage up to the product end date.',
				}),
			)
			return false
		}
	} else {
		const hasActivePrice = prices.some((price) => !price.endDate)

		if (!hasActivePrice) {
			await alert(
				intl.formatMessage({
					defaultMessage:
						'Aktiiviselle tuotteelle täytyy olla voimassa oleva hinta.',
					description:
						'Error message for missing an ongoing price for an active product.',
				}),
			)
			return false
		}
	}

	for (let i = 0; i < prices.length - 1; i++) {
		const currentPrice = prices[i]
		const nextPrice = prices[i + 1]

		if (
			currentPrice.endDate &&
			new Date(currentPrice.endDate).getTime() + 24 * 60 * 60 * 1000 !==
				new Date(nextPrice.startDate).getTime()
		) {
			await alert(
				intl.formatMessage({
					defaultMessage: 'Hintojen päivämäärissä ei saa olla aukkoja.',
					description: 'Error message for gaps in the price dates.',
				}),
			)
			return false
		}
	}

	const hasMatchingStartDate = prices.some(
		(price) =>
			new Date(price.startDate).getTime() === new Date(startDate).getTime(),
	)

	if (!hasMatchingStartDate) {
		await alert(
			intl.formatMessage({
				defaultMessage:
					'Maksulajin ensimmäisen hinnan pitää alkaa samana päivänä, kun maksulaji alkaa.',
				description:
					'Error message for price not matching the product start date.',
			}),
		)
		return false
	}

	return true
}
