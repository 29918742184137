import { useIntl } from 'react-intl'
import DataTable, {
	TableBody,
	TableColumn,
	TableHeader,
	TableRow,
} from 'react-md/lib/DataTables'
import OverflowGradient from 'ui/components/OverflowGradient'
import { TableContainer } from 'ui/StyledComponents/Tables'
import useCompanyApartments from 'util/hooks/useCompanyApartments'
import { loanApartmentShareGroupHeaders } from './loanApartmentShareGroupHeaders'
import LoanApartmentShareGroupsTableRow from './LoanApartmentShareGroupTableRow'
import type { LoanApartmentWithShareholderFields } from '../types'
import { useSelector } from 'react-redux'

type LoanApartmentShareGroupsTableProps = {
	loanApartments: LoanApartmentWithShareholderFields[]
}

function LoanApartmentShareGroupsTable(
	props: LoanApartmentShareGroupsTableProps,
) {
	const selectedFinanceCompanyUUID = useSelector(
		({ app }: any) => app?.selectedFinanceCompanyUUID,
	)

	const { loanApartments } = props
	const { companyApartments } = useCompanyApartments(selectedFinanceCompanyUUID)
	const intl = useIntl()

	const renderTableHeaders = () => {
		return (
			<TableRow>
				{loanApartmentShareGroupHeaders.map((header) => (
					<TableColumn key={header.key}>
						<p className="text-strong text-subtle">{header.title(intl)}</p>
					</TableColumn>
				))}
				<TableColumn key={'children-header'}></TableColumn>
			</TableRow>
		)
	}

	const renderTableRows = () => {
		const rows = loanApartments
			.map((apt) => {
				return (
					<LoanApartmentShareGroupsTableRow key={apt.uuid} apartment={apt} />
				)
			})
			.concat(
				<tr
					key="empty-row"
					className="full-width"
					style={{ height: 100, background: 'white' }}
				></tr>,
			)
		return rows
	}

	return (
		<TableContainer>
			{companyApartments ? (
				<TableContainer>
					<DataTable
						baseId="loan-apartment-sharegroup-table"
						selectableRows
						style={{
							background: 'white',
							maxHeight: 'calc(100vh - 205px)',
						}}
					>
						<TableHeader
							style={{
								position: 'sticky',
								top: 0,
								zIndex: 1,
								background: 'white',
							}}
						>
							{renderTableHeaders()}
						</TableHeader>
						<TableBody>{renderTableRows()}</TableBody>
						<OverflowGradient style={{ bottom: 40 }} />
					</DataTable>
				</TableContainer>
			) : null}
		</TableContainer>
	)
}

export default LoanApartmentShareGroupsTable
