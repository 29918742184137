import React, { Component } from 'react'
import queryString from 'qs'
import { connect } from 'react-redux'
import { compose } from 'redux'

export default (ComponentToWrap) => {
	class QueryParamsComponent extends Component {
		render() {
			if (!this.props.router || !this.props.router.location) {
				return (
					<ComponentToWrap {...this.props} queryParams={{}} hashParams={{}} />
				)
			}
			const sanitizedQueryString = this.props.router.location.search.replace(
				'?',
				'',
			)
			let parsedSearch = queryString.parse(sanitizedQueryString)
			const hashes = this.props.router.location.hash.split('#')
			const parsedHash = {}
			hashes.forEach((el) => {
				const sanitized = el.replace('#', '')
				parsedHash[sanitized] = true
			})
			if (parsedSearch.obs) {
				try {
					const decoded = decodeURIComponent(
						escape(window.atob(parsedSearch.obs)),
					)
					const parsedDecoded = JSON.parse(decoded)
					parsedSearch = {
						...parsedSearch,
						...parsedDecoded,
					}
				} catch (ex) {
					console.log(ex)
				}
			}
			return (
				<ComponentToWrap
					{...this.props}
					queryParams={parsedSearch}
					hashParams={parsedHash}
				/>
			)
		}
	}

	const mapState = ({ router }) => ({ router })

	const mapDispatchToProps = (dispatch) => ({})

	return compose(connect(mapState, mapDispatchToProps))(QueryParamsComponent)
}
