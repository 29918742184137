const initialState = {
	pendingTaskStatusUpdates: [],
}

function reducer(state = initialState, action) {
	if (action.type === 'ADD_PENDING_TASK_STATUS_UPDATE') {
		return {
			...state,
			pendingTaskStatusUpdates: [
				...state.pendingTaskStatusUpdates.filter(
					(existingPending) => existingPending.uuid !== action.uuid,
				),
				{
					uuid: action.uuid,
					status: action.status,
					originalTask: action.originalTask,
					updateTimestamp: action.updateTimestamp,
				},
			],
		}
	} else if (action.type === 'REMOVE_PENDING_TASK_STATUS_UPDATE') {
		return {
			...state,
			pendingTaskStatusUpdates: state.pendingTaskStatusUpdates.filter(
				(pending) =>
					!(
						pending.uuid === action.uuid &&
						pending.updateTimestamp === action.updateTimestamp
					),
			),
		}
	}

	return state
}

export default reducer
