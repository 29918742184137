export const FETCHING_ELECTRONIC_CONSENT = (intl) =>
	intl.formatMessage({
		defaultMessage: 'Haetaan sähköisen osakaspostituksen suostumuksia...',
		description:
			'Notification shown after starting to fetch the electronic mailing agreemenent in the meeting invite dialog.',
	})

export const FETCHING_ELECTRONIC_CONSENT_ERROR = (intl) =>
	intl.formatMessage({
		defaultMessage:
			'Tapahtui virhe sähköisen osakaspostituksen suostumusten haussa.',
		description:
			'Error message shown if the fetching of the electronic mailing agreemenent failed in the meeting invite dialog.',
	})
