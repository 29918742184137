import styled from 'styled-components'

export const CreateFormGrid = styled.form`
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 1.5rem;
	padding: 0 1.5rem;
	padding-bottom: 10rem;
`

export const CreateFormGridItem = styled.div<{ $fullWidth?: boolean }>`
	grid-column: ${(props) => (props.$fullWidth ? 'span 2' : 'span 1')};
`
