import { backendFunction } from './actions-util'

export const _htjSetEnabled = async (managerUUID, enabled) =>
	backendFunction('htjEnabledSet', {
		managerUUID,
		enabled,
	})

export const _htjSaveAuthorizationKeys = (
	managerUUID,
	authorizationKeyManager,
) =>
	backendFunction('htjUpdateAuthorizationKeys', {
		managerUUID,
		authorizationKeyManager,
	})

export const _htjUpdateUserAuthorizationKey = (authorizationKey) =>
	backendFunction('htjUpdateUserAuthorizationKey', {
		authorizationKey,
	})

export const _htjGetCompany = (companyUUID) =>
	backendFunction('htjGetCompany', {
		companyUUID,
	})

export const _htjCompanyLink = async (companyUUID, htj) =>
	backendFunction('htjCompanyLink', {
		companyUUID,
		htj,
	})

export const _htjCompanyLinkMass = async (managerUUID) =>
	backendFunction('htjCompanyLinkMass', {
		managerUUID,
	})

export const _htjGetApartments = (companyUUID) =>
	backendFunction(
		'htjGetApartments',
		{
			companyUUID,
		},
		{
			timeout: 300000,
		},
	)

export const _htjGetShareholders = (companyUUID) =>
	backendFunction(
		'htjGetShareholders',
		{
			companyUUID,
		},
		{
			timeout: 300000,
		},
	)

export const _htjSyncManual = (companyUUID) =>
	backendFunction(
		'htjSyncManual',
		{
			companyUUID,
		},
		{
			timeout: 300000,
		},
	)

export const _htjSyncSet = (companyUUID, enable) =>
	backendFunction('htjSyncSet', {
		companyUUID,
		enable,
	})

export const _htjGetCompanyByBusinessId = (managerUUID, businessId) =>
	backendFunction('htjGetCompanyByBusinessId', {
		managerUUID,
		businessId,
	})

export const _htjCompanyImport = (data) =>
	backendFunction('htjCompanyImport', data, {
		timeout: 300000,
	})
