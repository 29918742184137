import type {
	UpdateAllocations,
	CreatePaymentData,
	PaymentFilters,
} from 'ui/ManagerFinance/Payments/types'
import { generateQueryParams } from 'util/queryUtils'
import { callApi } from './api/finance-api'

export const _getPayments = async (filters: PaymentFilters) => {
	return callApi(`/v1/payments${generateQueryParams(filters)}`, {
		method: 'GET',
	})
}

export const _getPayment = async (uuid: string) => {
	return callApi(`/v1/payment/${uuid}`, {
		method: 'GET',
	})
}

export const _createPayment = async (data: CreatePaymentData) => {
	return callApi(`/v1/payments`, {
		method: 'POST',
		body: {
			...data,
		},
	})
}

export const _deletePayment = async (uuid: string) => {
	return callApi(`/v1/payments/${uuid}`, {
		method: 'DELETE',
	})
}

export const _updatePaymentAllocations = async (
	uuid: string,
	allocations: UpdateAllocations[],
) => {
	return callApi(`/v1/payments/${uuid}/allocations`, {
		method: 'PUT',
		body: {
			allocations: allocations,
		},
	})
}
