import { TableRow, TableColumn, MenuButton, CircularProgress } from 'react-md'
import { paymentHeaders } from './constants'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import type { Payments } from './types'
import { useIntl } from 'react-intl'
import { ApartmentSelectOptions } from '../types'
import { getPaymentRowValue } from './utils'

interface Props {
	payment: Payments
	menuItems: any[] | null
	apartmentOptions: ApartmentSelectOptions[]
	processing: boolean
}

const PaymentRow = ({
	payment,
	menuItems,
	apartmentOptions,
	processing,
}: Props) => {
	const intl = useIntl()
	const dispatch = useDispatch()
	const appendedMenuItems = menuItems ? [...menuItems] : null

	const menu = !appendedMenuItems ? null : (
		<MenuButton
			id={'menu-button'}
			icon
			menuItems={appendedMenuItems}
			centered
			anchor={{
				x: MenuButton.HorizontalAnchors['CENTER'],
				y: MenuButton.VerticalAnchors['CENTER'],
			}}
		>
			more_vert
		</MenuButton>
	)

	const handleOnClick = () => {
		dispatch(push(window.location.pathname + '?puuid=' + payment.uuid))
	}

	const renderTableColumns = () => {
		return paymentHeaders
			.filter((h) => h.show)
			.map((header) => {
				const { key, type } = header
				return (
					<TableColumn
						onClick={() => !processing && handleOnClick()}
						key={header.key}
						style={
							processing
								? {
										opacity: 0.5,
								  }
								: {
										cursor: 'pointer',
								  }
						}
					>
						<p>
							{getPaymentRowValue(payment, key, apartmentOptions, intl, type)}
						</p>
					</TableColumn>
				)
			})
	}

	return (
		<TableRow key={payment.uuid}>
			{renderTableColumns()}
			<TableColumn key={'children'}>
				<div>
					{processing ? (
						<CircularProgress id="progress" style={{ marginTop: 0 }} />
					) : (
						menu
					)}
				</div>
			</TableColumn>
		</TableRow>
	)
}

export default PaymentRow
