import BaseButton, { Status } from '../BaseButton'
import { PostalBatchStatus } from '../../status-dialogs/LetterStatusDialog/status'

const getStatus = (recipient, postalBatch) => {
	if (!recipient.checked || !recipient.checked['letter']) {
		return Status.NOT_SELECTED
	}

	if (!postalBatch) {
		return Status.UNKNOWN
	}

	const letterExists = postalBatch.letters.some((letter) =>
		letter.recipients.some((r) => r.uuid === recipient.uuid),
	)

	if (!letterExists) {
		return Status.UNKNOWN
	}

	if (postalBatch.status === PostalBatchStatus.DELIVERED_SUCCESS) {
		return Status.SENT
	}

	if (
		postalBatch.status === PostalBatchStatus.REGISTERED_FAILURE ||
		postalBatch.status === PostalBatchStatus.PROCESSING_FAILURE ||
		postalBatch.status === PostalBatchStatus.DELIVERED_FAILURE ||
		postalBatch.status === PostalBatchStatus.ABORTED
	) {
		return Status.ERROR
	}

	return Status.IN_PROGRESS
}

function LetterStatusButton({ recipient, postalBatch, onClick }) {
	const status = getStatus(recipient, postalBatch)

	return <BaseButton status={status} onClick={onClick} />
}

export default LetterStatusButton
