import React from 'react'
import PropTypes from 'prop-types'
import { List, CircularProgress, FontIcon, Button } from 'react-md'
import Dropzone from 'react-dropzone'
import imageCompression from 'browser-image-compression'
import styled from 'styled-components'
import { v4 as uuid } from 'uuid'

import './Uploader.css' // classnames needed
import fileIcon from 'svg/mimetypes/other.svg'
import pdfIcon from 'svg/mimetypes/pdf.svg'
import WithDialogs from '../dialogs/WithDialogs'

const UploaderContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: stretch;
	flex-grow: 1;
`

const MAX_FILE_SIZE_MB = 50

class Uploader extends React.Component {
	state = {
		accepted: [],
		rejected: [],
		processing: false,
	}

	onDrop = async (accepted, rejected) => {
		const { alert } = this.props
		const { multiple, maxMB } = this.props
		this.setState({ processing: true })
		const compressionPromises = accepted.map(this.compress)
		const compressed = await Promise.all(compressionPromises)

		const max_size = maxMB || MAX_FILE_SIZE_MB

		let oversized = false
		const validFiles = compressed
			.filter((file) => {
				if (file.size > max_size * (1024 * 1024)) {
					oversized = true
					return false
				}
				return true
			})
			.map((f) => {
				f.uuid = uuid()
				return f
			})

		if (oversized) {
			await alert(
				'Osa tiedostoista oli liian suuria (yli ' +
					max_size +
					'MB) ja ne on poistettu listasta. Yritä pienentää tiedostoja ja yritä uudelleen.',
			)
		}

		const newFiles = []
		if (multiple && Array.isArray(this.state.accepted)) {
			this.state.accepted.forEach((file) => newFiles.push(file))
		}
		if (Array.isArray(validFiles)) {
			validFiles.forEach((file) => {
				const found = newFiles.find(
					(f) => f.name === file.name && f.size === file.size,
				)
				if (!found) {
					newFiles.push(file)
				}
			})
		}

		this.setState({ accepted: newFiles, rejected, processing: false }, () => {
			const allFilesAccepted = accepted.length && !rejected.length
			this.props.onFilesChanged(allFilesAccepted ? newFiles : null)
		})
	}

	removeFile = (event, uuid) => {
		const { accepted } = this.state
		event.preventDefault()
		event.stopPropagation()
		const newFiles = accepted.filter((f) => f.uuid !== uuid)
		this.setState({ accepted: newFiles }, () => {
			this.props.onFilesChanged(newFiles)
		})
	}

	compress = async (file) => {
		const { maxMB, maxDimension } = this.props
		// Only JPEG's are compressed and rotated
		const mimeType = file.type ? file.type.toLowerCase() : ''
		if (
			mimeType !== 'image/jpeg' &&
			mimeType !== 'image/jpg' &&
			mimeType !== 'image/png'
		) {
			return file
		}

		const options = {
			maxSizeMB: maxMB || 5,
			maxWidthOrHeight: maxDimension || 3840,
			useWebWorker: true,
		}

		let compressedFile
		try {
			const blob = await imageCompression(file, options)
			const fileProps = {
				type: blob.type,
				size: blob.size,
				lastModified: file.lastModified,
				lastModifiedDate: file.lastModifiedDate,
			}
			compressedFile = new File([blob], file.name, fileProps)

			await new Promise((resolve) => {
				const reader = new FileReader()
				reader.onload = function (e) {
					compressedFile.preview = e.target.result
					resolve()
				}
				reader.readAsDataURL(compressedFile)
			})
		} catch (error) {
			console.log('Error compressing image')
			return file
		}
		return compressedFile
	}

	renderPreviews = () => {
		const { defaultFiles } = this.props
		const { accepted, processing } = this.state
		const files = accepted.length ? accepted : defaultFiles

		if (processing) {
			return <CircularProgress id="progress" style={{ marginTop: 0 }} />
		}

		return (
			<List
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'stretch',
					width: '100%',
					padding: 0,
				}}
			>
				{files.map((file, index) => {
					let src = fileIcon
					if (
						file.type === 'image/jpeg' ||
						file.type === 'image/png' ||
						file.type === 'image/gif' ||
						file.name.includes('.jpg') ||
						file.name.includes('.jpeg') ||
						file.name.includes('.png') ||
						file.name.includes('.gif')
					) {
						src = file.preview ? file.preview : null
					} else if (file.type === 'application/pdf') {
						src = pdfIcon
					}

					return (
						<div
							key={file.name}
							style={{
								width: '100%',
								padding: 6,
								background: index % 2 === 0 ? '#D3D3D3' : '#FAFAFA',
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								justifyContent: 'space-between',
							}}
						>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									alignItems: 'center',
									justifyContent: 'space-between',
								}}
							>
								<img
									key={file.name}
									style={{
										width: 32,
										height: 'auto',
										maxWidth: 32,
										minWidth: 32,
										marginRight: 16,
									}}
									src={src}
									alt={file.name}
								/>
								<p
									style={{
										whiteSpace: 'normal',
										overflowWrap: 'anywhere',
										fontSize: 14,
									}}
								>
									{file.name}
								</p>
							</div>
							<div>
								<Button
									icon
									onClick={(event) => this.removeFile(event, file.uuid)}
								>
									<FontIcon>delete</FontIcon>
								</Button>
							</div>
						</div>
					)
				})}
			</List>
		)
	}

	render() {
		return (
			<UploaderContainer>
				<Dropzone
					accept={
						this.props.acceptedContentTypes
							? this.props.acceptedContentTypes
							: null
					}
					onDrop={this.onDrop}
					className="uploader-dropzone"
					activeClassName="uploader-dropzone-active"
					rejectClassName="uploader-dropzone-reject"
					acceptClassName="uploader-dropzone-accept"
					multiple={this.props.multiple}
					style={
						this.state.accepted.length || this.props.defaultFiles.length
							? { minHeight: 100 }
							: null
					}
				>
					{({ isDragActive, isDragReject, acceptedFiles, rejectedFiles }) => {
						if (isDragActive) {
							return (
								<div className="uploader-dropzone-result-container">
									<p className="text-center margin-bottom">
										Päästä irti tallentaaksesi tiedostot.
									</p>
								</div>
							)
						}
						if (isDragReject) {
							return (
								<div className="uploader-dropzone-result-container">
									<p className="text-center margin-bottom">
										Tämän muotoisia tiedostoaja ei hyväksytä.
									</p>
								</div>
							)
						}
						if (rejectedFiles.length) {
							return (
								<div className="uploader-dropzone-result-container">
									<p className="text-center margin-bottom">{`${
										rejectedFiles.length
									}/${
										rejectedFiles.length + acceptedFiles.length
									} tiedostoista hylättiin.`}</p>
								</div>
							)
						}
						if (acceptedFiles.length) {
							return (
								<div className="uploader-dropzone-result-container">
									{this.renderPreviews()}
									{acceptedFiles.length > 1 ? (
										<p className="text-center margin-bottom">{`${acceptedFiles.length} tiedostoa valmiina lähetettäväksi`}</p>
									) : (
										<p className="text-center margin-bottom">{`Tiedosto valmiina lähetettäväksi`}</p>
									)}
								</div>
							)
						}
						return (
							<div className="uploader-dropzone-result-container">
								{this.renderPreviews()}
								<span role="img" aria-label="thumbs up">
									👇
								</span>
								<p
									className="text-center margin-bottom"
									style={{ fontSize: '14px' }}
								>
									{this.props.multiple
										? this.props.pluralForm
										: this.props.singularForm}
								</p>
							</div>
						)
					}}
				</Dropzone>
			</UploaderContainer>
		)
	}
}

Uploader.propTypes = {
	onFilesChanged: PropTypes.func.isRequired,
	acceptedContentTypes: PropTypes.string,
	multiple: PropTypes.bool,
	singularForm: PropTypes.string,
	pluralForm: PropTypes.string,
	defaultFiles: PropTypes.array,
}

Uploader.defaultProps = {
	singularForm: 'Voit lisätä tiedoston klikkaamalla tai raahaamalla sen tähän.',
	pluralForm: 'Voit lisätä tiedostot klikkaamalla tai raahaamalla ne tähän.',
	multiple: false,
	defaultFiles: [],
}

export default WithDialogs(Uploader)
