import { injectIntl } from 'react-intl'
import { compose } from 'redux'
import {
	Binding,
	colorOptions,
	plexOptions,
	postalClassOptions,
} from 'ui/MessageService/MessageServiceProcess/steps/Sending/options'
import SummaryItems from '../SummaryItems'

const findOptionLabel = (value, options) => {
	return options.find((o) => o.value === value).label
}

function PostalSummary({ letterCount, postalSettings, intl }) {
	const items = [
		{
			icon: 'email',
			text:
				letterCount === 1
					? intl.formatMessage({
							defaultMessage: '1 kirje',
							description:
								'Singular version of the letter count label in the message service summary step.',
					  })
					: intl.formatMessage(
							{
								defaultMessage: '{letterCount} kirjettä',
								description:
									'Plural version of the letter count label in the message service summary step.',
							},
							{
								letterCount,
							},
					  ),
		},
		{
			icon: 'print',
			text:
				postalSettings.binding === Binding.BOOKLET
					? intl.formatMessage({
							defaultMessage: 'Vihkosidonta',
							description:
								'Label for the postal printing binding selection input.',
					  })
					: findOptionLabel(postalSettings.plex, plexOptions(intl)),
		},
		{
			icon: 'palette',
			text: findOptionLabel(postalSettings.color, colorOptions(intl)),
		},
		{
			icon: 'local_shipping',
			text: findOptionLabel(
				postalSettings.postalClass,
				postalClassOptions(intl),
			).replace('*', ''),
		},
	]
	return (
		<div>
			<h2>
				{intl.formatMessage({
					defaultMessage: 'Postituspalvelu (maksullinen)',
					description:
						'Heading for the postal section in the message service summary step.',
				})}
			</h2>
			<SummaryItems items={items} />
		</div>
	)
}

export default compose(injectIntl)(PostalSummary)
