import React from 'react'
import { Button, FontIcon } from 'react-md'
import { push } from 'connected-react-router'
import styled from 'styled-components'
import { bindActionCreators, compose } from 'redux'
import WithDeployments from 'ui/components/WithDeployments'
import { connect } from 'react-redux'

const ONE_WEEK = 604800000

const OKButton = styled(Button)`
	border-radius: 32px;
	background: rgba(0, 0, 0, 0.1);
	transform: scale(0.9);
	margin-right: 8px;
`
const UrgentButton = styled(Button)`
	border-radius: 32px;
	color: white;
	background: orangered;
	margin-right: 8px;
`
const AdviceButton = styled(Button)`
	border-radius: 32px;
	color: rgba(150, 80, 0, 1);
	background: rgba(255, 255, 230, 1);
	border-color: orange;
	border-width: 2px;
	border-style: solid;
	margin-right: 8px;
`

const DeploymentUpdateButton = ({
	isRecentVersion,
	inflightVersionAge,
	location,
	auth,
	_push,
}) => {
	if (window.isNativeApp) {
		return null
	}

	if (!auth) {
		return null
	}

	const isInfoRoute =
		location &&
		location.pathname &&
		location.pathname.includes('/internal/info') &&
		!location.pathname.includes('/internal/infoscreen')

	if (isInfoRoute) {
		return null
	}

	if (isRecentVersion) {
		return (
			<div className="flex-center">
				<OKButton icon onClick={() => _push('/internal/info')}>
					<FontIcon
						style={{
							marginLeft: -8,
							marginRight: -8,
							color: 'green',
							background: 'white',
							borderRadius: 32,
							fontSize: 24,
							marginTop: -2,
						}}
					>
						check_circle
					</FontIcon>
				</OKButton>
			</div>
		)
	}

	let updateUrgent = false
	if (inflightVersionAge > ONE_WEEK) {
		updateUrgent = true
	}

	if (updateUrgent) {
		return (
			<div className="flex-center">
				<UrgentButton
					raised
					onClick={() => window.location.reload(true)}
					iconEl={
						<FontIcon style={{ marginLeft: -8, marginRight: -8 }}>
							download_for_offline
						</FontIcon>
					}
				>
					Päivitä
				</UrgentButton>
			</div>
		)
	}

	return (
		<div className="flex-center">
			<AdviceButton
				flat
				onClick={() => window.location.reload(true)}
				iconEl={
					<FontIcon style={{ marginLeft: -8, marginRight: -8 }}>
						download_for_offline
					</FontIcon>
				}
			>
				Päivitä
			</AdviceButton>
		</div>
	)
}

const mapState = ({ router: { location }, firebaseReducer: { auth } }) => ({
	location,
	auth,
})

const mapDispatchToProps = (dispatch) => ({
	_push: bindActionCreators(push, dispatch),
})

export default compose(
	connect(mapState, mapDispatchToProps),
	WithDeployments,
)(DeploymentUpdateButton)
