import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { StyledFontIcon, BaseButton } from './styles'

const StyledButton = styled(BaseButton)`
	background: #4267b2;
	color: rgba(255, 255, 255, 0.87);
	font-size: 13px;
`

const FacebookButton = ({ onClick }) => {
	return (
		<StyledButton
			raised
			onClick={onClick}
			iconEl={<StyledFontIcon>facebook</StyledFontIcon>}
		>
			<FormattedMessage
				defaultMessage="Facebook-tilillä"
				description="Button that selects the Facebook authentication flow"
			/>
		</StyledButton>
	)
}

export default FacebookButton
