import { push } from 'connected-react-router'

export const _showLoginDialog = (action) => ({
	type: 'UI_LOGIN_SHOW',
})

export const _hideLoginDialog = (action) => ({
	type: 'UI_LOGIN_HIDE',
})

export const _redirectLogin = () =>
	function (dispatch, getState, getFirebase) {
		const params = {
			redirectPath:
				window.document.location.pathname + window.document.location.search,
		}
		const stringParams = JSON.stringify(params)
		const obs = btoa(stringParams)
		if (window.location.pathname.includes('/manager')) {
			dispatch(
				push('/internal/login_redirector?experience=lean&manager=1&obs=' + obs),
			)
		} else {
			dispatch(push('/internal/login_redirector?experience=lean&obs=' + obs))
		}
	}
