import { FormattedMessage, injectIntl } from 'react-intl'
import { Checkbox } from 'react-md'
import styled from 'styled-components'
import Actions from '../../../../Actions'
import { StepContainer } from '../../../styled'
import DateAndTimePicker from 'ui/components/DateAndTimePicker'
import { compose } from 'redux'

const Content = styled.div`
	max-width: 600px;
	margin: 40px auto;
`

const StepTitle = styled.h1`
	font-size: 1.8rem;
	margin: 16px 16px 0px 16px;
`

const DatePickerContainer = styled.div`
	margin-bottom: 32px;
`

function Bulletins({
	stepTitle,
	messageServiceProcess,
	setMessageServiceProcess,
	onPreviousClick,
	onDraftClick,
	onNextClick,
	intl,
}) {
	const { publishTs, expirationTs, distributionOptions } =
		messageServiceProcess.bulletinsSettings || {}

	const updateDistributionOptions = (option) => {
		const updated = { ...distributionOptions, ...option }
		setMessageServiceProcess((prev) => ({
			...prev,
			bulletinsSettings: {
				...prev.bulletinsSettings,
				distributionOptions: updated,
			},
		}))
	}

	const updateTs = (input, value) => {
		setMessageServiceProcess((prev) => ({
			...prev,
			bulletinsSettings: {
				...prev.bulletinsSettings,
				[input]: value,
			},
		}))
	}

	const updatePublishTs = (value) => {
		updateTs('publishTs', value)
	}

	const updateExpirationTs = (value) => {
		updateTs('expirationTs', value)
	}

	return (
		<>
			<StepTitle>{stepTitle}</StepTitle>

			<StepContainer>
				<Content>
					<DatePickerContainer>
						<DateAndTimePicker
							label={intl.formatMessage({
								defaultMessage: 'Julkaisuaika',
								description: 'Label for the bulletin publish time input.',
							})}
							emptyText={intl.formatMessage({
								defaultMessage: 'Heti',
								description: 'Label for the bulletin publish time empty value.',
							})}
							ts={publishTs}
							updateTs={updatePublishTs}
						/>
					</DatePickerContainer>

					<DatePickerContainer>
						<DateAndTimePicker
							label={intl.formatMessage({
								defaultMessage: 'Poistumisaika',
								description: 'Label for the bulletin expiration time input.',
							})}
							emptyText={intl.formatMessage({
								defaultMessage: 'Aseta poistumisaika',
								description:
									'Label for the bulletin expiration time empty value.',
							})}
							ts={expirationTs}
							updateTs={updateExpirationTs}
						/>
					</DatePickerContainer>

					<p style={{ marginBottom: 16 }}>
						<FormattedMessage
							defaultMessage="Näkyvyys ilmoitustaululla"
							description="Header of a section in the message service where the user can select which user groups can see the bulletin on the bulletin board."
						/>
					</p>
					<Checkbox
						id="checkbox-bulletins-tenants"
						label={
							<FormattedMessage
								defaultMessage="Asukkaille"
								description="Checkbox allowing the bulletin to be targeted to tenants."
							/>
						}
						name="Asukkaille"
						checked={distributionOptions.user_tenant}
						value={distributionOptions.user_tenant}
						onChange={(value) => {
							updateDistributionOptions({ user_tenant: value })
						}}
						style={{ marginBottom: 16 }}
					/>
					<Checkbox
						id="checkbox-bulletins-shareholders"
						label={
							<FormattedMessage
								defaultMessage="Osakkaille"
								description="Checkbox allowing the bulletin to be targeted to shareholders."
							/>
						}
						name="Osakkaille"
						checked={distributionOptions.user_shareholder}
						onChange={(value) => {
							updateDistributionOptions({ user_shareholder: value })
						}}
						style={{ marginBottom: 16 }}
					/>
					<Checkbox
						id="checkbox-bulletins-board"
						label={
							<FormattedMessage
								defaultMessage="Hallitukselle"
								description="Checkbox allowing the bulletin to be targeted to the board members."
							/>
						}
						name="Hallitukselle"
						checked={distributionOptions.admin}
						onChange={(value) => {
							updateDistributionOptions({
								admin: value,
								admin_board_deputy: value,
								admin_board_director: value,
								admin_board_member: value,
							})
						}}
						style={{ marginBottom: 16 }}
					/>
				</Content>
			</StepContainer>

			<Actions
				onPreviousClick={onPreviousClick}
				onDraftClick={onDraftClick}
				onNextClick={onNextClick}
			/>
		</>
	)
}

export default compose(injectIntl)(Bulletins)
