import { useState } from 'react'
import { FontIcon } from 'react-md'
import styled from 'styled-components'
import RouterLink from 'ui/components/RouterLink'

const Toggler = styled.div`
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	align-items: center;
	max-width: 600px;
	padding-right: 16px;

	.md-icon {
		color: #fff;
		font-size: 32px;
	}
`

const NavLink = styled(RouterLink)`
	cursor: pointer;
	text-decoration: none;
	font-size: 30px;
	font-weight: bold;
	padding: 16px 20px;
`

const Sublinks = styled.div`
	display: flex;
	flex-direction: column;
	padding-left: 40px;
	max-height: ${(props) => (props.showSublinks ? '1000px' : '0px')};
	overflow: hidden;
	transition: max-height 0.5s ease;
`

function ExpandableNavItem({ item, afterOnClick }) {
	const [showSublinks, setShowSublinks] = useState(false)

	if (!item.sublinks) {
		return (
			<NavLink href={item.href} afterOnClick={afterOnClick}>
				{item.label}
			</NavLink>
		)
	}

	return (
		<>
			<Toggler onClick={() => setShowSublinks(!showSublinks)}>
				<NavLink>{item.label}</NavLink>
				<FontIcon>{showSublinks ? 'expand_less' : 'expand_more'}</FontIcon>
			</Toggler>

			<Sublinks showSublinks={showSublinks}>
				{item.sublinks.map((sublink) => (
					<NavLink
						key={sublink.href}
						href={sublink.href}
						afterOnClick={afterOnClick}
					>
						{sublink.label}
					</NavLink>
				))}
			</Sublinks>
		</>
	)
}

export default ExpandableNavItem
