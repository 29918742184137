import { Card } from 'react-md'
import styled from 'styled-components'
import RouterLink from 'ui/components/RouterLink'
import { maxScreenWidth } from 'ui/StyledComponents/MediaBreakpoints'

export const ActionAnchorButton = styled(RouterLink)`
	width: 100%;
	display: flex;
	padding: 16px 48px;
	font-size: 20px;
	letter-spacing: 1px;
	font-weight: 400;
	background-color: white;
	color: var(--color-secondary);
	border-color: var(--color-secondary);
	border-width: 2px;
	border-style: solid;
	border-radius: 8px;
	box-sizing: border-box;
	justify-content: center;
	align-items: center;
	margin: 8px;
	cursor: pointer;
	text-decoration: none;
	white-space: nowrap;

	&:visited {
		color: var(--color-secondary);
	}
`
export const PrimaryActionAnchorButton = styled(ActionAnchorButton)`
	background-color: var(--color-secondary);
	color: white !important;
	border-width: 0;
	border-style: none;
`
export const PageContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative; // For stripes
	min-height: 100%;
`

export const ContentContainer = styled.div`
	flex: 1 0 auto;
	width: 100%;
	max-width: 1200px;
	margin-top: 80px; // For navbar
	padding: 0 16px;
`
export const PageTitleSection = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: stretch;
	padding: 32px 0;
	max-width: 960px;
	width: 100%;
	align-self: center;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
	h1 {
		font-size: 56px;
		letter-spacing: 3px;
		font-weight: 700;
		color: white;
		text-align: center;
		margin-bottom: 38px;
	}
	h2 {
		font-size: 40px;
		letter-spacing: 3px;
		font-weight: 700;
		color: white;
		text-align: center;
		margin-bottom: 38px;
	}
	a {
		display: flex;
		font-size: 15px;
		letter-spacing: 1px;
		font-weight: 400;
		color: white;
		border-width: 0;
		border-style: none;
		border-radius: 4px;
		box-sizing: border-box;
		justify-content: center;
		align-items: center;
		margin-bottom: 8px;
		cursor: pointer;
		text-transform: uppercase;
		text-decoration: none;
		white-space: nowrap;
	}

	@media screen and (max-width: ${maxScreenWidth.phone}px) {
		h1 {
			font-size: 40px;
		}
		h2 {
			font-size: 32px;
		}
	}

	@media screen and (max-width: ${maxScreenWidth.phoneSmall}px) {
		h1 {
			font-size: 32px;
		}
		h2 {
			font-size: 24px;
		}
	}
`
export const LogoNormal = styled.div`
	font-weight: 700 !important;
	font-size: 2.3rem;
	font-family: 'Baloo 2', Arial, Helvetica, sans-serif;
	color: #096ed6;
`
export const FlexCenteredDiv = styled.div`
	display: flex;
	place-items: center;
	justify-content: center;
`
export const ContentCard = styled(Card)`
	width: 100%;
	border-radius: 8px;
	padding: 16px 24px 32px 24px;
	color: #4a4a4a;
	font-weight: 200;
	font-size: 20px;
	h3 {
		color: #4a4a4a;
		padding-top: 0;
		margin: 8px 0;
	}
	a {
		color: #3f95ea;
	}
	img {
		width: 100%;
	}
`
export const ArticleTitle = styled.div`
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	margin: 0;
	margin-block-end: 8px;
	font-size: 1.4rem;
	font-weight: bold;
	color: #1d1d21;
	color: var(--color-text-heading);
`
export const DashDivider = styled.div`
	width: 100%;
	position: relative;
	:after {
		content: '';
		width: 100%;
		position: absolute;
		top: 50%;
		border-top: 1px dashed #c1c1c1;
	}
`
export const StripeBase = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	background-color: #aa32bf;
	background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHZpZXdCb3g9IjAgMCAxIDEiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiPjxsaW5lYXJHcmFkaWVudCBpZD0idnNnZyIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiIHgxPSIwJSIgeTE9IjAlIiB4Mj0iMTAwJSIgeTI9IjEwMCUiPjxzdG9wIHN0b3AtY29sb3I9IiMzZjk1ZWEiIHN0b3Atb3BhY2l0eT0iMSIgb2Zmc2V0PSIwIi8+PHN0b3Agc3RvcC1jb2xvcj0iIzUyZDNhYSIgc3RvcC1vcGFjaXR5PSIxIiBvZmZzZXQ9IjEiLz48L2xpbmVhckdyYWRpZW50PjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxIiBoZWlnaHQ9IjEiIGZpbGw9InVybCgjdnNnZykiIC8+PC9zdmc+);
	background-image: -webkit-gradient(
		linear,
		0% 0%,
		100% 100%,
		color-stop(0, #3f95ea),
		color-stop(1, #aa32bf)
	);
	background-image: -webkit-repeating-linear-gradient(
		top left,
		#3f95ea 0%,
		#aa32bf 100%
	);
	background-image: repeating-linear-gradient(
		to bottom right,
		#3f95ea 0%,
		#aa32bf 100%
	);
	background-image: -ms-repeating-linear-gradient(
		top left,
		#3f95ea 0%,
		#aa32bf 100%
	);
`
export const FirstStripe = styled(StripeBase)`
	z-index: -3;
	clip-path: polygon(
		0 0,
		100% 0,
		100% calc(130vh + 256px),
		0 calc(130vh + 384px)
	);
`
