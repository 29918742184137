import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Toolbar, Button } from 'react-md'

import Uploader from 'ui/components/Uploader'
import { compose } from 'redux'
import WithDialogs from 'ui/components/dialogs/WithDialogs'
import ViiluDialog from 'ui/components/ViiluDialog'

let XLSX
const init = async () => {
	XLSX = await import('xlsx')
}
init()

const getInitialState = () => ({
	valid: false,
	processing: false,
	apartments: null,
})

class TampuuriApartmentReportImportDialog extends Component {
	state = getInitialState()

	componentDidUpdate(prevProps, prevState) {
		this.onValidation(prevState)
	}

	onValidation = (prevState) => {
		const valid = this.validate(this.state)
		if (valid !== prevState.valid) {
			this.setState({ valid })
		}
	}

	// onValidation is called every setState to validate the inputs
	validate = () => {
		const { apartments } = this.state
		if (!apartments) {
			return false
		}
		if (apartments.length === 0) {
			return false
		}
		return true
	}

	handleFile(file /*:File*/) {
		const { alert } = this.props
		/* Boilerplate to set up FileReader */
		const reader = new FileReader()
		const rABS = !!reader.readAsBinaryString
		reader.onload = async (e) => {
			/* Parse data */
			const bstr = e.target.result
			const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' })
			/* Get first worksheet */
			const wsname = wb.SheetNames[0]
			const ws = wb.Sheets[wsname]
			/* Convert array of arrays */
			const data = XLSX.utils.sheet_to_json(ws, { header: 1 })
			if (data.length < 1) {
				await alert(
					'Näyttäisi siltä, että tiedostossa ei ole yhtään huoneistoa. Tarkistathan, että se on oikeassa muodossa.',
				)
			} else {
				if (data[0].length < 2) {
					await alert(
						'Näyttäisi siltä, että tiedostossa ei ole yhtään huoneistoa. Tarkistathan, että se on oikeassa muodossa.',
					)
				} else if (data[0][0] === 'Polku') {
					data.shift()
				}
			}

			const filteredData = data
				.filter((row) => row.length === 5) // path, id, name, type, voteCount
				.filter((row) => row[0] !== undefined && row[0] !== null) // path should be included
				.filter((row) => row[1] !== undefined && row[1] !== null) // id should be included
				.filter((row) => row[2] !== undefined && row[2] !== null) // name should be included
				// type is optional
				.filter(
					(row) => row[4] !== undefined && row[4] !== null && row[4] !== 0,
				) // voteCount should be included

			const typeCheckedData = filteredData
				.filter((row) => row.length === 5) // path, id, name, type, voteCount
				.filter((row) => typeof row[0] === 'string') // path is a string
				.filter(
					(row) => typeof row[1] === 'string' || typeof row[1] === 'number',
				) // id is a string or number
				.filter(
					(row) => typeof row[2] === 'string' || typeof row[2] === 'number',
				) // name is a string or number
				// type is optional
				.filter((row) => typeof row[4] === 'number') // voteCount is a number

			const isNumeric = (num) => !isNaN(num)

			const reconstructedData = typeCheckedData.map((row) => {
				const name = row[2]
				let identifier = name
				if (isNumeric(name)) {
					// If the name is only numbers (which is a user mistake) let's add a bit more info from the type
					const type = row[3] || ''
					identifier = type + name
				}

				return {
					tampuuriCompanyTunnus: row[0].split(' ')[0],
					tampuuriCompanyName: row[0]
						.slice(row[0].indexOf(' '))
						.split(' / ')[0],
					identifier: identifier.toString().replace(/ /g, '').toUpperCase(),
					voteCount: row[4],
				}
			})

			const identifierCounts = {}

			reconstructedData.forEach((a) => {
				if (identifierCounts[a.tampuuriCompanyTunnus + a.identifier]) {
					identifierCounts[a.tampuuriCompanyTunnus + a.identifier]++
				} else {
					identifierCounts[a.tampuuriCompanyTunnus + a.identifier] = 1
				}
			})

			const identifierMap = {}
			reconstructedData.forEach((el) => {
				identifierMap[
					el.tampuuriCompanyTunnus +
						';' +
						el.tampuuriCompanyName +
						';' +
						el.identifier
				] = el.voteCount
			})

			const uniqueData = Object.keys(identifierMap).map((id) => ({
				tampuuriCompanyTunnus: id.split(';')[0],
				tampuuriCompanyName: id.split(';')[1],
				identifier: id.split(';')[2],
				voteCount: identifierMap[id],
			}))

			let duplicatesFound = false
			let duplicatesFoundTampuuriCompanyTunnusArray = []
			Object.keys(identifierCounts).forEach((c) => {
				if (identifierCounts[c] > 1) {
					duplicatesFoundTampuuriCompanyTunnusArray.push(c)
					duplicatesFound = true
				}
			})

			if (duplicatesFound) {
				await alert(
					'Tiedostosta löytyi useampi rivi, jolla on sama huoneiston tunnus. Kaksoiskappaleet poistetaan niin, että viimeisimmän rivin tiedot jäävät voimaan. Kaksoiskappaleet: ' +
						duplicatesFoundTampuuriCompanyTunnusArray.join(', '),
				)
			}

			/* Update state */
			this.setState({ apartments: uniqueData })
		}
		if (rABS) reader.readAsBinaryString(file)
		else reader.readAsArrayBuffer(file)
	}

	// onFilesChanged will get called with the accepted files or null in case there were rejections
	onFilesChanged = (files) => {
		try {
			this.handleFile(files[0])
		} catch (ex) {
			console.error(ex)
		}
	}

	onHide = () => {
		this.setState(getInitialState())
		this.props.onHide()
	}

	onProcess = async () => {
		const { onSuccess, alert } = this.props
		const { apartments } = this.state
		if (!apartments) {
			await alert(
				'Näyttäisi siltä, että tiedostossa ei ole yhtään huoneistoa. Tarkistathan, että se on oikeassa muodossa.',
			)
		}
		onSuccess(apartments)
		this.onHide()
		return true
	}

	renderToolbar = () => {
		return (
			<Toolbar
				colored
				title="Tuo huoneistotietoja Tampuurin raportista"
				style={{
					background: 'var(--color-secondary-dark)',
					position: 'fixed',
					width: '100%',
					zIndex: 2,
				}}
				nav={
					<Button icon onClick={this.onHide}>
						close
					</Button>
				}
			/>
		)
	}

	render() {
		const { apartments, processing, valid } = this.state
		const processButtonEnabled = !processing && valid

		return (
			<ViiluDialog
				id="responsive-dialog"
				aria-label="Tuo kohderaportit"
				visible={true}
				onHide={this.onHide}
				dialogClassName="responsive-dialog"
				contentClassName="responsive-dialog-content"
				autosizeContent={false}
				modal
				portal
				disableScrollLocking
				onClick={(e) => {
					e.preventDefault()
					e.stopPropagation()
				}}
				onDoubleClick={(e) => {
					e.preventDefault()
					e.stopPropagation()
				}}
				style={{ zIndex: 5000 }}
			>
				{this.renderToolbar()}
				<section
					className="md-toolbar-relative flex-column"
					style={{ padding: 24 }}
				>
					<p className="margin-bottom">
						Voit tuoda huoneistojen tunnukset ja äänimäärät suoraan Tampuurista.
					</p>
					<br />
					<Uploader onFilesChanged={this.onFilesChanged} />
					{!apartments
						? null
						: 'Tiedostossa on listattuna ' +
						  apartments.length +
						  ' osakeryhmää ja niiden äänimäärät.'}
					<Button
						secondary
						raised
						onClick={this.onProcess}
						disabled={!processButtonEnabled}
						className="margin-top"
					>
						TUO
					</Button>
				</section>
			</ViiluDialog>
		)
	}
}

TampuuriApartmentReportImportDialog.propTypes = {
	onHide: PropTypes.func.isRequired,
	onSuccess: PropTypes.func.isRequired,
}

const mapState = () => ({})

const mapDispatchToProps = (dispatch) => ({})

export default compose(
	WithDialogs,
	connect(mapState, mapDispatchToProps),
)(TampuuriApartmentReportImportDialog)
