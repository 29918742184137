import { push } from 'connected-react-router'
import {
	Button,
	DataTable,
	TableBody,
	TableColumn,
	TableHeader,
	TableRow,
} from 'react-md'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { bindActionCreators, compose } from 'redux'
import styled from 'styled-components'

const StatusButton = styled(Button)`
	color: ${(props) => props.$fontColor};
	background-color: ${(props) => props.$backgroundColor};
	border-radius: 16px;
	width: 100%;
	white-space: nowrap;
	padding: 8px;
	font-size: 14px;
`

function MessageServiceMassJobs({ messageServiceMassJobs, _push }) {
	if (!messageServiceMassJobs) {
		return null
	}

	const navToMessageService = (
		companyUrlName,
		status,
		messageServiceProcessUUID,
	) => {
		if (!status || !messageServiceProcessUUID) {
			return
		}

		const param = status === 'done' ? 'sent' : 'draft'
		_push(
			`/${companyUrlName}/message_service?${param}UUID=${messageServiceProcessUUID}`,
		)
	}

	const renderStatus = (status, companyUrlName, messageServiceProcessUUID) => {
		let label
		let backgroundColor
		let fontColor = 'white'

		switch (status) {
			case 'done':
				label = 'Lähetetty'
				backgroundColor = 'green'
				break
			case 'error':
				label = 'Virhe'
				backgroundColor = 'red'
				break
			default:
				label = 'Unknown status'
				backgroundColor = 'white'
				fontColor = 'black'
				break
		}

		return (
			<StatusButton
				flat
				$backgroundColor={backgroundColor}
				$fontColor={fontColor}
				onClick={() =>
					navToMessageService(companyUrlName, status, messageServiceProcessUUID)
				}
			>
				{label}
			</StatusButton>
		)
	}

	return (
		<DataTable
			plain
			style={{
				marginTop: 16,
				backgroundColor: 'white',
				border: '1px solid #ccc',
				borderRadius: 4,
			}}
		>
			<TableHeader>
				<TableRow>
					<TableColumn>Taloyhtiö</TableColumn>
					<TableColumn>Lähetys</TableColumn>
				</TableRow>
			</TableHeader>
			<TableBody>
				{messageServiceMassJobs.map((job) => (
					<TableRow key={job.uuid}>
						<TableColumn>{job.companyName}</TableColumn>
						<TableColumn>
							{renderStatus(
								job.status,
								job.companyUrlName,
								job.messageServiceProcessUUID,
							)}
						</TableColumn>
					</TableRow>
				))}
			</TableBody>
		</DataTable>
	)
}

const mapState = ({
	firestoreReducer: {
		ordered: { message_service_mass_job },
	},
}) => ({
	messageServiceMassJobs: message_service_mass_job,
})

const mapDispatchToProps = (dispatch) => ({
	_push: bindActionCreators(push, dispatch),
})

export default compose(
	firestoreConnect(({ messageServiceMassProcess }) => [
		{
			collection: 'message-service-mass-job',
			storeAs: 'message_service_mass_job',
			where: [
				['messageServiceMassProcessUUID', '==', messageServiceMassProcess.uuid],
				['managerUUID', '==', messageServiceMassProcess.managerUUID],
			],
		},
	]),
	connect(mapState, mapDispatchToProps),
)(MessageServiceMassJobs)
