import { useSelector } from 'react-redux'
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase'
import type { Company } from 'types/company'

export const useCompany = (selectedCompanyUUID: string) => {
	useFirestoreConnect([
		{
			collection: 'company',
			storeAs: `company_${selectedCompanyUUID}`,
			where: [['uuid', '==', selectedCompanyUUID]],
			limit: 1,
		},
	])

	return useSelector(({ firestoreReducer }: any) => {
		const companyList = firestoreReducer.ordered[
			`company_${selectedCompanyUUID}`
		] as Company[]

		if (!isLoaded(companyList)) {
			return { loading: true, company: null }
		}

		const company =
			Array.isArray(companyList) && companyList.length > 0
				? companyList[0]
				: null

		return { loading: false, company }
	})
}
