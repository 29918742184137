import React, { useState } from 'react'
import { useSnackbar } from 'notistack'
import { ThemeButton } from 'ui/StyledComponents/Buttons'
import useDialogs from 'ui/components/dialogs/useDialogs'

const snackBarTypeConfig = {
	default: {
		variant: 'default',
		autoHideDuration: 5000,
		persist: false,
		preventDuplicate: false,
		anchorOrigin: {
			vertical: 'bottom',
			horizontal: 'left',
		},
	},
	info: {
		variant: 'info',
		autoHideDuration: 5000,
		persist: false,
		preventDuplicate: false,
		anchorOrigin: {
			vertical: 'bottom',
			horizontal: 'left',
		},
	},
	error: {
		variant: 'error',
		autoHideDuration: 3000,
		persist: true,
		preventDuplicate: true,
		anchorOrigin: {
			vertical: 'bottom',
			horizontal: 'center',
		},
	},
	success: {
		variant: 'success',
		autoHideDuration: 3000,
		persist: false,
		preventDuplicate: true,
		anchorOrigin: {
			vertical: 'bottom',
			horizontal: 'right',
		},
	},
}

export default function TestSnackbar() {
	const [count, setCount] = useState(0)
	const { enqueueSnackbar, closeSnackbar } = useSnackbar()
	const { alert } = useDialogs()

	const handleOnClick = (type, noDuplication) => {
		!noDuplication && setCount(count + 1)
		enqueueSnackbar(`Notification ${count}`, {
			...snackBarTypeConfig[type],
			action,
		})
	}

	const action = (key) => (
		<>
			<ThemeButton
				flat
				primary
				onClick={async () => {
					await alert(`I belong to snackbar with key ${key}`)
				}}
			>
				Alert
			</ThemeButton>
			<ThemeButton
				flat
				primary
				onClick={() => {
					closeSnackbar(key)
				}}
			>
				Dismiss
			</ThemeButton>
		</>
	)

	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				width: '100%',
				height: '100vh',
				alignItems: 'center',
			}}
		>
			<div>
				<ThemeButton onClick={() => handleOnClick('default')} raised primary>
					Default
				</ThemeButton>
				<ThemeButton onClick={() => handleOnClick('info')} raised primary>
					Info
				</ThemeButton>
				<ThemeButton onClick={() => handleOnClick('success')} raised primary>
					Success
				</ThemeButton>
				<ThemeButton onClick={() => handleOnClick('error')} raised primary>
					Error
				</ThemeButton>
				<ThemeButton
					onClick={() => handleOnClick('success', true)}
					raised
					primary
				>
					No duplication
				</ThemeButton>
			</div>
		</div>
	)
}
