import { Button } from 'react-md'
import RootDialog from './RootDialog'

function ConfirmDialog({ dialog, resolveDialog }) {
	return (
		<RootDialog
			dialog={dialog}
			actions={[
				<Button flat onClick={() => resolveDialog(dialog.key, false)}>
					{dialog.cancelLabel}
				</Button>,
				<Button raised primary onClick={() => resolveDialog(dialog.key, true)}>
					{dialog.confirmLabel}
				</Button>,
			]}
		>
			<p>{dialog.message}</p>
		</RootDialog>
	)
}

export default ConfirmDialog
