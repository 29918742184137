import { useState } from 'react'
import { useIntl } from 'react-intl'
import { Button, CircularProgress, List, ListItem } from 'react-md'
import { _importPaymentProducts } from 'state/finance-payment-product-actions'
import type { Company } from 'types/company'
import { CANCEL_BUTTON_LABEL, GENERIC_ERROR_NOTIFICATION } from 'ui/messages'
import PaymentProductsTable from './PaymentProductsTable'
import usePaymentProducts from './usePaymentProducts'
import type { PaymentProduct } from './types'
import type { VatSelectOptions } from '../types'
import ViiluDialog from 'ui/components/ViiluDialog'

type Props = {
	selectedFinanceCompanyUUID: string
	selectedManagerCompanies: Company[]
	onHide: () => void
	refreshPaymentProducts: () => void
	vatOptions: VatSelectOptions[]
}

function PaymentProductImportDialog({
	selectedFinanceCompanyUUID,
	selectedManagerCompanies,
	onHide,
	refreshPaymentProducts,
	vatOptions,
}: Props) {
	const intl = useIntl()
	const [companyUUID, setCompanyUUID] = useState('')
	const paymentProducts = usePaymentProducts(companyUUID)
	const [selectedPaymentProducts, setSelectedPaymentProducts] = useState<
		PaymentProduct[]
	>([])
	const [loading, setLoading] = useState(false)

	const handleSubmit = async () => {
		setLoading(true)
		try {
			const response = await _importPaymentProducts({
				companyUUID: selectedFinanceCompanyUUID,
				paymentProducts: selectedPaymentProducts,
			})

			if (!response?.ok) {
				throw new Error('Response is not ok.')
			}

			refreshPaymentProducts()
			onHide()
		} catch (error) {
			console.error(error)
			alert(GENERIC_ERROR_NOTIFICATION(intl))
			setSelectedPaymentProducts([])
			setLoading(false)
		}
	}

	const renderContent = () => {
		if (!companyUUID) {
			return (
				<List>
					{selectedManagerCompanies.map((company) => (
						<ListItem
							key={company.uuid}
							primaryText={company.name}
							onClick={() => {
								setCompanyUUID(company.uuid)
							}}
						/>
					))}
				</List>
			)
		}

		if (loading || paymentProducts.loading) {
			return <CircularProgress id="payment-product-import-progress" />
		}

		if (!paymentProducts.data.length) {
			return (
				<p>
					{intl.formatMessage({
						defaultMessage: 'Maksulajeja ei löytynyt.',
						description: 'Message for empty finance payment products list.',
					})}
				</p>
			)
		}

		return (
			<PaymentProductsTable
				paymentProducts={paymentProducts.data}
				setSelected={setSelectedPaymentProducts}
				vatOptions={vatOptions}
			/>
		)
	}

	const actions = [
		<Button flat onClick={onHide}>
			{CANCEL_BUTTON_LABEL(intl)}
		</Button>,
	]

	if (companyUUID) {
		actions.push(
			<Button
				raised
				secondary
				disabled={loading || !selectedPaymentProducts.length}
				onClick={handleSubmit}
			>
				{intl.formatMessage({
					defaultMessage: 'Tuo',
					description: 'Label for the import finance payment products button.',
				})}
			</Button>,
		)
	}

	return (
		<ViiluDialog
			id="payment-poduct-import-dialog"
			visible={true}
			onHide={onHide}
			title={intl.formatMessage({
				defaultMessage: 'Tuo maksulajeja',
				description: 'Title for the finance payment edit product dialog.',
			})}
			actions={actions}
			modal
			dialogStyle={{ borderRadius: 8, width: 'auto' }}
			focusOnMount={false}
			paddedContent={true}
		>
			{renderContent()}
		</ViiluDialog>
	)
}

export default PaymentProductImportDialog
