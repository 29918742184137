import { useCallback, useEffect, useState } from 'react'
import type { WebInvoiceAddress } from './types'
import { _getWebInvoiceAddressSettingsByCompanyUUID } from 'state/settings-actions'

function useWebInvoiceAddresses(uuid: string | undefined) {
	const [webInvoiceAddresses, setWebInvoiceAddresses] = useState<
		WebInvoiceAddress[]
	>([])

	const [loadingWebInvoiceAddresses, setLoading] = useState<boolean>(false)
	const [errorWebInvoiceAddresses, setError] = useState<string | null>(null)

	const doQuery = useCallback(async () => {
		if (!uuid) return
		setLoading(true)
		setError(null)
		let data: WebInvoiceAddress[] = []

		try {
			const response = await _getWebInvoiceAddressSettingsByCompanyUUID(uuid)

			if (response && response.ok) {
				const res = await response.json()
				data = res.webInvoiceAddresses
			} else {
				throw new Error('Response is not ok.')
			}
		} catch (err) {
			console.error('Error fetching web invoice addresses:', err)
			setError(
				'An error occurred while fetching web invoice addresses: ' + uuid,
			)
		}
		setWebInvoiceAddresses(data)
		setLoading(false)
	}, [uuid])

	useEffect(() => {
		doQuery()
	}, [doQuery])

	return {
		webInvoiceAddresses,
		loadingWebInvoiceAddresses,
		errorWebInvoiceAddresses,
		refreshWebInvoiceAddresses: doQuery,
	}
}

export type UseWebInvoiceAddressesResult = ReturnType<
	typeof useWebInvoiceAddresses
>

export default useWebInvoiceAddresses
