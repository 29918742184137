export const getLetters = (recipients) => {
	const addressMap = {}
	recipients.forEach((recipient) => {
		const fullAddress = getFullAddress(recipient)
		if (addressMap[fullAddress]) {
			addressMap[fullAddress].push(recipient)
		} else {
			addressMap[fullAddress] = [recipient]
		}
	})
	const letters = Object.keys(addressMap).map((key) => {
		const recipients = addressMap[key]
		return {
			name: recipients.map((r) => r.name).join(', '),
			address: recipients[0].address,
			area: recipients[0].area,
			zip: recipients[0].zip,
			country: recipients[0].country,
			recipients,
		}
	})
	return [letters, addressMap]
}

export const getFullAddress = (recipient) => {
	const address = sanitizePostalAddress(recipient.address)
	const area = sanitizePostalArea(recipient.area || '')
	const zip = sanitizePostalZip(recipient.zip || '')
	const country = sanitizePostalCountry(recipient.country || '')
	const fullAddress = `${address}${area}${zip}${country}`
	return fullAddress
}

export const sanitizePostalAddress = (address) => {
	if (!address) {
		return address
	}
	return (address || '')
		.toUpperCase()
		.replace(/[^0-9](?=[0-9])/g, '$& ') // Insert spaces between letters and digits
		.replace(/\s\s+/g, ' ') // Replace multiple spaces with one
		.trim()
}

export const sanitizePostalArea = (area) => {
	if (!area) {
		return area
	}
	return (area || '').toUpperCase().trim()
}

export const sanitizePostalZip = (zip) => {
	if (!zip) {
		return zip
	}
	return (zip || '').toUpperCase().trim()
}

export const sanitizePostalCountry = (country) => {
	if (!country) {
		return country
	}
	return (country || '').toUpperCase().trim()
}
