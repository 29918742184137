import { Component } from 'react'
import PropTypes from 'prop-types'
import { push } from 'connected-react-router'
import { isLoaded } from 'react-redux-firebase'
import routes from './routes'
import { financeRoutes } from './ui/ManagerFinance/index'
import { compose, bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { themes } from './ui/themes/theme-context'
import { ThemeProvider } from 'styled-components'

import { I18nProvider, LOCALES, setMomentLocale } from './i18n'

import * as dimensionsActions from './state/dimensionsActions'
import * as notifyActions from './state/notifyActions'
import * as appUIActions from './state/ui/app-ui-actions'
import * as appActions from './state/appActions'

import LoadingState from './ui/components/LoadingState'
import RouteFacade from './ui/components/RouteFacade'
import AppDrawer from './ui/NavigationDrawer/AppDrawer'
import WithSelectedCompany from './ui/components/WithSelectedCompany'
import WithURLCompanyUpdater from './ui/components/WithURLCompanyUpdater'
import LoginDialogContainer from './ui/LoginDialogContainer'
import WithLoggedInUser from './ui/components/WithLoggedInUser'
import WithSelectedCompanyManager from './ui/components/WithSelectedCompanyManager'
import WithSelectedCompanyManagerRole from './ui/components/WithSelectedCompanyManagerRole'
import WithQueryParams from './ui/components/WithQueryParams'
import UserOnboarding from './ui/InternalUserOnboarding'
import WrapperToolbar from 'WrapperToolbar'
import LanguageDialog from './ui/YouMenu/LanguageDialog'
import { _updateUserSelectedLocaleDebounced } from './state/user-actions'
import NotificationListener from './ui/components/NotificationListener'
import BeamerUnreadCountFetcher from './ui/components/beamer/BeamerUnreadCountFetcher'
import UserUpdateContactDetails from 'ui/InternalUserOnboarding/UserUpdateContactDetails'
import { firebaseApp } from 'state/store'
import { getAuth } from 'firebase/auth'

const HIDE_FACADE_TIMEOUT = 1000

class App extends Component {
	state = {
		profileDialogVisible: false,
	}

	componentDidMount() {
		const {
			selectedLocale,
			queryParams: { lang },
			_setSelectedLocale,
			_updateDimensions,
			_setLanguageDialogVisible,
			_setAppToolbarVisible,
		} = this.props
		setMomentLocale(selectedLocale)
		_setAppToolbarVisible(true)

		window.addEventListener('resize', () =>
			_updateDimensions({
				width: window.innerWidth,
				height: window.innerHeight,
			}),
		)
		window.addEventListener('message', this.handleNativeMessage)
		window.document.addEventListener('message', this.handleNativeMessage)

		window.setLanguageDialogVisible = () => _setLanguageDialogVisible(true)

		// Setting firebase locale should be done before any _setSelectedLocale
		// Since _setSelectedLocale does the same thing in the next event loop
		if (firebaseApp) {
			if (!selectedLocale) {
				getAuth(firebaseApp).languageCode = 'fi'
			} else if (selectedLocale === LOCALES.FINNISH) {
				getAuth(firebaseApp).languageCode = 'fi'
			} else if (selectedLocale === LOCALES.SWEDISH) {
				getAuth(firebaseApp).languageCode = 'sv'
			} else if (selectedLocale === LOCALES.ENGLISH) {
				getAuth(firebaseApp).languageCode = 'en'
			}
		}

		// First try to check the url for a parameter
		if (lang) {
			for (const language in LOCALES) {
				if (lang === LOCALES[language]) {
					_setSelectedLocale(LOCALES[language])
				}
			}
		} else {
			// If no parameter is present resort to asking the user for the language
			// if it's not selected already (not on native since native side handles it)
			if (!selectedLocale && !window.isNativeApp && !window.isInfoscreen) {
				_setLanguageDialogVisible(true)
			}
		}

		this.props._setHasScrolledDown(false)

		this.checkManagerRouteSelectedManagerUUID()
	}

	componentDidUpdate() {
		this.checkManagerRouteSelectedManagerUUID()
	}

	checkManagerRouteSelectedManagerUUID = () => {
		const { _push, selectedManagerUUID } = this.props
		const route = this.getRoute()

		//This is to check for manager routes incase of managerUUID has not been selected.
		//Redirect to manager selection before using manager features if no managerUUID is selected and include check for path not be selection.
		const isManagerRoute =
			typeof route.category === 'string' &&
			route.category.toLowerCase().includes('manager')
		if (
			isManagerRoute &&
			!selectedManagerUUID &&
			route.path !== '/manager/selection'
		) {
			_push(`/manager/selection?redirectURL=${route.path}`)
		}
	}

	handleNativeMessage = (rawMessage) => {
		const { _push } = this.props
		if (typeof rawMessage !== 'object') {
			return
		}
		if (
			!rawMessage.data ||
			typeof rawMessage.data[0] !== 'string' ||
			rawMessage.data[0] !== '{'
		) {
			return
		}
		const message = JSON.parse(rawMessage.data)
		if (message.command === 'push') {
			_push(...message.args)
		}
	}

	showProfileDialog = () => {
		const { profileDialogVisible } = this.state
		if (!profileDialogVisible) {
			this.setState({ profileDialogVisible: true })
		}
	}
	hideProfileDialog = () => this.setState({ profileDialogVisible: false })

	showWelcomeMessage = (auth) => {
		const { _showInfoNotification } = this.props
		const { displayName } = auth
		const showWelcomeNotification = () => {
			const firstName = (displayName || '').split(' ')[0]
			_showInfoNotification('Tervetuloa takaisin ' + firstName + '!')
		}
		setTimeout(showWelcomeNotification, 2000)
	}

	componentWillReceiveProps(nextProps) {
		// Welcome message
		if (
			nextProps &&
			nextProps.auth &&
			!nextProps.auth.isEmpty &&
			nextProps.auth.isLoaded
		) {
			const { displayName } = nextProps.auth
			const {
				loggedInUser,
				selectedLocale,
				languageDialogVisible,
				_setLanguageDialogVisible,
				queryParams: { lang },
			} = nextProps

			if (
				!selectedLocale &&
				!languageDialogVisible &&
				!window.isNativeApp &&
				!window.isInfoscreen &&
				!lang
			) {
				// Ask for the lang selection if we are not on native,
				// there's no url parameter and we have no selectedLocale already
				_setLanguageDialogVisible(true)
			}

			// Initialize selectedLocale in Firestore for the user if there is not
			// any value yet. Later on the value will be updated on _setSelectedLocale action.
			if (selectedLocale && loggedInUser && !loggedInUser.selectedLocale) {
				_updateUserSelectedLocaleDebounced(selectedLocale)
			}

			const isOnInvitePage =
				window.location.pathname.includes('/company_invite')
			if (
				(!displayName || (loggedInUser && !loggedInUser.displayName)) &&
				!isOnInvitePage
			) {
				this.showProfileDialog()
			} else {
				if (
					!this.props.auth ||
					this.props.auth.isEmpty ||
					!this.props.auth.isLoaded
				) {
					this.showWelcomeMessage(nextProps.auth)
				}
			}
		}

		// Page scroll needs to be reset if moving between functionalities/pages
		const path =
			this.props.router &&
			this.props.router.location &&
			this.props.router.location.pathname
		const nextPath =
			nextProps.router &&
			nextProps.router.location &&
			nextProps.router.location.pathname
		if (nextPath !== path) {
			this.resetScroll()
		}

		if (nextProps && nextProps.selectedCompanyManager) {
			if (
				!this.props.selectedCompanyManager ||
				this.props.selectedCompanyManager.uuid !==
					nextProps.selectedCompanyManager.uuid
			) {
				let root = document.documentElement
				if (
					nextProps.selectedCompanyManager.whitelabelManager &&
					nextProps.selectedCompanyManager.colorPrimary
				) {
					root.style.setProperty(
						'--color-primary',
						nextProps.selectedCompanyManager.colorPrimary,
					)
				} else {
					root.style.setProperty('--color-primary', '#4596EC')
				}

				if (
					nextProps.selectedCompanyManager.whitelabelManager &&
					nextProps.selectedCompanyManager.colorPrimaryDark
				) {
					root.style.setProperty(
						'--color-primary-dark',
						nextProps.selectedCompanyManager.colorPrimaryDark,
					)
				} else {
					root.style.setProperty('--color-primary-dark', '#096ED6')
				}

				if (
					nextProps.selectedCompanyManager.whitelabelManager &&
					nextProps.selectedCompanyManager.colorSecondary
				) {
					root.style.setProperty(
						'--color-secondary',
						nextProps.selectedCompanyManager.colorSecondary,
					)
				} else {
					root.style.setProperty('--color-secondary', '#9C3FB9')
				}

				if (
					nextProps.selectedCompanyManager.whitelabelManager &&
					nextProps.selectedCompanyManager.colorSecondaryDark
				) {
					root.style.setProperty(
						'--color-secondary-dark',
						nextProps.selectedCompanyManager.colorSecondaryDark,
					)
				} else {
					root.style.setProperty('--color-secondary-dark', '#6E0B8D')
				}
			}
		} else if (this.props.selectedCompanyManager) {
			// We did have a manager with custom colors before
			// but now have a company without a manager. Reset colors.
			let root = document.documentElement
			root.style.setProperty('--color-primary', '#4596EC')
			root.style.setProperty('--color-secondary', '#9C3FB9')
			root.style.setProperty('--color-primary-dark', '#096ED6')
			root.style.setProperty('--color-secondary-dark', '#6E0B8D')
		}
	}

	resetScroll = () => {
		const containers = document.getElementsByClassName('content-container')
		const element = containers.length > 0 && containers[0]
		if (element) {
			element.scrollTop = 0
		}
		this.props._setHasScrolledDown(false)
	}

	getRoute = () => {
		const { _push } = this.props
		let relativePath = '/'
		let path =
			this.props.router &&
			this.props.router.location &&
			this.props.router.location.pathname
		if (path === null) {
			// TODO: this is done so this method doesn't return the Site route
			// before the router has been initialized. For some reason the router.location.pathname
			// is null for a brief moment before picking up,
			// which makes path here null and relative path '/', thus leading to the Site being picked
			return {}
		}
		if (path) {
			const company = path.split('/')[1]
			relativePath = path.split(company)[1]
			if (!relativePath) relativePath = '/' // Set path to root to be able to use the route specific settings
		}

		let route =
			routes.find((r) => r.path === path || r.path + '/' === path) ||
			routes.find((r) => r.relativePath === relativePath) ||
			financeRoutes.find((r) => r.path === path || r.path + '/' === path) ||
			financeRoutes.find((r) => r.relativePath === relativePath)

		if (!route) {
			// This forEach is an extra custom thing to handle the meeting redirector route
			routes.forEach((r) => {
				if (path.includes(r.relativePath) && r.relativePath !== '/') {
					// match relative path but not just the root
					route = r
					return
				}
			})

			if (!route) {
				_push('/internal/notfound')
				return {}
			}
		}

		return route
	}

	renderRoute = () => {
		const route = this.getRoute()
		const routeColor = route ? route.color : undefined
		const routeIcon = route ? route.icon : undefined
		const routeKey = route ? route.path : undefined

		return (
			<div
				id={'content-container'}
				className={'content-container full-height'}
				onScroll={(event) => {
					const scrollTop = event.target.scrollTop
					let hasScrolledDown = false
					if (scrollTop > 0) {
						hasScrolledDown = true
					}
					if (this.props.hasScrolledDown !== hasScrolledDown) {
						this.props._setHasScrolledDown(hasScrolledDown)
					}
				}}
			>
				<RouteFacade
					key={routeKey}
					background={routeColor}
					icon={routeIcon}
					animationDuration={HIDE_FACADE_TIMEOUT}
				/>
				{this.props.children}
			</div>
		)
	}

	render() {
		const { profileDialogVisible } = this.state
		const { auth, selectedLocale } = this.props

		if (!isLoaded(auth)) {
			return <LoadingState />
		}
		const route = this.getRoute()

		return (
			<I18nProvider locale={selectedLocale || LOCALES.FINNISH}>
				<ThemeProvider theme={themes.ViiluColorTheme}>
					<div id="body-container">
						<div id="body-container-scroll-container">
							<AppDrawer currentRoute={route} />

							<div
								className="flex-column full-height"
								style={{ flexGrow: 1, overflowX: 'hidden' }}
							>
								<WrapperToolbar />
								{this.renderRoute()}
							</div>
						</div>
						<UserOnboarding
							visible={profileDialogVisible}
							onHide={this.hideProfileDialog}
						/>
						<UserUpdateContactDetails />
						<NotificationListener />

						<LoginDialogContainer />
						<LanguageDialog />
						<BeamerUnreadCountFetcher />
					</div>
				</ThemeProvider>
			</I18nProvider>
		)
	}
}

App.propTypes = {
	auth: PropTypes.object,
}

const mapState = ({
	app: { selectedLocale, selectedManagerUUID },
	notifications,
	router,
	firebaseReducer: { auth },
	ui: {
		app: { languageDialogVisible, hasScrolledDown },
	},
}) => ({
	notifications,
	router,
	auth,
	selectedLocale,
	selectedManagerUUID,
	languageDialogVisible,
	hasScrolledDown,
})

const mapDispatchToProps = (dispatch) => ({
	_push: bindActionCreators(push, dispatch),
	_updateDimensions: bindActionCreators(
		dimensionsActions.updateDimensions,
		dispatch,
	),
	_showInfoNotification: bindActionCreators(notifyActions.info, dispatch),
	_setLanguageDialogVisible: bindActionCreators(
		appUIActions._setLanguageDialogVisible,
		dispatch,
	),
	_setSelectedLocale: bindActionCreators(
		appActions._setSelectedLocale,
		dispatch,
	),
	_setAppToolbarVisible: bindActionCreators(
		appUIActions._setAppToolbarVisible,
		dispatch,
	),
	_setHasScrolledDown: bindActionCreators(
		appUIActions._setHasScrolledDown,
		dispatch,
	),
})

export default compose(
	WithLoggedInUser,
	WithURLCompanyUpdater,
	WithSelectedCompany,
	WithSelectedCompanyManager,
	WithSelectedCompanyManagerRole,
	WithQueryParams,
	connect(mapState, mapDispatchToProps),
)(App)
