import { useCallback, useEffect, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import {
	Button,
	CircularProgress,
	DataTable,
	TableBody,
	TableColumn,
	TableHeader,
	TableRow,
} from 'react-md'
import { _createInvoices } from 'state/finance-invoice-actions'
import {
	CANCEL_BUTTON_LABEL,
	NEXT_BUTTON_LABEL,
	PREVIOUS_BUTTON_LABEL,
	SAVE_BUTTON_LABEL,
} from 'ui/messages'
import useContracts from '../Contracts/useContracts'
import type { Contract } from '../Contracts/types'
import type { ApartmentSelectOptions, VatSelectOptions } from '../types'
import type { Settings } from '../Settings/types'
import { getInvoicingPeriods } from './invoicePeriodCalculationTools'
import DatePickerISO from 'ui/components/DatePickerISO'
import moment from 'moment'
import useDialogs from 'ui/components/dialogs/useDialogs'
import { invoiceCreateHeaders } from './constants'
import { TableContainer } from 'ui/StyledComponents/Tables'
import InvoiceCreateRow from './InvoiceCreateRow'
import InvoiceCreateHeader from './InvoiceCreateHeader'
import { StandardDialogContent } from 'ui/StyledComponents/BasicComponents'
import naturalSort from 'javascript-natural-sort'
import { BondName } from '../enum'
import InvoiceCreateSingleDialog from './InvoiceCreateSingleDialog'
import type { PaymentProduct } from '../PaymentProducts/types'
import ViiluFullPageDialog, { Action } from 'ui/components/ViiluFullPageDialog'

type Props = {
	companyUUID: string
	onHide: () => void
	refreshInvoices: () => void
	massInvoicing: boolean
	apartmentOptions: ApartmentSelectOptions[]
	settings: Settings | null
	singleInvoice?: boolean
	paymentProducts: PaymentProduct[]
	vatOptions: VatSelectOptions[]
}

const InvoiceCreateDialog = ({
	companyUUID,
	onHide,
	refreshInvoices,
	massInvoicing,
	apartmentOptions,
	settings,
	singleInvoice,
	paymentProducts,
	vatOptions,
}: Props) => {
	const intl = useIntl()
	const { alert } = useDialogs()
	const { invoicingStartMonth } = settings ?? {}
	const [selectedDate, setSelectedDate] = useState(new Date())
	const [nextPeriod, setNextPeriod] = useState(false)
	const [sortByValue, setSortByValue] = useState<string>('apartmentUUID')
	const [searchByValue, setSearchByValue] = useState<string>('')

	const invoicingPeriods = getInvoicingPeriods(
		invoicingStartMonth as number,
		selectedDate,
	)

	const { currentInvoicingPeriod, nextInvoicingPeriod } = invoicingPeriods
	const { currentStartDate, currentEndDate } = currentInvoicingPeriod
	const { nextStartDate, nextEndDate } = nextInvoicingPeriod

	const filters = useMemo(
		() => ({
			companyUUID,
			apartmentUUID: '',
			reviewDate: new Date(),
			invoicingMethod: '',
			searchWord: '',
		}),
		[companyUUID],
	)
	const { loadingContracts, contracts } = useContracts(filters)
	const [loading, setLoading] = useState(false)

	const filteredContracts = contracts.filter((contract) => {
		if (searchByValue) {
			const lowerCaseSearchValue = searchByValue.toLowerCase()
			return (
				contract.partyName?.toLowerCase().includes(lowerCaseSearchValue) ||
				contract.secondaryPartyName
					?.toLowerCase()
					.includes(lowerCaseSearchValue)
			)
		}
		return true
	})

	const [step, setStep] = useState(0)

	const [selectedContracts, setSelectedContracts] = useState<
		Map<string, Contract>
	>(new Map())

	const initSelectedContracts = () => {
		setSelectedContracts(new Map())
	}

	const selectAllContracts = useCallback(
		(allSelected: boolean) => {
			if (allSelected) {
				initSelectedContracts()
			} else {
				const map = new Map<string, Contract>()
				contracts.forEach((c) => map.set(c.uuid, c))
				setSelectedContracts(map)
			}
		},
		[contracts],
	)

	useEffect(() => {
		if (massInvoicing) {
			selectAllContracts(false)
			setNextPeriod(true)
		}
	}, [selectAllContracts, massInvoicing])

	const createInvoices = async () => {
		setLoading(true)

		const invoicingStartDate = nextPeriod ? nextStartDate : selectedDate
		const invoicingEndDate = nextPeriod ? nextEndDate : currentEndDate

		try {
			const contractUUIDs = Array.from(selectedContracts.keys())
			const result = await _createInvoices({
				contractUUIDs,
				massInvoicing,
				nextPeriod,
				invoicingStartDate,
				invoicingEndDate,
			})
			if (!result?.ok) {
				throw new Error('Response is not ok.')
			}
			refreshInvoices()
			onHide()
		} catch (error) {
			alert('Something went wrong.')
			setLoading(false)
		}
	}

	const selectContract = (contract: Contract) => {
		setSelectedContracts((prev) => {
			const updatedMap = new Map(prev)

			if (updatedMap.has(contract.uuid)) {
				updatedMap.delete(contract.uuid)
			} else {
				updatedMap.set(contract.uuid, contract)
			}

			return updatedMap
		})
	}

	const renderTableHeaders = (preview: boolean) => {
		const allSelected = selectedContracts?.size === filteredContracts.length
		return (
			<TableRow>
				{!preview && !singleInvoice && (
					<TableColumn key={'checkbox-header'}>
						<input
							type="checkbox"
							checked={allSelected}
							onChange={() => selectAllContracts(allSelected)}
						/>
					</TableColumn>
				)}
				{invoiceCreateHeaders
					.filter((h) => h.show)
					.map((header) => (
						<TableColumn key={header.key}>
							<p className="text-strong text-subtle">{header.title(intl)}</p>
						</TableColumn>
					))}
			</TableRow>
		)
	}

	const sortBy = (value: string) => {
		setSortByValue(value)
	}

	const selectAllBy = (value: string) => {
		const updatedContracts = new Map(selectedContracts)

		filteredContracts.forEach((contract) => {
			const hasMatchingBond = contract.partyBonds?.some(
				(bond) => bond.name === value,
			)

			if (hasMatchingBond && !updatedContracts.has(contract.uuid)) {
				updatedContracts.set(contract.uuid, contract)
			}
		})

		setSelectedContracts(updatedContracts)
	}

	const renderContent = (preview: boolean = false) => {
		if (loadingContracts || loading) {
			return <CircularProgress id="invoice-create-progress" />
		}

		if (!contracts?.length) {
			return (
				<p>
					{intl.formatMessage({
						defaultMessage: 'Sopimuksia ei löytynyt.',
						description: 'Message for empty finance contracts list.',
					})}
				</p>
			)
		}

		return (
			<StandardDialogContent>
				<InvoiceCreateHeader
					sortByValue={sortByValue}
					sortBy={sortBy}
					selectAllBy={selectAllBy}
					searchBy={(value) => setSearchByValue(value)}
					preview={preview}
				/>
				<TableContainer
					style={{
						maxHeight: 'calc(100vh - 205px)',
					}}
				>
					<DataTable
						plain
						style={{
							background: 'white',
							maxHeight: 'calc(100vh - 205px)',
						}}
					>
						<TableHeader>{renderTableHeaders(preview)}</TableHeader>
						<TableBody>
							{filteredContracts
								.filter((contract) => {
									if (preview) {
										return selectedContracts.has(contract.uuid)
									}
									return true
								})
								.sort((a, b) => {
									if (sortByValue === 'apartmentUUID') {
										return naturalSort(
											a.apartmentIdentifier,
											b.apartmentIdentifier,
										)
									} else if (sortByValue === 'partyName') {
										return naturalSort(a.partyName, b.partyName)
									} else if (
										Object.values(BondName).includes(sortByValue as BondName)
									) {
										const aValue = a?.partyBonds?.some(
											(bond) => bond.name === sortByValue,
										)
										const bValue = b?.partyBonds?.some(
											(bond) => bond.name === sortByValue,
										)

										if (aValue && !bValue) {
											return -1
										} else if (!aValue && bValue) {
											return 1
										} else {
											return 0
										}
									}
									return naturalSort(
										a.apartmentIdentifier,
										b.apartmentIdentifier,
									)
								})
								.map((contract) => (
									<InvoiceCreateRow
										key={contract.uuid}
										selected={selectedContracts?.has(contract.uuid)}
										contract={contract}
										handleOnClick={() => selectContract(contract)}
										apartmentOptions={apartmentOptions}
										preview={preview}
										singleInvoice={singleInvoice || false}
									/>
								))
								.concat(
									// Concat one empty row to indicate list ending to the user
									<tr
										key="empty-row"
										className="full-width"
										style={{ height: 256, background: 'white' }}
									></tr>,
								)}
						</TableBody>
					</DataTable>
				</TableContainer>
			</StandardDialogContent>
		)
	}

	const formatDate = (date: Date): string => {
		return moment(new Date(date)).format('L')
	}

	const renderContract = () => {
		return (
			<StandardDialogContent style={{ alignSelf: 'center' }}>
				{nextPeriod ? (
					<p>
						<FormattedMessage
							defaultMessage="Laskut luodaan seuraavalle laskutusjaksolle: {nextStartDate} – {nextEndDate}"
							description="Invoices are created for the next invoicing period: {nextStartDate} – {nextEndDate}"
							values={{
								nextStartDate: formatDate(nextStartDate),
								nextEndDate: formatDate(nextEndDate),
							}}
						/>
					</p>
				) : (
					<p>
						<FormattedMessage
							defaultMessage="Laskut luodaan kuluvalle laskutusjaksolle: {currentStartDate} – {currentEndDate}, alkaen kuluvasta päivästä {selectedDate}"
							description="Invoices are created for the current invoicing period: {currentStartDate} – {currentEndDate}, starting from the current date {selectedDate}"
							values={{
								currentStartDate: formatDate(currentStartDate),
								currentEndDate: formatDate(currentEndDate),
								selectedDate: formatDate(selectedDate),
							}}
						/>
					</p>
				)}

				<p style={{ marginTop: '15px' }}>
					<FormattedMessage
						defaultMessage="Eräpäivä joka kuukauden {dueDate}. päivä"
						description="Due date on the {dueDate} of each month."
						values={{
							dueDate: settings?.dueDate,
						}}
					/>
				</p>

				<DatePickerISO
					id="selectedDate"
					label="Luontipäivä"
					style={{ marginTop: '15px' }}
					value={selectedDate}
					onChange={(isoDate: string) => {
						if (isoDate) {
							setSelectedDate(new Date(isoDate))
						} else {
							setSelectedDate(new Date())
						}
					}}
					disabled={nextPeriod}
				/>
				{!massInvoicing && (
					<div style={{ marginTop: '20px' }}>
						<input
							type="checkbox"
							checked={nextPeriod}
							onChange={() => {
								setNextPeriod(!nextPeriod)
								setSelectedDate(new Date())
							}}
						/>
						<label>
							<FormattedMessage
								defaultMessage="Luo laskut seuraavalle laskutusjaksolle: {nextStartDate} – {nextEndDate}"
								description="Create invoices for the next invoicing period: {nextStartDate} – {nextEndDate}"
								values={{
									nextStartDate: formatDate(nextStartDate),
									nextEndDate: formatDate(nextEndDate),
								}}
							/>
						</label>
					</div>
				)}

				{renderContent(true)}
			</StandardDialogContent>
		)
	}

	const changeStep = (step: number) => {
		if (step === 0 && singleInvoice) {
			initSelectedContracts()
		}
		if (step === 1 && !selectedContracts.size) {
			const alertMsg = intl.formatMessage({
				defaultMessage:
					'Valitse vähintään yksi sopimus, jotta laskun luontia voidaan jatkaa.',
				description:
					'Notification to select atleast 1 contract before continuing invoice creation',
			})
			alert(alertMsg)
		} else {
			setStep(step)
		}
	}

	const actions: Action[] = [
		step === 0 && {
			id: 'dialog-cancel',
			children: CANCEL_BUTTON_LABEL(intl),
			onClick: onHide,
		},
		step === 0 &&
			!singleInvoice && {
				id: 'dialog-next',
				secondary: true,
				children: NEXT_BUTTON_LABEL(intl),
				onClick: () => changeStep(1),
			},
		step === 1 && {
			id: 'dialog-previous',
			children: PREVIOUS_BUTTON_LABEL(intl),
			onClick: () => changeStep(0),
		},
		step === 1 && {
			id: 'dialog-save',
			secondary: true,
			children: SAVE_BUTTON_LABEL(intl),
			onClick: () => createInvoices(),
		},
	].filter(Boolean) as Action[]

	const steps = [{ render: renderContent }, { render: renderContract }]

	const currentStep = steps[step]?.render

	const selectedContract = selectedContracts.values().next().value
	const renderCreateSingle =
		singleInvoice && selectedContracts.size === 1 && selectedContract

	if (renderCreateSingle) {
		return (
			<InvoiceCreateSingleDialog
				onHide={onHide}
				onBack={() => changeStep(0)}
				refreshInvoices={refreshInvoices}
				contract={selectedContract}
				paymentProducts={paymentProducts}
				apartmentOptions={apartmentOptions}
				vatOptions={vatOptions}
			/>
		)
	}

	return (
		<ViiluFullPageDialog
			id="invoice-create-dialog"
			visible
			onHide={onHide}
			actions={actions}
			focusOnMount={false}
			title={
				massInvoicing
					? intl.formatMessage({
							defaultMessage: 'Luo massalaskuja',
							description: 'Title for the finance mass invoice create dialog.',
					  })
					: intl.formatMessage({
							defaultMessage: 'Luo lasku',
							description: 'Title for the finance invoice create dialog.',
					  })
			}
			toolbarStyle={{
				background: 'var(--color-secondary-dark)',
			}}
			nav={
				<Button
					icon
					onClick={() => {
						onHide()
					}}
				>
					close
				</Button>
			}
		>
			{currentStep && !renderCreateSingle && currentStep()}
		</ViiluFullPageDialog>
	)
}

export default InvoiceCreateDialog
