import { useCallback, useEffect, useState } from 'react'
import { _getAccountsLedgerList } from 'state/finance-accounts-ledger-actions'
import type { AccountsLedger, AccountsLedgerFilters } from './types'

function useAccountsLedger(filters: AccountsLedgerFilters) {
	const [accountsLedger, setAccountsLedger] = useState<AccountsLedger[]>([])

	const [loadingAccountsLedger, setLoading] = useState<boolean>(false)
	const [errorAccountsLedger, setError] = useState<string | null>(null)

	const doQuery = useCallback(async () => {
		setLoading(true)
		setError(null)
		let data: AccountsLedger[] = []

		try {
			const response = await _getAccountsLedgerList(filters)

			if (response && response.ok) {
				const res = await response.json()
				if (res.list && Array.isArray(res.list)) data = res.list
			} else {
				console.error('Something went wrong.')
				setError('Failed to fetch Accounts Ledger')
			}
		} catch (err) {
			console.error('Error fetching accountsLedger:', err)
			setError('An error occurred while fetching Accounts Ledger.')
		}
		setAccountsLedger(data)
		setLoading(false)
	}, [filters])
	useEffect(() => {
		doQuery()
	}, [doQuery])

	return {
		accountsLedger,
		loadingAccountsLedger,
		errorAccountsLedger,
		refreshAccountsLedger: doQuery,
	}
}

export type useAccountsLedgerResult = ReturnType<typeof useAccountsLedger>

export default useAccountsLedger
