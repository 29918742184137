import { useCallback, useEffect, useState } from 'react'
import { _getPayments } from 'state/finance-payment-actions'
import type { Payments, PaymentFilters } from './types'

type State = {
	loading: boolean
	data: Payments[]
}

function usePayments(filters: PaymentFilters) {
	const [state, setState] = useState<State>({ loading: true, data: [] })

	const doQuery = useCallback(async () => {
		if (!filters.companyUUID) {
			setState({ loading: false, data: [] })
			return
		}

		setState((prev) => ({ ...prev, loading: true }))

		let data: Payments[] = []

		try {
			const response = await _getPayments(filters)
			if (response?.ok) {
				const result = await response.json()
				data = result.payments
			} else {
				throw new Error('Response is not ok.')
			}
		} catch (error) {
			console.error(error)
		}
		setState({
			loading: false,
			data,
		})
	}, [filters])

	useEffect(() => {
		doQuery()
	}, [doQuery])

	return { ...state, refresh: doQuery }
}

export default usePayments
