import { useCallback, useEffect, useState } from 'react'
import { _getPayment } from 'state/finance-payment-actions'
import type { Payment } from './types'

type State = {
	loading: boolean
	payment: Payment | undefined
}

function usePayment(paymentUUID: string | null) {
	const [state, setState] = useState<State>({
		loading: false,
		payment: undefined,
	})

	const doQuery = useCallback(async () => {
		if (!paymentUUID) {
			return
		}

		setState((prev) => ({ ...prev, loading: true }))

		let payment: Payment | undefined

		try {
			const response = await _getPayment(paymentUUID)
			if (response?.ok) {
				const result = await response.json()
				payment = result.payment
			} else {
				throw new Error('Response is not ok.')
			}
		} catch (error) {
			console.error(error)
		}
		setState({
			loading: false,
			payment,
		})
	}, [paymentUUID])

	useEffect(() => {
		doQuery()
	}, [doQuery])

	return { ...state, refresh: doQuery }
}

export default usePayment
