import { TableColumn, TableRow } from 'react-md'
import type {
	LoanCreateFormInputs,
	LoanApartmentWithShareholderFields,
} from '../types'
import { formatCurrency } from 'util/formatStrings'
import { Controller, useFormContext } from 'react-hook-form'

type LoansTableRowProps = {
	apartment: LoanApartmentWithShareholderFields
}

const LoanApartmentShareGroupsTableRow = (props: LoansTableRowProps) => {
	const { apartment } = props
	const { control } = useFormContext<LoanCreateFormInputs>()

	return (
		<Controller
			name={`selectedSharegroups.${apartment.uuid}`}
			control={control}
			defaultValue={undefined}
			render={({ field }) => (
				<TableRow
					key={apartment.uuid}
					onCheckboxClick={() => {
						if (field.value !== undefined) {
							field.onChange(undefined)
						} else {
							field.onChange({
								...apartment,
							})
						}
					}}
					selected={field.value !== undefined}
				>
					<TableColumn>{apartment.identifier}</TableColumn>
					<TableColumn>{apartment.units}</TableColumn>
					<TableColumn>{formatCurrency(apartment.loanShareAmount)}</TableColumn>
					<TableColumn>{formatCurrency(apartment.debtShareAmount)}</TableColumn>
				</TableRow>
			)}
		/>
	)
}

export default LoanApartmentShareGroupsTableRow
