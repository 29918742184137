import { getAuth } from 'firebase/auth'
import { LOCALES } from '../i18n/locales'
import { firebaseApp } from './store'
import { statusOptions } from 'ui/TaskManager/statusOptions'

const initialState = {
	selectedCompanyUUID: null,
	selectedCompanyURLName: null,
	selectedApartmentInCompany: {},
	selectedManagerUUID: null,
	hiddenMessagingTokenBannerScopes: {},
	localMessagingToken: null,
	taskManagementFiltersManager: {
		manager: null,
		company: null,
		assignee: null,
		categories: [],
		tag: null,
		template: null,
		sortBy: 'dueDateAscending',
		ganttSortBy: 'assigneeAscending',
		status: statusOptions.map((s) => s.value),
		type: {
			task: true,
			meeting: true,
		},
		apartmentId: null,
	},
	taskManagementFiltersCompany: {
		assignee: null,
		categories: [],
		tag: null,
		sortBy: 'dueDateAscending',
		ganttSortBy: 'assigneeAscending',
		status: statusOptions.map((s) => s.value),
		type: {
			task: true,
			meeting: true,
		},
		apartmentId: null,
	},
	taskManagementSettings: {
		showAssignee: true,
		showTicketId: true,
		showQuickAction: true,
		showTags: true,
		showMeetingProcess: true,
		showCompanyName: false,
		showCompanyReference: false,
		showBoardOverride: false,
		filtersVisible: true,
	},
	selectedLocale: null,
	selectedFinanceCompanyUUID: null,
	initialized: true,
}

function reducer(state = initialState, action) {
	if (!state?.initialized) {
		state = { ...initialState, ...state, initialized: true }
	}

	if (action.type === 'APP_SET_SELECTED_COMPANY') {
		return {
			...state,
			selectedCompanyUUID: action.company.uuid,
			selectedCompanyURLName: action.company.urlName,
		}
	} else if (action.type === 'APP_SET_SELECTED_CUSTOMER_COMPANY') {
		return {
			...state,
			selectedCustomerCompanyUUID: action.company.uuid,
			selectedCustomerCompanyURLName: action.company.urlName,
		}
	} else if (action.type === 'APP_SET_SELECTED_APARTMENT_UUID') {
		if (!getAuth(firebaseApp).currentUser) {
			console.error('non-authenticated user trying to set selected apartment')
		}
		const userId = getAuth(firebaseApp).currentUser
			? getAuth(firebaseApp).currentUser.uid
			: 'unauthenticated'
		return {
			...state,
			selectedApartmentInCompany: {
				...state.selectedApartmentInCompany,
				[action.companyUUID + userId]: action.apartmentUUID,
			},
		}
	} else if (action.type === 'APP_SET_SELECTED_MANAGER') {
		return {
			...state,
			selectedManagerUUID: action.managerUUID,
			taskManagementFiltersManager: {
				...state.taskManagementFiltersManager,
				manager: action.manager,
				company: null,
				assignee: null,
				sortBy: 'dueDateAscending',
				categories: [],
				tag: null,
				template: null,
				apartmentId: null,
			},
		}
	} else if (action.type === 'APP_SET_SELECTED_LOCALE') {
		if (firebaseApp) {
			if (!action.selectedLocale) {
				getAuth(firebaseApp).languageCode = 'fi'
			} else if (action.selectedLocale === LOCALES.FINNISH) {
				getAuth(firebaseApp).languageCode = 'fi'
			} else if (action.selectedLocale === LOCALES.SWEDISH) {
				getAuth(firebaseApp).languageCode = 'sv'
			} else if (action.selectedLocale === LOCALES.ENGLISH) {
				getAuth(firebaseApp).languageCode = 'en'
			}
		}
		return { ...state, selectedLocale: action.selectedLocale }
	} else if (action.type === 'APP_HIDE_MESSAGING_TOKEN_BANNER_SCOPE') {
		return {
			...state,
			hiddenMessagingTokenBannerScopes: {
				...state.hiddenMessagingTokenBannerScopes,
				[action.scope]: true,
			},
		}
	} else if (action.type === 'APP_SET_LOCAL_MESSAGING_TOKEN') {
		return { ...state, localMessagingToken: action.token }
	} else if (action.type === 'APP_SET_TASK_MANAGEMENT_FILTERS_MANAGER') {
		for (const key in action.filters) {
			return {
				...state,
				taskManagementFiltersManager: {
					...state.taskManagementFiltersManager,
					[key]: action.filters[key],
				},
			}
		}
	} else if (action.type === 'APP_SET_TASK_MANAGEMENT_FILTERS_COMPANY') {
		for (const key in action.filters) {
			return {
				...state,
				taskManagementFiltersCompany: {
					...state.taskManagementFiltersCompany,
					[key]: action.filters[key],
				},
			}
		}
	} else if (action.type === 'APP_SET_TASK_MANAGEMENT_SETTINGS') {
		for (const key in action.settings) {
			return {
				...state,
				taskManagementSettings: {
					...state.taskManagementSettings,
					[key]: action.settings[key],
				},
			}
		}
	} else if (action.type === 'APP_SET_TASK_MANAGEMENT_FILTERS_VISIBLE') {
		return {
			...state,
			taskManagementSettings: {
				...state.taskManagementSettings,
				filtersVisible: action.visible,
			},
		}
	} else if (action.type === 'APP_SET_SELECTED_FINANCE_COMPANY') {
		return {
			...state,
			selectedFinanceCompanyUUID: action.company.uuid,
		}
	}

	// Initialize for users who don't yet have manager task manager filters
	if (!state.taskManagementFiltersManager) {
		// taskManagementFilters is deleted as it is legacy
		delete state['taskManagementFilters']

		state.taskManagementFiltersManager = {
			manager: null,
			company: null,
			assignee: null,
			categories: [],
			tag: null,
			template: null,
			sortBy: 'dueDateAscending',
			ganttSortBy: 'assigneeAscending',
			status: statusOptions.map((s) => s.value),
			type: {
				task: true,
				meeting: true,
			},
			apartmentId: null,
		}
		state.taskManagementFiltersCompany = {
			assignee: null,
			categories: [],
			tag: null,
			sortBy: 'dueDateAscending',
			ganttSortBy: 'assigneeAscending',
			status: statusOptions.map((s) => s.value),
			type: {
				task: true,
				meeting: true,
			},
			apartmentId: null,
		}
	}

	if (state.taskManagementFiltersManager.status === undefined) {
		state.taskManagementFiltersManager.status = statusOptions.map(
			(s) => s.value,
		)
	}

	if (state.taskManagementFiltersManager.type === undefined) {
		state.taskManagementFiltersManager.type = {
			task: true,
			meeting: true,
		}
	}

	if (state.taskManagementFiltersCompany.status === undefined) {
		state.taskManagementFiltersCompany.status = statusOptions.map(
			(s) => s.value,
		)
	}

	if (state.taskManagementFiltersCompany.type === undefined) {
		state.taskManagementFiltersCompany.type = {
			task: true,
			meeting: true,
		}
	}

	if (!state.taskManagementSettings) {
		state.taskManagementSettings = {
			showAssignee: true,
			showTicketId: true,
			showQuickAction: true,
			showTags: true,
			showMeetingProcess: true,
			showCompanyName: false,
			showCompanyReference: false,
			showBoardOverride: false,
			filtersVisible: true,
		}
	}

	return state
}

export default reducer
