import { DialogContainer, DialogContainerProps } from 'react-md'

// NOTE (03/2024): This component was originally created to fix a margin issue on iOS devices.
// It was since fixed in a more reliable way, so this is now just a wrapper for DialogContainer.
// For consistency, we should continue to use it instead of DialogContainer.

type Props = {
	children: any
	visible: boolean
} & Omit<DialogContainerProps, 'children' | 'visible'>

const ViiluDialog = ({ children, visible, ...rest }: Props) => {
	return (
		<DialogContainer {...(rest as DialogContainerProps)} visible={visible}>
			{children}
		</DialogContainer>
	)
}

export default ViiluDialog
