import { FormattedMessage } from 'react-intl'
import { List, ListItem } from 'react-md'
import styled from 'styled-components'
import Layout from '../Layout'
import DefaultTemplate from './DefaultTemplate'

const Title = styled.h1`
	margin-bottom: 24px;
`

const StyledList = styled(List)`
	flex: 1;
	margin: 16px 0;
	overflow: auto;
`

const TemplateContainer = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
`

function SMSPreview({
	stepTitle,
	onPreviousClick,
	onDraftClick,
	onNextClick,
	messageServiceProcess: { recipients, title, bodyText, attachments, viewUrl },
}) {
	const smsRecipients = recipients.filter((r) => r.checked?.['sms'])

	const left = (
		<>
			<Title>{stepTitle}</Title>
			<p>
				<FormattedMessage
					defaultMessage={
						'Olet lähettämässä tekstiviestiä alla oleviin numeroihin. Näet tekstiviestin sisällön ' +
						'oikealla. Huomioi, että oikealla on pelkkä esikatselu eikä sen sisältämät linkit johda minnekään.'
					}
					description="Informational description of the sms preview step in the general sender component."
				/>
			</p>
			<StyledList>
				{smsRecipients.map((i) => (
					<ListItem key={i.uuid} primaryText={i.phoneNumber} />
				))}
			</StyledList>
		</>
	)

	let right = (
		<TemplateContainer>
			<DefaultTemplate
				title={title}
				bodyText={bodyText}
				viewUrl={viewUrl}
				attachments={attachments}
			/>
		</TemplateContainer>
	)

	return (
		<>
			<Layout
				left={left}
				right={right}
				onPreviousClick={onPreviousClick}
				onDraftClick={onDraftClick}
				onNextClick={onNextClick}
			/>
		</>
	)
}

export default SMSPreview
