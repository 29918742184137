import { backendFunction } from './actions-util'

export const _fivaldiCompaniesByManagerUUID = (managerUUID) => async () =>
	backendFunction('fivaldiCompaniesByManagerUUID', {
		managerUUID,
	})

export const _getCompanyByBusinessId = (managerUUID, businessId) => async () =>
	backendFunction(
		'fivaldiCompanyByBusinessId',
		{
			managerUUID,
			businessId,
		},
		{
			timeout: 300000,
		},
	)

export const _getCompanyByCUID = (managerUUID, fivaldiCUID) => async () =>
	backendFunction(
		'fivaldiCompanyByCUID',
		{
			managerUUID,
			fivaldiCUID,
		},
		{
			timeout: 300000,
		},
	)

export const _importCompany =
	({
		name,
		apartmentCount,
		address,
		zip,
		area,
		businessId,
		managerUUID,
		fivaldiCompanyId,
		apartments,
		buildings,
		lots,
		features,
		featuresHidden,
		featureSettings,
		serviceLevel,
	}) =>
	async () =>
		backendFunction('fivaldiCompanyImport', {
			name,
			apartmentCount,
			address,
			zip,
			area,
			businessId,
			managerUUID,
			fivaldiCompanyId: parseInt(fivaldiCompanyId, 10),
			apartments,
			buildings,
			lots,
			features,
			featuresHidden,
			featureSettings,
			serviceLevel,
		})

export const _setEnabled = (managerUUID, enabled) => async () =>
	backendFunction('fivaldiEnabledSet', {
		managerUUID,
		enabled,
	})

export const _setCredentials = (managerUUID, account) => async () =>
	backendFunction('fivaldiCredentialsSet', {
		managerUUID,
		account,
	})

export const _companyLink =
	(managerUUID, fivaldiCompanyId) => async (dispatch, getState) =>
		backendFunction('fivaldiCompanyLink', {
			managerUUID,
			companyUUID: getState().app.selectedCompanyUUID,
			fivaldiCompanyId,
		})

export const _apartmentFinancials =
	(companyUUID, managerUUID, apartmentUUID, userRole) => async () =>
		backendFunction('fivaldiApartmentFinancials', {
			companyUUID,
			managerUUID,
			apartmentUUID,
			userRole,
		})

export const _getShareholders = (managerUUID, companyUUID, getViiluConsents) =>
	backendFunction(
		'fivaldiShareholders',
		{
			managerUUID,
			companyUUID,
			getViiluConsents,
		},
		{
			timeout: 300000,
		},
	)

export const _getTenants = (managerUUID, companyUUID) =>
	backendFunction(
		'fivaldiTenants',
		{
			managerUUID,
			companyUUID,
		},
		{
			timeout: 300000,
		},
	)

export const _getShareholdersAndTenants = (
	managerUUID,
	companyUUID,
	getViiluConsents,
) =>
	backendFunction(
		'fivaldiShareholdersAndTenants',
		{
			managerUUID,
			companyUUID,
			getViiluConsents,
		},
		{
			timeout: 300000,
		},
	)

export const _getCustomers = () => backendFunction('fivaldiCustomers', {})

export const _getCompanyRepairs = async (companyUUID) =>
	backendFunction('fivaldiCompanyRepairs', { companyUUID })

export const _getPropertyInformation = async (companyUUID) =>
	backendFunction('fivaldiEstatePreferences', { companyUUID })
