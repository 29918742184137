import { Button, FontIcon } from 'react-md'
import styled from 'styled-components'

export const BaseButton = styled(Button)`
	background: white;
	color: rgba(0, 0, 0, 0.87);
	font-weight: bold;
	margin-top: 8px;
	margin-bottom: 8px;
`
export const StyledFontIcon = styled(FontIcon)`
	margin-right: 8px;
	margin-left: -8px;
`
