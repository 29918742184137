import { useEffect, useMemo, useState } from 'react'
import { injectIntl } from 'react-intl'
import { Button, Toolbar } from 'react-md'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import styled from 'styled-components'
import LoadingState from 'ui/components/LoadingState'
import WithSelectedCompany from 'ui/components/WithSelectedCompany'
import { steps } from './config'
import Stepper from './Stepper'

import * as messageServiceActions from 'state/message-service-actions'
import * as notifyActions from 'state/notifyActions'
import useDialogs from 'ui/components/dialogs/useDialogs'

const Container = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
	overflow: hidden;
`

function MessageServiceProcess({
	toolbarTitle,
	messageServiceProcess,
	selectedCompany,
	onHide,
	_createProcess,
	_updateProcess,
	_showInfoNotification,
	intl,
}) {
	const [process, setProcess] = useState()
	const [isLoading, setIsLoading] = useState(true)
	const { alert } = useDialogs()

	useEffect(() => {
		const initProcess = async () => {
			const lettersEnabled = Boolean(selectedCompany.features?.postal)
			let deliveryMethods = ['email']
			if (lettersEnabled) {
				deliveryMethods.push('letter')
			}
			const result = await messageServiceActions._createProcess({
				companyUUID: selectedCompany.uuid,
				title: messageServiceProcess?.title,
				body: messageServiceProcess?.body,
				bodyText: messageServiceProcess?.bodyText,
				attachments: messageServiceProcess?.attachments,
				deliveryMethods,
				generator: messageServiceProcess?.generator,
				generatorDataUUID: messageServiceProcess?.generatorDataUUID,
				reportingDocumentType: messageServiceProcess?.reportingDocumentType,
			})

			if (!result || result.success === false) {
				await alert('Jokin meni pieleen.')
				return false
			}

			setProcess(result.data)
			setIsLoading(false)
			return true
		}

		if (!messageServiceProcess?.uuid) {
			initProcess()
		} else {
			setProcess(messageServiceProcess)
			setIsLoading(false)
		}
	}, [
		_createProcess,
		messageServiceProcess,
		selectedCompany.uuid,
		selectedCompany.features?.postal,
		alert,
	])

	const updateProcess = async (process) => {
		setIsLoading(true)
		const result = await _updateProcess(process)
		setIsLoading(false)

		if (!result || result.success === false) {
			const code = result?.ex?.details?.code
			if (code === 'invalid-status') {
				const message = intl.formatMessage({
					defaultMessage:
						'Tämä viesti on jo Lähetetty-tilassa, ja sitä ei voi enää muokata!',
					description: 'Error message for message status being "sent".',
				})
				await alert(message)
			} else {
				const message = intl.formatMessage({
					defaultMessage: 'Jotain meni pieleen.',
					description: 'Error message for unknown error.',
				})
				await alert(message)
			}
			return false
		}

		setProcess(result.data)
		return true
	}

	const [activeStepIndex, reachedStepIndex] = useMemo(() => {
		let active = steps.findIndex((s) => s.id === process?.step?.id)
		let reached = steps.findIndex((s) => s.id === process?.step?.reachedId)
		const found = (index) => (index === -1 ? 0 : index)
		return [found(active), found(reached)]
	}, [process?.step?.id, process?.step?.reachedId])

	const goToStep = async (step) => {
		const nextStep = steps.find((s) => s.id === step.id)

		if (!nextStep) {
			return
		}

		const withNextStep = {
			...process,
			step: {
				...process.step,
				...step,
			},
		}

		await updateProcess(withNextStep)
	}

	const onPreviousClick =
		activeStepIndex === 0
			? null
			: (childId) => {
					if (childId) {
						goToStep({
							id: process.step.id,
							childId: childId,
						})
					} else {
						const previousStep = steps[activeStepIndex - 1]

						if (!previousStep) {
							return
						}

						goToStep({
							id: previousStep.id,
							childId: '',
						})
					}
			  }

	const onDraftClick = async () => {
		const ok = await updateProcess(process)
		if (ok) {
			_showInfoNotification('✅ Lähetyksen luonnos tallennettu.')
			onHide()
		}
	}

	const onNextClick = (childId) => {
		if (childId) {
			goToStep({
				id: process.step.id,
				childId: childId,
			})
		} else {
			const nextIndex = activeStepIndex + 1
			const nextStep = steps[nextIndex]

			if (!nextStep) {
				return
			}

			let reachedId = process.step?.reachedId || 0

			if (!reachedId || nextIndex > reachedStepIndex) {
				reachedId = nextStep.id
			}

			goToStep({
				id: nextStep.id,
				childId: '',
				reachedId,
			})
		}
	}

	const renderStep = () => {
		const activeStep = steps[activeStepIndex]
		const childSteps = activeStep.childSteps
		const ActiveStepComponent = activeStep?.component

		return (
			<ActiveStepComponent
				messageServiceProcess={process}
				setMessageServiceProcess={setProcess}
				childSteps={childSteps}
				onPreviousClick={onPreviousClick}
				onDraftClick={onDraftClick}
				onNextClick={onNextClick}
				onHide={onHide}
			/>
		)
	}

	return (
		<Container>
			<Toolbar
				title={toolbarTitle || 'Viestintäpalvelu'}
				colored
				style={{
					boxShadow: 'none',
					background: 'var(--color-secondary-dark)',
				}}
				nav={
					<Button icon onClick={onHide}>
						close
					</Button>
				}
				actions={
					<Stepper
						steps={steps}
						goToStep={goToStep}
						activeStepIndex={activeStepIndex}
						reachedStepIndex={reachedStepIndex}
					/>
				}
			/>

			{isLoading ? <LoadingState /> : renderStep()}
		</Container>
	)
}

const mapState = () => ({})

const mapDispatchToProps = (dispatch) => ({
	_updateProcess: bindActionCreators(
		messageServiceActions._updateProcess,
		dispatch,
	),
	_showInfoNotification: bindActionCreators(notifyActions.info, dispatch),
})

export default compose(
	injectIntl,
	WithSelectedCompany,
	connect(mapState, mapDispatchToProps),
)(MessageServiceProcess)
