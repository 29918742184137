import { CircularProgress, FontIcon } from 'react-md'
import styled from 'styled-components'
import { SendStatus } from './index'

const Container = styled.div`
	display: flex;
	align-items: center;
	margin: 24px 24px 24px 0;
`

const Text = styled.span`
	margin-left: 24px;
`

const Spinner = styled(CircularProgress)`
	margin: 0;
`

function SendingStatus({ status, text }) {
	const renderIcon = () => {
		switch (status) {
			case SendStatus.NOT_SENT:
				return <FontIcon style={{ color: 'lightblue' }}>outgoing_mail</FontIcon>
			case SendStatus.SENDING:
				return <Spinner id="sending-progress" />
			case SendStatus.SUCCESS:
				return <FontIcon style={{ color: 'green' }}>done</FontIcon>
			case SendStatus.ERROR:
				return <FontIcon style={{ color: 'red' }}>error</FontIcon>
			default:
				break
		}
	}

	return (
		<Container>
			{renderIcon()}
			<Text>{text}</Text>
		</Container>
	)
}

export default SendingStatus
