const notify = (text, options) => ({
	type: 'ADD_NOTIFICATION',
	notification: {
		key: Date.now() + Math.random(), // 'key' can be any sequence of numbers or characters, but it has to be unique.
		text,
		options,
	},
})

export const info = (text, options) => {
	return notify(text, { ...options, variant: 'default' })
}

export const warning = (text, options) => {
	return notify(text, { ...options, variant: 'warning' })
}

export const error = (text, options) => {
	return notify(text, { ...options, variant: 'error' })
}

export const success = (text, options) => {
	return notify(text, { ...options, variant: 'success' })
}

export const dismissNotification = (key) => ({
	type: 'DISMISS_NOTIFICATION',
	dismissAll: !key,
	key,
})

export const removeNotification = (key) => ({
	type: 'REMOVE_NOTIFICATION',
	key,
})
