import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'

import { BaseButton } from './styles'
import GoogleLogo from 'svg/google-logo.svg'

const StyledButton = styled(BaseButton)`
	background: #fafafa;
	color: rgba(0, 0, 0, 0.8);
	border-color: rgba(0, 0, 255, 0.2);
	border-width: 1px;
	border-style: solid;
	font-size: 13px;
`
const StyledIcon = styled.img`
	margin-left: -7px;
	margin-right: 9px;
`

const GoogleButton = ({ onClick }) => {
	return (
		<StyledButton
			raised
			onClick={onClick}
			iconEl={<StyledIcon src={GoogleLogo} width={20} height={20} />}
		>
			<FormattedMessage
				defaultMessage="Google-tilillä"
				description="Button that selects the Google authentication flow"
			/>
		</StyledButton>
	)
}

export default GoogleButton
