import styled from 'styled-components'
import BulletinsSummary from 'ui/MessageService/History/DetailsDialog/Tabs/Summary/BulletinsSummary'
import EmailSummary from 'ui/MessageService/History/DetailsDialog/Tabs/Summary/EmailSummary'
import InfoscreenSummary from 'ui/MessageService/History/DetailsDialog/Tabs/Summary/InfoscreenSummary'
import PostalSummary from 'ui/MessageService/History/DetailsDialog/Tabs/Summary/PostalSummary'
import SMSSummary from 'ui/MessageService/History/DetailsDialog/Tabs/Summary/SMSSummary'
import MessageServiceMassJobs from './MessageServiceMassJobs'

const Summaries = styled.div`
	h2 {
		margin-top: 32px;
		font-size: 1.5rem;
	}
`

const TwoColumns = styled.div`
	flex: 1;
	display: flex;
	overflow: hidden;
`

const Column = styled.div`
	height: 100%;
	width: 50%;
	min-width: 500px;
	padding: 16px;
	overflow-y: auto;

	h1 {
		margin-top: 0;
		font-size: 1.8rem;
	}

	h2 {
		font-size: 1.5rem;
	}

	p {
		line-height: 1.6;
	}
`

const Left = styled(Column)`
	display: flex;
	flex-direction: column;
`

const Right = styled(Column)`
	background: #ececec;
`

function Summary({
	messageServiceProcess,
	messageServiceProcess: {
		deliveryMethods,
		recipientMethodsCount,
		postalSettings,
		bulletinsSettings,
	},
}) {
	const hasEmails = deliveryMethods.includes('email')
	const hasLetters = deliveryMethods.includes('letter')
	const hasSMSs = deliveryMethods.includes('sms')
	const hasBulletins = deliveryMethods.includes('bulletins')
	const hasInfoscreen = deliveryMethods.includes('infoscreen')

	const left = (
		<>
			<h1>Yhteenveto</h1>
			<Summaries>
				{hasLetters && (
					<PostalSummary
						letterCount={recipientMethodsCount.letter}
						postalSettings={postalSettings}
					/>
				)}
				{hasEmails && <EmailSummary emailCount={recipientMethodsCount.email} />}
				{hasSMSs && <SMSSummary smsCount={recipientMethodsCount.sms} />}
				{hasBulletins && (
					<BulletinsSummary bulletinsSettings={bulletinsSettings} />
				)}
				{hasInfoscreen && (
					<InfoscreenSummary bulletinsSettings={bulletinsSettings} />
				)}
			</Summaries>
		</>
	)

	const right = (
		<>
			<h1>Lähetykset</h1>
			<MessageServiceMassJobs
				messageServiceMassProcess={messageServiceProcess}
			/>
		</>
	)

	return (
		<TwoColumns>
			<Left>{left}</Left>
			<Right>{right}</Right>
		</TwoColumns>
	)
}

export default Summary
