import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Toolbar, Button, TextField, FontIcon } from 'react-md'
import { FormattedMessage, injectIntl } from 'react-intl'
import styled from 'styled-components'
import emailSpellChecker from '@zootools/email-spell-checker'

import LoginDialogEmailLogIn from './LoginDialogEmailLogIn'
import LoginDialogEmailSignUp from './LoginDialogEmailSignUp'
import { MALFORMED_EMAIL_MESSAGE, GENERIC_ERROR } from './messages'
import { firebaseApp } from 'state/store'
import { fetchSignInMethodsForEmail, getAuth } from 'firebase/auth'
import LoginDialogEmailSignUpBlocked from './LoginDialogEmailSignUpBlocked'
import useDialogs from 'ui/components/dialogs/useDialogs'
import ViiluDialog from 'ui/components/ViiluDialog'

const STEP_PROVIDER_VERIFICATION = 'provider-verification'
const STEP_LOG_IN = 'log-in'
const STEP_SIGN_UP = 'sign-up'
const STEP_PROVIDER_MISMATCH = 'provider-mismatch'

const EMAIL_FIELD_ID = 'email-login-dialog-textfield'

const CenteredText = styled.p`
	text-align: center;
`
const InfoIcon = styled(FontIcon)`
	font-size: ${({ $isDesktop }) =>
		$isDesktop ? '128px !important' : '96px !important'};
	color: var(--color-primary);
	margin-bottom: 16px;
	opacity: 0.87;
	&:after {
		content: 'info';
	}
`
const EmailIcon = styled(FontIcon)`
	font-size: ${({ $isDesktop }) =>
		$isDesktop ? '128px !important' : '96px !important'};
	color: var(--color-primary);
	margin-bottom: 16px;
	opacity: 0.87;
	&:after {
		content: 'alternate_email';
	}
`

const isValidEmail = (email) => {
	if (!email) {
		return false
	}
	if (!email.includes('@')) {
		return false
	}
	if (email.includes(' ')) {
		return false
	}
	return true
}

const LoginDialogEmail = ({
	auth,
	onHide,
	visible,
	intl,
	isDesktop,
	signUpAllowed,
}) => {
	const [email, setEmail] = useState('')
	const [step, setStep] = useState(STEP_PROVIDER_VERIFICATION)
	const [errorText, setErrorText] = useState('')
	const [processing, setProcessing] = useState(false)
	const { confirm, alert } = useDialogs()

	const cleanupAndHide = useCallback(() => {
		window.URLBeforeLogin = null
		setEmail('')
		setStep(STEP_PROVIDER_VERIFICATION)
		setErrorText('')
		onHide()
	}, [setEmail, setStep, setErrorText, onHide])

	useEffect(() => {
		if (auth && auth.uid) {
			cleanupAndHide()
		}
	}, [auth, cleanupAndHide])

	useEffect(() => {
		if (!visible) {
			return false
		}
		let element = window.document.getElementById(EMAIL_FIELD_ID)
		if (element) {
			element.focus()
		} else {
			window.setTimeout(() => {
				element = window.document.getElementById(EMAIL_FIELD_ID)
				if (element) {
					element.focus()
				}
			}, 500)
		}
	}, [visible])

	const validate = () => {
		return isValidEmail(email)
	}

	const next = async () => {
		const invalidEmailMessage = intl.formatMessage({
			defaultMessage: 'Sähköpostin muoto on virheellinen',
			description:
				'Error message telling the user that the typed email address is invalid.',
		})
		setErrorText(validate() ? '' : invalidEmailMessage)
		if (!firebaseApp) {
			return false
		}

		const suggestedEmail = emailSpellChecker.run({
			email,
			domains: [...emailSpellChecker.POPULAR_DOMAINS, 'vii.lu'],
		})

		if (suggestedEmail) {
			const message = intl.formatMessage(
				{
					defaultMessage:
						'Sähköpostiosoite {emailAddress} näyttää siltä, että siinä voisi olla kirjoitusvirhe. Jatka eteenpäin, jos kirjoitusvirhettä ei ole.',
					description:
						'Error message telling the user that there might be a typo in their email address.',
				},
				{
					emailAddress: email,
				},
			)

			const title = intl.formatMessage({
				defaultMessage: 'Huomio!',
				description:
					'Title of the dialog telling the user that there might be a typo in their email address.',
			})

			const confirmLabel = intl.formatMessage({
				defaultMessage: 'Jatka',
				description:
					'Button that allows the user to confirm that their email address does not have a typo.',
			})

			if (
				!(await confirm(message, {
					title,
					confirmLabel,
				}))
			) {
				return false
			}
		}

		setProcessing(true)
		let signInMethods
		try {
			signInMethods = await fetchSignInMethodsForEmail(
				getAuth(firebaseApp),
				email,
			)
		} catch (ex) {
			await alert(MALFORMED_EMAIL_MESSAGE(intl))
			return false
		} finally {
			setProcessing(false)
		}

		if (!signInMethods || !Array.isArray(signInMethods)) {
			await alert(GENERIC_ERROR(intl))
			return false
		}

		// We can log in with an email and password
		if (signInMethods.includes('password')) {
			setStep(STEP_LOG_IN)
			return true
		}

		if (signInMethods.includes('google.com')) {
			setStep(STEP_PROVIDER_MISMATCH)
			const text = intl.formatMessage({
				defaultMessage:
					'Tämä tunnus on linkitetty Google-tiliin. Sulje tämä ikkuna ja valitse kirjautumistavaksi Google-tili.',
				description:
					'Error message shown in the login dialog when user attempts to sign in with an email and password when they should use their Google-account.',
			})
			setErrorText(text)
			return true
		}

		if (signInMethods.includes('facebook.com')) {
			setStep(STEP_PROVIDER_MISMATCH)
			const text = intl.formatMessage({
				defaultMessage:
					'Tämä tunnus on linkitetty Facebook-tiliin. Sulje tämä ikkuna ja valitse kirjautumistavaksi Facebook-tili.',
				description:
					'Error message shown in the login dialog when user attempts to sign in with an email and password when they should use their Facebook-account.',
			})
			setErrorText(text)
			return true
		}

		setStep(STEP_SIGN_UP)
		setErrorText('')
		return true
	}

	const setSanitizedEmail = (email) => {
		if (typeof email === 'string') {
			setEmail(email.trim())
		}
	}

	const onKeyPress = (event) => {
		if (processing) {
			return false
		}
		if (event.charCode === 13) {
			// enter key pressed
			event.preventDefault()
			if (validate()) {
				next()
			}
		}
	}

	const renderToolbar = (title) => {
		return (
			<Toolbar
				title={title}
				titleStyle={{
					display: 'flex',
					whiteSpace: 'normal',
					lineHeight: '100%',
					marginLeft: -2,
				}}
				style={{
					display: 'flex',
					alignItems: 'center',
					position: 'relative',
					width: '100%',
					zIndex: 50,
				}}
				nav={
					<Button icon onClick={cleanupAndHide}>
						close
					</Button>
				}
			/>
		)
	}

	const getEmailAddressMessage = () => {
		return intl.formatMessage({
			defaultMessage: 'Sähköpostiosoite',
			description: 'Label of the email address field',
		})
	}

	const getDialogTitleMessage = () => {
		if (signUpAllowed) {
			return intl.formatMessage({
				defaultMessage: 'Kirjaudu tai luo tunnus',
				description: 'Login / SIgnup dialog title',
			})
		}

		return intl.formatMessage({
			defaultMessage: 'Kirjaudu sisään',
			description: 'Login / SIgnup dialog title',
		})
	}

	const renderProviderVerificationStep = () => {
		return (
			<div className="flex-column flex-center">
				<EmailIcon $isDesktop={isDesktop} />
				<TextField
					id={EMAIL_FIELD_ID}
					label={getEmailAddressMessage()}
					lineDirection="center"
					fullWidth
					value={email}
					onChange={setSanitizedEmail}
					autoComplete="off"
					autoCapitalize="off"
					onKeyPress={onKeyPress}
					errorText={errorText}
					error={Boolean(errorText)}
				/>
				<Button
					raised={validate()}
					flat={!validate()}
					secondary
					onClick={next}
					className="margin-top--xl"
					disabled={processing || !validate()}
					style={
						validate()
							? {
									alignSelf: 'center',
									background: 'var(--color-primary)',
									color: 'white',
							  }
							: { alignSelf: 'center' }
					}
				>
					<FormattedMessage
						defaultMessage="Seuraava"
						description="Button that takes the user to the next step"
					/>
				</Button>
			</div>
		)
	}

	const renderProviderMismatchStep = () => {
		return (
			<div className="flex-column flex-center">
				<InfoIcon $isDesktop={isDesktop} />
				<CenteredText>{errorText}</CenteredText>
			</div>
		)
	}

	let stepElement = null
	let dialogTitle = null
	if (step === STEP_PROVIDER_VERIFICATION) {
		stepElement = renderProviderVerificationStep()
		dialogTitle = getDialogTitleMessage()
	} else if (step === STEP_PROVIDER_MISMATCH) {
		stepElement = renderProviderMismatchStep()
		dialogTitle = getDialogTitleMessage()
	} else if (step === STEP_SIGN_UP) {
		if (signUpAllowed) {
			stepElement = (
				<LoginDialogEmailSignUp email={email} onHide={cleanupAndHide} />
			)
			dialogTitle = intl.formatMessage({
				defaultMessage: 'Luo uusi tunnus',
				description: 'Account sign up dialog title',
			})
		} else {
			stepElement = (
				<LoginDialogEmailSignUpBlocked
					onBack={() => setStep(STEP_PROVIDER_VERIFICATION)}
				/>
			)
			dialogTitle = intl.formatMessage({
				defaultMessage: 'Hups!',
				description: 'Account sign up dialog title',
			})
		}
	} else if (step === STEP_LOG_IN) {
		stepElement = (
			<LoginDialogEmailLogIn email={email} onHide={cleanupAndHide} />
		)
		dialogTitle = intl.formatMessage({
			defaultMessage: 'Kirjaudu sisään',
			description: 'Account log in dialog title',
		})
	}

	return (
		<ViiluDialog
			id="responsive-dialog"
			aria-label={getEmailAddressMessage()}
			visible={visible}
			onHide={cleanupAndHide}
			focusOnMount={false}
			containFocus={true}
			autosizeContent={false}
			modal
			portal
			fullPage={!isDesktop}
			paddedContent={false}
			onClick={(e) => {
				e.preventDefault()
				e.stopPropagation()
			}}
			style={{ zIndex: 101 }}
			dialogStyle={{
				borderRadius: isDesktop ? 8 : 0,
			}}
		>
			{renderToolbar(dialogTitle)}
			<section style={{ padding: 24 }}>{stepElement}</section>
		</ViiluDialog>
	)
}

LoginDialogEmail.propTypes = {
	auth: PropTypes.object,
	visible: PropTypes.bool.isRequired,
	onHide: PropTypes.func.isRequired,
}

const mapState = ({
	firebaseReducer: { auth },
	dimensions: { isDesktop },
}) => ({
	auth,
	isDesktop,
})

const mapDispatchToProps = (dispatch) => ({})

export default compose(
	injectIntl,
	connect(mapState, mapDispatchToProps),
)(LoginDialogEmail)
