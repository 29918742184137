import type { IntlShape } from 'react-intl'
import { ContractHeader } from './types'

export const contractHeaders: ContractHeader[] = [
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Kohde',
				description: 'Company',
			}),
		key: 'companyUUID',
		type: 'companyOptions',
		show: true,
		sortable: false,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Huoneisto',
				description: 'Apartment',
			}),
		key: 'apartmentUUID',
		type: 'apartmentOptions',
		show: true,
		sortable: true,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Viitenumero',
				description: 'Reference number',
			}),
		key: 'referenceNumber',
		show: true,
		sortable: true,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Maksaja',
				description: 'Party',
			}),
		key: 'partyUUID',
		type: 'partyUUID',
		show: true,
		sortable: true,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Toinen maksaja',
				description: 'Secondary party',
			}),
		key: 'secondaryPartyUUID',
		type: 'secondaryPartyUUID',
		show: true,
		sortable: true,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Alkupäivä',
				description: 'Start date',
			}),
		key: 'startDate',
		show: true,
		type: 'date',
		sortable: true,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Loppupäivä',
				description: 'End date',
			}),
		key: 'endDate',
		show: true,
		type: 'date',
		sortable: true,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Laskutustapa',
				description: 'Invoicing method',
			}),
		key: 'invoicingMethod',
		type: 'invoicingMethod',
		show: true,
		sortable: true,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Verkkolaskuosoite',
				description: 'Invoicing address',
			}),
		key: 'invoicingAddress',
		type: 'invoicingAddress',
		show: true,
		sortable: true,
	},
]

export const createContractHeaders = [
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Käytä sopimuskohtaista laskutustietoa',
				description: 'Use contract based invoicing information',
			}),
		key: 'customInvoicing',
		show: true,
		type: 'boolean',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Laskutustapa',
				description: 'Invoicing method',
			}),
		key: 'invoicingMethod',
		show: true,
		type: 'invoicingMethod',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Verkkolaskuosoite',
				description: 'Invoicing address',
			}),
		key: 'invoicingAddress',
		show: true,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Viitenumero',
				description: 'Reference number',
			}),
		boldTitle: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: '(muodostuu luodessa)',
				description: 'Reference number bold title: is formed in creation',
			}),
		key: 'referenceNumber',
		type: 'text',
		show: true,
		required: false,
		editable: false,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Alkupäivä',
				description: 'Start date',
			}),
		key: 'startDate',
		type: 'date',
		show: true,
		required: false,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Loppupäivä',
				description: 'End date',
			}),
		key: 'endDate',
		type: 'date',
		show: true,
		required: false,
	},
]

export const ContractPaymentProductHeaders = [
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Määrä',
				description: 'Quantity',
			}),
		key: 'quantity',
		type: 'number',
		show: true,
		required: false,
		editable: true,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Veloitusperuste',
				description: 'Billing basis',
			}),
		key: 'billingBasis',
		type: 'text',
		show: true,
		required: false,
		editable: false,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'á-hinta',
				description: 'price',
			}),
		key: 'price',
		type: 'text',
		show: true,
		required: false,
		editable: false,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Summa',
				description: 'Amount',
			}),
		key: 'billingAmount',
		type: 'text',
		show: true,
		required: false,
		editable: false,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'á-hinta + alv',
				description: 'price + vat',
			}),
		key: 'priceWithVat',
		type: 'text',
		show: true,
		required: false,
		editable: false,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Summa + alv',
				description: 'Amount + vat',
			}),
		key: 'billingAmountWithVat',
		type: 'text',
		show: true,
		required: false,
		editable: false,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Kuvaus',
				description: 'Description',
			}),
		key: 'description',
		type: 'paymentProduct',
		show: true,
		required: false,
		editable: false,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Alkupäivä',
				description: 'Start date',
			}),
		key: 'startDate',
		show: true,
		type: 'date',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Loppupäivä',
				description: 'End date',
			}),
		key: 'endDate',
		show: true,
		type: 'date',
	},
]
