import { compose } from 'redux'
import styled from 'styled-components'
import WithSelectedCompanyManager from 'ui/components/WithSelectedCompanyManager'
import postmarkTemplate from './postmarkTemplate'

const DEFAULT_SENDER_ADDRESS = 'ilmoitus@vii.lu'
const DEFAULT_SENDER_NAME = 'Viilu Ilmoitus'

const IFrame = styled.iframe`
	flex: 1;
	border: none;
`
const Container = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
`
const InfoContainer = styled.div`
	display: flex;
	flex-direction: row;
	background: lightgray;
`
const Sender = styled.p`
	margin: 0;
	padding: 4px;
	padding-bottom: 2px;
	font-weight: 200;
	color: var(--color-text-subtle);
`
const Subject = styled.strong`
	margin: 0;
	padding: 4px;
	padding-top: 2px;
`
const Avatar = styled.strong`
	margin: 8px;
	padding: 5px;
	padding-left: 14px;
	padding-right: 14px;
	background: var(--color-primary-dark);
	color: white;
	width: 48px;
	height: 48px;
	font-size: 28px;
	text-align: center;
`

const getFromEmailAddress = (manager) => {
	if (
		manager &&
		manager.whitelabelManager &&
		manager.whitelabelEmailFromAddress
	) {
		return manager.whitelabelEmailFromAddress
	}
	return DEFAULT_SENDER_ADDRESS
}

const getFromEmailName = (manager) => {
	if (manager && manager.whitelabelManager && manager.whitelabelEmailFromName) {
		return manager.whitelabelEmailFromName
	}
	return DEFAULT_SENDER_NAME
}

function DefaultTemplate({ title, body, viewUrl, selectedCompanyManager }) {
	const fromEmail = getFromEmailAddress(selectedCompanyManager)
	const fromName = getFromEmailName(selectedCompanyManager)
	const template = postmarkTemplate({
		title,
		body_html: body,
		view_url: viewUrl,
	})

	let sender = `${fromName} <${fromEmail}>`
	let avatar = fromName[0].toUpperCase()

	return (
		<Container>
			<InfoContainer>
				<Avatar>{avatar}</Avatar>
				<div>
					<Sender>{sender}</Sender>
					<Subject>{title}</Subject>
				</div>
			</InfoContainer>
			<IFrame srcDoc={template} />
		</Container>
	)
}

export default compose(WithSelectedCompanyManager)(DefaultTemplate)
