import { useCallback, useEffect, useState } from 'react'
import type { LoanShareholder } from './types'
import { _getShareholdersByShareGroupUUID } from 'state/finance-loan.actions'

function useShareholdersByShareGroupUUID(shareGroupUUID: string) {
	const [shareholders, setShareholders] = useState<LoanShareholder[] | null>(
		null,
	)

	const [loadingShareholders, setLoadingShareholders] = useState<boolean>(false)
	const [errorShareholders, setErrorShareholders] = useState<string | null>(
		null,
	)

	const doQuery = useCallback(async () => {
		setLoadingShareholders(true)
		setErrorShareholders(null)
		let data: LoanShareholder[] | null = null

		try {
			let response: Response | undefined = undefined
			response = await _getShareholdersByShareGroupUUID(shareGroupUUID)

			if (response && response.ok) {
				const res = await response.json()
				if (res.shareholders && Array.isArray(res.shareholders))
					data = res.shareholders
			} else {
				setErrorShareholders('Failed to fetch shareholders')
			}
		} catch (err) {
			console.error('Error fetching shareholders:', err)
			setErrorShareholders('An error occurred while fetching shareholders')
		}

		setShareholders(data)
		setLoadingShareholders(false)
	}, [shareGroupUUID])

	useEffect(() => {
		doQuery()
	}, [doQuery])

	return {
		shareholders,
		loadingShareholders,
		errorShareholders,
		refreshShareholders: doQuery,
	}
}

export default useShareholdersByShareGroupUUID
