import { FormattedMessage, useIntl } from 'react-intl'
import DataTable, {
	TableBody,
	TableColumn,
	TableHeader,
	TableRow,
} from 'react-md/lib/DataTables'
import OverflowGradient from 'ui/components/OverflowGradient'
import { TableContainer } from 'ui/StyledComponents/Tables'
import type { LoanShareholder } from '../types'
import { TableRowContainer, TableRowsNotFound } from '../components'
import Button from 'react-md/lib/Buttons'
import styled from 'styled-components'
import { formatCurrency } from 'util/formatStrings'
import { billingBasisMessage } from 'ui/ManagerFinance/utils/messages'

const ActionButtonContainer = styled.div`
	display: flex;
	flex-flow: row nowrap;
	justify-content: right;
	gap: 1rem;
`

const LoanShareholderTableContainer = styled.div`
	display: flex;
	flex-flow: column nowrap;
	gap: 1rem;
`

type LoanShareGroupShareholderTableProps = {
	shareholders: LoanShareholder[]
}

function LoanShareGroupShareholderTable(
	props: LoanShareGroupShareholderTableProps,
) {
	const { shareholders } = props
	const intl = useIntl()

	const renderTableHeaders = () => {
		return (
			<TableRow>
				<TableColumn key="loanName">
					<p className="text-strong text-subtle">
						<FormattedMessage
							defaultMessage="Laina"
							description="Table header for: loan name"
						/>
					</p>
				</TableColumn>
				<TableColumn key="units">
					<p className="text-strong text-subtle">
						<FormattedMessage
							defaultMessage="Yksiköt"
							description="Table header for: units"
						/>
					</p>
				</TableColumn>
				<TableColumn key="billingBasis">
					<p className="text-strong text-subtle">
						<FormattedMessage
							defaultMessage="Jakoperuste"
							description="Table header for: billing basis"
						/>
					</p>
				</TableColumn>
				<TableColumn key="loanShareAmount">
					<p className="text-strong text-subtle">
						<FormattedMessage
							defaultMessage="Lainaosuus"
							description="Table header for: loan share amount"
						/>
					</p>
				</TableColumn>
				<TableColumn key="debtShareAmount">
					<p className="text-strong text-subtle">
						<FormattedMessage
							defaultMessage="Velkaosuus"
							description="Table header for: debt share amount"
						/>
					</p>
				</TableColumn>
			</TableRow>
		)
	}

	const renderTableRows = () => {
		const rows = shareholders
			.map((shareholder, index) => {
				return (
					<TableRowContainer
						key={shareholder.uuid}
						$filledBackground={index % 2 === 0}
					>
						<TableColumn>
							{shareholder.loanName || shareholder.loanUUID}
						</TableColumn>
						<TableColumn>{shareholder.units}</TableColumn>
						<TableColumn>
							{billingBasisMessage(intl, shareholder.billingBasis)}
						</TableColumn>
						<TableColumn>
							{formatCurrency(shareholder.loanShareAmount)}
						</TableColumn>
						<TableColumn>
							{formatCurrency(shareholder.debtShareAmount)}
						</TableColumn>
					</TableRowContainer>
				)
			})
			.concat(
				<tr
					key="empty-row"
					className="full-width"
					style={{ height: 100, background: 'white' }}
				></tr>,
			)
		return rows
	}

	return (
		<LoanShareholderTableContainer>
			{shareholders.length > 0 ? (
				<>
					<ActionButtonContainer>
						<Button
							raised
							secondary
							onClick={() =>
								console.log('TODO: Placeholder click for forming notifications')
							}
						>
							<FormattedMessage
								defaultMessage="Muodosta ilmoitukset"
								description="Button label for forming notifications"
							/>
						</Button>
						<Button
							raised
							secondary
							onClick={() =>
								console.log('TODO: Placeholder click for forming bills')
							}
						>
							<FormattedMessage
								defaultMessage="Muodosta laskut"
								description="Button label for forming bills"
							/>
						</Button>
					</ActionButtonContainer>
					<TableContainer>
						<DataTable
							selectableRows
							className="md-paper--1"
							style={{
								background: 'white',
								maxHeight: 'calc(100vh - 205px)',
							}}
						>
							<TableHeader
								style={{
									position: 'sticky',
									top: 0,
									zIndex: 1,
									background: 'white',
								}}
							>
								{renderTableHeaders()}
							</TableHeader>
							<TableBody>{renderTableRows()}</TableBody>
							<OverflowGradient style={{ bottom: 40 }} />
						</DataTable>
					</TableContainer>
				</>
			) : (
				<TableRowsNotFound>
					{intl.formatMessage({
						defaultMessage: 'Osapuolia ei löytynyt',
						description: 'Error text when no parties were found',
					})}
					<Button
						raised
						secondary
						onClick={() =>
							console.log('TODO: Placeholder click for adding parties')
						}
					>
						<FormattedMessage
							defaultMessage="Lisää osapuoli"
							description="Button label for add party"
						/>
					</Button>
				</TableRowsNotFound>
			)}
		</LoanShareholderTableContainer>
	)
}

export default LoanShareGroupShareholderTable
