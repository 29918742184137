import { useCallback, useEffect, useState } from 'react'
import type { ValueAddedTax } from './types'
import { _getValueAddedTaxes } from 'state/value-added-tax-actions'

function useValueAddedTaxes() {
	const [valueAddedTaxes, setVats] = useState<ValueAddedTax[]>()

	const [loading, setLoading] = useState<boolean>(false)
	const [error, setError] = useState<string | null>(null)

	const doQuery = useCallback(async () => {
		setLoading(true)
		setError(null)
		let data: ValueAddedTax[] = []

		try {
			const response = await _getValueAddedTaxes()

			if (response && response.ok) {
				const res = await response.json()
				data = res.vats
			} else {
				throw new Error('Response is not ok.')
			}
		} catch (err) {
			console.error('Error fetching vat:', err)
			setError('An error occurred while fetching vat')
		}
		setVats(data)
		setLoading(false)
	}, [])

	useEffect(() => {
		doQuery()
	}, [doQuery])

	return {
		valueAddedTaxes,
		loading,
		error,
		refresh: doQuery,
	}
}

export default useValueAddedTaxes
