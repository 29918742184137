import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import { useIntl } from 'react-intl'
import Button from 'react-md/lib/Buttons'
import { loanShareGroupDetailRow } from './loanShareGroupDetailRows'
import LoadingState from 'ui/components/LoadingState'
import { useMemo, useState } from 'react'
import type { LoanShareGroupDetails } from '../types'
import { Tab, Tabs } from 'react-md/lib/Tabs'
import {
	AsideContentContainer,
	ContentContainer,
	LoanDetailContainer,
	LoanDetailRow,
	MainContentContainer,
	TabContentContainer,
	TabsContainer,
} from '../components'
import { useCompany } from 'ui/components/useCompany'
import { useApartment } from '../useApartment'
import useShareholdersByShareGroupUUID from '../useShareholders'
import LoanShareGroupPartiesTable from './LoanShareGroupPartiesTable'
import LoanShareGroupShareholdersView from './LoanShareGroupShareholdersView'
import useParties from 'ui/ManagerFinance/Parties/useParties'
import ViiluFullPageDialog from 'ui/components/ViiluFullPageDialog'

type LoanShareGroupViewDialogProps = {
	shareGroupUUID: string
}

function LoanShareGroupViewDialog(props: LoanShareGroupViewDialogProps) {
	const { shareGroupUUID } = props
	const [tabIndex, setTabIndex] = useState<0 | 1>(0)
	const dispatch = useDispatch()
	const { loadingShareholders, shareholders } =
		useShareholdersByShareGroupUUID(shareGroupUUID)
	const { loading: loadingApartment, apartment } = useApartment(shareGroupUUID)
	const { loading: loadingCompany, company } = useCompany(
		apartment?.companyUUID || '',
	)

	const filters = useMemo(
		() => ({ apartmentUUID: apartment?.uuid }),
		[apartment?.uuid],
	)
	const { loadingParties, parties } = useParties(company?.uuid || '', filters)
	const intl = useIntl()

	const loadingData =
		loadingShareholders || loadingApartment || loadingCompany || loadingParties

	const loanSharegroup: LoanShareGroupDetails | undefined = useMemo(() => {
		if (apartment && shareholders && company) {
			const sharegroup: LoanShareGroupDetails = {
				...apartment,
				companyName: company.name,
				shareholders,
			}
			return sharegroup
		}
		return undefined
	}, [apartment, shareholders, company])

	const onHide = () => {
		const searchParams = new URLSearchParams(window.location.search)
		searchParams.delete('sguuid')
		const newUrl = `${window.location.pathname}?${searchParams.toString()}`
		dispatch(push(newUrl))
	}

	const getTabs = () => {
		return [
			<Tab
				key={1}
				style={
					tabIndex === 0
						? {
								color: 'var(--color-primary-dark)',
								fontWeight: '700',
						  }
						: {
								color: 'var(--color-text-subtle)',
						  }
				}
				label={intl.formatMessage({
					defaultMessage: 'Osapuolet',
					description: 'Title of participants tab',
				})}
				onClick={() => setTabIndex(0)}
			/>,
			<Tab
				key={2}
				style={
					tabIndex === 1
						? {
								color: 'var(--color-primary-dark)',
								fontWeight: '700',
						  }
						: {
								color: 'var(--color-text-subtle)',
						  }
				}
				label={intl.formatMessage({
					defaultMessage: 'Lainaosuudet',
					description: 'Title of loan shares',
				})}
				onClick={() => setTabIndex(1)}
			/>,
		]
	}

	const renderAsideElement = () => {
		return (
			<AsideContentContainer>
				<LoanDetailContainer>
					{loanSharegroup &&
						loanShareGroupDetailRow.map((row, index) => {
							const value = loanSharegroup[row.key]
							return (
								<LoanDetailRow
									key={row.key}
									$filledBackground={index % 2 === 0}
								>
									<div>{row.title(intl)}</div>
									<div style={{ textAlign: 'right' }}>
										{typeof value !== 'object' ? value : null}
									</div>
								</LoanDetailRow>
							)
						})}
				</LoanDetailContainer>
			</AsideContentContainer>
		)
	}

	const renderMainElement = () => {
		return (
			<MainContentContainer>
				<TabsContainer>
					<Tabs
						tabId="loans-mobile-tab"
						mobile
						style={{
							flexShrink: 0,
							borderBottomStyle: 'solid',
							borderBottomColor: 'rgba(0,0,0,0.2)',
							borderBottomWidth: 1,
							marginLeft: '1rem',
							borderBottom: 'none',
						}}
						alignToKeyline={false}
						defaultTabIndex={tabIndex}
						indicatorHeight={3}
					>
						{getTabs()}
					</Tabs>
				</TabsContainer>
				{tabIndex === 0 && (
					<TabContentContainer>
						{parties && <LoanShareGroupPartiesTable parties={parties} />}
					</TabContentContainer>
				)}
				{tabIndex === 1 && (
					<TabContentContainer>
						{shareholders && (
							<LoanShareGroupShareholdersView shareholders={shareholders} />
						)}
					</TabContentContainer>
				)}
			</MainContentContainer>
		)
	}
	return (
		<ViiluFullPageDialog
			id="loan-view-dialog"
			title={intl.formatMessage({
				defaultMessage: 'Osakeryhmä',
				description:
					'Placeholder title for loan sharegroup dialog when the loan name is not found',
			})}
			visible={true}
			onHide={onHide}
			focusOnMount={false}
			containFocus={true}
			zIndex={25}
			toolbarStyle={{ background: 'var(--color-secondary-dark)' }}
			nav={
				<Button
					icon
					onClick={() => {
						onHide()
					}}
				>
					close
				</Button>
			}
		>
			<ContentContainer>
				{loadingData ? (
					<LoadingState />
				) : (
					<>
						{renderAsideElement()}
						{renderMainElement()}
					</>
				)}
			</ContentContainer>
		</ViiluFullPageDialog>
	)
}

export default LoanShareGroupViewDialog
