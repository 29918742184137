import { callApi } from './api/finance-api'
import { generateQueryParams } from 'util/queryUtils'
import {
	ContractFilters,
	Contract,
	EditContractPaymentProductLink,
	CreateContractPaymentProductLink,
} from 'ui/ManagerFinance/Contracts/types'
import type { PayableTypesFilters } from 'ui/ManagerFinance/Contracts/usePayableTypes'

export const _getContracts = async (filters: ContractFilters) => {
	const queryParams = generateQueryParams(filters)
	const url = `/v1/contracts${queryParams}`

	return callApi(url, {
		method: 'GET',
	})
}

export const _getPayableTypes = async (filters: PayableTypesFilters) => {
	const queryParams = generateQueryParams(filters)
	const url = `/v1/contracts/payableTypes${queryParams}`

	return callApi(url, {
		method: 'GET',
	})
}

export const _getContract = async (uuid: string) => {
	const url = `/v1/contract/${uuid}`

	return callApi(url, {
		method: 'GET',
	})
}
export const _deleteContract = async (uuid: string) => {
	const url = `/v1/contract/${uuid}`

	return callApi(url, {
		method: 'DELETE',
	})
}

export const _createContractWithLinks = async (
	contract: Contract,
	links: CreateContractPaymentProductLink[],
) => {
	return callApi('/v1/contract-with-links', {
		method: 'POST',
		body: {
			contract: contract,
			links: links,
		},
	})
}

export const _updateContractWithLinks = async (
	contract: Contract,
	links: EditContractPaymentProductLink[],
) => {
	return callApi('/v1/contract-with-links', {
		method: 'PUT',
		body: {
			contract: contract,
			links: links,
		},
	})
}
