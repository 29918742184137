const initialState = {
	selectedParent: {
		uuid: null,
		parentPath: null,
		name: '',
		path: null,
	},
}

function reducer(state = initialState, action) {
	if (action.type === 'UI_DOCUMENTS_V2_PARENT_VALUE') {
		return { ...state, selectedParent: action.parent }
	}
	return state
}

export default reducer
