import { useIntl } from 'react-intl'
import styled from 'styled-components'
import DatePickerISO from 'ui/components/DatePickerISO'
import type { AccountsLedgerFilters } from './types'
import DropdownField from 'ui/components/DropdownField'
import type { SelectOptions } from '../types'
import { YES_NO_MESSAGE } from 'ui/messages'

const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 16px;
	margin-bottom: 16px;
`

const Filter = styled.div`
	min-width: 160px;
`

type FilterField = {
	label: string
	value: string | boolean | number | null
	_onOptionSelect: (value: any) => void
	type: string
	options?: SelectOptions[] | any[]
	hasSelectAll?: boolean
	nullSelectAll?: boolean
	itemLabel?: string
	itemValue?: string
}

type Props = {
	filters: AccountsLedgerFilters
	setFilters: React.Dispatch<React.SetStateAction<AccountsLedgerFilters>>
	groupByOptions: SelectOptions[]
	viewOptions: SelectOptions[]
}

function Filters({ filters, setFilters, groupByOptions, viewOptions }: Props) {
	const intl = useIntl()

	const yesNoOptions = [
		{ label: YES_NO_MESSAGE(intl, true), value: true },
		{ label: YES_NO_MESSAGE(intl, false), value: false },
	]

	const filterFields: FilterField[] = [
		{
			label: intl.formatMessage({
				defaultMessage: 'Näkymä',
				description: 'Label for the accounts ledger filter: View',
			}),
			value: filters.view,
			options: viewOptions,
			_onOptionSelect: (value) =>
				setFilters((prevData) => ({
					...prevData,
					view: value,
				})),
			hasSelectAll: false,
			nullSelectAll: false,
			itemLabel: 'label',
			itemValue: 'value',
			type: 'dropdown',
		},
		{
			label: intl.formatMessage({
				defaultMessage: 'Ryhmittely',
				description: 'Label for the accounts ledger filter: Ryhmittely',
			}),
			value: filters.groupBy,
			options: groupByOptions,
			_onOptionSelect: (value) =>
				setFilters((prevData) => ({
					...prevData,
					groupBy: value,
				})),
			hasSelectAll: false,
			nullSelectAll: false,
			itemLabel: 'label',
			itemValue: 'value',
			type: 'dropdown',
		},
		{
			label: intl.formatMessage({
				defaultMessage: 'Vastikerästi',
				description: 'Label for the accounts ledger filter: Unpaid fees',
			}),
			value: filters.unPaidFees,
			options: yesNoOptions,
			_onOptionSelect: (value) =>
				setFilters((prevData) => ({
					...prevData,
					unPaidFees: value,
				})),
			hasSelectAll: true,
			nullSelectAll: true,
			itemLabel: 'label',
			itemValue: 'value',
			type: 'dropdown',
		},
		{
			label: intl.formatMessage({
				defaultMessage: 'Alkupäivämäärä',
				description: 'Label for the accounts ledger filter: Start date',
			}),
			_onOptionSelect: (value) => {
				setFilters((prev) => ({ ...prev, startDate: value }))
			},
			value: filters.startDate || '',
			type: 'date',
		},
		{
			label: intl.formatMessage({
				defaultMessage: 'Loppupäivämäärä',
				description: 'Label for the accounts ledger filter: End date',
			}),
			_onOptionSelect: (value) => {
				setFilters((prev) => ({ ...prev, endDate: value }))
			},
			value: filters.endDate || '',
			type: 'date',
		},
	]

	const renderFilterSelectField = (filterProps: FilterField) => {
		if (filterProps.type === 'date') {
			return (
				<Filter key={filterProps.label}>
					<DatePickerISO
						key={filterProps.label}
						id={filterProps.label}
						label={filterProps.label}
						value={filterProps.value as string}
						onChange={filterProps._onOptionSelect}
					/>
				</Filter>
			)
		}
		if (filterProps.type === 'dropdown' && Array.isArray(filterProps.options)) {
			return (
				<Filter key={filterProps.label}>
					<DropdownField
						options={filterProps.options as any[]}
						itemLabel={filterProps.itemLabel as string}
						itemValue={filterProps.itemValue as string}
						{...filterProps}
					/>
				</Filter>
			)
		}
	}

	return <Container>{filterFields.map(renderFilterSelectField)}</Container>
}

export default Filters
