const initialState = {
	name: '',
	apartmentCount: '',
	address: '',
	zip: '',
	area: '',
	paymentInterval: 'yearly',
	billingEmail: null,
	stepIndex: 0,
}

function reducer(state = initialState, action) {
	if (action.type === 'UI_COMPANY_REGISTER_STATE_SET') {
		return { ...state, ...action.update }
	} else if (action.type === 'UI_COMPANY_REGISTER_STATE_RESET') {
		return { ...initialState }
	} else if (action.type === 'UI_COMPANY_REGISTER_DECREMENT_STEP_INDEX') {
		return { ...state, stepIndex: state.stepIndex - 1 }
	} else if (action.type === 'UI_COMPANY_REGISTER_INCREMENT_STEP_INDEX') {
		return { ...state, stepIndex: state.stepIndex + 1 }
	}
	return state
}

export default reducer
