import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { bindActionCreators } from 'redux'
import { push } from 'connected-react-router'
import { TableRow, TableColumn, Button, FontIcon } from 'react-md'

import moment from 'moment-timezone'

import * as notifyActions from 'state/notifyActions'
import * as appActions from 'state/appActions'
import * as signatureRequestActions from 'state/signature-request-actions'

import { FormattedMessage, injectIntl } from 'react-intl'
import SignatureRequestStatusChip from './SignatureRequestStatusChip'
import { GENERIC_ERROR_NOTIFICATION } from 'ui/messages'
import WithDialogs from 'ui/components/dialogs/WithDialogs'

class SignatureRequestRowManager extends Component {
	state = {
		reminderSent: false,
		reminderSending: false,
	}

	onOpen = () => {
		const {
			signatureRequest: {
				companyUrlName,
				companyUUID,
				genericDocument,
				correlationUUID,
				uuid,
			},
			_push,
			_setSelectedCompany,
		} = this.props
		_setSelectedCompany({ uuid: companyUUID, urlName: companyUrlName })

		if (genericDocument) {
			window.setTimeout(
				() => _push('/internal/company_signature_request?uuid=' + uuid),
				100,
			)
		} else {
			window.setTimeout(
				() =>
					_push(
						'/' +
							companyUrlName +
							'/meeting?uuid=' +
							correlationUUID +
							'&stepId=MINUTES_SIGN',
					),
				100,
			)
		}
	}

	onReminder = async () => {
		const {
			signatureRequest: { anyOrderAllowed, remainingSignees, nextSignee, uuid },
			_resend,
			_showInfoNotification,
			intl,
			confirm,
		} = this.props

		const message = intl.formatMessage({
			defaultMessage:
				'Lähetetään muistutus allekirjoituksesta seuraavalle allekirjoittajalle?',
			description:
				'Confirmation asking the user if they want to send a reminder message to the next signee.',
		})
		if (!(await confirm(message))) {
			return false
		}
		this.setState({ reminderSending: true })
		if (anyOrderAllowed) {
			if (!remainingSignees || remainingSignees.length === 0) {
				_showInfoNotification('❌ ' + GENERIC_ERROR_NOTIFICATION(intl))
				return false
			}
			await Promise.all(
				remainingSignees.map((signee) => _resend(uuid, signee.unid)),
			)
		} else {
			if (!nextSignee) {
				_showInfoNotification('❌ ' + GENERIC_ERROR_NOTIFICATION(intl))
				return false
			}
			const result = await _resend(uuid, nextSignee.unid)
			if (!result || result.success === false) {
				_showInfoNotification('❌ ' + GENERIC_ERROR_NOTIFICATION(intl))
				return false
			}
		}

		this.setState({ reminderSending: false, reminderSent: true })
	}

	onDownload = () => {
		const {
			signatureRequest: { versions },
		} = this.props
		const latestVersion = versions[versions.length - 1]
		window.open(latestVersion.fileURL, '_blank')
	}

	render() {
		const { reminderSent, reminderSending } = this.state
		const {
			signatureRequest: {
				versions,
				status,
				ts,
				companyName,
				signees,
				remainingSignees,
				author,
			},
			intl,
		} = this.props

		const latestVersion = versions[versions.length - 1]

		const dateElement = ts ? (
			moment(new Date(ts)).format('DD.MM.YYYY')
		) : (
			<p className="text-subtle">-</p>
		)

		const sendMessage = intl.formatMessage({
			defaultMessage: 'Lähetä muistutus',
			description:
				'Button that allows the user to send a reminder message to the next signee.',
		})
		const sendingMessage = intl.formatMessage({
			defaultMessage: 'Lähetetään...',
			description:
				'Paragraph telling the user that the signature reminder message is being sent.',
		})
		const sentMessage = intl.formatMessage({
			defaultMessage: 'Muistutus lähetetty',
			description:
				'Paragraph telling the user that the signature reminder message has been sent.',
		})

		return (
			<TableRow>
				<TableColumn onClick={this.onOpen} style={{ paddingRight: 8 }}>
					<SignatureRequestStatusChip status={status} />
				</TableColumn>
				<TableColumn onClick={this.onOpen} style={{ paddingRight: 24 }}>
					<p
						style={{
							whiteSpace: 'nowrap',
						}}
					>
						{`${signees.length - remainingSignees.length} / ${signees.length}`}
					</p>
				</TableColumn>
				<TableColumn onClick={this.onOpen} style={{ paddingRight: 24 }}>
					{dateElement}
				</TableColumn>
				<TableColumn onClick={this.onOpen} style={{ paddingRight: 24 }}>
					<p style={{ whiteSpace: 'nowrap' }}>{author.name}</p>
				</TableColumn>
				<TableColumn onClick={this.onOpen} style={{ paddingRight: 24 }}>
					<p style={{ whiteSpace: 'nowrap' }}>{companyName}</p>
				</TableColumn>
				<TableColumn onClick={this.onOpen} style={{ paddingRight: 24 }}>
					<p
						style={{
							whiteSpace: 'nowrap',
							maxWidth: 450,
							overflow: 'hidden',
							textOverflow: 'ellipsis',
						}}
					>
						{latestVersion.fileName}
					</p>
				</TableColumn>
				<TableColumn>
					{status !== 'complete' ? (
						<Button
							flat
							secondary
							onClick={this.onReminder}
							style={{ minWidth: 180 }}
							disabled={reminderSent}
						>
							{reminderSending
								? sendingMessage
								: reminderSent
								? sentMessage
								: sendMessage}
						</Button>
					) : (
						<Button
							flat
							onClick={this.onDownload}
							style={{ minWidth: 180 }}
							iconEl={<FontIcon>download</FontIcon>}
						>
							<FormattedMessage
								defaultMessage="Lataa asiakirja"
								description="Button that allows the user to download the signed document."
							/>
						</Button>
					)}
				</TableColumn>
			</TableRow>
		)
	}
}

SignatureRequestRowManager.propTypes = {
	signatureRequest: PropTypes.object.isRequired,
}

const mapState = ({ dimensions: { isDesktop } }) => ({
	isDesktop,
})

const mapDispatchToProps = (dispatch) => ({
	_push: bindActionCreators(push, dispatch),
	_showInfoNotification: bindActionCreators(notifyActions.info, dispatch),
	_setSelectedCompany: bindActionCreators(
		appActions._setSelectedCompany,
		dispatch,
	),
	_resend: bindActionCreators(signatureRequestActions._resend, dispatch),
})

export default compose(
	injectIntl,
	WithDialogs,
	connect(mapState, mapDispatchToProps),
)(SignatureRequestRowManager)
