import { SelectField } from 'react-md'
import styled from 'styled-components'
import Layout from '../../Layout'
import {
	Color,
	Plex,
	colorOptions,
	plexOptions,
	PostalClass,
	postalClassOptions,
	bindingOptions,
	Binding,
} from '../../../options'
import Preview from './Preview'
import { compose } from 'redux'
import { injectIntl } from 'react-intl'
import { useEffect } from 'react'
import useDialogs from 'ui/components/dialogs/useDialogs'
import WithSelectedCompanyManager from 'ui/components/WithSelectedCompanyManager'

const SmallText = styled.p`
	font-size: 0.9rem;
	margin-top: 16px;
`

const DeliveryInfo = styled.p`
	font-size: 1.1rem;
	margin-bottom: 0.8rem;
`

function PrintingAndDistribution({
	stepTitle,
	onPreviousClick,
	onDraftClick,
	onNextClick,
	messageServiceProcess,
	setMessageServiceProcess,
	intl,
	selectedCompanyManager,
}) {
	const { alert } = useDialogs()
	const { binding, plex, color, postalClass } =
		messageServiceProcess.postalSettings || {}

	const blockBooklets =
		selectedCompanyManager &&
		selectedCompanyManager.groupUUID === '1af4c573-5734-4ef5-ad8d-dec48f14d1c4' // Oiva

	useEffect(() => {
		if (!binding || !plex || !color || !postalClass) {
			setMessageServiceProcess((prev) => ({
				...prev,
				postalSettings: {
					...prev.postalSettings,
					binding: binding || Binding.NO_BINDING,
					plex: plex || Plex.DUPLEX,
					color: color || Color.BLACK,
					postalClass: postalClass || PostalClass.ECONOMY,
				},
			}))
		}
	}, [binding, color, plex, postalClass, setMessageServiceProcess])

	// Has to be inited first.
	if (!binding || !plex || !color || !postalClass) {
		return null
	}

	const updatePostalSettings = (field, value) => {
		const update = {
			[field]: value,
		}

		if (field === 'binding' && value === Binding.BOOKLET) {
			if (blockBooklets) {
				alert(
					'Vihkosidonnan käyttö on estetty isännöintitoimiston päätöksestä.',
				)
				return
			}

			update.color = Color.COLOR
			update.plex = Plex.SIMPLEX
		}

		setMessageServiceProcess((prev) => ({
			...prev,
			postalSettings: {
				...prev.postalSettings,
				...update,
			},
		}))
	}

	const left = (
		<>
			<h1 style={{ marginBottom: 16 }}>{stepTitle}</h1>
			<SelectField
				id="plex"
				style={{ marginBottom: 16 }}
				label={intl.formatMessage({
					defaultMessage: 'Vihkosidonta',
					description: 'Label for the postal printing binding selection input.',
				})}
				menuItems={bindingOptions(intl)}
				value={binding}
				onChange={(value) => updatePostalSettings('binding', value)}
			/>
			{binding === Binding.NO_BINDING ? (
				<>
					<SelectField
						id="plex"
						style={{ marginBottom: 16 }}
						label={intl.formatMessage({
							defaultMessage: 'Tulostuksen puoleisuus',
							description:
								'Label for the postal printing plex input in the general sender component.',
						})}
						menuItems={plexOptions(intl)}
						value={plex}
						onChange={(value) => updatePostalSettings('plex', value)}
					/>
					<SelectField
						id="color"
						style={{ marginBottom: 16 }}
						label={intl.formatMessage({
							defaultMessage: 'Värillisyys',
							description:
								'Label for the postal printing color input in the general sender component.',
						})}
						menuItems={colorOptions(intl)}
						value={color}
						onChange={(value) => updatePostalSettings('color', value)}
					/>
				</>
			) : (
				<DeliveryInfo>
					<strong>Huom:</strong> vihkosidottava aineisto tulostetaan aina
					värillisenä.
				</DeliveryInfo>
			)}
			<SelectField
				id="postalClass"
				label={intl.formatMessage({
					defaultMessage: 'Postiluokka',
					description:
						'Label for the postal class input in the general sender component.',
				})}
				menuItems={postalClassOptions(intl)}
				value={postalClass}
				onChange={(value) => updatePostalSettings('postalClass', value)}
			/>
			<SmallText>
				{intl.formatMessage({
					defaultMessage:
						'* Suosittelemme yleisesti postitusluokaksi Economyä, koska postiluokkien jakelunopeus ' +
						'eroaa nykyään enää hyvin vähän. Käytäthän kuitenkin omaa harkintakykyäsi tilanteen ' +
						'tarvitseman postiluokan arvioimiseen.',
					description:
						'Additional info text for the postal class input preference in the general sender component.',
				})}
			</SmallText>

			<h2 style={{ marginTop: 32, marginBottom: 16 }}>
				{intl.formatMessage({
					defaultMessage: 'Toimitusaika-arvio',
					description:
						'Heading for the delivery time in the general sender component postal printing and distribution step.',
				})}
			</h2>

			{color === Color.BLACK && (
				<DeliveryInfo>
					{intl.formatMessage({
						defaultMessage:
							'Klo 23:59 mennessä saapunut mustavalkoinen aineisto tulostetaan, kuoritetaan ja toimitetaan jakeluyhtiölle seuraavana arkipäivänä. Postin leima saadaan siis mustavalkoiselle aineistolle seuraavalle arkipäivälle.',
						description:
							'Delivery time description for the black and white printing option in the general sender component postal printing and distribution step.',
					})}
				</DeliveryInfo>
			)}
			{color === Color.COLOR && (
				<DeliveryInfo>
					{intl.formatMessage({
						defaultMessage:
							'Aamu klo 9:00 mennessä saapunut värillinen (premium) aineisto tulostetaan, kuoritetaan ja toimitetaan jakeluyhtiölle saman arkipäivän puolella. Postin leima saadaan siis samalle päivälle, jos aineisto on jätetty tarpeeksi aikaisin.',
						description:
							'Delivery time description for the colored printing option in the general sender component postal printing and distribution step.',
					})}
				</DeliveryInfo>
			)}
			{postalClass === PostalClass.ECONOMY && (
				<DeliveryInfo>
					{intl.formatMessage({
						defaultMessage:
							'Economy-kirjeet ovat pääsääntöisesti jaeltuna neljäntenä arkipäivänä jakeluyhtiölle toimituksesta.',
						description:
							'Delivery time description for the Economy postal class option in the general sender component postal printing and distribution step.',
					})}
				</DeliveryInfo>
			)}
			{postalClass === PostalClass.PRIORITY && (
				<DeliveryInfo>
					{intl.formatMessage({
						defaultMessage:
							'Priority-kirjeet ovat pääsääntöisesti jaeltuna toisena arkipäivänä jakeluyhtiölle toimituksesta. Käytännössä kuitenkin ' +
							'Priority toimii tällä aikataululla vain suurissa asutuskeskuksissa. Muualla Priority on ' +
							'yleensä perillä samoihin aikoihin Economyn kanssa, eli pääsääntöisesti jaettuna neljäntenä arkipäivänä postituksesta.',
						description:
							'Delivery time description for the Priority postal class option in the general sender component postal printing and distribution step.',
					})}
				</DeliveryInfo>
			)}
		</>
	)

	const right = <Preview />

	return (
		<Layout
			left={left}
			right={right}
			onPreviousClick={onPreviousClick}
			onDraftClick={onDraftClick}
			onNextClick={onNextClick}
		/>
	)
}

export default compose(
	WithSelectedCompanyManager,
	injectIntl,
)(PrintingAndDistribution)
