import { TableRow, TableColumn, MenuButton, CircularProgress } from 'react-md'
import { partyHeaders } from './constants'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import type { Party } from './types'
import type { ApartmentSelectOptions } from '../types'
import { useIntl } from 'react-intl'
import { getPartyRowValue } from './utils'

interface Props {
	party: Party
	menuItems: any[] | null
	children: any
	processing: any
	apartmentOptions: ApartmentSelectOptions[]
}

const PartyRow = ({
	party,
	menuItems,
	children,
	processing,
	apartmentOptions,
}: Props) => {
	const intl = useIntl()
	const dispatch = useDispatch()
	const appendedMenuItems = menuItems ? [...menuItems] : null

	const menu = !appendedMenuItems ? null : (
		<MenuButton
			id={'menu-button'}
			icon
			menuItems={appendedMenuItems}
			centered
			anchor={{
				x: MenuButton.HorizontalAnchors['CENTER'],
				y: MenuButton.VerticalAnchors['CENTER'],
			}}
		>
			more_vert
		</MenuButton>
	)

	const handleOnClick = () => {
		dispatch(push(window.location.pathname + '?puuid=' + party.uuid))
	}

	const renderTableColumns = () => {
		return partyHeaders
			.filter((h) => h.show)
			.map((header) => {
				const { key, type } = header
				return (
					<TableColumn onClick={handleOnClick} key={key}>
						<p>{getPartyRowValue(party, key, apartmentOptions, intl, type)}</p>
					</TableColumn>
				)
			})
	}

	return (
		<TableRow key={party.uuid}>
			{renderTableColumns()}
			<TableColumn key={'children'}>
				<div>
					{processing ? (
						<CircularProgress id="progress" style={{ marginTop: 0 }} />
					) : (
						menu
					)}
					{children}
				</div>
			</TableColumn>
		</TableRow>
	)
}

export default PartyRow
