import { IntlShape } from 'react-intl'
import { YES_NO_MESSAGE } from 'ui/messages'
import { formatDate, formatCurrency } from 'util/formatStrings'
import type { ApartmentSelectOptions } from '../types'
import type { Payments, PaymentKey } from './types'

export const getPaymentRowValue = (
	payment: Payments,
	key: PaymentKey,
	apartmentOptions: ApartmentSelectOptions[],
	intl: IntlShape,
	type?: string,
) => {
	const value = payment[key]

	switch (type) {
		case 'apartmentOptions':
			return (
				apartmentOptions.find((a) => a.identifier === value)?.label || value
			)
		case 'date':
			return formatDate(value as string)
		case 'currency':
			return formatCurrency(value as number)
		case 'boolean':
			return YES_NO_MESSAGE(intl, value)
		default:
			return value || ''
	}
}
