import { useCallback, useEffect, useState } from 'react'
import { _getPaymentProducts } from 'state/finance-payment-product-actions'
import type { PaymentProductsFilters, PaymentProduct } from './types'

type State = {
	loading: boolean
	data: PaymentProduct[]
}

function usePaymentProducts(
	companyUUID: string,
	filters?: PaymentProductsFilters,
) {
	const [state, setState] = useState<State>({ loading: true, data: [] })

	const doQuery = useCallback(async () => {
		if (!companyUUID) {
			setState({ loading: false, data: [] })
			return
		}

		setState((prev) => ({ ...prev, loading: true }))
		let data: PaymentProduct[] = []
		try {
			const response = await _getPaymentProducts({ companyUUID, ...filters })
			if (response?.ok) {
				const result = await response.json()
				if (result.paymentProducts && Array.isArray(result.paymentProducts)) {
					data = result.paymentProducts
				}
			} else {
				throw new Error('Response is not ok.')
			}
		} catch (error) {
			console.error(error)
		}
		setState({
			loading: false,
			data,
		})
	}, [companyUUID, filters])

	useEffect(() => {
		doQuery()
	}, [doQuery])

	return { ...state, refresh: doQuery }
}

export default usePaymentProducts
