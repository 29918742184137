const initialState = {
	meetingParticipationDrawerVisible: false,
	meetingVotingDrawerVisible: false,
	meetingProcessDrawerVisible: false,
	meetingClausesDrawerVisible: false,
	unauthenticatedName: null,
	meetingUnauthenticatedNamePromptVisible: false,
	representedApartmentsInMeeting: {},
	clients: {},
	meetingUnsentMessages: {},
	meetingMessageCount: {},
	meetingMessageReadCount: {},
	meetingBouncerSessionIds: {},
	livekitMeetingBackground: 'NONE',
}

function reducer(state = initialState, action) {
	if (action.type === 'UI_MEETING_PARTICIPATION_SHOW') {
		return { ...state, meetingParticipationDrawerVisible: true }
	} else if (action.type === 'UI_MEETING_PARTICIPATION_HIDE') {
		return { ...state, meetingParticipationDrawerVisible: false }
	} else if (action.type === 'UI_MEETING_VOTING_SHOW') {
		return { ...state, meetingVotingDrawerVisible: true }
	} else if (action.type === 'UI_MEETING_VOTING_HIDE') {
		return { ...state, meetingVotingDrawerVisible: false }
	} else if (action.type === 'UI_MEETING_PROCESS_SHOW') {
		return { ...state, meetingProcessDrawerVisible: true }
	} else if (action.type === 'UI_MEETING_PROCESS_HIDE') {
		return { ...state, meetingProcessDrawerVisible: false }
	} else if (action.type === 'UI_MEETING_CLAUSES_SHOW') {
		return { ...state, meetingClausesDrawerVisible: true }
	} else if (action.type === 'UI_MEETING_CLAUSES_HIDE') {
		return { ...state, meetingClausesDrawerVisible: false }
	} else if (action.type === 'UI_MEETING_UNAUTHENTICATED_NAME_SET') {
		return { ...state, unauthenticatedName: action.name }
	} else if (action.type === 'UI_MEETING_UNAUTHENTICATED_NAME_PROMPT_SHOW') {
		return { ...state, meetingUnauthenticatedNamePromptVisible: true }
	} else if (action.type === 'UI_MEETING_UNAUTHENTICATED_NAME_PROMPT_HIDE') {
		return { ...state, meetingUnauthenticatedNamePromptVisible: false }
	} else if (action.type === 'UI_SET_MEETING_UNSENT_MESSAGE') {
		return {
			...state,
			meetingUnsentMessages: {
				...state.meetingUnsentMessages,
				[action.meetingUUID]: action.message,
			},
		}
	} else if (action.type === 'UI_CLEAR_MEETING_UNSENT_MESSAGE') {
		return {
			...state,
			meetingUnsentMessages: {
				...state.meetingUnsentMessages,
				[action.meetingUUID]: '',
			},
		}
	} else if (action.type === 'UI_MEETING_REPRESENTED_APARTMENTS_SET') {
		return {
			...state,
			representedApartmentsInMeeting: {
				...state.representedApartmentsInMeeting,
				[action.meetingUUID]: action.apartments,
			},
		}
	} else if (action.type === 'UI_MEETING_CLIENT_ID_ADD') {
		return {
			...state,
			clients: {
				...state.clients,
				[action.meetingUUID]:
					state.clients && state.clients[action.meetingUUID]
						? {
								...state.clients[action.meetingUUID],
								id: action.clientId,
						  }
						: {
								id: action.clientId,
						  },
			},
		}
	} else if (action.type === 'UI_MEETING_CLIENT_NAME_ADD') {
		return {
			...state,
			clients: {
				...state.clients,
				[action.meetingUUID]:
					state.clients && state.clients[action.meetingUUID]
						? {
								...state.clients[action.meetingUUID],
								name: action.clientName,
						  }
						: {
								name: action.clientName,
						  },
			},
		}
	} else if (action.type === 'UI_MEETING_MESSAGE_COUNT_SET') {
		return {
			...state,
			meetingMessageCount: {
				...state.meetingMessageCount,
				[action.meetingUUID]: action.count,
			},
		}
	} else if (action.type === 'UI_MEETING_MESSAGE_READ_COUNT_SET') {
		return {
			...state,
			meetingMessageReadCount: {
				...state.meetingMessageReadCount,
				[action.meetingUUID]: action.count,
			},
		}
	} else if (action.type === 'UI_MEETING_BOUNCER_SESSION_ID_SET') {
		return {
			...state,
			meetingBouncerSessionIds: {
				...state.meetingBouncerSessionIds,
				[action.meetingUUID]: {
					id: action.bouncerSessionId,
					ts: action.ts,
				},
			},
		}
	} else if (action.type === 'UI_MEETING_LIVEKIT_BACKGROUND_SET') {
		return { ...state, livekitMeetingBackground: action.background }
	}

	return state
}

export default reducer
