import { useIntl } from 'react-intl'
import {
	Button,
	CircularProgress,
	DataTable,
	TableBody,
	TableColumn,
	TableHeader,
	TableRow,
} from 'react-md'
import styled from 'styled-components'
import useAccountsLedgerDetails from './useAccountsLedgerDetails'
import OverflowGradient from 'ui/components/OverflowGradient'
import { TableContainer } from 'ui/StyledComponents/Tables'
import { accountLedgerEntryHeaders } from './constants'
import AccountLedgerEntryRow from './AccountLedgerEntryRow'
import type { AccountsLedgerDetailsFilters } from './types'
import useCompanyApartments from 'util/hooks/useCompanyApartments'
import { makeAparmentLabel } from '../utils/apartmentLabel'
import { useBuildings } from 'ui/CompanyBuildings/hooks'
import { useSelector } from 'react-redux'
import ViiluFullPageDialog from 'ui/components/ViiluFullPageDialog'

const NotFound = styled.p`
	margin: 16px;
`

type Props = {
	apartmentUUID: string
	filters: AccountsLedgerDetailsFilters
	onHide: () => void
}

function AccountLedgerDialog({ apartmentUUID, filters, onHide }: Props) {
	const intl = useIntl()

	const { accountsLedgerDetails, loading } = useAccountsLedgerDetails(
		apartmentUUID,
		filters,
	)

	const accountsLedgerEntries = accountsLedgerDetails?.entries || []

	const selectedCompanyUUID: string = useSelector(
		({ app }: any) => app?.selectedFinanceCompanyUUID,
	)
	const { buildings } = useBuildings(selectedCompanyUUID)
	const { companyApartments } = useCompanyApartments(selectedCompanyUUID)
	const apartment = companyApartments.find((a) => a.uuid === apartmentUUID)
	const building = buildings?.find((b) => b.uuid === apartment?.buildingUUID)
	const title = apartment?.identifier
		? makeAparmentLabel(apartment?.identifier || '', building?.name)
		: intl.formatMessage({
				defaultMessage: 'Reskontra',
				description: 'Title for the account ledger entries dialog.',
		  })

	const renderTableHeaders = () => {
		return (
			<TableRow>
				{accountLedgerEntryHeaders
					.filter((h) => h.show)
					.map((header) => (
						<TableColumn key={header.key}>
							<p className="text-strong text-subtle">{header.title(intl)}</p>
						</TableColumn>
					))}
			</TableRow>
		)
	}

	const renderSumRow = () => {
		let color
		if (accountsLedgerDetails!.balance < 0) {
			color = 'red'
		}
		if (accountsLedgerDetails!.balance > 0) {
			color = 'green'
		}
		return (
			<TableRow
				style={{
					position: 'sticky',
					bottom: 0,
					zIndex: 1,
					background: 'white',
				}}
			>
				<TableColumn key={'balance'}>
					<p className="text-strong">
						{intl.formatMessage({
							defaultMessage: 'Saldo',
							description: 'Balance',
						})}
					</p>
				</TableColumn>
				<TableColumn key={'empty'} colSpan={4}></TableColumn>
				<TableColumn key={'sum'}>
					<p style={{ color }} className="text-strong text-warning">
						{Number(accountsLedgerDetails!.balance.toFixed(2))} €
					</p>
				</TableColumn>
				<TableColumn key={'empty-3'}></TableColumn>
			</TableRow>
		)
	}

	const renderContent = () => {
		if (loading) {
			return (
				<CircularProgress
					id="invoice-dialog-progress"
					style={{ marginTop: 64 }}
				/>
			)
		}

		if (
			!accountsLedgerDetails ||
			!accountsLedgerEntries ||
			accountsLedgerEntries.length === 0
		) {
			return (
				<NotFound>
					{intl.formatMessage({
						defaultMessage: 'Reskontra rivejä ei löytynyt.',
						description: 'Message for account ledger entries not found.',
					})}
				</NotFound>
			)
		}

		return (
			<TableContainer>
				<TableContainer>
					<DataTable
						plain
						style={{
							background: 'white',
							maxHeight: '100vh',
						}}
					>
						<TableHeader
							style={{
								position: 'sticky',
								top: 0,
								zIndex: 1,
								background: 'white',
							}}
						>
							{renderTableHeaders()}
						</TableHeader>
						<TableBody>
							<>
								{
									<AccountLedgerEntryRow
										key={'openingBalance'}
										accountLedgerEntry={{
											uuid: 'openingBalance',
											type: 'openingBalance',
											partyName: '-',
											createdAt: accountsLedgerDetails.openingBalanceDate,

											identifier: '-',
											sum: accountsLedgerDetails.openingBalance,
											allocated: 0,
											status: '-',
										}}
									/>
								}
								{accountsLedgerEntries.map((accountLedgerEntry) => (
									<AccountLedgerEntryRow
										key={accountLedgerEntry.uuid}
										accountLedgerEntry={accountLedgerEntry}
									/>
								))}
								{renderSumRow()}
							</>
						</TableBody>
						<OverflowGradient />
					</DataTable>
				</TableContainer>
			</TableContainer>
		)
	}

	return (
		<ViiluFullPageDialog
			id="account-ledger-entries-dialog"
			title={title}
			visible
			onHide={onHide}
			focusOnMount={false}
			toolbarStyle={{
				background: 'var(--color-secondary-dark)',
			}}
			nav={
				<Button icon onClick={onHide}>
					close
				</Button>
			}
		>
			{renderContent()}
		</ViiluFullPageDialog>
	)
}

export default AccountLedgerDialog
