import { injectIntl } from 'react-intl'
import { Button, FontIcon, ListItem, MenuButton, Toolbar } from 'react-md'
import { connect } from 'react-redux'
import { compose } from 'redux'
import {
	HTJ_READY_FOR_PROD,
	HAUSVISE_READY_FOR_PROD,
} from 'ui/ManagerSettings/Integrations'
import WithSelectedCompanyManager from 'ui/components/WithSelectedCompanyManager'

function RecipientsTableToolbar({
	intl,
	showImportDialog,
	clearTable,
	recipientCount,
	isDesktop,
	selectedCompanyManager,
}) {
	const managerInternal =
		window.document.location.pathname.includes('_internal')

	const oivaManager =
		selectedCompanyManager &&
		selectedCompanyManager.groupUUID === '1af4c573-5734-4ef5-ad8d-dec48f14d1c4' // Oiva

	return (
		<Toolbar
			title={recipientCount ? `${recipientCount} riviä` : ''}
			actions={[
				<Button flat onClick={clearTable} iconEl={<FontIcon>delete</FontIcon>}>
					{isDesktop
						? intl.formatMessage({
								defaultMessage: 'Tyhjennä taulukko',
								description:
									'Label for the button that clears the list of recipients in the message service recipients table.',
						  })
						: intl.formatMessage({
								defaultMessage: 'Tyhjennä',
								description:
									'Short label for the button that clears the list of recipients in the message service recipients table.',
						  })}
				</Button>,
				<MenuButton
					id={'recipients-table-toolbar-menu-button'}
					flat
					iconEl={<FontIcon>add</FontIcon>}
					listHeightRestricted={false}
					menuItems={[
						<ListItem
							key={1}
							primaryText={intl.formatMessage({
								defaultMessage: 'Tuo sähköpostiosoitteita',
								description:
									'Menu item label for importing emails to the meeting invitee table in the meeting invite dialog.',
							})}
							leftIcon={<FontIcon>alternate_email</FontIcon>}
							onClick={() => showImportDialog('import-emails')}
							className="md-divider-border md-divider-border--right"
						/>,
						managerInternal ? null : (
							<ListItem
								key={2}
								primaryText={intl.formatMessage({
									defaultMessage: 'Tuo Viilusta',
									description:
										'Menu item label for importing Viilu users to the meeting invitee table in the meeting invite dialog.',
								})}
								leftIcon={<FontIcon>fiber_manual_record</FontIcon>}
								onClick={() => showImportDialog('import-viilu')}
								className="md-divider-border md-divider-border--right"
							/>
						),
						<ListItem
							key={3}
							primaryText={intl.formatMessage({
								defaultMessage: 'Tuo tiedostolla',
								description:
									'Menu item label for importing from a file to the meeting invitee table in the meeting invite dialog.',
							})}
							leftIcon={<FontIcon>description</FontIcon>}
							onClick={() => showImportDialog('import-file')}
							className="md-divider-border md-divider-border--right"
						/>,
						managerInternal ? null : (
							<ListItem
								key={4}
								primaryText={intl.formatMessage({
									defaultMessage: 'Tuo Fivaldista',
									description:
										'Menu item label for importing from Fivaldi to the meeting invitee table in the meeting invite dialog.',
								})}
								leftIcon={<FontIcon>import_export</FontIcon>}
								onClick={() => showImportDialog('import-fivaldi')}
								className="md-divider-border md-divider-border--right"
							/>
						),
						managerInternal ? null : (
							<ListItem
								key={5}
								primaryText={intl.formatMessage({
									defaultMessage: 'Tuo Tampuurista',
									description:
										'Menu item label for importing from Tampuuri to the meeting invitee table in the meeting invite dialog.',
								})}
								leftIcon={<FontIcon>import_export</FontIcon>}
								onClick={() => showImportDialog('import-tampuuri')}
								className="md-divider-border md-divider-border--right"
							/>
						),
						!HTJ_READY_FOR_PROD || managerInternal ? null : (
							<ListItem
								key={6}
								primaryText={intl.formatMessage({
									defaultMessage: 'Tuo HTJ:stä',
									description:
										'Menu item label for importing from HTJ to the meeting invitee table in the meeting invite dialog.',
								})}
								leftIcon={<FontIcon>import_export</FontIcon>}
								onClick={() => showImportDialog('htj')}
								className="md-divider-border md-divider-border--right"
							/>
						),
						!HAUSVISE_READY_FOR_PROD || managerInternal ? null : (
							<ListItem
								key={7}
								primaryText={intl.formatMessage({
									defaultMessage: 'Tuo Hausvisesta',
									description:
										'Menu item label for importing from Hausvise to the meeting invitee table in the meeting invite dialog.',
								})}
								leftIcon={<FontIcon>import_export</FontIcon>}
								onClick={() => showImportDialog('import-hausvise')}
								className="md-divider-border md-divider-border--right"
							/>
						),
						managerInternal || !oivaManager ? null : (
							<ListItem
								key={8}
								primaryText={intl.formatMessage({
									defaultMessage: 'Tuo Oiva 360:stä',
									description:
										'Menu item label for importing from Oiva 360 to the meeting invitee table in the meeting invite dialog.',
								})}
								leftIcon={<FontIcon>import_export</FontIcon>}
								onClick={() => showImportDialog('import-oiva360')}
								className="md-divider-border md-divider-border--right"
							/>
						),
						<ListItem
							key={9}
							primaryText={intl.formatMessage({
								defaultMessage: 'Lisää yksittäinen',
								description:
									'Menu item label for adding manually to the meeting invitee table in the meeting invite dialog.',
							})}
							leftIcon={<FontIcon>edit</FontIcon>}
							onClick={() => {
								showImportDialog('manual')
							}}
							className="md-divider-border md-divider-border--right"
						/>,
					].filter((t) => t)}
					centered
					anchor={{
						x: MenuButton.HorizontalAnchors.CENTER,
						y: MenuButton.VerticalAnchors.CENTER,
					}}
				>
					{isDesktop
						? intl.formatMessage({
								defaultMessage: 'Lisää vastaanottajia',
								description:
									'Label for the button that adds invitees to the meeting invitee table in the meeting invite dialog.',
						  })
						: intl.formatMessage({
								defaultMessage: 'Lisää',
								description:
									'Short label for the button that adds invitees to the meeting invitee table in the meeting invite dialog.',
						  })}
				</MenuButton>,
			]}
		/>
	)
}

const mapState = ({ dimensions: { isDesktop } }) => ({
	isDesktop,
})

export default compose(
	injectIntl,
	connect(mapState),
	WithSelectedCompanyManager,
)(RecipientsTableToolbar)
