import {
	DataTable,
	FontIcon,
	ListItem,
	TableBody,
	TableColumn,
	TableHeader,
	TableRow,
} from 'react-md'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import { _deletePaymentProduct } from 'state/finance-payment-product-actions'
import { useRef, useState } from 'react'
import useDialogs from 'ui/components/dialogs/useDialogs'
import styled from 'styled-components'
import type { PaymentProduct, PaymentProductKey } from './types'
import type { VatSelectOptions } from '../types'
import { paymentProductHeaders } from './constants'
import naturalSort from 'javascript-natural-sort'
import PaymentProductRow from './PaymentProductRow'
import { getPaymentProductRowValue } from './utils'

const StyledDataTable = styled(DataTable)`
	td {
		white-space: nowrap;
	}
`

type Props = {
	paymentProducts: PaymentProduct[]
	onCopyPaymentProduct?: (paymentProduct: PaymentProduct) => void
	refresh?: () => void
	setSelected?: React.Dispatch<React.SetStateAction<PaymentProduct[]>>
	vatOptions: VatSelectOptions[]
}

function PaymentProductsTable({
	paymentProducts,
	onCopyPaymentProduct,
	refresh,
	setSelected,
	vatOptions,
}: Props) {
	const intl = useIntl()
	const dispatch = useDispatch()
	const currentPath: string | undefined = useSelector(
		({ router }: any) => router?.location?.pathname,
	)
	const { confirm, alert } = useDialogs()
	const [deleting, setDeleting] = useState<string[]>([])
	const isRowClickActive = useRef(!setSelected)

	const [sort, setSort] = useState<{
		key: PaymentProductKey
		type: string | null
		order: string
	}>({
		key: 'name',
		type: null,
		order: 'asc',
	})

	const changeSort = (key: PaymentProductKey, type?: string) => {
		if (sort.key === key) {
			setSort((prevData) => ({
				...prevData,
				order: sort.order === 'asc' ? 'desc' : 'asc',
			}))
		} else {
			setSort({
				key: key,
				type: type || null,
				order: 'asc',
			})
		}
	}

	const navigateToPaymentProduct = (uuid: string) => {
		if (!currentPath) {
			return
		}
		dispatch(push(`${currentPath}?uuid=${uuid}`))
	}

	const deletePaymentProduct = async (uuid: string) => {
		if (!uuid) {
			return
		}

		const confirmMessage = intl.formatMessage({
			defaultMessage: 'Haluatko varmasti poistaa maksulajin?',
			description: 'Confirm message before deleting a payment product.',
		})

		if (!(await confirm(confirmMessage))) {
			return
		}

		setDeleting((prev) => [...prev, uuid])
		try {
			const result = await _deletePaymentProduct(uuid)
			if (result?.ok) {
				refresh?.()
			} else {
				throw new Error('Delete failed.')
			}
		} catch (error) {
			alert('Tapahtui virhe.')
			console.error(error)
		}
		setDeleting((prev) => prev.filter((u) => u !== uuid))
	}

	const onRowToggle = (rowId: number, checked: boolean) => {
		if (!setSelected) {
			return
		}
		if (rowId === 0) {
			setSelected(checked ? [...paymentProducts] : [])
			return
		}
		const paymentProduct = paymentProducts[rowId - 1]
		setSelected((prev) => {
			return checked
				? [...prev, paymentProduct]
				: prev.filter((p) => p.uuid !== paymentProduct.uuid)
		})
	}

	const renderTableHeaders = () => {
		return (
			<TableHeader>
				<TableRow>
					{paymentProductHeaders
						.filter((h) => h.show)
						.map((header) => (
							<TableColumn
								key={header.key}
								onClick={() =>
									header.sortable && changeSort(header.key, header.type)
								}
							>
								<div
									style={{ display: 'flex', alignItems: 'center', gap: '4px' }}
								>
									<p className="text-strong text-subtle">
										{header.title(intl)}
									</p>
									{sort.key === header.key && (
										<FontIcon style={{ fontSize: 12 }}>
											{sort.order === 'asc' ? 'arrow_upward' : 'arrow_downward'}
										</FontIcon>
									)}
								</div>
							</TableColumn>
						))}
					{!setSelected && (
						<TableColumn>
							{intl.formatMessage({
								defaultMessage: 'Toiminnot',
								description:
									'Table column header for finance payment product actions.',
							})}
						</TableColumn>
					)}
				</TableRow>
			</TableHeader>
		)
	}

	return (
		<StyledDataTable
			baseId="payment-products-table"
			plain={!setSelected}
			onRowToggle={!setSelected ? undefined : onRowToggle}
		>
			{renderTableHeaders()}

			<TableBody>
				{paymentProducts
					.sort((a, b) => {
						const aValue =
							sort.type && sort.type !== 'boolean'
								? getPaymentProductRowValue(
										a,
										sort.key,
										vatOptions,
										intl,
										sort.type,
								  )
								: a[sort.key]

						const bValue =
							sort.type && sort.type !== 'boolean'
								? getPaymentProductRowValue(
										b,
										sort.key,
										vatOptions,
										intl,
										sort.type,
								  )
								: b[sort.key]

						return sort.order === 'asc'
							? naturalSort(aValue, bValue)
							: naturalSort(bValue, aValue)
					})
					.map((paymentProduct) => {
						const { uuid } = paymentProduct
						return (
							<PaymentProductRow
								key={uuid}
								paymentProduct={paymentProduct}
								menuItems={[
									<ListItem
										key="edit"
										primaryText={intl.formatMessage({
											defaultMessage: 'Muokkaa',
											description: 'Edit payment product',
										})}
										leftIcon={<FontIcon>edit</FontIcon>}
										onClick={() => navigateToPaymentProduct(uuid)}
									/>,
									<ListItem
										key="copy"
										primaryText={intl.formatMessage({
											defaultMessage: 'Kopioi',
											description: 'Copy payment product',
										})}
										leftIcon={<FontIcon>content_copy</FontIcon>}
										onClick={() => onCopyPaymentProduct?.(paymentProduct)}
									/>,
									<ListItem
										key="delete"
										primaryText={intl.formatMessage({
											defaultMessage: 'Poista',
											description: 'Delete payment product',
										})}
										leftIcon={<FontIcon>delete</FontIcon>}
										onClick={() => deletePaymentProduct(uuid)}
										className="md-divider-border md-divider-border--right"
									/>,
								]}
								vatOptions={vatOptions}
								processing={deleting.includes(uuid)}
								isRowClickActive={isRowClickActive.current}
							/>
						)
					})
					.concat(
						<tr
							key="empty-row"
							className="full-width"
							style={{ height: 100, background: 'white' }}
						></tr>,
					)}
			</TableBody>
		</StyledDataTable>
	)
}

export default PaymentProductsTable
