import styled from 'styled-components'
import { FormattedMessage, injectIntl } from 'react-intl'
import { push } from 'connected-react-router'

import * as userActions from 'state/user-actions'

import { BaseButton } from './styles'
import MicrosoftLogo from 'svg/microsoft-logo.svg'
import { OAuthProvider, getAuth, signInWithPopup } from 'firebase/auth'
import useDialogs from 'ui/components/dialogs/useDialogs'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import FB_ERRORS from 'util/firebaseErrors'

const StyledButton = styled(BaseButton)`
	background: #fafafa;
	color: rgba(0, 0, 0, 0.8);
	border-color: rgba(0, 0, 255, 0.2);
	border-width: 1px;
	border-style: solid;
	font-size: 13px;
`
const StyledIcon = styled.img`
	margin-left: -7px;
	margin-right: 9px;
`

const MicrosoftButton = ({ intl, _push, _sendVerificationEmail }) => {
	const { alert } = useDialogs()

	const handleLoginSuccess = async (authResult) => {
		try {
			const user = authResult.user
			if (user?.providerData?.find((p) => p.providerId === 'microsoft.com')) {
				if (user.email && !user.emailVerified) {
					const redirectURL = window.location.href
					const result = await _sendVerificationEmail(redirectURL)

					if (!result || result.success === false) {
						if (result?.ex?.code === FB_ERRORS.FUNCTIONS_FAILED_PRECONDITION) {
							const message = intl.formatMessage({
								defaultMessage:
									'Tapahtui virhe. Käyttämäsi sähköposti saattaa olla sulkulistalla. Otathan meihin yhteyttä asiakaspalvelu@vii.lu niin hoidetaan homma kuntoon.',
								description:
									'Error message telling the user that their email address might be blocked.',
							})
							await alert(message)
						} else if (
							result?.ex?.code === FB_ERRORS.FUNCTIONS_RESOURCE_EXHAUSTED
						) {
							const message = intl.formatMessage({
								defaultMessage:
									'Olet lähettänyt useita vahvistusviestejä pienessä ajassa. Odota hetki ja yritä uudelleen.',
								description:
									'Error message telling the user that they have attempted to send too many verification emails in a short timespan.',
							})
							await alert(message)
						} else {
							const message = intl.formatMessage({
								defaultMessage:
									'Vahvistussähköpostin lähettämisessä tapahtui virhe.',
								description:
									'Error message telling the user that there was an error while sending the verification email.',
							})
							await alert(message)
						}
						return false
					}
				}
			}
			if (window.URLBeforeLogin) {
				const redirectURL = window.URLBeforeLogin
				window.setTimeout(() => _push(redirectURL), 10000)
			}
		} catch (ex) {
			if (window.sentry) window.sentry.captureException(ex)
			console.error(ex)
		}
	}

	const signIn = async () => {
		const provider = new OAuthProvider('microsoft.com')

		const auth = getAuth()
		try {
			const authResult = await signInWithPopup(auth, provider)
			handleLoginSuccess(authResult)
		} catch (ex) {
			if (ex.code === 'auth/account-exists-with-different-credential') {
				window.localStorage.setItem(
					'pendingCredentialLink',
					JSON.stringify({
						email: ex.customData._tokenResponse.email,
						pendingToken: ex.customData._tokenResponse.pendingToken,
						type: 'microsoft.com',
					}),
				)

				let message =
					'Olet luonut Viilu-tunnuksesi alun perin kirjautumalla sähköpostiosoitteella ja salasanalla. Ole hyvä ja kirjaudu alkuperäisellä tavalla ja yhdistä Microsoft-tilisi Viilun profiilisivun kautta.'
				const verifiedProviders = ex.customData._tokenResponse.verifiedProvider
				if (verifiedProviders.includes('google.com')) {
					message =
						'Olet luonut Viilu-tunnuksesi kirjautumalla Google-tililläsi. Ole hyvä ja kirjaudu sisään ensin Google-tililläsi ja yhdistä Microsoft-tilisi Viilun profiilisivun kautta.'
				}
				await alert(message)
			}
		}
	}
	return (
		<StyledButton
			raised
			onClick={signIn}
			iconEl={<StyledIcon src={MicrosoftLogo} width={20} height={20} />}
		>
			<FormattedMessage
				defaultMessage="Microsoft-tilillä"
				description="Button that selects the Microsoft authentication flow"
			/>
		</StyledButton>
	)
}

const mapDispatchToProps = (dispatch) => ({
	_push: bindActionCreators(push, dispatch),
	_sendVerificationEmail: bindActionCreators(
		userActions._sendVerificationEmail,
		dispatch,
	),
})

export default compose(
	injectIntl,
	connect(null, mapDispatchToProps),
)(MicrosoftButton)
