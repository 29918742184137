import styled from 'styled-components'
import { Button } from 'react-md'

export const DestructiveButton = styled(Button)`
	color: ${(props) => (props.color ? props.color : props.theme.destructive)};
`
export const PrimaryButton = styled(Button)`
	${(props) =>
		props.disabled
			? 'color: darkgrey;'
			: props.flat
			? 'color: var(--color-primary);'
			: 'color: white;'}
	${(props) =>
		props.disabled
			? 'background: lightgrey;'
			: !props.flat
			? 'background: var(--color-primary);'
			: ''}
`

export const SecondaryButton = styled(Button)`
	${(props) =>
		props.disabled
			? 'color: darkgrey;'
			: props.flat
			? 'color: var(--color-secondary);'
			: 'color: white;'}
	${(props) =>
		props.disabled
			? 'background: lightgrey;'
			: !props.flat
			? 'background: var(--color-secondary);'
			: ''}
`

export const ProgressIndicatorButton = styled(Button)`
	margin-top: 16px;
	padding-top: 4px;
`

/**
 * ThemeButton
 *
 * Overrides react-md colors with the colors from the ThemeContext.
 *
 */

const Base = styled(Button)`
	font-family: 'Lato';
	color: #fff;
`

const Flat = styled(Base)`
	color: ${(props) => getThemeColor(props)};
	&:hover {
		background: ${(props) => getThemeColor(props)}33;
	}
`

const Raised = styled(Base)`
	background: ${(props) => getThemeColor(props)};
	&:hover {
		background: ${(props) => getThemeColor(props)};
		opacity: 0.9;
	}
`

const Icon = styled(Base)`
	color: ${(props) => getThemeColor(props)};
	&:hover {
		background: ${(props) => getThemeColor(props)}33;
	}
`

const IconWithBg = styled(Base)`
	background: ${(props) => getThemeColor(props)};
	&:hover {
		background: ${(props) => getThemeColor(props)};
		opacity: 0.9;
	}
`

const getThemeColor = (props) => {
	if (props.primary) return props.theme.primary
	if (props.secondary) return props.theme.secondary
	if (props.destructive) return props.theme.destructive

	return '#000'
}

export const ThemeButton = (props) => {
	if (props.disabled) {
		return <Button {...props}>{props.children}</Button>
	}

	if (props.flat) {
		return <Flat {...props}>{props.children}</Flat>
	}

	if (props.raised) {
		return <Raised {...props}>{props.children}</Raised>
	}

	if (props.icon) {
		return props.swapTheming ? (
			<IconWithBg {...props}>{props.children}</IconWithBg>
		) : (
			<Icon {...props}>{props.children}</Icon>
		)
	}
}
