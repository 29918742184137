import { useIntl } from 'react-intl'
import {
	Button,
	DataTable,
	SelectField,
	TableBody,
	TableColumn,
	TableHeader,
	TableRow,
	TextField,
	TextFieldTypes,
} from 'react-md'
import { formatCurrency } from 'util/formatStrings'
import { billingBasisMessage, unitMessage } from '../utils/messages'
import { ListValue } from 'react-md/lib/SelectFields/SelectField'
import type { SelectOptions, VatSelectOptions } from '../types'
import type { PaymentProduct } from '../PaymentProducts/types'
import type { InvoicePaymentProduct } from './types'
import { invoiceSingleCreateHeaders } from './constants'
import { v4 as uuid } from 'uuid'
import { PaymentProductBillingBasis } from '../enum'
import styled from 'styled-components'
import { calculateSumWithVat, calculateSumWithoutVat } from '../utils/utils'

const Content = styled.div`
	overflow: auto;
`

type Props = {
	selectedPaymentProducts: Map<string, InvoicePaymentProduct>
	setPaymentProducts: React.Dispatch<
		React.SetStateAction<Map<string, InvoicePaymentProduct>>
	>
	paymentProducts: PaymentProduct[]
	vatOptions: VatSelectOptions[]
}

const InvoiceCreateSinglePaymentProductsTable = ({
	selectedPaymentProducts,
	setPaymentProducts,
	paymentProducts = [],
	vatOptions,
}: Props) => {
	const intl = useIntl()

	let paymentProductOptions: SelectOptions[] = []
	if (paymentProducts && paymentProducts.length > 0) {
		paymentProductOptions = paymentProducts.map((p: PaymentProduct) => {
			return {
				label: p.name,
				value: p.uuid,
			}
		})
	}

	const addEmptyRow = () => {
		setPaymentProducts((prev) => {
			const newMap = new Map(prev)
			const newUUID = uuid()

			const emptyRow: InvoicePaymentProduct = {
				uuid: newUUID,
				name: '',
				billingBasis: PaymentProductBillingBasis.PIECES,
				price: 0,
				priceWithVat: 0,
				quantity: 0,
				total: 0,
				totalWithVat: 0,
				currency: 'EUR',
				invoiceUUID: '',
				paymentProductUUID: null,
				vatUUID: null,
				vatPercentage: 0,
			}

			newMap.set(newUUID, emptyRow)
			return newMap
		})
	}

	const deleteRow = (uuid: string) => {
		setPaymentProducts((prev) => {
			const newMap = new Map(prev)
			newMap.delete(uuid)
			return newMap
		})
	}

	const handleSelectChange = async (value: ListValue, field: string) => {
		if (field === 'paymentProductUUID' && paymentProducts) {
			const paymentProduct = paymentProducts.find((p) => p.uuid === value)
			if (paymentProduct) {
				setPaymentProducts((prev) => {
					const newMap = new Map(prev)
					newMap.set(uuid(), {
						...paymentProduct,
						price: 0,
						priceWithVat: 0,
						quantity: 0,
						total: 0,
						totalWithVat: 0,
						currency: 'EUR',
						invoiceUUID: '',
						paymentProductUUID: null,
						vatUUID: null,
						vatPercentage: 0,
					})
					return newMap
				})
			}
		}
	}

	const renderTableHeaders = () => {
		return (
			<TableRow>
				{invoiceSingleCreateHeaders
					.filter((h) => h.show)
					.map((header) => (
						<TableColumn key={header.key}>
							<p className="text-strong text-subtle">{header.title(intl)}</p>
						</TableColumn>
					))}
			</TableRow>
		)
	}

	const handleInputChange = (
		value: string | number,
		field: string,
		uuid: string,
		type: string,
	) => {
		setPaymentProducts((prevData) => {
			const updatedData = new Map(prevData)
			const paymentProduct = updatedData.get(uuid)

			const updatedPaymentProduct = {
				...paymentProduct!,
				[field]: type === 'number' ? Number(value) : value,
			}

			if (field === 'vatUUID') {
				const percentage = vatOptions.find((v) => v.value === value)?.percentage
				updatedPaymentProduct.vatPercentage = percentage || 0
				updatedPaymentProduct.priceWithVat = calculateSumWithVat(
					Number(updatedPaymentProduct.price),
					updatedPaymentProduct.vatPercentage || 0,
				)
			}

			if (field === 'price') {
				updatedPaymentProduct.priceWithVat = calculateSumWithVat(
					Number(value),
					updatedPaymentProduct.vatPercentage || 0,
				)
			}
			if (field === 'priceWithVat') {
				updatedPaymentProduct.price = calculateSumWithoutVat(
					Number(value),
					updatedPaymentProduct.vatPercentage || 0,
				)
			}

			updatedData.set(uuid, updatedPaymentProduct)

			return updatedData
		})
	}

	const getValue = (
		paymentProduct: InvoicePaymentProduct,
		key: string,
		type: string,
	) => {
		let value = paymentProduct[key]

		if (type === 'billingBasis') {
			return billingBasisMessage(intl, value)
		} else if (type === 'unitMessage') {
			return unitMessage(intl, paymentProduct.billingBasis)
		} else if (type === 'total') {
			value = paymentProduct.price
			return (value || 0) * (paymentProduct.quantity || 0)
		} else if (type === 'totalWithVat') {
			value = paymentProduct.priceWithVat
			return (value || 0) * (paymentProduct.quantity || 0)
		} else {
			return value || ''
		}
	}

	const getOptions = (key: string) => {
		let options: SelectOptions[] = []
		if (key === 'vatUUID') {
			options = vatOptions
		}

		return options
	}

	const renderColumns = (
		uuid: string,
		paymentProduct: InvoicePaymentProduct,
	) => {
		return invoiceSingleCreateHeaders.map((header) => {
			const { show, key, type, editable, title } = header

			if (!show) return null

			const value = getValue(paymentProduct, key, type)
			if (editable) {
				if (type === 'select') {
					return (
						<TableColumn key={key}>
							<SelectField
								id={key}
								placeholder={title(intl)}
								value={value}
								onChange={(value) => {
									handleInputChange(value, key, uuid, type)
								}}
								menuItems={getOptions(key)}
								simplifiedMenu={false}
								listHeightRestricted
								style={{
									width: '100%',
									background: '#fafafa',
									padding: '5px 15px 5px 15px',
								}}
							/>
						</TableColumn>
					)
				} else {
					return (
						<TableColumn key={key}>
							<TextField
								key={key}
								id={key}
								floating
								lineDirection="center"
								fullWidth
								value={value}
								onChange={(value) => handleInputChange(value, key, uuid, type)}
								type={(type as TextFieldTypes) || undefined}
							/>
						</TableColumn>
					)
				}
			} else {
				return <TableColumn key={key}>{value}</TableColumn>
			}
		})
	}

	const renderSelectedPaymentProducts = () => {
		return Array.from(selectedPaymentProducts.entries()).map(
			([uuid, paymentProduct]) => {
				return (
					<TableRow key={uuid}>
						{renderColumns(uuid, paymentProduct)}
						<TableColumn key={'delete'}>
							<Button icon onClick={() => deleteRow(uuid)}>
								delete
							</Button>
						</TableColumn>
					</TableRow>
				)
			},
		)
	}

	return (
		<Content>
			<SelectField
				id="target"
				placeholder={intl.formatMessage({
					defaultMessage: 'Maksulajit',
					description: 'Placeholder of payment products',
				})}
				value={''}
				onChange={(value) => handleSelectChange(value, 'paymentProductUUID')}
				menuItems={paymentProductOptions}
				position={SelectField.Positions.BELOW}
				simplifiedMenu={false}
				listHeightRestricted
				style={{
					marginTop: '20px',
					width: '100%',
					background: '#fafafa',
					marginBottom: '10px',
				}}
			/>
			<DataTable plain>
				<TableHeader>{renderTableHeaders()}</TableHeader>
				<TableBody>
					<>
						{renderSelectedPaymentProducts()}
						<TableRow key={'add'}>
							<TableColumn
								colSpan={invoiceSingleCreateHeaders.length}
							></TableColumn>
							<TableColumn>
								<Button icon onClick={() => addEmptyRow()}>
									add
								</Button>
							</TableColumn>
						</TableRow>
						<TableRow key={'total'}>
							<TableColumn>
								<b>
									{intl.formatMessage({
										defaultMessage: 'Yhteensä',
										description: 'Table column for invoice total.',
									})}
								</b>
							</TableColumn>
							<TableColumn colSpan={5}></TableColumn>
							<TableColumn>
								<b>
									{formatCurrency(
										Array.from(selectedPaymentProducts.values()).reduce(
											(acc, { price, quantity }) =>
												acc + (price || 0) * (quantity || 0),
											0,
										),
									)}
								</b>
							</TableColumn>
							<TableColumn>
								<b>
									{formatCurrency(
										Array.from(selectedPaymentProducts.values()).reduce(
											(acc, { priceWithVat, quantity }) =>
												acc + (priceWithVat || 0) * (quantity || 0),
											0,
										),
									)}
								</b>
							</TableColumn>
						</TableRow>
					</>
				</TableBody>
			</DataTable>
		</Content>
	)
}

export default InvoiceCreateSinglePaymentProductsTable
