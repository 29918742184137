import { IntlShape } from 'react-intl'
import type { Settings } from './types'

export const validateSettings = async (
	settings: Settings | null,
	alert: (message: string) => Promise<void>,
	intl: IntlShape,
) => {
	if (
		settings &&
		settings.contactZipCode &&
		settings.contactZipCode.length > 5
	) {
		await alert(
			intl.formatMessage({
				defaultMessage:
					'Käyntiosoitteen postinumero on liian pitkä (postinumero saa olla max 5 kirjainta)',
				description: 'Error message for contact zipcode is too long.',
			}),
		)
		return false
	}
	if (
		settings &&
		settings.invoicingZipCode &&
		settings.invoicingZipCode.length > 5
	) {
		await alert(
			intl.formatMessage({
				defaultMessage:
					'Laskutusosoitteen postinumero on liian pitkä (postinumero saa olla max 5 kirjainta)',
				description: 'Error message for invoicing zipcode is too long.',
			}),
		)
		return false
	}
	return true
}
