const initialState = {
	displayName: '',
	email: '',
	tel: null,
	digitalDeliveryConsent: true,
	address: '',
	zip: '',
	stepIndex: 0,
}

function reducer(state = initialState, action) {
	if (action.type === 'UI_USER_ONBOARDING_STATE_SET') {
		return { ...state, ...action.update }
	} else if (action.type === 'UI_USER_ONBOARDING_DECREMENT_STEP_INDEX') {
		return { ...state, stepIndex: state.stepIndex - 1 }
	} else if (action.type === 'UI_USER_ONBOARDING_INCREMENT_STEP_INDEX') {
		return { ...state, stepIndex: state.stepIndex + 1 }
	} else if (action.type === 'UI_USER_ONBOARDING_RESET_STEP_INDEX') {
		return { ...state, stepIndex: 0 }
	}
	return state
}

export default reducer
