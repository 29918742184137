import config from 'config'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import { Tab, Tabs, List, ListItem, FontIcon } from 'react-md'
import { useIntl } from 'react-intl'
import { useEffect, useState } from 'react'
import BasicInformation from './BasicInformation'
import type { Settings } from './types'
import Taxes from './Taxes'
import Invoicing from './Invoicing'
import useSettings from './useSettings'
import * as notifyActions from 'state/notifyActions'
import { _updateSettings } from 'state/settings-actions'
import { GENERIC_ERROR_NOTIFICATION } from 'ui/messages'
import BankAccounts from './BankAccounts'
import ChartOfAccounts from './ChartOfAccounts'
import WebInvoice from './WebInvoice'
import useWebInvoiceAddresses from './useWebInvoiceAddresses'
import useChartOfAccounts from './useChartOfAccounts'
import useBankAccounts from './useBankAccounts'
import { useCompany } from 'ui/components/useCompany'
import useDialogs from 'ui/components/dialogs/useDialogs'
import { validateSettings } from './validate'
import { CountryCode } from '../enum'
import ValueAddedTax from '../ValueAddedTax/ValueAddedTax'

const HASH_BASIC_INFORMATION = config.routeHashes.basicInformation
const HASH_BANK_ACCOUNTS = config.routeHashes.bankAccounts
const HASH_CHART_OF_ACCOUNTS = config.routeHashes.chartOfAccounts
const HASH_WEB_INVOICE_ADDRESS = config.routeHashes.webInvoiceAddress
const HASH_TAXES = config.routeHashes.taxes
const HASH_BILLING = config.routeHashes.billing
const HASH_VALUE_ADDED_TAX = config.routeHashes.valueAddedTax

const NAVIGATION_ITEMS = [
	{
		label: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Perustiedot',
				description:
					'Title of an app section for finance settings: Basic information tab.',
			}),
		icon: 'info',
		hash: HASH_BASIC_INFORMATION,
	},
	{
		label: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Pankkitilit',
				description:
					'Title of an app section for finance settings: Bank accounts tab.',
			}),
		icon: 'account_balance',
		hash: HASH_BANK_ACCOUNTS,
	},
	{
		label: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Tilikartta',
				description:
					'Title of an app section for finance settings: Chart of accounts tab.',
			}),
		icon: 'grid_view',
		hash: HASH_CHART_OF_ACCOUNTS,
	},
	{
		label: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Verkkolaskuosoitteet',
				description:
					'Title of an app section for finance settings: E-invoices tab.',
			}),
		icon: 'receipt',
		hash: HASH_WEB_INVOICE_ADDRESS,
	},
	{
		label: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Verot',
				description: 'Title of an app section for finance settings: Taxes tab.',
			}),
		icon: 'account_balance_wallet',
		hash: HASH_TAXES,
	},
	{
		label: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Laskutus',
				description:
					'Title of an app section for finance settings: Billing tab.',
			}),
		icon: 'request_quote',
		hash: HASH_BILLING,
	},
	{
		label: (intl) =>
			intl.formatMessage({
				defaultMessage: 'ALV',
				description: 'Title of an app section for finance settings: VAT tab.',
			}),
		icon: 'request_quote',
		hash: HASH_VALUE_ADDED_TAX,
	},
]

const FinanceSettings = () => {
	const intl = useIntl()
	const isDesktop = useSelector((state: any) => state.dimensions.isDesktop)
	const { alert } = useDialogs()
	const dispatch = useDispatch()
	const selectedFinanceCompanyUUID = useSelector(
		({ app }: any) => app?.selectedFinanceCompanyUUID,
	)

	const location = useSelector((state: any) => state.router?.location)

	const params = new URLSearchParams(location.search)
	const bankAccountUUID = params.get('buuid')
	const chartOfAccountUUID = params.get('cuuid')
	const webInvoiceUUID = params.get('wuuid')

	const settingsInitialState = {
		companyUUID: selectedFinanceCompanyUUID,
		companyInfo: null,
		contactStreetAddress: null,
		contactZipCode: null,
		contactPostOffice: null,
		contactCountry: CountryCode.FI,

		invoicingStreetAddress: null,
		invoicingZipCode: null,
		invoicingPostOffice: null,
		invoicingCountry: CountryCode.FI,

		alvRegistered: false,
		alvRegisteryDate: null,
		taxPeriodLength: null,
		accountingMethod: null,

		taxOfficeReferenceNumber: null,
		taxOfficeBankAccount: null,

		referenceNumberPrefix: null,
		invoicePrefix: null,
		dueDate: null,
		collectionFee: null,
		invoicingStartMonth: null,

		contactInformation: null,
		paperInvoiceAdditionalInformation: null,

		eInvoiceStartDate: null,
		eInvoiceEndDate: null,
		eInvoiceCode: null,
	}

	const [settingsState, setSettings] = useState<Settings>(settingsInitialState)

	const [processing, setProcessing] = useState<boolean>(false)

	const { company } = useCompany(selectedFinanceCompanyUUID)

	const { settings, loadingSettings, refreshSettings } = useSettings(
		selectedFinanceCompanyUUID,
	)

	const bankAccounts = useBankAccounts(selectedFinanceCompanyUUID)
	const chartOfAccounts = useChartOfAccounts(selectedFinanceCompanyUUID)
	const webInvoiceAddresses = useWebInvoiceAddresses(selectedFinanceCompanyUUID)

	useEffect(() => {
		setSettings((prev) => ({
			...prev,
			uuid: settings?.uuid,
			createdAt: settings?.createdAt,
			companyInfo: settings?.companyInfo || null,
			contactStreetAddress: settings?.contactStreetAddress || null,
			contactZipCode: settings?.contactZipCode || null,
			contactPostOffice: settings?.contactPostOffice || null,
			contactCountry: settings?.contactCountry || CountryCode.FI,

			invoicingStreetAddress: settings?.invoicingStreetAddress || null,
			invoicingZipCode: settings?.invoicingZipCode || null,
			invoicingPostOffice: settings?.invoicingPostOffice || null,
			invoicingCountry: settings?.invoicingCountry || CountryCode.FI,

			alvRegistered: settings?.alvRegistered || false,
			alvRegisteryDate: settings?.alvRegisteryDate || null,
			taxPeriodLength: settings?.taxPeriodLength || null,
			accountingMethod: settings?.accountingMethod || null,

			taxOfficeReferenceNumber: settings?.taxOfficeReferenceNumber || null,
			taxOfficeBankAccount: settings?.taxOfficeBankAccount || null,

			referenceNumberPrefix: settings?.referenceNumberPrefix || null,
			invoicePrefix: settings?.invoicePrefix || null,
			dueDate: settings?.dueDate || null,
			collectionFee: Number(settings?.collectionFee) ?? null,
			invoicingStartMonth: settings?.invoicingStartMonth || null,

			contactInformation: settings?.contactInformation || null,
			paperInvoiceAdditionalInformation:
				settings?.paperInvoiceAdditionalInformation || null,

			eInvoiceStartDate: settings?.eInvoiceStartDate || null,
			eInvoiceEndDate: settings?.eInvoiceEndDate || null,
			eInvoiceCode: settings?.eInvoiceCode || null,
		}))
	}, [settings])

	const saveSettings = async () => {
		setProcessing(true)
		if (await validateSettings(settingsState, alert, intl)) {
			const result = await _updateSettings(settingsState)
			setProcessing(false)
			if (!result || result.ok === false) {
				dispatch(notifyActions.info('❌ ' + GENERIC_ERROR_NOTIFICATION(intl)))
				return false
			}
			dispatch(
				notifyActions.info(
					'✅ ' +
						intl.formatMessage({
							defaultMessage: 'Asetukset on päivitetty.',
							description: 'Notification text of settings has been updated',
						}),
				),
			)
			refreshSettings()
			if (location.search.includes('onboarding')) {
				dispatch(push(HASH_BILLING))
				window.location.reload()
			}
		}
		setProcessing(false)
	}

	const resetScroll = () => {
		const element = document.getElementById('settings-container')
		if (element) element.scrollTop = 0
	}

	const getTabIndex = () => {
		const { location } = window

		if (bankAccountUUID) {
			return 1
		} else if (chartOfAccountUUID) {
			return 2
		} else if (webInvoiceUUID) {
			return 3
		}

		if (location.hash?.includes(HASH_BASIC_INFORMATION)) {
			return 0
		} else if (location.hash?.includes(HASH_BANK_ACCOUNTS)) {
			return 1
		} else if (location.hash?.includes(HASH_CHART_OF_ACCOUNTS)) {
			return 2
		} else if (location.hash?.includes(HASH_WEB_INVOICE_ADDRESS)) {
			return 3
		} else if (location.hash?.includes(HASH_TAXES)) {
			return 4
		} else if (location.hash?.includes(HASH_BILLING)) {
			return 5
		} else if (location.hash?.includes(HASH_VALUE_ADDED_TAX)) {
			return 6
		}
		return 0
	}

	const renderTabs = () => {
		const tabIndex = getTabIndex()

		return (
			<Tabs
				tabId="managersettings-mobile-tab"
				mobile
				style={{
					flexShrink: 0,
					borderBottomStyle: 'solid',
					borderBottomColor: 'rgba(0,0,0,0.2)',
					borderBottomWidth: 1,
				}}
				alignToKeyline={false}
				defaultTabIndex={tabIndex}
				indicatorHeight={3}
			>
				{NAVIGATION_ITEMS.map((item, i) => (
					<Tab
						key={i}
						style={
							tabIndex === i
								? {
										color: 'var(--color-primary-dark)',
										fontWeight: '700',
								  }
								: {
										color: 'var(--color-text-subtle)',
								  }
						}
						label={item.label(intl)}
						icon={<FontIcon>{item.icon}</FontIcon>}
						onClick={() => {
							dispatch(push(item.hash))
							resetScroll()
						}}
					/>
				))}
			</Tabs>
		)
	}

	const renderList = () => {
		const tabIndex = getTabIndex()

		return (
			<List>
				{NAVIGATION_ITEMS.map((item, i) => (
					<ListItem
						key={i}
						style={
							tabIndex === i
								? {
										color: 'var(--color-primary-dark)',
										fontWeight: '700',
										background: '#DDDDDD',
										paddingLeft: 8,
								  }
								: {
										color: 'var(--color-text-subtle)',
										paddingLeft: 8,
								  }
						}
						primaryText={item.label(intl)}
						leftIcon={<FontIcon>{item.icon}</FontIcon>}
						onClick={() => {
							dispatch(push(item.hash))
							resetScroll()
						}}
					/>
				))}
			</List>
		)
	}

	const renderNavigation = () => {
		if (isDesktop) {
			return renderList()
		}
		return renderTabs()
	}

	const renderPage = () => {
		const tabIndex = getTabIndex()
		let pageComponent
		if (tabIndex === 0) {
			pageComponent = (
				<BasicInformation
					settings={settingsState}
					setSettings={setSettings}
					processing={processing}
					saveSettings={saveSettings}
					loading={loadingSettings}
					company={company}
				/>
			)
		} else if (tabIndex === 1) {
			pageComponent = (
				<BankAccounts
					selectedFinanceCompanyUUID={selectedFinanceCompanyUUID}
					useBankAccounts={bankAccounts}
					bankAccountUUID={bankAccountUUID}
				/>
			)
		} else if (tabIndex === 2) {
			pageComponent = (
				<ChartOfAccounts
					selectedFinanceCompanyUUID={selectedFinanceCompanyUUID}
					useChartOfAccounts={chartOfAccounts}
					chartOfAccountUUID={chartOfAccountUUID}
				/>
			)
		} else if (tabIndex === 3) {
			pageComponent = (
				<WebInvoice
					selectedFinanceCompanyUUID={selectedFinanceCompanyUUID}
					useWebInvoiceAddresses={webInvoiceAddresses}
					webInvoiceUUID={webInvoiceUUID}
				/>
			)
		} else if (tabIndex === 4) {
			pageComponent = (
				<Taxes
					settings={settingsState}
					setSettings={setSettings}
					processing={processing}
					saveSettings={saveSettings}
					loading={loadingSettings}
				/>
			)
		} else if (tabIndex === 5) {
			pageComponent = (
				<Invoicing
					settings={settingsState}
					setSettings={setSettings}
					processing={processing}
					saveSettings={saveSettings}
					loading={loadingSettings}
				/>
			)
		} else if (tabIndex === 6) {
			pageComponent = <ValueAddedTax />
		}
		return (
			<div className="flex-column" style={{ flexGrow: 1, overflow: 'hidden' }}>
				<div
					style={{
						display: 'flex',
						flexDirection: isDesktop ? 'row' : 'column',
						height: 'calc(100% - 64px)',
						gap: '10px',
					}}
				>
					<div
						style={{
							display: 'flex',
							flexDirection: isDesktop ? 'column' : 'row',
						}}
					>
						{renderNavigation()}
					</div>
					<div
						id="settings-container"
						className="flex-column flex-grow"
						style={{ overflowY: 'auto', alignItems: 'center' }}
					>
						{pageComponent}
					</div>
				</div>
			</div>
		)
	}

	return (
		<div className="flex-column" style={{ flexGrow: 1, overflow: 'hidden' }}>
			{renderPage()}
		</div>
	)
}

export default FinanceSettings
