import type { Header } from './types'

export const valueAddedTaxHeaders: Header[] = [
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Nimi',
				description: 'Name',
			}),
		key: 'name',
		type: 'text',
		show: true,
		required: true,
	},
]

export const valueAddedTaxPercentageHeaders: Header[] = [
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Prosentti %',
				description: 'Percentage',
			}),
		key: 'percentage',
		type: 'number',
		show: true,
		required: true,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Alkupäivämäärä',
				description: 'Start date',
			}),
		key: 'startDate',
		type: 'date',
		show: true,
		required: true,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Loppupäivämäärä',
				description: 'End date',
			}),
		key: 'endDate',
		type: 'date',
		show: true,
		required: false,
	},
]

export const valueAddedTaxTableHeaders: Header[] = [
	...valueAddedTaxHeaders,
	...valueAddedTaxPercentageHeaders,
]
