import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { isLoaded, isEmpty } from 'react-redux-firebase'

import bg from 'svg/illustrations/illustration_abstract_bg.svg'
import LoadingState from './LoadingState'
import * as loginUIActions from 'state/ui/login-ui-actions'
import WithQueryParams from './WithQueryParams'

export default (ComponentToWrap) => {
	class WithoutAuthShowLoginDialog extends Component {
		componentWillMount = () => {
			this.handleDialog(this.props)
		}

		componentWillReceiveProps = (nextProps) => {
			this.handleDialog(nextProps)
		}

		handleDialog = (props) => {
			const {
				auth,
				loginReducer: { loginDialogVisible },
				_showLoginDialog,
				_hideLoginDialog,
			} = props
			if (isEmpty(auth)) {
				window.loginDialogHideCloseButton = true
				if (!loginDialogVisible) {
					window.loginDialogHideCloseButton = true
					_showLoginDialog()
				}
			} else {
				if (loginDialogVisible) {
					_hideLoginDialog()
				}
			}
		}

		render() {
			const { auth } = this.props
			if (!isLoaded(auth)) {
				return <LoadingState />
			}
			if (isEmpty(auth)) {
				return (
					<div style={{ background: 'white', height: '100%', width: '100%' }}>
						<img
							src={bg}
							style={{ width: '100%', height: '100%', objectFit: 'cover' }}
							alt=""
						/>
					</div>
				)
			}
			return <ComponentToWrap {...this.props} />
		}
	}

	const mapState = ({
		firebaseReducer: { auth },
		ui: { login: loginReducer },
	}) => ({ auth, loginReducer })

	const mapDispatchToProps = (dispatch) => ({
		_showLoginDialog: bindActionCreators(
			loginUIActions._showLoginDialog,
			dispatch,
		),
		_hideLoginDialog: bindActionCreators(
			loginUIActions._hideLoginDialog,
			dispatch,
		),
	})

	return compose(
		WithQueryParams,
		connect(mapState, mapDispatchToProps),
	)(WithoutAuthShowLoginDialog)
}
