import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { useUIContext } from 'site/context/UIContext'
import RouterLink from 'ui/components/RouterLink'
import NavLogo from './NavLogo'
import MobileNav from './MobileNav'
import { ReactComponent as InfoscreenIcon } from 'site/assets/svgs/infoscreen-icon.svg'
import LinkIcon from './LinkIcon'

const MAX_WIDTH = '1200px'

const Container = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 99;
	padding: 0 16px;
	background: ${(props) => (props.bgVisible ? 'white' : 'transparent')};
	box-shadow: ${(props) =>
		props.boxShadow ? '0 8px 4px -4px rgb(38 44 49 / 20%)' : 'none'};

	a {
		color: ${(props) => (props.bgVisible ? 'var(--color-text)' : 'white')};
	}
`

const CenteredContent = styled.div`
	margin: 8px auto;
	max-width: ${MAX_WIDTH};
	display: flex;
	justify-content: space-between;
	align-items: center;
`

const Navigation = styled.nav`
	display: flex;
	align-items: center;
`

const NavLink = styled(RouterLink)`
	cursor: pointer;
	padding: 16px;
	font-size: 1.14em;
	letter-spacing: 1px;
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
`

const navButton = css`
	cursor: pointer;
	border-style: solid;
	border-width: 2px;
	border-color: ${(props) => (props.bgVisible ? 'var(--color-text)' : 'white')};
	border-radius: 4px;
	padding: 8px 16px;
	margin-left: 8px;
`

const LoginButton = styled(NavLink)`
	${navButton}
	position: relative;
	z-index: 100;
`

const NavButton = styled.button`
	${navButton}
	font-size: 1.14em;
	padding: 8px 16px;
	letter-spacing: 1px;
	background: transparent;
	color: ${(props) => (props.bgVisible ? 'var(--color-text)' : 'white')};
`

const Subnavigation = styled.div`
	position: absolute;
	left: 0;
	right: 0;
	padding: 0 16px;
	background-color: white;
	box-shadow: 0 8px 4px -4px rgb(38 44 49 / 20%);
`

const Sublinks = styled.div`
	max-width: ${MAX_WIDTH};
	margin: 0 auto;
	display: flex;
	flex-wrap: wrap;
	padding-top: 32px;
	padding-bottom: 16px;

	a {
		color: var(--color-text);
	}
`

const Sublink = styled(RouterLink)`
	display: flex;
	margin: 0 16px 16px 0;
	padding: 16px;
	text-decoration: none;
	max-width: 384px;
	min-width: 300px;

	background-color: ${(props) => props.bgColor};
	border-radius: 8px;

	&:last-child {
		margin-right: 0;
	}

	.sublink-label {
		font-size: 20px;
		line-height: 20px;
		font-weight: bold;
	}

	&:hover .sublink-label {
		text-decoration: underline;
	}

	p {
		font-size: 16px;
	}
`

const IconContainer = styled.div`
	width: 48px;
	height: 48px;
	flex-shrink: 0;
	margin-right: 16px;
`

export const APP_LOGIN_URL =
	'https://app.vii.lu/internal/mycompany?source=header'

const navItems = [
	{
		label: 'Palvelut',
		sublinks: [
			{
				label: 'Porrasnäytöt',
				href: '/infoscreens',
				description:
					'Oiva viestintäkanava ja tietolähde portaaseen. Räätälöitävissä tarpeeseesi ja yhdistettävissä muihin palveluihimme.',
				icon: <InfoscreenIcon />,
				bgColor: 'rgba(0, 0, 0, .1)',
			},
			{
				label: 'Kokoustyökalut',
				href: '/meetings',
				description:
					'Tehokkaat työkalut säästävät aikaa kokouksen valmistelussa kuin myös kokouksessa.',
				icon: <LinkIcon icon="videocam" bgColor="rgba(9, 110, 214)" />,
				bgColor: 'rgba(9, 110, 214, .1)',
			},
			{
				label: 'Kokonaispalvelu',
				href: '/suite',
				description:
					'Kokonaisvaltaisempi ratkaisu viestintään ja päätöksentekoon. Ota käyttöön kaikki tai vain tarvitsemasi työkalut.',
				icon: <LinkIcon icon="apartment" bgColor="rgba(156, 63, 185)" />,
				bgColor: 'rgba(156, 63, 185, .1)',
			},
			{
				label: 'Allekirjoituspalvelu',
				href: '/sign',
				description:
					'Sähköiset allekirjoitukset säästävät aikaa ja rahaa. Käytä sellaisenaan tai liitä osaksi prosesseja.',
				icon: <LinkIcon icon="edit" bgColor="rgb(99, 196, 172)" />,
				bgColor: 'rgba(99, 196, 172, .1)',
			},
			{
				label: 'Viestintäpalvelu',
				href: '/messages',
				description:
					'Lähetä ilmoituksia, tiedotteita ja kokouskutsuja muutamalla klikkauksella postitse tai sähköisesti.',
				icon: <LinkIcon icon="mail" bgColor="rgba(255, 129, 0)" />,
				bgColor: 'rgba(255, 129, 0, .1)',
			},
			{
				label: 'Maksupalvelu',
				href: '/payments',
				description:
					'Maksata asiakirjatilaukset, varaukset tai mitkä tahansa muut tuotteet ja palvelut jo tilauksen yhteydessä.',
				icon: <LinkIcon icon="shopping_cart" bgColor="rgba(156, 185, 65)" />,
				bgColor: 'rgba(156, 185, 65, .1)',
			},
		],
	},
	{
		label: 'Hinnoittelu',
		href: '/pricing',
	},
	{
		label: 'Asiakaspalvelu',
		href: '/support',
	},
	{
		label: 'Ajankohtaista',
		href: '/news',
	},
]

function NavBar() {
	const { hasScrolledDown, dimensions } = useUIContext()
	const isMobile = dimensions.width < 1024
	const [mobileNavVisible, setMobileNavVisible] = useState(false)
	const [panelActive, setPanelActive] = useState(false)
	const bgVisible = hasScrolledDown || panelActive

	if (mobileNavVisible) {
		return (
			<MobileNav navItems={navItems} close={() => setMobileNavVisible(false)} />
		)
	}

	return (
		<Container bgVisible={bgVisible} boxShadow={bgVisible && !panelActive}>
			<CenteredContent>
				<NavLogo fill={bgVisible ? 'var(--color-secondary)' : '#fff'} />
				{isMobile ? (
					<NavButton
						bgVisible={bgVisible}
						onClick={() => setMobileNavVisible(true)}
					>
						Valikko
					</NavButton>
				) : (
					<Navigation>
						{navItems.map((item) => (
							<div
								key={item.label}
								onMouseEnter={() => {
									if (item.sublinks) {
										setPanelActive(true)
									}
								}}
								onMouseLeave={() => {
									if (panelActive) {
										setPanelActive(false)
									}
								}}
								style={{
									paddingLeft: item.sublinks && panelActive ? 64 : undefined,
									paddingRight: item.sublinks && panelActive ? 64 : undefined,
									marginLeft: item.sublinks && panelActive ? -64 : undefined,
									marginRight: item.sublinks && panelActive ? -64 : undefined,
									zIndex: item.sublinks && panelActive ? 2 : undefined,
								}}
							>
								<NavLink href={item.href}>{item.label}</NavLink>
								{panelActive && item.sublinks && (
									<Subnavigation>
										<Sublinks>
											{item.sublinks.map((sublink) => (
												<Sublink
													key={sublink.label}
													href={sublink.href}
													afterOnClick={() => setPanelActive(false)}
													bgColor={sublink.bgColor}
												>
													<IconContainer>{sublink.icon}</IconContainer>
													<div>
														<span className="sublink-label">
															{sublink.label}
														</span>
														<p>{sublink.description}</p>
													</div>
												</Sublink>
											))}
										</Sublinks>
									</Subnavigation>
								)}
							</div>
						))}
						<LoginButton href={APP_LOGIN_URL} bgVisible={bgVisible}>
							Kirjaudu
						</LoginButton>
					</Navigation>
				)}
			</CenteredContent>
		</Container>
	)
}

export default NavBar
