import styled from 'styled-components'
import DayMonthPicker from './DayMonthPicker'
import type { DayMonthValue } from './DayMonthPicker'
import { Button, FontIcon } from 'react-md'
import { FormattedMessage } from 'react-intl'

const ListContainer = styled.div`
	display: flex;
	flex-flow: column nowrap;
	gap: 0.5rem;
	margin-top: 1rem;
`

const HeaderRow = styled.div<{ $singleColumn: boolean }>`
	display: grid;
	grid-template-columns: ${(props) =>
		props.$singleColumn ? '0 1fr 1fr' : '32px 1fr 1fr'};
	align-items: center;
	padding-bottom: 0.5rem;
`

const PickerContainer = styled.div<{ $singleColumn: boolean }>`
	display: grid;
	align-items: center;
	grid-template-columns: ${(props) =>
		props.$singleColumn ? '1fr' : '32px 1fr'};
`

const AddButtonContainer = styled.span`
	display: flex;
	justify-content: center;
`

const RemovePickerButton = styled(Button)`
	width: 16px;
	height: 16px;
	border-radius: 50%;
	border: 2px solid var(--color-secondary-dark);
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: white;
	margin-bottom: 4px;
	padding: 8px;
`

const AddPickerButton = styled(Button)`
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: center;
	margin-top: 0.5rem;
	gap: 0.5rem;
`

const NoPickersContainer = styled.div`
	width: 100%;
	text-align: center;
`

const NoPickersNotification = styled.small`
	font-size: 0.7em;
`

type DayMonthPickerMutableListProps = {
	values: DayMonthValue[]
	onChange: (value: DayMonthValue[]) => void
	minPickerAmount: number
	maxPickerAmount: number
}

function DayMonthPickerMutableList(props: DayMonthPickerMutableListProps) {
	const { values, onChange, minPickerAmount, maxPickerAmount } = props
	const addButtonDisabled = Object.keys(values).length >= maxPickerAmount
	const removeButtonsEnabled = values.length > minPickerAmount

	return (
		<ListContainer>
			{values.length > 0 && (
				<HeaderRow $singleColumn={!removeButtonsEnabled}>
					<span />
					<span className="md-floating-label--floating md-text--secondary">
						<FormattedMessage defaultMessage="Päivä" description="Day" />
					</span>
					<span className="md-floating-label--floating md-text--secondary">
						<FormattedMessage defaultMessage="Kuukausi" description="Month" />
					</span>
				</HeaderRow>
			)}
			{values.map((dayMonthValue, index) => (
				<PickerContainer
					key={`day-month-picker-container-${index}`}
					$singleColumn={!removeButtonsEnabled}
				>
					{removeButtonsEnabled ? (
						<RemovePickerButton
							icon
							onClick={() => {
								const newList = values.filter((_, i) => i !== index)
								onChange(newList)
							}}
						>
							<FontIcon
								style={{
									color: 'var(--color-secondary-dark)',
								}}
							>
								remove
							</FontIcon>
						</RemovePickerButton>
					) : (
						<span />
					)}
					<DayMonthPicker
						id={`day-month-picker-${index}`}
						value={dayMonthValue}
						hideLabels
						onChange={(newValue) => {
							const newList = [...values]
							newList[index] = newValue
							onChange(newList)
						}}
						style={{ width: '100%' }}
					/>
				</PickerContainer>
			))}
			{values.length === 0 && (
				<NoPickersContainer>
					<NoPickersNotification>
						<FormattedMessage
							defaultMessage="Yhtään päivämäärää ei ole vielä määritelty"
							description="Notification message when no dates have been defined yet"
						/>
					</NoPickersNotification>
				</NoPickersContainer>
			)}
			<AddButtonContainer>
				<AddPickerButton
					secondary
					flat
					disabled={addButtonDisabled}
					onClick={() =>
						onChange([
							...values,
							{
								month: 1,
								day: 1,
							},
						])
					}
				>
					<FontIcon
						style={{
							color: addButtonDisabled
								? 'rgba(0, 0, 0, 0.38)'
								: 'var(--color-secondary-dark)',
						}}
					>
						add
					</FontIcon>
					<FormattedMessage
						defaultMessage="Lisää uusi päivämäärä"
						description="Button label for adding new date"
					/>
				</AddPickerButton>
			</AddButtonContainer>
		</ListContainer>
	)
}

export default DayMonthPickerMutableList
