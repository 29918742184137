import { useCallback, useEffect, useState } from 'react'
import { _getParties } from 'state/finance-party-actions'
import type { PartyFilters, Party } from './types'
import naturalSort from 'javascript-natural-sort'

function useParties(companyUUID: string, filters?: PartyFilters) {
	const [parties, setParties] = useState<Party[]>([])

	const [loadingParties, setLoading] = useState<boolean>(false)
	const [errorParties, setError] = useState<string | null>(null)

	const doQuery = useCallback(async () => {
		setLoading(true)
		setError(null)
		let data: Party[] = []
		if (companyUUID) {
			try {
				const response = await _getParties({ companyUUID, ...filters })

				if (response && response.ok) {
					const res = await response.json()

					if (res.parties && Array.isArray(res.parties))
						data = res.parties.sort((a: Party, b: Party) =>
							naturalSort(a.name, b.name),
						)
				} else {
					throw new Error('Response is not ok.')
				}
			} catch (err) {
				console.error('Error fetching parties:', err)
				setError('An error occurred while fetching parties.')
			}
		}

		setLoading(false)
		setParties(data)
	}, [companyUUID, filters])

	useEffect(() => {
		doQuery()
	}, [doQuery])

	return {
		parties,
		loadingParties,
		errorParties,
		refreshParties: doQuery,
	}
}

export default useParties
