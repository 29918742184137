import { injectIntl } from 'react-intl'
import { FontIcon } from 'react-md'
import { compose } from 'redux'
import styled from 'styled-components'

const Container = styled.div`
	display: flex;
	align-items: center;
`

function MessageServiceProcessType({ generator, intl }) {
	let iconName = 'mail'
	let iconColor = 'orange'
	let text = intl.formatMessage({
		defaultMessage: 'Viesti',
		description: 'Label for the message service default process.',
	})

	if (generator === 'meetingInvite') {
		iconName = 'videocam'
		iconColor = '#4596EC'
		text = intl.formatMessage({
			defaultMessage: 'Kokouskutsu',
			description: 'Label for the message service meeting invite process.',
		})
	}

	return (
		<Container>
			<FontIcon style={{ marginRight: 8, color: iconColor }}>
				{iconName}
			</FontIcon>
			{text}
		</Container>
	)
}

export default compose(injectIntl)(MessageServiceProcessType)
