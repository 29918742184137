import { useState } from 'react'
import { useIntl } from 'react-intl'
import {
	DataTable,
	FontIcon,
	ListItem,
	TableBody,
	TableColumn,
	TableHeader,
	TableRow,
} from 'react-md'
import { _deletePayment } from 'state/finance-payment-actions'
import useDialogs from 'ui/components/dialogs/useDialogs'
import type { PaymentKey, Payments } from './types'
import { paymentHeaders } from './constants'
import PaymentRow from './PaymentRow'
import naturalSort from 'javascript-natural-sort'
import { getPaymentRowValue } from './utils'
import { ApartmentSelectOptions } from '../types'

type Props = {
	payments: Payments[]
	refreshPayments: () => void
	apartmentOptions: ApartmentSelectOptions[]
}

function PaymentsTable({ payments, refreshPayments, apartmentOptions }: Props) {
	const intl = useIntl()
	const { alert, confirm } = useDialogs()
	const [deleting, setDeleting] = useState<string[]>([])

	const [sort, setSort] = useState<{
		key: PaymentKey
		type: string | null
		order: string
	}>({
		key: 'apartmentIdentifier',
		type: null,
		order: 'asc',
	})

	const changeSort = (key: PaymentKey, type?: string) => {
		if (sort.key === key) {
			setSort((prevData) => ({
				...prevData,
				order: sort.order === 'asc' ? 'desc' : 'asc',
			}))
		} else {
			setSort({
				key: key,
				type: type || null,
				order: 'asc',
			})
		}
	}

	const deletePayment = async (uuid: string) => {
		if (!uuid) {
			return
		}

		const confirmMessage = intl.formatMessage({
			defaultMessage: 'Haluatko varmasti poistaa suorituksen?',
			description: 'Confirm message before deleting a payment.',
		})

		if (!(await confirm(confirmMessage))) {
			return
		}

		setDeleting((prev) => [...prev, uuid])
		try {
			const result = await _deletePayment(uuid)
			if (result?.ok) {
				refreshPayments()
			} else {
				throw new Error('Delete failed.')
			}
		} catch (error) {
			console.error(error)
			alert('Tapahtui virhe.')
		}
		setDeleting((prev) => prev.filter((u) => u !== uuid))
	}

	const renderTableHeaders = () => {
		return (
			<TableHeader>
				<TableRow>
					{paymentHeaders
						.filter((h) => h.show)
						.map((header) => (
							<TableColumn
								key={header.key}
								onClick={() =>
									header.sortable && changeSort(header.key, header.type)
								}
							>
								<div
									style={{ display: 'flex', alignItems: 'center', gap: '4px' }}
								>
									<p className="text-strong text-subtle">
										{header.title(intl)}
									</p>
									{sort.key === header.key && (
										<FontIcon style={{ fontSize: 12 }}>
											{sort.order === 'asc' ? 'arrow_upward' : 'arrow_downward'}
										</FontIcon>
									)}
								</div>
							</TableColumn>
						))}

					<TableColumn>
						{intl.formatMessage({
							defaultMessage: 'Toiminnot',
							description: 'Table header for finance payment actions column.',
						})}
					</TableColumn>
				</TableRow>
			</TableHeader>
		)
	}
	return (
		<DataTable plain>
			{renderTableHeaders()}
			<TableBody>
				{payments
					.sort((a, b) => {
						const aValue =
							sort.type && sort.type !== 'boolean'
								? getPaymentRowValue(
										a,
										sort.key,
										apartmentOptions,
										intl,
										sort.type,
								  )
								: a[sort.key]

						const bValue =
							sort.type && sort.type !== 'boolean'
								? getPaymentRowValue(
										b,
										sort.key,
										apartmentOptions,
										intl,
										sort.type,
								  )
								: b[sort.key]

						return sort.order === 'asc'
							? naturalSort(aValue, bValue)
							: naturalSort(bValue, aValue)
					})
					.map((payment) => {
						const { uuid } = payment
						return (
							<PaymentRow
								key={uuid}
								payment={payment}
								menuItems={[
									<ListItem
										key="delete"
										primaryText={intl.formatMessage({
											defaultMessage: 'Poista',
											description: 'Delete payment button',
										})}
										leftIcon={<FontIcon>delete</FontIcon>}
										onClick={() => deletePayment(uuid)}
										className="md-divider-border md-divider-border--right"
									/>,
								]}
								apartmentOptions={apartmentOptions}
								processing={deleting.includes(uuid)}
							/>
						)
					})
					.concat(
						<tr
							key="empty-row"
							className="full-width"
							style={{ height: 100, background: 'white' }}
						></tr>,
					)}
			</TableBody>
		</DataTable>
	)
}

export default PaymentsTable
