export enum LoanEventType {
	PAYMENT = 'payment',
	LOAN_PAYBACK = 'loan-payback',
}

export enum LoanInterestDaysMonth {
	THIRTY_DAYS = '30',
	ACTUAL_OF_MONTH = 'actual-of-month',
}

export enum LoanInterestDaysYear {
	THREE_SIXTY = '360',
	THREE_SIXTY_FIVE = '365',
	ACTUAL_OF_YEAR = 'actual-of-year',
}

export enum LoanFinancingType {
	CAPITAL = 'capital',
	MAINTENANCE = 'maintenance',
	UNREALIZED_MAINTENANCE = 'unrealized-maintenance',
}

export enum LoanStatus {
	DRAFT = 'draft',
	ACTIVE = 'active',
	ENDED = 'ended',
}
