import { Controller, useFormContext } from 'react-hook-form'
import type { CreateLoanInput, LoanCreateFormInputs } from '../types'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import YesNoSwitch from 'ui/components/YesNoSwitch'
import { TextField, SelectField, Radio } from 'react-md'
import DatePickerISO from 'ui/components/DatePickerISO'
import { CreateFormGridItem } from './components'
import DayMonthPickerMutableList from 'ui/components/DayMonthPickerMutableList'
import type { DayMonthValue } from 'ui/components/DayMonthPicker'

const FormSubHeading = styled.div`
	margin-top: 1rem;
	margin-bottom: 1rem;
	grid-column: span 2;
`

const ErrorMessage = styled.small`
	color: var(--color-error);
	font-size: 0.6em;
`

type LoanFormInputProps = {
	input: CreateLoanInput
}

function LoanFormInput(props: LoanFormInputProps) {
	const {
		control,
		watch,
		formState: { errors },
	} = useFormContext<LoanCreateFormInputs>()
	const intl = useIntl()
	const {
		show,
		showOnCondition,
		key,
		title,
		type,
		disabled,
		fullWidth,
		menuItems,
		rules,
	} = props.input

	if (key === 'sub-heading') {
		return (
			<FormSubHeading key={`${key}-${title(intl)}`}>
				<h3 className="md-subheading-2" style={{ marginBottom: '0' }}>
					{title(intl)}
				</h3>
				<hr style={{ borderColor: 'rgba(0, 0, 0, 0.2)' }} />
			</FormSubHeading>
		)
	}

	if (show || (showOnCondition && showOnCondition(watch))) {
		// In an ideal case our input components would use forwardRef to expose their ref
		// in which case we could directly use {...register(key)} to register them as inputs,
		// which would directly "ingest" the value and onChange attributes.
		// But in the absence of this, we will use Controller wrappers
		if (type === 'text') {
			return (
				<CreateFormGridItem key={key} $fullWidth={fullWidth}>
					<Controller
						name={key}
						control={control}
						rules={rules(intl)}
						render={({ field }) => (
							<TextField
								id={key}
								label={title(intl)}
								lineDirection="center"
								floating
								fullWidth
								disabled={disabled}
								error={errors[key] !== undefined}
								type="text"
								//@ts-ignore
								autoComplete="never-autocomplete"
								value={field.value as string}
								onChange={(value) => field.onChange(value)}
								onBlur={field.onBlur}
							/>
						)}
					/>
					{key !== 'selectedSharegroups' && errors[key]?.message && (
						<ErrorMessage>{errors[key]?.message}</ErrorMessage>
					)}
				</CreateFormGridItem>
			)
		} else if (type === 'number') {
			return (
				<CreateFormGridItem key={key} $fullWidth={fullWidth}>
					<Controller
						name={key}
						control={control}
						rules={rules(intl)}
						render={({ field }) => (
							<TextField
								id={key}
								label={title(intl)}
								lineDirection="center"
								floating
								fullWidth
								error={errors[key] !== undefined}
								disabled={disabled}
								type="number"
								//@ts-ignore
								autoComplete="never-autocomplete"
								value={field.value as number}
								onChange={(value) => field.onChange(Number(value))}
								onBlur={field.onBlur}
							/>
						)}
					/>
					{key !== 'selectedSharegroups' && errors[key]?.message && (
						<ErrorMessage>{errors[key]?.message}</ErrorMessage>
					)}
				</CreateFormGridItem>
			)
		} else if (type === 'date') {
			return (
				<CreateFormGridItem key={key} $fullWidth={fullWidth}>
					<Controller
						name={key}
						control={control}
						rules={rules(intl)}
						render={({ field: { onChange, onBlur, value } }) => (
							<DatePickerISO
								style={{
									width: '100%',
									paddingTop: '5px',
								}}
								id={key}
								label={title(intl)}
								value={value as string | Date}
								disabled={disabled}
								onChange={(date) => onChange(date)}
							/>
						)}
					/>
					{key !== 'selectedSharegroups' && errors[key]?.message && (
						<ErrorMessage>{errors[key]?.message}</ErrorMessage>
					)}
				</CreateFormGridItem>
			)
		} else if (type === 'boolean') {
			return (
				<CreateFormGridItem key={key} $fullWidth={fullWidth}>
					<Controller
						name={key}
						control={control}
						render={({ field: { onChange, value } }) => (
							<YesNoSwitch
								label={title(intl)}
								name={key}
								checked={value as boolean}
								disabled={disabled}
								onChange={(bool) => onChange(bool)}
								style={{
									marginTop: '10px',
								}}
							/>
						)}
					/>
				</CreateFormGridItem>
			)
		} else if (type === 'select') {
			return (
				<CreateFormGridItem key={key} $fullWidth={fullWidth}>
					<Controller
						name={key}
						control={control}
						render={({ field: { onChange, onBlur, value } }) => (
							<SelectField
								id={key}
								label={title(intl)}
								style={{
									width: '100%',
								}}
								menuItems={menuItems ? menuItems(intl) : []}
								value={value?.toString()}
								onChange={onChange}
								onBlur={onBlur}
							/>
						)}
					/>
				</CreateFormGridItem>
			)
		} else if (type === 'radio') {
			return (
				<CreateFormGridItem key={key} $fullWidth={fullWidth}>
					<label className="md-selection-control-label">{title(intl)}</label>
					<Controller
						name={key}
						control={control}
						render={({ field }) => {
							const items = menuItems ? menuItems(intl) : []
							return (
								<>
									{items.map((item) => (
										<Radio
											key={item.value}
											id={`${item.value}-id`}
											name={item.value}
											label={item.label}
											checked={field.value === item.value}
											value={item.value}
											onChange={field.onChange}
										/>
									))}
								</>
							)
						}}
					/>
				</CreateFormGridItem>
			)
		} else if (type === 'day-month') {
			return (
				<CreateFormGridItem key={key} $fullWidth={fullWidth}>
					<label className="md-selection-control-label">{title(intl)}</label>
					<Controller
						name={key}
						control={control}
						render={({ field }) => {
							return (
								<DayMonthPickerMutableList
									values={field.value as DayMonthValue[]}
									onChange={field.onChange}
									minPickerAmount={0}
									maxPickerAmount={12}
								/>
							)
						}}
					/>
					{key !== 'selectedSharegroups' && errors[key]?.message && (
						<ErrorMessage>{errors[key]?.message}</ErrorMessage>
					)}
				</CreateFormGridItem>
			)
		}
	}

	return null
}

export default LoanFormInput
