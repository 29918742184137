import moment from 'moment'
import { MenuButton, TableColumn, TableRow, CircularProgress } from 'react-md'
import type {
	BankAccount,
	ChartOfAccount,
	Header,
	WebInvoiceAddress,
} from './types'
import { useIntl } from 'react-intl'
import { directionMessage } from '../utils/messages'

type Props = {
	entity: BankAccount | ChartOfAccount | WebInvoiceAddress
	menuItems: any[] | null
	children: any
	processing: any
	headers: Header[]
	handleOnClick: () => void
}

const SettingsTableRow = ({
	entity,
	menuItems,
	children,
	processing,
	headers,
	handleOnClick,
}: Props) => {
	const intl = useIntl()
	const appendedMenuItems = menuItems ? [...menuItems] : null

	const menu = !appendedMenuItems ? null : (
		<MenuButton
			id={'menu-button'}
			icon
			menuItems={appendedMenuItems}
			centered
			anchor={{
				x: MenuButton.HorizontalAnchors['CENTER'],
				y: MenuButton.VerticalAnchors['CENTER'],
			}}
		>
			more_vert
		</MenuButton>
	)
	const getRowValue = (key: string, type: string | undefined) => {
		let value = entity[key]
		if (type && type === 'date') {
			const parsedDate = moment(value)
			if (!parsedDate.isValid()) return ''
			return parsedDate.format('DD.MM.YYYY')
		}
		if (type && type === 'boolean') {
			if (value) {
				return intl.formatMessage({
					defaultMessage: 'Kyllä',
					description: 'Yes',
				})
			} else {
				return intl.formatMessage({
					defaultMessage: 'Ei',
					description: 'No',
				})
			}
		}
		if (key && key === 'direction') {
			return directionMessage(intl, value)
		}
		return value
	}

	const renderTableColumns = () => {
		return headers
			.filter((h) => h.show)
			.map((header) => {
				return (
					<TableColumn onClick={handleOnClick} key={header.key}>
						<p>{getRowValue(header.key, header?.type)}</p>
					</TableColumn>
				)
			})
	}
	return (
		<TableRow key={entity.uuid}>
			{renderTableColumns()}
			<TableColumn key={'children'}>
				<div>
					{processing ? (
						<CircularProgress id="progress" style={{ marginTop: 0 }} />
					) : (
						menu
					)}
					{children}
				</div>
			</TableColumn>
		</TableRow>
	)
}

export default SettingsTableRow
