import { useEffect, useState } from 'react'
import { Button } from 'react-md'
import { compose, bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as notifyActions from 'state/notifyActions'
import * as messageServiceActions from 'state/message-service-actions'
import { injectIntl } from 'react-intl'
import LoadingState from 'ui/components/LoadingState'
import RecipientMethodsCount from './RecipientMethodsCount'
import BulletinsInfo from './BulletinsInfo'
import { GENERIC_ERROR_NOTIFICATION } from 'ui/messages'
import ViiluDialog from 'ui/components/ViiluDialog'

const DialogStatus = {
	CONFIRM_CREATE: 'confirm_create',
	CREATING: 'creating',
	CREATE_ERROR: 'create_error',
	CONFIRM_SEND: 'confirm_send',
	SENDING: 'sending',
	DONE: 'done',
	LOADING: 'loading',
}

const getInitStatus = (jobStatus) => {
	if (!jobStatus) {
		return DialogStatus.CONFIRM_CREATE
	}

	const statuses = Object.values(jobStatus)

	if (statuses.some((status) => status === 'creating')) {
		return DialogStatus.CREATING
	}

	if (statuses.every((status) => status === 'created')) {
		return DialogStatus.CONFIRM_SEND
	}

	if (statuses.some((status) => status === 'create-error')) {
		return DialogStatus.CREATE_ERROR
	}

	if (statuses.some((status) => status === 'sending')) {
		return DialogStatus.SENDING
	}

	if (statuses.every((status) => status === 'done' || status === 'error')) {
		return DialogStatus.DONE
	}

	return DialogStatus.LOADING
}

function SendDialog({
	visible,
	onHide,
	closeSending,
	messageServiceMassProcess,
	_createMassJobs,
	_sendMassJobs,
	syncedMassProcess,
	letterPageCount,
	intl,
	_showInfoNotification,
}) {
	const [dialogStatus, setDialogStatus] = useState(
		getInitStatus(syncedMassProcess?.jobStatus),
	)

	useEffect(() => {
		const status = getInitStatus(syncedMassProcess?.jobStatus)
		setDialogStatus(status)
	}, [syncedMassProcess?.jobStatus])

	const createMessageServiceProcesses = async () => {
		setDialogStatus(DialogStatus.CREATING)
		const result = await _createMassJobs(
			messageServiceMassProcess,
			letterPageCount,
		)

		if (!result || result.success === false || !result?.data?.success) {
			_showInfoNotification(`❌ ${GENERIC_ERROR_NOTIFICATION(intl)}`)
			return false
		}

		return true
	}

	const sendMessageServiceProcesses = async () => {
		setDialogStatus(DialogStatus.SENDING)
		const result = await _sendMassJobs(messageServiceMassProcess.uuid)

		if (!result || result.success === false || !result?.data?.success) {
			_showInfoNotification(`❌ ${GENERIC_ERROR_NOTIFICATION(intl)}`)
			return false
		}

		return true
	}

	const getStatusCount = (statusesToCheck) => {
		if (!syncedMassProcess.jobStatus) {
			return null
		}
		const all = Object.values(syncedMassProcess.jobStatus)
		const count = all.filter((s) => statusesToCheck.includes(s)).length
		return `${count} / ${all.length}`
	}

	const renderLoading = (statusesToCheck, message) => (
		<>
			<LoadingState />
			<p style={{ textAlign: 'center', marginTop: 16 }}>
				{getStatusCount(statusesToCheck)}
			</p>
			<p style={{ textAlign: 'center', marginTop: 16 }}>{message}</p>
		</>
	)

	const renderContent = () => {
		if (messageServiceMassProcess.uuid !== syncedMassProcess?.uuid) {
			return <LoadingState />
		}

		if (dialogStatus === DialogStatus.CONFIRM_CREATE) {
			return (
				<p>
					{intl.formatMessage({
						defaultMessage:
							'Taloyhtiöille luodaan lähetykset "Luonnos"-tilaan. Näet sen jälkeen vielä ' +
							'vastaanottajien lukumäärät ennen lopullista lähettämisen hyväksyntää. Luodaanko lähetykset?',
						description:
							'Informational paragraph about creating message service process drafts for each company in the manager mass sending.',
					})}
				</p>
			)
		}

		if (dialogStatus === DialogStatus.CREATING) {
			return renderLoading(
				['created', 'create-error'],
				intl.formatMessage({
					defaultMessage: 'Luodaan lähetyksiä "Luonnos"-tilaan. Odota hetki.',
					description:
						'Loading message during the creation of the message service process drafts in the manager mass sending.',
				}),
			)
		}

		if (dialogStatus === DialogStatus.CREATE_ERROR) {
			return (
				<p>
					{intl.formatMessage({
						defaultMessage:
							'Lähetysten luonti epäonnistui. Ota yhteyttä Viilun asiakaspalveluun.',
						description:
							'Error message for message service process drafts creation failure in the manager mass sending.',
					})}
				</p>
			)
		}

		if (dialogStatus === DialogStatus.CONFIRM_SEND) {
			return (
				<>
					<p>
						{intl.formatMessage({
							defaultMessage:
								'Taloyhtiöille on luotu lähetykset "Luonnos"-tilaan.',
							description:
								'Success message after creating the message service process drafts in the manager mass sending.',
						})}
					</p>
					<RecipientMethodsCount
						recipientMethodsCount={syncedMassProcess?.recipientMethodsCount}
					/>
					<BulletinsInfo
						messageServiceMassProcess={messageServiceMassProcess}
					/>
					<p style={{ marginTop: 32 }}>
						{intl.formatMessage({
							defaultMessage: 'Lähetetäänkö?',
							description:
								'Confirmation message for sending in the manager mass sending.',
						})}
					</p>
				</>
			)
		}

		if (dialogStatus === DialogStatus.SENDING) {
			return renderLoading(
				['done', 'error'],
				intl.formatMessage({
					defaultMessage: 'Lähetyksiä lähetetään. Odota hetki.',
					description:
						'Loading message during the sending of the message service processes in the manager mass sending.',
				}),
			)
		}

		if (dialogStatus === DialogStatus.DONE) {
			return (
				<>
					<p>
						{intl.formatMessage({
							defaultMessage: 'Lähetykset lähetetty!',
							description:
								'Success message after sending the message service processes in the manager mass sending.',
						})}
					</p>
					{Object.values(syncedMassProcess?.jobStatus || {}).some(
						(status) => status === 'error',
					) && (
						<p style={{ marginTop: 16 }}>
							{intl.formatMessage({
								defaultMessage:
									'Osa lähetyksistä epäonnistui. Näet ne tarkemmin "Lähetyt"-osiosta, ' +
									'kun suljet tämän dialogin. Ota yhteyttä Viilun asiakaspalveluun ' +
									'lisäselvitystä varten.',
								description:
									'Error message for message service process sending failure in the manager mass sending.',
							})}
						</p>
					)}
				</>
			)
		}

		return <LoadingState />
	}

	const renderActions = () => {
		if (messageServiceMassProcess.uuid !== syncedMassProcess?.uuid) {
			return null
		}

		const noMessage = intl.formatMessage({
			defaultMessage: 'Ei',
			description:
				'Cancel button label in the manager mass sending send dialog.',
		})
		const yesMessage = intl.formatMessage({
			defaultMessage: 'Kyllä',
			description:
				'Confirm button label in the manager mass sending send dialog.',
		})
		const closeMessage = intl.formatMessage({
			defaultMessage: 'Sulje',
			description:
				'Close button label in the manager mass sending send dialog.',
		})

		if (dialogStatus === DialogStatus.CONFIRM_CREATE) {
			return [
				<Button flat onClick={onHide}>
					{noMessage}
				</Button>,
				<Button raised secondary onClick={createMessageServiceProcesses}>
					{yesMessage}
				</Button>,
			]
		}

		if (dialogStatus === DialogStatus.CREATE_ERROR) {
			return [
				<Button flat onClick={onHide}>
					{closeMessage}
				</Button>,
			]
		}

		if (dialogStatus === DialogStatus.CONFIRM_SEND) {
			return [
				<Button flat onClick={onHide}>
					{noMessage}
				</Button>,
				<Button raised secondary onClick={sendMessageServiceProcesses}>
					{yesMessage}
				</Button>,
			]
		}

		if (dialogStatus === DialogStatus.DONE) {
			return [
				<Button raised secondary onClick={closeSending}>
					{closeMessage}
				</Button>,
			]
		}

		return null
	}

	return (
		<ViiluDialog
			id="message-service-mass-sending-confirm-dialog"
			visible={visible}
			onHide={onHide}
			title={intl.formatMessage({
				defaultMessage: 'Lähetykset',
				description: 'Title of the send dialog in the manager mass sending.',
			})}
			focusOnMount={false}
			actions={renderActions()}
			modal={true}
			closeOnEsc={false}
			dialogStyle={{ minWidth: '500px', borderRadius: '8px' }}
			autosizeContent={false}
		>
			{renderContent()}
		</ViiluDialog>
	)
}

const mapState = () => ({})

const mapDispatchToProps = (dispatch) => ({
	_createMassJobs: bindActionCreators(
		messageServiceActions._createMassJobs,
		dispatch,
	),
	_sendMassJobs: bindActionCreators(
		messageServiceActions._sendMassJobs,
		dispatch,
	),
	_showInfoNotification: bindActionCreators(notifyActions.info, dispatch),
})

export default compose(
	injectIntl,
	connect(mapState, mapDispatchToProps),
)(SendDialog)
