import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { compose, bindActionCreators } from 'redux'
import { firestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { Button, TextField } from 'react-md'
import LoadingState from '../../LoadingState'
import { FormattedMessage } from 'react-intl'
import { injectIntl } from 'react-intl'
import {
	getAuth,
	RecaptchaVerifier,
	signInWithPhoneNumber,
} from 'firebase/auth'
import { firebaseApp } from 'state/store'
import WithDialogs from 'ui/components/dialogs/WithDialogs'

class LoginDialogPhoneVerification extends Component {
	state = {
		stepIndex: 0,
		code: '',
	}

	componentDidUpdate = () => {
		const { stepIndex } = this.state
		if (stepIndex === 1) {
			window.recaptchaVerifier = new RecaptchaVerifier(
				'phone-login-send-button',
				{
					size: 'invisible',
					callback: () => {},
				},
				getAuth(firebaseApp),
			)
		}
	}

	componentWillReceiveProps = (nextProps) => {
		const { userPhoneLogin } = nextProps
		const { stepIndex } = this.state
		if (
			isLoaded(userPhoneLogin) &&
			!isEmpty(userPhoneLogin) &&
			stepIndex === 0
		) {
			this.setState({ stepIndex: 1 })
		}
	}

	onSend = async () => {
		const { phoneNumber } = this.props
		this.setState({ processing: true })
		try {
			const confirmationResult = await signInWithPhoneNumber(
				getAuth(firebaseApp),
				phoneNumber,
				window.recaptchaVerifier,
			)
			this.confirmationResult = confirmationResult
			this.setState({ stepIndex: 2, processing: false })
		} catch (error) {
			this.setState({ processing: false })
			const widgetId = await window.recaptchaVerifier.render()
			window.recaptchaVerifier.reset(widgetId)
		}
	}

	onConfirm = async () => {
		const { intl, alert } = this.props
		const { code } = this.state
		this.setState({ processing: true })

		const errorText = intl.formatMessage({
			defaultMessage:
				'Syöttämäsi koodi on virheellinen. Ole hyvä ja yritä hetken päästä uudelleen.',
			description:
				'Error message telling the user that the code they entered was incorrect and asks them to try again.',
		})

		try {
			const result = await this.confirmationResult.confirm(code)
			const user = result.user
			if (user) {
				return this.handleLoginSuccess()
			}
			await alert(errorText)
			this.setState({ stepIndex: 0 })
		} catch (error) {
			this.setState({ processing: false, stepIndex: 0 })
			const widgetId = await window.recaptchaVerifier.render()
			window.recaptchaVerifier.reset(widgetId)
			await alert(errorText)
		}
	}

	handleLoginSuccess = async (authResult) => {
		const { _push, onHide } = this.props
		try {
			if (window.URLBeforeLogin) {
				const redirectURL = window.URLBeforeLogin
				window.setTimeout(() => _push(redirectURL), 1000)
			}
			if (onHide) onHide()
		} catch (ex) {
			if (window.sentry) window.sentry.captureException(ex)
			console.error(ex)
		}
	}

	renderExistenceCheckStep = () => {
		const { userPhoneLogin } = this.props

		if (!isLoaded(userPhoneLogin)) {
			return <LoadingState />
		}

		if (isEmpty(userPhoneLogin)) {
			return (
				<div>
					<p>
						<FormattedMessage
							defaultMessage="Tällä puhelinnumerolla ei löydy käyttäjätunnusta. Tekstiviestitunnistautumista voi käyttää vain jos olet ensin luonut tunnuksen ja syöttänyt puhelinnumerosi profiilisi tietoihin. Ole hyvä ja valitse toinen tunnistautumistapa."
							description="Error message telling the user that there is no user profile found with the typed in phone number."
						/>
					</p>
				</div>
			)
		}
		return <LoadingState />
	}

	renderVerificationCodeSendStep = () => {
		const { processing } = this.state

		return (
			<div className="flex-column">
				{processing ? (
					<LoadingState />
				) : (
					<p>
						<FormattedMessage
							defaultMessage="Painamalla LÄHETÄ-painiketta lähetämme sinulle tekstiviestitse vahvistuskoodin, jolla pääset kirjautumaan sisään."
							description="Instructional paragraph telling the user that by pressing the Send-button they will be sent a single-use code that they can use to log in."
						/>
					</p>
				)}
				<Button
					id="phone-login-send-button"
					raised
					secondary
					className="margin-top--xl"
					style={{
						alignSelf: 'center',
						background: 'var(--color-secondary)',
						opacity: processing ? 0 : 1,
					}}
					onClick={this.onSend}
					disabled={processing}
				>
					<FormattedMessage
						defaultMessage="Lähetä"
						description="Button that sends the single-use code to the user via an SMS."
					/>
				</Button>
			</div>
		)
	}

	renderVerificationCodeVerifyStep = () => {
		const { intl } = this.props
		const { code, processing } = this.state
		return (
			<div className="flex-column">
				{processing ? (
					<LoadingState />
				) : (
					<>
						<p className="margin-bottom">
							<FormattedMessage
								defaultMessage="Syötä tekstiviestissä mainittu vahvistuskoodi alle."
								description="Instructional paragraph telling the user that they need to type in the code they received via SMS."
							/>
						</p>
						<TextField
							id="login-dialog-phone-code-textfield"
							lineDirection="center"
							fullWidth
							value={code}
							autoComplete="off"
							label={intl.formatMessage({
								defaultMessage: 'Koodi',
								description: 'Label of the code-field.',
							})}
							style={{
								background: 'rgba(0,0,0,0.05)',
								borderRadius: 4,
								padding: 16,
							}}
							onChange={(code) => this.setState({ code })}
							inputStyle={{
								fontSize: 24,
								fontWeight: 'bold',
								textAlign: 'center',
							}}
							maxLength={6}
						/>
						<Button
							id="phone-login-confirm-button"
							raised
							secondary
							className="margin-top--xl"
							style={{
								alignSelf: 'center',
								background: 'var(--color-secondary)',
							}}
							onClick={this.onConfirm}
							disabled={processing}
						>
							<FormattedMessage
								defaultMessage="Vahvista"
								description="Button that does the single-use code verification and logs the user in."
							/>
						</Button>
					</>
				)}
			</div>
		)
	}

	render() {
		const { stepIndex } = this.state

		let stepElement = null
		if (stepIndex === 0) {
			stepElement = this.renderExistenceCheckStep()
		} else if (stepIndex === 1) {
			stepElement = this.renderVerificationCodeSendStep()
		} else if (stepIndex === 2) {
			stepElement = this.renderVerificationCodeVerifyStep()
		}
		return stepElement
	}
}

LoginDialogPhoneVerification.propTypes = {
	phoneNumber: PropTypes.string.isRequired,
}

const mapState = ({
	firestoreReducer: {
		ordered: { user_phone_login },
	},
}) => ({
	userPhoneLogin: user_phone_login,
})

const mapDispatchToProps = (dispatch) => ({
	_push: bindActionCreators(push, dispatch),
})

export default compose(
	injectIntl,
	WithDialogs,
	firestoreConnect(({ phoneNumber }) =>
		phoneNumber
			? [
					{
						collection: 'user',
						storeAs: 'user_phone_login',
						where: ['phoneNumber', '==', phoneNumber],
						limit: 1,
					},
			  ]
			: [],
	),
	connect(mapState, mapDispatchToProps),
)(LoginDialogPhoneVerification)
