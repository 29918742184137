import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import WithSelectedCompany from './WithSelectedCompany'
import WithSelectedCompanyManagerRole from './WithSelectedCompanyManagerRole'
import WithUserRoles from './userRoles/WithUserRoles'
import config from 'config'

export default (ComponentToWrap) => {
	class WithSelectedCompanyRole extends Component {
		render() {
			const { selectedCompany, selectedCompanyManagerRole, uid, isSuperAdmin } =
				this.props

			let role = config.userRoles.none
			if (selectedCompany !== null) {
				role = selectedCompany.userRoles[uid] || config.userRoles.none
			}

			// Check if user is a manager
			if (
				selectedCompanyManagerRole &&
				selectedCompanyManagerRole.includes('manager_')
			) {
				role = 'admin'
			}

			// Check if super admin
			if (isSuperAdmin) {
				role = 'admin'
			}
			return <ComponentToWrap {...this.props} selectedCompanyRole={role} />
		}
	}

	const mapState = ({
		firebaseReducer: {
			auth: { uid },
		},
	}) => ({
		uid,
	})

	const mapDispatchToProps = (dispatch) => ({})

	return compose(
		WithSelectedCompany,
		WithSelectedCompanyManagerRole,
		WithUserRoles,
		connect(mapState, mapDispatchToProps),
	)(WithSelectedCompanyRole)
}
