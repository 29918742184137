import { generateQueryParams } from 'util/queryUtils'
import { callApi } from './api/finance-api'
import type {
	CreateInvoiceData,
	GetInvoicesFilters,
	CreateInvoicePaymentAllocation,
	CreateInvoice,
	UpdateInvoice,
} from 'ui/ManagerFinance/Invoices/types'
import type {
	GetConsolidatedInvoiceFilters,
	GetConsolidatedInvoicesFilters,
} from 'ui/ManagerFinance/ConsolidatedInvoices/types'
import type { CreateAllocations } from 'ui/ManagerFinance/Payments/types'

export const _getInvoice = async (uuid: string) => {
	return callApi(`/v1/invoices/${uuid}`, {
		method: 'GET',
	})
}

export const _getInvoices = async (filters: GetInvoicesFilters) => {
	return callApi(`/v1/invoices${generateQueryParams(filters)}`, {
		method: 'GET',
	})
}

export const _createInvoices = async (data: CreateInvoiceData) => {
	return callApi(`/v1/invoices`, {
		method: 'POST',
		body: {
			...data,
		},
	})
}

export const _deleteInvoice = async (uuid: string) => {
	return callApi(`/v1/invoices/${uuid}`, {
		method: 'DELETE',
	})
}

export const _sendInvoices = async (invoiceUUIDs: string[]) => {
	return callApi('/v1/invoices/send', {
		method: 'POST',
		body: {
			invoiceUUIDs: invoiceUUIDs,
		},
	})
}

export const _voidInvoices = async (invoiceUUIDs: string[]) => {
	return callApi('/v1/invoices/void', {
		method: 'POST',
		body: {
			invoiceUUIDs: invoiceUUIDs,
		},
	})
}

export const _saveInvoice = async (
	invoiceUUID: string,
	data: UpdateInvoice,
) => {
	return callApi(`/v1/invoice/${invoiceUUID}/save`, {
		method: 'PUT',
		body: {
			...data,
		},
	})
}

export const _updateInvoicesByCompanyUUID = async (companyUUID: string) => {
	return callApi('/v1/invoices/update-invoices-by-company-uuid', {
		method: 'PUT',
		body: {
			companyUUID: companyUUID,
		},
	})
}

export const _createInvoicePaymentAllocations = async (
	invoiceUUID: string,
	allocations: CreateInvoicePaymentAllocation[],
) => {
	return callApi(`/v1/invoice/${invoiceUUID}/allocations`, {
		method: 'POST',
		body: {
			allocations: allocations,
		},
	})
}

export const _updateInvoicePaymentAllocations = async (
	invoiceUUID: string,
	allocations: CreateAllocations[],
) => {
	return callApi(`/v1/invoice/${invoiceUUID}/allocations`, {
		method: 'PUT',
		body: {
			allocations: allocations,
		},
	})
}

export const _getInvoiceLocks = async (companyUUID: string) => {
	return callApi(`/v1/invoice-locks/${companyUUID}`, {
		method: 'GET',
	})
}

export const _getConsolidatedInvoice = async (
	filters: GetConsolidatedInvoiceFilters,
) => {
	return callApi(`/v1/consolidated-invoice${generateQueryParams(filters)}`, {
		method: 'GET',
	})
}

export const _getConsolidatedInvoices = async (
	filters: GetConsolidatedInvoicesFilters,
) => {
	return callApi(`/v1/consolidated-invoices${generateQueryParams(filters)}`, {
		method: 'GET',
	})
}

export const _createInvoice = async (data: CreateInvoice) => {
	return callApi(`/v1/invoice`, {
		method: 'POST',
		body: {
			...data,
		},
	})
}
