import {
	TextField,
	SelectField,
	TextFieldTypes,
	Button,
	ExpansionList,
	ExpansionPanel,
} from 'react-md'
import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import DatePickerISO from 'ui/components/DatePickerISO'
import type { SelectOptions } from '../types'
import type { BondUpdate, Party } from './types'
import { editBondHeaders } from './constants'
import { bondNameMessage } from '../utils/messages'

interface Props {
	party?: Party
	formData: Map<string, BondUpdate>
	setFormData: React.Dispatch<React.SetStateAction<Map<string, BondUpdate>>>
	bondNameOptions: SelectOptions[]
}

const BondDialog = ({
	party,
	formData,
	setFormData,
	bondNameOptions,
}: Props) => {
	const intl = useIntl()

	useEffect(() => {
		if (party && Array.isArray(party.bonds) && party.bonds.length > 0) {
			const updatedData = new Map(formData)
			party.bonds.forEach((bond) => {
				if (!updatedData.get(bond.name)) {
					updatedData.set(bond.name, {
						uuid: bond.uuid,
						name: bond.name,
						startDate: bond.startDate,
						endDate: bond.endDate,
						updated: false,
					})
				}
			})
			setFormData(updatedData)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [party, setFormData])

	const handleInputChange = (
		value: string | number,
		field: string,
		bondName: string,
	) => {
		setFormData((prevData) => {
			const updatedData = new Map(prevData)
			const link = updatedData.get(bondName)
			if (link && link.create) {
				const updatedLink = {
					...link,
					[field]: value,
				}
				updatedData.set(bondName, updatedLink)
			} else if (link) {
				const updatedLink = {
					...link,
					[field]: value,
					updated: true,
				}
				updatedData.set(bondName, updatedLink)
			}
			return updatedData
		})
	}

	const handleSelectBond = (value: string) => {
		setFormData((prevData) => {
			const updatedData = new Map(prevData)
			const existingBond = updatedData.get(value)
			if (existingBond) {
				updatedData.set(value, {
					...existingBond,
					deleted: false,
				})
				return updatedData
			}

			const existingPartyBond = party?.bonds?.find((b) => b.name === value)
			if (existingPartyBond) {
				updatedData.set(value, {
					...existingPartyBond,
				})
			} else {
				updatedData.set(value, {
					name: value,
					startDate: null,
					endDate: null,
					create: true,
				})
			}

			return updatedData
		})
	}

	const handleDeselectLink = async (name: string) => {
		setFormData((prevData) => {
			const updatedData = new Map(prevData)
			const bond = updatedData.get(name)
			if (bond && bond.uuid) {
				const updatedLink = { ...bond, deleted: true, updated: false }
				updatedData.set(name, updatedLink)
			} else {
				updatedData.delete(name)
			}
			return updatedData
		})
	}

	const getValue = (bond: BondUpdate, key: string) => {
		if (key === 'name') {
			return bondNameMessage(intl, bond[key]) || ''
		} else {
			return bond[key] || ''
		}
	}

	const renderFormFields = (bond: BondUpdate) => {
		return editBondHeaders.map((header) => {
			const { key, title, type } = header

			const value = getValue(bond, key)

			if (key === 'name') {
				return (
					<div
						key={key}
						style={{ minWidth: '100px', marginRight: '50px', width: 'auto' }}
					>
						<p>{value}</p>
					</div>
				)
			} else if (key === 'delete') {
				return (
					<div
						key={key}
						style={{ minWidth: '100px', marginRight: '0px', width: 'auto' }}
					>
						<Button icon onClick={() => handleDeselectLink(bond.name)}>
							delete
						</Button>
					</div>
				)
			} else if (type === 'date') {
				return (
					<div
						key={key}
						style={{ minWidth: '100px', marginRight: '50px', width: 'auto' }}
					>
						<DatePickerISO
							style={{ width: '100%' }}
							key={key}
							id={key}
							label={title(intl)}
							value={value}
							onChange={(value) => handleInputChange(value, key, bond.name)}
						/>
					</div>
				)
			} else {
				return (
					<div
						key={key}
						style={{ minWidth: '100px', marginRight: '50px', width: 'auto' }}
					>
						<TextField
							key={key}
							id={key}
							label={title(intl)}
							lineDirection="center"
							floating
							value={value}
							onChange={(value) => handleInputChange(value, key, bond.name)}
							type={(type as TextFieldTypes) || undefined}
						/>
					</div>
				)
			}
		})
	}

	const renderFormDatas = () => {
		return Array.from(formData.entries()).map(([name, bond]) => {
			if (bond.deleted) return null
			return (
				<div
					key={name}
					style={{
						display: 'flex',
						flexDirection: 'row',
						flexWrap: 'wrap',
						alignItems: 'baseline',
						marginBottom: '50px',
						marginLeft: '5px',
						justifySelf: 'center',
					}}
				>
					{renderFormFields(bond)}
				</div>
			)
		})
	}

	const renderChildren = () => {
		return (
			<>
				<div
					className="margin-left--lg margin-right--lg"
					style={{ paddingBottom: 64 }}
				>
					<SelectField
						id="target"
						placeholder={intl.formatMessage({
							defaultMessage: 'Roolit',
							description: 'Placeholder of bonds',
						})}
						value={''}
						onChange={(value) => handleSelectBond(value as string)}
						menuItems={bondNameOptions.filter((opt) => {
							const existingBond = formData.get(opt.value)
							return !existingBond || existingBond.deleted
						})}
						position={SelectField.Positions.BELOW}
						simplifiedMenu={false}
						listHeightRestricted
						style={{
							width: '100%',
							background: '#fafafa',
							marginBottom: '10px',
						}}
					/>
					{renderFormDatas()}
				</div>
			</>
		)
	}

	return (
		<ExpansionList>
			<ExpansionPanel
				label={
					<h4 style={{ margin: 0 }}>
						{intl.formatMessage({
							defaultMessage: 'Roolit',
							description: 'Title of bonds',
						})}
					</h4>
				}
				defaultExpanded={true}
				footer={null}
			>
				<div className="margin-top">{renderChildren()}</div>
			</ExpansionPanel>
		</ExpansionList>
	)
}

export default BondDialog
