import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { Toolbar, Button, Checkbox } from 'react-md'

import * as oiva360Actions from 'state/oiva360-actions'
import * as notifyActions from 'state/notifyActions'
import LoadingState from 'ui/components/LoadingState'
import { injectIntl } from 'react-intl'
import { GENERIC_ERROR_NOTIFICATION } from 'ui/messages'
import WithDialogs from 'ui/components/dialogs/WithDialogs'
import ViiluDialog from 'ui/components/ViiluDialog'

const STEPS = {
	INTRO: 'intro',
	IMPORT_CONFIRM: 'import_confirm',
}

const getInitialState = () => ({
	processing: false,
	step: STEPS.INTRO,
	fetchTenants: false,
	fetchShareholders: false,
	fetchBoardMembers: false,
	fetchManager: false,
})

class ImporterDialogOiva360 extends Component {
	state = getInitialState()

	getMissingManagerIntegrationMessage = () => {
		const { intl } = this.props
		return intl.formatMessage({
			defaultMessage:
				'Tämä toiminto vaatii isännöintitoimiston integraation Oiva 360:een. Ota tarvittaessa yhteys asiakaspalveluumme asiakaspalvelu@vii.lu.',
			description:
				'Message shown in the dialog for importing from Oiva 360 to the message service table if the Oiva 360 integration is not enabled.',
		})
	}

	getMissingCompanyIntegrationMessage = () => {
		const { intl } = this.props
		return intl.formatMessage({
			defaultMessage:
				'Tämä toiminto vaatii kohteen integraation Oiva 360:een. Ota tarvittaessa yhteys asiakaspalveluumme asiakaspalvelu@vii.lu.',
			description:
				'Message shown in the dialog for importing from Oiva 360 to the message service table if the Oiva 360 integration is not enabled.',
		})
	}

	getMissingBusinessIdMessage = () => {
		const { intl } = this.props
		return intl.formatMessage({
			defaultMessage:
				'Tämä tominto vaatii Y-tunnuksen syöttämistä talosivun asetuksiin.',
			description:
				'Message shown for missing business id in the dialog for importing from Oiva 360 to the message service table.',
		})
	}

	oiva360Import = async () => {
		const { fetchTenants, fetchShareholders, fetchBoardMembers, fetchManager } =
			this.state
		const { _showInfoNotification, intl, company, onSuccess, alert } =
			this.props

		const managerUUID = company.managerUUID
		const businessId = company.businessId
		if (!managerUUID) {
			await alert(this.getMissingManagerIntegrationMessage())
			return false
		}
		if (!businessId) {
			await alert(this.getMissingBusinessIdMessage())
			return false
		}

		let oiva360Enabled = false
		if (
			company &&
			company.integrations &&
			company.integrations.oiva360 &&
			company.integrations.oiva360.id
		) {
			oiva360Enabled = true
		}
		if (!oiva360Enabled) {
			await alert(this.getMissingCompanyIntegrationMessage())
			return false
		}

		this.setState({ processing: true })

		const result = await oiva360Actions._oiva360CompanyUsers(
			company.integrations.oiva360.id,
		)
		if (!result || !result.data) {
			_showInfoNotification('❌ ' + GENERIC_ERROR_NOTIFICATION(intl))
			return false
		}

		this.setState({ processing: false })

		if (!result || !result.data || !result.data.success || !result.data.users) {
			return
		}

		const invitees = result.data.users
			.map((u) => ({
				displayName: u.name || '',
				name: u.name || '',
				email: u.email,
				role: u.role,
				uuid: u.uuid,
				apartments: [],
			}))
			.filter((u) => {
				if (u.role === 'user_tenant' && !fetchTenants) {
					return false
				}
				if (u.role === 'user_shareholder' && !fetchShareholders) {
					return false
				}
				if (u.role.includes('admin_board') && !fetchBoardMembers) {
					return false
				}
				if (u.role === 'user_manager' && !fetchManager) {
					return false
				}
				return true
			})

		const uniqueInviteesMap = {}
		invitees.forEach((i) => (uniqueInviteesMap[i.uuid] = i))
		const uniqueInvitees = Object.values(uniqueInviteesMap)

		onSuccess(uniqueInvitees)
		this.onHide()
		return true
	}

	onHide = () => {
		this.setState(getInitialState())
		this.props.onHide()
	}

	renderToolbar = (dialogTitle) => {
		return (
			<Toolbar
				colored
				title={dialogTitle}
				style={{
					background: 'var(--color-secondary-dark)',
					position: 'fixed',
					width: '100%',
					zIndex: 2,
				}}
				nav={
					<Button icon onClick={this.onHide}>
						close
					</Button>
				}
			/>
		)
	}

	renderStep = () => {
		const {
			step,
			fetchTenants,
			fetchShareholders,
			fetchBoardMembers,
			fetchManager,
		} = this.state
		const { company, intl } = this.props

		if (step === STEPS.INTRO) {
			return (
				<>
					<div className="flex-column">
						<p>
							{intl.formatMessage(
								{
									defaultMessage:
										'Voit tuoda hallituksen jäsenet, osakkaat ja/tai asukkaat vastaanottajiksi Oiva 360:stä (Käytännössä sama tieto kuin Hausvisessa). Huomioithan, että emme saa Oiva 360:stä postiosoitteita emmekä sähköisen osakaspostituksen suostumuksia. Käytäthän HTJ-tuontia yhtiökokouskutsujen lähettämiseen.',
									description:
										'Instructional paragraph in the dialog for importing from Oiva 360 to the message service table.',
								},
								{ businessId: <strong>{company.businessId}</strong> },
							)}
						</p>
						<Checkbox
							id="oiva360-imported-tenant"
							label={<p style={{ margin: 0 }}>Asukkaat</p>}
							name="Asukkaat"
							checked={fetchTenants}
							onChange={() => {}}
							onClick={() =>
								this.setState({ fetchTenants: !this.state.fetchTenants })
							}
							style={{ marginTop: 32 }}
						/>
						<Checkbox
							id="oiva360-imported-shareholder"
							label={<p style={{ margin: 0 }}>Osakkaat</p>}
							name="Osakkaat"
							checked={fetchShareholders}
							onChange={() => {}}
							onClick={() =>
								this.setState({
									fetchShareholders: !this.state.fetchShareholders,
								})
							}
						/>
						<Checkbox
							id="oiva360-imported-boardmembers"
							label={<p style={{ margin: 0 }}>Hallituksen jäsenet</p>}
							name="Hallituksen jäsenet"
							checked={fetchBoardMembers}
							onChange={() => {}}
							onClick={() =>
								this.setState({
									fetchBoardMembers: !this.state.fetchBoardMembers,
								})
							}
						/>
						<Checkbox
							id="oiva360-imported-manager"
							label={<p style={{ margin: 0 }}>Isännöitsijä</p>}
							name="Isännöitsijä"
							checked={fetchManager}
							onChange={() => {}}
							onClick={() =>
								this.setState({
									fetchManager: !this.state.fetchManager,
								})
							}
						/>
						<div
							className="flex-row margin-top"
							style={{ justifyContent: 'flex-end' }}
						>
							<Button
								raised
								secondary
								onClick={() => {
									this.setState({ step: STEPS.IMPORT_CONFIRM })
								}}
								disabled={
									!fetchTenants &&
									!fetchShareholders &&
									!fetchBoardMembers &&
									!fetchManager
								}
							>
								{intl.formatMessage({
									defaultMessage: 'Seuraava',
									description:
										'Label for the button that proceeds to the next step in the dialog for importing from Oiva 360 to the message service table.',
								})}
							</Button>
						</div>
					</div>
				</>
			)
		} else {
			return (
				<>
					<div className="flex-column">
						<p>
							{intl.formatMessage(
								{
									defaultMessage:
										'Tiedot haetaan kohteen Oiva 360-tunnuksen perusteella painettuasia TUO TIEDOT -painiketta.',
									description:
										'Instructional paragraph in the dialog for importing from Oiva 360 to the message service table.',
								},
								{ businessId: <strong>{company.businessId}</strong> },
							)}
						</p>
						<div
							className="flex-row margin-top"
							style={{ justifyContent: 'flex-end' }}
						>
							<Button raised secondary onClick={this.oiva360Import}>
								{intl.formatMessage({
									defaultMessage: 'Tuo tiedot',
									description:
										'Label for the button that imports from Oiva 360 to the message service table.',
								})}
							</Button>
						</div>
					</div>
				</>
			)
		}
	}

	render() {
		const { company, intl } = this.props
		const { processing } = this.state

		const dialogTitle = intl.formatMessage({
			defaultMessage: 'Tuo Oiva 360 henkilörekisteristä',
			description:
				'Title of the dialog for importing invitees from Oiva 360 to the message service table.',
		})

		return (
			<ViiluDialog
				id="responsive-dialog"
				aria-label={dialogTitle}
				visible={true}
				onHide={this.onHide}
				focusOnMount={true}
				containFocus={true}
				dialogClassName="responsive-dialog"
				contentClassName="responsive-dialog-content"
				autosizeContent={false}
				disableScrollLocking
				modal
				paddedContent
				onClick={(e) => {
					e.preventDefault()
					e.stopPropagation()
				}}
				style={{ zIndex: 5000 }}
			>
				{this.renderToolbar(dialogTitle)}
				<section className="md-toolbar-relative" style={{ padding: 24 }}>
					{processing ? (
						<div className="flex-column flex-center">
							<LoadingState />
							<p>Odotetaan Oiva 360:stä...</p>
						</div>
					) : company.managerUUID ? (
						company.businessId ? (
							this.renderStep()
						) : (
							<p>{this.getMissingBusinessIdMessage()}</p>
						)
					) : (
						<p>{this.getMissingManagerIntegrationMessage()}</p>
					)}
				</section>
			</ViiluDialog>
		)
	}
}

ImporterDialogOiva360.propTypes = {
	onHide: PropTypes.func.isRequired,
	onSuccess: PropTypes.func.isRequired,
	company: PropTypes.object.isRequired,
}

const mapState = () => ({})

const mapDispatchToProps = (dispatch) => ({
	_showInfoNotification: bindActionCreators(notifyActions.info, dispatch),
})

export default compose(
	injectIntl,
	WithDialogs,
	connect(mapState, mapDispatchToProps),
)(ImporterDialogOiva360)
