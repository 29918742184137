import { injectIntl } from 'react-intl'
import { FontIcon } from 'react-md'
import { compose } from 'redux'
import {
	SummaryList,
	SummaryListItem,
} from 'ui/MessageService/MessageServiceProcess/steps/Sending/steps/Summary/styles'

function RecipientMethodsCount({ recipientMethodsCount, intl }) {
	if (!recipientMethodsCount) {
		return null
	}

	const emailCount = recipientMethodsCount.email
	const letterCount = recipientMethodsCount.letter
	const smsCount = recipientMethodsCount.sms

	const show = (count) => {
		return count > 0
	}

	return (
		<>
			<SummaryList>
				{show(letterCount) && (
					<SummaryListItem>
						<FontIcon>email</FontIcon>
						<span>
							{letterCount === 1
								? intl.formatMessage(
										{
											defaultMessage: '{letterCount} kirje',
											description:
												'Singular version of the letter count label in the message service summary step.',
										},
										{
											letterCount,
										},
								  )
								: intl.formatMessage(
										{
											defaultMessage: '{letterCount} kirjettä',
											description:
												'Plural version of the letter count label in the message service summary step.',
										},
										{
											letterCount,
										},
								  )}
						</span>
					</SummaryListItem>
				)}
				{show(emailCount) && (
					<SummaryListItem>
						<FontIcon>alternate_email</FontIcon>
						<span>
							{emailCount === 1
								? intl.formatMessage(
										{
											defaultMessage: '{recipientsCount} sähköposti',
											description:
												'Singular version of the email recipients count in the message service summary step.',
										},
										{
											recipientsCount: emailCount,
										},
								  )
								: intl.formatMessage(
										{
											defaultMessage: '{recipientsCount} sähköpostia',
											description:
												'Plural version of the email recipients count in the message service summary step.',
										},
										{
											recipientsCount: emailCount,
										},
								  )}
						</span>
					</SummaryListItem>
				)}
				{show(smsCount) && (
					<SummaryListItem>
						<FontIcon>sms</FontIcon>
						<span>
							{smsCount === 1
								? intl.formatMessage(
										{
											defaultMessage: '{recipientsCount} tekstiviesti',
											description:
												'Singular version of the sms recipients count in the message service summary step.',
										},
										{
											recipientsCount: smsCount,
										},
								  )
								: intl.formatMessage(
										{
											defaultMessage: '{recipientsCount} tekstiviestiä',
											description:
												'Plural version of the sms recipients count in the message service summary step.',
										},
										{
											recipientsCount: smsCount,
										},
								  )}
						</span>
					</SummaryListItem>
				)}
			</SummaryList>
		</>
	)
}

export default compose(injectIntl)(RecipientMethodsCount)
