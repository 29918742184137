import { useEffect, useState } from 'react'
import { collection, query, where, onSnapshot } from 'firebase/firestore'
import { firestore } from 'state/store'
import naturalSort from 'javascript-natural-sort'
import type { Apartment } from 'types/apartment'

function useCompanyApartments(selectedCompanyUUID: string) {
	const [loadingCompanyApartments, setLoading] = useState<boolean>(true)
	const [companyApartments, setCompanyApartments] = useState<Apartment[]>([])

	useEffect(() => {
		if (!selectedCompanyUUID) {
			console.error('Company not selected.')
			return
		}

		const getApartments = () => {
			const q = query(
				collection(firestore, 'apartment'),
				where('companyUUID', '==', selectedCompanyUUID),
			)
			const unsubscribe = onSnapshot(
				q,
				(querySnapshot) => {
					const data = querySnapshot.docs
						.map((doc) => doc.data() as Apartment)
						.sort((a, b) => naturalSort(a.identifier, b.identifier))
					setCompanyApartments(data)
					setLoading(false)
				},
				(error) => {
					console.error(error)
				},
			)

			return unsubscribe
		}

		return getApartments()
	}, [selectedCompanyUUID])

	return { companyApartments, loadingCompanyApartments }
}

export default useCompanyApartments
