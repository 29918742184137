import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { CardTitle, CardActions, CardText, Button } from 'react-md'

import * as notifyActions from 'state/notifyActions'
import { _apiAppCreate } from 'state/api-actions'

import WithSelectedManagerRole from 'ui/components/WithSelectedManagerRole'
import WithUserRoles from 'ui/components/userRoles/WithUserRoles'
import { StandardCard } from 'ui/StyledComponents/Cards'
import { firestoreConnect, isEmpty, isLoaded } from 'react-redux-firebase'
import LoadingState from 'ui/components/LoadingState'
import WithDialogs from 'ui/components/dialogs/WithDialogs'

class ViiluAPI extends Component {
	state = {
		showSecrets: false,
		processing: false,
	}

	isMaster = () => {
		const { managerRole, isSuperAdmin } = this.props
		return managerRole === 'manager_master' || isSuperAdmin
	}

	apiAppCreate = async () => {
		const { selectedManager, prompt, alert } = this.props
		const adminUID = await prompt(
			'Syötä teknisen pääkäyttäjän UID. Vain tällä henkilöllä on oikeus hakea avaimet palvelusta.',
		)
		if (!adminUID) {
			return
		}

		this.setState({ processing: true })
		const result = await _apiAppCreate(selectedManager.uuid, adminUID)
		this.setState({ processing: false })
		if (!result || result.success === false) {
			alert('Tapahtui virhe')
		}
	}

	render() {
		const { apiApps, isSuperAdmin } = this.props
		const { showSecrets, processing } = this.state

		if (!isLoaded(apiApps) || processing) {
			return <LoadingState />
		}

		if (isEmpty(apiApps)) {
			return (
				<div className="full-width">
					<StandardCard className="margin-bottom full-width">
						<CardTitle title={'Viilu-rajapinta'} />
						<CardText>
							Voit ottaa Viilu-rajapinnan käyttöön, joka mahdollistaa
							esimerkiksi kokousten lukemisen ja luomisen rajapinnan yli. Ota
							yhteyttä asiakaspalveluumme mikäli olet kiinnostunut rajapinnasta.
						</CardText>
						{isSuperAdmin ? (
							<CardActions>
								<Button
									flat
									secondary
									onClick={() => this.apiAppCreate()}
									disabled={!isSuperAdmin}
									style={{ color: 'var(--color-secondary)' }}
								>
									{'Luo avaimet'}
								</Button>
							</CardActions>
						) : null}
					</StandardCard>
				</div>
			)
		}

		return (
			<div className="full-width">
				<StandardCard
					className="margin-bottom full-width"
					style={{
						background:
							apiApps && apiApps.length ? 'rgb(240,255,240)' : undefined,
					}}
				>
					<CardTitle title={'Viilu-rajapinta'} />
					<CardText>
						Voit ottaa Viilu-rajapinnan käyttöön, joka mahdollistaa esimerkiksi
						kokousten lukemisen ja luomisen rajapinnan yli.
					</CardText>
					{apiApps && apiApps.length ? (
						<CardText>
							{apiApps.map((app) => (
								<div className="flex-column">
									<strong>{app.name}</strong>
									<p>{app.uuid}</p>
									<p>
										{showSecrets
											? app.secret
											: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'}
									</p>
								</div>
							))}
						</CardText>
					) : null}
					<CardActions>
						<Button
							flat
							secondary
							onClick={() => this.setState({ showSecrets: !showSecrets })}
							disabled={!this.isMaster()}
							style={{ color: 'var(--color-secondary)' }}
						>
							{showSecrets ? 'Piilota avaimet' : 'Näytä avaimet'}
						</Button>
					</CardActions>
				</StandardCard>
			</div>
		)
	}
}

ViiluAPI.propTypes = {}

const mapState = ({
	firebaseReducer: { auth },
	firestoreReducer: {
		ordered: { api_app_manager },
	},
}) => ({
	auth,
	apiApps: api_app_manager,
})

const mapDispatchToProps = (dispatch) => ({
	_showInfoNotification: bindActionCreators(notifyActions.info, dispatch),
})

export default compose(
	WithSelectedManagerRole,
	WithUserRoles,
	WithDialogs,
	connect(({ firebaseReducer: { auth } }) => ({ auth })),
	firestoreConnect(({ selectedManager, auth, isSuperAdmin }) => {
		if (isSuperAdmin) {
			return [
				{
					collection: 'api-app',
					storeAs: 'api_app_manager',
					where: [
						[
							'managerUUID',
							'==',
							selectedManager ? selectedManager.uuid : 'empty-value',
						],
					],
				},
			]
		}

		return [
			{
				collection: 'api-app',
				storeAs: 'api_app_manager',
				where: [
					[
						'managerUUID',
						'==',
						selectedManager ? selectedManager.uuid : 'empty-value',
					],
					['adminUID', '==', auth ? auth.uid : 'empty-value'],
				],
			},
		]
	}),
	connect(mapState, mapDispatchToProps),
)(ViiluAPI)
