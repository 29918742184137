import { TableRow, TableColumn, MenuButton, CircularProgress } from 'react-md'
import { bondHeaders } from './constants'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import type { Bond } from './types'
import moment from 'moment'
import { bondNameMessage } from '../utils/messages'
import { useIntl } from 'react-intl'

interface Props {
	bond: Bond
	menuItems: any[] | null
	children: any
	processing: any
}

const BondsRow = ({ bond, menuItems, children, processing }: Props) => {
	const intl = useIntl()
	const dispatch = useDispatch()
	const appendedMenuItems = menuItems ? [...menuItems] : null

	const menu = !appendedMenuItems ? null : (
		<MenuButton
			id={'menu-button'}
			icon
			menuItems={appendedMenuItems}
			centered
			anchor={{
				x: MenuButton.HorizontalAnchors['CENTER'],
				y: MenuButton.VerticalAnchors['CENTER'],
			}}
		>
			more_vert
		</MenuButton>
	)
	const getRowValue = (key: string, type: string | undefined) => {
		const value = bond[key]
		if (type && type === 'date') {
			const parsedDate = moment(value)
			if (!parsedDate.isValid()) return ''
			return parsedDate.format('DD.MM.YYYY')
		}
		if (type && type === 'enum') {
			return bondNameMessage(intl, value)
		}
		return value
	}

	const handleOnClick = () => {
		dispatch(push(window.location.pathname + '?ruuid=' + bond.uuid))
	}

	const renderTableColumns = () => {
		return bondHeaders
			.filter((h) => h.show)
			.map((header) => {
				return (
					<TableColumn onClick={handleOnClick} key={header.key}>
						<p>{getRowValue(header.key, header.type)}</p>
					</TableColumn>
				)
			})
	}

	return (
		<TableRow>
			{renderTableColumns()}
			<TableColumn>
				<div>
					{processing ? (
						<CircularProgress id="progress" style={{ marginTop: 0 }} />
					) : (
						menu
					)}
					{children}
				</div>
			</TableColumn>
		</TableRow>
	)
}

export default BondsRow
