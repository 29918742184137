import styled from 'styled-components'

export const DrawerLogoContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: row;
	padding-bottom: 16px;
	padding-left: 16px;
	padding-top: 22px;
	height: 56px;
`

export const DrawerMenuItemText = styled.p`
	font-weight: 700;
	color: white;
	font-size: var(--text-base-size);
`

export const DrawerMenuItemSubtitle = styled.p`
	font-weight: 100;
	color: white;
	font-size: var(--text-base-size);
`

export const DrawerMenuScrollContainer = styled.div`
	overflow-x: hidden;
	overflow-y: auto;
	height: 100%;
`

export const DrawerMenuLogo = styled.div`
	width: 24px;
	height: 24px;
	margin-right: 16px;
	background-color: #aa32bf;
	/* IE9, iOS 3.2+ */
	background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHZpZXdCb3g9IjAgMCAxIDEiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiPjxsaW5lYXJHcmFkaWVudCBpZD0idnNnZyIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiIHgxPSIwJSIgeTE9IjAlIiB4Mj0iMTAwJSIgeTI9IjEwMCUiPjxzdG9wIHN0b3AtY29sb3I9IiMzZjk1ZWEiIHN0b3Atb3BhY2l0eT0iMSIgb2Zmc2V0PSIwIi8+PHN0b3Agc3RvcC1jb2xvcj0iIzUyZDNhYSIgc3RvcC1vcGFjaXR5PSIxIiBvZmZzZXQ9IjEiLz48L2xpbmVhckdyYWRpZW50PjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxIiBoZWlnaHQ9IjEiIGZpbGw9InVybCgjdnNnZykiIC8+PC9zdmc+);
	background-image: -webkit-gradient(
		linear,
		0% 0%,
		100% 100%,
		color-stop(0, #3f95ea),
		color-stop(1, #aa32bf)
	);
	/* Android 2.3 */
	background-image: -webkit-repeating-linear-gradient(
		top left,
		#3f95ea 0%,
		#aa32bf 100%
	);
	/* IE10+ */
	background-image: repeating-linear-gradient(
		to bottom right,
		#3f95ea 0%,
		#aa32bf 100%
	);
	background-image: -ms-repeating-linear-gradient(
		top left,
		#3f95ea 0%,
		#aa32bf 100%
	);
	border-radius: 24px;
	margin-left: -4px;
	margin-top: 4px;
`
