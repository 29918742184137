import {
	TextField,
	CardTitle,
	CardText,
	TextFieldTypes,
	Checkbox,
	SelectField,
	Radio,
} from 'react-md'
import { useIntl } from 'react-intl'
import { StandardCard } from 'ui/StyledComponents/Cards'
import { taxHeaders, taxPaymentInformationHeaders } from './constants'
import type { Header, Settings } from './types'
import type { SelectOptions } from '../types'
import styled from 'styled-components'
import FloatingActionBtn from 'ui/components/FloatingActionBtn'
import LoadingState from 'ui/components/LoadingState'
import DatePickerISO from 'ui/components/DatePickerISO'
import { Fragment } from 'react'
import { TaxPeriodLength } from '../enum'
import { taxPeriodLengthMessage } from '../utils/messages'

export const TaxesContainer = styled.div`
	display: flex;
	flex-direction: column;
	flex-shrink: 0;
	flex-grow: 1;
	align-items: center;
	justify-content: flex-start;
	padding-top: 24px;
	padding-bottom: 24px;
`

const Label = styled.label`
	font-weight: 500;
	font-size: 1.1rem;
	margin-top: 16px;
	display: inline-block;
`

type Props = {
	settings: Settings
	setSettings: React.Dispatch<React.SetStateAction<Settings>>
	processing: boolean
	saveSettings: () => void
	loading: boolean
}

function Taxes({
	settings,
	setSettings,
	processing,
	saveSettings,
	loading,
}: Props) {
	const intl = useIntl()

	const taxPeriodLengthOptions: SelectOptions[] = Object.values(
		TaxPeriodLength,
	).map((value) => ({
		label: taxPeriodLengthMessage(intl, value),
		value: value,
	}))

	const handleInputChange = (
		value: string | number | boolean,
		field: string,
	) => {
		setSettings((prevData) => ({
			...prevData,
			[field]: value,
		}))
	}
	const renderTextField = (headers: Header[]) => {
		return headers.map((header: Header) => {
			const { title, key, type, editable } = header
			if (type === 'boolean') {
				return (
					<Checkbox
						key={key}
						id={key}
						name={key}
						label={title(intl)}
						value={settings[key] || ''}
						onChange={(value) => handleInputChange(value, key)}
						checked={settings[key] || false}
						style={{ marginLeft: -10 }}
					/>
				)
			} else if (type === 'date') {
				return (
					<DatePickerISO
						style={{ width: '100%' }}
						key={key}
						id={key}
						label={title(intl)}
						value={settings[key]}
						onChange={(value) => handleInputChange(value, key)}
					/>
				)
			} else if (key === 'accountingMethod') {
				return (
					<Fragment key={key}>
						<Label>{title(intl)}</Label>
						<Radio
							key="radio-net"
							id="radio-net"
							name="radios"
							label={intl.formatMessage({
								defaultMessage: 'Netto',
								description: 'Net',
							})}
							checked={settings[key] === 'net'}
							value={settings[key]?.toString()}
							onChange={() => handleInputChange('net', key)}
						/>
						<Radio
							key="radio-gross"
							id="radio-gross"
							name="radios"
							label={intl.formatMessage({
								defaultMessage: 'Brutto',
								description: 'Gross',
							})}
							checked={settings[key] === 'gross'}
							value={settings[key]?.toString()}
							onChange={() => handleInputChange('gross', key)}
						/>
					</Fragment>
				)
			} else if (key === 'taxPeriodLength') {
				return (
					<div key={key} style={{ marginTop: '15px' }}>
						<p className="text-subtle">{title(intl)}</p>
						<SelectField
							id={key}
							key={key}
							placeholder={title(intl)}
							value={settings[key] || ''}
							onChange={(value) => handleInputChange(value, key)}
							menuItems={taxPeriodLengthOptions}
							position={SelectField.Positions.BELOW}
							simplifiedMenu={false}
							listHeightRestricted
							style={{
								width: '100%',
								background: '#fafafa',
								marginBottom: '10px',
							}}
						/>
					</div>
				)
			} else {
				return (
					<TextField
						key={key}
						id={key}
						label={title(intl)}
						lineDirection="center"
						fullWidth
						value={settings[key] || ''}
						onChange={(value) => handleInputChange(value, key)}
						disabled={!editable}
						required={false}
						type={(type as TextFieldTypes) || undefined}
					/>
				)
			}
		})
	}

	const invoicingInformation = () => {
		return (
			<StandardCard
				className="margin-bottom"
				style={{ maxWidth: 768 }}
				key="vat"
			>
				<CardTitle
					key="vat-title"
					title={intl.formatMessage({
						defaultMessage: 'Arvonlisäverotus',
						description: 'VAT',
					})}
				/>
				<CardText>{renderTextField(taxHeaders)}</CardText>
			</StandardCard>
		)
	}

	const invoicingAdditionalInformation = () => {
		return (
			<StandardCard
				className="margin-bottom"
				style={{ maxWidth: 768 }}
				key="payment-information"
			>
				<CardTitle
					key="payment-information-title"
					title={intl.formatMessage({
						defaultMessage: 'Maksutiedot',
						description: 'Payment Information',
					})}
				/>
				<CardText>{renderTextField(taxPaymentInformationHeaders)}</CardText>
			</StandardCard>
		)
	}

	const renderFAB = () => {
		return (
			<FloatingActionBtn
				iconName={processing ? '' : 'save'}
				processing={processing}
				text={
					processing
						? intl.formatMessage({
								defaultMessage: 'Tallennetaan',
								description: 'Saving',
						  })
						: intl.formatMessage({
								defaultMessage: 'Tallenna',
								description: 'Save',
						  })
				}
				action={() => saveSettings()}
			/>
		)
	}

	if (loading) {
		return <LoadingState />
	}

	return (
		<TaxesContainer>
			<div className="flex-column" style={{ minWidth: '50vw' }}>
				{invoicingInformation()}
				{invoicingAdditionalInformation()}
			</div>
			{renderFAB()}
		</TaxesContainer>
	)
}

export default Taxes
