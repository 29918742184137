import { injectIntl } from 'react-intl'
import { FontIcon } from 'react-md'
import { compose } from 'redux'
import { SummaryList, SummaryListItem } from './styles'

function EmailSummary({ emailCount, intl }) {
	return (
		<>
			<h2>
				{intl.formatMessage({
					defaultMessage: 'Sähköpostit (maksuton)',
					description:
						'Heading for the email section in the message service summary step.',
				})}
			</h2>
			{emailCount > 0 && (
				<SummaryList>
					<SummaryListItem>
						<FontIcon>email</FontIcon>
						<span>
							{emailCount === 1
								? intl.formatMessage(
										{
											defaultMessage: '{recipientsCount} sähköposti',
											description:
												'Singular version of the email recipients count in the message service summary step.',
										},
										{
											recipientsCount: emailCount,
										},
								  )
								: intl.formatMessage(
										{
											defaultMessage: '{recipientsCount} sähköpostia',
											description:
												'Plural version of the email recipients count in the message service summary step.',
										},
										{
											recipientsCount: emailCount,
										},
								  )}
						</span>
					</SummaryListItem>
				</SummaryList>
			)}
			<p>
				{intl.formatMessage({
					defaultMessage:
						'Sähköpostit lähetetään heti vahvistettuasi lähetyksen.',
					description:
						'Informational text about the email send process in the message service summary step.',
				})}
			</p>
		</>
	)
}

export default compose(injectIntl)(EmailSummary)
