const getSetting = (company: any, feature: string, settingName: string) => {
	if (
		!company ||
		!company.featureSettings ||
		!company.featureSettings[feature]
	) {
		return null
	}
	const found = company.featureSettings[feature].find(
		(settingObject) => settingObject.name === settingName,
	)
	if (!found) {
		return null
	}
	return found.value
}

const getManagerSetting = (
	manager: any,
	feature: string,
	settingName: string,
) => {
	if (
		!manager ||
		!manager.featureSettingsManager ||
		!manager.featureSettingsManager[feature]
	) {
		return null
	}
	const found = manager.featureSettingsManager[feature].find(
		(settingObject) => settingObject.name === settingName,
	)
	if (!found) {
		return null
	}
	return found.value
}

export { getSetting, getManagerSetting }
