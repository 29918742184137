import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { Button, FontIcon } from 'react-md'
import { FormattedMessage, injectIntl } from 'react-intl'
import styled from 'styled-components'

const CenteredText = styled.p`
	text-align: center;
`
const WarningIcon = styled(FontIcon)`
	font-size: ${({ $isDesktop }) =>
		$isDesktop ? '128px !important' : '96px !important'};
	color: var(--color-warning);
	margin-bottom: 16px;
	opacity: 0.87;
	&:after {
		content: 'warning';
	}
`

const LoginDialogEmailSignUpBlocked = ({ onBack, intl, isDesktop }) => {
	return (
		<div className="flex-column flex-center">
			<WarningIcon $isDesktop={isDesktop} />
			<CenteredText>
				<FormattedMessage
					defaultMessage="Tällä sähköpostiosoitteella ei löytynyt tunnusta. Tarkistathan sähköpostiosoitteen oikeinkirjoituksen."
					description="Error message telling the user that there is no account with such email address"
				/>
			</CenteredText>
			<Button
				raised
				secondary
				onClick={onBack}
				className="margin-top--xl"
				style={{
					alignSelf: 'center',
					background: 'var(--color-primary)',
					color: 'white',
				}}
			>
				<FormattedMessage
					defaultMessage="Takaisin"
					description="Button that allows the user to go backwards in the login process."
				/>
			</Button>
		</div>
	)
}

LoginDialogEmailSignUpBlocked.propTypes = {
	onBack: PropTypes.func.isRequired,
}

const mapState = ({ dimensions: { isDesktop } }) => ({ isDesktop })

const mapDispatchToProps = (dispatch) => ({
	_push: bindActionCreators(push, dispatch),
})

export default compose(
	injectIntl,
	connect(mapState, mapDispatchToProps),
)(LoginDialogEmailSignUpBlocked)
