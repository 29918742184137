import { TableColumn, TableRow } from 'react-md'
import type { AccountLedgerEntry } from './types'
import { accountLedgerEntryHeaders } from './constants'
import moment from 'moment'
import { useIntl } from 'react-intl'
import { invoiceStatusMessage } from '../utils/messages'
import { push } from 'connected-react-router'
import { useDispatch } from 'react-redux'

type Props = {
	accountLedgerEntry: AccountLedgerEntry
}

const AccountLedgerEntryRow = ({ accountLedgerEntry }: Props) => {
	const intl = useIntl()
	const dispatch = useDispatch()

	const getRowValue = (key: string, type: string | undefined) => {
		let value = accountLedgerEntry[key]
		const entryType = accountLedgerEntry['type']
		if (type === 'date') {
			return moment(value).format('DD.MM.YYYY')
		} else if (key === 'sum') {
			return `${value} €`
		} else if (key === 'type') {
			const res = {
				invoice: intl.formatMessage({
					defaultMessage: 'Lasku',
					description: 'Account ledger entry type "invoice"',
				}),
				payment: intl.formatMessage({
					defaultMessage: 'Suoritus',
					description: 'Account ledger entry type "payment"',
				}),
				openingBalance: intl.formatMessage({
					defaultMessage: 'Alkusaldo',
					description: 'Account ledger entry type "openingBalance"',
				}),
			}[value]
			if (!res) {
				console.error('No translation for accountLedgerEntryRow.type:', value)
				return value
			}
			return res
		} else if (key === 'status') {
			if (entryType === 'invoice') {
				const sum = Number(accountLedgerEntry['sum'])
				const allocated = Number(accountLedgerEntry['allocated'])
				const outstanding = Number((sum - allocated).toFixed(2))
				if (outstanding > 0) {
					return {
						value: intl.formatMessage(
							{
								defaultMessage: 'Maksamatta {outstanding} €',
								description: 'Unpaid {outstanding} €',
							},
							{
								outstanding,
							},
						),
						color: 'red',
					}
				}
				if (outstanding === 0) {
					return {
						value: intl.formatMessage(
							{
								defaultMessage: 'Maksettu',
								description: 'Paid',
							},
							{
								outstanding,
							},
						),
						color: 'black',
					}
				}
				return invoiceStatusMessage(intl, value)
			} else if (entryType === 'payment') {
				const sum = Number(accountLedgerEntry.sum)
				const allocated = Number(accountLedgerEntry.allocated)
				const outstanding = Number((sum - allocated).toFixed(2))
				if (outstanding !== 0) {
					return {
						value: intl.formatMessage(
							{
								defaultMessage: 'Avoinna {outstanding} €',
								description: 'Open balance {outstanding} €',
							},
							{ outstanding },
						),
						color: 'green',
					}
				}
				const res = {
					matched: intl.formatMessage({
						defaultMessage: 'Kohdistettu',
						description: 'Account ledger entry status "matched"',
					}),
					notMatched: intl.formatMessage({
						defaultMessage: 'Kohdistamatta',
						description: 'Account ledger entry status "notMatched"',
					}),
				}[value]
				if (res === undefined) {
					console.error(
						'No translation for accountLedgerEntryRow.status:',
						value,
					)
					return value
				}
				return res
			}
		}
		return value
	}

	const handleOnClick = () => {
		const { type, uuid } = accountLedgerEntry
		const URLParams = new URLSearchParams(window.location.search)
		if (type === 'invoice') {
			URLParams.append('invoice_uuid', uuid)
		}
		if (type === 'payment') {
			URLParams.append('payment_uuid', uuid)
		}
		dispatch(push('?' + URLParams.toString()))
	}

	const renderTableColumns = () => {
		return accountLedgerEntryHeaders
			.filter((h) => h.show)
			.map((header) => {
				let value = getRowValue(header.key, header?.type)
				let color = undefined
				if (typeof value === 'object') {
					color = value.color
					value = value.value
				}
				return (
					<TableColumn onClick={handleOnClick} key={header.key}>
						<p style={{ color }}>{value}</p>
					</TableColumn>
				)
			})
	}
	return (
		<TableRow key={accountLedgerEntry.uuid}>{renderTableColumns()}</TableRow>
	)
}

export default AccountLedgerEntryRow
