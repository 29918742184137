import { useCallback, useEffect, useState } from 'react'
import { _getBonds } from 'state/finance-party-actions'
import type { BondFilters, Bond } from './types'
import naturalSort from 'javascript-natural-sort'

function useBonds(filters: BondFilters) {
	const [bonds, setBonds] = useState<Bond[]>([])

	const [loadingBonds, setLoading] = useState<boolean>(false)
	const [errorBonds, setError] = useState<string | null>(null)

	const doQuery = useCallback(async () => {
		setLoading(true)
		setError(null)
		let data: Bond[] = []

		try {
			const response = await _getBonds(filters)

			if (response && response.ok) {
				const res = await response.json()

				if (res.bonds && Array.isArray(res.bonds))
					data = res.bonds.sort((a: Bond, b: Bond) =>
						naturalSort(b.name, a.name),
					)
			} else {
				console.error('Something went wrong.')
				setError('Failed to fetch bonds')
			}
		} catch (err) {
			console.error('Error fetching bonds:', err)
			setError('An error occurred while fetching bonds.')
		}
		setBonds(data)
		setLoading(false)
	}, [filters])
	useEffect(() => {
		doQuery()
	}, [doQuery])

	return {
		bonds,
		loadingBonds,
		errorBonds,
		refreshBonds: doQuery,
	}
}

export default useBonds
