import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { bindActionCreators } from 'redux'
import { firestoreConnect, isEmpty, isLoaded } from 'react-redux-firebase'
import Stepper from 'react-stepper-horizontal'
import ReactGA from 'react-ga4'
import { Button } from 'react-md'
import { injectIntl } from 'react-intl'
import { getAuth, getIdToken } from 'firebase/auth'
import { firebaseApp } from 'state/store'

import * as notifyActions from 'state/notifyActions'
import * as userOnboardingActions from 'state/ui/user-onboarding-ui-actions'
import * as siteActions from 'state/ui/site-ui-actions'
import * as userActions from 'state/user-actions'

import UserOnboardingStepIntro from './UserOnboardingStepIntro'
import UserOnboardingStepName from './UserOnboardingStepName'
import UserOnboardingStepAddress from './UserOnboardingStepAddress'
import UserOnboardingStepEmail from './UserOnboardingStepEmail'
import UserOnboardingStepPhoneNumber from './UserOnboardingStepPhoneNumber'
import UserOnboardingStepFinalize from './UserOnboardingStepFinalize'
import WithQueryParams from 'ui/components/WithQueryParams'
import ShareholderOnboarding from './ShareholderOnboarding'
import WithSelectedCompanyRole from 'ui/components/WithSelectedCompanyRole'
import { GENERIC_ERROR_NOTIFICATION } from '../messages'
import {
	ONBOARDING_BACK_MESSAGE,
	ONBOARDING_CANCEL_MESSAGE,
	ONBOARDING_UPDATE_ERROR,
} from './messages'
import FB_ERRORS from '../../util/firebaseErrors'
import WithDialogs from 'ui/components/dialogs/WithDialogs'
import ViiluDialog from 'ui/components/ViiluDialog'
import config from 'config'

class UserOnboarding extends Component {
	state = {
		processing: false,
	}

	componentDidMount = () => {
		this.populateFields()
	}

	populateFields = () => {
		const { auth, onboardingReducer } = this.props
		if (!onboardingReducer.displayName) {
			if (isEmpty(auth) || !isLoaded(auth)) {
				return window.setTimeout(this.populateFields, 100)
			}
			this.onUpdate({
				displayName: auth.displayName,
				email: auth.email,
				phoneNumber: auth.phoneNumber,
			})
		}
	}

	getSteps = () => {
		return [
			{
				id: 'Intro',
				component: UserOnboardingStepIntro,
			},
			{
				id: 'Nimi',
				component: UserOnboardingStepName,
			},
			{
				id: 'Kotiosoitteesi',
				component: UserOnboardingStepAddress,
			},
			{
				id: 'Sähköposti',
				component: UserOnboardingStepEmail,
			},
			{
				id: 'Puhelinnumero',
				component: UserOnboardingStepPhoneNumber,
			},
			{
				id: 'Valmis',
				component: UserOnboardingStepFinalize,
			},
		]
	}

	onResetProfileOnboarding = () => {
		const { _onUpdate } = this.props
		_onUpdate({
			displayName: '',
			email: '',
			phoneNumber: null,
			tel: null, // legacy field
			address: '',
			zip: '',
			country: 'FI',
			stepIndex: 0,
		})
	}

	onUpdate = (update) => {
		const { _onUpdate } = this.props
		_onUpdate(update)
	}

	onNext = () => {
		const { onboardingReducer, _incrementStepIndex } = this.props
		window.onResetProfileOnboarding = this.onResetProfileOnboarding
		try {
			const steps = this.getSteps()
			const step = steps[onboardingReducer.stepIndex]

			ReactGA.event({
				category: 'onboarding',
				action: 'click',
				label: 'next from ' + step.id,
			})
		} catch (ex) {
			console.log(ex)
		}

		if (onboardingReducer.stepIndex < this.getSteps().length - 1) {
			_incrementStepIndex()
		}
	}

	onDone = async () => {
		const {
			_updateProfile,
			_showInfoNotification,
			_resetStepIndex,
			onboardingReducer,
			onHide,
			selectedCompanyRole,
			_showInviteRequestDialog,
			intl,
			alert,
		} = this.props
		const { displayName, email, phoneNumber, address, zip, area, country } =
			onboardingReducer

		this.setState({ processing: true })
		try {
			const result = await _updateProfile(
				displayName,
				email,
				phoneNumber,
				undefined,
				address,
				zip,
				area,
				country,
			)

			if (!result || result.success === false) {
				if (result?.ex?.code === FB_ERRORS.FUNCTIONS_ALREADY_EXISTS) {
					if (result?.ex?.message && result.ex.message.includes('email')) {
						const message = intl.formatMessage({
							defaultMessage:
								'Tapahtui virhe. Syöttämäsi sähköpostiosoite on jo toisen käyttäjän käytössä. Vinkki: onko mahdollisesti käynyt niin, että olet jo aiemmin luonut tunnuksen tällä sähköpostiosoitteella? Mikäli näin on käynyt suosittelemme sinua kirjautumaan sisään aiemmin luodulla tunnuksella.',
							description:
								'Error message telling the user that the email address is already in use by another user.',
						})
						await alert(message)
					} else if (
						result?.ex?.message &&
						result.ex.message.includes('phoneNumber')
					) {
						const message = intl.formatMessage({
							defaultMessage:
								'Tapahtui virhe. Syöttämäsi puhelinnumero on jo toisen käyttäjän käytössä. Vinkki: onko mahdollisesti käynyt niin, että olet jo aiemmin luonut tunnuksen tällä puhelinnumerolla? Mikäli näin on käynyt suosittelemme sinua kirjautumaan sisään aiemmin luodulla tunnuksella.',
							description:
								'Error message telling the user that the phone number is already in use by another user.',
						})
						await alert(message)
					} else {
						const message = intl.formatMessage({
							defaultMessage:
								'Tapahtui virhe. Syöttämäsi tieto on jo toisen käyttäjän käytössä.',
							description:
								'Error message telling the user that the information is already in use by another user.',
						})
						await alert(message)
					}
				} else {
					const message = intl.formatMessage({
						defaultMessage:
							'Tapahtui virhe. Tarkistathan sähköpostiosoitteen ja puhelinnumeron muodon. Muista maatunnus puhelinnumerossa.',
						description:
							'Error message telling the user that there was an error creating their account.',
					})
					await alert(message)
				}
				_showInfoNotification('❌ ' + ONBOARDING_UPDATE_ERROR(intl))
				return false
			}

			try {
				ReactGA.event({
					category: 'onboarding',
					action: 'complete',
					label: 'onboarding complete',
				})
			} catch (ex) {
				console.log(ex)
			}

			// refresh token to immediately reflect the changes in the UI
			await getIdToken(getAuth(firebaseApp).currentUser, true)
			const successMessage = intl.formatMessage({
				defaultMessage: 'Profiilisi on tallennettu.',
				description:
					'Notification shown to the user when their profile information has been saved.',
			})
			_showInfoNotification('✅ ' + successMessage)
			onHide()
			_resetStepIndex()

			// Show the invite request dialog if the user has no role yet
			if (
				!selectedCompanyRole ||
				selectedCompanyRole === config.userRoles.none
			) {
				_showInviteRequestDialog()
			}

			window.setTimeout(() => window.location.reload(true), 500)
			this.onResetProfileOnboarding()
			return true
		} catch (ex) {
			console.error(ex)
			_showInfoNotification('❌ ' + GENERIC_ERROR_NOTIFICATION(intl))
			return false
		} finally {
			this.setState({ processing: false })
		}
	}

	onBack = () => {
		const { onboardingReducer, _decrementStepIndex, onHide } = this.props

		if (onboardingReducer.stepIndex > 0) {
			_decrementStepIndex()
		} else {
			onHide()
		}
	}

	renderStep = () => {
		const { onboardingReducer } = this.props
		const { processing } = this.state
		const { stepIndex } = onboardingReducer
		const steps = this.getSteps()
		let StepComponent = steps[stepIndex].component

		return (
			<StepComponent
				onUpdate={this.onUpdate}
				onNext={this.onNext}
				onDone={this.onDone}
				onBack={this.onBack}
				processing={processing}
			/>
		)
	}

	render() {
		const steps = this.getSteps()
		const { onboardingReducer, user, visible, onHide, intl } = this.props
		const { stepIndex } = onboardingReducer
		const showCancelBtn = !!user?.displayName && stepIndex === 0
		const showBackBtn = stepIndex > 0

		// We are done so we could try showing the shareholder onboarding next
		if (!visible) {
			return (
				<div>
					<ShareholderOnboarding />
				</div>
			)
		}

		const titleMessage = intl.formatMessage({
			defaultMessage: 'Päivitä profiilisi',
			description: 'Title of the user profile update dialog.',
		})

		return (
			<ViiluDialog
				id="responsive-dialog"
				aria-label={titleMessage}
				visible={visible}
				onHide={onHide}
				focusOnMount={true}
				containFocus={true}
				autosizeContent={false}
				modal
				portal
				fullPage
				onClick={(e) => {
					e.preventDefault()
					e.stopPropagation()
				}}
			>
				<div className="full-height">
					<Stepper
						steps={steps}
						activeStep={stepIndex}
						size={24}
						circleFontSize={0}
						titleFontSize={14}
					/>
					{this.renderStep()}
					{showCancelBtn || showBackBtn ? (
						<Button
							flat
							onClick={this.onBack}
							style={{
								position: 'absolute',
								left: 24,
								bottom: 24,
								background: 'white',
							}}
						>
							{showCancelBtn
								? ONBOARDING_CANCEL_MESSAGE(intl)
								: ONBOARDING_BACK_MESSAGE(intl)}
						</Button>
					) : null}
				</div>
			</ViiluDialog>
		)
	}
}

UserOnboarding.propTypes = {
	visible: PropTypes.bool,
	onHide: PropTypes.func.isRequired,
}

const mapState = ({
	ui: { onboarding: onboardingReducer },
	firebaseReducer: { auth },
	firestoreReducer: {
		ordered: { onboarding_user },
	},
}) => ({
	user: onboarding_user ? onboarding_user[0] : null,
	onboardingReducer,
	auth,
})

const mapDispatchToProps = (dispatch) => ({
	_showInfoNotification: bindActionCreators(notifyActions.info, dispatch),
	_updateProfile: bindActionCreators(userActions._updateProfile, dispatch),
	_onUpdate: bindActionCreators(userOnboardingActions._onUpdate, dispatch),
	_incrementStepIndex: bindActionCreators(
		userOnboardingActions._incrementStepIndex,
		dispatch,
	),
	_decrementStepIndex: bindActionCreators(
		userOnboardingActions._decrementStepIndex,
		dispatch,
	),
	_resetStepIndex: bindActionCreators(
		userOnboardingActions._resetStepIndex,
		dispatch,
	),
	_showInviteRequestDialog: bindActionCreators(
		siteActions._showInviteRequestDialog,
		dispatch,
	),
})

export default compose(
	injectIntl,
	WithDialogs,
	WithQueryParams,
	WithSelectedCompanyRole,
	connect(mapState, mapDispatchToProps),
	firestoreConnect(({ auth }) => {
		return auth?.uid
			? [
					{
						collection: 'user',
						storeAs: 'onboarding_user',
						where: [['uuid', '==', auth.uid]],
						limit: 1,
					},
			  ]
			: []
	}),
)(UserOnboarding)
