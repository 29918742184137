import { push } from 'connected-react-router'
import { Button, Card } from 'react-md'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Footer from 'site/Footer'
import {
	ContentContainer,
	FirstStripe,
	PageContainer,
} from 'site/styledComponents/sharedComponents'
import styled from 'styled-components'
import RouterLink from 'ui/components/RouterLink'

const NotFoundCard = styled(Card)`
	border-radius: 8px;
	min-height: 500px;
	padding: 32px;
	margin-top: 32px;
`

const H1 = styled.h1`
	margin: 0;
	margin-bottom: 16px;
	font-size: 2.3rem;
`

const P = styled.p`
	font-size: 1.5rem;
	line-height: 1.5;
`

const ButtonContainer = styled.div`
	display: flex;
	margin: 24px 0;
`

const HomeButton = styled(Button)`
	font-size: 1.3rem;
	padding: 12px 20px;
	height: auto;
`

const H2 = styled.h2`
	margin: 40px 0 16px 0;
	font-size: 1.8rem;
`

function NotFound({ _push }) {
	return (
		<PageContainer>
			<FirstStripe style={{ height: '100vh' }} />
			<ContentContainer>
				<NotFoundCard>
					<H1>Sivua ei löytynyt</H1>
					<P>
						Hakemaasi sivua ei löytynyt. Sivun osoite on saattanut muuttua tai
						klikkaamasi linkki on ollut virheellinen.
					</P>
					<ButtonContainer>
						<HomeButton raised primary onClick={() => _push('/')}>
							Takaisin etusivulle
						</HomeButton>
					</ButtonContainer>
					<H2>Kiinnostaako Viilu?</H2>
					<P>
						Ota meihin yhteyttä{' '}
						<RouterLink href="/support">asiakaspalvelusivujemme</RouterLink>{' '}
						kautta.
					</P>
				</NotFoundCard>
			</ContentContainer>
			<Footer />
		</PageContainer>
	)
}

const mapDispatchToProps = (dispatch) => ({
	_push: bindActionCreators(push, dispatch),
})

export default connect(null, mapDispatchToProps)(NotFound)
