import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import Layout from 'ui/MessageService/MessageServiceProcess/steps/Sending/steps/Layout'
import DefaultTemplate from 'ui/MessageService/MessageServiceProcess/steps/Sending/steps/SMSPreview/DefaultTemplate'

const Title = styled.h1`
	margin-bottom: 24px;
`

const TemplateContainer = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
`

function SMSPreview({
	stepTitle,
	onPreviousClick,
	onDraftClick,
	onNextClick,
	messageServiceProcess: { title, bodyText, attachments, viewUrl },
}) {
	const left = (
		<>
			<Title>{stepTitle}</Title>
			<p>
				<FormattedMessage
					defaultMessage="Näet tekstiviestin sisällön oikealla. Huomioi, että oikealla on pelkkä esikatselu eikä sen sisältämät linkit johda minnekään."
					description="Informational description of the sms preview step in the message service mass sending component."
				/>
			</p>
		</>
	)

	let right = (
		<TemplateContainer>
			<DefaultTemplate
				title={title}
				bodyText={bodyText}
				viewUrl={viewUrl}
				attachments={attachments}
			/>
		</TemplateContainer>
	)

	return (
		<>
			<Layout
				left={left}
				right={right}
				onPreviousClick={onPreviousClick}
				onDraftClick={onDraftClick}
				onNextClick={onNextClick}
			/>
		</>
	)
}

export default SMSPreview
