import React, { useRef, useState, createContext, useCallback } from 'react'
import AlertDialog from './rootDialogs/AlertDialog'
import ConfirmDialog from './rootDialogs/ConfirmDialog'
import PromptDialog from './rootDialogs/PromptDialog'

const DialogContext = createContext()

function DialogProvider({ children }) {
	const [dialogs, setDialogs] = useState([])
	const resolver = useRef({})

	const showDialog = useCallback((dialog) => {
		const promise = new Promise((resolve, reject) => {
			resolver.current[dialog.key] = { resolve, reject }
		}).catch(() => null)
		setDialogs((prev) => [...prev, dialog])
		return promise
	}, [])

	const resolveDialog = useCallback((key, returnValue) => {
		if (resolver.current[key]) {
			resolver.current[key].resolve(returnValue)
			delete resolver.current[key]
			setDialogs((prev) => prev.filter((d) => d.key !== key))
		}
	}, [])

	const cleanUpDialogs = useCallback((keys) => {
		if (!Array.isArray(keys)) {
			return
		}
		keys.forEach((key) => {
			if (resolver.current[key]) {
				resolver.current[key].reject()
				delete resolver.current[key]
				setDialogs((prev) => prev.filter((d) => d.key !== key))
			}
		})
	}, [])

	const renderDialog = useCallback(
		(dialog) => {
			switch (dialog.type) {
				case 'alert':
					return (
						<AlertDialog
							key={dialog.key}
							dialog={dialog}
							resolveDialog={resolveDialog}
						/>
					)
				case 'confirm':
					return (
						<ConfirmDialog
							key={dialog.key}
							dialog={dialog}
							resolveDialog={resolveDialog}
						/>
					)
				case 'prompt':
					return (
						<PromptDialog
							key={dialog.key}
							dialog={dialog}
							resolveDialog={resolveDialog}
						/>
					)
				default:
					console.log('Unknown dialog type.')
					return null
			}
		},
		[resolveDialog],
	)

	return (
		<DialogContext.Provider value={{ showDialog, cleanUpDialogs }}>
			{children}
			{dialogs.map((dialog) => renderDialog(dialog))}
		</DialogContext.Provider>
	)
}

export default DialogProvider
export { DialogContext }
