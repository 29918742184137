import { useState } from 'react'
import { useIntl } from 'react-intl'
import { Button, CircularProgress } from 'react-md'
import { _createPaymentProduct } from 'state/finance-payment-product-actions'
import useDialogs from 'ui/components/dialogs/useDialogs'
import {
	CANCEL_BUTTON_LABEL,
	GENERIC_ERROR_NOTIFICATION,
	SAVE_BUTTON_LABEL,
} from 'ui/messages'
import PaymentProductForm from './PaymentProductForm'
import type { PaymentProduct } from './types'
import { getPaymentProductErrorMessage, initFormData } from './utils'
import { validateFormData } from './validate'
import type { VatSelectOptions } from '../types'
import ViiluDialog from 'ui/components/ViiluDialog'

type Props = {
	companyUUID: string
	initialData?: PaymentProduct
	onHide: () => void
	refreshPaymentProducts: () => void
	paymentProducts: PaymentProduct[]
	vatOptions: VatSelectOptions[]
}

function PaymentProductCreateDialog({
	companyUUID,
	initialData,
	onHide,
	refreshPaymentProducts,
	paymentProducts,
	vatOptions,
}: Props) {
	const intl = useIntl()
	const [formData, setFormData] = useState(initFormData(initialData))
	const [loading, setLoading] = useState(false)
	const { alert } = useDialogs()

	const handleSave = async () => {
		const isValid = await validateFormData(
			formData,
			alert,
			intl,
			paymentProducts,
		)

		if (!isValid) {
			return
		}

		setLoading(true)
		try {
			const response = await _createPaymentProduct({
				companyUUID,
				number: Number(formData.number),
				priority: Number(formData.priority),
				name: formData.name,
				description: formData.description,
				startDate: formData.startDate,
				endDate: formData.endDate || null,
				separateBilling: formData.separateBilling,
				vatUUID: formData.vatUUID,
				billingBasis: formData.billingBasis,
				billingBasisDescription: formData.billingBasisDescription,
				prices: formData.prices.map((p) => ({
					...p,
					price: Number(p.price),
					endDate: p.endDate || null,
				})),
			})

			const result = await response?.json()
			if (!response?.ok) {
				const errorMessage = getPaymentProductErrorMessage(
					result.errorCode,
					intl,
				)
				if (errorMessage) {
					alert(errorMessage)
					setLoading(false)
					return
				} else {
					throw new Error('Response is not ok.')
				}
			}

			refreshPaymentProducts()
			onHide()
		} catch (error) {
			console.error(error)
			alert(GENERIC_ERROR_NOTIFICATION(intl))
			setLoading(false)
		}
	}

	const renderContent = () => {
		if (loading) {
			return <CircularProgress id="payment-product-update-progress" />
		}

		return (
			<PaymentProductForm
				formData={formData}
				setFormData={setFormData}
				vatOptions={vatOptions}
			/>
		)
	}

	return (
		<ViiluDialog
			id="payment-product-dialog"
			visible={true}
			onHide={onHide}
			title={intl.formatMessage({
				defaultMessage: 'Uusi maksulaji',
				description: 'Title for the finance payment product dialog.',
			})}
			actions={[
				<Button flat onClick={onHide}>
					{CANCEL_BUTTON_LABEL(intl)}
				</Button>,
				<Button raised secondary disabled={loading} onClick={handleSave}>
					{SAVE_BUTTON_LABEL(intl)}
				</Button>,
			]}
			modal
			dialogStyle={{ borderRadius: 8, width: 'auto' }}
			focusOnMount={false}
		>
			{renderContent()}
		</ViiluDialog>
	)
}

export default PaymentProductCreateDialog
