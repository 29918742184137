import type {
	PartyFilters,
	BondFilters,
	CreateParty,
	CreateBondsWithPartyUUIDs,
	Bond,
	PartyBond,
	UpdateParty,
	CreatePartyBondLink,
	EditPartyBondLink,
} from 'ui/ManagerFinance/Parties/types'
import { callApi } from './api/finance-api'
import { generateQueryParams } from 'util/queryUtils'

export const _getPartyNamesWithBonds = async () => {
	const url = '/v1/party-names-with-bonds'

	return callApi(url, {
		method: 'GET',
	})
}
type GetPartyFilters = {
	companyUUID: string
} & PartyFilters
export const _getParties = async (filters: GetPartyFilters) => {
	const queryParams = generateQueryParams(filters)

	const url = `/v1/party${queryParams}`

	return callApi(url, {
		method: 'GET',
	})
}

export const _getParty = async (uuid: string) => {
	return callApi(`/v1/party/${uuid}`, {
		method: 'GET',
	})
}

export const _createPartyWithBonds = async (
	data: CreateParty,
	bonds: CreatePartyBondLink[],
) => {
	return callApi(`/v1/party-with-bonds`, {
		method: 'POST',
		body: {
			party: data,
			bonds: bonds,
		},
	})
}

export const _updatePartyWithBonds = async (
	data: UpdateParty,
	bonds: EditPartyBondLink[],
) => {
	return callApi(`/v1/party-with-bonds/${data.uuid}`, {
		method: 'PUT',
		body: {
			party: data,
			bonds: bonds,
		},
	})
}

export const _deleteParty = async (data: { uuid: string }) => {
	return callApi(`/v1/party/${data.uuid}`, {
		method: 'DELETE',
	})
}

export const _getBonds = async (filters: BondFilters) => {
	const queryParams = generateQueryParams(filters)

	const url = `/v1/bond${queryParams}`

	return callApi(url, {
		method: 'GET',
	})
}

export const _getBond = async (uuid: string) => {
	return callApi(`/v1/bond/${uuid}`, {
		method: 'GET',
	})
}

export const _createBonds = async (data: CreateBondsWithPartyUUIDs) => {
	return callApi(`/v1/bonds`, {
		method: 'POST',
		body: {
			...data,
		},
	})
}

export const _updateBonds = async (data: Bond[] | PartyBond[]) => {
	return callApi('/v1/bonds', {
		method: 'PUT',
		body: data,
	})
}

export const _deleteBond = async (data: { uuid: string }) => {
	return callApi(`/v1/bond/${data.uuid}`, {
		method: 'DELETE',
	})
}
