import React from 'react'
import { IntlProvider } from 'react-intl'

import { LOCALES } from './locales'
import AppText from './AppText'

const LocalesProvider = ({ children, locale = LOCALES.FINNISH }) => {
	return (
		<IntlProvider
			locale={locale}
			defaultLocale={LOCALES.FINNISH}
			textComponent={React.Fragment}
			messages={AppText[locale]}
		>
			{children}
		</IntlProvider>
	)
}

export default LocalesProvider
