import type { Contract } from './types'
import { MenuButton, TableColumn, TableRow, CircularProgress } from 'react-md'
import { useDispatch } from 'react-redux'
import { contractHeaders } from './constants'
import type { SelectOptions, ApartmentSelectOptions } from '../types'
import { push } from 'connected-react-router'
import { useIntl } from 'react-intl'
import { getContractRowValue } from './utils'

type Props = {
	contract: Contract
	menuItems: any[] | null
	children: any
	processing: any
	companyOptions: SelectOptions[]
	apartmentOptions: ApartmentSelectOptions[]
}

const ContractRow = ({
	contract,
	menuItems,
	children,
	processing,
	companyOptions,
	apartmentOptions,
}: Props) => {
	const intl = useIntl()
	const dispatch = useDispatch()
	const appendedMenuItems = menuItems ? [...menuItems] : null

	const menu = !appendedMenuItems ? null : (
		<MenuButton
			id={'menu-button'}
			icon
			menuItems={appendedMenuItems}
			centered
			anchor={{
				x: MenuButton.HorizontalAnchors['CENTER'],
				y: MenuButton.VerticalAnchors['CENTER'],
			}}
		>
			more_vert
		</MenuButton>
	)

	const handleOnClick = () => {
		dispatch(push(window.location.pathname + '?cuuid=' + contract.uuid))
	}

	const renderTableColumns = () => {
		return contractHeaders
			.filter((h) => h.show)
			.map((header) => {
				const { key, type } = header
				return (
					<TableColumn onClick={handleOnClick} key={key}>
						<p>
							{getContractRowValue(
								contract,
								key,
								companyOptions,
								apartmentOptions,
								intl,
								type,
							)}
						</p>
					</TableColumn>
				)
			})
	}
	return (
		<TableRow key={contract.uuid}>
			{renderTableColumns()}
			<TableColumn key={'children'}>
				<div>
					{processing ? (
						<CircularProgress id="progress" style={{ marginTop: 0 }} />
					) : (
						menu
					)}
					{children}
				</div>
			</TableColumn>
		</TableRow>
	)
}

export default ContractRow
