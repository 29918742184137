import styled from 'styled-components'

export const SummaryList = styled.ul`
	list-style: none;
	padding: 0;
	margin: 0;
`

export const SummaryListItem = styled.li`
	display: flex;
	align-items: center;
	margin: 16px 0;

	.md-icon  {
		color: #000;
	}

	span {
		margin-left: 16px;
	}
`
