import config from 'config'
import { getAuth, getIdToken } from 'firebase/auth'
import { firebaseApp } from 'state/store'

const API_URL = `${config.functions.baseURL}finance`

type FetchOptions = {
	method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'
	body?: Record<any, any>
}

export const callApi = async (route: string, options: FetchOptions) => {
	const currentUser = getAuth(firebaseApp).currentUser

	if (!currentUser) {
		console.error('Unauthenticated.')
		return
	}

	const token = await getIdToken(currentUser)

	return fetch(`${API_URL}${route}`, {
		method: options.method,
		headers: {
			Authorization: 'Bearer ' + token,
			'Content-Type': 'application/json',
		},
		body: options.body ? JSON.stringify(options.body) : undefined,
	})
}
