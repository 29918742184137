import { backendFunction } from './actions-util'

export const _oiva360DatabaseSync = (forceFullSync) =>
	backendFunction(
		'oiva360DatabaseSync',
		{ forceFullSync },
		{
			timeout: 3600000,
		},
	)

export const _oiva360UserSync = () =>
	backendFunction(
		'oiva360UserSync',
		{},
		{
			timeout: 3600000,
		},
	)

export const _oiva360CompanyUsers = (oiva360CompanyId) =>
	backendFunction('oiva360CompanyUsers', {
		oiva360CompanyId,
	})
