export const defaultColumnSettings = [
	{
		label: 'Tiketti',
		key: 'ticketId',
		show: true,
	},
	{
		label: 'Deadline',
		key: 'duedate',
		show: true,
	},
	{
		label: 'Tunnisteet',
		key: 'tag',
		show: false,
	},
	{
		label: 'Otsikko',
		key: 'title',
		show: true,
	},
	{
		label: 'Ilmoittaja',
		key: 'author',
		show: true,
	},
	{
		label: 'Kohde',
		key: 'company',
		show: true,
	},
	{
		label: 'Vastuuhenkilö',
		key: 'assignee',
		show: true,
	},
	{
		label: 'Reaktiot',
		key: 'reaction',
		show: false,
	},
	{
		label: 'Alitehtävät',
		key: 'subtask',
		show: false,
	},
	{
		label: 'Luotu',
		key: 'createdAt',
		show: false,
	},
	{
		label: 'Huoneistotunnus',
		key: 'apartmentId',
		show: false,
	},
]
