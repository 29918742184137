import ViiluDialog from 'ui/components/ViiluDialog'
const rootDialog = document.getElementById('root-dialog')

function RootDialog({ dialog, actions, children, focusOnMount = false }) {
	return (
		<ViiluDialog
			id={`root-dialog-${dialog.key}`}
			portal
			renderNode={rootDialog}
			modal
			visible={true}
			title={dialog.title}
			focusOnMount={focusOnMount}
			actions={actions}
			dialogStyle={{ borderRadius: 8, width: 480 }}
		>
			{children}
		</ViiluDialog>
	)
}

export default RootDialog
