import { injectIntl } from 'react-intl'
import { FontIcon } from 'react-md'
import { compose } from 'redux'
import { SummaryList, SummaryListItem } from './styles'
import config from 'config'

function BulletinsSummary({ bulletinsSettings, intl }) {
	const getIcon = (role) => {
		return bulletinsSettings.distributionOptions[role] ? 'check' : 'block'
	}

	const formatTs = (ts) => {
		if (!ts) {
			return null
		}

		return new Date(ts).toLocaleString('fi', {
			dateStyle: 'medium',
			timeStyle: 'short',
		})
	}

	const formatPublishTs = () => {
		const tsString = formatTs(bulletinsSettings.publishTs)

		if (!tsString) {
			return 'Julkaistaan heti'
		}

		return `Julkaisuaika ${tsString}`
	}

	const formatExpirationTs = () => {
		const tsString = formatTs(bulletinsSettings.expirationTs)

		if (!tsString) {
			return 'Ei poistumisaikaa'
		}

		return `Poistumisaika ${tsString}`
	}

	const roles = config.userRoles

	return (
		<>
			<h2>Ilmoitustaulu (maksuton)</h2>
			<SummaryList>
				<SummaryListItem>
					<FontIcon>{getIcon(roles.userTenant)}</FontIcon>
					<span>Asukkaille</span>
				</SummaryListItem>
				<SummaryListItem>
					<FontIcon>{getIcon(roles.userShareholder)}</FontIcon>
					<span>Osakkaille</span>
				</SummaryListItem>
				<SummaryListItem>
					<FontIcon>{getIcon(roles.adminBoardMember)}</FontIcon>
					<span>Hallitukselle</span>
				</SummaryListItem>
				<SummaryListItem>
					<FontIcon>event</FontIcon>
					<span>{formatPublishTs()}</span>
				</SummaryListItem>
				<SummaryListItem>
					<FontIcon>event</FontIcon>
					<span>{formatExpirationTs()}</span>
				</SummaryListItem>
			</SummaryList>
		</>
	)
}

export default compose(injectIntl)(BulletinsSummary)
