import { TableColumn, TableRow } from 'react-md'
import type { Loan } from './types'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import { formatCurrency } from 'util/formatStrings'
import { billingBasisMessage } from '../utils/messages'
import { LoanStatus } from './enum'
import { loanStatusMessage } from './messages'

type LoansTableRowProps = {
	loan: Loan
	onDraftLoanClick: (loan: Loan) => void
}

const LoansTableRow = (props: LoansTableRowProps) => {
	const { loan, onDraftLoanClick } = props
	const intl = useIntl()
	const dispatch = useDispatch()

	const onRowClick = () => {
		// If loan is a draft (e.g active = false), we should not open the loan view dialog,
		// instead open the form creation dialog with loan info
		if (loan.status === LoanStatus.ACTIVE) {
			dispatch(push(window.location.pathname + '?luuid=' + loan.uuid))
		} else {
			onDraftLoanClick(loan)
		}
	}

	return (
		<TableRow key={loan.uuid} onClick={onRowClick}>
			<TableColumn>{loan.companyName}</TableColumn>
			<TableColumn>{loan.name}</TableColumn>
			<TableColumn>{loan.creditId}</TableColumn>
			<TableColumn>{loanStatusMessage(intl, loan.status)}</TableColumn>
			<TableColumn>{billingBasisMessage(intl, loan.billingBasis)}</TableColumn>
			<TableColumn>{formatCurrency(loan.originalLoanAmount)}</TableColumn>
			<TableColumn>{formatCurrency(loan.debtLeftToPay)}</TableColumn>
		</TableRow>
	)
}

export default LoansTableRow
