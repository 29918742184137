import { useUserRoles } from 'ui/components/userRoles/hooks'

// for use in class components (use hooks otherwise)
function WithUserRoles(Component) {
	return function WithUserRolesComponent(props) {
		const {
			isAdmin,
			isSuperAdmin,
			isManager,
			isTenant,
			isShareholder,
			isBoardMember,
			isBoardDeputy,
			isBoardDirector,
			isPartOfBoard,
			isMaintenanceCompany,
		} = useUserRoles()
		return (
			<Component
				{...props}
				isAdmin={isAdmin}
				isSuperAdmin={isSuperAdmin}
				isManager={isManager}
				isTenant={isTenant}
				isShareholder={isShareholder}
				isBoardMember={isBoardMember}
				isBoardDeputy={isBoardDeputy}
				isBoardDirector={isBoardDirector}
				isPartOfBoard={isPartOfBoard}
				isMaintenanceCompany={isMaintenanceCompany}
			/>
		)
	}
}

export default WithUserRoles
