import { backendFunction } from './actions-util'

export const _init =
	(
		correlationUUID,
		correlationCollectionOverride,
		fileBucket,
		filePath,
		signees,
		enabledSignMethods,
		genericDocument,
		includeAttachments,
		appendAttachments,
		anyOrderAllowed,
		note,
	) =>
	async (dispatch, getState) =>
		backendFunction('signatureRequestInit', {
			correlationUUID,
			correlationCollectionOverride,
			companyUUID: getState().app.selectedCompanyUUID,
			fileBucket,
			filePath,
			signees,
			enabledSignMethods,
			genericDocument,
			includeAttachments,
			appendAttachments,
			anyOrderAllowed,
			note,
		})

export const _send = (uuid, phoneNumber, unid) => async () =>
	backendFunction('signatureRequestSend', {
		uuid,
		phoneNumber,
		unid,
	})

export const _sendStrong = (uuid, phoneNumber, unid) => async () =>
	backendFunction('signatureRequestStrongSend', {
		uuid,
		unid,
	})

export const _sign = (uuid, signatureImage, unid) => async () =>
	backendFunction(
		'signatureRequestSign',
		{
			uuid,
			signatureImage,
			unid,
		},
		{
			timeout: 300000,
		},
	)

export const _signWithStrongAuth = (uuid, unid, code) => async () =>
	backendFunction(
		'signatureRequestStrongSign',
		{
			uuid,
			unid,
			code,
		},
		{
			timeout: 300000,
		},
	)

export const _saveSignatureImage = (uuid, unid, signatureImage) => async () =>
	backendFunction('signatureRequestStrongSignSaveSignatureImage', {
		uuid,
		unid,
		signatureImage,
	})

export const _reset = (uuid) => async () =>
	backendFunction('signatureRequestReset', {
		uuid,
	})

export const _complete = (uuid) => async () =>
	backendFunction('signatureRequestComplete', {
		uuid,
	})

export const _resend = (uuid, unid) => async () =>
	backendFunction('signatureRequestResend', {
		uuid,
		unid,
	})

export const _signeeUpdateEmail = (uuid, unid, email) => async () =>
	backendFunction('signatureRequestSigneeUpdateEmail', {
		uuid,
		unid,
		email,
	})

export const _verifySignatures = (uuid) => async () =>
	backendFunction('signatureRequestVerifySignatures', {
		uuid,
	})

export const _verifyDocument = (uuid, files) =>
	async function (dispatch, getState, getFirebase) {
		try {
			// Done with fetch and FormData because firebase callable functions don't support file uploads
			const formData = new FormData()
			formData.append('uuid', uuid)
			files.forEach((file) => {
				formData.append(file.name, file)
			})

			const functionURL =
				'https://signaturerequestverifydocument-ekw3uy5dmq-ew.a.run.app'
			const response = await fetch(functionURL, {
				method: 'POST',
				body: formData,
			})

			const resJson = await response.json()

			if (response.ok) {
				return resJson
			} else {
				throw new Error(resJson.error)
			}
		} catch (ex) {
			console.log(ex)
			if (window.sentry) window.sentry.captureException(ex)
			return { success: false, ex }
		}
	}

export const _createAuditLog = async (data) => {
	return backendFunction('signatureRequestAuditLogCreate', data)
}

export const _downloadAuditLog = async (uuid) => {
	return backendFunction('signatureRequestAuditLogDownload', { uuid })
}
