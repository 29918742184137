export const maxScreenWidth = {
	phoneSmall: 360,
	phone: 768,
	tablet: 1024,
}

export const breakpoint = {
	phoneSmall: `only screen and (max-width: ${maxScreenWidth.phoneSmall}px)`,
	phone: `only screen and (max-width: ${maxScreenWidth.phone}px)`,
	tablet: `only screen and (min-width : 768px) and (max-width : ${maxScreenWidth.tablet}px)`,
	desktop: 'screen and (min-width: 1024px)',
}
