import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import WithSelectedManager from './WithSelectedManager'
import WithUserRoles from './userRoles/WithUserRoles'
import config from 'config'

export default (ComponentToWrap) => {
	class WithSelectedManagerRole extends Component {
		render() {
			const { selectedManager, uid, isSuperAdmin } = this.props

			let role = config.userRoles.none
			if (selectedManager !== null) {
				role = selectedManager.userRoles[uid] || config.userRoles.none
			}

			// Check if super admin
			if (isSuperAdmin) {
				role = 'manager_master'
			}
			return <ComponentToWrap {...this.props} managerRole={role} />
		}
	}

	const mapState = ({
		firebaseReducer: {
			auth: { uid },
		},
	}) => ({
		uid,
	})

	const mapDispatchToProps = (dispatch) => ({})

	return compose(
		WithSelectedManager({ hideLoading: false }),
		WithUserRoles,
		connect(mapState, mapDispatchToProps),
	)(WithSelectedManagerRole)
}
