import { push } from 'connected-react-router'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import {
	DataTable,
	FontIcon,
	ListItem,
	TableBody,
	TableColumn,
	TableHeader,
	TableRow,
} from 'react-md'
import { useDispatch, useSelector } from 'react-redux'
import { _deleteInvoice } from 'state/finance-invoice-actions'
import styled from 'styled-components'
import useDialogs from 'ui/components/dialogs/useDialogs'
import type { Invoice, InvoiceKey } from './types'
import type { ApartmentSelectOptions } from '../types'
import { invoiceHeaders } from './constants'
import InvoiceRow from './InvoiceRow'
import { getInvoiceRowValue } from './utils'
import naturalSort from 'javascript-natural-sort'

const StyledDataTable = styled(DataTable)`
	td {
		white-space: nowrap;
	}
`

type Props = {
	invoices: Invoice[]
	refreshInvoices: () => void
	apartmentOptions: ApartmentSelectOptions[]
	consolidatedUUID?: string
}

function InvoicesTable({
	invoices = [],
	refreshInvoices,
	apartmentOptions,
	consolidatedUUID,
}: Props) {
	const intl = useIntl()
	const dispatch = useDispatch()
	const currentPath: string | undefined = useSelector(
		({ router }: any) => router?.location?.pathname,
	)
	const { confirm, alert } = useDialogs()
	const [deleting, setDeleting] = useState<string[]>([])

	const [sort, setSort] = useState<{
		key: InvoiceKey
		type: string | null
		order: string
	}>({
		key: 'apartmentIdentifier',
		type: null,
		order: 'asc',
	})

	const changeSort = (key: InvoiceKey, type?: string) => {
		if (sort.key === key) {
			setSort((prevData) => ({
				...prevData,
				order: sort.order === 'asc' ? 'desc' : 'asc',
			}))
		} else {
			setSort({
				key: key,
				type: type || null,
				order: 'asc',
			})
		}
	}

	const navigateToInvoice = (uuid: string) => {
		if (!currentPath) {
			return
		}
		let url = `${currentPath}?uuid=${uuid}`
		if (consolidatedUUID) {
			url += `&consolidatedUUID=${consolidatedUUID}`
		}
		dispatch(push(url))
	}

	const deleteInvoice = async (uuid: string) => {
		if (!uuid) {
			return
		}

		const confirmMessage = intl.formatMessage({
			defaultMessage: 'Haluatko varmasti poistaa laskun?',
			description: 'Confirm message before deleting an invoice.',
		})

		if (!(await confirm(confirmMessage))) {
			return
		}

		setDeleting((prev) => [...prev, uuid])
		try {
			const result = await _deleteInvoice(uuid)
			if (result?.ok) {
				refreshInvoices()
			} else {
				throw new Error('Delete failed.')
			}
		} catch (error) {
			console.error(error)
			alert('Tapahtui virhe.')
		}
		setDeleting((prev) => prev.filter((u) => u !== uuid))
	}

	const renderTableHeaders = () => {
		return (
			<TableHeader>
				<TableRow>
					{invoiceHeaders
						.filter((h) => h.show)
						.map((header) => (
							<TableColumn
								key={header.key}
								onClick={() =>
									header.sortable && changeSort(header.key, header.type)
								}
							>
								<div
									style={{ display: 'flex', alignItems: 'center', gap: '4px' }}
								>
									<p className="text-strong text-subtle">
										{header.title(intl)}
									</p>
									{sort.key === header.key && (
										<FontIcon style={{ fontSize: 12 }}>
											{sort.order === 'asc' ? 'arrow_upward' : 'arrow_downward'}
										</FontIcon>
									)}
								</div>
							</TableColumn>
						))}
					<TableColumn>
						{intl.formatMessage({
							defaultMessage: 'Toiminnot',
							description: 'Table column header for finance invoice actions.',
						})}
					</TableColumn>
				</TableRow>
			</TableHeader>
		)
	}

	return (
		<StyledDataTable plain>
			{renderTableHeaders()}

			<TableBody>
				{invoices
					.sort((a, b) => {
						const aValue =
							sort.type && sort.type !== 'boolean'
								? getInvoiceRowValue(
										a,
										sort.key,
										apartmentOptions,
										intl,
										sort.type,
								  )
								: a[sort.key]

						const bValue =
							sort.type && sort.type !== 'boolean'
								? getInvoiceRowValue(
										b,
										sort.key,
										apartmentOptions,
										intl,
										sort.type,
								  )
								: b[sort.key]

						return sort.order === 'asc'
							? naturalSort(aValue, bValue)
							: naturalSort(bValue, aValue)
					})
					.map((invoice) => {
						const { uuid } = invoice
						return (
							<InvoiceRow
								key={uuid}
								invoice={invoice}
								processing={deleting.includes(uuid)}
								navigateToInvoice={navigateToInvoice}
								apartmentOptions={apartmentOptions}
								menuItems={[
									<ListItem
										key="edit"
										primaryText={intl.formatMessage({
											defaultMessage: 'Muokkaa',
											description: 'Edit invoice',
										})}
										leftIcon={<FontIcon>edit</FontIcon>}
										onClick={() => navigateToInvoice(invoice.uuid)}
									/>,
									<ListItem
										key="delete"
										primaryText={intl.formatMessage({
											defaultMessage: 'Poista',
											description: 'Delete invoice',
										})}
										leftIcon={<FontIcon>delete</FontIcon>}
										onClick={() => deleteInvoice(invoice.uuid)}
										className="md-divider-border md-divider-border--right"
									/>,
								]}
							/>
						)
					})}
			</TableBody>
		</StyledDataTable>
	)
}

export default InvoicesTable
