import { bindActionCreators, compose } from 'redux'
import RouteHeader from 'ui/components/RouteHeader'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Tabs as MdTabs, Tab as MdTab } from 'react-md'
import Drafts from './Drafts'
import History from './History'
import MessageServiceDialog from './MessageServiceDialog'
import WithSelectedCompany from 'ui/components/WithSelectedCompany'
import MessageServicePromo from './MessageServicePromo'
import WithSelectedCompanyRole from 'ui/components/WithSelectedCompanyRole'
import { getSetting } from 'util/companyModuleSettings'
import { FEATURE_DISABLED_MESSAGE } from 'ui/messages'
import WithQueryParams from 'ui/components/WithQueryParams'
import { push, replace } from 'connected-react-router'
import { useIsPartOfBoard } from 'ui/components/userRoles/hooks'
import FloatingActionBtn from 'ui/components/FloatingActionBtn'

const Container = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
`

const Tabs = styled(MdTabs)`
	border-bottom-style: solid;
	border-bottom-color: rgba(0, 0, 0, 0.2);
	border-bottom-width: 1;
`

const Tab = styled(MdTab)`
	.md-tab-label {
		color: ${(props) =>
			props.active ? 'var(--color-primary-dark)' : 'var(--color-text-subtle)'};
		font-weight: ${(props) => (props.active ? 'bold' : 'normal')};
	}
`

function MessageService({
	selectedCompany,
	intl,
	tabsOnly,
	hideRouteHeader,
	generator,
	generatorDataUUID,
	defaultTabIndex = 0,
	openUpdatedDialog,
	queryParams,
	pathname,
	_push,
	_replace,
}) {
	const draftUUIDToOpen = queryParams?.draftUUID
	const sentUUIDToOpen = queryParams?.sentUUID
	const [activeTabIndex, setActiveTabIndex] = useState(
		draftUUIDToOpen ? 0 : sentUUIDToOpen ? 1 : defaultTabIndex,
	)
	const [dialog, setDialog] = useState({
		visible: false,
		messageServiceProcess: null,
	})
	const [fabVisible, setFabVisible] = useState(false)
	const messageServiceModuleEnabled =
		selectedCompany.features && selectedCompany.features['messageService']

	const companyMessageServiceDisableFromBoard = getSetting(
		selectedCompany,
		'messageService',
		'companyMessageServiceDisableFromBoard',
	)

	const isBoardMember = useIsPartOfBoard()

	useEffect(() => {
		const skip = isBoardMember && companyMessageServiceDisableFromBoard
		if (!skip && messageServiceModuleEnabled && !tabsOnly) {
			setFabVisible(true)
		}
		// called on unmount or when dependencies change
		return () => {
			setFabVisible(false)
		}
	}, [
		messageServiceModuleEnabled,
		tabsOnly,
		isBoardMember,
		companyMessageServiceDisableFromBoard,
	])

	const openDialog = useCallback((messageServiceProcess) => {
		setDialog({ visible: true, messageServiceProcess })
	}, [])

	if (isBoardMember && companyMessageServiceDisableFromBoard) {
		return (
			<div className="full-height full-width flex-center">
				<p className="text-subtle">{FEATURE_DISABLED_MESSAGE(intl)}</p>
			</div>
		)
	}

	const replacePath = (item, dialogType) => {
		if (dialogType === 'draft') {
			setActiveTabIndex(0)
		}
		let newPath = pathname?.includes('/manager')
			? 'message_service_internal'
			: 'message_service'
		let param = `?${dialogType}UUID=${item?.uuid}`

		if (item?.generator === 'meetingInvite') {
			newPath = `meeting?uuid=${item.generatorDataUUID}&stepId=INVITE_SEND`
			param = `&${dialogType}UUID=${item.uuid}`
		}

		if (dialogType) {
			newPath += param
		}

		if (
			pathname.includes('message_service') &&
			!newPath.startsWith('message_service')
		) {
			_push(newPath)
		} else {
			_replace(newPath)
		}
	}

	const renderActiveTab = () => {
		switch (activeTabIndex) {
			case 0:
				return (
					<Drafts
						openDialog={openUpdatedDialog || openDialog}
						generator={generator}
						generatorDataUUID={generatorDataUUID}
						draftUUIDToOpen={draftUUIDToOpen}
						replacePath={replacePath}
					/>
				)
			case 1:
				return (
					<History
						generator={generator}
						generatorDataUUID={generatorDataUUID}
						sentUUIDToOpen={sentUUIDToOpen}
						replacePath={replacePath}
					/>
				)
			default:
				return <p style={{ padding: 32 }}>Tab not implemented.</p>
		}
	}

	return (
		<Container>
			{!tabsOnly && !hideRouteHeader && (
				<RouteHeader
					header={intl.formatMessage({
						defaultMessage: 'Viestintäpalvelu',
						description: 'Header of the message service section.',
					})}
				/>
			)}
			{!messageServiceModuleEnabled ? (
				<MessageServicePromo />
			) : (
				<>
					<Tabs
						tabId="message-service-tabs"
						activeTabIndex={activeTabIndex}
						onTabChange={(tabIndex) => {
							setActiveTabIndex(tabIndex)
						}}
						style={{
							borderBottom: '1px solid rgba(0,0,0,0.2)',
						}}
					>
						<Tab
							label={intl.formatMessage({
								defaultMessage: 'Luonnokset',
								description: 'Message service drafts tab.',
							})}
							active={activeTabIndex === 0}
						/>
						<Tab
							label={intl.formatMessage({
								defaultMessage: 'Lähetetyt',
								description: 'Message service sent tab.',
							})}
							active={activeTabIndex === 1}
						/>
					</Tabs>

					{renderActiveTab()}

					{dialog.visible && (
						<MessageServiceDialog
							toolbarTitle="Viestintäpalvelu"
							messageServiceProcess={dialog.messageServiceProcess}
							onHide={() => {
								setDialog({ visible: false, messageServiceProcess: null })
								replacePath(dialog?.messageServiceProcess)
							}}
						/>
					)}
				</>
			)}
			<FloatingActionBtn
				iconName={'mail'}
				text={'Luo viesti'}
				action={() => {
					setDialog({ visible: true, messageServiceProcess: null })
				}}
				visible={fabVisible}
			/>
		</Container>
	)
}

const mapState = ({ firestoreReducer, ui, router }) => ({
	firestoreReducer,
	ui,
	pathname: router?.location?.pathname,
})

const mapDispatchToProps = (dispatch) => ({
	_push: bindActionCreators(push, dispatch),
	_replace: bindActionCreators(replace, dispatch),
})

export default compose(
	WithSelectedCompany,
	WithSelectedCompanyRole,
	WithQueryParams,
	injectIntl,
	connect(mapState, mapDispatchToProps),
)(MessageService)
