import type { IntlShape } from 'react-intl'
import { PaymentProductBillingBasis } from '../enum'
import {
	LoanFinancingType,
	LoanInterestDaysMonth,
	LoanInterestDaysYear,
	LoanStatus,
} from './enum'
import type { CreateLoanInput, LoanCreateFormInputs } from './types'
import { loanFinancingTypeMessage } from './messages'
import { billingBasisMessage } from '../utils/messages'

export const createLoanDefaultValues: LoanCreateFormInputs = {
	name: '',
	companyUUID: '',
	companyName: '',
	loanFinancingType: LoanFinancingType.CAPITAL,
	loanStartDateEstimated: '',
	loanEndDateEstimated: '',
	originalLoanAmount: 0,
	loanSharesCanBePaidOff: false,
	loanSharePaymentDates: [],
	usableOnBuildingManagerCertificate: false,
	interestDaysMonth: LoanInterestDaysMonth.THIRTY_DAYS,
	interestDaysYear: LoanInterestDaysYear.THREE_SIXTY,
	creditId: '',
	status: LoanStatus.DRAFT,
	loanStartDate: '',
	loanEndDate: '',
	currency: 'EUR',
	loanType: '',
	loanGrantedBy: '',
	loanTime: '',
	loanDrawnAmount: 0,
	loanFirstDrawDate: '',
	debtLeftToPay: 0,
	loanDrawn: false,
	interestType: '',
	interestPercentage: 0,
	interestLastUpdated: '',
	interestFirstDueDate: '',
	interestInspectionDates: [],
	marginalPercentage: 0,
	amortizationAmount: 0,
	amortizationFirstDueDate: '',
	amortizationInterval: '',
	amortizationDates: [],
	units: 0,
	billingBasis: PaymentProductBillingBasis.AREA,
	shareholders: [],
	selectedSharegroups: {},
}
/**
 * Defines what fields are included in each loan creation form step. This is necessary
 * for validations between steps.
 */
export const formStepToInputsMap: Record<
	number,
	Array<keyof LoanCreateFormInputs>
> = {
	0: ['loanFinancingType'],
	1: [
		'name',
		'loanStartDateEstimated',
		'loanEndDateEstimated',
		'originalLoanAmount',
		'loanSharesCanBePaidOff',
		'loanDrawn',
		'usableOnBuildingManagerCertificate',
		'interestDaysMonth',
		'interestDaysYear',
	],
	2: [
		'creditId',
		'status',
		'loanType',
		'loanGrantedBy',
		'loanTime',
		'loanStartDate',
		'loanEndDate',
		'debtLeftToPay',
		'loanFirstDrawDate',
		'amortizationInterval',
		'amortizationAmount',
		'amortizationFirstDueDate',
		'interestPercentage',
		'interestFirstDueDate',
		'interestType',
		'interestLastUpdated',
		'marginalPercentage',
	],
	3: ['billingBasis'],
}

export const loanFinancingTypeInput: CreateLoanInput = {
	title: (intl) =>
		intl.formatMessage({
			defaultMessage: 'Määritä lainan tyyppi',
			description: 'Define the financing type of the loan',
		}),
	key: 'loanFinancingType',
	show: true,
	type: 'radio',
	menuItems: (intl) => {
		return Object.values(LoanFinancingType).map((financingType) => ({
			label: loanFinancingTypeMessage(intl, financingType),
			value: financingType,
		}))
	},
	rules: (intl) => ({
		required: intl.formatMessage({
			defaultMessage: 'Korkopäivät vuodessa vaaditaan.',
			description: 'Error message for required interest days in year',
		}),
	}),
}

export const billingBasisInput: CreateLoanInput = {
	title: (intl) =>
		intl.formatMessage({
			defaultMessage: 'Jakoperuste',
			description: 'Billing basis',
		}),
	key: 'billingBasis',
	show: true,
	type: 'select',
	menuItems: (intl) => {
		return Object.values(PaymentProductBillingBasis).map((basisOption) => ({
			label: billingBasisMessage(intl, basisOption),
			value: basisOption,
		}))
	},
	rules: (intl) => ({
		required: intl.formatMessage({
			defaultMessage: 'Jakoperuste vaaditaan.',
			description: 'Error message for required billing basis',
		}),
	}),
}

export const STEPS_FOR_CAPITAL_LOAN = (intl: IntlShape) => [
	{
		label: intl.formatMessage({
			defaultMessage: 'Lainan tyyppi',
			description: 'Title for form step: loan type',
		}),
		id: 'step1',
	},
	{
		label: intl.formatMessage({
			defaultMessage: 'Yleiset tiedot',
			description: 'Title for form step: basic information',
		}),
		id: 'step2',
	},
	{
		label: intl.formatMessage({
			defaultMessage: 'Lisätiedot',
			description: 'Title for form step: advanced information',
		}),
		id: 'step3',
	},
	{
		label: intl.formatMessage({
			defaultMessage: 'Lainaosuudet',
			description: 'Title for form step: loan shares',
		}),
		id: 'step4',
	},
]

export const STEPS_FOR_NON_CAPITAL_LOAN = (intl: IntlShape) =>
	STEPS_FOR_CAPITAL_LOAN(intl).slice(0, -1)
