import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'

export default (ComponentToWrap) => {
	class WithSelectedCompanyUUID extends Component {
		render() {
			const { selectedCompanyUUID } = this.props
			return (
				<ComponentToWrap
					{...this.props}
					selectedCompanyUUID={selectedCompanyUUID}
				/>
			)
		}
	}

	const mapState = ({ app: { selectedCompanyUUID } }) => ({
		selectedCompanyUUID,
	})

	const mapDispatchToProps = (dispatch) => ({})

	return compose(connect(mapState, mapDispatchToProps))(WithSelectedCompanyUUID)
}
