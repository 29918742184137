import React from 'react'
import { Button } from 'react-md'

const isNetworkError = (error) =>
	Boolean(error) &&
	(error.toString().includes('NetworkError') ||
		error.toString().includes('Network Error'))

class NetworkErrorBoundary extends React.Component {
	constructor(props) {
		super(props)
		this.state = { error: null }
	}

	static getDerivedStateFromError(error) {
		return { error }
	}

	render() {
		const { error } = this.state
		if (!error) {
			return this.props.children
		}

		if (isNetworkError(error)) {
			return (
				<div>
					<div
						className="flex-column flex-center full-width full-height"
						style={{ background: 'lightgray', borderRadius: 4, padding: 24 }}
					>
						<h1>Ei internetyhteyttä</h1>
						<p>
							Internetyhteytesi on poikki tai se katkesi juuri. Tarkasta yhteys
							ja yritä sitten uudelleen.
						</p>
						<Button
							raised
							primary
							onClick={() => window.location.reload(true)}
							className="margin-top margin-bottom"
						>
							Päivitä
						</Button>
					</div>
				</div>
			)
		}

		// Throw the error so the next boundary can handle it
		throw error
	}
}

export default NetworkErrorBoundary
