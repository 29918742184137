import { FontIcon } from 'react-md'
import { SummaryList, SummaryListItem } from './styles'
import { useIntl } from 'react-intl'
import useMessageServiceIntegrations from 'ui/MessageService/hooks/useMessageServiceIntegrations'

function IntegrationsSummary({
	deliveryMethodsIntegrations,
	integrationsSettings,
}) {
	const intl = useIntl()
	const messageServiceIntegrations = useMessageServiceIntegrations()

	const formatIntegrationsList = () => {
		return deliveryMethodsIntegrations
			.map((uuid) => {
				const integration = messageServiceIntegrations.find(
					(i) => i.uuid === uuid,
				)
				if (!integration) {
					return intl.formatMessage({
						defaultMessage: 'Poistettu',
						description:
							'Error message if an integration is not found in the message service summary.',
					})
				}
				return integration.title
			})
			.join(', ')
	}

	const getIcon = (option) => {
		return integrationsSettings?.distribution.includes(option)
			? 'check'
			: 'block'
	}

	return (
		<>
			<h2>
				{intl.formatMessage({
					defaultMessage: 'Integraatiot',
					description: 'Title of the message service integrations summary.',
				})}
			</h2>
			<p>{formatIntegrationsList()}</p>
			<SummaryList>
				<SummaryListItem>
					<FontIcon>{getIcon('tenants')}</FontIcon>
					<span>
						{intl.formatMessage({
							defaultMessage: 'Asukkaille',
							description:
								'Label for the tenants option in the message service integration settings step.',
						})}
					</span>
				</SummaryListItem>
				<SummaryListItem>
					<FontIcon>{getIcon('shareholders')}</FontIcon>
					<span>
						{intl.formatMessage({
							defaultMessage: 'Osakkaille',
							description:
								'Label for the shareholders option in the message service integration settings step.',
						})}
					</span>
				</SummaryListItem>
				<SummaryListItem>
					<FontIcon>{getIcon('board')}</FontIcon>
					<span>
						{intl.formatMessage({
							defaultMessage: 'Hallitukselle',
							description:
								'Label for the shareholders option in the message service integration settings step.',
						})}
					</span>
				</SummaryListItem>
			</SummaryList>
		</>
	)
}

export default IntegrationsSummary
