import styled from 'styled-components'
import { PropTypes } from 'prop-types'
import { Button } from 'react-md'
import { breakpoint } from 'ui/StyledComponents/MediaBreakpoints'

const Container = styled.div`
	position: absolute;
	width: 100%;
	bottom: 0;
	background: #fff;
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 40px;
`
const LabelContainer = styled.div`
	width: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
	@media ${breakpoint.phone} {
		width: 80px;
	}
`
const Label = styled.p`
	user-select: none;
	font-size: 14px;
`

const Pagination = ({ rowCount, pageIndex, rowsPerPage, onBack, onNext }) => {
	const min = pageIndex * rowsPerPage
	const max = Math.min((pageIndex + 1) * rowsPerPage, rowCount)
	const total = rowCount
	return (
		<Container>
			<Button icon onClick={onBack} disabled={pageIndex === 0}>
				chevron_left
			</Button>
			<LabelContainer>
				<Label>
					{min}-{max}
					<span className="text-subtle"> ({total})</span>
				</Label>
			</LabelContainer>
			<Button
				icon
				onClick={onNext}
				disabled={pageIndex >= Math.ceil(rowCount / rowsPerPage) - 1}
			>
				chevron_right
			</Button>
		</Container>
	)
}

Pagination.propTypes = {
	rowCount: PropTypes.number.isRequired,
	pageIndex: PropTypes.number.isRequired,
	rowsPerPage: PropTypes.number.isRequired,
	onBack: PropTypes.func.isRequired,
	onNext: PropTypes.func.isRequired,
}

export default Pagination
