import { createLoanInputs } from './loanFormInputs'
import LoanFormInput from './LoanFormInput'
import { CreateFormGrid } from './components'

function LoanCreateForm() {
	return (
		<CreateFormGrid>
			{createLoanInputs.map((input, index) => {
				return <LoanFormInput key={`${input.key}-${index}`} input={input} />
			})}
		</CreateFormGrid>
	)
}

export default LoanCreateForm
