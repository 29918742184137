import { useEffect, useState } from 'react'
import { bindActionCreators, compose } from 'redux'
import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid'
import WithSelectedCompany from 'ui/components/WithSelectedCompany'
import {
	getFullAddress,
	sanitizePostalAddress,
	sanitizePostalArea,
	sanitizePostalCountry,
	sanitizePostalZip,
} from 'util/recipientsToLetters'
import Actions from '../../../../Actions'
import RecipientsTable from './RecipientsTable'
import RecipientsTableToolbar from './RecipientsTableToolbar'
import ImporterDialogManual from './RecipientsTable/ImporterDialogManual'
import ImporterDialogEmails from './RecipientsTable/ImporterDialogEmails'
import ImporterDialogViilu from './RecipientsTable/ImporterDialogViilu'
import ImporterDialogFile from './RecipientsTable/ImporterDialogFile'
import ImporterDialogFivaldi from './RecipientsTable/ImporterDialogFivaldi'
import ImporterDialogTampuuri from './RecipientsTable/ImporterDialogTampuuri'
import ImporterDialogOiva360 from './RecipientsTable/ImporterDialogOiva360'
import ImporterDialogHausvise from './RecipientsTable/ImporterDialogHausvise'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import * as notifyActions from 'state/notifyActions'
import { getSetting } from 'util/companyModuleSettings'
import useDialogs from 'ui/components/dialogs/useDialogs'
import { validateEmail } from 'util/validation'
import ImporterDialogHtj from './RecipientsTable/ImporterDialogHtj'
import WithSelectedCompanyManager from 'ui/components/WithSelectedCompanyManager'
import parseRecipientCountry from 'util/numericCountryCodeToAlpha2'

const Container = styled.div`
	flex: 1;
	overflow: hidden;
	display: flex;
	flex-direction: column;
`

const StepTitle = styled.h1`
	font-size: 1.8rem;
	margin: 16px 16px 0px 16px;
`

function Recipients({
	stepTitle,
	selectedCompany,
	selectedCompanyManager,
	messageServiceProcess,
	setMessageServiceProcess,
	onPreviousClick,
	onDraftClick,
	onNextClick,
	intl,
	_showInfoNotification,
}) {
	const [activeImportDialog, setActiveImportDialog] = useState()
	const { alert } = useDialogs()

	const showImportDialog = (dialog) => {
		setActiveImportDialog(dialog)
	}

	const hideImportDialog = () => {
		setActiveImportDialog(null)
	}

	const clearTable = () => {
		setMessageServiceProcess({
			...messageServiceProcess,
			recipients: [],
		})
	}

	const getManagerRecipient = () => {
		if (!selectedCompanyManager) {
			return null
		}
		return {
			uuid: uuidv4(),
			displayName: selectedCompanyManager.name || '',
			address: selectedCompanyManager.address || '',
			area: selectedCompanyManager.area || '',
			zip: selectedCompanyManager.zip || '',
			country: 'FI',
			checked: {
				email: false,
				letter: true,
				sms: false,
			},
		}
	}

	useEffect(() => {
		const appendManagerRecipient = getSetting(
			selectedCompany,
			'messageService',
			'companyMessageServiceAppendManagerRecipient',
		)

		if (!appendManagerRecipient) {
			return
		}

		// Managers only want to have mail if it's a meeting invite
		if (messageServiceProcess.generator !== 'meetingInvite') {
			return
		}

		if (
			!messageServiceProcess.recipients ||
			messageServiceProcess.recipients.length === 0
		) {
			const managerRecipient = getManagerRecipient()
			if (managerRecipient) {
				setMessageServiceProcess({
					...messageServiceProcess,
					recipients: [managerRecipient],
				})
			}
		}
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	const setImportedRecipients = (importRecipients) => {
		if (!importRecipients || importRecipients.length === 0) {
			const message = intl.formatMessage({
				defaultMessage: 'Uusia vastaanottajia ei löytynyt. Rivejä ei lisätty.',
				description:
					'Info message shown in the message service recipients table if there were not any new recipients added from the importers.',
			})
			_showInfoNotification(message)
			return
		}

		const doNotSendEmailIfSendingLetter = getSetting(
			selectedCompany,
			'messageService',
			'companyMessageServiceDoNotSendEmailIfSendingLetter',
		)

		const sanitizedRecipients = importRecipients.map((r) => {
			const sanitizedRecipient = { ...r }
			if (sanitizedRecipient.address) {
				sanitizedRecipient.address = sanitizePostalAddress(
					sanitizedRecipient.address,
				)
			}

			if (sanitizedRecipient.area) {
				sanitizedRecipient.area = sanitizePostalArea(sanitizedRecipient.area)
			}

			if (sanitizedRecipient.zip) {
				sanitizedRecipient.zip = sanitizePostalZip(sanitizedRecipient.zip)
			}

			if (sanitizedRecipient.country) {
				const parsedCountry = parseRecipientCountry(sanitizedRecipient.country)
				sanitizedRecipient.country = sanitizePostalCountry(parsedCountry)
			}

			if (sanitizedRecipient.email) {
				sanitizedRecipient.email = sanitizedRecipient.email.trim()
			}

			return sanitizedRecipient
		})

		const currentRecipients = messageServiceProcess.recipients
		const filtered = sanitizedRecipients.filter(
			(i) =>
				!currentRecipients.some((c) => {
					if (i.email && i.email === c.email) {
						return true
					}

					if (
						i.address &&
						c.displayName === i.displayName &&
						getFullAddress(c) === getFullAddress(i)
					) {
						return true
					}

					if (i.phoneNumber && i.phoneNumber === c.phoneNumber) {
						return true
					}

					return false
				}),
		)

		const emailDelivery =
			messageServiceProcess.deliveryMethods.includes('email')
		const letterDelivery =
			messageServiceProcess.deliveryMethods.includes('letter')

		const withSelections = filtered.map((r) => {
			const checked = {
				email: false,
				letter: false,
				sms: false,
			}

			if (emailDelivery && validateEmail(r.email)) {
				checked.email = true
			}

			if (
				letterDelivery &&
				r.address &&
				r.zip &&
				r.area &&
				r.country &&
				r.digitalDeliveryConsents &&
				!Object.values(r.digitalDeliveryConsents).some((c) => c)
			) {
				checked.letter = true
			}

			if (letterDelivery && doNotSendEmailIfSendingLetter && checked.letter) {
				checked.email = false
			}

			return { ...r, checked }
		})

		setMessageServiceProcess({
			...messageServiceProcess,
			recipients: [...currentRecipients, ...withSelections],
		})
	}

	const renderImportDialog = () => {
		switch (activeImportDialog) {
			case 'import-emails':
				return (
					<ImporterDialogEmails
						onHide={hideImportDialog}
						onSuccess={setImportedRecipients}
						company={selectedCompany}
					/>
				)
			case 'import-viilu':
				return (
					<ImporterDialogViilu
						onHide={hideImportDialog}
						onSuccess={setImportedRecipients}
						company={selectedCompany}
					/>
				)
			case 'import-file':
				return (
					<ImporterDialogFile
						onHide={hideImportDialog}
						onSuccess={setImportedRecipients}
						company={selectedCompany}
					/>
				)
			case 'import-fivaldi':
				return (
					<ImporterDialogFivaldi
						onHide={hideImportDialog}
						onSuccess={setImportedRecipients}
						company={selectedCompany}
					/>
				)
			case 'import-tampuuri':
				return (
					<ImporterDialogTampuuri
						onHide={hideImportDialog}
						onSuccess={setImportedRecipients}
						company={selectedCompany}
					/>
				)
			case 'import-oiva360':
				return (
					<ImporterDialogOiva360
						onHide={hideImportDialog}
						onSuccess={setImportedRecipients}
						company={selectedCompany}
					/>
				)
			case 'import-hausvise':
				return (
					<ImporterDialogHausvise
						onHide={hideImportDialog}
						onSuccess={setImportedRecipients}
						selectedCompany={selectedCompany}
					/>
				)
			case 'htj':
				return (
					<ImporterDialogHtj
						onHide={hideImportDialog}
						onSuccess={setImportedRecipients}
						selectedCompany={selectedCompany}
					/>
				)
			case 'manual':
				return (
					<ImporterDialogManual
						deliveryMethods={messageServiceProcess.deliveryMethods}
						onHide={hideImportDialog}
						onSuccess={setImportedRecipients}
						company={selectedCompany}
					/>
				)
			default:
				return null
		}
	}

	const handleNextClick = async () => {
		const { deliveryMethods, recipients } = messageServiceProcess

		const isSomeRowChecked =
			(deliveryMethods.includes('email') &&
				recipients.some((r) => r.checked?.email)) ||
			(deliveryMethods.includes('letter') &&
				recipients.some((r) => r.checked?.letter)) ||
			(deliveryMethods.includes('sms') &&
				recipients.some((r) => r.checked?.sms))

		if (!isSomeRowChecked) {
			const message = intl.formatMessage({
				defaultMessage:
					'Et ole valinnut yhtään vastaanottajaa. Valitse ainakin yksi jatkaaksesi.',
				description:
					'Alert message shown in the message service if the user has not selected any recipients.',
			})
			await alert(message)
			return
		}

		onNextClick()
	}

	return (
		<>
			<StepTitle>{stepTitle}</StepTitle>

			<Container>
				<RecipientsTableToolbar
					showImportDialog={showImportDialog}
					clearTable={clearTable}
					recipientCount={
						messageServiceProcess.recipients
							? messageServiceProcess.recipients.length
							: 0
					}
				/>
				<RecipientsTable
					deliveryMethods={messageServiceProcess.deliveryMethods}
					recipients={messageServiceProcess.recipients}
					setMessageServiceProcess={setMessageServiceProcess}
				/>
				{renderImportDialog()}
			</Container>

			<Actions
				onPreviousClick={onPreviousClick}
				onDraftClick={onDraftClick}
				onNextClick={handleNextClick}
			/>
		</>
	)
}

const mapState = () => ({})

const mapDispatchToProps = (dispatch) => ({
	_showInfoNotification: bindActionCreators(notifyActions.info, dispatch),
})

export default compose(
	injectIntl,
	WithSelectedCompany,
	WithSelectedCompanyManager,
	connect(mapState, mapDispatchToProps),
)(Recipients)
