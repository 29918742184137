import { injectIntl } from 'react-intl'
import { compose } from 'redux'
import styled from 'styled-components'
import IllustrationsLettersC4C5 from 'svg/illustrations/illustration_letters_c4_c5.svg'

const Container = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`

const Illustration = styled.div`
	height: 80%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 32px;

	img {
		height: 100%;
	}
`
const Text = styled.p`
	font-size: 1.5rem;
	color: rgba(0, 0, 0, 0.4);
	text-align: center;
`

function Preview({ intl }) {
	return (
		<Container>
			<Illustration>
				<img
					src={IllustrationsLettersC4C5}
					alt={intl.formatMessage({
						defaultMessage: 'Kuva C4 ja C5 kirjekuorista.',
						description:
							'Alternative text for the letter types illustration in the general sender component.',
					})}
				/>
			</Illustration>
			<Text>
				{intl.formatMessage({
					defaultMessage:
						'Lähetyksesi jaellaan C4 tai C5 kuoressa sivumäärästä riippuen.',
					description:
						'Informational text for different letter types in the general sender component.',
				})}
			</Text>
		</Container>
	)
}

export default compose(injectIntl)(Preview)
