import styled from 'styled-components'
import BaseDialog from '../BaseDialog'
import SMSStatusItem from './SMSStatusItem'

const Container = styled.div`
	background: #f6f7f9;
	padding: 16px;
	border-radius: 8px;
	margin-bottom: 16px;
`

const SMS = styled.p`
	margin-bottom: 8px;

	span {
		font-weight: bold;
		margin-right: 8px;
	}
`

const formatTimestamp = (timestamp) => {
	return new Date(timestamp).toLocaleString('fi', {
		dateStyle: 'medium',
		timeStyle: 'short',
	})
}

const parseProcessed = (item) => {
	const details = {
		timestamp: formatTimestamp(item.submittedAt),
	}

	if (item.message === 'OK') {
		details.iconColor = 'green'
		details.title = 'Lähetetty'
		details.message =
			'Tekstiviestipalvelu on saanut viestin ja lähettää sen vastaanottajalle.'
	} else {
		details.iconColor = 'red'
		details.title = 'Virhe'
		details.message = 'Tekstiviestin käsittelyssä tapahtui virhe.'
	}

	return details
}

const parseBounced = (item) => {
	const details = {
		iconColor: 'red',
		timestamp: formatTimestamp(item.bouncedAt),
		title: `Virhe (${item.name})`,
		message: 'Tekstiviestiä ei pystytty toimittamaan vastaanottajalle.',
		errorMessage: `"${item.description}"`,
	}

	return details
}

const parseDelivered = (item) => {
	const details = {
		iconColor: 'green',
		timestamp: formatTimestamp(item.deliveredAt),
		title: 'Vastaanotettu',
		message: 'Viesti on toimitettu vastaanottajalle.',
	}

	return details
}

const getStatusDetails = (item) => {
	if (item.status === 'Processed') {
		return parseProcessed(item)
	}

	if (item.status === 'Bounced') {
		return parseBounced(item)
	}

	if (item.status === 'Delivered') {
		return parseDelivered(item)
	}

	return {
		iconColor: 'black',
		title: 'Tuntematon tila.',
	}
}

function SMSStatusDialog({ visible, onHide, recipient, smsStatus }) {
	const history = smsStatus?.statusHistory
		? [...smsStatus.statusHistory].reverse() // Latest first.
		: null

	return (
		<BaseDialog visible={visible} onHide={onHide} title={recipient.displayName}>
			<Container>
				<SMS>
					<span>Tekstiviesti:</span> {recipient.sms}
				</SMS>

				{!history
					? 'Ei tietoja.'
					: history.map((item, index) => {
							const details = getStatusDetails(item)
							return <SMSStatusItem key={index} details={details} />
					  })}
			</Container>
		</BaseDialog>
	)
}

export default SMSStatusDialog
