import { SelectionControlGroup } from 'react-md'
import styled from 'styled-components'
import { StepContainer } from 'ui/MessageService/MessageServiceProcess/steps/styled'
import MessageServiceIntegrations from './MessageServiceIntegrations'

const checkboxControls = [
	{
		label: 'Sähköposti',
		value: 'email',
		disabled: true,
	},
	{
		label: 'Kirje',
		value: 'letter',
		disabled: true,
	},
	{
		label: 'SMS',
		value: 'sms',
		disabled: true,
	},
	{
		label: 'Talosivun ilmoitustaulu',
		value: 'bulletins',
		disabled: true,
	},
	{
		label: 'Porrasnäyttö',
		value: 'infoscreen',
		disabled: true,
	},
]

const Content = styled.div`
	max-width: 600px;
	padding: 24px 32px;
	margin: 16px auto;
	background: #f6f7f9;
	border-radius: 8px;
`

const StepTitle = styled.h1`
	font-size: 1.8rem;
	margin: 0;
	margin-bottom: 16px;
`

function DeliveryMethods({ messageServiceProcess }) {
	return (
		<>
			<StepContainer>
				<Content>
					<StepTitle>Lähetystavat</StepTitle>
					<SelectionControlGroup
						id="message-service-delivery-methods"
						name="message-service-delivery-methods"
						type="checkbox"
						controls={checkboxControls}
						value={messageServiceProcess.deliveryMethods?.join(',') || ''}
						onChange={() => {}}
					/>

					<MessageServiceIntegrations
						deliveryMethodsIntegrations={
							messageServiceProcess.deliveryMethodsIntegrations
						}
					/>
				</Content>
			</StepContainer>
		</>
	)
}

export default DeliveryMethods
