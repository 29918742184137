import React, { Component } from 'react'
import { ListItem, FontIcon } from 'react-md'
import { push } from 'connected-react-router'
import { compose, bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { isLoaded, isEmpty } from 'react-redux-firebase'

import * as appUIActions from 'state/ui/app-ui-actions'

import LoadingState from 'ui/components/LoadingState'
import WithAuth from 'ui/components/WithAuth'
import cap from 'util/capitalizeFirstLetter'
import { DrawerMenuItemText } from './styles'

class ManagerDrawerContextList extends Component {
	state = {}

	render() {
		const { _push, selectedManager, isDesktop, _setNavDrawerVisible } =
			this.props
		if (!isLoaded(selectedManager)) {
			return <LoadingState />
		}

		if (isEmpty(selectedManager)) {
			return null
		}

		const managerName = selectedManager.name || ''

		return (
			<div>
				<ListItem
					key="menuItem-context-manager"
					leftIcon={
						<FontIcon
							style={{ color: 'rgba(255,255,255,0.5)', marginRight: -16 }}
						>
							work
						</FontIcon>
					}
					primaryText={
						<DrawerMenuItemText>{cap(managerName)}</DrawerMenuItemText>
					}
					onClick={() => {
						if (!isDesktop) {
							_setNavDrawerVisible(false)
						}
						_push('/manager/selection')
					}}
				/>
			</div>
		)
	}
}

const mapState = ({ dimensions: { isDesktop } }) => ({
	isDesktop,
})

const mapDispatchToProps = (dispatch) => ({
	_push: bindActionCreators(push, dispatch),
	_setNavDrawerVisible: bindActionCreators(
		appUIActions._setNavDrawerVisible,
		dispatch,
	),
})

export default compose(
	WithAuth,
	connect(mapState, mapDispatchToProps),
)(ManagerDrawerContextList)
