import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { isLoaded, isEmpty } from 'react-redux-firebase'
import LoadingState from './LoadingState'

export default (ComponentToWrap) => {
	class WithAuth extends Component {
		render() {
			const { auth } = this.props
			if (!isLoaded(auth)) {
				return <LoadingState />
			}
			if (isEmpty(auth)) {
				return null
			}
			return <ComponentToWrap {...this.props} />
		}
	}

	const mapState = ({ firebaseReducer: { auth } }) => ({ auth })

	const mapDispatchToProps = () => ({})

	return compose(connect(mapState, mapDispatchToProps))(WithAuth)
}
