import { Button } from 'react-md'
import styled from 'styled-components'

const StyledButton = styled(Button)`
	color: ${(props) => props.$fontColor || '#fff'};
	background-color: ${(props) => props.$backgroundColor};
	border-radius: 16px;
	width: 100%;
	white-space: nowrap;
`

export const Status = {
	NOT_SELECTED: {
		label: 'Ei valittu',
		backgroundColor: 'white',
		fontColor: 'black',
		flat: true,
		disabled: true,
	},
	SENT: {
		label: 'Lähetetty',
		backgroundColor: 'green',
	},
	DELIVERED: {
		label: 'Vastaanotettu',
		backgroundColor: 'green',
	},
	ERROR: {
		label: 'Virhe',
		backgroundColor: 'red',
	},
	IN_PROGRESS: {
		label: 'Käsittelyssä',
		backgroundColor: 'blue',
	},
	UNKNOWN: {
		label: 'Tuntematon',
		backgroundColor: 'white',
		fontColor: 'black',
	},
}

function BaseButton({ status, onClick }) {
	return (
		<StyledButton
			flat={status.flat}
			raised={!status.flat}
			$backgroundColor={status.backgroundColor}
			$fontColor={status.fontColor}
			onClick={onClick}
			disabled={status.disabled}
		>
			{status.label}
		</StyledButton>
	)
}

export default BaseButton
