import { backendFunction } from './actions-util'

export const _getHausviseCompaniesByManagerUUID = (
	managerUUID,
	addApartments,
) =>
	backendFunction('hausviseCompaniesByManagerUUID', {
		managerUUID,
		addApartments,
	})

export const _getHausviseCompanyByHausviseId = (
	managerUUID,
	hausviseCompanyId,
) =>
	backendFunction('hausviseCompanyByHausviseId', {
		managerUUID,
		hausviseCompanyId,
	})

export const _getHausviseCompanyByBusinessId = (managerUUID, businessId) =>
	backendFunction('hausviseCompanyByBusinessId', {
		managerUUID,
		businessId,
	})

export const _importHausviseCompany = ({
	name,
	apartmentCount,
	apartments,
	address,
	zip,
	area,
	businessId,
	managerUUID,
	hausviseCompanyId,
	features,
	featuresHidden,
	featureSettings,
	serviceLevel,
}) =>
	backendFunction('hausviseCompanyImport', {
		name,
		apartmentCount,
		apartments,
		address,
		zip,
		area,
		businessId,
		managerUUID,
		hausviseCompanyId,
		features,
		featuresHidden,
		featureSettings,
		serviceLevel,
	})

export const _setHausviseEnabled = (managerUUID, enabled) =>
	backendFunction('hausviseEnabledSet', {
		managerUUID,
		enabled,
	})

export const _setHausviseCredentials = (managerUUID, apiKey) =>
	backendFunction('hausviseCredentialsSet', {
		managerUUID,
		apiKey,
	})

export const _hausviseCompanyLink = (
	managerUUID,
	companyUUID,
	hausviseCompanyId,
) =>
	backendFunction('hausviseCompanyLink', {
		managerUUID,
		companyUUID,
		hausviseCompanyId,
	})

export type ShareholdersDeliveryConsentsType = 'company-meeting' | 'general'
/**
 * @param deliveryConsentsType If undefined, deliveryConsents will not be fetched
 */
export const _getHausviseCompanyShareholders = (
	companyUUID: string,
	managerUUID: string,
	deliveryConsentsType?: ShareholdersDeliveryConsentsType,
) =>
	backendFunction('hausviseCompanyShareholders', {
		companyUUID,
		managerUUID,
		deliveryConsentsType,
	})

export const _getHausviseCompanyApartmentShareGroups = (
	companyUUID,
	managerUUID,
) =>
	backendFunction('hausviseCompanyApartmentShareGroups', {
		companyUUID,
		managerUUID,
	})
export const _getHausviseCompanyApartments = (companyUUID, managerUUID) =>
	backendFunction('hausviseCompanyApartments', {
		companyUUID,
		managerUUID,
	})
export const _getHausviseCompanyTenants = (companyUUID, managerUUID) =>
	backendFunction('hausviseCompanyTenants', {
		companyUUID,
		managerUUID,
	})
