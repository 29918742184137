import { v4 as uuid } from 'uuid'
import sanitizeFileName from 'util/sanitizeFileName'
import imageCompression from 'browser-image-compression'
import { getStorageBucket } from 'state/store'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'

const WYSIWYG_BUCKET_NAME = 'gs://viilu-wysiwyg-editor-uploads'

class ImageUploadAdapter {
	constructor(loader, selectedCompanyUUID) {
		this.loader = loader
		this.selectedCompanyUUID = selectedCompanyUUID
	}

	async compress(file) {
		if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
			return file
		}

		const options = {
			maxSizeMB: 0.5,
			maxWidthOrHeight: 1920,
			useWebWorker: true,
		}

		try {
			const blob = await imageCompression(file, options)
			return new File([blob], file.name, { type: blob.type, size: blob.size })
		} catch (e) {
			console.log(e)
		}
	}

	upload() {
		const onNext = (snapshot) => {
			const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
			console.log('Upload is ' + progress + '% done')
			switch (snapshot.state) {
				case 'paused':
					console.log('Upload is paused')
					break
				case 'running':
					console.log('Upload is running')
					break
				default:
					console.log('Something is wrong.')
			}
		}
		const onError = (reject) => (error) => {
			// A full list of error codes is available at
			// https://firebase.google.com/docs/storage/web/handle-errors
			switch (error.code) {
				case 'storage/unauthorized':
					return reject(" User doesn't have permission to access the object")
				case 'storage/canceled':
					return reject('User canceled the upload')
				case 'storage/unknown':
					return reject('Unknown error occurred, inspect error.serverResponse')
				default:
					return reject('Something went wrong.')
			}
		}
		const onComplete = (uploadTask, resolve) => () => {
			getDownloadURL(uploadTask.snapshot.ref).then(function (downloadURL) {
				console.log('File available at', downloadURL)
				resolve({
					default: downloadURL,
				})
			})
		}

		return this.loader.file.then(
			(file) =>
				new Promise(async (resolve, reject) => {
					if (!this.selectedCompanyUUID) {
						return reject('Error: selectedCompanyUUID is missing.')
					}

					const compressedFile = await this.compress(file)
					if (!compressedFile) {
						return reject('Error: Image compression failed.')
					}

					const uploadPath = `${this.selectedCompanyUUID}/${uuid()}`
					const metadata = {
						contentDisposition:
							'attachment; filename="' +
							sanitizeFileName(compressedFile.name) +
							'"',
					}
					const uploadTask = uploadBytesResumable(
						ref(getStorageBucket(WYSIWYG_BUCKET_NAME), uploadPath),
						compressedFile,
						metadata,
					)

					uploadTask.on(
						'state_changed',
						onNext,
						onError(reject),
						onComplete(uploadTask, resolve),
					)
				}),
		)
	}
}

const ImageUploadAdapterPlugin = (selectedCompanyUUID) => (editor) => {
	editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
		return new ImageUploadAdapter(loader, selectedCompanyUUID)
	}
}

export default ImageUploadAdapterPlugin
