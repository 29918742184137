import { useMemo } from 'react'

const initSteps = (deliveryMethods, recipients, childSteps) => {
	const showEmail =
		deliveryMethods.includes('email') &&
		recipients.some((r) => r.checked?.['email'])
	const showPostal =
		deliveryMethods.includes('letter') &&
		recipients.some((r) => r.checked?.['letter'])
	const showSMS =
		deliveryMethods.includes('sms') &&
		recipients.some((r) => r.checked?.['sms'])

	return childSteps.filter((s) => {
		if (s.id === 'email') {
			return showEmail
		}
		if (s.id.startsWith('postal')) {
			return showPostal
		}
		if (s.id === 'sms') {
			return showSMS
		}
		return true
	})
}

function Sending({
	messageServiceProcess,
	setMessageServiceProcess,
	onPreviousClick,
	onDraftClick,
	onNextClick,
	onHide,
	childSteps,
}) {
	const filteredSteps = useMemo(
		() =>
			initSteps(
				messageServiceProcess.deliveryMethods,
				messageServiceProcess.recipients,
				childSteps,
			),
		[
			childSteps,
			messageServiceProcess.deliveryMethods,
			messageServiceProcess.recipients,
		],
	)

	if (filteredSteps.length === 0) {
		return <p style={{ margin: 32 }}>Vastaanottajia ei ole valittu.</p>
	}

	const id = messageServiceProcess.step.childId || filteredSteps[0].id
	const activeChildIndex = filteredSteps.findIndex((s) => s.id === id)

	const handlePreviousClick = () => {
		const previousChild = filteredSteps[activeChildIndex - 1]

		if (previousChild) {
			onPreviousClick(previousChild.id)
		} else {
			onPreviousClick()
		}
	}

	const handleNextClick = () => {
		const nextChild = filteredSteps[activeChildIndex + 1]

		if (nextChild) {
			onNextClick(nextChild.id)
		} else {
			onNextClick()
		}
	}

	const formatStepTitle = (title) => {
		const progress = `(${activeChildIndex + 1}/${filteredSteps.length})`
		return `${title} ${progress}`
	}

	const step = filteredSteps[activeChildIndex]
	const Component = step.component

	return (
		<Component
			stepTitle={formatStepTitle(step.label)}
			onPreviousClick={handlePreviousClick}
			onDraftClick={onDraftClick}
			onNextClick={handleNextClick}
			onHide={onHide}
			messageServiceProcess={messageServiceProcess}
			setMessageServiceProcess={setMessageServiceProcess}
		/>
	)
}

export default Sending
