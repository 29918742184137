import React from 'react'
import PropTypes from 'prop-types'
import { compose, bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'

import * as userActions from 'state/user-actions'
import {
	FacebookAuthProvider,
	getAuth,
	GoogleAuthProvider,
} from 'firebase/auth'
import { firebaseApp } from 'state/store'
import { injectIntl } from 'react-intl'
import useDialogs from '../dialogs/useDialogs'
import FB_ERRORS from 'util/firebaseErrors'

const FirebaseUILogin = ({
	squished,
	onHide,
	intl,
	_push,
	_sendVerificationEmail,
}) => {
	const { alert } = useDialogs()

	const handleLoginSuccess = async (authResult) => {
		try {
			const user = authResult.user
			if (user?.providerData?.find((p) => p.providerId === 'password')) {
				if (user.email && !user.emailVerified) {
					const redirectURL = window.location.href
					const result = await _sendVerificationEmail(redirectURL)

					if (!result || result.success === false) {
						if (result?.ex?.code === FB_ERRORS.FUNCTIONS_FAILED_PRECONDITION) {
							const message = intl.formatMessage({
								defaultMessage:
									'Tapahtui virhe. Käyttämäsi sähköposti saattaa olla sulkulistalla. Otathan meihin yhteyttä asiakaspalvelu@vii.lu niin hoidetaan homma kuntoon.',
								description:
									'Error message telling the user that their email address might be blocked.',
							})
							await alert(message)
						} else if (
							result?.ex?.code === FB_ERRORS.FUNCTIONS_RESOURCE_EXHAUSTED
						) {
							const message = intl.formatMessage({
								defaultMessage:
									'Olet lähettänyt useita vahvistusviestejä pienessä ajassa. Odota hetki ja yritä uudelleen.',
								description:
									'Error message telling the user that they have attempted to send too many verification emails in a short timespan.',
							})
							await alert(message)
						} else {
							const message = intl.formatMessage({
								defaultMessage:
									'Vahvistussähköpostin lähettämisessä tapahtui virhe.',
								description:
									'Error message telling the user that there was an error while sending the verification email.',
							})
							await alert(message)
						}
						return false
					}
				}
			}
			if (window.URLBeforeLogin) {
				const redirectURL = window.URLBeforeLogin
				window.setTimeout(() => _push(redirectURL), 10000)
			}
			onHide()
		} catch (ex) {
			if (window.sentry) window.sentry.captureException(ex)
			console.error(ex)
		}
	}

	const usePopupSignInFlow =
		window?.location?.hostname === 'localhost' ||
		window?.location?.hostname.endsWith('viilu-web.pages.dev')

	const uiConfig = {
		signInFlow: usePopupSignInFlow ? 'popup' : 'redirect',
		signInOptions: [
			/*{
				provider: EmailAuthProvider.PROVIDER_ID,
				// Use email link authentication and do not require password.
				// Note this setting affects new users only.
				// For pre-existing users, they will still be prompted to provide their
				// passwords on sign-in.
				signInMethod: EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD,
				// Allow the user the ability to complete sign-in cross device, including
				// the mobile apps specified in the ActionCodeSettings object below.
				forceSameDevice: false,
				// Used to define the optional firebase.auth.ActionCodeSettings if
				// additional state needs to be passed along request and whether to open
				// the link in a mobile app if it is installed.
				emailLinkSignIn: function() {
				  return {
					url: window.location.href,
					// Always true for email link sign-in.
					handleCodeInApp: true,
				  }
				}
			},
			*/
			GoogleAuthProvider.PROVIDER_ID,
			FacebookAuthProvider.PROVIDER_ID,
		].filter((t) => t),
		callbacks: {
			signInSuccessWithAuthResult: (authResult) => {
				handleLoginSuccess(authResult)
				return false
			},
			signInFailure: (error) => {
				// Some unrecoverable error occurred during sign-in.
				// Return a promise when error handling is completed and FirebaseUI
				// will reset, clearing any UI. This commonly occurs for error code
				// 'firebaseui/anonymous-upgrade-merge-conflict' when merge conflict
				// occurs. Check below for more details on this.
				if (window.sentry) window.sentry.captureException(error)
				return Promise.resolve()
			},
		},
	}

	return (
		<div
			style={{
				position: 'relative',
				height: squished ? 0 : undefined,
				overflow: 'hidden',
			}}
		>
			<StyledFirebaseAuth
				uiConfig={uiConfig}
				firebaseAuth={getAuth(firebaseApp)}
			/>
		</div>
	)
}

FirebaseUILogin.propTypes = {
	squished: PropTypes.bool.isRequired,
	onHide: PropTypes.func.isRequired,
}

const mapState = () => ({})

const mapDispatchToProps = (dispatch) => ({
	_push: bindActionCreators(push, dispatch),
	_sendVerificationEmail: bindActionCreators(
		userActions._sendVerificationEmail,
		dispatch,
	),
})

export default compose(
	injectIntl,
	connect(mapState, mapDispatchToProps),
)(FirebaseUILogin)
