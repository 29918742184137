import React from 'react'
import { Button, Card, CardText, CardTitle, FontIcon } from 'react-md'
import styled from 'styled-components'
import { compose } from 'redux'
import getBuildInfo from '../../build-info'
import moment from 'moment-timezone'
import WithDeployments from 'ui/components/WithDeployments'

const ONE_WEEK = 604800000

const VersionIcon = styled(FontIcon)`
	font-size: 128px !important;
`
const VersionStatus = styled.h4`
	margin-top: 0;
`

const BuildInfo = ({
	inflightVersion,
	latestVersion,
	isLatestVersion,
	inflightVersionAge,
}) => {
	const { buildSHA } = getBuildInfo()

	const buildNumber = inflightVersion
		? inflightVersion.buildNumber
		: 'tieto puuttuu'
	const buildDate = inflightVersion
		? moment(new Date(inflightVersion.ts)).format('HH:mm DD.MM.YYYY')
		: 'tieto puuttuu'

	let updateUrgent = false
	if (inflightVersionAge > ONE_WEEK) {
		updateUrgent = true
	}

	return (
		<div className="flex-column flex-grow flex-center">
			<div className="flex-column flex-center margin-top margin-bottom--xl">
				{isLatestVersion ? (
					<>
						<VersionIcon style={{ color: 'green' }}>check_circle</VersionIcon>
						<VersionStatus>Käytössäsi on ajantasainen versio</VersionStatus>
					</>
				) : (
					<>
						<VersionIcon
							style={{ color: updateUrgent ? 'orangered' : 'orange' }}
						>
							change_circle
						</VersionIcon>
						<VersionStatus>Käytössäsi on vanhentunut versio</VersionStatus>
						<Button
							className="margin-top"
							primary
							flat={!updateUrgent}
							raised={updateUrgent}
							onClick={() => window.location.reload(true)}
							iconEl={<FontIcon>download_for_offline</FontIcon>}
						>
							Päivitä nyt
						</Button>
					</>
				)}
			</div>
			<Card>
				<CardTitle title="Viilu-palvelun versio" />
				<CardText>
					Käytössäsi on palvelun versio <strong>{buildNumber}</strong>, joka on
					julkaistu <strong>{buildDate}</strong>.
				</CardText>
			</Card>
			<p className="text-subtle" style={{ fontSize: 11, marginTop: 4 }}>
				{buildSHA}
			</p>
		</div>
	)
}

export default compose(WithDeployments)(BuildInfo)
