import { useCallback, useEffect, useState } from 'react'
import { _getConsolidatedInvoice } from 'state/finance-invoice-actions'
import type { ConsolidatedInvoice } from './types'

type State = {
	loading: boolean
	data: ConsolidatedInvoice[]
}

function useConsolidatedInvoice(companyUUID: string, batchUUID: string) {
	const [state, setState] = useState<State>({ loading: true, data: [] })

	const doQuery = useCallback(async () => {
		if (!companyUUID) {
			setState({ loading: false, data: [] })
			return
		}

		setState((prev) => ({ ...prev, loading: true }))
		let data: ConsolidatedInvoice[] = []
		try {
			const response = await _getConsolidatedInvoice({
				companyUUID,
				batchUUID,
			})
			if (response?.ok) {
				const result = await response.json()
				const { consolidatedInvoice } = result
				if (consolidatedInvoice && Array.isArray(consolidatedInvoice)) {
					data = consolidatedInvoice.sort(
						(a: ConsolidatedInvoice, b: ConsolidatedInvoice) =>
							new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime(),
					)
				}
			} else {
				throw new Error('Response is not ok.')
			}
		} catch (error) {
			console.error(error)
		}
		setState({
			loading: false,
			data,
		})
	}, [companyUUID, batchUUID])

	useEffect(() => {
		doQuery()
	}, [doQuery])

	return { ...state, refresh: doQuery }
}

export default useConsolidatedInvoice
