import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import {
	Button,
	CircularProgress,
	ExpansionList,
	ExpansionPanel,
} from 'react-md'
import {
	_saveInvoice,
	_sendInvoices,
	_voidInvoices,
} from 'state/finance-invoice-actions'
import styled from 'styled-components'
import useDialogs from 'ui/components/dialogs/useDialogs'
import InvoiceDetailsTable from './InvoiceDetailsTable'
import InvoicePaymentProductsTable from './InvoicePaymentProductsTable'
import InvoicePaymentsTable from './InvoicePaymentsTable'
import useInvoice from './useInvoice'
import type { ApartmentSelectOptions, VatSelectOptions } from '../types'
import { InvoiceStatus } from '../enum'
import InvoicePaymentAllocationView from './InvoicePaymentAllocationView'
import InvoiceStatusChip from './InvoiceStatusChip'
import InvoicePaymentAllocationEditDialog from './InvoicePaymentAllocationEditDialog'
import InvoiceCreateSinglePaymentProductsTable from './InvoiceCreateSinglePaymentProductsTable'
import type { PaymentProduct } from '../PaymentProducts/types'
import type { InvoicePaymentProduct, UpdateInvoice } from './types'
import ViiluFullPageDialog from 'ui/components/ViiluFullPageDialog'

const NotFound = styled.p`
	margin: 16px;
`

const Content = styled.div`
	flex: 1;
	display: flex;
	overflow: auto;
`

const Left = styled.div`
	padding: 16px;
	border-right: 1px solid rgba(0, 0, 0, 0.2);
`

const Right = styled.div`
	padding: 16px;
	flex: 1;
`

const Heading = styled.h2`
	margin: 0;
`

type Props = {
	invoiceUUID: string
	onHide: () => void
	refreshInvoices?: () => void
	apartmentOptions: ApartmentSelectOptions[]
	vatOptions: VatSelectOptions[]
	back?: boolean
	paymentProducts?: PaymentProduct[]
}

function InvoiceDialog({
	invoiceUUID,
	onHide,
	refreshInvoices,
	apartmentOptions,
	back,
	paymentProducts,
	vatOptions,
}: Props) {
	const intl = useIntl()
	const { data, loading, refresh } = useInvoice(invoiceUUID)
	const { confirm } = useDialogs()
	const [processing, setProcessing] = useState(false)

	const [isEditingPaymentAllocations, setIsEditingInvoicePaymentAllocations] =
		useState(false)

	const [invoicePaymentProducts, setInvoicePaymentProducts] = useState<
		Map<string, InvoicePaymentProduct>
	>(new Map())

	const [updateInvoice, setUpdateInvoice] = useState<UpdateInvoice>()

	useEffect(() => {
		if (data) {
			const paymentProducts = new Map<string, InvoicePaymentProduct>()
			data.paymentProducts.forEach((pp) => {
				paymentProducts.set(pp.uuid, pp)
			})

			setInvoicePaymentProducts(paymentProducts)
			setUpdateInvoice({
				...data,
				invoiceUUID: data.uuid,
				dueDate: data.dueDate,
				invoiceDate: data.invoiceDate,
			})
		}
	}, [data])

	if (loading) {
		return (
			<CircularProgress
				id="invoice-dialog-progress"
				style={{ marginTop: 64 }}
			/>
		)
	}

	if (!data || !updateInvoice) {
		return (
			<NotFound>
				{intl.formatMessage({
					defaultMessage: 'Laskua ei löytynyt.',
					description: 'Message for invoice not found.',
				})}
			</NotFound>
		)
	}

	const saveInvoice = async () => {
		if (!updateInvoice) {
			return
		}

		const paymentProducts = Array.from(invoicePaymentProducts.values()).map(
			(product) => {
				const updatedProduct = { ...product }

				updatedProduct.total = updatedProduct.price * updatedProduct.quantity
				updatedProduct.totalWithVat =
					updatedProduct.priceWithVat * updatedProduct.quantity

				return updatedProduct
			},
		)

		const confirmMessage = intl.formatMessage({
			defaultMessage: 'Tallennetaanko lasku?',
			description: 'Confirm message before saving an invoice.',
		})

		if (!(await confirm(confirmMessage))) {
			return
		}

		setProcessing(true)

		try {
			const response = await _saveInvoice(updateInvoice?.invoiceUUID, {
				...updateInvoice,
				paymentProducts: paymentProducts,
				status: InvoiceStatus.SAVED,
			})
			if (!response?.ok) {
				throw new Error('Response is not ok.')
			}
			refreshInvoices?.()
			refresh()
		} catch (error) {
			console.error(error)
		}

		setProcessing(false)
	}

	const createInvoice = async () => {
		if (!updateInvoice) {
			return
		}

		const paymentProducts = Array.from(invoicePaymentProducts.values()).map(
			(product) => {
				const updatedProduct = { ...product }

				updatedProduct.total = updatedProduct.price * updatedProduct.quantity
				updatedProduct.totalWithVat =
					updatedProduct.priceWithVat * updatedProduct.quantity

				return updatedProduct
			},
		)

		const confirmMessage = intl.formatMessage({
			defaultMessage:
				'Haluatko varmasti luoda laskun, laskua ei voi enään muokata tämän jälkeen?',
			description: 'Confirm message before creating an invoice.',
		})

		if (!(await confirm(confirmMessage))) {
			return
		}

		setProcessing(true)

		try {
			const response = await _saveInvoice(updateInvoice?.invoiceUUID, {
				...updateInvoice,
				paymentProducts: paymentProducts,
				status: InvoiceStatus.CREATED,
			})
			if (!response?.ok) {
				throw new Error('Response is not ok.')
			}
			refreshInvoices?.()
			refresh()
		} catch (error) {
			console.error(error)
		}

		setProcessing(false)
	}

	const sendInvoice = async () => {
		if (!data) {
			return
		}

		// TODO: Actual sending is not implemented yet. This only sets the status as 'sent'.
		const confirmMessage = intl.formatMessage({
			defaultMessage:
				'Lähetetäänkö lasku? Vaihtaa vain statuksen tällä hetkellä.',
			description: 'Confirm message before sending an invoice.',
		})

		if (!(await confirm(confirmMessage))) {
			return
		}

		setProcessing(true)

		try {
			const response = await _sendInvoices([data?.uuid])
			if (!response?.ok) {
				throw new Error('Response is not ok.')
			}
			refreshInvoices?.()
			refresh()
		} catch (error) {
			console.error(error)
		}

		setProcessing(false)
	}

	const voidInvoice = async () => {
		if (!data) {
			return
		}

		// TODO: Actual voiding is not implemented yet. This only sets the status as 'void'.
		const confirmMessage = intl.formatMessage({
			defaultMessage:
				'Haluatko mitätöidä lasku? Vaihtaa vain statuksen tällä hetkellä.',
			description: 'Confirm message before voiding an invoice.',
		})

		if (!(await confirm(confirmMessage))) {
			return
		}

		setProcessing(true)

		try {
			const response = await _voidInvoices([data?.uuid])
			if (!response?.ok) {
				throw new Error('Response is not ok.')
			}
			refreshInvoices?.()
			refresh()
		} catch (error) {
			console.error(error)
		}

		setProcessing(false)
	}

	const renderEditableContent = () => {
		return (
			<>
				<Heading>
					{intl.formatMessage({
						defaultMessage: 'Laskurivit',
						description: 'Heading for invoice payment products.',
					})}
				</Heading>
				<InvoiceCreateSinglePaymentProductsTable
					selectedPaymentProducts={invoicePaymentProducts}
					setPaymentProducts={setInvoicePaymentProducts}
					paymentProducts={paymentProducts || []}
					vatOptions={vatOptions}
				/>
			</>
		)
	}

	const renderRightContent = () => {
		return (
			<>
				<Heading>
					{intl.formatMessage({
						defaultMessage: 'Laskurivit',
						description: 'Heading for invoice payment products.',
					})}
				</Heading>
				<InvoicePaymentProductsTable
					invoicePaymentProducts={data.paymentProducts}
				/>
				<Heading style={{ marginTop: 32 }}>
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							width: '100%',
						}}
					>
						{intl.formatMessage({
							defaultMessage: 'Suoritukset',
							description: 'Heading for invoice payments.',
						})}
						{!isEditingPaymentAllocations ? (
							<Button
								flat
								secondary
								onClick={() => setIsEditingInvoicePaymentAllocations(true)}
							>
								{intl.formatMessage({
									defaultMessage: 'Muokkaa',
									description: 'TODO',
								})}
							</Button>
						) : null}
					</div>
				</Heading>

				{isEditingPaymentAllocations ? (
					<InvoicePaymentAllocationEditDialog
						invoice={data}
						invoiceAllocations={data.invoiceAllocations}
						refreshInvoice={() => refresh()}
						onStopEditing={() => setIsEditingInvoicePaymentAllocations(false)}
					/>
				) : (
					<InvoicePaymentsTable invoiceAllocations={data.invoiceAllocations} />
				)}
				{data.status === InvoiceStatus.SENT && (
					<ExpansionList style={{ padding: '48px 0px 24px 0px' }}>
						<ExpansionPanel
							label={
								<Heading style={{ margin: 0 }}>
									{intl.formatMessage({
										defaultMessage: 'Kohdista suorituksia laskuun',
										description: 'Heading for allocate payments to invoice.',
									})}
								</Heading>
							}
							defaultExpanded={false}
							footer={null}
						>
							<InvoicePaymentAllocationView
								invoice={data}
								refreshInvoices={refresh}
							/>
						</ExpansionPanel>
					</ExpansionList>
				)}
			</>
		)
	}

	const renderContent = () => {
		return (
			<Content>
				<Left>
					<InvoiceStatusChip status={data.status} />
					<InvoiceDetailsTable
						entity={updateInvoice}
						setEntity={
							setUpdateInvoice as React.Dispatch<
								React.SetStateAction<UpdateInvoice>
							>
						}
						apartmentOptions={apartmentOptions ?? []}
					/>
				</Left>

				<Right>
					{data?.status === InvoiceStatus.SAVED
						? renderEditableContent()
						: renderRightContent()}
				</Right>
			</Content>
		)
	}

	const title = intl.formatMessage({
		defaultMessage: 'Lasku',
		description: 'Title for the invoice dialog.',
	})

	const actions: JSX.Element[] = []

	if (data?.status === InvoiceStatus.SAVED) {
		actions.push(
			<Button
				style={{ marginRight: '5px' }}
				raised
				secondary
				disabled={processing}
				onClick={createInvoice}
			>
				{intl.formatMessage({
					defaultMessage: 'Luo lasku',
					description: 'Label for the create invoice button.',
				})}
			</Button>,
		)
		actions.push(
			<Button raised secondary disabled={processing} onClick={saveInvoice}>
				{intl.formatMessage({
					defaultMessage: 'Tallenna lasku',
					description: 'Label for the save invoice button.',
				})}
			</Button>,
		)
	}

	if (data?.status === InvoiceStatus.CREATED) {
		actions.push(
			<Button
				style={{ marginRight: '5px' }}
				raised
				secondary
				disabled={processing}
				onClick={voidInvoice}
			>
				{intl.formatMessage({
					defaultMessage: 'Mitätöi',
					description: 'Label for the void invoice button.',
				})}
			</Button>,
		)
		actions.push(
			<Button raised secondary disabled={processing} onClick={sendInvoice}>
				{intl.formatMessage({
					defaultMessage: 'Lähetä',
					description: 'Label for the send invoice button.',
				})}
			</Button>,
		)
	}

	return (
		<ViiluFullPageDialog
			id="invoice-dialog"
			title={title}
			visible
			onHide={onHide}
			focusOnMount={false}
			toolbarStyle={{
				background: 'var(--color-secondary-dark)',
			}}
			nav={
				<Button icon onClick={onHide}>
					{back ? 'arrow_back' : 'close'}
				</Button>
			}
			navActions={actions}
		>
			{renderContent()}
		</ViiluFullPageDialog>
	)
}

export default InvoiceDialog
