export enum InvoicingMethod {
	E_INVOICE = 'eInvoice',
	WEB_INVOICE = 'webInvoice',
	PAPAER_INVOICE = 'paperInvoice',
}

export enum BondName {
	TENANT = 'tenant',
	SHAREHOLDER = 'shareholder',
	SHAREHOLDER_NOT_REGISTERED = 'shareholder_not_registered',
}

export enum PaymentProductBillingBasis {
	AREA = 'area',
	AREA_ADJUSTED = 'area-adjusted',
	SHARE_COUNT = 'share-count',
	RESIDENT_COUNT = 'resident-count',
	PIECES = 'pieces',
}

export enum InvoiceStatus {
	SAVED = 'saved',
	CREATED = 'created',
	SENT = 'sent',
	PAID = 'paid',
	VOID = 'void',
	VOID_BY_UPDATION = 'void_by_updation',
}
export const ActiveInvoiceStatus = [
	InvoiceStatus.SENT,
	InvoiceStatus.PAID,
] as const

export const VoidedInvoiceStatus = [
	InvoiceStatus.VOID,
	InvoiceStatus.VOID_BY_UPDATION,
] as const

export const InvoiceStatusConfig: Record<
	InvoiceStatus,
	{ icon: string; color: string }
> = {
	[InvoiceStatus.SAVED]: { icon: 'save', color: 'teal' },
	[InvoiceStatus.CREATED]: { icon: 'create', color: 'teal' },
	[InvoiceStatus.SENT]: { icon: 'send', color: 'orange' },
	[InvoiceStatus.PAID]: { icon: 'check_circle', color: 'green' },
	[InvoiceStatus.VOID]: { icon: 'block', color: 'red' },
	[InvoiceStatus.VOID_BY_UPDATION]: { icon: 'block', color: 'red' },
}

export enum TaxPeriodLength {
	MONTH = 'month',
	QUARTER = 'quarter',
	YEAR = 'year',
}

export enum Operator {
	'012345' = 'Apix',
}

export enum Direction {
	INVOICE = 'invoice',
}

export enum CountryCode {
	FI = 'FI',
}

export enum InvoicingMonth {
	JANUARY = 1,
	FEBRUARY = 2,
	MARCH = 3,
	APRIL = 4,
	MAY = 5,
	JUNE = 6,
	JULY = 7,
	AUGUST = 8,
	SEPTEMBER = 9,
	OCTOBER = 10,
	NOVEMBER = 11,
	DECEMBER = 12,
}

export enum AccountsLedgerGroupBy {
	APARTMENT = 'apartment',
	CONTRACT = 'contract',
	PARTY = 'party',
}

export enum AccountsLedgerView {
	ACTUALS = 'actuals',
	FORECAST = 'forecast',
}
