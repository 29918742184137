import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { isEmpty, isLoaded } from 'react-redux-firebase'
import styled from 'styled-components'

import * as userActions from 'state/user-actions'
import LoadingState from './LoadingState'
import { getAuth, getIdToken, getIdTokenResult } from 'firebase/auth'
import { firebaseApp } from 'state/store'
import { FormattedMessage } from 'react-intl'

const ProgressContainer = styled.div`
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: white;
	z-index: 100;
`
const ProgressCircleContainer = styled.div`
	height: 100px;
	width: 100px;
`

export default (companyUUIDFunc, skipFunc) => (ComponentToWrap) => {
	class WithUserCompanyClaims extends Component {
		state = {
			loading: skipFunc ? !skipFunc(this.props) : true,
		}

		checkClaims = async (companyUUID) => {
			const { auth, _updateClaims, partnerUUID } = this.props
			if (!companyUUID) {
				console.error('companyUUID is not defined')
				const error = 'Taloyhtiötä ei ole valittu.'
				this.setState({ error })
				return null
			}
			if (!isLoaded(auth)) {
				console.log('user is not loaded')
				return window.setTimeout(this.checkClaims, 1000)
			}
			if (isEmpty(auth)) {
				this.setState({ loading: false })
				console.error('user is not authenticated')
				return null
			}

			const result = await getIdTokenResult(
				getAuth(firebaseApp).currentUser,
				false,
			)
			if (result.claims[companyUUID] === undefined) {
				const updateResult = await _updateClaims(companyUUID, partnerUUID)
				await getIdToken(getAuth(firebaseApp).currentUser, true)
				if (updateResult && updateResult.data === true) {
					this.setState({ loading: false })
					console.log('claims update')
					return true
				}
				console.log('claims update failed')
				const error = 'Sinulla ei ole vaadittavia käyttöoikeuksia.'
				this.setState({ error })
				return false
			} else {
				this.setState({ loading: false })
				console.log('claims up-to-date')
				return true
			}
		}

		componentDidMount = () => {
			if (skipFunc && skipFunc(this.props)) {
				return
			}
			const companyUUID = companyUUIDFunc(this.props)
			this.checkClaims(companyUUID)
		}

		render() {
			const { loading, error } = this.state
			if (error) {
				return (
					<p className="text-center text-strong margin-left margin-bottom">
						{error}
					</p>
				)
			}
			if (loading) {
				return (
					<ProgressContainer>
						<ProgressCircleContainer>
							<LoadingState />
						</ProgressCircleContainer>
						<p className="text-subtle">
							<FormattedMessage
								defaultMessage="Varmennetaan oikeuksia..."
								description="Loading text shown when the company permissions are being verified."
							/>
						</p>
					</ProgressContainer>
				)
			}
			return <ComponentToWrap {...this.props} />
		}
	}

	const mapState = ({ firebaseReducer: { auth } }) => ({
		auth,
	})

	const mapDispatchToProps = (dispatch) => ({
		_updateClaims: bindActionCreators(userActions._updateClaims, dispatch),
	})

	return compose(connect(mapState, mapDispatchToProps))(WithUserCompanyClaims)
}
