import type { IntlShape } from 'react-intl'
import { PaymentHeader } from './types'

export const paymentHeaders: PaymentHeader[] = [
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Huoneisto',
				description: 'Table header for finance payment apartment column.',
			}),
		key: 'apartmentIdentifier',
		type: 'apartmentOptions',
		show: true,
		sortable: true,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Sopimus',
				description: 'Table header for finance payment contract column.',
			}),
		key: 'contractUUID',
		show: true,
		sortable: true,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Maksaja',
				description: 'Table header for finance payment party column.',
			}),
		key: 'partyName',
		show: true,
		sortable: true,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Viitenumero',
				description:
					'Table header for finance payment reference number column.',
			}),
		key: 'referenceNumber',
		show: true,
		sortable: true,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Arkistotunnus',
				description:
					'Table header for finance payment archive identifier column.',
			}),
		key: 'archiveId',
		show: true,
		sortable: true,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Maksupäivä',
				description: 'Table header for finance payment date column.',
			}),
		key: 'paymentDate',
		type: 'date',
		show: true,
		sortable: true,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Kirjauspäivä',
				description: 'Table header for finance payment recorded date column.',
			}),
		key: 'recordedDate',
		type: 'date',
		show: true,
		sortable: true,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Summa',
				description: 'Table header for finance payment total column.',
			}),
		key: 'total',
		type: 'currency',
		show: true,
		sortable: true,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Kohdistettu',
				description: 'Table header for finance payment matched column.',
			}),
		key: 'matched',
		type: 'boolean',
		show: true,
		sortable: true,
	},
]
