import { FontIcon } from 'react-md'
import styled from 'styled-components'

const Container = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: ${(props) => props.bgColor};
	border-radius: 4px;

	.md-icon {
		color: #fff;
		font-size: 40px;
	}
`
function LinkIcon({ icon, bgColor }) {
	return (
		<Container bgColor={bgColor}>
			<FontIcon>{icon}</FontIcon>
		</Container>
	)
}

export default LinkIcon
