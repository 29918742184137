import React, { useState, useEffect } from 'react'

import { PropTypes } from 'prop-types'
import styled from 'styled-components'

import LoginDialogToolbar from './Toolbar'
import EmailButton from './AuthButtons/EmailButton'
import GoogleButton from './AuthButtons/GoogleButton'
import MicrosoftButton from './AuthButtons/MicrosoftButton'
import FacebookButton from './AuthButtons/FacebookButton'
import SMSButton from './AuthButtons/SMSButton'
import LoginDialogFooter from './Footer'
import LoginDialogPhone from './PhoneAuth/LoginDialogPhone'
import FirebaseUILogin from './FirebaseUILogin'
import LoginDialogEmail from './EmailAuth/LoginDialogEmail'
import { compose } from 'redux'
import { injectIntl } from 'react-intl'
import LoadingState from '../LoadingState'
import SignInButton from './AuthButtons/SignInButton'
import SignUpButton from './AuthButtons/SignUpButton'
import BackButton from './AuthButtons/BackButton'
import ViiluDialog from 'ui/components/ViiluDialog'
import WithQueryParams from '../WithQueryParams'

const EMAIL = 'email'
const GOOGLE = 'google'
const MICROSOFT = 'microsoft'
const FACEBOOK = 'facebook'
const SMS = 'sms'

const DialogPadding = styled.div`
	padding: 24px;
`
const ButtonContainer = styled.div`
	display: flex;
	flex-direction: column;
`
const StallingContainer = styled.div`
	height: 200px;
`

const STEPS = {
	INTRO: 'intro',
	LOGIN: 'login',
	SIGNUP: 'signup',
}

const LoginDialog = ({ onHide, intl, queryParams }) => {
	const isManagerView =
		window.location.pathname.includes('/manager') || queryParams.manager
	const [stepId, setStepId] = useState(STEPS.INTRO)
	const [visibleAuthMethods, setVisibleAuthMethods] = useState(
		[EMAIL, GOOGLE, isManagerView ? MICROSOFT : null, SMS].filter((t) => t),
	)
	const [phoneLoginVisible, setPhoneLoginVisible] = useState(false)
	const [emailLoginVisible, setEmailLoginVisible] = useState(false)
	const [stalling, setStalling] = useState(true)

	useEffect(() => {
		window.setTimeout(() => {
			if (window.isNativeApp && window.ReactNativeWebView) {
				if (navigator.userAgent && !navigator.userAgent.includes('Viilu')) {
					// Set the auth methods separately for the Aarre Easy app
					// Since it doesn't support social logins
					setVisibleAuthMethods([EMAIL, SMS])
				}
			}
			setStalling(false)
		}, 2000)
	})

	const cleanupAndHide = () => {
		window.URLBeforeLogin = null
		if (onHide) {
			onHide()
		}
	}

	const handleEmailClick = () => {
		setEmailLoginVisible(true)
	}

	const handleGoogleClick = () => {
		const results = window.document.getElementsByClassName(
			'firebaseui-idp-google',
		)
		if (!results || results.length === 0) {
			return false
		}
		const element = results[0]
		element.click()
	}

	const handleMicrosoftClick = () => {
		const results = window.document.getElementsByClassName(
			'firebaseui-idp-generic',
		)
		if (!results || results.length === 0) {
			return false
		}

		const filteredResults = [...results].filter(
			(r) => r.dataset.providerId === 'microsoft.com',
		)
		if (!filteredResults || filteredResults.length === 0) {
			return false
		}

		const element = filteredResults[0]
		element.click()
	}

	const handleFacebookClick = () => {
		const results = window.document.getElementsByClassName(
			'firebaseui-idp-facebook',
		)
		if (!results || results.length === 0) {
			return false
		}
		const element = results[0]
		element.click()
	}

	const handleSMSClick = () => {
		setPhoneLoginVisible(true)
	}

	const renderLoginStep = () => {
		return (
			<ButtonContainer>
				{visibleAuthMethods.includes(EMAIL) ? (
					<EmailButton onClick={handleEmailClick} />
				) : null}
				{visibleAuthMethods.includes(GOOGLE) ? (
					<GoogleButton onClick={handleGoogleClick} />
				) : null}
				{visibleAuthMethods.includes(MICROSOFT) ? (
					<MicrosoftButton onClick={handleMicrosoftClick} />
				) : null}
				{visibleAuthMethods.includes(FACEBOOK) ? (
					<FacebookButton onClick={handleFacebookClick} />
				) : null}
				{visibleAuthMethods.includes(SMS) ? (
					<SMSButton onClick={handleSMSClick} />
				) : null}
				<BackButton onClick={() => setStepId(STEPS.INTRO)} />
			</ButtonContainer>
		)
	}

	const renderSignupStep = () => {
		return (
			<ButtonContainer>
				{visibleAuthMethods.includes(EMAIL) ? (
					<EmailButton onClick={handleEmailClick} />
				) : null}
				{visibleAuthMethods.includes(GOOGLE) ? (
					<GoogleButton onClick={handleGoogleClick} />
				) : null}
				{visibleAuthMethods.includes(MICROSOFT) ? (
					<MicrosoftButton onClick={handleMicrosoftClick} />
				) : null}
				{visibleAuthMethods.includes(FACEBOOK) ? (
					<FacebookButton onClick={handleFacebookClick} />
				) : null}
				<BackButton onClick={() => setStepId(STEPS.INTRO)} />
			</ButtonContainer>
		)
	}

	const renderStalling = () => {
		// We wait for a couple of seconds before showing the auth choices
		// This is done to not flash the buttons to the user when they come back
		// from an auth callback
		return (
			<StallingContainer>
				<LoadingState />
			</StallingContainer>
		)
	}

	const renderIntroStep = () => {
		return (
			<ButtonContainer>
				<SignInButton onClick={() => setStepId(STEPS.LOGIN)} />
				<SignUpButton onClick={() => setStepId(STEPS.SIGNUP)} />
			</ButtonContainer>
		)
	}

	const renderStep = () => {
		if (stepId === STEPS.INTRO) {
			return renderIntroStep()
		} else if (stepId === STEPS.LOGIN) {
			return renderLoginStep()
		} else if (stepId === STEPS.SIGNUP) {
			return renderSignupStep()
		}
		return renderIntroStep()
	}

	let dialogTitle
	if (stepId === STEPS.INTRO) {
		dialogTitle = intl.formatMessage({
			defaultMessage: 'Kirjaudu tai luo tunnus',
			description: 'Login / Signup dialog title',
		})
	} else if (stepId === STEPS.LOGIN) {
		dialogTitle = intl.formatMessage({
			defaultMessage: 'Kirjaudu sisään',
			description: 'Login / Signup dialog title',
		})
	} else if (stepId === STEPS.SIGNUP) {
		dialogTitle = intl.formatMessage({
			defaultMessage: 'Luo uusi tunnus',
			description: 'Login / Signup dialog title',
		})
	}

	return (
		<div>
			<ViiluDialog
				id="responsive-dialog"
				aria-label={dialogTitle}
				visible={true}
				onHide={cleanupAndHide}
				containFocus={true}
				focusOnMount={true}
				autosizeContent={false}
				paddedContent={false}
				modal
				portal
				onClick={(e) => {
					e.preventDefault()
					e.stopPropagation()
				}}
				onDoubleClick={(e) => {
					e.preventDefault()
					e.stopPropagation()
				}}
				dialogStyle={{
					borderRadius: 8,
				}}
			>
				<LoginDialogToolbar onHide={cleanupAndHide} title={dialogTitle} />
				<DialogPadding>
					{stalling ? renderStalling() : renderStep()}
				</DialogPadding>
				<FirebaseUILogin onHide={cleanupAndHide} squished={true} />
				<LoginDialogFooter />
			</ViiluDialog>
			<LoginDialogPhone
				visible={phoneLoginVisible}
				onHide={() => setPhoneLoginVisible(false)}
			/>
			<LoginDialogEmail
				visible={emailLoginVisible}
				onHide={() => setEmailLoginVisible(false)}
				signUpAllowed={stepId === STEPS.SIGNUP}
			/>
		</div>
	)
}

LoginDialog.propTypes = {
	onHide: PropTypes.func.isRequired,
}

export default compose(injectIntl, WithQueryParams)(LoginDialog)
