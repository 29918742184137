import { TableContainer } from 'ui/StyledComponents/Tables'
import FloatingActionBtn from 'ui/components/FloatingActionBtn'
import { useIntl } from 'react-intl'
import {
	TableRow,
	TableColumn,
	DataTable,
	TableHeader,
	TableBody,
	ListItem,
	FontIcon,
} from 'react-md'
import OverflowGradient from 'ui/components/OverflowGradient'
import { useState } from 'react'
import SettingsTableRow from './SettingsTableRow'
import { webInvoiceHeaders } from './constants'
import WebInvoiceDialog from './WebInvoiceDialog'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import config from 'config'
import useDialogs from 'ui/components/dialogs/useDialogs'
import * as notifyActions from 'state/notifyActions'
import { _deleteWebInvoiceAddressSetting } from 'state/settings-actions'
import { GENERIC_ERROR_NOTIFICATION } from 'ui/messages'
import type { UseWebInvoiceAddressesResult } from './useWebInvoiceAddresses'

type Props = {
	selectedFinanceCompanyUUID: string
	useWebInvoiceAddresses: UseWebInvoiceAddressesResult
	webInvoiceUUID: string | null
}

const HASH_WEB_INVOICE_ADDRESS = config.routeHashes.webInvoiceAddress

const WebInvoice = ({
	selectedFinanceCompanyUUID,
	useWebInvoiceAddresses,
	webInvoiceUUID,
}: Props) => {
	const intl = useIntl()
	const dispatch = useDispatch()
	const { confirm } = useDialogs()
	const [processing, setProcessing] = useState<boolean>(false)

	const [addDialogVisible, setDialogVisible] = useState<boolean>(false)

	const { webInvoiceAddresses, refreshWebInvoiceAddresses } =
		useWebInvoiceAddresses

	const showAddWebInvoiceDialog = () => {
		setDialogVisible(true)
	}

	const hideAddWebInvoiceDialog = () => {
		if (webInvoiceUUID) {
			dispatch(push(window.location.pathname + HASH_WEB_INVOICE_ADDRESS))
		}
		setDialogVisible(false)
	}

	const renderFAB = () => {
		return (
			<FloatingActionBtn
				iconName={'add'}
				text={intl.formatMessage({
					defaultMessage: 'UUSI OSOITE',
					description: 'Add new web invoice address',
				})}
				action={showAddWebInvoiceDialog}
			/>
		)
	}
	const deleteWebInvoice = async (uuid: string) => {
		const confirmMessage = intl.formatMessage({
			defaultMessage: 'Oletko varma, että haluat poistaa verkkolaskuosoitteen?',
			description: 'Confirm message for the delete web invoice address.',
		})
		if (!(await confirm(confirmMessage))) {
			return
		}

		setProcessing(true)

		const result = await _deleteWebInvoiceAddressSetting(uuid)
		setProcessing(false)

		if (!result || result.ok === false) {
			dispatch(notifyActions.info('❌ ' + GENERIC_ERROR_NOTIFICATION(intl)))
			return false
		}

		dispatch(
			notifyActions.info(
				'✅ ' +
					intl.formatMessage({
						defaultMessage: 'Verkkolaskuosoite on poistettu.',
						description:
							'Notification text web invoice address has been deleted',
					}),
			),
		)
		refreshWebInvoiceAddresses()
		return true
	}

	const renderTableHeaders = () => {
		return (
			<TableRow>
				{webInvoiceHeaders
					.filter((h) => h.show)
					.map((header) => (
						<TableColumn key={header.key}>
							<p className="text-strong text-subtle">{header.title(intl)}</p>
						</TableColumn>
					))}
				<TableColumn key={'children-header'}></TableColumn>
			</TableRow>
		)
	}

	const handleOnClick = (uuid: string) => {
		dispatch(push(window.location.pathname + '?wuuid=' + uuid))
	}

	return (
		<TableContainer>
			{webInvoiceAddresses ? (
				<TableContainer>
					<DataTable
						plain
						style={{
							background: 'white',
							maxHeight: 'calc(100vh - 205px)',
						}}
					>
						<TableHeader
							style={{
								position: 'sticky',
								top: 0,
								zIndex: 1,
								background: 'white',
							}}
						>
							{renderTableHeaders()}
						</TableHeader>
						<TableBody>
							{webInvoiceAddresses
								.map((webInvoiceAddress) => (
									<SettingsTableRow
										key={webInvoiceAddress.uuid}
										entity={webInvoiceAddress}
										menuItems={[
											<ListItem
												key={1}
												primaryText={intl.formatMessage({
													defaultMessage: 'Poista osoite',
													description: 'Delete web invoice address',
												})}
												leftIcon={<FontIcon>delete</FontIcon>}
												onClick={() => deleteWebInvoice(webInvoiceAddress.uuid)}
												className="md-divider-border md-divider-border--right"
											/>,
										]}
										children={null}
										processing={processing}
										headers={webInvoiceHeaders}
										handleOnClick={() => handleOnClick(webInvoiceAddress.uuid)}
									/>
								))
								.concat(
									<tr
										key="empty-row"
										className="full-width"
										style={{ height: 100, background: 'white' }}
									></tr>,
								)}
						</TableBody>
						<OverflowGradient style={{ bottom: 40 }} />
					</DataTable>
				</TableContainer>
			) : null}

			<WebInvoiceDialog
				id={webInvoiceUUID}
				useWebInvoiceAddresses={useWebInvoiceAddresses}
				visible={typeof webInvoiceUUID === 'string' || addDialogVisible}
				onHide={hideAddWebInvoiceDialog}
				selectedFinanceCompanyUUID={selectedFinanceCompanyUUID}
			/>

			{renderFAB()}
		</TableContainer>
	)
}

export default WebInvoice
