import { injectIntl } from 'react-intl'
import { compose } from 'redux'
import SummaryItems from '../SummaryItems'
import config from 'config'

function BulletinsSummary({
	bulletinsSettings,
	messageServiceProcessSentTs,
	intl,
}) {
	const getIcon = (role) => {
		return bulletinsSettings.distributionOptions[role] ? 'check' : 'block'
	}

	const formatTs = (ts) => {
		if (!ts) {
			return null
		}

		return new Date(ts).toLocaleString('fi', {
			dateStyle: 'medium',
			timeStyle: 'short',
		})
	}

	const formatPublishTs = () => {
		const tsString = formatTs(
			bulletinsSettings.publishTs || messageServiceProcessSentTs,
		)

		if (!tsString) {
			return 'Julkaistaan heti'
		}

		return `Julkaisuaika ${tsString}`
	}

	const formatExpirationTs = () => {
		const tsString = formatTs(bulletinsSettings.expirationTs)

		if (!tsString) {
			return 'Ei poistumisaikaa'
		}

		return `Poistumisaika ${tsString}`
	}

	const r = config.userRoles

	const items = [
		{
			icon: getIcon(r.userTenant),
			text: 'Asukkaille',
		},
		{
			icon: getIcon(r.userShareholder),
			text: 'Osakkaille',
		},
		{
			icon: getIcon(r.adminBoardMember),
			text: 'Hallitukselle',
		},
		{
			icon: 'event',
			text: formatPublishTs(),
		},
		{
			icon: 'event',
			text: formatExpirationTs(),
		},
	]

	return (
		<>
			<h2>Ilmoitustaulu (maksuton)</h2>
			<SummaryItems items={items} />
		</>
	)
}

export default compose(injectIntl)(BulletinsSummary)
