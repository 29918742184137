import { useEffect, useState } from 'react'
import { Button, CircularProgress } from 'react-md'
import { compose, bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import SendingStatus from './SendingStatus'
import * as messageServiceActions from 'state/message-service-actions'
import * as notifyActions from 'state/notifyActions'
import { injectIntl } from 'react-intl'
import { GENERIC_ERROR_NOTIFICATION } from 'ui/messages'
import ViiluDialog from 'ui/components/ViiluDialog'
import { firestoreConnect } from 'react-redux-firebase'

const DialogStatus = {
	CONFIRM: 'confirm',
	SENDING: 'sending',
	DONE: 'done',
}

export const SendStatus = {
	NOT_SENT: 'not_sent',
	SENDING: 'sending',
	SUCCESS: 'success',
	ERROR: 'error',
}

const initSendingStatus = (sendingStatus) => ({
	email: SendStatus.NOT_SENT,
	sms: SendStatus.NOT_SENT,
	letter: SendStatus.NOT_SENT,
	bulletins: SendStatus.NOT_SENT,
	infoscreen: SendStatus.NOT_SENT,
	integrations: SendStatus.NOT_SENT,
	...sendingStatus,
})

function SendDialog({
	visible,
	onHide,
	closeSending,
	messageServiceProcess,
	emailRecipients,
	smsRecipients,
	letterRecipients,
	letterPageCount,
	hasBulletins,
	hasInfoscreen,
	hasIntegrations,
	syncedMessageServiceProcess,
	_sendAll,
	_showInfoNotification,
	intl,
}) {
	const [dialogStatus, setDialogStatus] = useState(DialogStatus.CONFIRM)
	const [sendingStatus, setSendingStatus] = useState(
		initSendingStatus(syncedMessageServiceProcess?.sendingStatus),
	)

	useEffect(() => {
		const synced = syncedMessageServiceProcess?.sendingStatus
		setSendingStatus(initSendingStatus(synced))
		const statuses = Object.values(synced || {})

		if (statuses.some((s) => s === 'sending')) {
			setDialogStatus(DialogStatus.SENDING)
		} else if (statuses.length > 0 && statuses.every((s) => s === 'success')) {
			setDialogStatus(DialogStatus.DONE)
		} else {
			setDialogStatus(DialogStatus.CONFIRM)
		}
	}, [syncedMessageServiceProcess?.sendingStatus])

	const hasEmails = emailRecipients?.length > 0
	const hasSMSs = smsRecipients?.length > 0
	const hasLetters = letterRecipients.length > 0

	const updateStatus = (dialogStatus, sendStatus) => {
		setDialogStatus(dialogStatus)

		const updated = {
			email: sendStatus,
			sms: sendStatus,
			letter: sendStatus,
			bulletins: sendStatus,
			infoscreen: sendStatus,
			integrations: sendStatus,
		}

		if (syncedMessageServiceProcess?.sendingStatus) {
			Object.entries(syncedMessageServiceProcess.sendingStatus).forEach(
				([key, value]) => {
					if (value === SendStatus.SUCCESS) {
						updated[key] = SendStatus.SUCCESS
					}
				},
			)
		}

		setSendingStatus(updated)
	}

	const handleSend = async () => {
		updateStatus(DialogStatus.SENDING, SendStatus.SENDING)
		const result = await _sendAll(
			messageServiceProcess.companyUUID,
			messageServiceProcess.uuid,
			letterPageCount,
		)
		if (!result?.data?.success) {
			_showInfoNotification(`❌ ${GENERIC_ERROR_NOTIFICATION(intl)}`)
			updateStatus(DialogStatus.CONFIRM, SendStatus.NOT_SENT)
		}
	}

	const handleOnHide = () => {
		onHide()
	}

	let dialogTitle
	let actions

	if (dialogStatus === DialogStatus.CONFIRM) {
		dialogTitle = intl.formatMessage({
			defaultMessage: 'Lähetetäänkö?',
			description:
				'Title for the confirm sending dialog in the general sender component summary step.',
		})
		actions = [
			<Button flat onClick={handleOnHide}>
				{intl.formatMessage({
					defaultMessage: 'Ei',
					description:
						'Label for the button that cancels the confirmation of the sending in the general sender component summary step.',
				})}
			</Button>,
			<Button raised secondary onClick={handleSend}>
				{intl.formatMessage({
					defaultMessage: 'Kyllä',
					description:
						'Label for the button that confirms the sending in the general sender component summary step.',
				})}
			</Button>,
		]
	}

	if (dialogStatus === DialogStatus.SENDING) {
		dialogTitle = intl.formatMessage({
			defaultMessage: 'Lähetetään...',
			description:
				'Title for the confirm sending dialog while processing the sending in the general sender component summary step.',
		})
		actions = null
	}

	if (dialogStatus === DialogStatus.DONE) {
		dialogTitle = intl.formatMessage({
			defaultMessage: 'Lähetetty',
			description:
				'Title for the confirm sending dialog after the sending has been processed in the general sender component summary step.',
		})
		actions = (
			<Button raised secondary onClick={closeSending}>
				{intl.formatMessage({
					defaultMessage: 'Sulje',
					description:
						'Label for the button that closes the sending dialog in the general sender component summary step.',
				})}
			</Button>
		)
	}

	const isSynced =
		messageServiceProcess.uuid === syncedMessageServiceProcess?.uuid

	return (
		<ViiluDialog
			id="message-service-send-dialog"
			visible={visible}
			onHide={handleOnHide}
			title={isSynced ? dialogTitle : null}
			focusOnMount={false}
			actions={isSynced ? actions : null}
			modal={true}
			closeOnEsc={false}
			dialogStyle={{ minWidth: '320px', borderRadius: '8px' }}
		>
			{isSynced ? (
				<>
					{hasEmails && (
						<SendingStatus
							status={sendingStatus.email}
							text={intl.formatMessage({
								defaultMessage: 'Sähköpostit',
								description:
									'Label for the email status in the confirm sending dialog in the message service summary step.',
							})}
						/>
					)}
					{hasSMSs && (
						<SendingStatus
							status={sendingStatus.sms}
							text={intl.formatMessage({
								defaultMessage: 'Tekstiviestit',
								description:
									'Label for the sms status in the confirm sending dialog in the message service summary step.',
							})}
						/>
					)}
					{hasLetters && (
						<SendingStatus
							status={sendingStatus.letter}
							text={intl.formatMessage({
								defaultMessage: 'Kirjeet',
								description:
									'Label for the letter status in the confirm sending dialog in the message service summary step.',
							})}
						/>
					)}
					{hasBulletins && (
						<SendingStatus
							status={sendingStatus.bulletins}
							text={intl.formatMessage({
								defaultMessage: 'Viilu-palvelun ilmoitustaulu',
								description:
									'Label for the bulletins status in the message service confirm sending dialog summary step.',
							})}
						/>
					)}
					{hasInfoscreen && (
						<SendingStatus
							status={sendingStatus.infoscreen}
							text={intl.formatMessage({
								defaultMessage: 'Viilu-porrasnäyttö',
								description:
									'Label for the infoscreen status in the message service confirm sending dialog summary step.',
							})}
						/>
					)}
					{hasIntegrations && (
						<SendingStatus
							status={sendingStatus.integrations}
							text={intl.formatMessage({
								defaultMessage: 'Integraatiot',
								description:
									'Label for the integrations status in the message service confirm sending dialog summary step.',
							})}
						/>
					)}
				</>
			) : (
				<CircularProgress id="message-service-send-dialog-sync-progress" />
			)}
		</ViiluDialog>
	)
}

const mapState = ({ firestoreReducer: { ordered } }) => ({
	syncedMessageServiceProcess: ordered['synced_message_service_process']?.[0],
})

const mapDispatchToProps = (dispatch) => ({
	_sendAll: bindActionCreators(messageServiceActions._sendAll, dispatch),
	_showInfoNotification: bindActionCreators(notifyActions.info, dispatch),
})

export default compose(
	injectIntl,
	firestoreConnect(({ messageServiceProcess }) => [
		{
			collection: 'message-service-process',
			doc: messageServiceProcess.uuid,
			storeAs: 'synced_message_service_process',
		},
	]),
	connect(mapState, mapDispatchToProps),
)(SendDialog)
