import React from 'react'
import styled from 'styled-components'

const GradientBottom = styled.div`
	pointer-events: none;
	position: absolute;
	bottom: 0;
	height: 32px;
	width: 100%;
	background: -moz-linear-gradient(
		top,
		rgba(255, 255, 255, 0) 0%,
		rgba(255, 255, 255, 1) 100%
	); /* FF3.6-15 */
	background: -webkit-linear-gradient(
		top,
		rgba(255, 255, 255, 0) 0%,
		rgba(255, 255, 255, 1) 100%
	); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(
		to bottom,
		rgba(255, 255, 255, 0) 0%,
		rgba(255, 255, 255, 1) 100%
	); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
`

const GradientTop = styled.div`
	pointer-events: none;
	position: absolute;
	top: 0;
	height: 32px;
	width: 100%;
	background: -moz-linear-gradient(
		bottom,
		rgba(255, 255, 255, 0) 0%,
		rgba(255, 255, 255, 1) 100%
	); /* FF3.6-15 */
	background: -webkit-linear-gradient(
		bottom,
		rgba(255, 255, 255, 0) 0%,
		rgba(255, 255, 255, 1) 100%
	); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(
		to top,
		rgba(255, 255, 255, 0) 0%,
		rgba(255, 255, 255, 1) 100%
	); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
`

const GradientLeft = styled.div`
	pointer-events: none;
	position: absolute;
	left: 0;
	height: 100%;
	width: 32px;
	background: -moz-linear-gradient(
		right,
		rgba(255, 255, 255, 0) 0%,
		rgba(255, 255, 255, 1) 100%
	); /* FF3.6-15 */
	background: -webkit-linear-gradient(
		right,
		rgba(255, 255, 255, 0) 0%,
		rgba(255, 255, 255, 1) 100%
	); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(
		to left,
		rgba(255, 255, 255, 0) 0%,
		rgba(255, 255, 255, 1) 100%
	); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
`

const GradientRight = styled.div`
	pointer-events: none;
	position: absolute;
	right: 0;
	height: 100%;
	width: 32px;
	background: -moz-linear-gradient(
		left,
		rgba(255, 255, 255, 0) 0%,
		rgba(255, 255, 255, 1) 100%
	); /* FF3.6-15 */
	background: -webkit-linear-gradient(
		left,
		rgba(255, 255, 255, 0) 0%,
		rgba(255, 255, 255, 1) 100%
	); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(
		to right,
		rgba(255, 255, 255, 0) 0%,
		rgba(255, 255, 255, 1) 100%
	); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
`
const GradientBelow = styled.div`
	pointer-events: none;
	bottom: 0;
	height: 32px;
	width: 100%;
	background: -moz-linear-gradient(
		top,
		rgba(255, 255, 255, 0) 0%,
		rgba(255, 255, 255, 1) 100%
	); /* FF3.6-15 */
	background: -webkit-linear-gradient(
		top,
		rgba(255, 255, 255, 0) 0%,
		rgba(255, 255, 255, 1) 100%
	); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(
		to bottom,
		rgba(255, 255, 255, 0) 0%,
		rgba(255, 255, 255, 1) 100%
	); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
`
const OverflowGradient = (props) => {
	const { top, left, right, below } = props
	if (top) {
		return <GradientTop {...props}></GradientTop>
	} else if (left) {
		return <GradientLeft {...props}></GradientLeft>
	} else if (right) {
		return <GradientRight {...props}></GradientRight>
	} else if (below) {
		return <GradientBelow {...props}></GradientBelow>
	}
	return <GradientBottom {...props}></GradientBottom>
}

export default OverflowGradient
