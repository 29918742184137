import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import WithQueryParams from './WithQueryParams'

const RouteHeader = ({
	header,
	subheader,
	style,
	textStyle,
	isDesktop,
	queryParams,
	drawerVisible,
	selectField,
}) => {
	const { experience, e } = queryParams

	if (window.isNativeApp) {
		return (
			<div
				style={{
					position: 'relative',
					height: 1,
					width: '100%',
					marginBottom: 80,
				}}
			>
				<div
					className="flex-column"
					style={{
						position: 'fixed',
						zIndex: 3,
						marginTop: 48,
						marginLeft: 24,
						marginRight: 96,
						...style,
					}}
				>
					{header ? (
						<h1
							style={{
								fontSize: 24,
								marginTop: 0,
								marginBottom: 0,
								color: 'rgba(0,0,0,0.87)',
								...textStyle,
							}}
						>
							{header}
						</h1>
					) : null}
				</div>
			</div>
		)
	} else if (isDesktop) {
		const defaultMargin = 16
		const desktopMarginLeft =
			experience === 'lean' || e === 'l'
				? 0
				: drawerVisible
				? defaultMargin
				: 56
		return (
			<div
				className="flex-column"
				style={{
					margin: defaultMargin,
					marginTop: 12,
					marginLeft: desktopMarginLeft,
					marginBottom: 48,
					...style,
				}}
			>
				<div
					className={selectField ? 'flex-row' : 'flex-column'}
					style={{
						position: 'fixed',
						zIndex: 3,
						marginLeft: 16,
						alignItems: selectField ? 'center' : '',
						...style,
					}}
				>
					{header ? (
						<h1
							style={{
								fontSize: 28,
								marginTop: 0,
								marginBottom: 0,
								color: 'rgba(0,0,0,0.87)',
								...textStyle,
							}}
						>
							{header}
						</h1>
					) : null}

					{selectField ? (
						<div
							className="flex-column"
							style={{
								marginLeft: 16,
								...style,
							}}
						>
							{selectField}
						</div>
					) : null}
				</div>
				{subheader ? (
					<h3
						style={{ fontSize: 20, opacity: 0.7, marginTop: 0, ...textStyle }}
					>
						{subheader}
					</h3>
				) : null}
			</div>
		)
	} else if (experience === 'lean' || e === 'l') {
		return (
			<div
				style={{
					position: 'relative',
					height: 1,
					width: '100%',
					marginBottom: 64,
				}}
			>
				<div
					className="flex-column"
					style={{
						position: 'fixed',
						zIndex: 3,
						marginTop: 22,
						marginLeft: 16,
						marginRight: 96,
						...style,
					}}
				>
					{header ? (
						<h1
							style={{
								fontSize: 16,
								marginTop: 0,
								marginBottom: 0,
								color: 'rgba(0,0,0,0.87)',
								...textStyle,
							}}
						>
							{header}
						</h1>
					) : null}
				</div>
			</div>
		)
	}
	return (
		<div
			style={{
				position: 'relative',
				height: 1,
				width: '100%',
				marginBottom: 64,
			}}
		>
			<div
				className="flex-column"
				style={{
					position: 'fixed',
					zIndex: 3,
					marginTop: 22,
					marginLeft: 56,
					marginRight: 96,
					...style,
				}}
			>
				{header ? (
					<h1
						style={{
							fontSize: 16,
							marginTop: 0,
							marginBottom: 0,
							color: 'rgba(0,0,0,0.87)',
							...textStyle,
						}}
					>
						{header}
					</h1>
				) : null}
			</div>
		</div>
	)
}

RouteHeader.propTypes = {
	header: PropTypes.string,
	subheader: PropTypes.string,
	selectField: PropTypes.element,
}

const mapState = ({
	ui: {
		app: { drawerVisible },
	},
	dimensions: { isDesktop },
}) => ({
	drawerVisible,
	isDesktop,
})

const mapDispatchToProps = (dispatch) => ({})

export default compose(
	WithQueryParams,
	connect(mapState, mapDispatchToProps),
)(RouteHeader)
