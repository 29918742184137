export const PostalBatchStatus = {
	UNKNOWN: 'unknown',
	CREATED: 'created',
	UPLOAD_STARTED: 'upload_started',
	UPLOAD_SUCCESS: 'upload_success',
	UPLOAD_FAILURE: 'upload_failure',
	WATCHDOG_FAILED: 'watchdog_failed',
	WATCHDOG_WAITING: 'watchdog_waiting',
	REGISTERED_SUCCESS: 'registered_success',
	REGISTERED_FAILURE: 'registered_failure',
	PROCESSING_SUCCESS: 'processing_success',
	PROCESSING_FAILURE: 'processing_failure',
	DELIVERED_SUCCESS: 'delivered_success',
	DELIVERED_FAILURE: 'delivered_failure',
	ABORTED: 'aborted',
}
