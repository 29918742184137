import styled from 'styled-components'
import { StepContainer } from 'ui/MessageService/MessageServiceProcess/steps/styled'
import { getLetters } from 'util/recipientsToLetters'
import BulletinsSummary from './BulletinsSummary'
import EmailSummary from './EmailSummary'
import InfoscreenSummary from './InfoscreenSummary'
import PostalSummary from './PostalSummary'
import SMSSummary from './SMSSummary'
import IntegrationsSummary from 'ui/MessageService/MessageServiceProcess/steps/Sending/steps/Summary/IntegrationsSummary'

const Title = styled.h2`
	margin: 0;
`

const Summaries = styled.div`
	h2 {
		margin-top: 32px;
		font-size: 1.5rem;
	}
`

const parseRecipients = (deliveryMethods = [], recipients = []) => {
	const email = []
	const letter = []
	const sms = []

	const emailDelivery = deliveryMethods.includes('email')
	const letterDelivery = deliveryMethods.includes('letter')
	const smsDelivery = deliveryMethods.includes('sms')

	recipients.forEach((r) => {
		if (emailDelivery && r.checked?.['email']) {
			email.push(r)
		}
		if (letterDelivery && r.checked?.['letter']) {
			letter.push(r)
		}
		if (smsDelivery && r.checked?.['sms']) {
			sms.push(r)
		}
	})

	return [email, letter, sms]
}

function Summary({
	messageServiceProcess: {
		deliveryMethods,
		recipients,
		postalSettings,
		bulletinsSettings,
		deliveryMethodsIntegrations,
		integrationsSettings,
		sentTs,
	},
}) {
	const [emailRecipients, letterRecipients, smsRecipients] = parseRecipients(
		deliveryMethods,
		recipients,
	)
	const hasEmails = emailRecipients.length > 0
	const hasLetters = letterRecipients.length > 0
	const hasSMSs = smsRecipients.length > 0
	const letterCount = hasLetters ? getLetters(letterRecipients)[0].length : 0
	const hasBulletins = deliveryMethods.includes('bulletins')
	const hasInfoscreen = deliveryMethods.includes('infoscreen')
	const hasIntegrations = deliveryMethodsIntegrations?.length > 0

	return (
		<StepContainer style={{ margin: '16px 0 0 40px' }}>
			<Title>Yhteenveto</Title>
			<Summaries>
				{hasLetters && (
					<PostalSummary
						letterCount={letterCount}
						postalSettings={postalSettings}
					/>
				)}
				{hasEmails && <EmailSummary emailCount={emailRecipients.length} />}
				{hasSMSs && <SMSSummary smsCount={smsRecipients.length} />}
				{hasBulletins && (
					<BulletinsSummary
						bulletinsSettings={bulletinsSettings}
						messageServiceProcessSentTs={sentTs}
					/>
				)}
				{hasInfoscreen && (
					<InfoscreenSummary
						bulletinsSettings={bulletinsSettings}
						messageServiceProcessSentTs={sentTs}
					/>
				)}
				{hasIntegrations && (
					<IntegrationsSummary
						deliveryMethodsIntegrations={deliveryMethodsIntegrations}
						integrationsSettings={integrationsSettings}
					/>
				)}
			</Summaries>
		</StepContainer>
	)
}

export default Summary
