import AwesomeDebouncePromise from 'awesome-debounce-promise'
import { getAuth } from 'firebase/auth'
import { backendFunction } from './actions-util'
import { firebaseApp } from './store'

const screenTimeCache = {}

export const _updateEmailNotificationSettings =
	(emailNotificationSettings) => async () =>
		backendFunction('userUpdateEmailNotificationSettings', {
			emailNotificationSettings,
		})

export const _updateProfile =
	(
		displayName,
		email,
		phoneNumber,
		digitalDeliveryConsent,
		address,
		zip,
		area,
		country,
	) =>
	async (dispatch, getState) =>
		backendFunction('userUpdateProfile', {
			displayName,
			email,
			phoneNumber,
			digitalDeliveryConsent,
			address,
			zip,
			area,
			country,
			selectedLocale: getState().app.selectedLocale,
		})

export const _incrementScreentime = (incrementSeconds, scope) => async () => {
	if (!screenTimeCache[scope]) screenTimeCache[scope] = 0
	screenTimeCache[scope] += incrementSeconds
	if (screenTimeCache[scope] < 60) {
		return true // only call backend if we have a minute's worth of screentime
	}
	incrementSeconds = screenTimeCache[scope]
	screenTimeCache[scope] = 0
	const userAgent = window.navigator ? window.navigator.userAgent : 'missing'

	return backendFunction('userIncrementScreentime', {
		incrementSeconds,
		scope,
		userAgent,
	})
}

export const _recordMessagingToken = () => async () => {
	// TODO: implement
	return { success: false }
}

export const _sendVerificationEmail =
	(redirectURL) => async (dispatch, getState) =>
		backendFunction('userSendVerificationEmail', {
			redirectURL,
			selectedLocale: getState().app.selectedLocale,
		})

export const _sendPasswordResetEmail = (userUUID) => async () =>
	backendFunction('userSendPasswordResetEmail', {
		userUUID,
	})

export const _sendPasswordResetEmailUnauthenticated = (email) => async () =>
	backendFunction('userSendPasswordResetEmailUnauthenticated', {
		email,
	})

export const _verifyEmail = (uuid) => async () =>
	backendFunction('userVerifyEmail', {
		userUUID: uuid,
	})

export const _checkAdmin = (userUUID, companyUUID) => async () =>
	backendFunction('userCheckAdmin', {
		userUUID,
		companyUUID,
	})

export const _delete = (userUUID) => async () =>
	backendFunction('userDelete', {
		userUUID,
	})

export const _disable = (userUUID) => async () =>
	backendFunction('userDisable', {
		userUUID,
	})

export const _setEmail = (userUUID, email) => async () =>
	backendFunction('userSetEmail', {
		userUUID,
		email,
	})

export const _setPhoneNumber = (userUUID, phoneNumber) => async () =>
	backendFunction('userSetPhoneNumber', {
		userUUID,
		phoneNumber,
	})

export const _testCompanyAccess = (userUUID, companyUUID) => async () =>
	backendFunction('userTestCompanyAccess', {
		userUUID,
		companyUUID,
	})

export const _updateClaims = (companyUUID, partnerUUID) => async () =>
	backendFunction('userUpdateClaims', {
		companyUUID,
		partnerUUID,
	})

export const _resetClaims = () => async () =>
	backendFunction('userResetClaims', {})

const _updateUserSelectedLocale = async (locale) => {
	const authenticated = getAuth(firebaseApp).currentUser
	if (!authenticated) {
		return { success: false }
	}

	return backendFunction('userSetSelectedLocale', {
		locale,
	})
}

export const _updateUserSelectedLocaleDebounced = AwesomeDebouncePromise(
	_updateUserSelectedLocale,
	2000,
)

window._resetClaims = _resetClaims
