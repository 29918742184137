import { TextField, CircularProgress, TextFieldTypes, Chip } from 'react-md'
import { GENERIC_ERROR_NOTIFICATION } from 'ui/messages'
import ViiluDialog from 'ui/components/ViiluDialog'
import { useEffect, useState } from 'react'
import SelectField, { ListValue } from 'react-md/lib/SelectFields/SelectField'
import { createBondHeaders } from './constants'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import * as notifyActions from 'state/notifyActions'
import { _createBonds } from 'state/finance-party-actions'
import useDialogs from 'ui/components/dialogs/useDialogs'
import DatePickerISO from 'ui/components/DatePickerISO'
import type { CreateBondsWithPartyUUIDs, PartyName } from './types'
import type { ApartmentSelectOptions, SelectOptions } from '../types'

interface Props {
	visible: boolean
	onHide: () => void
	refreshBonds: () => void
	selectedFinanceCompanyUUID: string
	bondNameOptions: SelectOptions[]
	companyOptions: SelectOptions[]
	refreshParties: () => void
	apartmentOptions: ApartmentSelectOptions[]
	partyNames?: PartyName[]
	refreshPartyNames?: () => void
}

const AddBondDialog = ({
	visible,
	onHide,
	refreshBonds,
	selectedFinanceCompanyUUID,
	bondNameOptions,
	companyOptions,
	refreshParties,
	apartmentOptions,
	partyNames,
	refreshPartyNames,
}: Props) => {
	const intl = useIntl()
	const { alert } = useDialogs()
	const dispatch = useDispatch()

	const initialFormData = {
		name: 'tenant',
		companyUUID: selectedFinanceCompanyUUID,
		startDate: null,
		endDate: null,
		active: true,
		partyUUIDs: [],
	}
	const [formData, setFormData] =
		useState<CreateBondsWithPartyUUIDs>(initialFormData)

	useEffect(() => {
		setFormData(initialFormData)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [visible])

	const [processing, setProcessing] = useState(false)
	const resetFormData = () => {
		setFormData(initialFormData)
	}

	useEffect(() => {
		setFormData((prevData) => ({
			...prevData,
			partyUUIDs: [],
		}))
	}, [formData.name])

	useEffect(() => {
		setFormData((prev) => ({
			...prev,
			companyUUID: selectedFinanceCompanyUUID,
		}))
	}, [selectedFinanceCompanyUUID])

	const handleInputChange = (value: string | number, field: string) => {
		setFormData((prevData) => ({
			...prevData,
			[field]: value,
		}))
	}

	const handleSelectChange = (value: ListValue, field: string) => {
		if (field === 'partyUUIDs') {
			setFormData((prevData) => ({
				...prevData,
				partyUUIDs: [...(prevData.partyUUIDs || []), value as string],
			}))
		} else
			setFormData((prevData) => ({
				...prevData,
				[field]: value,
			}))
	}

	const handleChipDeleteClick = (value: string) => {
		setFormData((prevData) => ({
			...prevData,
			partyUUIDs: prevData?.partyUUIDs?.filter((uuid) => uuid !== value),
		}))
	}

	const validate = () => {
		let isValid = true
		createBondHeaders.forEach((header) => {
			if (header.show && header.required) {
				isValid = formData[header.key]
			}
		})
		if (!formData.companyUUID) {
			isValid = false
		}

		return isValid
	}

	const hide = () => {
		resetFormData()
		onHide()
	}

	const onProcess = async () => {
		const isValid = validate()
		if (!isValid) {
			const message = intl.formatMessage({
				defaultMessage: 'Täytä kaikki vaaditut tiedot.',
				description:
					'Error message for missing finance bond create input values.',
			})
			alert(message)
			return
		}

		setProcessing(true)
		const result: any = await _createBonds(formData)
		setProcessing(false)

		if (!result || result.ok === false) {
			dispatch(notifyActions.info('❌ ' + GENERIC_ERROR_NOTIFICATION(intl)))
			return false
		}

		dispatch(
			notifyActions.info(
				'✅ ' +
					intl.formatMessage({
						defaultMessage: 'Rooli on lisätty.',
						description: 'Notification text of Bond has been added',
					}),
			),
		)
		refreshBonds()
		refreshParties()
		if (refreshPartyNames) refreshPartyNames()
		onHide()
		return true
	}

	const renderFormFields = () => {
		return createBondHeaders.map((header) => {
			const { title, key, type, required, show } = header
			if (show) {
				if (type === 'date') {
					return (
						<DatePickerISO
							style={{ width: '100%' }}
							key={key}
							id={key}
							label={title(intl)}
							value={formData[key]}
							onChange={(value) => handleInputChange(value, key)}
						/>
					)
				} else {
					return (
						<TextField
							key={key}
							id={key}
							label={title(intl)}
							floating
							lineDirection="center"
							fullWidth
							value={formData[key]}
							onChange={(value) => handleInputChange(value, key)}
							required={required}
							type={(type as TextFieldTypes) || undefined}
						/>
					)
				}
			}

			return null
		})
	}
	let partyNameOptions: SelectOptions[] = []
	if (partyNames && partyNames.length > 0) {
		partyNameOptions = []
		partyNames.forEach((party) => {
			const apartmentLabel = apartmentOptions.find(
				(a) => a.value === party.apartmentUUID,
			)?.label
			const isInFormData = formData?.partyUUIDs?.includes(party.uuid)
			if (!isInFormData)
				if (party.bonds && party.bonds.length > 0) {
					const bondWithSameName = party.bonds.some(
						(bond) => bond.name === formData.name,
					)

					if (!bondWithSameName) {
						partyNameOptions.push({
							label: party.name + ' / ' + apartmentLabel,
							value: party.uuid,
						})
					}
				} else {
					partyNameOptions.push({
						label: party.name + ' / ' + apartmentLabel,
						value: party.uuid,
					})
				}
		})
	}

	const renderSelectedParties = () => (
		<div style={{ marginTop: '10px' }}>
			{formData.partyUUIDs &&
				formData.partyUUIDs.map((uuid) => {
					const label = partyNames
						? partyNames.find((p) => p.uuid === uuid)?.name
						: ''
					return (
						<Chip
							key={uuid}
							style={{ marginRight: '10px', marginBottom: '10px' }}
							label={label}
							removable
							onClick={() => handleChipDeleteClick(uuid)}
						/>
					)
				})}
		</div>
	)

	const renderChildren = () => {
		return (
			<div
				className="margin-left--lg margin-right--lg"
				style={{ paddingBottom: 64 }}
			>
				<p className="text-subtle">
					{intl.formatMessage({
						defaultMessage: 'Kohde',
						description: 'Title of company',
					})}
				</p>
				<SelectField
					id="company"
					placeholder={intl.formatMessage({
						defaultMessage: 'Kohde',
						description: 'Placeholder of company',
					})}
					value={formData.companyUUID}
					onChange={(value) => handleSelectChange(value, 'companyUUID')}
					menuItems={companyOptions}
					position={SelectField.Positions.BELOW}
					simplifiedMenu={false}
					listHeightRestricted
					disabled
					style={{
						width: '100%',
						background: '#fafafa',
						marginBottom: '10px',
					}}
				/>

				<p className="text-subtle">
					{intl.formatMessage({
						defaultMessage: 'Rooli',
						description: 'Title of bond',
					})}
				</p>
				<SelectField
					id="bond"
					placeholder={intl.formatMessage({
						defaultMessage: 'Rooli',
						description: 'Placeholder of bond',
					})}
					value={formData.name}
					onChange={(value) => handleSelectChange(value, 'name')}
					menuItems={bondNameOptions}
					position={SelectField.Positions.BELOW}
					simplifiedMenu={false}
					listHeightRestricted
					style={{
						width: '100%',
						background: '#fafafa',
						marginBottom: '10px',
					}}
				/>

				<p className="text-subtle" style={{ marginTop: '10px' }}>
					{intl.formatMessage({
						defaultMessage: 'Osapuolet',
						description: 'Title of parties',
					})}
				</p>
				<SelectField
					id="parties"
					placeholder={intl.formatMessage({
						defaultMessage: 'Osapuolet',
						description: 'Placeholder of parties',
					})}
					value={''}
					onChange={(value) => handleSelectChange(value, 'partyUUIDs')}
					menuItems={partyNameOptions}
					position={SelectField.Positions.BELOW}
					simplifiedMenu={false}
					disabled={!formData.name}
					listHeightRestricted
					style={{
						width: '100%',
						background: '#fafafa',
						marginBottom: '10px',
					}}
				/>
				{renderSelectedParties()}

				{renderFormFields()}
			</div>
		)
	}

	const actions = [
		{
			id: 'dialog-cancel',
			children: intl.formatMessage({
				defaultMessage: 'Peruuta',
				description: 'Cancel button',
			}),
			onClick: hide,
		},
		{
			id: 'dialog-ok',
			secondary: true,
			children: processing ? (
				<CircularProgress id="progress" style={{ marginTop: 0 }} />
			) : (
				intl.formatMessage({
					defaultMessage: 'Tallenna',
					description: 'Save button',
				})
			),
			onClick: onProcess,
		},
	]

	return (
		<ViiluDialog
			id="responsive-dialog"
			title={intl.formatMessage({
				defaultMessage: 'Lisää rooli osapuolille',
				description: 'Title of add bond to parties',
			})}
			visible={visible}
			actions={actions}
			onHide={onHide}
			focusOnMount={true}
			containFocus={true}
			dialogClassName="responsive-dialog"
			contentClassName="responsive-dialog-content"
			autosizeContent={false}
			modal
			portal
			paddedContent
		>
			{renderChildren()}
		</ViiluDialog>
	)
}

export default AddBondDialog
