import {
	TextField,
	CircularProgress,
	SelectField,
	TextFieldTypes,
} from 'react-md'
import { GENERIC_ERROR_NOTIFICATION } from 'ui/messages'
import ViiluDialog from 'ui/components/ViiluDialog'
import { useEffect, useState } from 'react'
import { ListValue } from 'react-md/lib/SelectFields/SelectField'
import { editBondHeaders } from './constants'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import * as notifyActions from 'state/notifyActions'
import useBond from './useBond'
import useDialogs from 'ui/components/dialogs/useDialogs'
import { _updateBonds } from 'state/finance-party-actions'
import LoadingState from 'ui/components/LoadingState'
import moment from 'moment'
import DatePickerISO from 'ui/components/DatePickerISO'
import type { Bond } from './types'
import type { SelectOptions } from '../types'
import { BondName } from '../enum'

interface Props {
	id: string
	visible: boolean
	onHide: () => void
	refreshBonds: () => void
	companyOptions: SelectOptions[]
	bondNameOptions: SelectOptions[]
}

const UpdateBondDialog = ({
	id,
	visible,
	onHide,
	refreshBonds,
	companyOptions,
	bondNameOptions,
}: Props) => {
	const intl = useIntl()
	const { alert, confirm } = useDialogs()
	const dispatch = useDispatch()
	const [processing, setProcessing] = useState(false)

	const { bond, loadingBond } = useBond(id)

	const initialFormData = {
		uuid: '',
		name: BondName.TENANT,
		companyUUID: '',
		startDate: null,
		endDate: null,
		active: true,
		partyUUID: '',
	}

	const [formData, setFormData] = useState<Bond>(initialFormData)

	useEffect(() => {
		if (bond) {
			setFormData({
				uuid: bond.uuid || '',
				name: bond.name || 'tenant',
				companyUUID: bond.companyUUID || '',
				startDate: bond.startDate || null,
				endDate: bond.endDate || null,
				active: bond.active || true,
				partyUUID: bond.partyUUID || '',
			})
		}
	}, [bond])

	const resetFormData = () => {
		setFormData(initialFormData)
	}

	const handleInputChange = (value: string | number | null, field: string) => {
		setFormData((prevData) => ({
			...prevData,
			[field]: value,
		}))
	}

	const handleSelectChange = async (value: ListValue, field: string) => {
		if (field === 'companyUUID') {
			const confirmMessage = intl.formatMessage({
				defaultMessage: 'Haluatko varmasti päivittää roolin kohteen?',
				description:
					'Confirm message shown to the user when updating company of a bond.',
			})
			if (await confirm(confirmMessage)) {
				setFormData((prevData) => ({
					...prevData,
					[field]: value as string,
				}))
			}
		} else {
			setFormData((prevData) => ({
				...prevData,
				[field]: value,
			}))
		}
	}

	const validate = () => {
		let isValid = true

		if (!formData.companyUUID) {
			isValid = false
		}

		return [isValid]
	}

	const hide = () => {
		resetFormData()
		onHide()
	}

	const onProcess = async () => {
		const [isValid] = validate()
		if (!isValid) {
			const message = intl.formatMessage({
				defaultMessage: 'Täytä kaikki vaaditut tiedot.',
				description:
					'Error message for missing finance bond create input values.',
			})
			alert(message)
			return
		}

		setProcessing(true)
		const result = await _updateBonds([formData])
		setProcessing(false)

		if (!result || result.ok === false) {
			dispatch(notifyActions.info('❌ ' + GENERIC_ERROR_NOTIFICATION(intl)))
			return false
		}

		dispatch(
			notifyActions.info(
				'✅ ' +
					intl.formatMessage({
						defaultMessage: 'Rooli on päivitetty.',
						description: 'Notification text of bond has been updated',
					}),
			),
		)
		refreshBonds()
		onHide()
		return true
	}

	const getRowValue = (value: string, type: string | undefined) => {
		if (type && type === 'date') {
			const parsedDate = moment(value)
			if (!parsedDate.isValid()) return ''
			return parsedDate.format('YYYY-MM-DD')
		}
		return value
	}

	const renderFormFields = () => {
		return editBondHeaders.map((header) => {
			const { show, key, title, type } = header
			if (show) {
				if (type === 'date') {
					return (
						<DatePickerISO
							style={{ width: '100%' }}
							key={key}
							id={key}
							label={title(intl)}
							value={getRowValue(formData[key], type)}
							onChange={(value) => handleInputChange(value || null, key)}
						/>
					)
				} else {
					return (
						<TextField
							key={key}
							id={key}
							label={title(intl)}
							lineDirection="center"
							fullWidth
							floating
							value={getRowValue(formData[key], type)}
							onChange={(value) => handleInputChange(value, key)}
							required={true}
							type={(type as TextFieldTypes) || undefined}
						/>
					)
				}
			}
			return null
		})
	}

	const renderChildren = () => {
		return (
			<>
				<div
					className="margin-left--lg margin-right--lg"
					style={{ paddingBottom: 64 }}
				>
					<p className="text-subtle">
						{intl.formatMessage({
							defaultMessage: 'Kohde',
							description: 'Title of company',
						})}
					</p>
					<SelectField
						id="company"
						placeholder={intl.formatMessage({
							defaultMessage: 'Kohde',
							description: 'Placeholder of company',
						})}
						value={formData.companyUUID || ''}
						onChange={(value) => handleSelectChange(value, 'companyUUID')}
						menuItems={companyOptions}
						position={SelectField.Positions.BELOW}
						simplifiedMenu={false}
						listHeightRestricted
						disabled
						style={{
							width: '100%',
							background: '#fafafa',
							marginBottom: '10px',
						}}
					/>

					<p className="text-subtle">
						{intl.formatMessage({
							defaultMessage: 'Rooli',
							description: 'Title of bond',
						})}
					</p>
					<SelectField
						id="bond"
						placeholder={intl.formatMessage({
							defaultMessage: 'Rooli',
							description: 'Placeholder of bond',
						})}
						value={formData.name}
						onChange={(value) => handleSelectChange(value, 'name')}
						menuItems={bondNameOptions}
						position={SelectField.Positions.BELOW}
						simplifiedMenu={false}
						listHeightRestricted
						disabled
						style={{
							width: '100%',
							background: '#fafafa',
							marginBottom: '10px',
						}}
					/>
					{renderFormFields()}
				</div>
			</>
		)
	}

	const actions = [
		{
			id: 'dialog-cancel',
			children: intl.formatMessage({
				defaultMessage: 'Peruuta',
				description: 'Cancel button',
			}),
			onClick: hide,
		},
		{
			id: 'dialog-ok',
			secondary: true,
			children: processing ? (
				<CircularProgress id="progress" style={{ marginTop: 0 }} />
			) : (
				intl.formatMessage({
					defaultMessage: 'Tallenna',
					description: 'Save button',
				})
			),
			onClick: onProcess,
		},
	]

	if (loadingBond) {
		return <LoadingState />
	}

	return (
		<ViiluDialog
			id="responsive-dialog"
			title={intl.formatMessage(
				{
					defaultMessage: 'Päivitä osapuolen {partyName} rooli',
					description: 'Title of update party bond',
				},
				{ partyName: bond?.partyName },
			)}
			visible={visible}
			actions={actions}
			onHide={hide}
			focusOnMount={true}
			containFocus={true}
			dialogClassName="responsive-dialog"
			contentClassName="responsive-dialog-content"
			autosizeContent={false}
			modal
			portal
			paddedContent
		>
			{renderChildren()}
		</ViiluDialog>
	)
}

export default UpdateBondDialog
