import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import WithSelectedCompanyRole from './WithSelectedCompanyRole'

export default (roles) => (ComponentToWrap) => {
	class WithoutSelectedCompanyRoleRenderNull extends Component {
		render() {
			const { selectedCompanyRole } = this.props

			if (!roles.includes(selectedCompanyRole)) {
				return null
			}
			return <ComponentToWrap {...this.props} />
		}
	}

	const mapState = () => ({})

	const mapDispatchToProps = (dispatch) => ({})

	return compose(
		WithSelectedCompanyRole,
		connect(mapState, mapDispatchToProps),
	)(WithoutSelectedCompanyRoleRenderNull)
}
