import { lazy, useEffect, useRef } from 'react'
import { Route, Switch } from 'react-router'
import { I18nProvider, LOCALES } from '../i18n'
import styled from 'styled-components'
import Navbar from './Navbar'
import { UIContextProvider, useUIContext } from './context/UIContext'
import { history } from 'state/store'
import NotFound from './NotFound'
import { registerRunAfterCookieConsentAcceptCallback } from 'util/cookieConsent'

const Landing = lazy(() => import('./Landing'))
const Infoscreens = lazy(() => import('./Infoscreens'))
const Managers = lazy(() => import('./Managers'))
const MeetingTools = lazy(() => import('./MeetingTools'))
const Suite = lazy(() => import('./Suite'))
const Sign = lazy(() => import('./Sign'))
const Messages = lazy(() => import('./Messages'))
const Payments = lazy(() => import('./Payments'))
const Pricing = lazy(() => import('./Pricing'))
const News = lazy(() => import('./News'))
const CustomerService = lazy(() => import('./CustomerService'))
const DownloadAppPage = lazy(() => import('./OtherPages/DownloadAppPage'))
const Security = lazy(() => import('./OtherPages/securityAndPrivacy/Security'))
const Privacy = lazy(() => import('./OtherPages/securityAndPrivacy/Privacy'))
const AccountDeletion = lazy(() =>
	import('./OtherPages/securityAndPrivacy/AccountDeletion'),
)
const BrandGuideline = lazy(() => import('./OtherPages/BrandGuideline'))
const JoinMeeting = lazy(() => import('./OtherPages/JoinMeeting'))
const TermsOfService = lazy(() => import('./OtherPages/TermsOfService'))
const PostalTerms = lazy(() => import('./OtherPages/PostalTerms'))
const SignTerms = lazy(() => import('./OtherPages/SignTerms'))
const CodeOfConduct = lazy(() => import('./OtherPages/CodeOfConduct'))
const Demo = lazy(() => import('./OtherPages/Demo'))

const Container = styled.div`
	overflow-y: auto;
	overflow-x: hidden;
	height: 100%;
`

function Site() {
	const containerRef = useRef()
	const { navbarVisible, hasScrolledDown, setHasScrolledDown } = useUIContext()

	// Scroll to top on route change
	useEffect(() => {
		const unlisten = history.listen(() => {
			containerRef.current.scrollTo(0, 0)
		})
		return () => {
			unlisten()
		}
	}, [])

	// Load Hubspot script
	useEffect(() => {
		const unregister = registerRunAfterCookieConsentAcceptCallback(() => {
			const script = document.createElement('script')
			script.id = 'hs-script-loader'
			script.async = true
			script.defer = true
			script.src = '//js.hs-scripts.com/6014604.js'
			document.body.appendChild(script)
		})
		return unregister
	}, [])

	const handleContainerScroll = (e) => {
		if (e.target.scrollTop === 0) {
			setHasScrolledDown(false)
		} else if (e.target.scrollTop > 0 && !hasScrolledDown) {
			setHasScrolledDown(true)
		}
	}

	return (
		<Container ref={containerRef} onScroll={handleContainerScroll}>
			{navbarVisible && <Navbar />}
			<Switch>
				<Route path="/" exact component={Landing} />
				<Route path="/infoscreens" exact component={Infoscreens} />
				<Route
					path="/digitaalinen-yhtiokokous"
					exact
					component={MeetingTools}
				/>
				<Route path="/meetings" exact component={MeetingTools} />
				<Route path="/suite" exact component={Suite} />
				<Route path="/sign" exact component={Sign} />
				<Route path="/messages" exact component={Messages} />
				<Route path="/payments" exact component={Payments} />
				<Route path="/pricing" exact component={Pricing} />
				<Route path="/support" exact component={CustomerService} />
				<Route path="/news" component={News} />
				<Route path="/download" exact component={DownloadAppPage} />
				<Route path="/security" exact component={Security} />
				<Route path="/privacy" exact component={Privacy} />
				<Route path="/deletion-request" exact component={AccountDeletion} />
				<Route path="/brand-guideline" exact component={BrandGuideline} />
				<Route path="/trial" exact component={Pricing} />
				<Route path="/kokous" exact component={JoinMeeting} />
				<Route path="/tos" exact component={TermsOfService} />
				<Route path="/postitusehdot" exact component={PostalTerms} />
				<Route path="/allekirjoitusehdot" exact component={SignTerms} />
				<Route path="/eettisetohjeet" exact component={CodeOfConduct} />
				<Route path="/demo" exact component={Demo} />
				<Route path="/managers" exact component={Managers} />

				{/* 404 */}
				<Route component={NotFound} />
			</Switch>
		</Container>
	)
}

function SiteWithContext() {
	return (
		<UIContextProvider>
			<I18nProvider locale={LOCALES.FINNISH}>
				<Site />
			</I18nProvider>
		</UIContextProvider>
	)
}

export default SiteWithContext
