import { injectIntl } from 'react-intl'
import { FontIcon } from 'react-md'
import { compose } from 'redux'
import { SummaryList, SummaryListItem } from './styles'

function SMSSummary({ smsCount, intl }) {
	return (
		<>
			<h2>
				{intl.formatMessage({
					defaultMessage: 'Tekstiviestit (maksullinen)',
					description:
						'Heading for the sms section in the message service summary step.',
				})}
			</h2>
			{smsCount > 0 && (
				<SummaryList>
					<SummaryListItem>
						<FontIcon>sms</FontIcon>
						<span>
							{smsCount === 1
								? intl.formatMessage(
										{
											defaultMessage: '{recipientsCount} tekstiviesti',
											description:
												'Singular version of the sms recipients count in the message service summary step.',
										},
										{
											recipientsCount: smsCount,
										},
								  )
								: intl.formatMessage(
										{
											defaultMessage: '{recipientsCount} tekstiviestiä',
											description:
												'Plural version of the sms recipients count in the message service summary step.',
										},
										{
											recipientsCount: smsCount,
										},
								  )}
						</span>
					</SummaryListItem>
				</SummaryList>
			)}
			<p>
				{intl.formatMessage({
					defaultMessage:
						'Tekstiviestit lähetetään heti vahvistettuasi lähetyksen.',
					description:
						'Informational text about the sms send process in the message service summary step.',
				})}
			</p>
		</>
	)
}

export default compose(injectIntl)(SMSSummary)
