import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Toolbar, Button, Grid, Cell, CircularProgress } from 'react-md'
import { ReactMultiEmail, isEmail } from 'react-multi-email'
import 'react-multi-email/style.css'
import { bindActionCreators, compose } from 'redux'

import * as companyActions from 'state/company-actions'
import * as notifyActions from 'state/notifyActions'
import { generateRecipientUUID } from './utils'
import { injectIntl } from 'react-intl'
import {
	FETCHING_ELECTRONIC_CONSENT,
	FETCHING_ELECTRONIC_CONSENT_ERROR,
} from './translations'
import WithDialogs from 'ui/components/dialogs/WithDialogs'
import ViiluDialog from 'ui/components/ViiluDialog'

const getInitialState = () => ({
	inviteEmails: [],
})

class ImporterDialogEmails extends Component {
	state = getInitialState()

	getUsersEmail = async () => {
		const {
			company,
			onSuccess,
			_getUserDigitalDeliveryConsentByEmailBatch,
			_showInfoNotification,
			intl,
			alert,
		} = this.props
		const { inviteEmails } = this.state

		if (inviteEmails.length === 0) {
			await alert(
				intl.formatMessage({
					defaultMessage: 'Et ole syöttänyt yhtäkään sähköpostiosoitetta.',
					description:
						'Alert message shown if the user has not input any emails in the dialog for importing emails to the meeting invite table.',
				}),
			)
			return false
		}

		let users = inviteEmails.map((email) => ({
			displayName: null,
			email,
			apartments: [],
			uuid: generateRecipientUUID(),
			digitalDeliveryConsents: {},
		}))

		_showInfoNotification(FETCHING_ELECTRONIC_CONSENT(intl))
		this.setState({ processing: true })
		const result = await _getUserDigitalDeliveryConsentByEmailBatch(
			company.uuid,
			inviteEmails,
		)
		this.setState({ processing: false })

		if (result && result.data && Array.isArray(result.data)) {
			users = users.map((user, index) => {
				return {
					...user,
					...result.data[index],
				}
			})
		} else {
			_showInfoNotification(FETCHING_ELECTRONIC_CONSENT_ERROR(intl))
		}

		onSuccess(users)
		this.onHide()
		return true
	}

	onHide = () => {
		this.setState(getInitialState())
		this.props.onHide()
	}

	renderToolbar = (dialogTitle) => {
		return (
			<Toolbar
				colored
				title={dialogTitle}
				style={{
					background: 'var(--color-secondary-dark)',
					position: 'fixed',
					width: '100%',
					zIndex: 2,
				}}
				nav={
					<Button icon onClick={this.onHide}>
						close
					</Button>
				}
			/>
		)
	}

	render() {
		const { intl } = this.props
		const { inviteEmails, processing } = this.state

		const dialogTitle = intl.formatMessage({
			defaultMessage: 'Tuo sähköpostiosoitteita',
			description:
				'Title of the dialog for importing emails to the meeting invite table.',
		})

		return (
			<ViiluDialog
				id="responsive-dialog"
				aria-label={dialogTitle}
				visible={true}
				onHide={this.onHide}
				focusOnMount={true}
				containFocus={true}
				dialogClassName="responsive-dialog"
				contentClassName="responsive-dialog-content"
				autosizeContent={false}
				disableScrollLocking
				modal
				paddedContent
				onClick={(e) => {
					e.preventDefault()
					e.stopPropagation()
				}}
				style={{ zIndex: 5000 }}
			>
				{this.renderToolbar(dialogTitle)}
				<section className="md-toolbar-relative" style={{ padding: 24 }}>
					<div className="flex-column">
						<Grid style={{ padding: 0 }}>
							<Cell size={12}>
								<p>
									{intl.formatMessage({
										defaultMessage:
											'Kirjoita tai liitä sähköpostilista. Voit erottaa sähköpostit pilkulla tai välilyönnillä tai kopioida ne suoraan Excelistä.',
										description:
											'Instructions in the dialog for importing emails to the meeting invite table.',
									})}
								</p>
							</Cell>
							<Cell
								size={12}
								style={{ display: 'flex', alignItems: 'flex-end', margin: 0 }}
							>
								<ReactMultiEmail
									placeholder={intl.formatMessage({
										defaultMessage: 'Sähköpostiosoitteet',
										description:
											'Placeholder for the emails input in the dialog for importing emails to the meeting invite table.',
									})}
									emails={inviteEmails}
									onChange={(inviteEmails) => this.setState({ inviteEmails })}
									validateEmail={isEmail}
									className="full-width"
									getLabel={(email, index, removeEmail) => (
										<div data-tag key={index}>
											{email}
											<span data-tag-handle onClick={() => removeEmail(index)}>
												×
											</span>
										</div>
									)}
								/>
							</Cell>
						</Grid>

						<div
							className="flex-row margin-top"
							style={{ justifyContent: 'flex-end' }}
						>
							{processing ? (
								<Button raised secondary disabled>
									<CircularProgress id="progress" style={{ marginTop: -4 }} />
								</Button>
							) : (
								<Button raised secondary onClick={this.getUsersEmail}>
									{intl.formatMessage({
										defaultMessage: 'Tuo sähköpostilista',
										description:
											'Label for the button that imports the email list in the dialog for importing emails to the meeting invite table.',
									})}
								</Button>
							)}
						</div>
					</div>
				</section>
			</ViiluDialog>
		)
	}
}

ImporterDialogEmails.propTypes = {
	onHide: PropTypes.func.isRequired,
	onSuccess: PropTypes.func.isRequired,
	company: PropTypes.object.isRequired,
}

const mapState = () => ({})

const mapDispatchToProps = (dispatch) => ({
	_showInfoNotification: bindActionCreators(notifyActions.info, dispatch),
	_getUserDigitalDeliveryConsentByEmailBatch: bindActionCreators(
		companyActions._getUserDigitalDeliveryConsentByEmailBatch,
		dispatch,
	),
})

export default compose(
	injectIntl,
	WithDialogs,
	connect(mapState, mapDispatchToProps),
)(ImporterDialogEmails)
