import React, { useEffect, useState } from 'react'
import { collection, query, where, getDocs } from 'firebase/firestore'
import { useSelector } from 'react-redux'

import { firestore } from 'state/store'
import LoadingState from './LoadingState'
import naturalSort from 'javascript-natural-sort'
import WithSelectedManager from 'ui/components/WithSelectedManager'

const HOC =
	({ hideLoading = false }) =>
	(WrappedComponent) => {
		const WithSelectedManagerCompanies = (props) => {
			const [companies, setCompanies] = useState(null)
			const uid = useSelector((state) => state.firebaseReducer.auth?.uid)
			const managerUUID = props.selectedManager
				? props.selectedManager.uuid
				: 'empty-value'
			useEffect(() => {
				const doQuery = async (q) => {
					const querySnapshot = await getDocs(q)
					const companies = []
					querySnapshot.forEach((c) => companies.push(c.data()))
					setCompanies(companies)
				}
				if (uid) {
					doQuery(
						query(
							collection(firestore, 'company'),
							where('managerUUID', '==', managerUUID),
						),
					)
				}
			}, [uid, managerUUID])

			if (!uid) {
				return <WrappedComponent {...props} selectedManagerCompanies={[]} />
			}

			if (!companies) {
				if (hideLoading) {
					return null
				}
				return <LoadingState small />
			}

			const sortedCompanies = [...companies].sort(byName)

			return (
				<WrappedComponent
					{...props}
					selectedManagerCompanies={sortedCompanies}
				/>
			)
		}

		return WithSelectedManager({ hideLoading: true })(
			WithSelectedManagerCompanies,
		)
	}

const byName = (a, b) => {
	const aName = a.name || ''
	const bName = b.name || ''
	return naturalSort(aName, bName)
}

export default HOC
