import { Button } from 'react-md'
import { connect } from 'react-redux'
import { compose } from 'redux'
import styled from 'styled-components'

const Container = styled.div`
	display: flex;
	padding: 16px;
	box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.33);
`

function Actions({
	onPreviousClick,
	onDraftClick,
	onNextClick,
	nextLabel,
	isDesktop,
}) {
	return (
		<Container>
			<Button
				flat
				onClick={() => onPreviousClick()}
				disabled={!onPreviousClick}
			>
				Edellinen
			</Button>
			<Button
				flat
				style={{ marginLeft: 'auto', marginRight: 8 }}
				onClick={() => onDraftClick()}
				disabled={!onDraftClick}
			>
				{isDesktop ? 'Tallenna luonnos' : 'Tallenna'}
			</Button>
			<Button
				secondary
				raised
				onClick={() => onNextClick()}
				disabled={!onNextClick}
			>
				{nextLabel || 'Seuraava'}
			</Button>
		</Container>
	)
}

const mapState = ({ dimensions: { isDesktop } }) => ({
	isDesktop,
})

export default compose(connect(mapState))(Actions)
