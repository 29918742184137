import { Button, FontIcon } from 'react-md'
import FloatingActionBtn from '.'
import { useState } from 'react'

type Buttons = {
	key: string
	secondary: boolean
	iconName: string
	label: string
	action: () => void
}
type Props = {
	buttons: Buttons[]
	text: string
	iconName: string
	menuOpenText?: string
}

const FloatingActionBtnMenu = ({
	buttons,
	text,
	iconName,
	menuOpenText = '',
}: Props) => {
	const [showMenu, setShowMenu] = useState(false)

	if (buttons.length === 1) {
		return (
			<FloatingActionBtn
				iconName={buttons[0].iconName}
				text={buttons[0].label}
				action={buttons[0].action}
			/>
		)
	}

	return (
		<div>
			{buttons.map(({ key, secondary, iconName, label, action }, index) => {
				const classIndex = index + 1
				const className = showMenu ? 'fab-sub' : 'fab-sub fab-sub-hidden'

				return (
					<Button
						key={key}
						secondary={secondary}
						floating
						fixed
						mini
						fixedPosition="br"
						className={className}
						style={{
							marginBottom: `calc(8px + (64px * ${classIndex}))`,
						}}
						onClick={action}
					>
						<div>
							<FontIcon style={{ color: 'rgba(255,255,255,1)' }}>
								{iconName}
							</FontIcon>
							<p
								style={{
									background: 'rgba(0,0,0,0.33)',
									borderRadius: 4,
									position: 'absolute',
									color: 'rgba(255,255,255,1)',
									top: '15%',
									right: 44,
									padding: 4,
									fontSize: 14,
									fontFamily: 'Lato',
									textTransform: 'uppercase',
								}}
							>
								{label}
							</p>
						</div>
					</Button>
				)
			})}

			<FloatingActionBtn
				iconName={showMenu ? 'close' : iconName}
				text={showMenu ? menuOpenText : text}
				action={() => setShowMenu(!showMenu)}
			/>
		</div>
	)
}

export default FloatingActionBtnMenu
