import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Button, CircularProgress } from 'react-md'

import illustrationDone from 'svg/illustrations/illustration_done.svg'
import WithSelectedCompanyUUID from 'ui/components/WithSelectedCompanyUUID'
import WithSelectedCompanyRole from 'ui/components/WithSelectedCompanyRole'
import { FormattedMessage, injectIntl } from 'react-intl'
import config from 'config'

export class UserOnboardingStepFinalize extends Component {
	done = async () => {
		const { onDone } = this.props
		onDone()
	}

	render = () => {
		const { processing, selectedCompanyRole, selectedCompanyUUID, intl } =
			this.props

		let title, subtitle
		if (window.location.pathname.includes('/manager/')) {
			title = intl.formatMessage({
				defaultMessage: 'Kiitos tietojen täydentämisestä.',
				description:
					'Informational paragraph thanking the user for filling in the details.',
			})
			subtitle = intl.formatMessage({
				defaultMessage:
					'Viimeistele prosessi painamalla vielä Tallenna-painiketta alta.',
				description:
					'Informational paragraph telling the user that they still need to press the save button.',
			})
		} else if (!selectedCompanyUUID) {
			// Most likely a new customer creating a new housing company
			title = intl.formatMessage({
				defaultMessage: 'Kiitos tietojen täydentämisestä.',
				description:
					'Informational paragraph thanking the user for filling in the details.',
			})
			subtitle = intl.formatMessage({
				defaultMessage:
					'Viimeistele prosessi painamalla vielä Tallenna-painiketta alta.',
				description:
					'Informational paragraph telling the user that they still need to press the save button.',
			})
		} else if (selectedCompanyRole === config.userRoles.none) {
			title = intl.formatMessage({
				defaultMessage:
					'Kiitos! Tunnuksesi on nyt luotu, mutta tarvitset vielä käyttöoikeudet.',
				description:
					'Informational paragraph telling the user that they still need permissions.',
			})
			subtitle = intl.formatMessage({
				defaultMessage:
					'Viimeistele prosessi painamalla vielä Tallenna-painiketta alta. Sinulla on nyt tunnus, mutta ei vielä käyttöoikeuksia taloyhtiön sivuille. Voit pyytää käyttöoikeuksia seuraavaksi talosivujen kautta.',
				description:
					'Informational paragraph telling the user that they still need permissions.',
			})
		} else {
			title = intl.formatMessage({
				defaultMessage:
					'Kiitos! Taloyhtiösi omat sivut ovat nyt käytettävissäsi.',
				description:
					'Informational paragraph telling the user that the housing company page is now available to them.',
			})
			subtitle = intl.formatMessage({
				defaultMessage:
					'Viimeistele prosessi painamalla vielä Tallenna-painiketta alta.',
				description:
					'Informational paragraph telling the user that the housing company page is available 24/7.',
			})
		}

		return (
			<div
				className="flex-column flex-center"
				style={{ height: 'calc(100% - 56px)', justifyContent: 'flex-start' }}
			>
				<img
					alt=""
					src={illustrationDone}
					style={{
						width: '30%',
						height: 'auto',
						maxWidth: 200,
						position: 'relative',
						margin: 16,
					}}
				/>
				<h4 className="text-center">{title}</h4>
				<p className="text-center">{subtitle}</p>
				{processing ? (
					<CircularProgress id="progress" />
				) : (
					<Button
						raised
						secondary
						onClick={this.done}
						style={{ position: 'absolute', right: 24, bottom: 24 }}
					>
						<FormattedMessage
							defaultMessage="Tallenna"
							description="Button that allows the user to close the user profile update dialog."
						/>
					</Button>
				)}
			</div>
		)
	}
}

UserOnboardingStepFinalize.propTypes = {
	onUpdate: PropTypes.func.isRequired,
	onDone: PropTypes.func.isRequired,
	processing: PropTypes.bool,
}

const mapState = ({ ui: { onboarding: onboardingReducer } }) => ({
	onboardingReducer,
})

const mapDispatchToProps = (dispatch) => ({})

export default compose(
	injectIntl,
	WithSelectedCompanyUUID,
	WithSelectedCompanyRole,
	connect(mapState, mapDispatchToProps),
)(UserOnboardingStepFinalize)
