import { IntlShape } from 'react-intl'
import {
	LoanEventType,
	LoanFinancingType,
	LoanInterestDaysMonth,
	LoanInterestDaysYear,
	LoanStatus,
} from './enum'

export const loanEventTypeMessage = (
	intl: IntlShape,
	loanEventType: LoanEventType,
) => {
	switch (loanEventType) {
		case LoanEventType.PAYMENT:
			return intl.formatMessage({
				defaultMessage: 'Suoritus',
				description: 'Label for loan event type: payment',
			})
		case LoanEventType.LOAN_PAYBACK:
			return intl.formatMessage({
				defaultMessage: 'Maksu lainanantajalle',
				description: 'Label for loan event type: loan payback',
			})
		default:
			return ''
	}
}

export const loanFinancingTypeMessage = (
	intl: IntlShape,
	loanFinancingType: LoanFinancingType,
) => {
	switch (loanFinancingType) {
		case LoanFinancingType.CAPITAL:
			return intl.formatMessage({
				defaultMessage: 'Pääomalaina',
				description: 'Label for loan financing type: capital loan',
			})
		case LoanFinancingType.MAINTENANCE:
			return intl.formatMessage({
				defaultMessage: 'Hoitolaina',
				description: 'Label for loan financing type: maintenance loan',
			})
		case LoanFinancingType.UNREALIZED_MAINTENANCE:
			return intl.formatMessage({
				defaultMessage: 'Pääomittamaton hoitolaina',
				description:
					'Label for loan financing type: unrealized maintenance loan',
			})
		default:
			return ''
	}
}

export const interestDaysMonthMessage = (
	intl: IntlShape,
	interestDaysMonth: LoanInterestDaysMonth,
) => {
	switch (interestDaysMonth) {
		case LoanInterestDaysMonth.THIRTY_DAYS:
			return intl.formatMessage({
				defaultMessage: '30 päivää',
				description: 'Label for interest days in month option 30 days.',
			})
		case LoanInterestDaysMonth.ACTUAL_OF_MONTH:
			return intl.formatMessage({
				defaultMessage: 'Kuukauden todelliset',
				description:
					'Label for interest days in month option actuals of the month.',
			})
		default:
			return ''
	}
}

export const interestDaysYearMessage = (
	intl: IntlShape,
	interestDaysYear: LoanInterestDaysYear,
) => {
	switch (interestDaysYear) {
		case LoanInterestDaysYear.THREE_SIXTY:
			return intl.formatMessage({
				defaultMessage: '360 päivää',
				description: 'Label for interest days in year option 360 days.',
			})
		case LoanInterestDaysYear.THREE_SIXTY_FIVE:
			return intl.formatMessage({
				defaultMessage: '365 päivää',
				description: 'Label for interest days in year option 365 days.',
			})
		case LoanInterestDaysYear.ACTUAL_OF_YEAR:
			return intl.formatMessage({
				defaultMessage: 'Vuoden todelliset',
				description:
					'Label for interest days in year option actuals of the year.',
			})
		default:
			return ''
	}
}

export const loanStatusMessage = (intl: IntlShape, status: LoanStatus) => {
	switch (status) {
		case LoanStatus.DRAFT:
			return intl.formatMessage({
				defaultMessage: 'Luonnos',
				description: 'Label for loan status: draft',
			})
		case LoanStatus.ACTIVE:
			return intl.formatMessage({
				defaultMessage: 'Aktiivinen',
				description: 'Label for loan status: active',
			})
		case LoanStatus.ENDED:
			return intl.formatMessage({
				defaultMessage: 'Loppunut',
				description: 'Label for loan status: ended',
			})
		default:
			return ''
	}
}
