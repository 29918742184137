import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
	Toolbar,
	Button,
	Grid,
	Cell,
	TextField,
	CircularProgress,
} from 'react-md'
import { compose, bindActionCreators } from 'redux'

import * as companyActions from 'state/company-actions'
import * as notifyActions from 'state/notifyActions'

import WithSelectedCompany from 'ui/components/WithSelectedCompany'
import { generateRecipientUUID } from './utils'
import { injectIntl } from 'react-intl'
import {
	FETCHING_ELECTRONIC_CONSENT,
	FETCHING_ELECTRONIC_CONSENT_ERROR,
} from './translations'
import { validateEmail } from 'util/validation'
import WithDialogs from 'ui/components/dialogs/WithDialogs'
import ViiluDialog from 'ui/components/ViiluDialog'

const getInitialState = () => ({
	displayName: undefined,
	identifiersText: undefined,
	email: undefined,
	phoneNumber: undefined,
	address: undefined,
	area: undefined,
	zip: undefined,
	country: 'FI',
	processing: false,
})

class ImporterDialogManual extends Component {
	state = getInitialState()

	getUsersManual = async () => {
		const {
			onSuccess,
			selectedCompany,
			_getUserDigitalDeliveryConsentByEmail,
			_showInfoNotification,
			intl,
			alert,
		} = this.props
		const {
			displayName,
			email,
			phoneNumber,
			address,
			area,
			zip,
			country,
			identifiersText,
		} = this.state

		const invitee = {
			displayName: displayName ? displayName.trim() : displayName,
			email: email ? email.trim() : email,
			address: address ? address.trim() : address,
			area: area ? area.trim() : area,
			zip: zip ? zip.trim() : zip,
			country: country ? country.trim() : country,
			phoneNumber: phoneNumber ? phoneNumber.trim() : phoneNumber,
			apartments: [],
			uuid: generateRecipientUUID(),
			digitalDeliveryConsents: {},
		}

		if (invitee.country && invitee.country.length !== 2) {
			_showInfoNotification(
				intl.formatMessage({
					defaultMessage:
						'Maakoodin tulee olla 2 merkkiä pitkä. Esimerkiksi FI.',
					description:
						'Notification message shown for invalid country code in the dialog for manually adding an invitee to the message service recipients table.',
				}),
			)
			return
		}

		if (invitee.email) {
			if (!validateEmail(invitee.email)) {
				const errorMessage = intl.formatMessage({
					defaultMessage: 'Tarkista sähköpostiosoite.',
					description: 'Error message shown for invalid email addresses.',
				})
				await alert(errorMessage)
				return
			}

			_showInfoNotification(FETCHING_ELECTRONIC_CONSENT(intl))
			this.setState({ processing: true })

			const result = await _getUserDigitalDeliveryConsentByEmail(
				selectedCompany.uuid,
				invitee.email,
			)

			this.setState({ processing: false })

			if (result && result.data) {
				invitee.digitalDeliveryConsents = result.data.digitalDeliveryConsents
			} else {
				_showInfoNotification(FETCHING_ELECTRONIC_CONSENT_ERROR(intl))
			}
		}

		if (invitee.phoneNumber && !invitee.phoneNumber.includes('+358')) {
			_showInfoNotification(
				intl.formatMessage({
					defaultMessage: 'Puhelinnumeron täytyy sisältää maakoodi +358',
					description:
						'Notification message shown for invalid country code in the dialog for manually adding an invitee to the message service recipients table.',
				}),
			)
			return
		}

		if (identifiersText && identifiersText.length > 0) {
			const identifiers = identifiersText
				.trim()
				.split(',')
				.map((id) => id.toUpperCase().replace(/ /g, ''))
			invitee.apartments = identifiers.map((identifier) => ({ identifier }))
		}

		onSuccess([invitee])
		this.onHide()
		return true
	}

	onHide = () => {
		this.setState(getInitialState())
		this.props.onHide()
	}

	renderToolbar = (dialogTitle) => {
		return (
			<Toolbar
				colored
				title={dialogTitle}
				style={{
					background: 'var(--color-secondary-dark)',
					position: 'fixed',
					width: '100%',
					zIndex: 2,
				}}
				nav={
					<Button icon onClick={this.onHide}>
						close
					</Button>
				}
			/>
		)
	}

	render() {
		const {
			displayName,
			email,
			phoneNumber,
			identifiersText,
			address,
			area,
			zip,
			country,
			processing,
		} = this.state
		const { intl, isDesktop, deliveryMethods = [] } = this.props

		const dialogTitle = intl.formatMessage({
			defaultMessage: 'Lisää yksittäinen',
			description:
				'Title of the dialog for manually adding an invitee to the message service recipients table.',
		})

		return (
			<ViiluDialog
				id="responsive-dialog"
				aria-label={dialogTitle}
				visible={true}
				onHide={this.onHide}
				focusOnMount={true}
				containFocus={true}
				dialogClassName="responsive-dialog"
				contentClassName="responsive-dialog-content"
				autosizeContent={false}
				disableScrollLocking
				modal
				paddedContent
				onClick={(e) => {
					e.preventDefault()
					e.stopPropagation()
				}}
				style={{ zIndex: 5000 }}
			>
				{this.renderToolbar(dialogTitle)}
				<section className="md-toolbar-relative" style={{ padding: 8 }}>
					<div className="flex-column">
						<Grid>
							<Cell size={12}>
								<p>
									{intl.formatMessage({
										defaultMessage:
											'Lisää yksittäinen vastaanottaja syöttämällä hänen tietonsa.',
										description:
											'Instructions in the dialog for manually adding a recipient to the message service recipients table.',
									})}
								</p>
							</Cell>
							<Cell
								size={12}
								style={{ display: 'flex', alignItems: 'flex-end' }}
							>
								<TextField
									id="floating-center-displayname"
									label={intl.formatMessage({
										defaultMessage: 'Nimi',
										description:
											'Label for the name input in the dialog for manually adding an invitee to the message service recipients table.',
									})}
									lineDirection="center"
									placeholder={intl.formatMessage({
										defaultMessage: 'Esim. Matti Meikäläinen',
										description:
											'Placeholder for the name input in the dialog for manually adding an invitee to the message service recipients table.',
									})}
									fullWidth
									value={displayName || ''}
									onChange={(displayName) => this.setState({ displayName })}
									autoComplete="off"
								/>
							</Cell>
							<Cell
								size={12}
								style={{ display: 'flex', alignItems: 'flex-end' }}
							>
								<TextField
									id="floating-center-identifiers"
									label={intl.formatMessage({
										defaultMessage: 'Huoneistot',
										description:
											'Label for the apartments input in the dialog for manually adding an invitee to the message service recipients table.',
									})}
									lineDirection="center"
									placeholder={intl.formatMessage({
										defaultMessage: 'Esim. A1, A2',
										description:
											'Placeholder for the apartment input in the dialog for manually adding an invitee to the message service recipients table.',
									})}
									fullWidth
									value={identifiersText || ''}
									onChange={(identifiersText) =>
										this.setState({ identifiersText })
									}
									autoComplete="off"
									helpText={intl.formatMessage({
										defaultMessage: 'Pilkulla erotetut huoneistojen tunnukset',
										description:
											'Help text for the apartment input in the dialog for manually adding an invitee to the message service recipients table.',
									})}
								/>
							</Cell>
							{deliveryMethods.includes('letter') && (
								<Cell
									size={12}
									style={{
										display: 'flex',
										alignItems: 'flex-start',
										flexDirection: 'column',
										background: 'rgba(0, 0, 255, 0.05)',
										padding: 16,
										borderRadius: 8,
										marginTop: 32,
									}}
								>
									<strong>
										{intl.formatMessage({
											defaultMessage: 'Kirjepostitus',
											description:
												'Title of the postal inputs in the dialog for manually adding an invitee to the message service recipients table.',
										})}
									</strong>
									<p>
										{intl.formatMessage({
											defaultMessage:
												'Täytä nämä tiedot mikäli haluat käyttää Viilun postituspalvelua.',
											description:
												'Instructional paragraph for the postal inputs in the dialog for manually adding an invitee to the message service recipients table.',
										})}
									</p>
									<TextField
										id="floating-center-address"
										label={intl.formatMessage({
											defaultMessage: 'Katuosoite',
											description:
												'Label for the postal address input in the dialog for manually adding an invitee to the message service recipients table.',
										})}
										lineDirection="center"
										placeholder={intl.formatMessage({
											defaultMessage: 'Esim. Kokoustie 3 A2',
											description:
												'Placeholder for the postal address input in the dialog for manually adding an invitee to the message service recipients table.',
										})}
										fullWidth
										value={address || ''}
										onChange={(address) => this.setState({ address })}
										autoComplete="off"
									/>
									<div
										className="flex-row full-width"
										style={{
											marginLeft: -8,
											marginRight: -8,
											flexWrap: isDesktop ? 'nowrap' : 'wrap',
										}}
									>
										<TextField
											id="floating-center-zip"
											label={intl.formatMessage({
												defaultMessage: 'Postinumero',
												description:
													'Label for the postal zip code input in the dialog for manually adding an invitee to the message service recipients table.',
											})}
											lineDirection="center"
											placeholder={intl.formatMessage({
												defaultMessage: 'Esim. 00100',
												description:
													'Placeholder for the postal zip code input in the dialog for manually adding an invitee to the message service recipients table.',
											})}
											fullWidth
											value={zip || ''}
											onChange={(zip) => this.setState({ zip })}
											autoComplete="off"
											style={{ marginRight: 8, marginLeft: 8 }}
										/>
										<TextField
											id="floating-center-area"
											label={intl.formatMessage({
												defaultMessage: 'Postitoimipaikka',
												description:
													'Label for the postal area input in the dialog for manually adding an invitee to the message service recipients table.',
											})}
											lineDirection="center"
											placeholder={intl.formatMessage({
												defaultMessage: 'Esim. HELSINKI',
												description:
													'Placeholder for the postal area input in the dialog for manually adding an invitee to the message service recipients table.',
											})}
											fullWidth
											value={area || ''}
											onChange={(val) =>
												this.setState({ area: val ? val.toUpperCase() : val })
											}
											autoComplete="off"
											style={{ marginRight: 8, marginLeft: 8 }}
										/>
										<TextField
											id="floating-center-country"
											label={intl.formatMessage({
												defaultMessage: 'Maakoodi',
												description:
													'Label for the country code input in the dialog for manually adding an invitee to the message service recipients table.',
											})}
											lineDirection="center"
											placeholder={intl.formatMessage({
												defaultMessage: 'Esim. FI',
												description:
													'Placeholder for the postal country code input in the dialog for manually adding an invitee to the message service recipients table.',
											})}
											fullWidth
											maxLength={2}
											value={country || ''}
											onChange={(val) =>
												this.setState({
													country: val ? val.toUpperCase() : val,
												})
											}
											autoComplete="off"
											style={{ marginRight: 8, marginLeft: 8 }}
										/>
									</div>
								</Cell>
							)}
							{deliveryMethods.includes('email') && (
								<Cell
									size={12}
									style={{
										display: 'flex',
										alignItems: 'flex-start',
										flexDirection: 'column',
										background: 'rgba(255, 0, 0, 0.05)',
										padding: 16,
										borderRadius: 8,
										marginTop: 32,
									}}
								>
									<strong>
										{intl.formatMessage({
											defaultMessage: 'Sähköpostitus',
											description:
												'Title of the email inputs in the dialog for manually adding an invitee to the message service recipients table.',
										})}
									</strong>
									<p>
										{intl.formatMessage({
											defaultMessage:
												'Täytä nämä tiedot mikäli haluat lähettää sähköpostia Viilun kautta.',
											description:
												'Instructional paragraph for the email inputs in the dialog for manually adding an invitee to the message service recipients table.',
										})}
									</p>
									<TextField
										id="floating-center-email"
										label={intl.formatMessage({
											defaultMessage: 'Sähköpostiosoite',
											description:
												'Label for the email input in the dialog for manually adding an invitee to the message service recipients table.',
										})}
										lineDirection="center"
										placeholder={intl.formatMessage({
											defaultMessage: 'Esim. matti@meikalainen.fi',
											description:
												'Placeholder for the email input in the dialog for manually adding an invitee to the message service recipients table.',
										})}
										fullWidth
										value={email || ''}
										onChange={(email) => this.setState({ email })}
										autoComplete="off"
									/>
								</Cell>
							)}
							{deliveryMethods.includes('sms') && (
								<Cell
									size={12}
									style={{
										display: 'flex',
										alignItems: 'flex-start',
										flexDirection: 'column',
										background: 'rgba(0, 255, 0, 0.05)',
										padding: 16,
										borderRadius: 8,
										marginTop: 32,
									}}
								>
									<strong>
										{intl.formatMessage({
											defaultMessage: 'Tekstiviestitys',
											description:
												'Title of the sms inputs in the dialog for manually adding an invitee to the message service recipients table.',
										})}
									</strong>
									<p>
										{intl.formatMessage({
											defaultMessage:
												'Täytä nämä tiedot mikäli haluat lähettää tekstiviestiä Viilun kautta.',
											description:
												'Instructional paragraph for the sms inputs in the dialog for manually adding an invitee to the message service recipients table.',
										})}
									</p>
									<TextField
										id="floating-center-sms"
										label={intl.formatMessage({
											defaultMessage: 'Puhelinnumero',
											description:
												'Label for the sms input in the dialog for manually adding an invitee to the message service recipients table.',
										})}
										lineDirection="center"
										placeholder={intl.formatMessage({
											defaultMessage: 'Muista maakoodi +358',
											description:
												'Placeholder for the sms input in the dialog for manually adding an invitee to the message service recipients table.',
										})}
										fullWidth
										value={phoneNumber || ''}
										onChange={(phoneNumber) => this.setState({ phoneNumber })}
										autoComplete="off"
									/>
								</Cell>
							)}
						</Grid>

						<div
							className="flex-row margin-bottom--sm margin-right--sm"
							style={{ justifyContent: 'flex-end' }}
						>
							{processing ? (
								<Button raised secondary disabled>
									<CircularProgress id="progress" style={{ marginTop: -4 }} />
								</Button>
							) : (
								<Button raised secondary onClick={this.getUsersManual}>
									{intl.formatMessage({
										defaultMessage: 'Lisää vastaanottaja',
										description:
											'Label for the button that manually adds a recipient to the message service recipients table.',
									})}
								</Button>
							)}
						</div>
					</div>
				</section>
			</ViiluDialog>
		)
	}
}

ImporterDialogManual.propTypes = {
	onHide: PropTypes.func.isRequired,
	onSuccess: PropTypes.func.isRequired,
	company: PropTypes.object.isRequired,
}

const mapState = ({ dimensions: { isDesktop } }) => ({
	isDesktop,
})

const mapDispatchToProps = (dispatch) => ({
	_showInfoNotification: bindActionCreators(notifyActions.info, dispatch),
	_getUserDigitalDeliveryConsentByEmail: bindActionCreators(
		companyActions._getUserDigitalDeliveryConsentByEmail,
		dispatch,
	),
})

export default compose(
	injectIntl,
	WithDialogs,
	WithSelectedCompany,
	connect(mapState, mapDispatchToProps),
)(ImporterDialogManual)
