import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect, isLoaded } from 'react-redux-firebase'
import getBuildInfo from '../../build-info'
import LoadingState from './LoadingState'

const ONE_WEEK = 604800000
const THREE_DAYS = 259200000
const ONE_HOUR = 3600000

export default (ComponentToWrap) => {
	class WithDeployments extends Component {
		componentDidMount = () => {
			const delayMs = 60000
			this.updateInterval = window.setInterval(
				() => this.forceUpdate(),
				delayMs,
			)
		}
		componentWillUnmount = () => {
			if (this.updateInterval) {
				window.clearInterval(this.updateInterval)
			}
		}
		render() {
			const { deployments } = this.props
			const { buildSHA } = getBuildInfo()
			if (!isLoaded(deployments)) {
				return <LoadingState small />
			}

			const inflightVersion =
				deployments &&
				deployments.length &&
				deployments.find((v) => v.uuid === buildSHA)
			const latestVersion = deployments && deployments.length && deployments[0]

			const inflightVersionAge = inflightVersion
				? Date.parse(new Date()) - inflightVersion.ts
				: ONE_WEEK
			const latestVersionAge = latestVersion
				? Date.parse(new Date()) - latestVersion.ts
				: ONE_WEEK

			let isLatestVersion = false
			if (
				inflightVersion &&
				latestVersion &&
				(inflightVersion.uuid === latestVersion.uuid ||
					(latestVersionAge < ONE_HOUR &&
						latestVersion.buildNumber - inflightVersion.buildNumber < 3)) // Don't notify about updates right away
			) {
				// Above we first check if we are in fact using the latest version.
				// If not we wait for at least an hour before showing the update message
				// except if there have been multiple deployments already in between the current
				// and latest deployments. This makes sure we don't flash the green up-to-date
				// icon in case the user is running an old version
				isLatestVersion = true
			}

			let isRecentVersion = false
			if (
				inflightVersion &&
				latestVersion &&
				(inflightVersion.uuid === latestVersion.uuid ||
					inflightVersionAge < THREE_DAYS) // Don't notify about updates right away
			) {
				// Above we check that first if the current version is the latest.
				// If not we think everything is fine until the current version is over
				// three days old. After that we start showing visible update suggestions.
				isRecentVersion = true
			}

			return (
				<ComponentToWrap
					{...this.props}
					isLatestVersion={isLatestVersion}
					isRecentVersion={isRecentVersion}
					inflightVersion={inflightVersion}
					latestVersion={latestVersion}
					deployments={deployments}
					inflightVersionAge={inflightVersionAge}
					latestVersionAge={latestVersionAge}
				/>
			)
		}
	}

	const mapState = ({
		firestoreReducer: {
			ordered: { deployment },
		},
	}) => ({
		deployments: deployment,
	})

	const mapDispatchToProps = (dispatch) => ({})

	return compose(
		firestoreConnect(() => {
			return [
				{
					collection: 'deployment',
					orderBy: ['ts', 'desc'],
					limit: 100,
				},
			]
		}),
		connect(mapState, mapDispatchToProps),
	)(WithDeployments)
}
