import { FontIcon } from 'react-md'
import {
	SummaryList,
	SummaryListItem,
} from 'ui/MessageService/MessageServiceProcess/steps/Sending/steps/Summary/styles'

function SummaryItems({ items }) {
	return (
		<SummaryList>
			{items.map((item, index) => (
				<SummaryListItem key={index}>
					<FontIcon>{item.icon}</FontIcon>
					<span>{item.text}</span>
				</SummaryListItem>
			))}
		</SummaryList>
	)
}

export default SummaryItems
