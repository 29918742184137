import { LoanInterestDaysMonth, LoanInterestDaysYear } from '../enum'
import { interestDaysMonthMessage, interestDaysYearMessage } from '../messages'
import type { CreateLoanInput } from '../types'

export const createLoanBasicInfoInputs: CreateLoanInput[] = [
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Lainan nimi',
				description: 'Loan name',
			}),
		key: 'name',
		show: true,
		type: 'text',
		fullWidth: true,
		rules: (intl) => ({
			required: intl.formatMessage({
				defaultMessage: 'Lainan nimi vaaditaan.',
				description: 'Error message for required loan name',
			}),
		}),
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Lainan arvioitu nostopäivä',
				description: 'Loan end date',
			}),
		key: 'loanStartDateEstimated',
		show: true,
		type: 'date',
		rules: (intl) => ({
			required: intl.formatMessage({
				defaultMessage: 'Lainan arvioitu alkupäivä vaaditaan.',
				description: 'Error message for required loan end date',
			}),
		}),
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Lainan arvioitu loppupäivä',
				description: 'Loan end date',
			}),
		key: 'loanEndDateEstimated',
		show: true,
		type: 'date',
		rules: (intl) => ({
			required: intl.formatMessage({
				defaultMessage: 'Lainan arvioitu loppupäivä vaaditaan.',
				description: 'Error message for required loan end date',
			}),
		}),
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Päätetty lainasumma',
				description: 'Original loan amount',
			}),
		key: 'originalLoanAmount',
		show: true,
		type: 'number',
		fullWidth: true,
		rules: (intl) => ({
			required: intl.formatMessage({
				defaultMessage: 'Alkuperäinen pääoma vaaditaan.',
				description: 'Error message for required original loan amount',
			}),
			min: {
				value: 10,
				message: intl.formatMessage({
					defaultMessage: 'Alkuperäisen pääoman on oltava vähintään 10',
					description: 'Error message for too small original loan amount',
				}),
			},
		}),
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Lainaosuus maksettavissa',
				description: 'Loan share can be paid off',
			}),
		key: 'loanSharesCanBePaidOff',
		show: true,
		type: 'boolean',
		rules: (intl) => ({
			required: intl.formatMessage({
				defaultMessage: 'Tieto lainaosuuden maksettavuudesta vaaditaan.',
				description:
					'Error message for required loan share can be paid off boolean',
			}),
		}),
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Laina kokonaan nostettu',
				description: 'Loan has been fully drawn',
			}),
		key: 'loanDrawn',
		show: true,
		type: 'boolean',
		rules: (intl) => ({
			required: intl.formatMessage({
				defaultMessage: 'Tieto lainan nostotilasta vaaditaan.',
				description: 'Error message for required loan drawn status boolean',
			}),
		}),
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Maksupäivät ilman lisäkuluja',
				description: 'Payment dates without extra fees',
			}),
		key: 'loanSharePaymentDates',
		show: false,
		showOnCondition: (watch) => watch('loanSharesCanBePaidOff') === true,
		type: 'day-month',
		rules: (intl) => ({
			required: intl.formatMessage({
				defaultMessage: 'Maksupäivät ilman lisäkuluja vaaditaan.',
				description: 'Error message for required loan share payment dates',
			}),
		}),
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Näytetään isännöitsijätodistuksella',
				description: 'Show on building manager certificate',
			}),
		key: 'usableOnBuildingManagerCertificate',
		show: true,
		type: 'boolean',
		rules: (intl) => ({
			required: intl.formatMessage({
				defaultMessage:
					'Tieto isännöitsijätodistuksella näyttämisestä vaaditaan.',
				description:
					'Error message for required building manager certificate boolean',
			}),
		}),
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Korkopäivät / kk',
				description: 'Interest days in month',
			}),
		key: 'interestDaysMonth',
		show: true,
		type: 'radio',
		menuItems: (intl) => {
			return Object.values(LoanInterestDaysMonth).map((interestOption) => ({
				label: interestDaysMonthMessage(intl, interestOption),
				value: interestOption,
			}))
		},
		rules: (intl) => ({
			required: intl.formatMessage({
				defaultMessage: 'Korkopäivät kuukaudessa vaaditaan.',
				description: 'Error message for required interest days in month',
			}),
		}),
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Korkopäivät / vuosi',
				description: 'Interest days in year',
			}),
		key: 'interestDaysYear',
		show: true,
		type: 'radio',
		menuItems: (intl) => {
			return Object.values(LoanInterestDaysYear).map((interestOption) => ({
				label: interestDaysYearMessage(intl, interestOption),
				value: interestOption,
			}))
		},
		rules: (intl) => ({
			required: intl.formatMessage({
				defaultMessage: 'Korkopäivät vuodessa vaaditaan.',
				description: 'Error message for required interest days in year',
			}),
		}),
	},
]
