// NOTE: on native app, the user agent is of the form:
// {`${userAgentPrefix} ${Platform.OS} ${version}`}, where
// Platform.OS = "ios" | "android" | "windows" | "macos" | "web"
// const UA = navigator.userAgent.toLowerCase() // not needed for now, but preserving for future

// NOTE: these functions do not work properly on the native mobile browser, because we don't have the injected window props ('isNativeAppIos', etc)!

const W = window as any

// enum for now due to use in js files
export const PLATFORM = {
	ANDROID: 'android',
	IOS: 'ios',
	WEB: 'web',
	UNCHECKED: 'unchecked',
}

export const getPlatform = () => {
	if (isIosDevice()) {
		return PLATFORM.IOS
	} else if (isAndroidDevice()) {
		return PLATFORM.ANDROID
	} else {
		return PLATFORM.WEB
	}
}

// detects tablets as well
export const isAndroidDevice = () => W.isNativeAppAndroid

export const isIosDevice = () => W.isNativeAppIos

export const isIpad = () => W.isIpad

export const isIphone = () => isIosDevice() && !isIpad()

export const isMobileDevice = () => isIosDevice() || isAndroidDevice()

export const isWeb = () => !isMobileDevice()

export const isWindows = () => navigator.appVersion.indexOf('Win') !== -1
