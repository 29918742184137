import { injectIntl } from 'react-intl'
import { useState } from 'react'
import moment from 'moment-timezone'
import {
	DataTable,
	TableBody,
	TableColumn,
	TableHeader,
	TableRow,
} from 'react-md'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import naturalSort from 'javascript-natural-sort'

import EmptyState from 'ui/components/EmptyState'
import documentsEmptyIcon from 'svg/documents-empty.svg'
import WithSelectedManager from 'ui/components/WithSelectedManager'
import DetailsDialog from './DetailsDialog'

function History({ history }) {
	const [detailsDialog, setDetailsDialog] = useState({
		visible: false,
		messageServiceMassProcess: null,
	})
	const [sortBy, setSortBy] = useState('sentTs')
	const [sortAscending, setSortAscending] = useState(false)

	if (!history) {
		return null
	}

	if (history.length === 0) {
		return (
			<EmptyState
				text="Et ole vielä lähettänyt mitään"
				icon={documentsEmptyIcon}
			/>
		)
	}

	const renderTs = (ts) => {
		if (!ts) {
			return '-'
		}

		const m = moment(new Date(ts))

		return (
			<div className="flex-row">
				<p>{m.format('DD.MM.YYYY')}</p>
				<p style={{ color: 'var(--color-text-subtle)', marginLeft: 4 }}>
					{m.format('HH:mm')}
				</p>
			</div>
		)
	}

	const sortedHistory = [...history]
	if (sortBy === 'sentTs') {
		if (sortAscending) {
			sortedHistory.sort(bySentTsAsc)
		} else {
			sortedHistory.sort(bySentTsDesc)
		}
	} else if (sortBy === 'title') {
		if (sortAscending) {
			sortedHistory.sort(byTitleAsc)
		} else {
			sortedHistory.sort(byTitleDesc)
		}
	}

	const renderSortableTableHeaderColumn = (text, columnSortKey) => {
		return (
			<TableColumn
				key={columnSortKey}
				onClick={() => {
					if (sortBy !== columnSortKey) {
						setSortBy(columnSortKey)
						setSortAscending(false)
					} else {
						setSortAscending(!sortAscending)
					}
				}}
				sorted={sortBy === columnSortKey ? sortAscending : undefined}
				style={{ cursor: 'pointer' }}
			>
				{text}
			</TableColumn>
		)
	}

	return (
		<>
			<DataTable plain>
				<TableHeader>
					<TableRow>
						{renderSortableTableHeaderColumn('Otsikko', 'title')}
						{renderSortableTableHeaderColumn('Lähetetty', 'sentTs')}
					</TableRow>
				</TableHeader>
				<TableBody>
					{sortedHistory.map((item) => (
						<TableRow
							key={item.uuid}
							onClick={() => {
								setDetailsDialog({
									visible: true,
									messageServiceMassProcess: item,
								})
							}}
							style={{ cursor: 'pointer' }}
						>
							<TableColumn>{item.title}</TableColumn>
							<TableColumn>{renderTs(item.sentTs)}</TableColumn>
						</TableRow>
					))}
				</TableBody>
			</DataTable>

			{detailsDialog.visible && (
				<DetailsDialog
					visible={detailsDialog.visible}
					messageServiceMassProcess={detailsDialog.messageServiceMassProcess}
					onHide={() => {
						setDetailsDialog({
							visible: false,
							messageServiceMassProcess: null,
						})
					}}
				/>
			)}
		</>
	)
}

const byTitleAsc = (a, b) => {
	if (!a.title && !b.title) return 0
	if (!a.title) return 1
	if (!b.title) return -1
	return naturalSort(b.title, a.title)
}
const byTitleDesc = (a, b) => {
	if (!a.title && !b.title) return 0
	if (!a.title) return 1
	if (!b.title) return -1
	return naturalSort(a.title, b.title)
}

const bySentTsAsc = (a, b) => {
	if (!a.sentTs && !b.sentTs) return 0
	if (!a.sentTs) return 1
	if (!b.sentTs) return -1
	return a.sentTs - b.sentTs
}
const bySentTsDesc = (a, b) => {
	if (!a.sentTs && !b.sentTs) return 0
	if (!a.sentTs) return 1
	if (!b.sentTs) return -1
	return b.sentTs - a.sentTs
}

const mapState = ({
	firestoreReducer: {
		ordered: { message_service_mass_process_history },
	},
}) => ({
	history: message_service_mass_process_history,
})

const mapDispatchToProps = (dispatch) => ({})

export default compose(
	injectIntl,
	WithSelectedManager({ hideLoading: false }),
	firestoreConnect(({ selectedManager }) => {
		const query = [
			['managerUUID', '==', selectedManager.uuid],
			['status', '==', 'sent'],
		]

		return [
			{
				collection: 'message-service-mass-process',
				storeAs: 'message_service_mass_process_history',
				where: query,
			},
		]
	}),
	connect(mapState, mapDispatchToProps),
)(History)
