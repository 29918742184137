import { useSelector } from 'react-redux'
import { useFirestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase'
import config from 'config'

const ROLES = config.userRoles

// TODO: once the 'userRoles' object in Firestore is migrated to handle more than
// a single value, the combined checks that determine admin status (etc) can be decoupled from
// one another.

// we can assume it to be in Redux
const selectUid = (state: any) => {
	return state.firebaseReducer?.auth?.uid
}

// we can assume it to be in Redux
const selectSelectedCompanyUUID = (state: any) => {
	return state.app?.selectedCompanyUUID
}

const selectSuperAdmin = (state: any) => {
	const uid = state.firebaseReducer?.auth?.uid
	const superAdmin = state.firestoreReducer.ordered[`superAdmin_${uid}`]
	if (!isLoaded(superAdmin) || isEmpty(superAdmin)) {
		return null
	}
	return superAdmin[0]
}

// it is only a hook so that we can use other hooks within it
const useSelectedCompanyRole = (): string => {
	const selectedCompanyUUID = useSelector(selectSelectedCompanyUUID) || ''
	useFirestoreConnect([
		{
			collection: `company`,
			storeAs: `company_${selectedCompanyUUID}`,
			where: [['uuid', '==', selectedCompanyUUID]],
			limit: 1,
		},
	])
	const selectedCompany = useSelector((state: any) => {
		const company =
			state.firestoreReducer.ordered[`company_${selectedCompanyUUID}`]
		if (!isLoaded(company) || isEmpty(company)) {
			return null
		}
		return company[0]
	})
	const uid = useSelector(selectUid)

	if (!uid || !selectedCompany || !selectedCompany.userRoles) {
		return ROLES.none
	}
	return selectedCompany.userRoles[uid] || ROLES.none
}

const useIsCompanyAdmin = () => {
	const selectedCompanyUUID = useSelector(selectSelectedCompanyUUID) || ''
	useFirestoreConnect([
		{
			collection: `company`,
			storeAs: `company_${selectedCompanyUUID}`,
			where: [['uuid', '==', selectedCompanyUUID]],
			limit: 1,
		},
	])
	const selectedCompany = useSelector((state: any) => {
		const company =
			state.firestoreReducer.ordered[`company_${selectedCompanyUUID}`]
		if (!isLoaded(company) || isEmpty(company)) {
			return null
		}
		return company[0]
	})
	const uid = useSelector(selectUid)
	if (!selectedCompany || !uid || !selectedCompany.userRoles) {
		return false
	}

	const role = selectedCompany.userRoles[uid] || ROLES.none
	return role.includes('admin')
}

// Finnish: järjestelmänvalvoja
export const useIsAdmin = () => {
	const isManager = useIsManager()
	const isSuperAdmin = useIsSuperAdmin()
	const isCompanyAdmin = useIsCompanyAdmin()
	if (isSuperAdmin || isManager || isCompanyAdmin) {
		return true
	}
	return false
}

export const useIsSuperAdmin = () => {
	const uid = useSelector(selectUid) || null

	useFirestoreConnect(
		uid
			? [
					{
						collection: 'super-admin',
						storeAs: `superAdmin_${uid}`,
						where: [['uid', '==', uid]],
						limit: 1,
					},
			  ]
			: [],
	)

	const superAdmin = useSelector(selectSuperAdmin)
	if (!uid || !superAdmin) {
		return false
	}

	if (superAdmin.uid !== uid || !superAdmin.active) {
		return false
	}
	return true
}

// Finnish: isännöitsijä
export const useIsManager = () => {
	const selectedCompanyUUID = useSelector(selectSelectedCompanyUUID) || ''
	useFirestoreConnect([
		{
			collection: `company`,
			storeAs: `company_${selectedCompanyUUID}`,
			where: [['uuid', '==', selectedCompanyUUID]],
			limit: 1,
		},
	])
	const selectedCompany = useSelector((state: any) => {
		const company =
			state.firestoreReducer.ordered[`company_${selectedCompanyUUID}`]
		if (!isLoaded(company) || isEmpty(company)) {
			return null
		}
		return company[0]
	})
	const managerUUID = selectedCompany
		? selectedCompany.managerUUID || null
		: null

	useFirestoreConnect([
		{
			collection: `manager`,
			storeAs: `manager_${managerUUID}`,
			where: [['uuid', '==', managerUUID]],
			limit: 1,
		},
	])
	const manager = useSelector((state: any) => {
		const manager = state.firestoreReducer.ordered[`manager_${managerUUID}`]
		if (!isLoaded(manager) || isEmpty(manager)) {
			return null
		}
		return manager[0]
	})
	const uid = useSelector(selectUid)

	if (!uid || !selectedCompany || !manager) {
		return false
	}

	if (!manager.userRoles) {
		return false
	}

	const role = manager.userRoles[uid] || ROLES.none
	return role !== ROLES.none
}

// Finnish: asukas
export const useIsTenant = () => {
	return useSelectedCompanyRole() === ROLES.userTenant
}

// Finnish: osakas
export const useIsShareholder = () => {
	return useSelectedCompanyRole() === ROLES.userShareholder
}

// Finnish: hallituksen jäsen
export const useIsBoardMember = () => {
	return useSelectedCompanyRole() === ROLES.adminBoardMember
}

// Finnish: hallituksen varajäsen
export const useIsBoardDeputy = () => {
	return useSelectedCompanyRole() === ROLES.adminBoardDeputy
}

// Finnish: hallituksen puheenjohtaja
export const useIsBoardDirector = () => {
	return useSelectedCompanyRole() === ROLES.adminBoardDirector
}

// 'useIsBoardMember' is taken; otherwise we'd use it as the name
export const useIsPartOfBoard = () => {
	const role = useSelectedCompanyRole()
	return (
		role === ROLES.adminBoardMember ||
		role === ROLES.adminBoardDeputy ||
		role === ROLES.adminBoardDirector
	)
}

export const useIsMaintenanceCompany = () => {
	return useSelectedCompanyRole() === ROLES.maintenanceCompany
}

export const useUserRoles = () => {
	return {
		isAdmin: useIsAdmin(),
		isSuperAdmin: useIsSuperAdmin(),
		isManager: useIsManager(),
		isTenant: useIsTenant(),
		isShareholder: useIsShareholder(),
		isBoardMember: useIsBoardMember(),
		isBoardDeputy: useIsBoardDeputy(),
		isBoardDirector: useIsBoardDirector(),
		isPartOfBoard: useIsPartOfBoard(),
		isMaintenanceCompany: useIsMaintenanceCompany(),
	}
}
