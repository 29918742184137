import React from 'react'
import { FontIcon } from 'react-md'
import styled, { keyframes } from 'styled-components'

const anim = keyframes`
	0% {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}

	75% {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}

	100% {
		opacity: 1;
		transform: translate3d(-100%, 0, 0);
	}
`
const RouteFacadeContainer = styled.div`
	opacity: 0;
	position: absolute;
	display: flex;
	height: 100%;
	width: 100%;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	z-index: 16;
	animation-name: ${anim};
	animation-fill-mode: both;
`

const RouteFacade = ({
	background,
	leftImg,
	rightImg,
	icon,
	animationDuration = 1300,
}) => {
	animationDuration = String(animationDuration / 1000) + 's'
	return (
		<RouteFacadeContainer style={{ background, animationDuration }}>
			<FontIcon style={{ fontSize: 64, color: 'white' }}>{icon}</FontIcon>
		</RouteFacadeContainer>
	)
}

export default RouteFacade
