import AwesomeDebouncePromise from 'awesome-debounce-promise'
import { useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import {
	FirestoreReducer,
	isLoaded,
	useFirestoreConnect,
} from 'react-redux-firebase'
import type { Building } from 'types/building'
import config from '../../config'
import Geocode from 'util/geocode'
import cap from 'util/capitalizeFirstLetter'
type FirestoreState = FirestoreReducer.Reducer

export const useBuildings = (selectedCompanyUUID: string) => {
	useFirestoreConnect(() => {
		return [
			{
				collection: 'building',
				storeAs: `buildings_list_${selectedCompanyUUID}`,
				where: [['companyUUID', '==', selectedCompanyUUID]],
			},
		]
	})

	return useSelector(
		({ firestoreReducer }: { firestoreReducer: FirestoreState }) => {
			if (!firestoreReducer.ordered) {
				return { loading: true, buildings: [] }
			}

			const buildings = firestoreReducer.ordered[
				`buildings_list_${selectedCompanyUUID}`
			] as Building[]

			if (!isLoaded(buildings)) {
				return { loading: true, buildings }
			}
			return { loading: false, buildings }
		},
	)
}

export const useGeocode = (
	initialAddress = '',
	initialZip = '',
	initialArea = '',
) => {
	const [address, setAddress] = useState(initialAddress)
	const [zip, setZip] = useState(initialZip)
	const [area, setArea] = useState(initialArea)

	Geocode.setApiKey(config.maps.key)
	Geocode.setLanguage('fi')
	const GeoCodeAPI = (address) =>
		Geocode.fromAddress(address, config.maps.key, 'fi')
	const GeoCodeAPIDebounced = AwesomeDebouncePromise(GeoCodeAPI, 500)

	const geocode = useCallback(async () => {
		let query = address
		if (zip) {
			query += ', ' + zip
		}
		if (area) {
			query += ', ' + area
		}
		query += ', Suomi'

		try {
			const response = await GeoCodeAPIDebounced(query + ', Suomi')
			let longName: string
			const locality = response.results[0].address_components.find((a) =>
				a.types.includes('locality'),
			)
			if (locality) {
				longName = locality.long_name
			} else {
				const political = response.results[0].address_components.find((a) =>
					a.types.includes('political'),
				)
				if (political) {
					longName = political.long_name
				} else {
					setArea('')
					console.error('area not found')
					return
				}
			}
			setArea(cap(longName))
		} catch (ex) {
			setArea('')
			console.error(ex)
		}
	}, [address, zip, area, GeoCodeAPIDebounced])

	return {
		address,
		setAddress,
		zip,
		setZip,
		area,
		setArea,
		geocode,
	}
}
