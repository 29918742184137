import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'

import AppToolbar from 'ui/AppToolbar'
import Navbar from 'site/Navbar'

const WrapperToolbar = (props) => {
	const { location } = props

	const isMarketingSiteRoute = () => {
		const isMarketingSiteRoute =
			location && location.pathname && location.pathname.includes('/site')
		return isMarketingSiteRoute
	}
	const showMarketingSiteNavbar = isMarketingSiteRoute()

	if (showMarketingSiteNavbar) {
		return <Navbar {...props} />
	}

	return <AppToolbar {...props} />
}

WrapperToolbar.propTypes = {
	auth: PropTypes.object,
}

const mapState = ({ firebaseReducer: { auth }, router: { location } }) => ({
	auth,
	location,
})

export default compose(connect(mapState))(WrapperToolbar)
