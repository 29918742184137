import { useIntl } from 'react-intl'
import { Checkbox } from 'react-md'

function DistributionSelection({ distribution = [], onSelectDistribution }) {
	const intl = useIntl()

	return (
		<>
			<Checkbox
				id="checkbox-message-service-integration-tenants"
				label={intl.formatMessage({
					defaultMessage: 'Asukkaille',
					description:
						'Label for the tenants option in the message service integration settings step.',
				})}
				name="tenants"
				checked={distribution.includes('tenants')}
				onChange={(checked) => {
					onSelectDistribution(checked, 'tenants')
				}}
				style={{ marginBottom: 16 }}
				disabled={!onSelectDistribution}
			/>
			<Checkbox
				id="checkbox-message-service-integration-shareholders"
				label={intl.formatMessage({
					defaultMessage: 'Osakkaille',
					description:
						'Label for the shareholders option in the message service integration settings step.',
				})}
				name="shareholders"
				checked={distribution.includes('shareholders')}
				onChange={(checked) => {
					onSelectDistribution(checked, 'shareholders')
				}}
				style={{ marginBottom: 16 }}
				disabled={!onSelectDistribution}
			/>
			<Checkbox
				id="checkbox-message-service-integration-board"
				label={intl.formatMessage({
					defaultMessage: 'Hallitukselle',
					description:
						'Label for the shareholders option in the message service integration settings step.',
				})}
				name="board"
				checked={distribution.includes('board')}
				onChange={(checked) => {
					onSelectDistribution(checked, 'board')
				}}
				style={{ marginBottom: 16 }}
				disabled={!onSelectDistribution}
			/>
		</>
	)
}

export default DistributionSelection
