import { functions } from './store'
import { httpsCallable } from 'firebase/functions'
import ReactGA from 'react-ga4'

export function filterUndefinedChanges(changes) {
	const parameters = Object.keys(changes)
	const filteredChanges = {}
	parameters.forEach((key) => {
		const value = changes[key]
		if (value !== undefined) {
			filteredChanges[key] = value
		}
	})
	return filteredChanges
}

export const backendFunction = async (
	functionName,
	parameters,
	callableOptions,
) => {
	ReactGA.event({
		category: 'feature-usage',
		action: functionName,
	})

	const callable = httpsCallable(functions, functionName, callableOptions)

	try {
		const result = await callable(parameters)
		return result
	} catch (ex) {
		console.log(ex)
		if (window.sentry) {
			window.sentry.captureException(ex, { tags: { functionName } })
		}

		return {
			success: false,
			ex,
		}
	}
}
