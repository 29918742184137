import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import useContracts from './useContracts'
import { useEffect, useMemo, useState } from 'react'
import WithSelectedManagerCompanies from 'ui/components/WithSelectedManagerCompanies'
import type { Manager } from 'types/manager'
import type { ContractFilters } from './types'
import ContractView from './ContractView'
import { InvoicingMethod } from '../enum'
import useCompanyApartments from 'util/hooks/useCompanyApartments'
import useParties from '../Parties/useParties'
import ContractDialog from './ContractDialog'
import { createApartmentOptions } from '../utils/apartmentOptions'
import { useBuildings } from 'ui/CompanyBuildings/hooks'
import type { SelectOptions } from '../types'
import { createPartyOptions } from '../utils/partyOptions'
import { invoicingMethodMessage } from '../utils/messages'
import { useIntl } from 'react-intl'

type Props = {
	selectedManagerCompanies: Manager[]
}

const Contracts = ({ selectedManagerCompanies }: Props) => {
	const intl = useIntl()
	const selectedFinanceCompanyUUID = useSelector(
		({ app }: any) => app?.selectedFinanceCompanyUUID,
	)
	const dispatch = useDispatch()

	const [filters, setFilters] = useState<ContractFilters>({
		companyUUID: selectedFinanceCompanyUUID || '',
		apartmentUUID: '',
		reviewDate: new Date(),
		invoicingMethod: '',
		searchWord: '',
	})

	useEffect(() => {
		setFilters((prev) => ({ ...prev, companyUUID: selectedFinanceCompanyUUID }))
	}, [selectedFinanceCompanyUUID])

	const contracts = useContracts(filters)

	const financeFilters = useMemo(
		() => ({
			reviewDate: new Date(),
			active: true,
			bondName: '',
			apartmentUUID: '',
			invoicingMethod: '',
		}),
		[],
	)

	const { parties } = useParties(selectedFinanceCompanyUUID, financeFilters)
	const { companyApartments } = useCompanyApartments(selectedFinanceCompanyUUID)
	const { buildings } = useBuildings(selectedFinanceCompanyUUID)

	const location = useSelector((state: any) => state.router?.location)
	const params = new URLSearchParams(location.search)
	const contractUUID = params.get('cuuid')

	const onHideUpdateContractDialog = () => {
		dispatch(push(window.location.pathname))
	}

	const apartmentOptions = createApartmentOptions(companyApartments, buildings)

	const invoicingOptions: SelectOptions[] = Object.values(InvoicingMethod).map(
		(value) => ({
			label: invoicingMethodMessage(intl, value),
			value: value,
		}),
	)

	let companyOptions: SelectOptions[] = []
	if (selectedManagerCompanies && selectedManagerCompanies.length > 0) {
		companyOptions = selectedManagerCompanies.map((manager: Manager) => {
			return {
				label: manager.name,
				value: manager.uuid,
			}
		})
	}

	const payerOptions = createPartyOptions(parties, intl)

	const renderPage = () => {
		return (
			<div
				className="flex-column flex-grow"
				style={{ overflowY: 'auto', height: 'auto', minHeight: '400px' }}
			>
				<ContractView
					useContracts={contracts}
					filters={filters}
					setFilters={setFilters}
					apartmentOptions={apartmentOptions}
					invoicingOptions={invoicingOptions}
					selectedFinanceCompanyUUID={selectedFinanceCompanyUUID}
					companyOptions={companyOptions}
					payerOptions={payerOptions}
					companyApartments={companyApartments}
				/>
				{contractUUID && (
					<ContractDialog
						id={contractUUID}
						useContracts={contracts}
						onHide={onHideUpdateContractDialog}
						selectedFinanceCompanyUUID={selectedFinanceCompanyUUID}
						companyOptions={companyOptions}
						payerOptions={payerOptions}
						apartmentOptions={apartmentOptions}
						invoicingOptions={invoicingOptions}
						companyApartments={companyApartments}
					/>
				)}
			</div>
		)
	}

	return (
		<div className="flex-column" style={{ flexGrow: 1, overflow: 'hidden' }}>
			{renderPage()}
		</div>
	)
}

export default WithSelectedManagerCompanies({ hideLoading: false })(Contracts)
