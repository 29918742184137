import { TextField, CardTitle, CardText, TextFieldTypes } from 'react-md'
import { useIntl } from 'react-intl'
import { StandardCard } from 'ui/StyledComponents/Cards'
import {
	contactInformationHeaders,
	invoicingInformationHeaders,
	settingsCompanyHeaders,
} from './constants'
import type { Header, Settings } from './types'
import styled from 'styled-components'
import FloatingActionBtn from 'ui/components/FloatingActionBtn'
import LoadingState from 'ui/components/LoadingState'
import type { Company } from 'types/company'
import { countryCodeMessage } from '../utils/messages'

export const SettingsContainer = styled.div`
	display: flex;
	flex-direction: column;
	flex-shrink: 0;
	flex-grow: 1;
	align-items: center;
	justify-content: flex-start;
	padding-top: 24px;
	padding-bottom: 24px;
`

type Props = {
	settings: Settings
	setSettings: React.Dispatch<React.SetStateAction<Settings>>
	processing: boolean
	saveSettings: () => void
	loading: boolean
	company: Company | null
}

const BasicInformation = ({
	settings,
	setSettings,
	processing,
	saveSettings,
	loading,
	company,
}: Props) => {
	const intl = useIntl()

	const handleInputChange = (
		value: string | number | boolean,
		field: string,
	) => {
		setSettings((prevData) => ({
			...prevData,
			[field]: value,
		}))
	}

	const renderTextField = (headers: Header[]) => {
		return headers.map((header: Header) => {
			const { title, key, type, editable } = header
			if (header?.dataType === 'company') {
				return (
					<TextField
						key={key}
						id={key}
						label={title(intl)}
						lineDirection="center"
						fullWidth
						value={company?.[key] ?? ''}
						disabled={true}
						required={false}
						type={(type as TextFieldTypes) || undefined}
					/>
				)
			} else if (header?.dataType === 'zipCode') {
				return (
					<TextField
						key={key}
						id={key}
						label={title(intl)}
						lineDirection="center"
						fullWidth
						value={settings[key] || ''}
						onChange={(value) => handleInputChange(value, key)}
						disabled={!editable}
						required={false}
						type={(type as TextFieldTypes) || undefined}
						maxLength={5}
					/>
				)
			} else if (header?.dataType === 'country') {
				return (
					<TextField
						key={key}
						id={key}
						label={title(intl)}
						lineDirection="center"
						fullWidth
						value={countryCodeMessage(intl, settings[key]) || ''}
						onChange={(value) => handleInputChange(value, key)}
						disabled={!editable}
						required={false}
						type={(type as TextFieldTypes) || undefined}
					/>
				)
			} else
				return (
					<TextField
						key={key}
						id={key}
						label={title(intl)}
						lineDirection="center"
						fullWidth
						value={settings[key] || ''}
						onChange={(value) => handleInputChange(value, key)}
						disabled={!editable}
						required={false}
						type={(type as TextFieldTypes) || undefined}
					/>
				)
		})
	}

	const companyInformation = () => {
		return (
			<StandardCard className="margin-bottom" style={{ maxWidth: 768 }}>
				<CardTitle
					title={intl.formatMessage({
						defaultMessage: 'Kohde',
						description: 'Title of the housing company',
					})}
				/>
				<CardText>{renderTextField(settingsCompanyHeaders)}</CardText>
			</StandardCard>
		)
	}

	const contactInformation = () => {
		return (
			<StandardCard className="margin-bottom" style={{ maxWidth: 768 }}>
				<CardTitle
					title={intl.formatMessage({
						defaultMessage: 'Käyntiosoite',
						description: 'Title of the contact information',
					})}
				/>
				<CardText>{renderTextField(contactInformationHeaders)}</CardText>
			</StandardCard>
		)
	}

	const invoicingInformation = () => {
		return (
			<StandardCard className="margin-bottom" style={{ maxWidth: 768 }}>
				<CardTitle
					title={intl.formatMessage({
						defaultMessage: 'Laskutusosoite',
						description: 'Title of the invoicing address',
					})}
				/>
				<CardText>{renderTextField(invoicingInformationHeaders)}</CardText>
			</StandardCard>
		)
	}

	const renderFAB = () => {
		return (
			<FloatingActionBtn
				iconName={processing ? '' : 'save'}
				processing={processing}
				text={
					processing
						? intl.formatMessage({
								defaultMessage: 'Tallennetaan',
								description: 'Saving',
						  })
						: intl.formatMessage({
								defaultMessage: 'Tallenna',
								description: 'Save',
						  })
				}
				action={() => saveSettings()}
			/>
		)
	}

	if (loading) {
		return <LoadingState />
	}

	return (
		<SettingsContainer>
			<div className="flex-column" style={{ minWidth: '50vw' }}>
				{companyInformation()}
				{contactInformation()}
				{invoicingInformation()}
			</div>
			{renderFAB()}
		</SettingsContainer>
	)
}

export default BasicInformation
