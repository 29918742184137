import styled from 'styled-components'

export const Title = styled.h1`
	margin: 0 0 8px 0;
	font-size: 1.5rem;
`

export const StatusUl = styled.ul`
	list-style: none;
	margin: 16px 0;
	padding: 0;
`
