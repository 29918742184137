import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'

const Footer = styled.div`
	display: flex;
	flex-direction: row;
	padding: 16px;
	align-items: center;
	justify-content: center;
`
const FooterLink = styled.a`
	font-size: 10px;
	text-decoration: none;
	color: rgba(0, 0, 0, 0.6);
	:visited {
		color: rgba(0, 0, 0, 0.6);
	}
`
const FooterBulletin = styled.p`
	font-size: 10px;
	margin-left: 8px;
	margin-right: 8px;
	color: lightgray;
`

const LoginDialogFooter = () => {
	return (
		<Footer>
			<FooterLink
				href="https://vii.lu/privacy"
				target="_blank"
				onClick={() => window.open('https://vii.lu/privacy', 'blank_')}
			>
				<FormattedMessage
					defaultMessage="Tietosuojakäytäntö"
					description="Link that takes the user to the privacy policy page"
				/>
			</FooterLink>
			<FooterBulletin>•</FooterBulletin>
			<FooterLink
				href="https://vii.lu/tos"
				target="_blank"
				onClick={() => window.open('https://vii.lu/tos', 'blank_')}
			>
				<FormattedMessage
					defaultMessage="Käyttöehdot"
					description="Link that takes the user to the terms of service page"
				/>
			</FooterLink>
		</Footer>
	)
}

export default LoginDialogFooter
