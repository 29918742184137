import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Button } from 'react-md'
import { FormattedMessage } from 'react-intl'

import illustrationHome from 'svg/illustrations/illustration_home.svg'
import WithSelectedCompany from 'ui/components/WithSelectedCompany'

export class UserOnboardingStepIntro extends Component {
	next = async () => {
		const { onNext } = this.props
		onNext()
	}

	render = () => {
		const { selectedCompany } = this.props
		let title
		if (selectedCompany) {
			title = selectedCompany.name
		}

		const isManagerRoute =
			window.document.location.pathname.includes('/manager') ||
			window.document.location.pathname.includes('manager_invite')

		return (
			<div
				className="flex-column flex-center"
				style={{ height: 'calc(100% - 56px)', justifyContent: 'flex-start' }}
			>
				{title ? <h3>{title}</h3> : null}
				<img
					alt=""
					src={illustrationHome}
					style={{
						width: 288,
						height: 'auto',
						position: 'relative',
						margin: 16,
					}}
				/>
				<h4 className="text-center">
					{isManagerRoute ? (
						<FormattedMessage
							defaultMessage="Tervetuloa Viilun isännöintinäkymään!"
							description="Title of the manager profile update dialog."
						/>
					) : (
						<FormattedMessage
							defaultMessage="Tervetuloa taloyhtiösi omille sivuille!"
							description="Title of the user profile update dialog."
						/>
					)}
				</h4>
				<p className="text-center">
					<FormattedMessage
						defaultMessage="Varmistetaan ensin nopeasti, että tietosi ovat ajan tasalla."
						description="Instructional paragraph telling the user to ensure that their user profile info is up-to-date."
					/>
				</p>
				<Button
					raised
					secondary
					onClick={this.next}
					style={{ position: 'absolute', right: 24, bottom: 24 }}
				>
					<FormattedMessage
						defaultMessage="Aloita"
						description="Button that allows the user to start filling their user profile information."
					/>
				</Button>
			</div>
		)
	}
}

UserOnboardingStepIntro.propTypes = {
	onUpdate: PropTypes.func.isRequired,
	onNext: PropTypes.func.isRequired,
}

const mapState = ({ ui: { onboarding: onboardingReducer } }) => ({
	onboardingReducer,
})

const mapDispatchToProps = (dispatch) => ({})

export default compose(
	WithSelectedCompany,
	connect(mapState, mapDispatchToProps),
)(UserOnboardingStepIntro)
