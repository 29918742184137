import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import { compose } from 'redux'
import WithSelectedCompany from 'ui/components/WithSelectedCompany'
import DefaultTemplate from 'ui/MessageService/MessageServiceProcess/steps/Sending/steps/EmailPreview/DefaultTemplate'
import Layout from 'ui/MessageService/MessageServiceProcess/steps/Sending/steps/Layout'

const Title = styled.h1`
	margin-bottom: 24px;
`

const TemplateContainer = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
`

const Attachments = styled.div`
	display: flex;
	flex-wrap: wrap;
`
const Attachment = styled.div`
	border: 2px dashed #666;
	padding: 8px 32px;
	border-radius: 8px;
	background-color: white;
	text-align: center;
	margin: 4px;
`

function EmailPreview({
	stepTitle,
	onPreviousClick,
	onDraftClick,
	onNextClick,
	messageServiceProcess: { title, body, attachments, viewUrl },
	selectedCompany,
}) {
	const left = (
		<>
			<Title>{stepTitle}</Title>
			<p>
				<FormattedMessage
					defaultMessage="Näet sähköpostin sisällön oikealla. Huomioi, että oikealla on pelkkä esikatselu eivätkä sen sisältämät linkit johda minnekään."
					description="Informational description of the email preview step in the message service mass sending component."
				/>
			</p>
		</>
	)

	let right = (
		<TemplateContainer>
			<DefaultTemplate
				title={title}
				body={body}
				viewUrl={viewUrl}
				companyName={selectedCompany && selectedCompany.name}
			/>
			{attachments?.length > 0 && (
				<>
					<h3 style={{ fontSize: 18 }}>Liitteet</h3>
					<Attachments>
						{attachments.map((a) => (
							<Attachment key={a.filename}>{a.filename}</Attachment>
						))}
					</Attachments>
				</>
			)}
		</TemplateContainer>
	)

	return (
		<>
			<Layout
				left={left}
				right={right}
				onPreviousClick={onPreviousClick}
				onDraftClick={onDraftClick}
				onNextClick={onNextClick}
			/>
		</>
	)
}

export default compose(WithSelectedCompany)(EmailPreview)
