import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { Button, TextField, FontIcon } from 'react-md'
import { FormattedMessage, injectIntl } from 'react-intl'
import styled from 'styled-components'

import * as userActions from 'state/user-actions'

import {
	MALFORMED_EMAIL_MESSAGE,
	USER_DISABLED_MESSAGE,
	USER_NOT_FOUND_MESSAGE,
	WRONG_PASSWORD_MESSAGE,
	GENERIC_ERROR,
} from './messages'
import LoadingState from '../../LoadingState'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import { firebaseApp } from 'state/store'
import useDialogs from 'ui/components/dialogs/useDialogs'
import FB_ERRORS from 'util/firebaseErrors'

const CenteredText = styled.p`
	text-align: center;
`
const WarningIcon = styled(FontIcon)`
	font-size: ${({ $isDesktop }) =>
		$isDesktop ? '128px !important' : '96px !important'};
	color: var(--color-warning);
	margin-bottom: 16px;
	opacity: 0.87;
	&:after {
		content: 'warning';
	}
`
const PasswordIcon = styled(FontIcon)`
	font-size: ${({ $isDesktop }) =>
		$isDesktop ? '128px !important' : '96px !important'};
	margin-bottom: 16px;
	opacity: 0.87;
	&:after {
		content: 'lock';
	}
`

const PASSWORD_FIELD_ID = 'email-login-dialog-textfield-pass'

const LoginDialogEmailLogIn = ({
	email,
	onHide,
	intl,
	isDesktop,
	_push,
	_sendVerificationEmail,
	_sendPasswordResetEmailUnauthenticated,
}) => {
	const [password, setPassword] = useState('')
	const [errorText, setErrorText] = useState('')
	const [processing, setProcessing] = useState(false)
	const [helpVisible, setHelpVisible] = useState(false)
	const { alert } = useDialogs()

	useEffect(() => {
		let element = window.document.getElementById(PASSWORD_FIELD_ID)
		if (element) {
			element.focus()
		} else {
			window.setTimeout(() => {
				element = window.document.getElementById(PASSWORD_FIELD_ID)
				if (element) {
					element.focus()
				}
			}, 500)
		}
	}, [])

	const handleSuccess = async (authResult) => {
		try {
			const user = authResult.user
			if (user?.providerData?.find((p) => p.providerId === 'password')) {
				if (user.email && !user.emailVerified) {
					const redirectURL = window.location.href
					const result = await _sendVerificationEmail(redirectURL)

					if (!result || result.success === false) {
						if (result?.ex?.code === FB_ERRORS.FUNCTIONS_FAILED_PRECONDITION) {
							const message = intl.formatMessage({
								defaultMessage:
									'Tapahtui virhe. Käyttämäsi sähköposti saattaa olla sulkulistalla. Otathan meihin yhteyttä asiakaspalvelu@vii.lu niin hoidetaan homma kuntoon.',
								description:
									'Error message telling the user that their email address might be blocked.',
							})
							await alert(message)
						} else if (
							result?.ex?.code === FB_ERRORS.FUNCTIONS_RESOURCE_EXHAUSTED
						) {
							const message = intl.formatMessage({
								defaultMessage:
									'Olet lähettänyt useita vahvistusviestejä pienessä ajassa. Odota hetki ja yritä uudelleen.',
								description:
									'Error message telling the user that they have attempted to send too many verification emails in a short timespan.',
							})
							await alert(message)
						} else {
							const message = intl.formatMessage({
								defaultMessage:
									'Vahvistussähköpostin lähettämisessä tapahtui virhe.',
								description:
									'Error message telling the user that there was an error while sending the verification email.',
							})
							await alert(message)
						}
						return false
					}
				}
			}
			if (window.URLBeforeLogin) {
				const redirectURL = window.URLBeforeLogin
				window.setTimeout(() => _push(redirectURL), 1000)
			}
			onHide()
		} catch (ex) {
			if (window.sentry) window.sentry.captureException(ex)
			console.error(ex)
		}
	}

	const onSubmit = async () => {
		if (!firebaseApp) {
			return false
		}
		setProcessing(true)
		try {
			const userCredential = await signInWithEmailAndPassword(
				getAuth(firebaseApp),
				email,
				password,
			)
			if (!userCredential || !userCredential.user) {
				setErrorText(WRONG_PASSWORD_MESSAGE(intl))
				return false
			}
			handleSuccess(userCredential)
			return true
		} catch (ex) {
			if (ex.code === 'auth/invalid-email') {
				setErrorText(MALFORMED_EMAIL_MESSAGE(intl))
				return true
			} else if (ex.code === 'auth/user-disabled') {
				setErrorText(USER_DISABLED_MESSAGE(intl))
				return true
			} else if (ex.code === 'auth/user-not-found') {
				setErrorText(USER_NOT_FOUND_MESSAGE(intl))
				return true
			} else if (ex.code === 'auth/wrong-password') {
				setErrorText(WRONG_PASSWORD_MESSAGE(intl))
				return true
			}
			await alert(GENERIC_ERROR(intl))
			return false
		} finally {
			setProcessing(false)
		}
	}

	const onHelp = () => {
		setHelpVisible(true)
	}

	const onPasswordReset = async () => {
		try {
			setHelpVisible(false)
			const result = await _sendPasswordResetEmailUnauthenticated(email)
			if (!result || result.success === false) {
				await alert(
					intl.formatMessage({
						defaultMessage: 'Tapahtui virhe.',
						description: 'Generic error message.',
					}),
				)
			} else {
				await alert(
					intl.formatMessage({
						defaultMessage:
							'Lähetimme sinulle sähköpostitse ohjeet salasanasi palauttamiseen. Yritäthän uudelleen, mikäli viestiä ei näy eikä löydy edes roskapostista puolen tunnin kuluessa.',
						description:
							'Instructive paragraph telling the user that we have sent the password reset instructions to their email.',
					}),
				)
			}
		} catch (ex) {
			setHelpVisible(false)
			await alert(
				intl.formatMessage({
					defaultMessage:
						'Salasanan palautusviestin lähettämisessä tapahtui virhe. Syötithän sähköpostiosoitteesi oikeassa muodossa?',
					description:
						'Error message telling the user that an error occured while trying to send the password reset email.',
				}),
			)
			onHide()
		}
	}

	const validate = () => {
		if (!password) {
			return false
		}
		return true
	}

	const onKeyPress = (event) => {
		if (processing) {
			return false
		}
		if (event.charCode === 13) {
			// enter key pressed
			event.preventDefault()
			if (validate()) {
				onSubmit()
			}
		}
	}

	const getPasswordMessage = () => {
		return intl.formatMessage({
			defaultMessage: 'Salasana',
			description: 'Label of the password address field',
		})
	}

	const reset = () => {
		setPassword('')
		setErrorText('')
	}

	if (processing) {
		return (
			<div className="flex-column flex-center">
				<LoadingState />
			</div>
		)
	}

	if (helpVisible) {
		return (
			<div className="flex-column flex-center">
				<CenteredText>
					<FormattedMessage
						defaultMessage="Painamalla alla olevaa LÄHETÄ-painiketta lähetämme sinulle sähköpostitse ohjeet salasanasi palauttamista koskien."
						description="Instructional paragraph telling the user that by pressing the button we send them instructions on how to reset their password."
					/>
				</CenteredText>
				<Button
					raised
					secondary
					onClick={onPasswordReset}
					className="margin-top--xl"
					style={{
						alignSelf: 'center',
						background: 'var(--color-primary)',
						color: 'white',
					}}
				>
					<FormattedMessage
						defaultMessage="Lähetä"
						description="Button that sends the password reset instructions to the user via email."
					/>
				</Button>
			</div>
		)
	}

	if (errorText) {
		return (
			<div className="flex-column flex-center">
				<WarningIcon $isDesktop={isDesktop} />
				<CenteredText>{errorText}</CenteredText>
				<Button
					raised
					secondary
					onClick={reset}
					className="margin-top--xl"
					style={{
						alignSelf: 'center',
						background: 'var(--color-primary)',
						color: 'white',
					}}
				>
					<FormattedMessage
						defaultMessage="Yritä uudelleen"
						description="Button that resets the error message and let's the user try logging in again."
					/>
				</Button>
			</div>
		)
	}

	return (
		<div className="flex-column flex-center">
			<PasswordIcon $isDesktop={isDesktop} />
			<div>
				<CenteredText>
					<FormattedMessage
						defaultMessage="Syötä tunnuksesi salasana."
						description="Instructive paragraph asking the user to type in their password."
					/>
				</CenteredText>
				<a
					href="#!"
					onClick={onHelp}
					style={{ cursor: 'pointer', textAlign: 'center', marginBottom: 16 }}
				>
					{intl.formatMessage({
						defaultMessage: 'Ongelmia kirjautumisessa?',
						description:
							'Link that shows the password reset instructions when clicked.',
					})}
				</a>
			</div>
			<TextField
				id={PASSWORD_FIELD_ID}
				label={getPasswordMessage()}
				lineDirection="center"
				fullWidth
				onChange={setPassword}
				autoComplete="off"
				autoCapitalize="off"
				onKeyPress={onKeyPress}
				type="password"
			/>
			<Button
				raised={validate()}
				flat={!validate()}
				secondary
				onClick={onSubmit}
				disabled={processing || !validate()}
				className="margin-top--xl"
				style={
					validate()
						? {
								alignSelf: 'center',
								background: 'var(--color-primary)',
								color: 'white',
						  }
						: { alignSelf: 'center' }
				}
			>
				<FormattedMessage
					defaultMessage="Kirjaudu"
					description="Button that tries to log in the user with the given details."
				/>
			</Button>
		</div>
	)
}

LoginDialogEmailLogIn.propTypes = {
	email: PropTypes.string.isRequired,
	onHide: PropTypes.func.isRequired,
}

const mapState = ({ dimensions: { isDesktop } }) => ({ isDesktop })

const mapDispatchToProps = (dispatch) => ({
	_push: bindActionCreators(push, dispatch),
	_sendVerificationEmail: bindActionCreators(
		userActions._sendVerificationEmail,
		dispatch,
	),
	_sendPasswordResetEmailUnauthenticated: bindActionCreators(
		userActions._sendPasswordResetEmailUnauthenticated,
		dispatch,
	),
})

export default compose(
	injectIntl,
	connect(mapState, mapDispatchToProps),
)(LoginDialogEmailLogIn)
