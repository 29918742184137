import {
	DataTable,
	TableBody,
	TableColumn,
	TableHeader,
	TableRow,
} from 'react-md'
import { connect } from 'react-redux'
import { compose } from 'redux'
import WithSelectedManagerCompanies from 'ui/components/WithSelectedManagerCompanies'
import cap from 'util/capitalizeFirstLetter'

export const getFullAddress = (company) => {
	const address = company.address || ''
	const area = company.area || ''
	const zip = company.zip || ''

	return `${cap(address)}, ${cap(area)} ${zip}`
}

function CompanyTable({
	selectedManagerCompanies,
	selectedProcessCompanies,
	setSelectedProcessCompanies,
}) {
	const filteredManagerCompanies = selectedManagerCompanies.filter(
		(c) => !c.hiddenFromListing,
	)

	const handleSelect = (i, checked) => {
		const updated = { ...selectedProcessCompanies }

		if (i === 0) {
			filteredManagerCompanies.forEach((c) => {
				updated[c.uuid] = checked
			})
		} else {
			const selected = filteredManagerCompanies[i - 1]
			updated[selected.uuid] = checked
		}

		setSelectedProcessCompanies(updated)
	}

	return (
		<DataTable
			baseId="message-service-company-selection-table"
			style={{ maxWidth: 800, width: '100%', margin: '32px auto' }}
			onRowToggle={handleSelect}
		>
			<TableHeader>
				<TableRow>
					<TableColumn grow>Nimi</TableColumn>
					<TableColumn>Osoite</TableColumn>
				</TableRow>
			</TableHeader>
			<TableBody>
				{filteredManagerCompanies.map((company) => (
					<TableRow
						key={company.uuid}
						selected={selectedProcessCompanies[company.uuid]}
					>
						<TableColumn>{company.name}</TableColumn>
						<TableColumn>{getFullAddress(company)}</TableColumn>
					</TableRow>
				))}
			</TableBody>
		</DataTable>
	)
}

const mapState = () => ({})

const mapDispatchToProps = (dispatch) => ({})

export default compose(
	WithSelectedManagerCompanies({ hideLoading: false }),
	connect(mapState, mapDispatchToProps),
)(CompanyTable)
