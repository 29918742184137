import { useMemo, useState } from 'react'
import { injectIntl } from 'react-intl'
import {
	DataTable,
	FontIcon,
	TableBody,
	TableColumn,
	TableHeader,
	TableRow,
} from 'react-md'
import { compose } from 'redux'
import styled from 'styled-components'
import alpha2ToCountryName from 'util/alpha2ToCountryName'
import { getFullAddress, getLetters } from 'util/recipientsToLetters'
import stringToColour from 'util/stringToColor'
import naturalSort from 'javascript-natural-sort'
import cap from 'util/capitalizeFirstLetter'
import LetterStatusButton from './status-buttons/LetterStatusButton'
import LetterStatusDialog from './status-dialogs/LetterStatusDialog'
import EmailStatusButton from './status-buttons/EmailStatusButton'
import EmailStatusDialog from './status-dialogs/EmailStatusDialog'
import SMSStatusButton from './status-buttons/SMSStatusButton'
import SMSStatusDialog from './status-dialogs/SMSStatusDialog'

const byFullAddress = (a, b) => {
	const aFullAddress = getFullAddress(a)
	const bFullAddress = getFullAddress(b)

	return aFullAddress.localeCompare(bFullAddress, 'fi')
}

const joinIdentifiers = (invitee) =>
	[...invitee.apartments]
		.map((apt) => apt.identifier)
		.sort((a, b) => a.localeCompare(b, 'fi', { numeric: true }))
		.join(', ')

const byApartment = (a, b) => {
	const aIdentifiers = joinIdentifiers(a)
	const bIdentifiers = joinIdentifiers(b)

	return aIdentifiers.localeCompare(bIdentifiers, 'fi', { numeric: true })
}

const getConsentAllSources = (invitees) => {
	const consentSourceMap = {}
	invitees.forEach((invitee) => {
		if (invitee.digitalDeliveryConsents) {
			Object.keys(invitee.digitalDeliveryConsents).forEach((source) => {
				consentSourceMap[source] = true
			})
		}
	})
	return Object.keys(consentSourceMap)
}

const PostalAddressContainer = styled.div`
	display: flex;
	flex-direction: column;
	p {
		font-size: 11px;
		white-space: nowrap;
		margin-right: 4px;
	}
`

const filterColumns = (deliveryMethods) => {
	const columns = [
		'address',
		'displayName',
		'apartments',
		'email',
		'phoneNumber',
		'digitalDeliveryConsents',
		'letterStatus',
		'emailStatus',
		'smsStatus',
	]
	return columns.filter((column) => {
		if (column === 'email' || column === 'emailStatus') {
			return deliveryMethods.includes('email')
		}
		if (column === 'address' || column === 'letterStatus') {
			return deliveryMethods.includes('letter')
		}
		if (column === 'phoneNumber' || column === 'smsStatus') {
			return deliveryMethods.includes('sms')
		}
		return true
	})
}

function RecipientsStatusTable({
	intl,
	recipients = [],
	deliveryMethods = [],
	postalBatch,
	emailStatuses,
	smsStatuses,
}) {
	const columns = useMemo(
		() => filterColumns(deliveryMethods),
		[deliveryMethods],
	)
	const [sort, setSort] = useState({
		column: columns.find((c) => c === 'address') || 'apartments',
		ascending: true,
	})
	const [statusDialog, setStatusDialog] = useState({
		id: null,
		recipient: null,
	})

	const getSortedStatus = (column) => {
		return sort.column === column ? sort.ascending : undefined
	}

	const setSortedStatus = (column) => {
		const newSort = {
			column,
		}

		if (column === sort.column) {
			newSort.ascending = !sort.ascending
		} else {
			newSort.ascending = true
		}

		setSort(newSort)
	}

	const hideStatusDialog = () => {
		setStatusDialog({
			id: null,
			recipient: null,
		})
	}

	const renderStatusDialog = () => {
		switch (statusDialog.id) {
			case 'letter':
				return (
					<LetterStatusDialog
						visible={true}
						onHide={hideStatusDialog}
						recipient={statusDialog.recipient}
						postalBatch={postalBatch}
					/>
				)
			case 'email':
				return (
					<EmailStatusDialog
						visible={true}
						onHide={hideStatusDialog}
						recipient={statusDialog.recipient}
						emailStatus={emailStatuses[statusDialog.recipient.uuid]}
					/>
				)
			case 'sms':
				return (
					<SMSStatusDialog
						visible={true}
						onHide={hideStatusDialog}
						recipient={statusDialog.recipient}
						smsStatus={smsStatuses[statusDialog.recipient.uuid]}
					/>
				)
			default:
				return null
		}
	}

	const recipientsWithValidAddresses = recipients.filter(
		(r) => r.address && r.zip && r.area && r.country,
	)
	const [, addressMap] = getLetters(recipientsWithValidAddresses)
	const digitalDeliveryConsentSources =
		getConsentAllSources(recipients).sort(naturalSort)

	let sortFunc
	switch (sort.column) {
		case 'address':
			sortFunc = byFullAddress
			break
		case 'apartment':
			sortFunc = byApartment
			break
		default:
			sortFunc = byFullAddress
			break
	}
	let sortedRecipients = [...recipients].sort(sortFunc)
	if (!sort.ascending) {
		sortedRecipients.reverse()
	}

	const renderHeaderRow = () => {
		const headerColumns = {
			address: {
				content: intl.formatMessage({
					defaultMessage: 'Osoite',
					description:
						'Label for the meeting invitee table address column in the meeting invite dialog.',
				}),
				style: { cursor: 'pointer' },
				sorted: getSortedStatus('address'),
				onClick: () => setSortedStatus('address'),
			},
			displayName: {
				content: intl.formatMessage(
					{
						defaultMessage: 'Nimi ({rowCount} riviä)',
						description:
							'Label for the meeting invitee table name column in the meeting invite dialog.',
					},
					{ rowCount: recipients.length },
				),
			},
			apartments: {
				content: intl.formatMessage({
					defaultMessage: 'Huoneistot',
					description:
						'Label for the meeting invitee table apartment column in the meeting invite dialog.',
				}),
				style: { cursor: 'pointer' },
				sorted: getSortedStatus('apartments'),
				onClick: () => setSortedStatus('apartments'),
			},
			email: {
				content: intl.formatMessage({
					defaultMessage: 'Sähköpostiosoite',
					description:
						'Label for the meeting invitee table email column in the meeting invite dialog.',
				}),
			},
			phoneNumber: {
				content: intl.formatMessage({
					defaultMessage: 'Puhelinnumero',
					description:
						'Label for the meeting invitee table phone number column in the meeting invite dialog.',
				}),
			},
			digitalDeliveryConsents: {
				content: intl.formatMessage({
					defaultMessage: 'Sähköisen osakaspostituksen suostumus',
					description:
						'Label for the meeting invitee table electronic mailing agreement column in the meeting invite dialog.',
				}),
			},
			letterStatus: {
				content: intl.formatMessage({
					defaultMessage: 'Kirje',
					description:
						'Label for the meeting invitee table letter column in the meeting invite dialog.',
				}),
			},
			emailStatus: {
				content: intl.formatMessage({
					defaultMessage: 'Email',
					description:
						'Label for the meeting invitee table email column in the meeting invite dialog.',
				}),
			},
			smsStatus: {
				content: intl.formatMessage({
					defaultMessage: 'SMS',
					description:
						'Label for the meeting invitee table sms column in the meeting invite dialog.',
				}),
			},
		}
		return (
			<TableRow>
				{columns.map((c) => {
					const headerColumn = headerColumns[c]
					return (
						<TableColumn
							{...headerColumn}
							key={c}
							style={{ ...headerColumn.style }}
						>
							{headerColumn.content}
						</TableColumn>
					)
				})}
			</TableRow>
		)
	}

	const renderBodyRow = (r) => {
		const bodyColumns = {
			address: {
				content: (
					<PostalAddressContainer
						style={
							addressMap[getFullAddress(r)]?.length > 1
								? {
										background:
											'linear-gradient(90deg, ' +
											stringToColour(getFullAddress(r)) +
											'44 0%, rgba(0,0,0,0) 100%)',
										paddingLeft: 20,
										marginLeft: -24,
										borderLeftWidth: 4,
										borderLeftStyle: 'solid',
										borderLeftColor: stringToColour(getFullAddress(r)),
								  }
								: undefined
						}
					>
						{r.address ? (
							<p>{r.address}</p>
						) : (
							<p style={{ color: 'red' }}>
								{intl.formatMessage({
									defaultMessage: 'Katuosoite',
									description:
										'Placeholder for the meeting invitee table address value in the meeting invite dialog.',
								})}
							</p>
						)}
						<div className="flex-row">
							{r.zip ? (
								<p>{r.zip}</p>
							) : (
								<p style={{ color: 'red' }}>
									{intl.formatMessage({
										defaultMessage: 'Postinumero',
										description:
											'Placeholder for the meeting invitee table zip code value in the meeting invite dialog.',
									})}
								</p>
							)}
							{r.area ? (
								<p>{r.area.toUpperCase()}</p>
							) : (
								<p style={{ color: 'red' }}>
									{intl.formatMessage({
										defaultMessage: 'Postitoimipaikka',
										description:
											'Placeholder for the meeting invitee table area value in the meeting invite dialog.',
									})}
								</p>
							)}
						</div>
						{r.country && alpha2ToCountryName(r.country) ? (
							<p>{alpha2ToCountryName(r.country)}</p>
						) : (
							<p style={{ color: 'red' }}>
								{intl.formatMessage({
									defaultMessage: 'Maakoodi',
									description:
										'Placeholder for the meeting invitee table country code value in the meeting invite dialog.',
								})}
							</p>
						)}
					</PostalAddressContainer>
				),
			},
			displayName: {
				content: r.displayName || '-',
			},
			apartments: {
				content: r.apartments && r.apartments.length ? joinIdentifiers(r) : '-',
			},
			email: {
				content: r.email || '-',
			},
			phoneNumber: {
				content: r.phoneNumber || '-',
			},
			digitalDeliveryConsents: {
				content: (
					<div className="flex-row">
						{digitalDeliveryConsentSources.map((source) => {
							const cappedSource = cap(source)
							const hasConsent =
								r.digitalDeliveryConsents && r.digitalDeliveryConsents[source]
							return (
								<div key={source} className="flex-row flex-center">
									<FontIcon
										style={{
											color: hasConsent ? 'green' : '#DD0000',
											marginRight: 8,
										}}
									>
										{hasConsent ? 'check_circle' : 'do_not_disturb_on'}
									</FontIcon>
									<p style={{ marginRight: 32 }}>{cappedSource}</p>
								</div>
							)
						})}
					</div>
				),
			},
			letterStatus: {
				content: (
					<LetterStatusButton
						recipient={r}
						postalBatch={postalBatch}
						onClick={() => {
							setStatusDialog({
								id: 'letter',
								recipient: r,
							})
						}}
					/>
				),
			},
			emailStatus: {
				content: (
					<EmailStatusButton
						recipient={r}
						emailStatus={emailStatuses[r.uuid]}
						onClick={() => {
							setStatusDialog({
								id: 'email',
								recipient: r,
							})
						}}
					/>
				),
			},
			smsStatus: {
				content: (
					<SMSStatusButton
						recipient={r}
						smsStatus={smsStatuses[r.uuid]}
						onClick={() => {
							setStatusDialog({
								id: 'sms',
								recipient: r,
							})
						}}
					/>
				),
			},
		}
		return (
			<TableRow key={r.uuid}>
				{columns.map((c) => {
					const bodyColumn = bodyColumns[c]
					return (
						<TableColumn
							{...bodyColumn}
							key={c}
							style={{ ...bodyColumn.style }}
						>
							{bodyColumn.content}
						</TableColumn>
					)
				})}
			</TableRow>
		)
	}

	return (
		<>
			<DataTable plain style={{ flex: 1 }}>
				<TableHeader>{renderHeaderRow()}</TableHeader>
				<TableBody>{sortedRecipients.map((r) => renderBodyRow(r))}</TableBody>
			</DataTable>

			{renderStatusDialog()}
		</>
	)
}

export default compose(injectIntl)(RecipientsStatusTable)
