import { useCallback, useEffect, useState } from 'react'
import type { BankAccount } from './types'
import { _getBankAccountByUUID } from 'state/settings-actions'

function useBankAccount(uuid: string | undefined | null) {
	const [bankAccount, setBankAccount] = useState<BankAccount>()

	const [loadingBankAccount, setLoading] = useState<boolean>(false)
	const [errorBankAccount, setError] = useState<string | null>(null)

	const doQuery = useCallback(async () => {
		if (!uuid) return
		setLoading(true)
		setError(null)
		let data: BankAccount | undefined

		try {
			const response = await _getBankAccountByUUID(uuid)

			if (response && response.ok) {
				const res = await response.json()
				data = res.bankAccount
			} else {
				throw new Error('Response is not ok.')
			}
		} catch (err) {
			console.error('Error fetching bank account:', err)
			setError('An error occurred while fetching bank account: ' + uuid)
		}
		setBankAccount(data)
		setLoading(false)
	}, [uuid])

	useEffect(() => {
		doQuery()
	}, [doQuery])

	return {
		bankAccount,
		loadingBankAccount,
		errorBankAccount,
		refreshBankAccount: doQuery,
	}
}

export default useBankAccount
