import { useState } from 'react'
import { Button, Toolbar } from 'react-md'
import styled from 'styled-components'
import Tabs from './Tabs'
import DeliveryMethods from './Tabs/DeliveryMethods'
import Message from './Tabs/Message'
import Recipients from './Tabs/Recipients'
import Summary from './Tabs/Summary'
import ViiluDialog from 'ui/components/ViiluDialog'

const DialogContant = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
	overflow: hidden;
`

export const TABS = [
	{
		label: 'Viesti',
		component: Message,
	},
	{
		label: 'Lähetystavat',
		component: DeliveryMethods,
	},
	{
		label: 'Vastaanottajat',
		component: Recipients,
	},
	{
		label: 'Yhteenveto',
		component: Summary,
	},
]

function DetailsDialog({ visible, messageServiceProcess, onHide }) {
	const [activeTabIndex, setActiveTabIndex] = useState(0)

	if (!messageServiceProcess) {
		return null
	}

	const renderTab = () => {
		const Tab = TABS[activeTabIndex].component
		return <Tab messageServiceProcess={messageServiceProcess} />
	}

	return (
		<ViiluDialog
			id="message-service-status-dialog"
			visible={visible}
			fullPage
			onHide={onHide}
			focusOnMount={false}
		>
			<DialogContant>
				<Toolbar
					title={'Lähetyksen tiedot'}
					colored
					style={{
						boxShadow: 'none',
						background: 'var(--color-secondary-dark)',
					}}
					nav={
						<Button icon onClick={onHide}>
							close
						</Button>
					}
					actions={
						<Tabs
							activeTabIndex={activeTabIndex}
							setActiveTabIndex={setActiveTabIndex}
							tabs={TABS}
						/>
					}
				/>
				{renderTab()}
			</DialogContant>
		</ViiluDialog>
	)
}

export default DetailsDialog
