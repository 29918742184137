import { useIntl } from 'react-intl'
import styled from 'styled-components'
import DropdownField from 'ui/components/DropdownField'
import type { LoanFilters } from './types'
import { loanStatusMessage } from './messages'
import { LoanStatus } from './enum'

const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 16px;
	margin-bottom: 16px;
`

const Filter = styled.div`
	min-width: 160px;
`

type Props = {
	filters: LoanFilters
	setFilters: React.Dispatch<React.SetStateAction<LoanFilters>>
}

function Filters({ filters, setFilters }: Props) {
	const intl = useIntl()

	const loanStatusOptions = [
		{ label: loanStatusMessage(intl, LoanStatus.DRAFT), value: 'draft' },
		{ label: loanStatusMessage(intl, LoanStatus.ACTIVE), value: 'active' },
		{ label: loanStatusMessage(intl, LoanStatus.ENDED), value: 'ended' },
	]

	return (
		<Container>
			<Filter>
				<DropdownField
					label={intl.formatMessage({
						defaultMessage: 'Tila',
						description: 'Label for loan active status.',
					})}
					itemLabel={'label'}
					itemValue={'value'}
					options={loanStatusOptions}
					hasSelectAll={true}
					nullSelectAll={true}
					value={filters.status}
					_onOptionSelect={(value) => {
						setFilters((prev) => ({ ...prev, status: value }))
					}}
				/>
			</Filter>
		</Container>
	)
}

export default Filters
