import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import styled from 'styled-components'
import { FormattedMessage, useIntl } from 'react-intl'
import Button from 'react-md/lib/Buttons'
import { loanDetailRows } from './loanDetailRows'
import { isValidBillingBasis } from '../utils'
import { Tab, Tabs } from 'react-md/lib/Tabs'
import { useState } from 'react'
import LoanEventsTable from '../LoanCreateDialog/LoanEventsTable'
import Chip from 'react-md/lib/Chips'
import Avatar from 'react-md/lib/Avatars'
import FontIcon from 'react-md/lib/FontIcons'
import DropdownField from 'ui/components/DropdownField'
import DatePickerISO from 'ui/components/DatePickerISO'
import useLoan from '../useLoan'
import LoanShareGroupsTable from './LoanShareholdersTable'
import {
	AsideContentContainer,
	ContentContainer,
	LoanDetailContainer,
	LoanDetailRow,
	MainContentContainer,
	TabContentContainer,
	TabsContainer,
} from '../components'
import ViiluFullPageDialog from 'ui/components/ViiluFullPageDialog'
import { ExpansionList, ExpansionPanel } from 'react-md'
import type { Loan, LoanDetailRowData } from '../types'
import { formatCurrency } from 'util/formatStrings'
import { billingBasisMessage } from 'ui/ManagerFinance/utils/messages'
import moment from 'moment'
import { LoanStatus } from '../enum'

const ChipContainer = styled.div`
	display: flex;
	flex-flow: row nowrap;
	gap: 1rem;
	margin-bottom: 1.5rem;
`

const LoanEventFilterContainer = styled.div`
	display: flex;
	flex-flow: row nowrap;
	gap: 1rem;
	margin-bottom: 1rem;
`

const LoanEventFilterItemContainer = styled.div`
	display: flex;
	flex-flow: column nowrap;
	gap: 0.25rem;
`

const LoanDetailRowHeader = styled.div`
	display: flex;
`

const FieldRowRight = styled.div`
	text-align: right;
`

const DayMonthExpansionPanel = styled(ExpansionPanel)`
	margin: 0.25rem 0 !important;
	padding: 0.5rem;

	.md-panel-content {
		margin-top: 0.5rem;
		padding: 0.5rem;
		padding-bottom: 0.5rem;
	}

	.md-panel-header--expanded {
		height: 0;
	}
`

const DayMonthGrid = styled.div`
	border: rgba(0, 0, 0, 0.2) solid 1px;
`

type LoanViewDialogProps = {
	loanUUID: string
}

function LoanViewDialog(props: LoanViewDialogProps) {
	const { loanUUID } = props
	const dispatch = useDispatch()
	const intl = useIntl()
	const { loan } = useLoan(loanUUID)
	const [tabIndex, setTabIndex] = useState<0 | 1>(0)
	const [datesDropdownOpen, setDatesDropdownOpen] = useState<string[]>([])
	const [eventStartDate, setEventStartDate] = useState<string | null>(null)
	const [eventEndDate, setEventEndDate] = useState<string | null>(null)
	const [eventTypeFilter, setEventTypeFilter] = useState<string | null>(null)

	const onHide = () => {
		dispatch(push('/manager/finance/loans'))
	}

	const getTabs = () => {
		return [
			<Tab
				key={1}
				style={
					tabIndex === 0
						? {
								color: 'var(--color-primary-dark)',
								fontWeight: '700',
						  }
						: {
								color: 'var(--color-text-subtle)',
						  }
				}
				label={intl.formatMessage({
					defaultMessage: 'Tapahtumat',
					description: 'Title of events tab',
				})}
				onClick={() => setTabIndex(0)}
			/>,
			<Tab
				key={2}
				style={
					tabIndex === 1
						? {
								color: 'var(--color-primary-dark)',
								fontWeight: '700',
						  }
						: {
								color: 'var(--color-text-subtle)',
						  }
				}
				label={intl.formatMessage({
					defaultMessage: 'Lainaosuudet',
					description: 'Title of loan share groups',
				})}
				onClick={() => setTabIndex(1)}
			/>,
		]
	}

	const getLoanDrawStatusChip = () => {
		if (loan && loan?.loanDrawnAmount >= loan?.originalLoanAmount) {
			return (
				<Chip
					label={intl.formatMessage({
						defaultMessage: 'Nostettu',
						description: 'Chip label when loan has been drawn',
					})}
					avatar={
						<Avatar
							icon={<FontIcon key="check">done</FontIcon>}
							suffix="orange"
						/>
					}
				/>
			)
		}
		return (
			<Chip
				label={intl.formatMessage({
					defaultMessage: 'Ei nostettu',
					description: 'Chip label when loan has NOT been drawn',
				})}
				avatar={
					<Avatar icon={<FontIcon key="check">done</FontIcon>} suffix="red" />
				}
			/>
		)
	}

	const getLoanActivityStatusChip = () => {
		if (loan && loan.status === LoanStatus.ACTIVE) {
			return (
				<Chip
					label={intl.formatMessage({
						defaultMessage: 'Aktiivinen',
						description: 'Chip label when loan is active',
					})}
					avatar={
						<Avatar
							icon={<FontIcon key="check">done</FontIcon>}
							suffix="green"
						/>
					}
				/>
			)
		}
		return (
			<Chip
				label={intl.formatMessage({
					defaultMessage: 'Ei aktiivinen',
					description: 'Chip label when loan is not active',
				})}
				avatar={
					<Avatar icon={<FontIcon key="check">done</FontIcon>} suffix="red" />
				}
			/>
		)
	}

	const renderDayMonthField = (row: LoanDetailRowData) => {
		if (
			loan &&
			(row.key === 'interestInspectionDates' ||
				row.key === 'loanSharePaymentDates' ||
				row.key === 'amortizationDates')
		) {
			const dayMonthField = loan[row.key]
			if (!dayMonthField || (dayMonthField && dayMonthField.length === 0))
				return <FieldRowRight>-</FieldRowRight>

			const sortedDayMonths = dayMonthField.sort((a, b) => {
				if (a.month !== b.month) {
					return a.month - b.month
				}
				return a.day - b.day
			})
			return (
				<ExpansionList>
					<DayMonthExpansionPanel
						component="div"
						label={intl.formatMessage({
							defaultMessage: 'Päivämäärät',
							description: 'Dates',
						})}
						headerStyle={{ marginTop: '0', padding: '0', height: 'auto' }}
						onClick={() => {
							setDatesDropdownOpen((prevState) =>
								prevState.includes(row.key)
									? prevState.filter((k) => k !== row.key)
									: [...prevState, row.key],
							)
						}}
						expanded={datesDropdownOpen.includes(row.key)}
						footer={null}
					>
						<DayMonthGrid>
							<LoanDetailRow $filledBackground={false}>
								<div>
									<FormattedMessage defaultMessage="Päivä" description="Day" />
								</div>
								<div>
									<FormattedMessage
										defaultMessage="Kuukausi"
										description="Month"
									/>
								</div>
							</LoanDetailRow>
							{sortedDayMonths.map((dayMonthValue, index) => {
								return (
									<LoanDetailRow $filledBackground={index % 2 === 0}>
										<div>{dayMonthValue.day}</div>
										<div>{dayMonthValue.month}</div>
									</LoanDetailRow>
								)
							})}
						</DayMonthGrid>
					</DayMonthExpansionPanel>
				</ExpansionList>
			)
		}
		return null
	}

	const getDetailRowValue = (row: LoanDetailRowData, loan: Loan) => {
		const value = loan[row.key]

		if (row.key === 'billingBasis' && isValidBillingBasis(value)) {
			return <FieldRowRight>{billingBasisMessage(intl, value)}</FieldRowRight>
		}

		switch (row.type) {
			case 'boolean':
				if (typeof value === 'boolean') {
					return (
						<FieldRowRight>
							{value
								? intl.formatMessage({
										defaultMessage: 'Kyllä',
										description: 'Yes',
								  })
								: intl.formatMessage({
										defaultMessage: 'Ei',
										description: 'No',
								  })}
						</FieldRowRight>
					)
				}
				break

			case 'day-month-list':
				return renderDayMonthField(row)

			case 'currency':
				if (typeof value === 'number') {
					return <FieldRowRight>{formatCurrency(value)}</FieldRowRight>
				}
				break

			case 'percentage':
				if (typeof value === 'number') {
					return <FieldRowRight>{`${value}%`}</FieldRowRight>
				}
				break

			case 'date':
				if (typeof value === 'string') {
					return (
						<FieldRowRight>{moment(value).format('D.M.YYYY')}</FieldRowRight>
					)
				}
				break
			default:
				return <FieldRowRight>{value?.toString() || '-'}</FieldRowRight>
		}
	}

	const renderDetailRows = () => {
		if (!loan) return
		return (
			<>
				{loanDetailRows.map((row, index) => {
					if (row.showOnCondition && !row.showOnCondition(loan)) {
						return null
					}

					const detailRowValue = getDetailRowValue(row, loan)

					return (
						<LoanDetailRow key={row.key} $filledBackground={index % 2 === 0}>
							<LoanDetailRowHeader>{row.title(intl)}</LoanDetailRowHeader>
							{detailRowValue}
						</LoanDetailRow>
					)
				})}
			</>
		)
	}

	const renderAsideElement = () => {
		return (
			<AsideContentContainer>
				<ChipContainer>
					{getLoanDrawStatusChip()}
					{getLoanActivityStatusChip()}
				</ChipContainer>
				<LoanDetailContainer>{renderDetailRows()}</LoanDetailContainer>
			</AsideContentContainer>
		)
	}

	const renderLoanEventFilters = () => {
		return (
			<LoanEventFilterContainer>
				<LoanEventFilterItemContainer>
					<DatePickerISO
						id="payments-payment-date-filter"
						label={intl.formatMessage({
							defaultMessage: 'Alkupäivä',
							description: 'Title of select field: start date',
						})}
						value={eventStartDate || ''}
						onChange={(isoDate: string) => setEventStartDate(isoDate)}
					/>
				</LoanEventFilterItemContainer>
				<LoanEventFilterItemContainer>
					<DatePickerISO
						id="payments-payment-date-filter"
						label={intl.formatMessage({
							defaultMessage: 'Loppupäivä',
							description: 'Title of select field: end date',
						})}
						value={eventEndDate || ''}
						onChange={(isoDate: string) => setEventEndDate(isoDate)}
					/>
				</LoanEventFilterItemContainer>
				<LoanEventFilterItemContainer>
					<DropdownField
						label={intl.formatMessage({
							defaultMessage: 'Tyyppi',
							description: 'Label for the loan event type filter',
						})}
						itemLabel={'label'}
						itemValue={'value'}
						options={[]}
						hasSelectAll={true}
						nullSelectAll={true}
						value={eventTypeFilter}
						_onOptionSelect={(value) => setEventTypeFilter(value)}
					/>
				</LoanEventFilterItemContainer>
			</LoanEventFilterContainer>
		)
	}

	const renderMainElement = () => {
		return (
			<MainContentContainer>
				<TabsContainer>
					<Tabs
						tabId="loans-mobile-tab"
						mobile
						style={{
							flexShrink: 0,
							borderBottomStyle: 'solid',
							borderBottomColor: 'rgba(0,0,0,0.2)',
							borderBottomWidth: 1,
							marginLeft: '1rem',
							borderBottom: 'none',
						}}
						alignToKeyline={false}
						defaultTabIndex={tabIndex}
						indicatorHeight={3}
					>
						{getTabs()}
					</Tabs>
				</TabsContainer>
				{tabIndex === 0 && (
					<TabContentContainer>
						{loan?.loanEvents && (
							<>
								{renderLoanEventFilters()}
								<LoanEventsTable events={loan.loanEvents} />
							</>
						)}
					</TabContentContainer>
				)}
				{tabIndex === 1 && (
					<TabContentContainer>
						{loan?.shareholders && (
							<LoanShareGroupsTable shareholders={loan?.shareholders} />
						)}
					</TabContentContainer>
				)}
			</MainContentContainer>
		)
	}

	return (
		<ViiluFullPageDialog
			id="loan-view-dialog"
			title={
				loan?.name ||
				intl.formatMessage({
					defaultMessage: 'Lainan tiedot',
					description:
						'Placeholder title for loan view dialog when the loan name is not found',
				})
			}
			visible={true}
			zIndex={20}
			onHide={onHide}
			focusOnMount={false}
			containFocus={true}
			toolbarStyle={{ background: 'var(--color-secondary-dark)' }}
			nav={
				<Button
					icon
					onClick={() => {
						onHide()
					}}
				>
					close
				</Button>
			}
		>
			<ContentContainer>
				{renderAsideElement()}
				{renderMainElement()}
			</ContentContainer>
		</ViiluFullPageDialog>
	)
}

export default LoanViewDialog
