import moment from 'moment'
import { IntlShape } from 'react-intl'
import type { ApartmentSelectOptions } from '../types'
import { bondNameMessage } from '../utils/messages'
import type { Party } from './types'

export const getPartyRowValue = (
	party: Party,
	key: string,
	apartmentOptions: ApartmentSelectOptions[],
	intl: IntlShape,
	type?: string,
) => {
	const value = party[key]
	switch (type) {
		case 'date':
			const parsedDate = moment(value)
			if (!parsedDate.isValid()) return ''
			return parsedDate.format('DD.MM.YYYY')

		case 'bondName':
			if (party.bonds && party.bonds.length > 0) {
				return party.bonds
					.map((bond) => bondNameMessage(intl, bond.name))
					.join(', ')
			} else return ''

		case 'apartmentOptions':
			return (
				apartmentOptions.find((a) => a.value === value)?.label ||
				party.apartmentIdentifier ||
				'-'
			)
		default:
			return value
	}
}
