import { FontIcon } from 'react-md'
import styled from 'styled-components'
import RouterLink from 'ui/components/RouterLink'
import { APP_LOGIN_URL } from '../index'
import NavLogo from '../NavLogo'
import ExpandableNavItem from './ExpandableNavItem'

const Container = styled.div`
	position: fixed;
	z-index: 100;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: var(--color-secondary);
	overflow: auto;
	display: flex;
	flex-direction: column;
`

const Header = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-left: 16px;
	background: var(--color-secondary);
`

const CloseButton = styled.button`
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 16px;
	background: transparent;
	border: none;

	.md-icon {
		color: #fff;
		font-size: 32px;
	}
`

const Nav = styled.nav`
	flex: 1;
	margin-top: 100px;
	display: flex;
	flex-direction: column;

	a {
		color: #fff;
	}
`

const LoginLink = styled(RouterLink)`
	text-decoration: none;
	font-size: 30px;
	font-weight: bold;
	padding: 15px 20px;
	margin-top: auto;
`

function MobileNav({ navItems, close }) {
	return (
		<Container>
			<Header>
				<NavLogo fill="#fff" afterOnClick={close} />
				<CloseButton onClick={close}>
					<FontIcon>close</FontIcon>
				</CloseButton>
			</Header>

			<Nav>
				{navItems.map((item) => (
					<ExpandableNavItem
						key={item.label}
						item={item}
						afterOnClick={close}
					/>
				))}
				<LoginLink href={APP_LOGIN_URL}>Kirjaudu</LoginLink>
			</Nav>
		</Container>
	)
}

export default MobileNav
