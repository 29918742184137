import React, { Component } from 'react'
import PropTypes from 'prop-types'
import LoadingState from '../LoadingState'
import ImageUploadAdapterPlugin from './ImageUploadAdapterPlugin'
import WithSelectedCompanyUUID from '../WithSelectedCompanyUUID'
import { compose } from 'redux'

let Editor, CKEditor, viewToPlainText
const init = async () => {
	let EditorModule = await import('ckeditor5-custom-build/build/ckeditor')
	Editor = EditorModule.default
	let CKReact = await import('@ckeditor/ckeditor5-react')
	CKEditor = CKReact.CKEditor
	viewToPlainText = (
		await import('@ckeditor/ckeditor5-clipboard/src/utils/viewtoplaintext')
	).default
}
init()

const editorConfiguration = {
	toolbar: [
		'heading',
		'bold',
		'italic',
		'bulletedList',
		'numberedList',
		'imageInsert',
		'link',
		'|',
		'undo',
		'redo',
	],
	heading: {
		options: [
			{ model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
			{
				model: 'heading1',
				view: 'h1',
				title: 'Heading 1',
				class: 'ck-heading_heading1',
			},
			{
				model: 'heading2',
				view: 'h2',
				title: 'Heading 2',
				class: 'ck-heading_heading2',
			},
			{
				model: 'heading3',
				view: 'h3',
				title: 'Heading 3',
				class: 'ck-heading_heading3',
			},
		],
	},
}

class WYSIWYGEditor extends Component {
	onChange = (value) => {
		const { onChange } = this.props

		onChange(value)
	}

	onChangePlainText = (editor) => {
		const { onChangePlainText } = this.props

		if (!onChangePlainText) {
			return
		}

		// A bit hacky but without the timeout the value is missing the last inputted character.
		setTimeout(() => {
			const viewRoot = editor.editing.view.document.getRoot()
			const plainText = viewToPlainText(viewRoot)
			onChangePlainText(plainText)
		}, 300)
	}

	render() {
		const {
			value,
			style,
			selectedCompanyUUID,
			htmlOutput,
			disabled,
			extraConfig,
			maxHeight,
			disableImageDropUpload,
			onReadyInnerHtml,
		} = this.props

		if (!CKEditor || !Editor || !viewToPlainText) {
			return <LoadingState />
		}

		const plugins = []
		// removing 'imageInsert' from the toolbar removes the UI button for image upload;
		// this setting disables upload via drag-n-drop
		if (!disableImageDropUpload) {
			plugins.push(ImageUploadAdapterPlugin(selectedCompanyUUID))
		}

		return (
			<div style={style}>
				<CKEditor
					editor={Editor}
					config={{
						...editorConfiguration,
						removePlugins: htmlOutput ? ['Markdown'] : [],
						extraPlugins: plugins,
						...extraConfig,
					}}
					data={value || ''}
					onChange={(event, editor) => {
						const data = editor.getData()
						this.onChange(data, '')
						this.onChangePlainText(editor)
					}}
					disabled={disabled}
					onReady={(editor) => {
						if (maxHeight) {
							editor.editing.view.change((writer) => {
								writer.setStyle(
									'max-height',
									`${maxHeight}px`,
									editor.editing.view.document.getRoot(),
								)
							})
						}
						if (onReadyInnerHtml) {
							const styleElement = document.createElement('style')
							styleElement.innerHTML = onReadyInnerHtml
							document.head.appendChild(styleElement)
						}
					}}
				/>
			</div>
		)
	}
}

WYSIWYGEditor.propTypes = {
	onChange: PropTypes.func.isRequired,
	value: PropTypes.string,
	style: PropTypes.object,
	htmlOutput: PropTypes.bool,
	extraConfig: PropTypes.object,
	onReadyInnerHtml: PropTypes.string,
}

export default compose(WithSelectedCompanyUUID)(WYSIWYGEditor)
