import { useState } from 'react'
import { Tabs as MdTabs, Tab as MdTab } from 'react-md'
import styled from 'styled-components'
import Bulletins from './Bulletins'
import Infoscreen from './Infoscreen'
import Personal from './Personal'
import Integrations from './Integrations/Integrations'

const Tabs = styled(MdTabs)``

const Tab = styled(MdTab)`
	.md-tab-label {
		color: ${(props) =>
			props.active ? 'var(--color-primary-dark)' : 'var(--color-text-subtle)'};
		font-weight: ${(props) => (props.active ? 'bold' : 'normal')};
	}
`

const initTabs = (deliveryMethods, deliveryMethodsIntegrations) => {
	const tabs = []

	if (
		deliveryMethods.some((m) => m === 'email' || m === 'letter' || m === 'sms')
	) {
		tabs.push({
			id: 'personal',
			label: 'Henkilökohtainen',
			component: Personal,
		})
	}

	if (deliveryMethods.includes('bulletins')) {
		tabs.push({
			id: 'bulletins',
			label: 'Ilmoitustaulu',
			component: Bulletins,
		})
	}

	if (deliveryMethods.includes('infoscreen')) {
		tabs.push({
			id: 'infoscreen',
			label: 'Porrasnäyttö',
			component: Infoscreen,
		})
	}

	if (deliveryMethodsIntegrations?.length > 0) {
		tabs.push({
			id: 'integrations',
			label: 'Integraatiot',
			component: Integrations,
		})
	}

	return tabs
}

function Recipients({ messageServiceProcess }) {
	const tabs = initTabs(
		messageServiceProcess.deliveryMethods,
		messageServiceProcess.deliveryMethodsIntegrations,
	)
	const [activeTabIndex, setActiveTabIndex] = useState(0)

	const renderTab = () => {
		const tab = tabs[activeTabIndex]
		if (!tab) {
			return null
		}
		const TabComponent = tab.component
		return <TabComponent messageServiceProcess={messageServiceProcess} />
	}

	return (
		<>
			<Tabs
				tabId="message-service-delivery-methods-tabs"
				activeTabIndex={activeTabIndex}
				onTabChange={(tabIndex) => {
					setActiveTabIndex(tabIndex)
				}}
			>
				{tabs.map((tab, index) => (
					<Tab
						key={tab.id}
						label={tab.label}
						active={activeTabIndex === index}
					/>
				))}
			</Tabs>

			{renderTab()}
		</>
	)
}

export default Recipients
