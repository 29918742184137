import { FontIcon } from 'react-md'
import { connect } from 'react-redux'
import { compose } from 'redux'
import styled, { css } from 'styled-components'

const Container = styled.div`
	height: 100%;
	display: flex;
	align-items: center;
`

const Steps = styled.div`
	padding: 8px 16px;
	border-radius: 8px;
	display: flex;
	align-items: center;
`

const StepItem = styled.div`
	display: flex;
	align-items: center;
	margin: 0 ${({ isDesktop }) => (isDesktop ? '16px' : '2px')};

	.number {
		width: 24px;
		height: 24px;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: ${({ isDesktop }) => (isDesktop ? '16px' : '2px')};
		color: #fff;
		font-size: 14px;
	}
	.label {
		font-size: 16px;
	}

	${(props) => props.styleForStatus}
`

const getStyleForStatus = (index, activeStepIndex, reachedStepIndex) => {
	const isActive = index === activeStepIndex
	const isCompleted = index < reachedStepIndex
	const isReached = index === reachedStepIndex
	const isDisabled = index > reachedStepIndex

	if (isActive) {
		return css`
			font-weight: bold;
			.number {
				background-color: ${isCompleted ? 'green' : 'white'};
				color: ${isCompleted ? 'white' : 'black'};
			}
			.label {
				text-decoration: underline;
				text-underline-offset: 8px;
			}
		`
	}

	if (isCompleted) {
		return css`
			cursor: pointer;
			.number {
				background-color: green;
			}
		`
	}

	if (isReached) {
		return css`
			cursor: pointer;
			.number {
				background-color: #929292;
			}
			.label {
				color: lightgray;
			}
		`
	}

	if (isDisabled) {
		return css`
			opacity: 0.3;
			.number {
				background-color: white;
				color: gray;
			}
			.label {
			}
		`
	}
}

function Stepper({
	steps,
	goToStep,
	activeStepIndex,
	reachedStepIndex,
	isDesktop,
}) {
	return (
		<Container>
			<Steps>
				{steps.map((step, index) => (
					<StepItem
						key={step.id}
						styleForStatus={getStyleForStatus(
							index,
							activeStepIndex,
							reachedStepIndex,
						)}
						onClick={() => {
							if (index === activeStepIndex || index > reachedStepIndex) {
								return
							}
							goToStep({
								id: step.id,
								childId: '',
							})
						}}
						isDesktop={isDesktop}
					>
						<span className="number">
							{index < reachedStepIndex ? <FontIcon>done</FontIcon> : index + 1}
						</span>
						{isDesktop ? <span className="label">{step.label}</span> : null}
					</StepItem>
				))}
			</Steps>
		</Container>
	)
}

const mapState = ({ dimensions: { isDesktop } }) => ({
	isDesktop,
})

export default compose(connect(mapState))(Stepper)
