import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'

import { StyledFontIcon, BaseButton } from './styles'

const StyledButton = styled(BaseButton)`
	margin-top: 32px;
	color: rgba(0, 0, 0, 0.6);
	font-size: 13px;
`

const BackButton = ({ onClick }) => {
	return (
		<StyledButton
			flat
			onClick={onClick}
			iconEl={<StyledFontIcon>arrow_back</StyledFontIcon>}
		>
			<FormattedMessage
				defaultMessage="Takaisin"
				description="Button that goes backwards in the login flow."
			/>
		</StyledButton>
	)
}

export default BackButton
