import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { AutoSizer } from 'react-virtualized'
import { Button, CircularProgress, FontIcon } from 'react-md'
import styled from 'styled-components'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'

import LoadingState from '../LoadingState'
import { FormattedMessage } from 'react-intl'

const TipContainer1 = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	background: white;
	padding-top: 0;
	padding-bottom: 0;
	padding-left: 0;
	padding-right: 0;
	border-top-right-radius: 4px;
	border-top-left-radius: 4px;
	height: 32px;
	cursor: default;
	margin-left: 16px;
	transform: translateY(2px);
	box-shadow: -1px -1px 2px rgba(0, 0, 0, 0.15),
		1px -1px 2px rgba(0, 0, 0, 0.15);
	z-index: 11;
	p {
		white-space: nowrap;
	}
`
const TipContainer2 = styled.div`
	z-index: 11;
	p {
		white-space: nowrap;
	}
`
const PageContainer = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	padding: 0;
	box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.33);
	${(props) =>
		props.refreshing
			? `
		border-color: var(--color-secondary);
		border-style: solid;
		border-width: 2px;
		border-radius: 4px;
	`
			: ''}
`
const Overlay = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: rgba(0, 0, 0, 0.1);
	z-index: 10;
`

let Document
let Page

class PDFPreview extends PureComponent {
	state = {
		processing: false,
		numPages: null,
		pageNumber: 1,
		reactPdfInitialized: false,
	}

	componentDidMount() {
		const init = async () => {
			const ReactPDF = await import('react-pdf')
			const pdfjsWorker = await import('pdfjs-dist/build/pdf.worker.entry')
			ReactPDF.pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker
			Document = ReactPDF.Document
			Page = ReactPDF.Page
			this.setState({ reactPdfInitialized: true })
		}
		init()
	}

	nextPage = () => {
		const { numPages, pageNumber } = this.state
		if (pageNumber === numPages) {
			return
		}
		this.setState({ pageNumber: pageNumber + 1 })
	}

	previousPage = () => {
		const { pageNumber } = this.state
		if (pageNumber === 1) {
			return
		}
		this.setState({ pageNumber: pageNumber - 1 })
	}

	download = async () => {
		const { fileURL } = this.props
		if (fileURL) {
			window.open(fileURL, '_blank')
			return true
		} else {
			console.error('error getting the fileURL for the pdf')
		}
	}

	onDocumentLoadSuccess = ({ numPages }) => {
		const { pageNumber } = this.state
		const { getPageCount } = this.props
		this.setState({
			numPages,
			pageNumber: pageNumber <= numPages ? pageNumber : 1,
		})
		if (getPageCount) {
			getPageCount(numPages)
		}
	}

	renderLoading = (width) => {
		return (
			<div
				style={{
					position: 'absolute',
					width,
					height: 1.414 * width,
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.33)',
					zIndex: 9,
				}}
			>
				<CircularProgress id="progress" style={{ marginTop: 64 }} />
			</div>
		)
	}

	renderPDF = () => {
		const { pageNumber, reactPdfInitialized } = this.state
		const { fileURL } = this.props

		if (!reactPdfInitialized) {
			return <LoadingState />
		}

		return (
			<AutoSizer disableHeight>
				{({ width }) => (
					<Document
						file={fileURL}
						onLoadSuccess={this.onDocumentLoadSuccess}
						loading={this.renderLoading(width)}
						noData={this.renderLoading(width)}
						error={
							<div
								style={{
									minWidth: 'calc(100vw - 64px)',
									marginLeft: 'auto',
									marginRight: 'auto',
									padding: 16,
								}}
							>
								<p>PDF-tiedostoa ei voitu ladata virheen vuoksi.</p>
							</div>
						}
					>
						<Page
							pageNumber={pageNumber}
							width={width}
							loading={this.renderLoading(width)}
							noData={this.renderLoading(width)}
						/>
					</Document>
				)}
			</AutoSizer>
		)
	}

	render() {
		const { pageNumber, numPages } = this.state
		const { refreshing, fileURL, hideDownload, isDesktop, toolbar } = this.props
		return (
			<div
				className="flex-column"
				style={{
					maxWidth: 960,
					width: '100%',
					marginLeft: 'auto',
					marginRight: 'auto',
					height: '100%',
				}}
			>
				<div className="flex-row">
					{toolbar ? (
						toolbar
					) : isDesktop ? (
						<p className="text-strong margin-top--xs">
							<FormattedMessage
								defaultMessage="Esikatselu"
								description="PDF preview header."
							/>
						</p>
					) : null}
					<div
						className="flex-row"
						style={{ justifyContent: 'flex-end', width: '100%' }}
					>
						<TipContainer2>
							{!hideDownload ? (
								<Button
									flat
									iconEl={<FontIcon style={{ opacity: 0.5 }}>get_app</FontIcon>}
									onClick={this.download}
									style={{ marginTop: 0, marginBottom: 0 }}
								>
									<FormattedMessage
										defaultMessage="Lataa"
										description="PDF preview download button."
									/>
								</Button>
							) : null}
						</TipContainer2>

						{fileURL ? (
							<TipContainer1>
								{refreshing ? (
									<CircularProgress
										id="progress"
										style={{ marginTop: 14, marginLeft: 16, marginRight: 16 }}
									/>
								) : (
									[
										numPages > 1 ? (
											<Button
												iconEl={<FontIcon>chevron_left</FontIcon>}
												onClick={this.previousPage}
												style={{ padding: 0 }}
												disabled={pageNumber === 1}
												icon
												key={1}
											/>
										) : null,
										<p
											key={2}
											style={{
												display: 'flex',
												paddingLeft: 4,
												paddingRight: 4,
												paddingTop: 8,
												paddingBottom: 8,
												textTransform: 'uppercase',
											}}
										>
											<FormattedMessage
												defaultMessage="Sivu {pageNumber} / {numPages}"
												description="PDF preview page numbers."
												values={{
													pageNumber,
													numPages,
												}}
											/>
										</p>,

										numPages > 1 ? (
											<Button
												iconEl={<FontIcon>chevron_right</FontIcon>}
												onClick={this.nextPage}
												style={{ padding: 0 }}
												disabled={pageNumber === numPages}
												icon
												key={3}
											/>
										) : null,
									]
								)}
							</TipContainer1>
						) : null}
					</div>
				</div>

				<PageContainer
					refreshing={refreshing}
					style={{
						position: 'relative',
						overflowX: 'hidden',
						overflowY: 'auto',
						height: '100%',
					}}
				>
					{this.renderPDF()}
					{refreshing ? <Overlay /> : null}
				</PageContainer>
			</div>
		)
	}
}

const mapState = ({
	firebaseReducer: { auth },
	firestoreReducer,
	dimensions: { isDesktop },
}) => ({ auth, firestoreReducer, isDesktop })

const mapDispatchToProps = (dispatch) => ({})

PDFPreview.propTypes = {
	fileURL: PropTypes.string,
	refreshing: PropTypes.bool,
	hideDownload: PropTypes.bool,
	getPageCount: PropTypes.func,
	toolbar: PropTypes.any,
}

export default compose(connect(mapState, mapDispatchToProps))(PDFPreview)
