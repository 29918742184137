import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

import ManagerDrawer from './ManagerDrawer'
import CompanyDrawer from './CompanyDrawer'
import WithSelectedManager from 'ui/components/WithSelectedManager'
import { compose } from 'redux'

const BASE_TITLE = 'Viilu'

class AppDrawer extends Component {
	updateDocumentTitle() {
		const { intl, currentRoute } = this.props
		if (typeof currentRoute.title === 'function') {
			document.title = `${currentRoute.title(intl)} - ${BASE_TITLE}`
		}
	}

	// this is done here because App.js does not yet have intl available
	componentDidMount() {
		this.updateDocumentTitle()
	}

	componentDidUpdate(prevProps) {
		if (prevProps.currentRoute !== this.props.currentRoute) {
			this.updateDocumentTitle()
		}
	}

	render() {
		const { currentRoute, selectedManager } = this.props

		if (window.isNativeApp) {
			// The side drawer should never be visible in the native apps
			return null
		}

		if (
			currentRoute.category === 'manager' ||
			currentRoute.category === 'manager-hidden' ||
			currentRoute.category === 'manager-customer' ||
			currentRoute.category === 'manager-internal' ||
			currentRoute.category === 'finance'
		) {
			return (
				<ManagerDrawer
					currentRoute={currentRoute}
					selectedManager={selectedManager}
				/>
			)
		}
		return (
			<CompanyDrawer
				currentRoute={currentRoute}
				selectedManager={selectedManager}
			/>
		)
	}
}

AppDrawer.propTypes = {
	currentRoute: PropTypes.object.isRequired,
}

export default compose(
	injectIntl,
	WithSelectedManager({ hideLoading: true }),
)(AppDrawer)
