import { TableContainer } from 'ui/StyledComponents/Tables'
import FloatingActionBtn from 'ui/components/FloatingActionBtn'
import { useIntl } from 'react-intl'
import {
	TableRow,
	TableColumn,
	DataTable,
	TableHeader,
	TableBody,
	ListItem,
	FontIcon,
} from 'react-md'
import OverflowGradient from 'ui/components/OverflowGradient'
import { useState } from 'react'
import SettingsTableRow from './SettingsTableRow'
import { bankAccountHeaders } from './constants'
import BankAccountDialog from './BankAccountDialog'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import config from 'config'
import useDialogs from 'ui/components/dialogs/useDialogs'
import { _deleteBankAccountSetting } from 'state/settings-actions'
import * as notifyActions from 'state/notifyActions'
import { GENERIC_ERROR_NOTIFICATION } from 'ui/messages'
import type { UseBankAccountsResult } from './useBankAccounts'

const HASH_BANK_ACCOUNTS = config.routeHashes.bankAccounts

type Props = {
	selectedFinanceCompanyUUID: string
	useBankAccounts: UseBankAccountsResult
	bankAccountUUID: string | null
}

const BankAccounts = ({
	selectedFinanceCompanyUUID,
	useBankAccounts,
	bankAccountUUID,
}: Props) => {
	const intl = useIntl()
	const dispatch = useDispatch()
	const { confirm } = useDialogs()
	const [processing, setProcessing] = useState<boolean>(false)

	const [addDialogVisible, setDialogVisible] = useState<boolean>(false)

	const { bankAccounts, refreshBankAccounts } = useBankAccounts
	const showAddBankAccountDialog = () => {
		setDialogVisible(true)
	}

	const hideAddBankAccountDialog = () => {
		if (bankAccountUUID) {
			dispatch(push(window.location.pathname + HASH_BANK_ACCOUNTS))
		}
		setDialogVisible(false)
	}

	const renderFAB = () => {
		return (
			<FloatingActionBtn
				iconName={'add'}
				text={intl.formatMessage({
					defaultMessage: 'LISÄÄ PANKKITILI',
					description: 'Add new bank account',
				})}
				action={showAddBankAccountDialog}
			/>
		)
	}
	const deleteBankAccounts = async (uuid: string) => {
		const confirmMessage = intl.formatMessage({
			defaultMessage: 'Oletko varma, että haluat poistaa pankkitilin?',
			description: 'Confirm message for the delete bank account.',
		})
		if (!(await confirm(confirmMessage))) {
			return
		}

		setProcessing(true)

		const result = await _deleteBankAccountSetting(uuid)
		setProcessing(false)

		if (!result || result.ok === false) {
			dispatch(notifyActions.info('❌ ' + GENERIC_ERROR_NOTIFICATION(intl)))
			return false
		}

		dispatch(
			notifyActions.info(
				'✅ ' +
					intl.formatMessage({
						defaultMessage: 'Pankkitili on poistettu.',
						description: 'Notification text of bank account has been deleted',
					}),
			),
		)
		refreshBankAccounts()
		return true
	}

	const renderTableHeaders = () => {
		return (
			<TableRow>
				{bankAccountHeaders
					.filter((h) => h.show)
					.map((header) => (
						<TableColumn key={header.key}>
							<p className="text-strong text-subtle">{header.title(intl)}</p>
						</TableColumn>
					))}
				<TableColumn key={'children-header'}></TableColumn>
			</TableRow>
		)
	}

	const handleOnClick = (uuid: string) => {
		dispatch(push(window.location.pathname + '?buuid=' + uuid))
	}

	return (
		<TableContainer>
			{bankAccounts ? (
				<TableContainer>
					<DataTable
						plain
						style={{
							background: 'white',
							maxHeight: 'calc(100vh - 205px)',
						}}
					>
						<TableHeader
							style={{
								position: 'sticky',
								top: 0,
								zIndex: 1,
								background: 'white',
							}}
						>
							{renderTableHeaders()}
						</TableHeader>
						<TableBody>
							{bankAccounts
								.map((bankAccount) => (
									<SettingsTableRow
										key={bankAccount.uuid}
										entity={bankAccount}
										menuItems={[
											<ListItem
												key={1}
												primaryText={intl.formatMessage({
													defaultMessage: 'Poista pankkitili',
													description: 'Delete bank account',
												})}
												leftIcon={<FontIcon>delete</FontIcon>}
												onClick={() => deleteBankAccounts(bankAccount.uuid)}
												className="md-divider-border md-divider-border--right"
											/>,
										]}
										children={null}
										processing={processing}
										headers={bankAccountHeaders}
										handleOnClick={() => handleOnClick(bankAccount.uuid)}
									/>
								))
								.concat(
									<tr
										key="empty-row"
										className="full-width"
										style={{ height: 100, background: 'white' }}
									></tr>,
								)}
						</TableBody>
						<OverflowGradient style={{ bottom: 40 }} />
					</DataTable>
				</TableContainer>
			) : null}

			<BankAccountDialog
				id={bankAccountUUID}
				useBankAccounts={useBankAccounts}
				visible={typeof bankAccountUUID === 'string' || addDialogVisible}
				onHide={hideAddBankAccountDialog}
				selectedFinanceCompanyUUID={selectedFinanceCompanyUUID}
			/>

			{renderFAB()}
		</TableContainer>
	)
}

export default BankAccounts
