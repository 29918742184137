export const ONBOARDING_NEXT_MESSAGE = (intl) =>
	intl.formatMessage({
		defaultMessage: 'Seuraava',
		description:
			'Button that allows the user to go to the next step in the user profile update dialog.',
	})

export const ONBOARDING_BACK_MESSAGE = (intl) =>
	intl.formatMessage({
		defaultMessage: 'Edellinen',
		description:
			'Button that allows the user to go to the previous step in the user profile update dialog.',
	})

export const ONBOARDING_CANCEL_MESSAGE = (intl) =>
	intl.formatMessage({
		defaultMessage: 'Peruuta',
		description:
			'Button that allows the user to cancel the profile update process.',
	})

export const ONBOARDING_VALIDATION_ERROR = (intl) =>
	intl.formatMessage({
		defaultMessage: 'Tarkistathan syöttämäsi tiedot ja yritä uudelleen.',
		description:
			'Error text asking the user to double check the info they inputed.',
	})

export const ONBOARDING_UPDATE_ERROR = (intl) =>
	intl.formatMessage({
		defaultMessage:
			'Profiilin päivityksessä tapahtui virhe. Ole hyvä ja yritä uudelleen.',
		description:
			'Error message shown to the user when a profile update error occures.',
	})

export const ONBOARDING_SHAREHOLDER_INFOREQUEST_HEADER = (intl) =>
	intl.formatMessage({
		defaultMessage: 'Osakkaan tietopyyntö',
		description: 'Title of the shareholder information request dialog.',
	})

export const ONBOARDING_APARTMENT_SELECTION_MESSAGE = (intl) =>
	intl.formatMessage({
		defaultMessage:
			'Valitse omistamasi tai vuokraamasi huoneistot, auto-, katos- ja hallipaikat sekä varastot.',
		description:
			'Instructional paragraph asking the user to select the apartments, parking spots and storage spaces.',
	})
