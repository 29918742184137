import config from 'config'
import { FontIcon, Tab, Tabs } from 'react-md'
import PartiesView from './PartiesView'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import UpdateBondDialog from './UpdateBondDialog'
import { useIntl } from 'react-intl'
import useParties from './useParties'
import useBonds from './useBonds'
import { useEffect, useState } from 'react'
import type { BondFilters, PartyFilters } from './types'
import WithSelectedManagerCompanies from 'ui/components/WithSelectedManagerCompanies'
import type { Manager } from 'types/manager'
import BondsView from './BondsView'
import useCompanyApartments from 'util/hooks/useCompanyApartments'
import { BondName, InvoicingMethod } from '../enum'
import { createApartmentOptions } from '../utils/apartmentOptions'
import { useBuildings } from 'ui/CompanyBuildings/hooks'
import type { SelectOptions } from '../types'
import { bondNameMessage, invoicingMethodMessage } from '../utils/messages'
import PartyDialog from './PartyDialog'

const HASH_PARTIES = config.routeHashes.parties
const HASH_BONDS = config.routeHashes.bonds

type Props = {
	selectedManagerCompanies: Manager[]
}

const Parties = ({ selectedManagerCompanies }: Props) => {
	const selectedFinanceCompanyUUID = useSelector(
		({ app }: any) => app?.selectedFinanceCompanyUUID,
	)
	const dispatch = useDispatch()
	const intl = useIntl()

	const [filters, setFilters] = useState<PartyFilters>({
		bondName: '',
		apartmentUUID: '',
		reviewDate: new Date(),
		invoicingMethod: '',
		active: null,
	})

	const [bondFilters, setBondFilters] = useState<BondFilters>({
		companyUUID: selectedFinanceCompanyUUID || '',
	})

	useEffect(() => {
		setFilters((prev) => ({ ...prev, companyUUID: selectedFinanceCompanyUUID }))
		setBondFilters((prev) => ({
			...prev,
			companyUUID: selectedFinanceCompanyUUID,
		}))
	}, [selectedFinanceCompanyUUID])

	const { companyApartments } = useCompanyApartments(selectedFinanceCompanyUUID)
	const { buildings } = useBuildings(selectedFinanceCompanyUUID)

	const location = useSelector((state: any) => state.router?.location)

	const { parties, loadingParties, refreshParties } = useParties(
		selectedFinanceCompanyUUID,
		filters,
	)
	const { bonds, loadingBonds, refreshBonds } = useBonds(bondFilters)

	const params = new URLSearchParams(location.search)
	const partyUUID = params.get('puuid')
	const bondUUID = params.get('ruuid')

	const setPageParties = () => {
		dispatch(push(HASH_PARTIES))
	}

	const setPageBonds = () => {
		dispatch(push(HASH_BONDS))
	}

	const getTabIndex = () => {
		if (bondUUID) {
			return 1
		}
		if (window.location.hash && window.location.hash.includes(HASH_BONDS)) {
			return 1
		}
		return 0
	}

	const onHideUpdatePartyDialog = () => {
		dispatch(push(window.location.pathname + HASH_PARTIES))
	}

	const onHideUpdateBondDialog = () => {
		dispatch(push(window.location.pathname + HASH_BONDS))
	}

	const apartmentOptions = createApartmentOptions(companyApartments, buildings)

	const invoicingOptions: SelectOptions[] = Object.values(InvoicingMethod).map(
		(value) => ({
			label: invoicingMethodMessage(intl, value),
			value: value,
		}),
	)

	const bondNameOptions: SelectOptions[] = Object.values(BondName).map(
		(value) => ({
			label: bondNameMessage(intl, value),
			value: value,
		}),
	)

	let companyOptions: SelectOptions[] = []
	if (selectedManagerCompanies && selectedManagerCompanies.length > 0) {
		companyOptions = selectedManagerCompanies.map((manager: Manager) => {
			return {
				label: manager.name,
				value: manager.uuid,
			}
		})
	}

	const getTabs = () => {
		const tabIndex = getTabIndex()

		return [
			<Tab
				key={1}
				style={
					tabIndex === 0
						? {
								color: 'var(--color-primary-dark)',
								fontWeight: '700',
						  }
						: {
								color: 'var(--color-text-subtle)',
						  }
				}
				label={intl.formatMessage({
					defaultMessage: 'Osapuolet',
					description: 'Title of parties',
				})}
				icon={<FontIcon>group</FontIcon>}
				onClick={setPageParties}
			/>,
			<Tab
				key={2}
				style={
					tabIndex === 1
						? {
								color: 'var(--color-primary-dark)',
								fontWeight: '700',
						  }
						: {
								color: 'var(--color-text-subtle)',
						  }
				}
				label={intl.formatMessage({
					defaultMessage: 'Roolit',
					description: 'Title of bonds',
				})}
				icon={<FontIcon>group</FontIcon>}
				onClick={setPageBonds}
			/>,
		]
	}
	const tabIndex = getTabIndex()

	const renderPage = () => {
		let pageComponent
		if (tabIndex === 0) {
			pageComponent = (
				<PartiesView
					parties={parties}
					refreshParties={refreshParties}
					loadingParties={loadingParties}
					filters={filters}
					setFilters={setFilters}
					selectedFinanceCompanyUUID={selectedFinanceCompanyUUID}
					apartmentOptions={apartmentOptions}
					invoicingOptions={invoicingOptions}
					bondNameOptions={bondNameOptions}
					companyOptions={companyOptions}
					refreshBonds={refreshBonds}
				/>
			)
		} else if (tabIndex === 1) {
			pageComponent = (
				<BondsView
					bonds={bonds}
					refreshBonds={refreshBonds}
					loadingBonds={loadingBonds}
					selectedFinanceCompanyUUID={selectedFinanceCompanyUUID}
					bondNameOptions={bondNameOptions}
					apartmentOptions={apartmentOptions}
					companyOptions={companyOptions}
					refreshParties={refreshParties}
				/>
			)
		}

		return (
			<div
				className="flex-column flex-grow"
				style={{ overflowY: 'auto', height: 'auto', minHeight: '400px' }}
			>
				{pageComponent}
				{partyUUID && (
					<PartyDialog
						id={partyUUID}
						visible={true}
						onHide={onHideUpdatePartyDialog}
						refreshParties={refreshParties}
						selectedFinanceCompanyUUID={selectedFinanceCompanyUUID}
						apartmentOptions={apartmentOptions}
						invoicingOptions={invoicingOptions}
						companyOptions={companyOptions}
						bondNameOptions={bondNameOptions}
						refreshBonds={refreshBonds}
					/>
				)}
				{bondUUID && (
					<UpdateBondDialog
						id={bondUUID}
						visible={true}
						onHide={onHideUpdateBondDialog}
						refreshBonds={refreshBonds}
						companyOptions={companyOptions}
						bondNameOptions={bondNameOptions}
					/>
				)}
			</div>
		)
	}

	return (
		<div className="flex-column" style={{ flexGrow: 1, overflow: 'hidden' }}>
			<Tabs
				tabId="users-mobile-tab"
				mobile
				style={{
					flexShrink: 0,
					borderBottomStyle: 'solid',
					borderBottomColor: 'rgba(0,0,0,0.2)',
					borderBottomWidth: 1,
				}}
				alignToKeyline={false}
				defaultTabIndex={tabIndex}
				indicatorHeight={3}
			>
				{getTabs()}
			</Tabs>
			{renderPage()}
		</div>
	)
}

export default WithSelectedManagerCompanies({ hideLoading: false })(Parties)
