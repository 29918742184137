const initialState = {
	loginDialogVisible: false,
}

function reducer(state = initialState, action) {
	if (action.type === 'UI_LOGIN_SHOW') {
		return { ...state, loginDialogVisible: true }
	} else if (action.type === 'UI_LOGIN_HIDE') {
		return { ...state, loginDialogVisible: false }
	}
	return state
}

export default reducer
