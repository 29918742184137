import { Checkbox } from 'react-md'
import { compose } from 'redux'
import styled from 'styled-components'
import WithSelectedCompany from 'ui/components/WithSelectedCompany'
import Actions from '../../Actions'
import { StepContainer } from '../styled'
import MessageServiceIntegrations from './MessageServiceIntegrations'

const Content = styled.div`
	max-width: 600px;
	padding: 24px 32px;
	margin: 16px auto;
	background: #f6f7f9;
	border-radius: 8px;
`

const StepTitle = styled.h1`
	font-size: 1.8rem;
	margin: 0;
	margin-bottom: 16px;
`

function DeliveryMethods({
	messageServiceProcess,
	setMessageServiceProcess,
	onPreviousClick,
	onDraftClick,
	onNextClick,
	selectedCompany,
	isMassSending,
}) {
	const updateMethods = (checked, method) => {
		let updated = [...messageServiceProcess.deliveryMethods]

		if (checked) {
			updated.push(method)
		} else {
			updated = updated.filter((x) => x !== method)
		}

		return updated
	}

	const handlePersonalChange = (checked, method) => {
		setMessageServiceProcess({
			...messageServiceProcess,
			deliveryMethods: updateMethods(checked, method),
		})
	}

	const handleBulletinsChange = (checked) => {
		const updatedMethods = updateMethods(checked, 'bulletins')
		const distributionOptions = {
			...messageServiceProcess.bulletinsSettings?.distributionOptions,
			user_tenant: checked,
			user_shareholder: checked,
			admin: checked,
			admin_board_deputy: checked,
			admin_board_director: checked,
			admin_board_member: checked,
		}
		setMessageServiceProcess({
			...messageServiceProcess,
			deliveryMethods: updatedMethods,
			bulletinsSettings: {
				...messageServiceProcess.bulletinsSettings,
				distributionOptions,
			},
			viewUrl: checked
				? `https://app.vii.lu/${selectedCompany.urlName}/bulletinboard`
				: null,
		})
	}

	const handleInfoscreenChange = (checked) => {
		const updatedMethods = updateMethods(checked, 'infoscreen')
		const distributionOptions = {
			...messageServiceProcess.bulletinsSettings?.distributionOptions,
			device_infoscreen: checked,
		}
		setMessageServiceProcess({
			...messageServiceProcess,
			deliveryMethods: updatedMethods,
			bulletinsSettings: {
				...messageServiceProcess.bulletinsSettings,
				distributionOptions,
			},
		})
	}

	const handleIntegrationChange = (selected, integrationUUID) => {
		let updated = messageServiceProcess.deliveryMethodsIntegrations
			? [...messageServiceProcess.deliveryMethodsIntegrations]
			: []

		if (selected) {
			updated.push(integrationUUID)
		} else {
			updated = updated.filter((i) => i !== integrationUUID)
		}

		setMessageServiceProcess({
			...messageServiceProcess,
			deliveryMethodsIntegrations: updated,
		})
	}

	const validateNext = () => {
		return (
			messageServiceProcess.deliveryMethods?.length > 0 ||
			messageServiceProcess.deliveryMethodsIntegrations?.length > 0
		)
	}

	const managerInternal =
		window.document.location.pathname.includes('_internal') && !isMassSending

	const checkEnabled = (feature) =>
		Boolean(selectedCompany?.features?.[feature])
	const lettersEnabled = checkEnabled('postal')
	const bulletinsEnabled = checkEnabled('bulletins')
	const infoscreensEnabled = checkEnabled('infoscreens')

	return (
		<>
			<StepContainer>
				<Content>
					<StepTitle>Lähetystavat</StepTitle>
					<Checkbox
						id={'delivery-methods-checkbox-email'}
						name={'delivery-methods-checkbox-email'}
						label={'Sähköposti'}
						checked={messageServiceProcess?.deliveryMethods.includes('email')}
						onChange={(value) => handlePersonalChange(value, 'email')}
						style={{ marginBottom: 8 }}
					/>
					<Checkbox
						id={'delivery-methods-checkbox-letter'}
						name={'delivery-methods-checkbox-letter'}
						label={
							'Kirje' +
							(isMassSending || lettersEnabled
								? ''
								: ' (postituspalvelua ei ole kytketty päälle)')
						}
						checked={messageServiceProcess?.deliveryMethods.includes('letter')}
						onChange={(value) => handlePersonalChange(value, 'letter')}
						style={{ marginBottom: 8 }}
						disabled={isMassSending || !lettersEnabled}
					/>
					<Checkbox
						id={'delivery-methods-checkbox-sms'}
						name={'delivery-methods-checkbox-sms'}
						label={'SMS'}
						checked={messageServiceProcess?.deliveryMethods.includes('sms')}
						onChange={(value) => handlePersonalChange(value, 'sms')}
						style={{ marginBottom: 8 }}
						disabled={isMassSending || false}
					/>
					<Checkbox
						id={'delivery-methods-checkbox-bulletins'}
						name={'delivery-methods-checkbox-bulletins'}
						label={'Viilu-palvelun ilmoitustaulu'}
						checked={messageServiceProcess?.deliveryMethods.includes(
							'bulletins',
						)}
						onChange={handleBulletinsChange}
						style={{ marginBottom: 8 }}
						disabled={managerInternal || !bulletinsEnabled}
					/>
					<Checkbox
						id={'delivery-methods-checkbox-infoscreen'}
						name={'delivery-methods-checkbox-infoscreen'}
						label={'Viilu-porrasnäyttö'}
						checked={messageServiceProcess?.deliveryMethods.includes(
							'infoscreen',
						)}
						onChange={handleInfoscreenChange}
						style={{ marginBottom: 8 }}
						disabled={managerInternal || !infoscreensEnabled}
					/>

					{!isMassSending && (
						<MessageServiceIntegrations
							deliveryMethodsIntegrations={
								messageServiceProcess.deliveryMethodsIntegrations
							}
							onSelectIntegration={handleIntegrationChange}
						/>
					)}
				</Content>
			</StepContainer>

			<Actions
				onPreviousClick={onPreviousClick}
				onDraftClick={onDraftClick}
				onNextClick={validateNext() ? onNextClick : null}
			/>
		</>
	)
}

export default compose(WithSelectedCompany)(DeliveryMethods)
