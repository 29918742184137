import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'

export default (ComponentToWrap) => {
	class WithCompanyNameFromURL extends Component {
		render() {
			const { location } = this.props
			let urlName
			if (location) {
				urlName = location.pathname.split('/')[1]
			} else {
				urlName = window.location.pathname.split('/')[1]
			}
			return (
				<ComponentToWrap
					{...this.props}
					urlName={!urlName ? null : decodeURIComponent(urlName)}
				/>
			)
		}
	}

	const mapState = ({ router: { location } }) => ({ location })

	const mapDispatchToProps = (dispatch) => ({})

	return compose(connect(mapState, mapDispatchToProps))(WithCompanyNameFromURL)
}
