import { IntlShape } from 'react-intl'
import type { SelectOptions, ApartmentSelectOptions } from '../types'
import { invoicingMethodMessage } from '../utils/messages'
import type { Contract } from './types'
import moment from 'moment'

export const getContractRowValue = (
	contract: Contract,
	key: string,
	companyOptions: SelectOptions[],
	apartmentOptions: ApartmentSelectOptions[],
	intl: IntlShape,
	type?: string,
) => {
	const value = contract[key]

	switch (type) {
		case 'invoicingMethod':
			if (contract.customInvoicing) {
				return invoicingMethodMessage(intl, value)
			}
			return invoicingMethodMessage(intl, contract.partyInvoicingMethod)
		case 'invoicingAddress':
			if (contract.customInvoicing) {
				return value
			}
			return contract.partyInvoicingAddress
		case 'partyUUID':
			return contract.partyName
		case 'secondaryPartyUUID':
			return contract?.secondaryPartyName || ''
		case 'companyOptions':
			return companyOptions.find((c) => c.value === value)?.label || ''
		case 'date':
			const parsedDate = moment(value)
			if (!parsedDate.isValid()) return ''
			return parsedDate.format('DD.MM.YYYY')
		case 'apartmentOptions':
			return (
				apartmentOptions.find((a) => a.value === value)?.label ||
				contract['apartmentIdentifier']
			)
		default:
			return value
	}
}
