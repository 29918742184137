import { useEffect, useMemo, useState } from 'react'
import { injectIntl } from 'react-intl'
import { Button, Toolbar } from 'react-md'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import WithSelectedManager from 'ui/components/WithSelectedManager'

import * as messageServiceActions from 'state/message-service-actions'
import * as notifyActions from 'state/notifyActions'
import LoadingState from 'ui/components/LoadingState'
import styled from 'styled-components'
import { steps } from './config'
import Stepper from 'ui/MessageService/MessageServiceProcess/Stepper'
import useDialogs from 'ui/components/dialogs/useDialogs'

const Container = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
	overflow: hidden;
`

function MassSendingProcess({
	intl,
	onHide,
	messageServiceMassProcess,
	selectedManager,
	_createMassProcess,
	_showInfoNotification,
	_updateMassProcess,
}) {
	const [process, setProcess] = useState()
	const [isLoading, setIsLoading] = useState(true)
	const { alert } = useDialogs()

	useEffect(() => {
		const initProcess = async () => {
			const result = await _createMassProcess({
				managerUUID: selectedManager.uuid,
			})

			if (!result || result.success === false) {
				await alert('Jokin meni pieleen.')
				return false
			} else {
				setProcess(result.data)
				setIsLoading(false)
				return true
			}
		}

		if (!messageServiceMassProcess?.uuid) {
			initProcess()
		} else {
			setProcess(messageServiceMassProcess)
			setIsLoading(false)
		}
	}, [
		_createMassProcess,
		messageServiceMassProcess,
		selectedManager.uuid,
		alert,
	])

	const [activeStepIndex, reachedStepIndex] = useMemo(() => {
		let active = steps.findIndex((s) => s.id === process?.step?.id)
		let reached = steps.findIndex((s) => s.id === process?.step?.reachedId)
		const found = (index) => (index === -1 ? 0 : index)
		return [found(active), found(reached)]
	}, [process?.step?.id, process?.step?.reachedId])

	const updateProcess = async (process) => {
		setIsLoading(true)
		const result = await _updateMassProcess(process)
		setIsLoading(false)

		if (!result || result.success === false) {
			await alert('Jotain meni pieleen.')
			return false
		}

		setProcess(result.data)
		return true
	}

	const goToStep = async (step) => {
		if (messageServiceMassProcess?.locked) {
			await alert('Prosessi on lukittu.')
			return
		}

		const nextStep = steps.find((s) => s.id === step.id)

		if (!nextStep) {
			return
		}

		const withNextStep = {
			...process,
			step: {
				...process.step,
				...step,
			},
		}

		await updateProcess(withNextStep)
	}

	const onPreviousClick =
		activeStepIndex === 0
			? null
			: (childId) => {
					if (childId) {
						goToStep({
							id: process.step.id,
							childId: childId,
						})
					} else {
						const previousStep = steps[activeStepIndex - 1]

						if (!previousStep) {
							return
						}

						goToStep({
							id: previousStep.id,
							childId: '',
						})
					}
			  }

	const onDraftClick = async () => {
		const ok = await updateProcess(process)
		if (ok) {
			_showInfoNotification('✅ Lähetyksen luonnos tallennettu.')
			onHide()
		}
	}

	const onNextClick = (childId) => {
		if (childId) {
			goToStep({
				id: process.step.id,
				childId: childId,
			})
		} else {
			const nextIndex = activeStepIndex + 1
			const nextStep = steps[nextIndex]

			if (!nextStep) {
				return
			}

			let reachedId = process.step?.reachedId || 0

			if (!reachedId || nextIndex > reachedStepIndex) {
				reachedId = nextStep.id
			}

			goToStep({
				id: nextStep.id,
				childId: '',
				reachedId,
			})
		}
	}

	const renderStep = () => {
		const activeStep = steps[activeStepIndex]
		const childSteps = activeStep.childSteps
		const ActiveStepComponent = activeStep?.component

		return (
			<ActiveStepComponent
				messageServiceProcess={process}
				setMessageServiceProcess={setProcess}
				childSteps={childSteps}
				onPreviousClick={onPreviousClick}
				onDraftClick={onDraftClick}
				onNextClick={onNextClick}
				onHide={onHide}
			/>
		)
	}

	return (
		<Container>
			<Toolbar
				title={intl.formatMessage({
					defaultMessage: 'Massalähetys',
					description: 'Title of the message service mass process.',
				})}
				colored
				style={{
					boxShadow: 'none',
					background: 'var(--color-secondary-dark)',
				}}
				nav={
					<Button icon onClick={onHide}>
						close
					</Button>
				}
				actions={
					<Stepper
						steps={steps}
						goToStep={goToStep}
						activeStepIndex={activeStepIndex}
						reachedStepIndex={reachedStepIndex}
					/>
				}
			/>

			{isLoading ? <LoadingState /> : renderStep()}
		</Container>
	)
}

const mapState = () => ({})

const mapDispatchToProps = (dispatch) => ({
	_createMassProcess: bindActionCreators(
		messageServiceActions._createMassProcess,
		dispatch,
	),
	_updateMassProcess: bindActionCreators(
		messageServiceActions._updateMassProcess,
		dispatch,
	),
	_showInfoNotification: bindActionCreators(notifyActions.info, dispatch),
})

export default compose(
	injectIntl,
	WithSelectedManager({ hideLoading: false }),
	connect(mapState, mapDispatchToProps),
)(MassSendingProcess)
