import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { FontIcon } from 'react-md'
import { NoWrapTextSpan } from '../../StyledComponents/BasicComponents'

const ComponentContainer = styled.div`
	width: 100%;
	position: relative;
	margin: 2px;
	font-size: 1rem;
`
const ItemWidthAndPadding = styled.div`
	width: 100%;
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 16px;
	padding-right: 8px;
`
const FieldContainer = styled(ItemWidthAndPadding)`
	cursor: pointer;
	border: 1px solid ${(props) => props.theme.lightGrey};
	background-color: ${(props) =>
		props.focused
			? props.backgroundColorFocused || 'transparent'
			: props.backgroundColorUnfocused || props.theme.lightGrey};
	display: grid;
	grid-template-columns: 1fr minmax(20px, auto);
`
const OptionItem = styled(ItemWidthAndPadding)`
	cursor: ${({ readOnly }) => (readOnly ? 'default' : 'pointer')};
	background-color: ${(props) =>
		props.readOnly ? props.theme.lightGrey : 'transparent'};
	&:hover {
		background-color: ${(props) => props.theme.lightGrey};
	}
`
const SearchInputField = styled.input`
	cursor: pointer;
	width: 100%;
	border: none;
	background: transparent;
`
const DropdownOptionsContainer = styled.div`
	width: 100%;
	max-height: 260px;
	background-color: white;
	position: absolute;
	overflow: auto;
	z-index: 10;
	border: 1px solid ${(props) => props.theme.lightGrey};
	box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.25);
	visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
`

const SelectedValueContainer = styled(ItemWidthAndPadding)`
	height: 40px;
	background-color: ${(props) => props.theme.lightGrey};
	overflow: hidden;
	position: absolute;
	display: grid;
	grid-template-columns: 1fr minmax(20px, auto);
	place-items: center;
	text-align: start;
	box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.25);
	z-index: 9;
	pointer-events: none;
`

function useOutsideClickListener(ref, _onOutsideClick) {
	useEffect(() => {
		const handleClickOutside = (event) => {
			if (ref.current && !ref.current.contains(event.target)) {
				_onOutsideClick()
			}
		}

		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [ref]) // eslint-disable-line react-hooks/exhaustive-deps
}

export default function SearchFieldDropdown(props) {
	const {
		label, // field label
		icon, // icon name string
		options, // array of objects with label and value for dropdown options
		itemLabel = 'label', // string that is used as a dynamic key in each option. This is for the label of the dropdown option
		itemValue = 'value', // string that is used as a dynamic key in each option. This is for the value of the dropdown option, Set it to null to return the whole object
		_onOptionSelect, // call back function on option select. option value is passed
		value, // field value for when redux store is not in use
		reduxStoreValue, // The default value of the field with be set to what is stored in the redux store. _onStoreSelect should then be passed with an action to update the value.
		hasSelectAll, // Boolean. If true, a select all option will be made as first item in options
		nullSelectAll, // Boolean. If true, show all value will be null. If false, the select all option will be an array of all option[itemValue] strings
		autoselectFirstValue, // Boolean. If true, the first option will be selected automatically if no value is passed
		autoselectFirstReduxValue, // Boolean. If true, the first option will be selected automatically if no redux value is passed
		containerStyle, // styles that will get merged to the FieldContainer
		searchInputStyle, // styles that will get merged to the SearchInputField
	} = props

	const initFieldOptions = () => {
		const defaultDropdownOptions = []
		const allValues = []
		const validOptions = options && Array.isArray(options)

		if (validOptions) {
			options.forEach((option) => {
				defaultDropdownOptions.push(option)
				if (hasSelectAll && !nullSelectAll) {
					allValues.push(option[itemValue])
				}
			})
		}

		if (validOptions && hasSelectAll) {
			defaultDropdownOptions.unshift({
				[itemLabel]: 'Näytä kaikki',
				[itemValue]: nullSelectAll ? null : allValues,
				key: 0,
			})
		}

		return defaultDropdownOptions
	}
	const getDefaultSelectOptionValue = () => {
		if (fieldOptions) {
			if (reduxStoreValue) {
				const optionWithReduxStoreValue = fieldOptions.find((option) => {
					if (typeof reduxStoreValue === 'string')
						return option[itemValue] === reduxStoreValue
					if (Array.isArray(reduxStoreValue)) {
						if (reduxStoreValue.length === 1)
							return option[itemValue] === reduxStoreValue[0]
					}
					return false
				})
				if (optionWithReduxStoreValue) {
					return optionWithReduxStoreValue
				}
			}
			if (value || typeof value === 'boolean') {
				const matchingValue = fieldOptions.find(
					(option) => option[itemValue] === value,
				)
				return matchingValue
			}

			return fieldOptions[0]
		}
	}

	const [fieldOptions, setFieldOptions] = useState(() => {
		const initialState = initFieldOptions()
		return initialState
	})
	const [selectedOption, setSelectedOption] = useState(() => {
		const initialState = getDefaultSelectOptionValue()
		return initialState
	})
	const [searchInputFocused, setSearchInputFocused] = useState(false)
	const [dropdownVisibility, setDropdownVisibility] = useState(false)
	const [searchValue, setSearchValue] = useState('')
	const [tempOptions, setTempOptions] = useState(null)
	const searchInputRef = useRef()
	const fieldRef = useRef()
	useOutsideClickListener(fieldRef, () => setDropdownVisibility(false))

	useEffect(() => {
		const newOptions = initFieldOptions()
		setFieldOptions(newOptions)
		if (value === null && selectedOption) {
			setSelectedOption({
				[itemLabel]: newOptions[0][itemLabel],
				[itemValue]: newOptions[0][itemValue],
				key: 0,
			})
		}
		if (reduxStoreValue === null && selectedOption) {
			setSelectedOption({
				[itemLabel]: newOptions[0][itemLabel],
				[itemValue]: newOptions[0][itemValue],
				key: 0,
			})
		}
		if (autoselectFirstValue) {
			if (options && options.length && !value) {
				_onOptionSelect(options[0][itemValue])
				setSelectedOption({
					[itemLabel]: options[0][itemLabel],
					[itemValue]: options[0][itemValue],
					key: 0,
				})
			}
		} else if (autoselectFirstReduxValue) {
			if (options && options.length && !reduxStoreValue) {
				_onOptionSelect(options[0][itemValue])
				setSelectedOption({
					[itemLabel]: options[0][itemLabel],
					[itemValue]: options[0][itemValue],
					key: 0,
				})
			}
		} else if (Array.isArray(reduxStoreValue) && reduxStoreValue.length === 0) {
			// Here we select all the options if we receive in an empty array as the selected value
			// This is done because in case of changing the value of the manager filter (Task management)
			// we set all other filters to their default values
			// However, the categories will then be an empty array, which will make it so that no tasks are queried
			if (_onOptionSelect && hasSelectAll && !nullSelectAll) {
				const allValues = options.map((option) => option[itemValue])
				if (allValues.length > 0) {
					_onOptionSelect(allValues)
					setSelectedOption({
						[itemLabel]: 'Näytä kaikki',
						[itemValue]: allValues,
						key: 0,
					})
				}
			}
		}
	}, [options]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (searchInputFocused) {
			setDropdownVisibility(true)
			searchInputRef.current && searchInputRef.current.focus()
		}
	}, [searchInputFocused])

	useEffect(() => {
		const initialOptions = initFieldOptions()
		// Filter out the selected value so it's not rendered "twice"
		// once in the top and once in the list
		const filteredOutCurrentValueOptions =
			initialOptions &&
			initialOptions.filter((option) => {
				const currentOptionValue = option[itemValue]
				const currentSelectedValue = selectedOption
					? selectedOption[itemValue]
					: null
				const stringifiedCurrentSelectedValue =
					typeof currentSelectedValue === 'string'
						? currentSelectedValue
						: JSON.stringify(currentSelectedValue)
				const stringifiedOptionValue =
					typeof currentOptionValue === 'string'
						? currentOptionValue
						: JSON.stringify(currentOptionValue)
				return stringifiedCurrentSelectedValue !== stringifiedOptionValue
			})
		setFieldOptions(filteredOutCurrentValueOptions)
	}, [selectedOption, options]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (searchValue) {
			const filteredOptions = fieldOptions.filter((option) => {
				const optionLabel = option && (option[itemLabel] || option.label)
				const lowerCaseLabel = (optionLabel || '').toLowerCase()
				const input = searchValue.toLowerCase()
				return (
					lowerCaseLabel.indexOf(input) > -1 &&
					option[itemLabel] !== selectedOption[itemLabel]
				)
			})
			setTempOptions(filteredOptions)
		}
		if (!searchValue) setTempOptions(fieldOptions)
	}, [searchValue, fieldOptions]) // eslint-disable-line react-hooks/exhaustive-deps

	const renderOptionItem = (item, index) => {
		return (
			<OptionItem
				key={item[itemValue] || index}
				onClick={() => {
					setSelectedOption(item)

					if (selectedOption && _onOptionSelect) {
						if (itemValue) {
							_onOptionSelect(item[itemValue])
						} else {
							_onOptionSelect(item)
						}
					}

					setSearchInputFocused(false)
					setDropdownVisibility(false)
				}}
			>
				{itemLabel ? item[itemLabel] : item.label}
			</OptionItem>
		)
	}

	const renderDropdownOptions = () => {
		const optionsToRender = tempOptions || fieldOptions
		return optionsToRender && optionsToRender.map(renderOptionItem)
	}

	const handleOnSearchInputChange = (event) => {
		if (searchInputFocused) {
			setSearchValue(event.target.value)
		}
	}
	return (
		<ComponentContainer ref={fieldRef}>
			{label && (
				<div className="flex-row">
					<FontIcon>{icon}</FontIcon>
					<p className="text--xs">{label}</p>
				</div>
			)}
			<FieldContainer
				onClick={() => setSearchInputFocused(true)}
				focused={dropdownVisibility}
				style={{
					...containerStyle,
				}}
				backgroundColorUnfocused={containerStyle?.backgroundColorUnfocused}
				backgroundColorFocused={containerStyle?.backgroundColorFocused}
			>
				<SearchInputField
					data-testid={label || 'search-field-dropdown-input'}
					ref={searchInputRef}
					value={
						searchInputFocused
							? searchValue
							: selectedOption
							? selectedOption[itemLabel]
							: ''
					}
					onChange={handleOnSearchInputChange}
					onFocus={() => setSearchInputFocused(true)}
					onBlur={() => setSearchInputFocused(false)}
					style={{
						...searchInputStyle,
					}}
				/>
				<FontIcon>arrow_drop_down</FontIcon>
			</FieldContainer>
			{selectedOption && dropdownVisibility && (
				<SelectedValueContainer>
					<NoWrapTextSpan
						data-testid={`${label}-selected-option-display`}
						style={{ width: '100%' }}
					>
						{selectedOption[itemLabel]}
					</NoWrapTextSpan>
					<div style={{ fontSize: '0.8em', opacity: 0.8, paddingLeft: '5px' }}>
						valittu
					</div>
				</SelectedValueContainer>
			)}
			<DropdownOptionsContainer
				visible={dropdownVisibility}
				style={{ top: label ? '102px' : '82px' }}
			>
				{dropdownVisibility && renderDropdownOptions()}
			</DropdownOptionsContainer>
		</ComponentContainer>
	)
}

SearchFieldDropdown.propTypes = {
	options: PropTypes.array.isRequired,
	itemLabel: PropTypes.string.isRequired,
	itemValue: PropTypes.string.isRequired,
	label: PropTypes.string,
	_onOptionSelect: PropTypes.func,
	hasSelectAll: PropTypes.bool,
	hasNullSelectAll: PropTypes.bool,
	icon: PropTypes.string,
}
