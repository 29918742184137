import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import { StepContainer } from 'ui/MessageService/MessageServiceProcess/steps/styled'

const Content = styled.div`
	max-width: 600px;
	margin: 40px auto;
`

const DatePickerContainer = styled.div`
	margin-bottom: 32px;
	display: flex;
	align-items: center;
`

function Infoscreen({
	messageServiceProcess: {
		bulletinsSettings: { publishTsInfoscreen, expirationTsInfoscreen },
	},
}) {
	const formatTs = (ts) => {
		if (!ts) {
			return ''
		}

		return new Date(ts).toLocaleString('fi', {
			dateStyle: 'medium',
			timeStyle: 'short',
		})
	}

	return (
		<StepContainer>
			<Content>
				<p style={{ marginBottom: 16 }}>
					<FormattedMessage
						defaultMessage="Julkaisupäivä"
						description="Header of a section in the message service where the user can select the bulletin publish time."
					/>
				</p>
				<DatePickerContainer>
					<p style={{ fontSize: 14 }}>
						{publishTsInfoscreen ? (
							formatTs(publishTsInfoscreen)
						) : (
							<FormattedMessage
								defaultMessage="Julkaistaan heti."
								description="Label in the message service for bulletin publish time if it has not been selected."
							/>
						)}
					</p>
				</DatePickerContainer>
				<p style={{ marginBottom: 16 }}>
					<FormattedMessage
						defaultMessage="Poistumispäivä"
						description="Header of a section in the message service where the user can select the bulletin expiration time."
					/>
				</p>
				<DatePickerContainer>
					<p style={{ fontSize: 14 }}>
						{expirationTsInfoscreen ? (
							formatTs(expirationTsInfoscreen)
						) : (
							<FormattedMessage
								defaultMessage="Ei poistumispäivää."
								description="Label in the message service for bulletin expiration time if it has not been selected."
							/>
						)}
					</p>
				</DatePickerContainer>
			</Content>
		</StepContainer>
	)
}

export default Infoscreen
