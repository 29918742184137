import { useFormContext } from 'react-hook-form'
import type {
	LoanApartmentWithShareholderFields,
	LoanCreateFormInputs,
} from '../types'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import type { SelectOptions } from '../../types'
import LoanApartmentShareGroupsTable from './LoanApartmentShareGroupTable'
import LoanFormInput from './LoanFormInput'
import { formatCurrency } from 'util/formatStrings'
import { CreateFormGrid, CreateFormGridItem } from './components'
import { billingBasisInput } from '../constants'

const ApartmentShareGroupNotification = styled.div`
	text-align: center;
	padding: 2rem 0;
	color: var(--color-text-subtle);
	font-size: var(--text-xs); ;
`

const ApartmentShareGroupInfoContainer = styled.div`
	display: flex;
	gap: 0.25rem;
	font-size: var(--text-xs);
	margin-bottom: 0.5rem;
`

type LoanCreateShareGroupsFormProps = {
	companyOptions: SelectOptions[]
	loanApartments: LoanApartmentWithShareholderFields[]
}

function LoanCreateShareGroupsForm(props: LoanCreateShareGroupsFormProps) {
	const { loanApartments } = props
	const { watch } = useFormContext<LoanCreateFormInputs>()
	const originalLoanAmount = watch('originalLoanAmount')

	return (
		<CreateFormGrid>
			<CreateFormGridItem $fullWidth>
				<LoanFormInput input={billingBasisInput} />
			</CreateFormGridItem>
			<CreateFormGridItem $fullWidth>
				{originalLoanAmount > 0 ? (
					<>
						<ApartmentShareGroupInfoContainer>
							<FormattedMessage
								defaultMessage="Olet valinnut lainasummaksi:"
								description="Chosen loan amount is currently:"
							/>
							<strong>{formatCurrency(originalLoanAmount)}</strong>
						</ApartmentShareGroupInfoContainer>
						<LoanApartmentShareGroupsTable loanApartments={loanApartments} />
					</>
				) : (
					<ApartmentShareGroupNotification>
						<FormattedMessage
							defaultMessage="Määritä lainan määrä (alkuperäinen pääoma), jotta voit nähdä laina-osuudet."
							description="Enter loan amount in order to see loan shares"
						/>
					</ApartmentShareGroupNotification>
				)}
			</CreateFormGridItem>
		</CreateFormGrid>
	)
}

export default LoanCreateShareGroupsForm
