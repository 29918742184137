import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import WithLoadingUntilFirestoreLoaded from './WithLoadingUntilFirestoreLoaded'

export default (storeAs, propName, hideLoading, uuidProp) =>
	(ComponentToWrap) => {
		class WithFirestoreArrayResultToSingleValue extends Component {
			render() {
				const {
					firestoreReducer,
					firestoreReducer: { ordered },
				} = this.props
				if (
					firestoreReducer.errors &&
					firestoreReducer.errors.allIds &&
					firestoreReducer.errors.allIds.length > 0
				) {
					console.error(firestoreReducer)
				}
				let props = { ...this.props }

				if (uuidProp) {
					if (uuidProp.includes('.')) {
						const elements = uuidProp.split('.')
						if (elements.length === 2) {
							if (
								!elements ||
								!elements[0] ||
								!elements[1] ||
								!this.props[elements[0]] ||
								!this.props[elements[0]][elements[1]] ||
								!ordered[storeAs + this.props[elements[0]][elements[1]]] ||
								!ordered[storeAs + this.props[elements[0]][elements[1]]][0]
							) {
								return <ComponentToWrap {...props} />
							}
							props = {
								...this.props,
								[propName]:
									ordered[storeAs + this.props[elements[0]][elements[1]]][0],
							}
						} else if (elements.length === 3) {
							if (
								!elements ||
								!elements[0] ||
								!elements[1] ||
								!elements[2] ||
								!this.props[elements[0]] ||
								!this.props[elements[0]][elements[1]] ||
								!this.props[elements[0]][elements[1]][elements[2]] ||
								!ordered[storeAs + this.props[elements[0]][elements[1]]] ||
								!ordered[
									storeAs + this.props[elements[0]][elements[1]][elements[2]]
								] ||
								!ordered[
									storeAs + this.props[elements[0]][elements[1]][elements[2]]
								][0]
							) {
								return <ComponentToWrap {...props} />
							}
							props = {
								...this.props,
								[propName]:
									ordered[
										storeAs + this.props[elements[0]][elements[1]][elements[2]]
									][0],
							}
						} else {
							// Shouldn't happen?
							if (
								!this.props[uuidProp] ||
								!ordered[storeAs + this.props[uuidProp]]
							) {
								return <ComponentToWrap {...props} />
							}
							props = {
								...this.props,
								[propName]: ordered[storeAs + this.props[uuidProp]][0],
							}
						}
					} else {
						if (
							!this.props[uuidProp] ||
							!ordered[storeAs + this.props[uuidProp]]
						) {
							return <ComponentToWrap {...props} />
						}
						props = {
							...this.props,
							[propName]: ordered[storeAs + this.props[uuidProp]][0],
						}
					}
				} else {
					if (!ordered[storeAs]) {
						return <ComponentToWrap {...props} />
					}
					props = { ...this.props, [propName]: ordered[storeAs][0] }
				}
				return <ComponentToWrap {...props} />
			}
		}

		const mapState = ({ firestoreReducer }) => ({
			firestoreReducer,
		})

		const mapDispatchToProps = (dispatch) => ({})

		return compose(
			WithLoadingUntilFirestoreLoaded(storeAs, hideLoading, uuidProp),
			connect(mapState, mapDispatchToProps),
		)(WithFirestoreArrayResultToSingleValue)
	}
