import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect, isEmpty } from 'react-redux-firebase'
import WithLoggedInUserUUID from './WithLoggedInUserUUID'

export default (ComponentToWrap) => {
	class WithLoggedInUser extends Component {
		render() {
			const { user } = this.props
			const users = user
			// Don't check isLoaded by design, because unauthenticated users can't load
			if (isEmpty(users)) {
				return <ComponentToWrap {...this.props} loggedInUser={null} />
			}

			if (window.sentry && users[0]) {
				window.sentry.withScope((scope) => {
					scope.setUser({
						id: users[0].uuid,
						email: users[0].email,
						username: users[0].displayName,
					})
				})
			}

			return <ComponentToWrap {...this.props} loggedInUser={users[0]} />
		}
	}

	const mapState = ({
		firestoreReducer: {
			ordered: { user },
		},
	}) => ({ user })

	const mapDispatchToProps = (dispatch) => ({})

	return compose(
		WithLoggedInUserUUID,
		firestoreConnect(({ loggedInUserUUID }) => {
			if (!loggedInUserUUID) {
				return []
			}
			return [
				{
					collection: 'user',
					where: [['uuid', '==', loggedInUserUUID || '']],
					limit: 1,
				},
			]
		}),
		connect(mapState, mapDispatchToProps),
	)(WithLoggedInUser)
}
