import { Button, CardActions, CardText, CardTitle } from 'react-md'
import { StandardCard } from 'ui/StyledComponents/Cards'

import bg from 'svg/illustrations/illustration_abstract_bg.svg'

const MessageServicePromo = () => {
	return (
		<div className="flex-column full-width full-height flex-center">
			<img
				src={bg}
				style={{
					position: 'absolute',
					width: '100%',
					height: '100%',
					objectFit: 'cover',
				}}
				alt=""
			/>
			<StandardCard style={{ maxWidth: 500, zIndex: 2 }}>
				<CardTitle title={'Kiinnostaako viestintäpalvelu?'} />
				<CardText>
					Viilu tarjoaa viestintäpalvelua, joka mahdollistaa aineiston ja
					viestien lähetyksen sähköpostitse, kirjeitse, tekstiviestitse,
					sovelluksen ilmoitustaululle sekä porrasnäytöille.
				</CardText>
				<CardActions>
					<Button
						secondary
						raised
						onClick={() => window.open('https://vii.lu/support', '_blank')}
						style={{ marginLeft: 8, marginBottom: 8 }}
					>
						Ota yhteyttä
					</Button>
				</CardActions>
			</StandardCard>
		</div>
	)
}

export default MessageServicePromo
