import { FormattedMessage, useIntl } from 'react-intl'
import { TextField, Button } from 'react-md'
import styled from 'styled-components'
import DropdownField from 'ui/components/DropdownField'
import AwesomeDebouncePromise from 'awesome-debounce-promise'
import { useEffect, useState } from 'react'
import type { SelectOptions } from '../types'
import { BondName } from '../enum'
import { bondNameMessage } from '../utils/messages'

const Container = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	gap: 1rem;
	margin-bottom: 1rem;
`
const Header = styled.div`
	min-width: 160px;
`

const FlexBox = styled.div`
	display: flex;
	gap: 0.5rem;
`

const SearchTextInput = styled.div`
	display: flex;
	align-items: center;
	gap: 0.5rem;
`

interface Props {
	sortByValue: string
	sortBy: (value: string) => void
	selectAllBy: (value: string) => void
	searchBy: (value: string) => void
	preview: boolean
}

const searchTextDebounced = AwesomeDebouncePromise((func) => {
	func()
}, 500)

const InvoiceCreateHeader = ({
	sortByValue,
	sortBy,
	selectAllBy,
	searchBy,
	preview,
}: Props) => {
	const intl = useIntl()
	const [searchText, setSearchText] = useState<string>()

	useEffect(() => {
		if (searchText !== undefined) {
			searchTextDebounced(() => {
				searchBy(searchText)
			})
		}
	}, [searchText, searchBy])

	const sortOptions = (): SelectOptions[] => {
		const options: SelectOptions[] = [
			{
				label: intl.formatMessage({
					defaultMessage: 'Asunto',
					description: 'apartmentUUID',
				}),
				value: 'apartmentUUID',
			},
			{
				label: intl.formatMessage({
					defaultMessage: 'Maksaja',
					description: 'partyName',
				}),
				value: 'partyName',
			},
		]

		Object.values(BondName).map((value) =>
			options.push({
				label: bondNameMessage(intl, value),
				value: value,
			}),
		)
		return options
	}

	return (
		<Container>
			<Header>
				<DropdownField
					label={intl.formatMessage({
						defaultMessage: 'Lajittele',
						description: 'Label for the invoice apartment filter.',
					})}
					itemLabel={'label'}
					itemValue={'value'}
					options={sortOptions()}
					hasSelectAll={false}
					nullSelectAll={true}
					value={sortByValue}
					_onOptionSelect={(value) => {
						sortBy(value)
					}}
				/>
			</Header>
			<Header>
				<SearchTextInput>
					<TextField
						id="payment-product-search-text-input"
						label={intl.formatMessage({
							defaultMessage: 'Hae maksajan nimellä',
							description:
								'Label for the invoice create search text filter: search by payer name',
						})}
						value={searchText || ''}
						onChange={(value) => {
							setSearchText(value.toString())
						}}
					/>
					<Button
						icon
						style={{ marginTop: 16 }}
						onClick={() => {
							setSearchText('')
						}}
					>
						clear
					</Button>
				</SearchTextInput>
			</Header>
			{!preview && (
				<Header>
					<p className="text--xs">
						{intl.formatMessage({
							defaultMessage: 'Valitse kaikki',
							description: 'Title of select all',
						})}
					</p>
					<FlexBox>
						<Button raised primary onClick={() => selectAllBy('shareholder')}>
							<FormattedMessage
								defaultMessage="OSAKKAAT"
								description="Button to select all by: Shareholder."
							/>
						</Button>
						<Button raised primary onClick={() => selectAllBy('tenant')}>
							<FormattedMessage
								defaultMessage="ASUKKAAT"
								description="Button to select all by: Tenant."
							/>
						</Button>
						<Button
							raised
							primary
							onClick={() => selectAllBy('shareholder_not_registered')}
						>
							<FormattedMessage
								defaultMessage="OSAKKAAT (ei rekisteröity)"
								description="Button to select all by: Shareholder (not registered)."
							/>
						</Button>
					</FlexBox>
				</Header>
			)}
		</Container>
	)
}

export default InvoiceCreateHeader
