import type { LoanCreateData, LoanFilters } from 'ui/ManagerFinance/Loans/types'
import { callApi } from './api/finance-api'
import { generateQueryParams } from 'util/queryUtils'

export const _getLoan = async (loanUUID: string) => {
	return callApi(`/v1/loan/${loanUUID}`, {
		method: 'GET',
	})
}

export const _getLoans = async (loanFilters: LoanFilters) => {
	return callApi(`/v1/loan${generateQueryParams(loanFilters)}`, {
		method: 'GET',
	})
}

export const _getLoanShareholder = async (
	loanUUID: string,
	shareholderUUID: string,
) => {
	return callApi(`/v1/loan/${loanUUID}/shareholders/${shareholderUUID}`, {
		method: 'GET',
	})
}

export const _getShareholdersByShareGroupUUID = async (
	shareGroupUUID: string,
) => {
	return callApi(`/v1/loan/shareholders?shareGroupUUID=${shareGroupUUID}`, {
		method: 'GET',
	})
}

export const _createLoan = async (data: LoanCreateData) => {
	return callApi(`/v1/loan`, {
		method: 'POST',
		body: {
			...data,
		},
	})
}

export const _updateLoan = async (loanUUID: string, data: LoanCreateData) => {
	return callApi(`/v1/loan/${loanUUID}`, {
		method: 'PUT',
		body: {
			...data,
		},
	})
}
