import { useEffect } from 'react'
import { injectIntl } from 'react-intl'
import {
	CircularProgress,
	DataTable,
	TableBody,
	TableColumn,
	TableHeader,
	TableRow,
} from 'react-md'
import { connect } from 'react-redux'
import { firestoreConnect, isLoaded } from 'react-redux-firebase'
import { compose } from 'redux'
import styled from 'styled-components'
import WithSelectedCompany from 'ui/components/WithSelectedCompany'

const NoInfoscreens = styled.p`
	margin-left: 16px;
	margin-top: 16px;
	font-size: 16px;
`

function InfoscreenSelection({
	intl,
	selectedCompany,
	selectedInfoscreens,
	updateSelectedInfoscreens,
	infoscreens,
	infoscreenModels,
	style,
}) {
	useEffect(() => {
		if (!isLoaded(infoscreens)) {
			return
		}

		if (
			infoscreens.length > 0 &&
			infoscreens[0].companyUUID !== selectedCompany.uuid
		) {
			// Still fetching infoscreens after company change.
			return
		}

		// Select all infoscreens by default.
		if (!selectedInfoscreens) {
			const updated = {}
			infoscreens?.forEach((infoscreen) => {
				updated[infoscreen.uuid] = true
			})
			updateSelectedInfoscreens(updated)
		}
	}, [
		infoscreens,
		selectedCompany.uuid,
		selectedInfoscreens,
		updateSelectedInfoscreens,
	])

	const handleSelect = (i, checked) => {
		const updated = { ...selectedInfoscreens }
		if (i === 0) {
			infoscreens.forEach((infoscreen) => {
				updated[infoscreen.uuid] = checked
			})
		} else {
			const selected = infoscreens[i - 1]
			updated[selected.uuid] = checked
		}
		updateSelectedInfoscreens(updated)
	}

	const renderModel = (modelUUID) => {
		const model = infoscreenModels?.find((m) => m.uuid === modelUUID)
		if (!model?.display) {
			return '-'
		}
		const size = model.display.inch
		const type = model.display.touchscreen
			? intl.formatMessage({
					defaultMessage: 'Kosketusnäyttö',
					description:
						'Label for touchscreen models in the infoscreen selection component.',
			  })
			: intl.formatMessage({
					defaultMessage: 'Passiivinäyttö',
					description:
						'Label for non touchscreen models in the infoscreen selection component.',
			  })
		return `${size}″ ${type}`
	}

	const isAllSelected = () => {
		const selected = Object.values(selectedInfoscreens || {})
		return selected.length > 0 && selected.every((s) => s)
	}

	const renderSelection = () => {
		if (!isLoaded(infoscreens) || !isLoaded(infoscreenModels)) {
			return <CircularProgress id="infoscreen-selection-progress" />
		}

		if (
			infoscreens.length > 0 &&
			infoscreens[0].companyUUID !== selectedCompany.uuid
		) {
			return <CircularProgress id="infoscreen-selection-progress" />
		}

		if (infoscreens?.length === 0) {
			return (
				<NoInfoscreens>
					{intl.formatMessage({
						defaultMessage: 'Porrasnäyttöjä ei löytynyt.',
						description:
							'Message shown if the company does not have any infoscreens in the infoscreen selection component.',
					})}
				</NoInfoscreens>
			)
		}

		return (
			<DataTable
				baseId="message-service-infoscreen-selection-table"
				style={{}}
				onRowToggle={handleSelect}
			>
				<TableHeader>
					<TableRow selected={isAllSelected()}>
						<TableColumn>
							{intl.formatMessage({
								defaultMessage: 'Nimi',
								description:
									'Table header for infoscreen name in the infoscreen selection component.',
							})}
						</TableColumn>
						<TableColumn grow>
							{intl.formatMessage({
								defaultMessage: 'Malli',
								description:
									'Table header for infoscreen model in the infoscreen selection component.',
							})}
						</TableColumn>
					</TableRow>
				</TableHeader>
				<TableBody>
					{infoscreens.map((infoscreen) => (
						<TableRow
							key={infoscreen.uuid}
							selected={selectedInfoscreens?.[infoscreen.uuid]}
						>
							<TableColumn>{infoscreen.name}</TableColumn>
							<TableColumn>{renderModel(infoscreen.modelUUID)}</TableColumn>
						</TableRow>
					))}
				</TableBody>
			</DataTable>
		)
	}

	return (
		<div style={{ ...style }}>
			{intl.formatMessage({
				defaultMessage: 'Valitse porrasnäytöt',
				description: 'Top label for the infoscreen selection component.',
			})}
			{renderSelection()}
		</div>
	)
}

const mapState = ({
	firestoreReducer: {
		ordered: { company_infoscreens_message_service, company_infoscreen_models },
	},
}) => ({
	infoscreens: company_infoscreens_message_service,
	infoscreenModels: company_infoscreen_models,
})

const mapDispatchToProps = (dispatch) => ({})

export default compose(
	injectIntl,
	WithSelectedCompany,
	firestoreConnect(({ selectedCompany }) =>
		selectedCompany
			? [
					{
						collection: 'company-infoscreen',
						storeAs: 'company_infoscreens_message_service',
						where: [['companyUUID', '==', selectedCompany.uuid]],
					},
			  ]
			: [],
	),
	firestoreConnect(() => [
		{
			collection: 'company-infoscreen-model',
			storeAs: 'company_infoscreen_models',
		},
	]),
	connect(mapState, mapDispatchToProps),
)(InfoscreenSelection)
