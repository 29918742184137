import { useCallback, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import usePayments from '../Payments/usePayments'
import {
	DataTable,
	TableHeader,
	TableRow,
	TableColumn,
	TableBody,
	TextField,
	Button,
	CircularProgress,
} from 'react-md'
import { formatCurrency } from 'util/formatStrings'
import type { Invoice } from './types'
import type { Payments, AllocatePayment } from '../Payments/types'
import { removeLeadingZero } from '../utils/utils'
import useDialogs from 'ui/components/dialogs/useDialogs'
import { _createInvoicePaymentAllocations } from 'state/finance-invoice-actions'

const Content = styled.div`
	flex: 1;
	display: flex;
	overflow: auto;
	padding: 24px 0px 24px 0px;
`
const Heading = styled.h4`
	margin: 0;
`
const HeadingGuide = styled.text`
	font-size: 12px;
	margin: 0;
	margin-top: 8;
	color: #666;
`
const ButtonContainer = styled.div`
	display: flex;
	justify-content: end;
	margin-top: 8px;
`
type Props = {
	invoice: Invoice
	refreshInvoices: () => void
}

const InvoicePaymentAllocationView = ({ invoice, refreshInvoices }: Props) => {
	const intl = useIntl()
	const selectedFinanceCompanyUUID = useSelector(
		({ app }: any) => app?.selectedFinanceCompanyUUID,
	)
	const { alert } = useDialogs()

	const paymentFilters = useMemo(
		() => ({
			companyUUID: selectedFinanceCompanyUUID || '',
			apartmentUUID: null,
			paymentDate: null,
			recordedDate: null,
			contractUUID: null,
			partyUUID: null,
			matched: null,
			referenceNumber: null,
			message: null,
		}),
		[selectedFinanceCompanyUUID],
	)
	const [loading, setLoading] = useState(false)
	const { data: payments, loading: loadingPayments } =
		usePayments(paymentFilters)

	const isLoading = loading || loadingPayments

	const [selectedPayments, setSelectedPayments] = useState<AllocatePayment[]>(
		[],
	)

	const selectPayment = (payment: Payments) => {
		setSelectedPayments((prev) => {
			if (prev.some((s) => s.uuid === payment.uuid)) {
				return prev
			}
			return [...prev, { ...payment, amount: 0 }]
		})
	}

	const unSelectPayment = (payment: Payments) => {
		setSelectedPayments((prev) => {
			return prev.filter((s) => s.uuid !== payment.uuid)
		})
	}

	const validateAllocationTotal = useCallback(() => {
		if (!invoice) {
			return false
		}

		const { paymentsTotal, totalWithVat } = invoice

		const allocationTotal =
			selectedPayments.reduce((acc, a) => acc + a.amount, 0) + paymentsTotal

		if (allocationTotal > totalWithVat) {
			const message = intl.formatMessage({
				defaultMessage:
					'Kohdistettujen suorituksien summa on suurempi kuin laskun summa.',
				description:
					'Error message for payment total is higher then the invoice total.',
			})
			alert(message)
			return false
		}
		return true
	}, [alert, intl, invoice, selectedPayments])

	useEffect(() => {
		validateAllocationTotal()
	}, [invoice, selectedPayments, validateAllocationTotal])

	const handleCancel = () => {
		setSelectedPayments([])
	}

	const handleSubmit = async () => {
		if (!validateAllocationTotal()) {
			return
		}
		//TODO: VALIDATE

		const paymentAllocations = selectedPayments.map((sp) => {
			return {
				paymentUUID: sp.uuid,
				amount: sp.amount,
			}
		})

		if (!paymentAllocations.length) {
			const alertMSG = intl.formatMessage({
				defaultMessage: 'Ei uusia kohdistuksia',
				description: 'No new allocations',
			})
			alert(alertMSG)
			return
		}

		setLoading(true)
		try {
			const result = await _createInvoicePaymentAllocations(
				invoice.uuid,
				paymentAllocations,
			)
			if (result?.ok) {
				refreshInvoices()
				handleCancel()
			} else {
				throw new Error('Response is not ok.')
			}
		} catch (e) {
			console.error(e)
			setLoading(false)
		}
	}

	const renderSelectedPayments = () => {
		return (
			<>
				<Heading style={{ marginTop: 24 }}>
					{intl.formatMessage({
						defaultMessage: 'Kohdista suoritukset laskulle',
						description:
							'Heading for selected payment to be allocated to invoice.',
					})}
				</Heading>
				<HeadingGuide>
					{intl.formatMessage({
						defaultMessage: 'Poista valinta klikkaamalla nimeä',
						description: 'HeadingGuide for invoice payments.',
					})}
				</HeadingGuide>
				<Content>
					<DataTable plain>
						<TableHeader>
							<TableRow>
								<TableColumn>
									{intl.formatMessage({
										defaultMessage: 'Maksaja',
										description:
											'Table header for invoice payment allocation: Payer.',
									})}
								</TableColumn>

								<TableColumn>
									{intl.formatMessage({
										defaultMessage: 'Maksun summa',
										description:
											'Table header for invoice payment allocation: Payment amount.',
									})}
								</TableColumn>
								<TableColumn>
									{intl.formatMessage({
										defaultMessage: 'Maksusta kohdistettu summa',

										description:
											'Table header for invoice payment allocation: Payment allocated amount.',
									})}
								</TableColumn>
								<TableColumn>
									{intl.formatMessage({
										defaultMessage: 'Kohdistettavissa oleva summa',
										description:
											'Table header for invoice payment allocation: Payment available amount.',
									})}
								</TableColumn>
								<TableColumn>
									{intl.formatMessage({
										defaultMessage: 'Kohdista laskulle',

										description:
											'Table header for invoice payment allocation: Allocate to invoice.',
									})}
								</TableColumn>
							</TableRow>
						</TableHeader>
						<TableBody>
							{selectedPayments.map((payment) => {
								const { uuid, partyName, total, invoiceAllocations, amount } =
									payment
								const allocationsTotal = invoiceAllocations.reduce(
									(acc, { total }) => acc + total,
									0,
								)
								const availableToAllocate = total - allocationsTotal
								return (
									<TableRow key={uuid}>
										<TableColumn onClick={() => unSelectPayment(payment)}>
											{partyName}
										</TableColumn>
										<TableColumn>{formatCurrency(total)}</TableColumn>
										<TableColumn>
											{formatCurrency(allocationsTotal)}
										</TableColumn>
										<TableColumn>
											{formatCurrency(availableToAllocate - amount)}
										</TableColumn>
										<TableColumn>
											<TextField
												id={'payment-amount-input' + uuid}
												type="number"
												min={0}
												required
												value={removeLeadingZero(amount)}
												onChange={(value) => {
													const noLeadingZeroValue = Number(
														removeLeadingZero(value),
													) // Remove leading zeros
													setSelectedPayments((prev) =>
														prev.map((a) => {
															if (a.uuid === uuid) {
																if (
																	availableToAllocate - noLeadingZeroValue <
																	0
																) {
																	const alertMSG = intl.formatMessage({
																		defaultMessage:
																			'Syötetty summa ylittää suorituksella vapaana olevan kohdistettavan määrän',
																		description:
																			'The entered amount exceeds the available amount that can be allocated with the payment.',
																	})
																	alert(alertMSG)
																}
																return {
																	...a,
																	amount: noLeadingZeroValue,
																}
															}
															return a
														}),
													)
												}}
											/>
										</TableColumn>
									</TableRow>
								)
							})}
						</TableBody>
					</DataTable>
				</Content>
			</>
		)
	}

	const renderPayments = () => {
		return (
			<>
				<Heading style={{ marginTop: 24 }}>
					{intl.formatMessage({
						defaultMessage: 'Kohdistettavissa olevat suoritukset',
						description: 'Heading for invoice payments: Allocatable payments.',
					})}
				</Heading>
				<HeadingGuide>
					{intl.formatMessage({
						defaultMessage: 'Valitse kohdistettava suoritus klikkaamalla riviä',
						description: 'Select the payment to allocate by clicking the row.',
					})}
				</HeadingGuide>
				<Content>
					{/* //TODO: Filtering of payments may be required here
					<SelectField
				id="payment-contracts-select"
				style={{ width: '100%' }}
				label={intl.formatMessage({
					defaultMessage: 'Sopimukset',
					//TODO:
					description:
						'Label for the contract select in the finance payment form.',
				})}
				simplifiedMenu={false}
				menuItems={contractOptions}
				required
				value={selectedContract}
				onChange={(value) => {
					setSelectedContract(value.toString())
				}}
			/>*/}
					<DataTable plain>
						<TableHeader>
							<TableRow>
								<TableColumn>
									{intl.formatMessage({
										defaultMessage: 'Maksaja',
										description:
											'Table header for invoice payment allocation: Payer.',
									})}
								</TableColumn>

								<TableColumn>
									{intl.formatMessage({
										defaultMessage: 'Maksun summa',
										description:
											'Table header for invoice payment allocation: Payment amount.',
									})}
								</TableColumn>
								<TableColumn>
									{intl.formatMessage({
										defaultMessage: 'Maksusta kohdistettu summa',

										description:
											'Table header for invoice payment allocation: Payment allocated amount.',
									})}
								</TableColumn>
								<TableColumn>
									{intl.formatMessage({
										defaultMessage: 'Kohdistettavissa oleva summa',
										description:
											'Table header for invoice payment allocation: Payment available amount.',
									})}
								</TableColumn>
							</TableRow>
						</TableHeader>
						<TableBody>
							{payments
								.filter((payment) => {
									if (selectedPayments.some((s) => s.uuid === payment.uuid)) {
										return false
									}

									if (
										invoice.invoiceAllocations.some(
											(i) => i.paymentUUID === payment.uuid,
										)
									) {
										return false
									}

									return true
								})
								.map((payment) => {
									const { uuid, partyName, total, invoiceAllocations } = payment
									const allocationsTotal = invoiceAllocations.reduce(
										(acc, { total }) => acc + total,
										0,
									)

									const availableToAllocate = total - allocationsTotal
									return (
										<TableRow key={uuid} onClick={() => selectPayment(payment)}>
											<TableColumn>{partyName}</TableColumn>
											<TableColumn>{formatCurrency(total)}</TableColumn>
											<TableColumn>
												{formatCurrency(allocationsTotal)}
											</TableColumn>
											<TableColumn>
												{formatCurrency(availableToAllocate)}
											</TableColumn>
										</TableRow>
									)
								})}
						</TableBody>
					</DataTable>
				</Content>
			</>
		)
	}

	const renderActions = () => {
		return (
			<ButtonContainer>
				<Button flat onClick={handleCancel}>
					Peruuta
				</Button>
				{isLoading ? (
					<CircularProgress
						style={{ width: '93px', margin: 0 }}
						id="invoice-payment-allocation-submit-progress"
					/>
				) : (
					<Button flat secondary onClick={handleSubmit}>
						Tallenna
					</Button>
				)}
			</ButtonContainer>
		)
	}
	return (
		<>
			{renderSelectedPayments()}
			{renderPayments()}
			{renderActions()}
		</>
	)
}

export default InvoicePaymentAllocationView
