import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'

import { StyledFontIcon, BaseButton } from './styles'

const StyledButton = styled(BaseButton)`
	background: var(--color-primary-dark);
	color: rgba(255, 255, 255, 0.87);
	font-size: 13px;
`

const SignInButton = ({ onClick }) => {
	return (
		<StyledButton
			raised
			onClick={onClick}
			iconEl={<StyledFontIcon>account_circle</StyledFontIcon>}
		>
			<FormattedMessage
				defaultMessage="Kirjaudu sisään"
				description="Button that selects the login flow"
			/>
		</StyledButton>
	)
}

export default SignInButton
