import useDialogs from 'ui/components/dialogs/useDialogs'

function WithDialogs(Component) {
	return function WithDialogsComponent(props) {
		const { confirm, alert, prompt } = useDialogs()
		return (
			<Component {...props} confirm={confirm} alert={alert} prompt={prompt} />
		)
	}
}

export default WithDialogs
