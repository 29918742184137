import React, { useEffect, useState } from 'react'
import { collection, query, where, getDocs } from 'firebase/firestore'
import { useSelector } from 'react-redux'

import { firestore } from 'state/store'
import LoadingState from './LoadingState'
import { useIsSuperAdmin } from 'ui/components/userRoles/hooks'

const HOC =
	({ hideLoading = false }) =>
	(WrappedComponent) => {
		const WithSelectedManager = (props) => {
			const [managers, setManagers] = useState(null)
			const uid = useSelector((state) => state.firebaseReducer.auth?.uid)
			const selectedManager = props.selectedManager
			const isSuperAdmin = useIsSuperAdmin()

			useEffect(() => {
				const doQuery = async (q) => {
					const querySnapshot = await getDocs(q)
					const managers = []
					querySnapshot.forEach((m) => managers.push(m.data()))
					setManagers(managers)
				}
				if (uid && !selectedManager) {
					if (isSuperAdmin) {
						doQuery(query(collection(firestore, 'manager')))
					} else {
						doQuery(
							query(
								collection(firestore, 'manager'),
								where('userUUIDs', 'array-contains', uid),
							),
						)
					}
				}
			}, [uid, isSuperAdmin]) // eslint-disable-line react-hooks/exhaustive-deps

			const selectedManagerUUID = useSelector(
				(state) => state.app.selectedManagerUUID,
			)

			if (!uid) {
				return <WrappedComponent {...props} selectedManager={null} />
			}

			if (selectedManager) {
				// Use the existing value if we have it
				return <WrappedComponent {...props} selectedManager={selectedManager} />
			}

			if (!managers) {
				if (hideLoading) {
					return null
				}
				return <LoadingState small />
			}

			let filteredManagers = []
			if (isSuperAdmin) {
				filteredManagers = managers
			} else {
				filteredManagers = managers.filter((m) => m.userUUIDs.includes(uid))
			}

			if (filteredManagers.length === 0) {
				return <WrappedComponent {...props} selectedManager={null} />
			}

			if (selectedManagerUUID) {
				const found = filteredManagers.find(
					(m) => m.uuid === selectedManagerUUID && !m.disabled,
				)
				if (!found) {
					return null
				}
				return <WrappedComponent {...props} selectedManager={found} />
			}
			return (
				<WrappedComponent {...props} selectedManager={filteredManagers[0]} />
			)
		}
		return WithSelectedManager
	}

export default HOC
