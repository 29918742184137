import { useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import LoadingState from 'ui/components/LoadingState'
import useAccountsLedger from './useAccountsLedger'
import type { AccountsLedgerFilters } from './types'
import AccountsLedgersTable from './AccountsLedgersTable'
import Filters from './Filters'
import AccountLedgerDialog from './AccountLedgerDialog'
import { push } from 'connected-react-router'
import PaymentDialog from '../Payments/PaymentDialog'
import InvoiceDialog from '../Invoices/InvoiceDialog'
import useContracts from '../Contracts/useContracts'
import { createApartmentOptions } from '../utils/apartmentOptions'
import useCompanyApartments from 'util/hooks/useCompanyApartments'
import { useBuildings } from 'ui/CompanyBuildings/hooks'
import { createContractOptions } from '../utils/contractOptions'
import useValueAddedTaxes from '../ValueAddedTax/useValueAddedTaxes'
import { createVatOptions } from '../utils/vatOptions'
import { AccountsLedgerGroupBy, AccountsLedgerView } from '../enum'
import {
	AccountsLedgerGroupByMessage,
	AccountsLedgerViewMessage,
} from '../utils/messages'
import type { SelectOptions } from '../types'

const Container = styled.div`
	padding: 16px;
`

const LoadingContainer = styled.div`
	margin-top: 64px;
`

const NotFound = styled.p`
	margin-top: 16px;
`

function InvoiceDialogWrapper({ invoiceUUID }: { invoiceUUID: string }) {
	const dispatch = useDispatch()
	const selectedFinanceCompanyUUID = useSelector(
		({ app }: any) => app?.selectedFinanceCompanyUUID,
	)

	const { companyApartments } = useCompanyApartments(selectedFinanceCompanyUUID)
	const { buildings } = useBuildings(selectedFinanceCompanyUUID)
	const apartmentOptions = createApartmentOptions(companyApartments, buildings)

	const { valueAddedTaxes } = useValueAddedTaxes()
	const vatOptions = createVatOptions(valueAddedTaxes || [])

	return (
		<InvoiceDialog
			invoiceUUID={invoiceUUID}
			apartmentOptions={apartmentOptions}
			vatOptions={vatOptions}
			onHide={() => {
				const URLParams = new URLSearchParams(window.location.search)
				URLParams.delete('invoice_uuid')
				dispatch(push('?' + URLParams.toString()))
			}}
		/>
	)
}

function PaymentDialogWrapper({ paymentUUID }: { paymentUUID: string }) {
	const dispatch = useDispatch()
	const selectedFinanceCompanyUUID = useSelector(
		({ app }: any) => app?.selectedFinanceCompanyUUID,
	)

	const contractFilters = useMemo(
		() => ({
			companyUUID: selectedFinanceCompanyUUID || '',
			apartmentUUID: '',
			reviewDate: new Date(),
			invoicingMethod: '',
			searchWord: '',
		}),
		[selectedFinanceCompanyUUID],
	)

	const { contracts } = useContracts(contractFilters)

	const contractOptions = createContractOptions(contracts)

	return (
		<PaymentDialog
			id={paymentUUID}
			onHide={() => {
				const URLParams = new URLSearchParams(window.location.search)
				URLParams.delete('payment_uuid')
				dispatch(push('?' + URLParams.toString()))
			}}
			contractOptions={contractOptions}
		/>
	)
}

function AccountsLedger() {
	const intl = useIntl()
	const dispatch = useDispatch()
	const selectedFinanceCompanyUUID = useSelector(
		({ app }: any) => app?.selectedFinanceCompanyUUID,
	)

	const [filters, setFilters] = useState<AccountsLedgerFilters>({
		companyUUID: selectedFinanceCompanyUUID || '',
		view: AccountsLedgerView.ACTUALS,
		groupBy: AccountsLedgerGroupBy.APARTMENT,
		unPaidFees: null,
		startDate: null,
		endDate: null,
	})

	useEffect(() => {
		setFilters((prev) => ({ ...prev, companyUUID: selectedFinanceCompanyUUID }))
	}, [selectedFinanceCompanyUUID])

	const { accountsLedger, loadingAccountsLedger } = useAccountsLedger(filters)

	const location = useSelector((state: any) => state.router?.location)
	const params = new URLSearchParams(location.search)
	const apartmentUUID = params.get('uuid')
	const paymentUUID = params.get('payment_uuid')
	const invoiceUUID = params.get('invoice_uuid')

	const view = params.get('view')
	const groupBy = params.get('groupBy')
	const startDate = params.get('startDate')
	const endDate = params.get('endDate')

	const groupByOptions: SelectOptions[] = Object.values(
		AccountsLedgerGroupBy,
	).map((value) => ({
		label: AccountsLedgerGroupByMessage(intl, value),
		value: value,
	}))

	const viewOptions: SelectOptions[] = Object.values(AccountsLedgerView).map(
		(value) => ({
			label: AccountsLedgerViewMessage(intl, value),
			value: value,
		}),
	)

	const onHide = () => {
		dispatch(push(window.location.pathname))
	}

	const renderTable = () => {
		if (loadingAccountsLedger) {
			return (
				<LoadingContainer>
					<LoadingState />
				</LoadingContainer>
			)
		}

		if (!accountsLedger?.length) {
			return (
				<NotFound>
					{intl.formatMessage({
						defaultMessage: 'Reskonta rivejä ei löytynyt.',
						description: 'Message for empty accounts ledger.',
					})}
				</NotFound>
			)
		}

		return (
			<AccountsLedgersTable accountsLedger={accountsLedger} filters={filters} />
		)
	}

	return (
		<Container>
			<Filters
				filters={filters}
				setFilters={setFilters}
				groupByOptions={groupByOptions}
				viewOptions={viewOptions}
			/>

			{renderTable()}
			{!paymentUUID && !invoiceUUID && apartmentUUID && (
				<AccountLedgerDialog
					apartmentUUID={apartmentUUID}
					filters={{
						view: view as AccountsLedgerView,
						groupBy: groupBy as AccountsLedgerGroupBy,
						startDate: startDate,
						endDate: endDate,
					}}
					onHide={onHide}
				/>
			)}
			{paymentUUID && <PaymentDialogWrapper paymentUUID={paymentUUID} />}
			{invoiceUUID && <InvoiceDialogWrapper invoiceUUID={invoiceUUID} />}
		</Container>
	)
}

export default AccountsLedger
