import { useCallback, useContext, useEffect, useRef } from 'react'
import { DialogContext } from './DialogProvider'
import { v4 as uuidV4 } from 'uuid'
import { IntlShape, useIntl } from 'react-intl'

type Options = {
	clearPrevious?: boolean
	type?: 'alert' | 'confirm' | 'prompt'
	title?: any
	message?: any
	inputLabel?: any
	inputPlaceholder?: any
	defaultValue?: any
	cancelLabel?: any
	confirmLabel?: any
	inputMultiline?: boolean
}

const initAlert = (intl: IntlShape) => ({
	type: 'alert',
	title: intl.formatMessage({
		defaultMessage: 'Ilmoitus',
		description: 'Default title for the app alert dialog.',
	}),
	message: '',
	confirmLabel: intl.formatMessage({
		defaultMessage: 'Ok',
		description: 'Default confirm button label for the app alert dialog.',
	}),
})

const initConfirm = (intl: IntlShape) => ({
	type: 'confirm',
	title: intl.formatMessage({
		defaultMessage: 'Vahvista',
		description: 'Default title for the app confirmation dialog.',
	}),
	message: intl.formatMessage({
		defaultMessage: 'Oletko varma?',
		description: 'Default message for the app confirmation dialog.',
	}),
	cancelLabel: intl.formatMessage({
		defaultMessage: 'Peruuta',
		description: 'Default cancel button label for the app confirmation dialog.',
	}),
	confirmLabel: intl.formatMessage({
		defaultMessage: 'Ok',
		description:
			'Default confirm button label for the app confirmation dialog.',
	}),
})

const initPrompt = (intl: IntlShape) => ({
	type: 'prompt',
	title: intl.formatMessage({
		defaultMessage: 'Syöte',
		description: 'Default title for the app prompt dialog.',
	}),
	message: '',
	inputLabel: null,
	inputPlaceholder: null,
	defaultValue: '',
	cancelLabel: intl.formatMessage({
		defaultMessage: 'Peruuta',
		description: 'Default cancel button label for the app prompt dialog.',
	}),
	confirmLabel: intl.formatMessage({
		defaultMessage: 'Ok',
		description: 'Default confirm button label for the app prompt dialog.',
	}),
	inputMultiline: false,
})

function useDialogs() {
	const intl = useIntl()
	const { showDialog, cleanUpDialogs } = useContext(DialogContext)
	const shownKeys = useRef<string[]>([])

	useEffect(() => {
		const keys = shownKeys.current
		return () => {
			cleanUpDialogs(keys)
		}
	}, [cleanUpDialogs])

	const show = useCallback(
		(defaults, message: string | null, options: Options) => {
			const key = uuidV4()

			if (options?.clearPrevious && shownKeys.current.length > 0) {
				cleanUpDialogs(shownKeys.current)
				shownKeys.current = []
			}

			const dialog = {
				...defaults,
				...options,
				message: message || defaults.message,
				key,
			}

			shownKeys.current.push(key)

			return showDialog(dialog)
		},
		[showDialog, cleanUpDialogs],
	)

	const alert = useCallback(
		(message: string | null, options: Options = {}) => {
			return show(initAlert(intl), message, options)
		},
		[show, intl],
	)

	const confirm = useCallback(
		(message: string | null, options: Options = {}) => {
			return show(initConfirm(intl), message, options)
		},
		[show, intl],
	)

	const prompt = useCallback(
		(message: string | null, options: Options = {}) => {
			return show(initPrompt(intl), message, options)
		},
		[show, intl],
	)

	return {
		alert,
		confirm,
		prompt,
	}
}

export default useDialogs
