import { bindActionCreators, compose } from 'redux'
import styled from 'styled-components'
import WithSelectedCompany from 'ui/components/WithSelectedCompany'

import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import * as notifyActions from 'state/notifyActions'
import Actions from 'ui/MessageService/MessageServiceProcess/Actions'
import { Checkbox, Radio } from 'react-md'
import { StepContainer } from 'ui/MessageService/MessageServiceProcess/steps/styled'
import config from 'config'

const Content = styled.div`
	padding: 0px 32px;
`

const StepTitle = styled.h1`
	font-size: 1.8rem;
	margin: 16px 16px 0px 16px;
`

function Recipients({
	stepTitle,
	messageServiceProcess,
	setMessageServiceProcess,
	onPreviousClick,
	onDraftClick,
	onNextClick,
	intl,
	_showInfoNotification,
}) {
	const setRespectDigitalDeliveryConsent = (checked) => {
		setMessageServiceProcess((prev) => ({
			...prev,
			respectDigitalDeliveryConsent: checked,
		}))
	}

	const setRecipientsSource = (source) => {
		setMessageServiceProcess((prev) => ({
			...prev,
			recipientSource: source,
		}))
	}

	const setRecipientRoles = (role, checked) => {
		setMessageServiceProcess((prev) => ({
			...prev,
			recipientRoles: {
				...prev.recipientRoles,
				[role]: checked,
			},
		}))
	}

	const validateNext = () => {
		const source = messageServiceProcess.recipientSource
		const roles = Object.values(
			messageServiceProcess.recipientRoles || {},
		).some((checked) => checked)

		return source && roles ? onNextClick : null
	}

	const showDigitalDeliverySetting =
		messageServiceProcess.deliveryMethods.includes('email') &&
		messageServiceProcess.deliveryMethods.includes('letter')

	const roles = config.userRoles

	return (
		<>
			<StepTitle>{stepTitle}</StepTitle>

			<StepContainer>
				<Content>
					{showDigitalDeliverySetting && (
						<>
							<p>Sähköinen osakaspostitus</p>
							<Checkbox
								id="checkbox-digital-delivery-consents"
								label={
									'Käytä sähköisen osakaspostituksen suostumusta vähentämään kirjepostin määrää.'
								}
								name="respect-digital-delivery-consent"
								checked={messageServiceProcess.respectDigitalDeliveryConsent}
								onChange={(checked) =>
									setRespectDigitalDeliveryConsent(checked)
								}
							/>
						</>
					)}
					<p style={{ marginTop: 16 }}>Mistä haluat tuoda vastaanottajat?</p>
					<Radio
						id="radio-recipient-source-viilu"
						label={'Viilu'}
						name="Viilu"
						value="Viilu"
						checked={messageServiceProcess.recipientSource === 'Viilu'}
						onChange={(checked) => setRecipientsSource(checked)}
					/>
					<Radio
						id="radio-recipient-source-fivaldi"
						label={'Fivaldi'}
						name="Fivaldi"
						value="Fivaldi"
						checked={messageServiceProcess.recipientSource === 'Fivaldi'}
						onChange={(checked) => setRecipientsSource(checked)}
						disabled
					/>

					<p style={{ marginTop: 16 }}>Kenelle lähetetään?</p>
					<Checkbox
						id="checkbox-recipient-roles-tenants"
						label={'Asukkaat'}
						name="Asukkaat"
						checked={messageServiceProcess.recipientRoles?.user_tenant}
						onChange={(checked) => setRecipientRoles(roles.userTenant, checked)}
					/>
					<Checkbox
						id="checkbox-recipient-roles-shareholders"
						label={'Osakkaat'}
						name="Osakkaat"
						checked={messageServiceProcess.recipientRoles?.user_shareholder}
						onChange={(checked) =>
							setRecipientRoles(roles.userShareholder, checked)
						}
					/>
					{messageServiceProcess.recipientSource === 'Viilu' && (
						<>
							<Checkbox
								id="checkbox-recipient-roles-board"
								label={'Hallitus'}
								name="Hallitus"
								checked={
									messageServiceProcess.recipientRoles.admin_board_member &&
									messageServiceProcess.recipientRoles.admin_board_deputy &&
									messageServiceProcess.recipientRoles.admin_board_director
								}
								onChange={(checked) => {
									setRecipientRoles(roles.adminBoardMember, checked)
									setRecipientRoles(roles.adminBoardDeputy, checked)
									setRecipientRoles(roles.adminBoardDirector, checked)
								}}
							/>
							<Checkbox
								id="checkbox-recipient-roles-board-director"
								label={'Hallituksen puheenjohtajat'}
								name="Hallituksen puheenjohtajat"
								checked={
									messageServiceProcess.recipientRoles.admin_board_director
								}
								onChange={(checked) => {
									setRecipientRoles(roles.adminBoardDirector, checked)
								}}
							/>
							<Checkbox
								id="checkbox-recipient-roles-auditor-operations"
								label={'Toiminnantarkastajat'}
								name="Toiminnantarkastajat"
								checked={
									messageServiceProcess.recipientRoles?.auditor_operations
								}
								onChange={(checked) =>
									setRecipientRoles(roles.auditorOperations, checked)
								}
							/>
							<Checkbox
								id="checkbox-recipient-roles-auditor-accounting"
								label={'Tilintarkastajat'}
								name="Tilintarkastajat"
								checked={
									messageServiceProcess.recipientRoles?.auditor_accounting
								}
								onChange={(checked) =>
									setRecipientRoles(roles.auditorAccounting, checked)
								}
							/>
							<Checkbox
								id="checkbox-recipient-roles-assigned-manager-employee"
								label={'Kohdeisännöitsijä'}
								name="Kohdeisännöitsijä"
								checked={
									messageServiceProcess.recipientRoles
										?.assigned_manager_employee
								}
								onChange={(checked) =>
									setRecipientRoles('assigned_manager_employee', checked)
								}
							/>
							<Checkbox
								id="checkbox-recipient-roles-maintenance-company"
								label={'Kohteen huoltoyhtiö'}
								name="Kohteen huoltoyhtiö"
								checked={
									messageServiceProcess.recipientRoles?.maintenance_company
								}
								onChange={(checked) =>
									setRecipientRoles('maintenance_company', checked)
								}
							/>
						</>
					)}
				</Content>
			</StepContainer>

			<Actions
				onPreviousClick={onPreviousClick}
				onDraftClick={onDraftClick}
				onNextClick={validateNext()}
			/>
		</>
	)
}

const mapState = () => ({})

const mapDispatchToProps = (dispatch) => ({
	_showInfoNotification: bindActionCreators(notifyActions.info, dispatch),
})

export default compose(
	injectIntl,
	WithSelectedCompany,
	connect(mapState, mapDispatchToProps),
)(Recipients)
