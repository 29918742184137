import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Drawer, ListItem, FontIcon, Divider } from 'react-md'
import tinycolor from 'tinycolor2'

import { push } from 'connected-react-router'

import routes from '../../routes'
import { compose, bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Img } from 'react-image'

import * as appUIActions from 'state/ui/app-ui-actions'

import WithSelectedCompany from 'ui/components/WithSelectedCompany'
import WithSelectedCompanyRole from 'ui/components/WithSelectedCompanyRole'
import WithSelectedCompanyManager from 'ui/components/WithSelectedCompanyManager'
import WithUserRoles from 'ui/components/userRoles/WithUserRoles'
import WithQueryParams from 'ui/components/WithQueryParams'
import {
	CardImgSkeletonAbsolute,
	CardImgSkeletonAbsoluteLoading,
} from 'ui/StyledComponents/Cards'
import {
	DrawerLogoContainer,
	DrawerMenuItemText,
	DrawerMenuScrollContainer,
	DrawerMenuLogo,
} from './styles'
import ViiluLogo from 'svg/viilu-logo-white.svg'
import { FormattedMessage, injectIntl } from 'react-intl'
import { getSetting } from 'util/companyModuleSettings'
import ManagerCompanyQuickSwitch from 'ui/ManagerCompanyQuickSwitch'
import WithSelectedCompanyManagerRole from 'ui/components/WithSelectedCompanyManagerRole'
import WithDialogs from 'ui/components/dialogs/WithDialogs'
import config from 'config'

const ROLES = config.userRoles

class AppDrawer extends Component {
	state = {
		boardItemsExpanded: false,
	}

	componentDidMount = () => {
		const { _setNavDrawerVisible, isDesktop, drawerVisible } = this.props
		const isMeetingRoute = this.isMeetingRoute()

		if (drawerVisible === null) {
			if (!isMeetingRoute) {
				_setNavDrawerVisible(isDesktop)
			}
		}
	}

	isMeetingRoute = () => {
		const { currentRoute } = this.props
		return currentRoute.relativePath === '/meeting'
	}

	isManagerOrSuperAdmin = () => {
		const { auth, isManager, isSuperAdmin } = this.props
		if (!auth) {
			return false
		}
		return isManager || isSuperAdmin
	}

	getMeetingExitMessage = () =>
		this.props.intl.formatMessage({
			defaultMessage:
				'Toiselle sivulle siirtyminen poistaa sinut kokouksesta. Haluatko edelleen siirtyä?',
			description:
				'Confirmation asking the user if they really want to navigate out of the meeting.',
		})

	openMenuItem = async (currentRoute, path) => {
		const { selectedCompany, _push, isDesktop, _setNavDrawerVisible, confirm } =
			this.props
		const isMeetingRoute = this.isMeetingRoute()

		if (isMeetingRoute) {
			if (!(await confirm(this.getMeetingExitMessage()))) {
				return
			}
		}

		if (!isDesktop) {
			_setNavDrawerVisible(false)
		}

		if (!selectedCompany || !selectedCompany.uuid) {
			return _push('/')
		}

		if (!path.includes('internal/')) {
			return _push('/' + selectedCompany.urlName + path)
		}

		_push(path)
	}

	filterByCategory = (route, category) => {
		const {
			selectedCompany,
			auth,
			currentRoute,
			selectedCompanyRole,
			selectedCompanyManager,
			intl,
		} = this.props
		if (route.hidden || route.category !== category) return null
		// accounting auditors have access to docs only
		const disabled = !!(
			(auth.isEmpty && !route.public) ||
			!selectedCompany ||
			!selectedCompany.uuid ||
			route.disabled ||
			((!selectedCompanyRole || selectedCompanyRole === ROLES.none) &&
				!route.public) ||
			selectedCompanyRole === ROLES.auditorAccounting
		)
		const current = route.path === currentRoute.path

		if (
			selectedCompany &&
			selectedCompany.featuresHidden &&
			selectedCompany.featuresHidden[route.feature]
		) {
			return null
		}

		let selectedColor
		if (category === 'board') {
			if (
				selectedCompanyManager &&
				selectedCompanyManager.companyDrawerDefaultColorBoard
			) {
				selectedColor = selectedCompanyManager.companyDrawerDefaultColorBoard
			} else {
				selectedColor = 'var(--color-secondary-dark)'
			}
		} else if (selectedCompany && selectedCompany.color) {
			selectedColor = tinycolor(selectedCompany.color).darken().toString()
		} else {
			if (
				selectedCompanyManager &&
				selectedCompanyManager.companyDrawerDefaultColor
			) {
				selectedColor = selectedCompanyManager.companyDrawerDefaultColor
			} else {
				selectedColor = 'var(--color-primary-dark)'
			}
		}

		return (
			<ListItem
				key={'menuItem-' + route.path}
				leftIcon={
					route.icon ? (
						<FontIcon
							style={{ color: 'rgba(255,255,255,0.5)', marginRight: -16 }}
						>
							{route.icon}
						</FontIcon>
					) : undefined
				}
				style={{ background: current ? 'rgba(0,0,0,0.15)' : selectedColor }}
				primaryText={
					<DrawerMenuItemText>
						{typeof route.title === 'function'
							? route.title(intl)
							: route.title}
					</DrawerMenuItemText>
				}
				primaryTextStyle={
					disabled
						? {
								textDecoration: 'line-through',
								fontWeight: 400,
								color: 'white',
						  }
						: {
								color: 'white',
						  }
				}
				onClick={() =>
					this.openMenuItem(currentRoute, route.relativePath || route.path)
				}
				disabled={disabled}
			/>
		)
	}

	renderDocumentsItem = (routes, navStyle) => {
		const {
			selectedCompany,
			auth,
			selectedCompanyRole,
			selectedCompanyManager,
			currentRoute,
			_push,
			_setNavDrawerVisible,
			isDesktop,
			confirm,
		} = this.props
		const { documentsItemsExpanded } = this.state
		const isMeetingRoute = this.isMeetingRoute()

		if (
			selectedCompany &&
			selectedCompany.featuresHidden &&
			selectedCompany.featuresHidden['documents']
		) {
			return null
		}

		let companyColor
		if (selectedCompany && selectedCompany.color) {
			companyColor = tinycolor(selectedCompany.color).darken().toString()
		} else {
			if (
				selectedCompanyManager &&
				selectedCompanyManager.companyDrawerDefaultColor
			) {
				companyColor = selectedCompanyManager.companyDrawerDefaultColor
			} else {
				companyColor = 'var(--color-primary-dark)'
			}
		}

		if (
			selectedCompany &&
			selectedCompany.features &&
			selectedCompany.features['documents']
		) {
			// Documents v1 setting makes us show the old experience
			const v1Enabled = getSetting(
				selectedCompany,
				'documents',
				'companyDocumentsV1',
			)
			if (v1Enabled) {
				const documentsRoutes = routes.filter((r) => r.category === 'documents')
				const dosumentsItems = documentsRoutes
					.map((route) => this.filterByCategory(route, 'documents'))
					.concat(<Divider key={'documents-divider'} />)

				return (
					<ListItem
						key="menuItem-documents"
						primaryText={
							<DrawerMenuItemText>
								<FormattedMessage
									defaultMessage="Asiakirjat"
									description="Button that allows the user to navigate to the documents senction of the app."
								/>
							</DrawerMenuItemText>
						}
						primaryTextStyle={
							!selectedCompany
								? { textDecoration: 'line-through', color: 'white' }
								: { fontWeight: 700 }
						}
						nestedItems={dosumentsItems}
						leftIcon={
							<FontIcon
								style={{ color: 'rgba(255,255,255,0.5)', marginRight: -16 }}
							>
								folder
							</FontIcon>
						}
						nestedListStyle={{
							...navStyle,
							paddingLeft: 16,
						}}
						onClick={() =>
							this.setState({ documentsItemsExpanded: !documentsItemsExpanded })
						}
					/>
				)
			}

			const disabled = !!(
				auth.isEmpty ||
				!selectedCompany ||
				!selectedCompany.uuid ||
				!selectedCompanyRole ||
				selectedCompanyRole === ROLES.none
			)

			const current =
				currentRoute &&
				currentRoute.path &&
				currentRoute.path.includes('documents')

			return (
				<ListItem
					key="menuItem-documents"
					primaryText={
						<DrawerMenuItemText>
							<FormattedMessage
								defaultMessage="Asiakirjat"
								description="Button that allows the user to navigate to the documents senction of the app."
							/>
						</DrawerMenuItemText>
					}
					primaryTextStyle={
						disabled
							? {
									textDecoration: 'line-through',
									fontWeight: 400,
									color: 'white',
							  }
							: {
									color: 'white',
							  }
					}
					leftIcon={
						<FontIcon
							style={{ color: 'rgba(255,255,255,0.5)', marginRight: -16 }}
						>
							folder
						</FontIcon>
					}
					onClick={async () => {
						if (isMeetingRoute) {
							if (!(await confirm(this.getMeetingExitMessage()))) {
								return
							}
						}
						if (!isDesktop) {
							_setNavDrawerVisible(false)
						}
						_push('/' + selectedCompany.urlName + '/documents_v2_hub')
					}}
					disabled={disabled}
					style={{ background: current ? 'rgba(0,0,0,0.15)' : companyColor }}
				/>
			)
		}

		// TODO: this can be removed once all companies have been migrated to use the modules
		const documentsRoutes = routes.filter((r) => r.category === 'documents')
		const dosumentsItems = documentsRoutes
			.map((route) => this.filterByCategory(route, 'documents'))
			.concat(<Divider key={'documents-divider'} />)

		return (
			<ListItem
				key="menuItem-documents"
				primaryText={
					<DrawerMenuItemText>
						<FormattedMessage
							defaultMessage="Asiakirjat"
							description="Button that allows the user to navigate to the documents senction of the app."
						/>
					</DrawerMenuItemText>
				}
				primaryTextStyle={
					!selectedCompany
						? { textDecoration: 'line-through', color: 'white' }
						: { fontWeight: 700 }
				}
				nestedItems={dosumentsItems}
				leftIcon={
					<FontIcon
						style={{ color: 'rgba(255,255,255,0.5)', marginRight: -16 }}
					>
						folder
					</FontIcon>
				}
				nestedListStyle={{
					...navStyle,
					paddingLeft: 16,
				}}
				onClick={() =>
					this.setState({ documentsItemsExpanded: !documentsItemsExpanded })
				}
			/>
		)
	}

	renderBoardItems = (routes, navStyle) => {
		const {
			selectedCompany,
			selectedCompanyManager,
			intl,
			isMaintenanceCompany,
		} = this.props
		const { boardItemsExpanded } = this.state

		let boardRoutes = routes.filter((r) => r.category === 'board')

		if (isMaintenanceCompany) {
			boardRoutes = boardRoutes.filter(
				(route) => route.maintenanceCompanyHasAccess === true,
			)
		}

		const boardItems = boardRoutes.map((route) =>
			this.filterByCategory(route, 'board'),
		)

		let backgroundColor
		if (
			selectedCompanyManager &&
			selectedCompanyManager.companyDrawerDefaultColorBoard
		) {
			backgroundColor = selectedCompanyManager.companyDrawerDefaultColorBoard
		} else {
			backgroundColor = 'var(--color-secondary-dark)'
		}

		return (
			<ListItem
				key="menuItem-board"
				primaryText={
					<DrawerMenuItemText>
						<FormattedMessage
							defaultMessage="Hallintotyökalut"
							description="Button that allows the user to open the management tools section of the app."
						/>
					</DrawerMenuItemText>
				}
				primaryTextStyle={
					!selectedCompany
						? { textDecoration: 'line-through', color: 'white' }
						: { fontWeight: 700 }
				}
				secondaryText={boardRoutes
					.map((r) => (typeof r.title === 'function' ? r.title(intl) : r.title))
					.join(', ')}
				secondaryTextStyle={{
					color: boardItemsExpanded ? 'transparent' : 'white',
				}}
				nestedItems={boardItems}
				style={{ background: backgroundColor }}
				nestedListStyle={{
					...navStyle,
					paddingLeft: 16,
					background: backgroundColor,
				}}
				onClick={() =>
					this.setState({ boardItemsExpanded: !boardItemsExpanded })
				}
			/>
		)
	}

	renderManagerItems = (routes, navStyle) => {
		const { _push, confirm } = this.props

		return (
			<ListItem
				key="menuItem-manager"
				primaryText={
					<DrawerMenuItemText>
						<FormattedMessage
							defaultMessage="Isännöinti"
							description="Button that takes the manager to the manager tools section."
						/>
					</DrawerMenuItemText>
				}
				leftIcon={
					<FontIcon
						style={{ color: 'rgba(255,255,255,0.5)', marginRight: -16 }}
					>
						work
					</FontIcon>
				}
				onClick={async () => {
					const isMeetingRoute = this.isMeetingRoute()
					if (isMeetingRoute) {
						if (!(await confirm(this.getMeetingExitMessage()))) {
							return
						}
					}
					_push('/manager')
				}}
				style={{ background: '#385167' }}
			/>
		)
	}

	render() {
		const {
			isDesktop,
			selectedCompany,
			currentRoute,
			selectedCompanyRole,
			selectedCompanyManager,
			_push,
			queryParams: { experience, e },
			selectedManager,
			drawerVisible,
			intl,
			_setNavDrawerVisible,
			confirm,
		} = this.props
		const isMeetingRoute = this.isMeetingRoute()

		if (
			experience === 'lean' ||
			e === 'l' ||
			currentRoute.lean ||
			window.location.href.includes('id_callback')
		) {
			return null
		}

		let companyColor
		if (selectedCompany && selectedCompany.color) {
			companyColor = tinycolor(selectedCompany.color).darken().toString()
		} else {
			if (
				selectedCompanyManager &&
				selectedCompanyManager.companyDrawerDefaultColor
			) {
				companyColor = selectedCompanyManager.companyDrawerDefaultColor
			} else {
				companyColor = 'var(--color-primary-dark)'
			}
		}

		const navStyle = {
			background: companyColor,
			margin: 0,
			listStyleType: 'none',
		}

		if (isDesktop && drawerVisible) {
			navStyle.position = 'relative'
		}

		let headerItem = [
			<DrawerLogoContainer key="company-drawer-menu-logo">
				<div
					onClick={async () => {
						if (selectedCompany && selectedCompany.urlName) {
							if (isMeetingRoute) {
								if (!(await confirm(this.getMeetingExitMessage()))) {
									return
								}
							}
							if (!isDesktop) {
								_setNavDrawerVisible(false)
							}
							return _push('/' + selectedCompany.urlName)
						}
					}}
					style={{ display: 'flex', textDecoration: 'none' }}
				>
					<DrawerMenuLogo />
					<img src={ViiluLogo} alt="viilu" width={60} height={30} />
				</div>
				<div
					className="flex-center margin-left margin-right"
					onClick={() => _setNavDrawerVisible(false)}
					style={{
						background: 'rgba(0,0,0,0.1)',
						borderRadius: 8,
						borderColor: 'rgba(0,0,0,0.1)',
						borderWidth: 1,
						borderStyle: 'solid',
						cursor: 'pointer',
					}}
				>
					<FontIcon style={{ color: 'white' }}>chevron_left</FontIcon>
				</div>
			</DrawerLogoContainer>,
		]

		if (selectedCompanyManager) {
			const {
				name,
				logo,
				drawerLogo,
				drawerLogoAlignment = 'center',
				whitelabelManager: whitelabel,
			} = selectedCompanyManager

			if (whitelabel && (logo || drawerLogo)) {
				const { fileURL } = drawerLogo || logo
				headerItem = [
					<div key="menuItem-header" style={{ position: 'relative' }}>
						{!fileURL ? null : (
							<div
								style={{
									width: 'calc(100% + 64px)',
									background: 'white',
									display: 'flex',
									marginLeft: drawerLogoAlignment === 'center' ? -32 : 0,
									marginRight: drawerLogoAlignment === 'center' ? -32 : 0,
									height: 56,
								}}
							>
								<Img
									alt={name}
									src={fileURL}
									loader={
										<CardImgSkeletonAbsolute>
											<CardImgSkeletonAbsoluteLoading />
										</CardImgSkeletonAbsolute>
									}
									unloader={
										<CardImgSkeletonAbsolute>
											<p className="text-subtle">Kuvaa ei löydy</p>
										</CardImgSkeletonAbsolute>
									}
									style={{
										height: 56,
										maxWidth: '100%',
										marginLeft: drawerLogoAlignment === 'center' ? 'auto' : 0,
										marginRight: drawerLogoAlignment === 'center' ? 'auto' : 0,
									}}
								/>
								<div
									className="flex-center margin-left margin-right"
									onClick={() => _setNavDrawerVisible(false)}
									style={{
										position: 'absolute',
										right: 0,
										top: 20,
										background: 'rgba(0,0,0,0.2)',
										borderRadius: 8,
										borderColor: 'rgba(0,0,0,0.1)',
										borderWidth: 1,
										borderStyle: 'solid',
										cursor: 'pointer',
										height: 22,
									}}
								>
									<FontIcon style={{ color: 'white' }}>chevron_left</FontIcon>
								</div>
							</div>
						)}
					</div>,
				]
			}
		} else if (selectedCompanyManager === undefined) {
			// we are still loading the manager info
			headerItem = [
				<div key="menuItem-header" style={{ position: 'relative' }}>
					<div
						style={{
							width: 'calc(100% + 64px)',
							background: 'transparent',
							display: 'flex',
							marginLeft: -32,
							marginRight: -32,
							height: 56,
						}}
					>
						<div
							className="flex-center margin-left margin-right"
							onClick={() => _setNavDrawerVisible(false)}
							style={{
								position: 'absolute',
								right: 0,
								top: 20,
								background: 'rgba(0,0,0,0.2)',
								borderRadius: 8,
								borderColor: 'rgba(0,0,0,0.1)',
								borderWidth: 1,
								borderStyle: 'solid',
								cursor: 'pointer',
								height: 22,
							}}
						>
							<FontIcon style={{ color: 'white' }}>chevron_left</FontIcon>
						</div>
					</div>
				</div>,
			]
		}

		const companyItem = (
			<ListItem
				key="menuItem-company"
				primaryText={
					<DrawerMenuItemText>
						{selectedCompany && !this.isManagerOrSuperAdmin()
							? selectedCompany.name
							: intl.formatMessage({
									defaultMessage: 'Talosivu',
									description: 'Company drawer landing page header.',
							  })}
					</DrawerMenuItemText>
				}
				onClick={async () => {
					if (selectedCompany && selectedCompany.urlName) {
						if (isMeetingRoute) {
							if (!(await confirm(this.getMeetingExitMessage()))) {
								return
							}
						}
						if (!isDesktop) {
							_setNavDrawerVisible(false)
						}
						return _push('/' + selectedCompany.urlName)
					}
				}}
				leftIcon={
					<FontIcon
						style={{ color: 'rgba(255,255,255,0.5)', marginRight: -16 }}
					>
						home
					</FontIcon>
				}
				disabled={!selectedCompany}
				primaryTextStyle={
					!selectedCompany
						? {
								textDecoration: 'line-through',
								color: 'white',
						  }
						: undefined
				}
				style={{
					background:
						currentRoute && currentRoute.relativePath === '/'
							? 'rgba(0,0,0,0.15)'
							: companyColor,
				}}
			/>
		)

		const actionItems = routes
			.map((route) => this.filterByCategory(route, 'actions'))
			.filter((m) => m)

		const documentsItem = this.renderDocumentsItem(routes, navStyle)
		const boardItem = this.renderBoardItems(routes, navStyle)
		const managerItem = this.renderManagerItems(routes, navStyle)

		const companyQuickSelectItem = this.isManagerOrSuperAdmin() ? (
			<div
				key="menuItem-quickswitch"
				style={{ height: 46, margin: -2, marginRight: 2, marginBottom: -4 }}
			>
				<ManagerCompanyQuickSwitch />
			</div>
		) : null

		let menuItems = headerItem
			.concat(companyQuickSelectItem)
			.concat(companyItem)
			.concat(actionItems)
			.concat(documentsItem)
		if (
			selectedCompanyRole &&
			(selectedCompanyRole.includes('admin') ||
				selectedCompanyRole === ROLES.maintenanceCompany)
		) {
			menuItems.push(boardItem)
		}

		let bottomMenuItems = []
		if (selectedManager) {
			bottomMenuItems.push(managerItem)
		}

		return (
			<Drawer
				visible={drawerVisible}
				onVisibilityChange={(visible) => {
					this.setState({ boardItemsExpanded: false })
				}}
				desktopType={Drawer.DrawerTypes.FULL_HEIGHT}
				tabletType={Drawer.DrawerTypes.TEMPORARY}
				mobileType={Drawer.DrawerTypes.TEMPORARY}
				navStyle={navStyle}
				style={{ ...navStyle }}
			>
				<div
					className="flex-column"
					style={{ justifyContent: 'space-between', height: '100%' }}
				>
					<DrawerMenuScrollContainer>{menuItems}</DrawerMenuScrollContainer>
					<div className="flex-column">{bottomMenuItems}</div>
				</div>
			</Drawer>
		)
	}
}

AppDrawer.propTypes = {
	auth: PropTypes.object.isRequired,
	currentRoute: PropTypes.object.isRequired,
}

const mapState = ({
	ui: {
		app: { drawerVisible },
	},
	dimensions: { isDesktop },
	router,
	firebaseReducer: { auth },
}) => ({
	drawerVisible,
	isDesktop,
	router,
	auth,
})

const mapDispatchToProps = (dispatch) => ({
	_push: bindActionCreators(push, dispatch),
	_setNavDrawerVisible: bindActionCreators(
		appUIActions._setNavDrawerVisible,
		dispatch,
	),
})

export default compose(
	injectIntl,
	WithDialogs,
	WithQueryParams,
	WithSelectedCompany,
	WithSelectedCompanyRole,
	WithUserRoles,
	WithSelectedCompanyManager,
	WithSelectedCompanyManagerRole,
	connect(mapState, mapDispatchToProps),
)(AppDrawer)
