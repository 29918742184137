import { useState } from 'react'
import { injectIntl } from 'react-intl'
import { compose } from 'redux'

import MassSendingDialog from '../MassSendingDialog'
import { Tabs as MdTabs, Tab as MdTab } from 'react-md'
import styled from 'styled-components'
import Drafts from './Drafts'
import History from './History'
import FloatingActionBtn from 'ui/components/FloatingActionBtn'

const Tabs = styled(MdTabs)`
	border-bottom-style: solid;
	border-bottom-color: rgba(0, 0, 0, 0.2);
	border-bottom-width: 1;
`

const Tab = styled(MdTab)`
	.md-tab-label {
		color: ${(props) =>
			props.active ? 'var(--color-primary-dark)' : 'var(--color-text-subtle)'};
		font-weight: ${(props) => (props.active ? 'bold' : 'normal')};
	}
`

function MassSending({ intl }) {
	const [activeTabIndex, setActiveTabIndex] = useState(0)
	const [dialog, setDialog] = useState({
		visible: false,
		messageServiceMassProcess: null,
	})

	const createLabel = intl.formatMessage({
		defaultMessage: 'Luo massalähetys',
		description:
			'Label for the button that creates a new message service mass process in the the manager message service.',
	})

	const openDialog = (messageServiceMassProcess) => {
		setDialog({ visible: true, messageServiceMassProcess })
	}

	const renderActiveTab = () => {
		switch (activeTabIndex) {
			case 0:
				return <Drafts openDialog={openDialog} />
			case 1:
				return <History />
			default:
				return <p style={{ padding: 16 }}>Tab not implemented.</p>
		}
	}

	return (
		<>
			<Tabs
				tabId="message-service-tabs"
				activeTabIndex={activeTabIndex}
				onTabChange={(tabIndex) => {
					setActiveTabIndex(tabIndex)
				}}
				style={{
					borderBottom: '1px solid rgba(0,0,0,0.2)',
				}}
			>
				<Tab label="Luonnokset" active={activeTabIndex === 0} />
				<Tab label="Lähetetyt" active={activeTabIndex === 1} />
			</Tabs>

			{renderActiveTab()}

			{dialog.visible && (
				<MassSendingDialog
					toolbarTitle="Massalähetys"
					messageServiceMassProcess={dialog.messageServiceMassProcess}
					onHide={() =>
						setDialog({ visible: false, messageServiceMassProcess: null })
					}
				/>
			)}
			<FloatingActionBtn
				iconName={'mail'}
				text={createLabel}
				action={() => {
					setDialog({ visible: true, messageServiceMassProcess: null })
				}}
			/>
		</>
	)
}

export default compose(injectIntl)(MassSending)
