import { defaultColumnSettings } from 'ui/TaskManager/staticData'

const defaultColumnSettingsWithoutLabel = defaultColumnSettings.map(
	(column) => {
		return {
			key: column.key,
			show: column.show,
		}
	},
)

const initialState = {
	taskColumnSettings: defaultColumnSettingsWithoutLabel,
}

function reducer(state = initialState, action) {
	if (action.type === 'SET_TASK_COLUMN_SETTINGS') {
		return {
			...state,
			taskColumnSettings: action.settings,
		}
	}
	return state
}

export default reducer
