import { Button, CircularProgress, TextField } from 'react-md'
import { useEffect, useState } from 'react'
import { _htjSaveAuthorizationKeys } from 'state/htj-actions'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import * as notifyActions from 'state/notifyActions'
import {
	CANCEL_BUTTON_LABEL,
	GENERIC_ERROR_NOTIFICATION,
	GENERIC_SAVED_NOTIFICATION,
	SAVE_BUTTON_LABEL,
} from 'ui/messages'
import ViiluDialog from 'ui/components/ViiluDialog'
import styled from 'styled-components'
import { doc, getDoc } from 'firebase/firestore'
import { firestore } from 'state/store'

const Description = styled.p`
	margin-bottom: 24px;
`
const Title = styled.h3`
	margin: 0;
	font-size: 1.3rem;
`
function AuthorizationKeysDialog({ visible, onHide, selectedManagerUUID }) {
	const intl = useIntl()
	const dispatch = useDispatch()
	const [isLoading, setIsLoading] = useState(true)
	const [authorizationKeyManager, setAuthorizationKeyManager] = useState('')

	useEffect(() => {
		const getAuthorizationKey = async () => {
			try {
				const docSnap = await getDoc(
					doc(firestore, 'manager-integration-htj', selectedManagerUUID),
				)
				if (docSnap.exists()) {
					const key = docSnap.data()?.authorizationKeyManager
					setAuthorizationKeyManager(typeof key === 'string' ? key : '')
				}
			} catch (error) {
				console.log(error)
				const errorMessage = intl.formatMessage({
					defaultMessage: 'Valtuutusavaimen hakemisessa tapahtui virhe.',
					description:
						'Error message for a failed htj authorization key fetch attempt.',
				})
				dispatch(notifyActions.error(errorMessage))
			}
			setIsLoading(false)
		}
		if (visible) {
			getAuthorizationKey()
		} else {
			setIsLoading(true)
		}
	}, [visible, selectedManagerUUID, intl, dispatch])

	const hideDialog = () => {
		onHide()
	}

	const handleSave = async () => {
		setIsLoading(true)
		const result = await _htjSaveAuthorizationKeys(
			selectedManagerUUID,
			authorizationKeyManager,
		)
		if (result?.success === false) {
			dispatch(notifyActions.error(GENERIC_ERROR_NOTIFICATION(intl)))
		} else {
			dispatch(notifyActions.success(GENERIC_SAVED_NOTIFICATION(intl)))
			hideDialog()
		}
		setIsLoading(false)
	}

	return (
		<ViiluDialog
			id="htj-authorization-keys-dialog"
			visible={visible}
			onHide={hideDialog}
			title={intl.formatMessage({
				defaultMessage: 'Valtuutusavaimet',
				description: 'Title of the HTJ authorization keys edit dialog.',
			})}
			focusOnMount={false}
			actions={[
				<Button flat disabled={isLoading} onClick={hideDialog}>
					{CANCEL_BUTTON_LABEL(intl)}
				</Button>,
				<Button raised secondary disabled={isLoading} onClick={handleSave}>
					{SAVE_BUTTON_LABEL(intl)}
				</Button>,
			]}
			dialogStyle={{ borderRadius: 8, width: 600 }}
		>
			{isLoading ? (
				<CircularProgress
					id="htj-authorization-progress"
					style={{ marginTop: 32 }}
				/>
			) : (
				<>
					<Description>
						{intl.formatMessage({
							defaultMessage:
								'Valtuutusavaimet löytyvät Maanmittauslaitoksen osakeluettelon siirtopalvelusta. Isännöintiyhteisön valtuutusavain kattaa kaikki ko. yhteisön hallinnoimat taloyhtiöt.',
							description: 'Description for HTJ authorization keys.',
						})}
					</Description>
					<Title>
						{intl.formatMessage({
							defaultMessage: 'Isännöintiyhteisön valtuutusavain',
							description: 'Title for HTJ manager authorization key.',
						})}
					</Title>
					<TextField
						id="htj-manager-authorization-key-input"
						type="password"
						style={{ marginBottom: 32 }}
						label={intl.formatMessage({
							defaultMessage: 'Valtuutusavain',
							description: 'Label for the HTJ authorization key input.',
						})}
						value={authorizationKeyManager}
						onChange={(value) => setAuthorizationKeyManager(value)}
					/>
				</>
			)}
		</ViiluDialog>
	)
}

export default AuthorizationKeysDialog
