import { useCallback, useState } from 'react'
import { injectIntl } from 'react-intl'
import { compose } from 'redux'
import styled from 'styled-components'
import DateAndTimePicker from 'ui/components/DateAndTimePicker'
import Actions from '../../../../Actions'
import { StepContainer } from '../../../styled'
import InfoscreenSelection from './InfoscreenSelection'

const Content = styled.div`
	max-width: 600px;
	margin: 40px auto;
`

const StepTitle = styled.h1`
	font-size: 1.8rem;
	margin: 16px 16px 0px 16px;
`

const DatePickerContainer = styled.div`
	margin-bottom: 32px;
`

const RequiredText = styled.p`
	font-size: 1rem;
	${(props) => props.$isError && 'color: red;'}
`

function Infoscreen({
	stepTitle,
	messageServiceProcess,
	setMessageServiceProcess,
	onPreviousClick,
	onDraftClick,
	onNextClick,
	isMassSending,
	intl,
}) {
	const [isError, setIsError] = useState(false)
	const { publishTsInfoscreen, expirationTsInfoscreen } =
		messageServiceProcess.bulletinsSettings || {}

	const updateTs = (input, value) => {
		setMessageServiceProcess((prev) => ({
			...prev,
			bulletinsSettings: {
				...prev.bulletinsSettings,
				[input]: value,
			},
		}))
	}

	const updatePublishTs = (value) => {
		updateTs('publishTsInfoscreen', value)
	}

	const updateExpirationTs = (value) => {
		updateTs('expirationTsInfoscreen', value)
		setIsError(false)
	}

	const updateSelectedInfoscreens = useCallback(
		(updated) => {
			setMessageServiceProcess((prev) => ({
				...prev,
				bulletinsSettings: {
					...prev.bulletinsSettings,
					selectedInfoscreens: updated,
				},
			}))
		},
		[setMessageServiceProcess],
	)

	const handleNextClick = () => {
		if (!expirationTsInfoscreen) {
			setIsError(true)
			return
		}
		onNextClick()
	}

	return (
		<>
			<StepTitle>{stepTitle}</StepTitle>

			<StepContainer>
				<Content>
					<DatePickerContainer>
						<DateAndTimePicker
							label={intl.formatMessage({
								defaultMessage: 'Julkaisuaika',
								description: 'Label for the bulletin publish time input.',
							})}
							emptyText={intl.formatMessage({
								defaultMessage: 'Heti',
								description: 'Label for the bulletin publish time empty value.',
							})}
							ts={publishTsInfoscreen}
							updateTs={updatePublishTs}
						/>
					</DatePickerContainer>

					<DatePickerContainer>
						<DateAndTimePicker
							label={
								intl.formatMessage({
									defaultMessage: 'Poistumisaika',
									description: 'Label for the bulletin expiration time input.',
								}) + ' *'
							}
							emptyText={intl.formatMessage({
								defaultMessage: 'Aseta poistumisaika',
								description:
									'Label for the bulletin expiration time empty value.',
							})}
							ts={expirationTsInfoscreen}
							updateTs={updateExpirationTs}
						/>
					</DatePickerContainer>
					<RequiredText $isError={isError}>
						{'* ' +
							intl.formatMessage({
								defaultMessage:
									'Poistumisaika on pakollinen kenttä. Sen avulla vanhentuneet ilmoitukset poistuvat automaattisesti ja porrasnäytöt pysyvät ajan tasalla.',
								description:
									'Information about the required infoscreen expiration time input in the message service.',
							})}
					</RequiredText>

					{!isMassSending && (
						<InfoscreenSelection
							selectedInfoscreens={
								messageServiceProcess.bulletinsSettings?.selectedInfoscreens
							}
							updateSelectedInfoscreens={updateSelectedInfoscreens}
							style={{ marginTop: 32 }}
						/>
					)}
				</Content>
			</StepContainer>

			<Actions
				onPreviousClick={onPreviousClick}
				onDraftClick={onDraftClick}
				onNextClick={handleNextClick}
			/>
		</>
	)
}

export default compose(injectIntl)(Infoscreen)
