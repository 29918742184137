import { useCallback, useEffect, useState } from 'react'
import type { ChartOfAccount } from './types'
import { _getChartOfAccountSettingsByUUID } from 'state/settings-actions'

function useChartOfAccount(uuid: string | undefined | null) {
	const [chartOfAccount, setChartOfAccount] = useState<ChartOfAccount>()

	const [loadingChartOfAccount, setLoading] = useState<boolean>(false)
	const [errorChartOfAccount, setError] = useState<string | null>(null)

	const doQuery = useCallback(async () => {
		if (!uuid) return
		setLoading(true)
		setError(null)
		let data: ChartOfAccount | undefined

		try {
			const response = await _getChartOfAccountSettingsByUUID(uuid)

			if (response && response.ok) {
				const res = await response.json()
				data = res.chartOfAccount
			} else {
				throw new Error('Response is not ok.')
			}
		} catch (err) {
			console.error('Error fetching billing account:', err)
			setError('An error occurred while fetching billing account: ' + uuid)
		}
		setChartOfAccount(data)
		setLoading(false)
	}, [uuid])

	useEffect(() => {
		doQuery()
	}, [doQuery])

	return {
		chartOfAccount,
		loadingChartOfAccount,
		errorChartOfAccount,
		refreshChartOfAccount: doQuery,
	}
}

export default useChartOfAccount
