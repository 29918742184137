import moment from 'moment'
import { useIntl } from 'react-intl'
import { Button, DatePicker } from 'react-md'
import styled from 'styled-components'
import { CANCEL_BUTTON_LABEL } from 'ui/messages'

const Container = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`

type Props = {
	id: string
	label?: string
	value: string | Date
	onChange: (value: string) => void
	style?: object
	required?: boolean
	disabled?: boolean
}

function DatePickerISO({
	id,
	label,
	value,
	onChange,
	style,
	required,
	disabled,
}: Props) {
	const intl = useIntl()

	return (
		<Container>
			<DatePicker
				id={id}
				label={label}
				style={{ minWidth: 160, ...style }}
				disableScrollLocking={true}
				cancelLabel={CANCEL_BUTTON_LABEL(intl)}
				firstDayOfWeek={1}
				locales={intl.locale || 'fi-FI'}
				value={value ? new Date(value) : ''}
				onChange={(_, date) => {
					onChange(moment(date).format('YYYY-MM-DD'))
				}}
				required={required}
				disabled={disabled}
			/>
			<Button
				icon
				style={{ marginTop: 16 }}
				onClick={() => onChange('')}
				disabled={disabled}
			>
				close
			</Button>
		</Container>
	)
}

export default DatePickerISO
