import { CircularProgress, FontIcon } from 'react-md'
import styled from 'styled-components'
import { breakpoint } from 'ui/StyledComponents/MediaBreakpoints'

const Fab = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	position: fixed;
	bottom: 24px;
	right: 24px;
	z-index: 15;
	cursor: pointer;
	box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.33);
	border-radius: 32px;
	height: 40px;
	padding-right: 12px;
	padding-left: 12px;
	@media ${breakpoint.phone} {
		bottom: 16px;
		right: 12px;
	}
`
const StyledLabel = styled.p`
	text-align: right;
	padding: 0;
	margin-left: 8px;
	margin-bottom: 2px;
	color: white;
	white-space: nowrap;
	text-transform: uppercase;
	font-size: 14px;
	transition-property: padding-right;
	transition-duration: 0.3s;
`
const StyledIcon = styled(FontIcon)`
	font-size: 20px !important;
	color: white;
`
type Props = {
	iconName: string | null
	text: string
	action: (...args: any[]) => any
	visible?: boolean
	color?: string
	style?: any
	processing?: boolean
	second?: boolean
}

// NOTE: renamed from 'FloatingActionButton' to silence a Babel error on branch switch
const FloatingActionBtn = ({
	iconName,
	text,
	action,
	visible = true,
	color = 'var(--color-secondary)',
	style,
	processing,
	second,
}: Props) => {
	return visible ? (
		<Fab
			style={{
				background: processing ? 'white' : color,
				bottom: second && '72px',
				width: !text && '40px',
				...style,
			}}
			onClick={action}
		>
			<StyledIcon>{iconName}</StyledIcon>
			{processing && (
				<CircularProgress id="progress" style={{ marginTop: '15px' }} />
			)}
			{text ? (
				<StyledLabel style={{ color: processing ? 'black' : '' }}>
					{text}
				</StyledLabel>
			) : null}
		</Fab>
	) : null
}

export default FloatingActionBtn
