import { IntlShape } from 'react-intl'
import { LoanDetailRowData } from '../types'

export const loanDetailRows: LoanDetailRowData[] = [
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Kohde',
				description: 'Title for company location',
			}),
		key: 'companyName',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Nimi',
				description: 'Title for loan name',
			}),
		key: 'name',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Lainan numero',
				description: 'Title for loan credit ID',
			}),
		key: 'creditId',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Lainanantaja',
				description: 'Title for loan giver',
			}),
		key: 'loanGrantedBy',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Laina-aika',
				description: 'Title for loan time/duration',
			}),
		key: 'loanTime',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Laina päättyy',
				description: 'Title for loan end date',
			}),
		key: 'loanEndDate',
		type: 'date',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Alkuperäinen pääoma',
				description: 'Title for original loan amount',
			}),
		key: 'originalLoanAmount',
		type: 'currency',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: '1. nostopäivämäärä',
				description: 'Title for first loan draw date',
			}),
		key: 'loanFirstDrawDate',
		type: 'date',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Nostettu pääoma',
				description: 'Title for drawn loan amount',
			}),
		key: 'loanDrawnAmount',
		type: 'currency',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Lainaosuudet maksettavissa',
				description: 'Title for loan share can be paid off',
			}),
		key: 'loanSharesCanBePaidOff',
		type: 'boolean',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Maksupäivät ilman lisäkuluja',
				description: 'Title for loan share payment dates',
			}),
		type: 'day-month-list',
		key: 'loanSharePaymentDates',
		showOnCondition: (loan) => loan.loanSharesCanBePaidOff === true,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Näytetään isännöitsijätodistuksella',
				description: 'Title for usable on building manager certificate',
			}),
		key: 'usableOnBuildingManagerCertificate',
		type: 'boolean',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Korkosidos',
				description: 'Title for interest type',
			}),
		key: 'interestType',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Korko',
				description: 'Title for interest amount',
			}),
		key: 'interestPercentage',
		type: 'percentage',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Marginaali',
				description: 'Title for loan marginal',
			}),
		key: 'marginalPercentage',
		type: 'percentage',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Korkopäivät / kk',
				description: 'Title for interest days in month',
			}),
		key: 'interestDaysMonth',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Korkopäivät / vuosi',
				description: 'Title for interest days in year',
			}),
		key: 'interestDaysYear',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: '1. koronmaksupäivä',
				description: 'Title for first interest due date',
			}),
		key: 'interestFirstDueDate',
		type: 'date',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Koron tarkastuspäivät',
				description: 'Title for interest inspection dates',
			}),
		type: 'day-month-list',
		key: 'interestInspectionDates',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Korko muuttunut viimeksi',
				description: 'Title for interest last updated date',
			}),
		key: 'interestLastUpdated',
		type: 'date',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Jäljellä oleva pääoma',
				description: 'Title for debt left to pay',
			}),
		key: 'debtLeftToPay',
		type: 'currency',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Lyhennyserä',
				description: 'Title for amortization amount',
			}),
		key: 'amortizationAmount',
		type: 'currency',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: '1. lyhennyspäivä',
				description: 'Title for first amortization date',
			}),
		key: 'amortizationFirstDueDate',
		type: 'date',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Lyhennyspäivät',
				description: 'Title for amortization dates',
			}),
		type: 'day-month-list',
		key: 'amortizationDates',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Yksiköt',
				description: 'Title for units in the loan',
			}),
		key: 'units',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Jakoperuste',
				description: 'Title for the billing basis',
			}),
		key: 'billingBasis',
	},
]
