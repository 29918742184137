const initialState = {
	inviteRequestDialogVisible: false,
}

function reducer(state = initialState, action) {
	if (action.type === 'UI_SITE_INVITE_REQUEST_DIALOG_SHOW') {
		return { ...state, inviteRequestDialogVisible: true }
	} else if (action.type === 'UI_SITE_INVITE_REQUEST_DIALOG_HIDE') {
		return { ...state, inviteRequestDialogVisible: false }
	}
	return state
}

export default reducer
