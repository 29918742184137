import {
	Button,
	DialogContainer,
	DialogContainerProps,
	Toolbar,
} from 'react-md'
import styled from 'styled-components'

// NOTE (03/2025): This component was created to unify full page dialog

const Content = styled.div`
	display: flex;
	flex-direction: column;
	height: 100vh;
	width: 100vw;
	overflow: auto;
`
const Actions = styled.div`
	display: flex;
	justify-content: flex-end;
	gap: 8px;
	background-color: white;
	border-top: 1px solid #dddddd;
	padding: 1.5rem 1rem;
`

const ViiluDialog = styled(DialogContainer)<{ zindex?: number }>`
	.md-dialog--full-page {
		${({ zindex }) =>
			zindex !== undefined ? `z-index: ${zindex} !important;` : ''}
	}
`

const ActionGroup = styled.div<{ align?: Alignment }>`
	display: flex;
	gap: 8px;
	${({ align }) => {
		if (align === 'left') return 'justify-content: flex-start;'
		if (align === 'right') return 'justify-content: flex-start;'
		if (align === 'center') return 'justify-content: center; flex-grow: 1;'
	}}
`

export type Action = {
	id: string
	children: any
	onClick: () => void
	raised?: boolean
	secondary?: boolean
	disabled?: boolean
	/**
	 * If multiple elements have the same alignment, their
	 * order is dependant on their order in the array
	 */
	align?: Alignment
}

type Alignment = 'left' | 'center' | 'right'

type Props = {
	id: string
	title: string
	hideTitle?: boolean
	children: any
	visible: boolean
	actions?: Action[]
	toolbarStyle?: any
	nav?: JSX.Element
	navActions?: JSX.Element | JSX.Element[]
	zIndex?: number
} & Omit<DialogContainerProps, 'title' | 'visible' | 'actions' | 'children'>

const ViiluFullPageDialog = ({
	title,
	hideTitle,
	children,
	actions,
	toolbarStyle,
	nav,
	navActions,
	zIndex,
	...rest
}: Props) => {
	return (
		<ViiluDialog
			aria-label={title}
			fullPage
			dialogStyle={{
				display: 'flex',
				flexDirection: 'column',
			}}
			zindex={zIndex}
			{...(rest as DialogContainerProps)}
		>
			<Toolbar
				title={hideTitle ? '' : title}
				colored
				style={toolbarStyle}
				nav={nav}
				actions={navActions}
			/>
			<Content>{children}</Content>
			{actions && actions.length > 0 && (
				<Actions>
					{['left', 'center', 'right'].map((align) => {
						const alignment = align as Alignment
						// If alignment isn't defined, default to right
						const alignedActions = actions.filter(
							(action) =>
								(!action.align && align === 'right') ||
								action.align === alignment,
						)

						return (
							<ActionGroup key={align} align={alignment}>
								{alignedActions.map((action) => (
									<Button
										flat={!action.raised}
										raised={action.raised}
										key={action.id}
										secondary={action.secondary}
										disabled={action.disabled}
										onClick={action.onClick}
									>
										{action.children}
									</Button>
								))}
							</ActionGroup>
						)
					})}
				</Actions>
			)}
		</ViiluDialog>
	)
}

export default ViiluFullPageDialog
