import React, { useState } from 'react'
import { List, ListItem, FontIcon, Button } from 'react-md'
import { PropTypes } from 'prop-types'
import { compose, bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import styled from 'styled-components'

import * as appActions from 'state/appActions'
import * as appUIActions from 'state/ui/app-ui-actions'

import { LOCALES } from '../../i18n'
import illustrationLanguage from 'svg/illustrations/illustration-language.svg'

const Checked = (
	<FontIcon style={{ color: 'var(--color-primary)' }}>
		radio_button_checked
	</FontIcon>
)
const UnChecked = <FontIcon>radio_button_unchecked</FontIcon>

const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	padding-bottom: 24px;
`
const StyledButton = styled(Button)`
	margin-left: 24px;
	margin-right: 24px;
`
const IllustrationContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: 24px;
	margin-bottom: 24px;
`

const LanguageMenu = ({
	selectedLocale,
	_setSelectedLocale,
	_setLanguageDialogVisible,
}) => {
	const [modified, setModified] = useState(false)

	const selectLanguage = (locale) => {
		setModified(true)
		_setSelectedLocale(locale)
	}

	const close = () => {
		if (!modified) {
			// In case nothing was changed still set the language
			// To make sure the user can always override their persisted locale
			// This will fire the cloud function to persist the current local locale
			_setSelectedLocale(selectedLocale)
		}
		_setLanguageDialogVisible(false)
	}

	return (
		<Container>
			<IllustrationContainer>
				<img alt="" src={illustrationLanguage} width={128} height={128} />
				<h4>
					<FormattedMessage
						defaultMessage="Valitse käytettävä kieli"
						description="Language selection dialog header"
					/>
				</h4>
			</IllustrationContainer>
			<List style={{ overflowY: 'hidden', height: '100%', minHeight: 56 * 3 }}>
				<ListItem
					primaryText={'Suomeksi'}
					leftIcon={selectedLocale === LOCALES.FINNISH ? Checked : UnChecked}
					onClick={() => selectLanguage(LOCALES.FINNISH)}
					className="md-divider-border md-divider-border--top"
				/>
				<ListItem
					primaryText={'På svenska'}
					leftIcon={selectedLocale === LOCALES.SWEDISH ? Checked : UnChecked}
					onClick={() => selectLanguage(LOCALES.SWEDISH)}
					className="md-divider-border md-divider-border--top"
				/>
				<ListItem
					primaryText={'In English'}
					leftIcon={selectedLocale === LOCALES.ENGLISH ? Checked : UnChecked}
					onClick={() => selectLanguage(LOCALES.ENGLISH)}
					className="md-divider-border md-divider-border--top"
				/>
			</List>
			<StyledButton raised secondary disabled={!selectedLocale} onClick={close}>
				OK
			</StyledButton>
		</Container>
	)
}

LanguageMenu.propTypes = {
	selectedLocale: PropTypes.string,
	_setSelectedLocale: PropTypes.func.isRequired,
	_setLanguageDialogVisible: PropTypes.func.isRequired,
}

const mapState = ({ app: { selectedLocale } }) => ({
	selectedLocale,
})

const mapDispatchToProps = (dispatch) => ({
	_setSelectedLocale: bindActionCreators(
		appActions._setSelectedLocale,
		dispatch,
	),
	_setLanguageDialogVisible: bindActionCreators(
		appUIActions._setLanguageDialogVisible,
		dispatch,
	),
})

export default compose(
	injectIntl,
	connect(mapState, mapDispatchToProps),
)(LanguageMenu)
