import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import WithLoadingUntilFirestoreLoaded from 'ui/components/WithLoadingUntilFirestoreLoaded'
import RecipientsStatusTable from 'ui/MessageService/MessageServiceProcess/steps/Recipients/RecipientsStatusTable'

function Personal({
	messageServiceProcess,
	postalBatch,
	emailBatches,
	smsBatches,
}) {
	let emailStatuses = {}
	let smsStatuses = {}

	if (emailBatches && emailBatches.length > 0) {
		emailBatches.forEach((b) => {
			emailStatuses = {
				...emailStatuses,
				...b.recipientStatus,
			}
		})
	}

	if (smsBatches && smsBatches.length > 0) {
		smsBatches.forEach((b) => {
			smsStatuses = {
				...smsStatuses,
				...b.recipientStatus,
			}
		})
	}

	return (
		<RecipientsStatusTable
			recipients={messageServiceProcess.recipients}
			deliveryMethods={messageServiceProcess.deliveryMethods}
			postalBatch={postalBatch}
			emailStatuses={emailStatuses}
			smsStatuses={smsStatuses}
		/>
	)
}

const mapState = ({
	firestoreReducer: {
		ordered: {
			message_service_postal_batches,
			message_service_email_batches,
			message_service_sms_batches,
		},
	},
}) => ({
	postalBatch: message_service_postal_batches?.[0],
	emailBatches: message_service_email_batches,
	smsBatches: message_service_sms_batches,
})

const mapDispatchToProps = () => ({})

export default compose(
	firestoreConnect(({ messageServiceProcess }) => {
		return [
			{
				collection: 'postal-batch',
				storeAs: 'message_service_postal_batches',
				where: [
					['companyUUID', '==', messageServiceProcess.companyUUID],
					['correlationCollection', '==', 'message-service-process'],
					['correlationUUID', '==', messageServiceProcess.uuid],
				],
			},
		]
	}),
	WithLoadingUntilFirestoreLoaded('message_service_postal_batches'),
	firestoreConnect(({ messageServiceProcess }) => {
		return [
			{
				collection: 'message-service-email-batch',
				storeAs: 'message_service_email_batches',
				where: [
					['companyUUID', '==', messageServiceProcess.companyUUID],
					['processUUID', '==', messageServiceProcess.uuid],
				],
			},
		]
	}),
	WithLoadingUntilFirestoreLoaded('message_service_email_batches'),
	firestoreConnect(({ messageServiceProcess }) => {
		return [
			{
				collection: 'message-service-sms-batch',
				storeAs: 'message_service_sms_batches',
				where: [
					['companyUUID', '==', messageServiceProcess.companyUUID],
					['processUUID', '==', messageServiceProcess.uuid],
				],
			},
		]
	}),
	WithLoadingUntilFirestoreLoaded('message_service_sms_batches'),
	connect(mapState, mapDispatchToProps),
)(Personal)
