import { useCallback, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import {
	Button,
	CircularProgress,
	DataTable,
	ExpansionList,
	ExpansionPanel,
	SelectField,
	TableBody,
	TableColumn,
	TableHeader,
	TableRow,
	TextField,
	FontIcon,
} from 'react-md'
import { CANCEL_BUTTON_LABEL, SAVE_BUTTON_LABEL } from 'ui/messages'
import useParties from '../Parties/useParties'
import { useSelector } from 'react-redux'
import {
	_createPayment,
	_updatePaymentAllocations,
} from 'state/finance-payment-actions'
import useDialogs from 'ui/components/dialogs/useDialogs'
import DatePickerISO from 'ui/components/DatePickerISO'
import type { CreateAllocations, CreatePaymentData } from './types'
import styled from 'styled-components'
import type { SelectOptions } from '../types'
import { StandardDialogContent } from 'ui/StyledComponents/BasicComponents'
import usePayment from './usePayment'
import { formatCurrency, formatInvoiceNumber } from 'util/formatStrings'
import useInvoices from '../Invoices/useInvoices'
import { InvoiceStatus } from '../enum'
import { removeLeadingZero } from '../utils/utils'
import ViiluFullPageDialog, { Action } from 'ui/components/ViiluFullPageDialog'

const Heading = styled.h2`
	margin: 0;
`

type Props = {
	id: string | null
	onHide: () => void
	refreshPayments?: () => void
	contractOptions: SelectOptions[]
}

type FormData = {
	partyUUID: string
	referenceNumber: string
	message: string
	archiveId: string
	paymentDate: string
	recordedDate: string
	total: string
}

function PaymentDialog({
	id,
	onHide,
	refreshPayments,
	contractOptions,
}: Props) {
	const intl = useIntl()
	const { alert } = useDialogs()
	const [loading, setLoading] = useState(false)
	const [formData, setFormData] = useState<FormData>({
		partyUUID: '',
		referenceNumber: '',
		message: '',
		archiveId: '',
		paymentDate: '',
		recordedDate: '',
		total: '',
	})
	const [selectedContract, setSelectedContract] = useState<string>('')

	const selectedFinanceCompanyUUID = useSelector(
		({ app }: any) => app?.selectedFinanceCompanyUUID,
	)
	const filters = useMemo(
		() => ({
			referenceNumber: selectedContract,
		}),
		[selectedContract],
	)
	const invoices = useInvoices(
		selectedContract ? selectedFinanceCompanyUUID : undefined,
		filters,
	)

	const [allocations, setAllocations] = useState<CreateAllocations[]>([])
	const removeAllocation = (invoiceUUID: string) => {
		setAllocations((prev) => {
			const newAllocations = prev.filter((a) => a.invoiceUUID !== invoiceUUID)
			return newAllocations
		})
	}
	const addAllocation = (createAllocations: CreateAllocations) => {
		setAllocations([...allocations, createAllocations])
	}
	const setAllocationTotal = (uuid: string, total: number) => {
		setAllocations((prev) =>
			prev.map((a) => {
				if (a.invoiceUUID === uuid) {
					return {
						...a,
						total,
					}
				}
				return a
			}),
		)
	}

	const { payment } = usePayment(id)

	const validateAllocationTotal = useCallback(() => {
		if (!payment) {
			return false
		}

		const paymentAllocationTotal = payment.invoiceAllocations
			.filter((a) => !allocations.some((b) => b.uuid === a.uuid))
			.reduce((acc, a) => acc + a.total, 0)

		const allocationTotal =
			allocations.reduce((acc, a) => acc + a.total, 0) + paymentAllocationTotal
		//TODO: Triggers on every input, fix to trigger only once
		if (allocationTotal > payment.total) {
			const message = intl.formatMessage({
				defaultMessage:
					'Laskuille kohdistettu summa on suurempi kuin suorituksen summa.',
				description:
					'Error message for allocation total exceeding the payment.',
			})
			alert(message)
			return false
		}

		for (const allocation of allocations) {
			const { invoice, total } = allocation
			const { totalWithVat } = invoice

			if (total > totalWithVat) {
				const message = intl.formatMessage({
					defaultMessage:
						'Kohdistettu summa on suurempi kuin laskun summa tai laskun kohdistuksien yhteissumma.',
					description:
						'Error message for allocation total exceeding the allocated invoice total.',
				})
				alert(message)
				return false
			}
		}

		return true
	}, [alert, allocations, intl, payment])

	useEffect(() => {
		if (payment) {
			validateAllocationTotal()
		}
	}, [payment, validateAllocationTotal])

	useEffect(() => {
		if (payment) {
			setFormData({
				partyUUID: payment.partyUUID,
				referenceNumber: payment.referenceNumber,
				message: payment.message,
				archiveId: payment.archiveId,
				paymentDate: payment.paymentDate,
				recordedDate: payment.recordedDate || '',
				total: payment.total.toString(),
			})

			setSelectedContract(payment.referenceNumber)

			setAllocations(() => {
				const allocations: CreateAllocations[] = []
				payment.invoiceAllocations.forEach((allocation) => {
					const invoice = payment.invoices.find(
						(i) => i.uuid === allocation.invoiceUUID,
					)
					if (invoice) {
						allocations.push({
							...allocation,
							invoice,
						})
					}
				})
				return allocations
			})
		}
	}, [payment])

	const { parties, loadingParties } = useParties(selectedFinanceCompanyUUID)

	const isLoading = loading || loadingParties

	const validate = (data: CreatePaymentData): boolean => {
		const hasErrors =
			!data.archiveId || !data.paymentDate || !data.total || data.total <= 0

		if (hasErrors) {
			const message = intl.formatMessage({
				defaultMessage: 'Tarkista tiedot.',
				description:
					'Error message for invalid values in the finance payment form.',
			})
			alert(message)
		}

		return !hasErrors
	}

	const handleUpdate = async () => {
		if (!validateAllocationTotal() || !payment) {
			return
		}
		const allocationsUpdate = allocations.map(({ invoice, ...a }) => {
			return {
				...a,
				total: Number(a.total),
			}
		})
		setLoading(true)
		try {
			const result = await _updatePaymentAllocations(
				payment.uuid,
				allocationsUpdate,
			)
			if (result?.ok) {
				refreshPayments?.()
				onHide()
			} else {
				throw new Error('Response is not ok.')
			}
		} catch (error) {
			console.error(error)
			setLoading(false)
		}
	}

	const handleSave = async () => {
		const party = parties.find((p) => p.uuid === formData.partyUUID)

		const paymentCreateData: CreatePaymentData = {
			companyUUID: selectedFinanceCompanyUUID,
			partyUUID: formData.partyUUID,
			partyName: party?.name,
			apartmentUUID: party?.apartmentUUID,
			apartmentIdentifier: party?.apartmentIdentifier,
			referenceNumber: formData.referenceNumber,
			archiveId: formData.archiveId,
			paymentDate: formData.paymentDate,
			recordedDate: formData.recordedDate,
			total: Number(formData.total),
		}

		if (!validate(paymentCreateData)) {
			return
		}

		setLoading(true)
		try {
			const result = await _createPayment(paymentCreateData)
			if (result?.ok) {
				refreshPayments?.()
				onHide()
			} else {
				throw new Error('Response is not ok.')
			}
		} catch (error) {
			console.error(error)
			setLoading(false)
		}
	}

	const onProcess = async () => {
		if (payment && payment.uuid) {
			return await handleUpdate()
		} else {
			return await handleSave()
		}
	}

	const renderForm = () => {
		if (isLoading) {
			return <CircularProgress id="payment-create-progress" />
		}

		return (
			<div
				className="margin-left--lg margin-right--lg"
				style={{ paddingBottom: 64 }}
			>
				<SelectField
					id="payment-party-select"
					style={{ width: '100%' }}
					label={intl.formatMessage({
						defaultMessage: 'Maksaja',
						description:
							'Label for the party input in the finance payment form.',
					})}
					simplifiedMenu={false}
					menuItems={parties?.map((party) => ({
						label: `${party.name} (${party.apartmentIdentifier})`,
						value: party.uuid,
					}))}
					disabled={id !== null}
					value={formData.partyUUID}
					onChange={(value) => {
						setFormData((prev) => ({
							...prev,
							partyUUID: value.toString(),
						}))
					}}
				/>

				<TextField
					id="payment-reference-number-input"
					label={intl.formatMessage({
						defaultMessage: 'Viitenumero',
						description:
							'Label for the reference number input in the finance payment form.',
					})}
					disabled={id !== null}
					value={formData.referenceNumber}
					onChange={(value) => {
						setFormData((prev) => ({
							...prev,
							referenceNumber: value.toString(),
						}))
					}}
				/>
				<TextField
					id="payment-message-input"
					label={intl.formatMessage({
						defaultMessage: 'Viesti',
						description:
							'Label for the message input in the finance payment form.',
					})}
					disabled={id !== null}
					value={formData.message}
					onChange={(value) => {
						setFormData((prev) => ({
							...prev,
							message: value.toString(),
						}))
					}}
				/>

				<TextField
					id="payment-archive-id-input"
					label={intl.formatMessage({
						defaultMessage: 'Arkistotunnus',
						description:
							'Label for the archive id input in the finance payment form.',
					})}
					required
					disabled={id !== null}
					value={formData.archiveId}
					onChange={(value) => {
						setFormData((prev) => ({
							...prev,
							archiveId: value.toString(),
						}))
					}}
				/>

				<DatePickerISO
					id={'payment-date-input'}
					label={intl.formatMessage({
						defaultMessage: 'Maksupäivä',
						description:
							'Label for the payment date input in the finance payment form.',
					})}
					required
					disabled={id !== null}
					value={formData.paymentDate}
					onChange={(value) => {
						setFormData((prev) => ({
							...prev,
							paymentDate: value,
						}))
					}}
				/>

				<DatePickerISO
					id={'recorded-date-input'}
					label={intl.formatMessage({
						defaultMessage: 'Kirjauspäivä',
						description:
							'Label for the payment recorded date input in the finance payment form.',
					})}
					disabled={id !== null}
					value={formData.recordedDate}
					onChange={(value) => {
						setFormData((prev) => ({
							...prev,
							recordedDate: value,
						}))
					}}
				/>

				<TextField
					id="payment-total-input"
					label={intl.formatMessage({
						defaultMessage: 'Summa',
						description:
							'Label for the total input in the finance payment form.',
					})}
					type="number"
					min={0}
					required
					disabled={id !== null}
					value={formData.total}
					onChange={(value) => {
						setFormData((prev) => ({
							...prev,
							total: value.toString(),
						}))
					}}
				/>
			</div>
		)
	}

	const renderInvoices = () => {
		if (!payment) return null

		const total = allocations.reduce((acc, a) => acc + a.total, 0)
		return (
			<>
				<Heading>
					{intl.formatMessage({
						defaultMessage: 'Kohdistetut laskut',
						description: 'Heading for payment dialog.',
					})}
				</Heading>
				<DataTable plain>
					<TableHeader>
						<TableRow>
							<TableColumn>
								{intl.formatMessage({
									defaultMessage: 'Laskunumero',
									description:
										'Table header for invoice payment dialog: Invoice number.',
								})}
							</TableColumn>

							<TableColumn>
								{intl.formatMessage({
									defaultMessage: 'Laskun summa',
									description:
										'Table header for invoice payment dialog: Invoice amount.',
								})}
							</TableColumn>
							<TableColumn>
								{intl.formatMessage({
									defaultMessage: 'Laskulle kohdistettu summa',
									description:
										'Table header for invoice payment dialog: Invoice allocated amount.',
								})}
							</TableColumn>
							<TableColumn>
								{intl.formatMessage({
									defaultMessage: 'Kohdistettu summa',
									description:
										'Table header for invoice payment dialog: Total allocated amount.',
								})}
							</TableColumn>
						</TableRow>
					</TableHeader>
					<TableBody>
						<>
							{allocations.length > 0
								? allocations.map((allocation) => {
										const { total, invoice } = allocation
										const {
											uuid,
											totalWithVat: invoiceTotalWithVat,
											invoiceAllocations,
										} = invoice

										const allocationTotal =
											allocation.total +
											invoiceAllocations
												.filter((a) => a.paymentUUID !== payment.uuid)
												.reduce((acc, a) => acc + a.total, 0)
										return (
											<TableRow key={uuid}>
												<TableColumn onClick={() => removeAllocation(uuid)}>
													{formatInvoiceNumber(invoice!)}
												</TableColumn>
												<TableColumn>
													{formatCurrency(invoiceTotalWithVat)}
												</TableColumn>
												<TableColumn>
													{formatCurrency(allocationTotal)}
												</TableColumn>
												<TableColumn>
													<TextField
														id={'allocation-total-input' + uuid}
														type="number"
														min={0}
														required
														value={removeLeadingZero(total)}
														onChange={(value) =>
															setAllocationTotal(
																uuid,
																Number(removeLeadingZero(value)),
															)
														}
													/>
												</TableColumn>
												<TableColumn>
													<FontIcon
														onClick={() =>
															removeAllocation(allocation.invoiceUUID)
														}
													>
														delete
													</FontIcon>
												</TableColumn>
											</TableRow>
										)
								  })
								: null}
							<TableRow key={'sum'}>
								<TableColumn>
									{intl.formatMessage({
										defaultMessage: 'Yhteensä',
										description: 'Total',
									})}
								</TableColumn>
								<TableColumn></TableColumn>
								<TableColumn></TableColumn>
								<TableColumn>
									{formatCurrency(total)} / {formatCurrency(payment.total)}
								</TableColumn>
							</TableRow>
						</>
					</TableBody>
				</DataTable>
			</>
		)
	}

	const renderPaymentAllocation = () => {
		if (!payment) return null

		return (
			<ExpansionList style={{ padding: '24px 0px 24px 0px' }}>
				<ExpansionPanel
					label={
						<Heading style={{ margin: 0 }}>
							{intl.formatMessage({
								defaultMessage: 'Kohdista suoritus laskuihin',
								description: 'Heading for payment dialog.',
							})}
						</Heading>
					}
					defaultExpanded={false}
					footer={null}
				>
					<SelectField
						id="payment-contracts-select"
						style={{ width: '100%' }}
						label={intl.formatMessage({
							defaultMessage: 'Sopimukset',
							description:
								'Label for the contract select in the finance payment dialog.',
						})}
						simplifiedMenu={false}
						menuItems={contractOptions}
						required
						value={selectedContract}
						onChange={(value) => {
							setSelectedContract(value.toString())
						}}
					/>

					{invoices.data.length > 0 && (
						<DataTable plain>
							<TableHeader>
								<TableRow>
									<TableColumn>
										{intl.formatMessage({
											defaultMessage: 'Laskunumero',
											description:
												'Table header for invoice payment dialog: Invoice number.',
										})}
									</TableColumn>

									<TableColumn>
										{intl.formatMessage({
											defaultMessage: 'Laskun summa',
											description:
												'Table header for invoice payment dialog: Invoice amount.',
										})}
									</TableColumn>
									<TableColumn>
										{intl.formatMessage({
											defaultMessage: 'Kohdistettu summa',
											description:
												'Table header for invoice payment dialog: Invoice allocated number.',
										})}
									</TableColumn>
								</TableRow>
							</TableHeader>
							<TableBody>
								{invoices.data
									.filter((invoice) => {
										if (invoice.status !== InvoiceStatus.SENT) {
											return false
										}
										if (
											allocations.some((a) =>
												a.invoiceUUID.includes(invoice.uuid),
											)
										) {
											return false
										}
										return true
									})
									.map((invoice) => {
										const { uuid, totalWithVat, paymentsTotal } = invoice
										return (
											<TableRow
												key={uuid}
												onClick={() =>
													addAllocation({
														invoiceUUID: uuid,
														paymentUUID: payment.uuid,
														total: 0,
														invoice: invoice,
													})
												}
											>
												<TableColumn>
													{formatInvoiceNumber(invoice)}
												</TableColumn>
												<TableColumn>
													{formatCurrency(totalWithVat)}
												</TableColumn>
												<TableColumn>
													{formatCurrency(paymentsTotal)}
												</TableColumn>
											</TableRow>
										)
									})}
							</TableBody>
						</DataTable>
					)}
				</ExpansionPanel>
			</ExpansionList>
		)
	}

	const actions: Action[] = [
		{
			id: 'cancel-button',
			children: CANCEL_BUTTON_LABEL(intl),
			onClick: onHide,
		},
		{
			id: 'save-button',
			secondary: true,
			raised: true,
			children: SAVE_BUTTON_LABEL(intl),
			onClick: onProcess,
		},
	]

	return (
		<ViiluFullPageDialog
			id="payment-dialog"
			visible
			actions={actions}
			onHide={onHide}
			focusOnMount={false}
			title={
				id
					? intl.formatMessage({
							defaultMessage: 'Muokkaa suoritusta',
							description: 'Title for the finance payment update dialog.',
					  })
					: intl.formatMessage({
							defaultMessage: 'Uusi suoritus',
							description: 'Title for the finance payment create dialog.',
					  })
			}
			toolbarStyle={{
				background: 'var(--color-secondary-dark)',
			}}
			nav={
				<Button
					icon
					onClick={() => {
						onHide()
					}}
				>
					{id ? 'arrow_back' : 'close'}
				</Button>
			}
		>
			<StandardDialogContent>{renderForm()}</StandardDialogContent>
			<StandardDialogContent>{renderInvoices()}</StandardDialogContent>
			{renderPaymentAllocation()}
		</ViiluFullPageDialog>
	)
}

export default PaymentDialog
