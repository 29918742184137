import type { LoanShareholder } from '../types'
import styled from 'styled-components'
import LoanShareGroupShareholderTable from './LoanShareGroupShareholderTable'

const ShareholderViewContainer = styled.div``

type LoanShareGroupPartiesTableProps = {
	shareholders: LoanShareholder[]
}

function LoanShareGroupShareholdersView(
	props: LoanShareGroupPartiesTableProps,
) {
	const { shareholders } = props

	// TODO: Add loan payments table after data location is more clear
	return (
		<ShareholderViewContainer>
			<LoanShareGroupShareholderTable shareholders={shareholders} />
		</ShareholderViewContainer>
	)
}

export default LoanShareGroupShareholdersView
