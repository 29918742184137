import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button, CircularProgress, Chip, Avatar, FontIcon } from 'react-md'
import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import {
	StandardDialogContent,
	StandardToolbar,
} from 'ui/StyledComponents/BasicComponents'
import Calendar from 'react-calendar'
import { injectIntl } from 'react-intl'
import moment from 'moment-timezone'

import * as notifyActions from 'state/notifyActions'

import { GENERIC_ERROR_NOTIFICATION } from 'ui/messages'
import ViiluDialog from 'ui/components/ViiluDialog'

const CalendarDialogContent = styled(StandardDialogContent)`
	padding: 0;
`
const TaskCalendar = styled(Calendar)`
	width: 100% !important;
`

function DueDateSelect({
	dateValue,
	emptyText,
	disabled,
	onDateUpdate,
	portal = false, // NOTE: causes crash if used with 'true' inside another ViiluDialog!
	_showInfoNotification,
	_fireStoreUpdate,
	intl,
	style,
}) {
	const [dialogOpen, setDialogOpen] = useState(false)
	const [processing, setProcessing] = useState(false)

	const onChange = async (dateObj) => {
		if (disabled) {
			return false
		}
		setProcessing(true)
		setDialogOpen(false)
		const dateStamp = moment(dateObj)
			.hour(0)
			.minute(0)
			.second(0)
			.millisecond(0)
			.valueOf()

		//_fireStoreUpdate will be changed according to update actions when there is a backend function for date update
		if (_fireStoreUpdate) {
			const result = await _fireStoreUpdate(dateStamp)

			if (!result || result.success === false) {
				_showInfoNotification('❌ ' + GENERIC_ERROR_NOTIFICATION(intl))
				return false
			}

			return true
		}

		if (onDateUpdate) onDateUpdate(dateStamp)

		setProcessing(false)
	}

	const renderButtonLabel = () => {
		if (dateValue) {
			const date = new Date(dateValue)
			const timeString = moment(date).format('DD.MM.YYYY')
			return timeString
		}
		return emptyText || 'Aseta deadline'
	}

	if (processing) {
		return <CircularProgress id="progress" style={{ marginTop: 0 }} />
	}

	return (
		<React.Fragment>
			<Chip
				style={{ ...style }}
				label={renderButtonLabel()}
				avatar={
					<Avatar
						icon={<FontIcon key="check">today</FontIcon>}
						suffix={dateValue ? 'blue' : 'grey'}
					/>
				}
				disabled={disabled}
				onClick={() => setDialogOpen(true)}
			/>
			<ViiluDialog
				id="responsive-dialog"
				dialogClassName="responsive-dialog"
				contentClassName="responsive-dialog-content"
				focusOnMount={false}
				aria-label={'Aseta päivämäärä'}
				visible={dialogOpen}
				containFocus={true}
				autosizeContent={false}
				modal
				portal={portal}
				onClick={(e) => {
					e.preventDefault()
					e.stopPropagation()
				}}
				style={{ zIndex: 98 }}
				disableScrollLocking
				onHide={() => {}}
			>
				<StandardToolbar
					title={emptyText || 'Aseta deadline'}
					nav={
						<Button icon onClick={() => setDialogOpen(false)}>
							close
						</Button>
					}
				/>
				<CalendarDialogContent className="md-toolbar-relative">
					<TaskCalendar
						onChange={(dateValue) => onChange(dateValue)}
						value={dateValue ? new Date(dateValue) : null}
						locale={'fi-FI'}
						className={'task-due-date-calendar'}
					/>
				</CalendarDialogContent>
			</ViiluDialog>
		</React.Fragment>
	)
}

const mapDispatchToProps = (dispatch) => ({
	_showInfoNotification: bindActionCreators(notifyActions.info, dispatch),
})

DueDateSelect.propTypes = {
	dateValue: PropTypes.number,
	onDateUpdate: PropTypes.func,
	disabled: PropTypes.bool,
}

export default compose(
	injectIntl,
	connect(null, mapDispatchToProps),
)(DueDateSelect)
