import { injectIntl } from 'react-intl'
import { compose } from 'redux'
import SummaryItems from '../SummaryItems'

function InfoscreenSummary({
	bulletinsSettings,
	messageServiceProcessSentTs,
	intl,
}) {
	const formatTs = (ts) => {
		if (!ts) {
			return null
		}

		return new Date(ts).toLocaleString('fi', {
			dateStyle: 'medium',
			timeStyle: 'short',
		})
	}

	const formatPublishTs = () => {
		const tsString = formatTs(
			bulletinsSettings.publishTsInfoscreen || messageServiceProcessSentTs,
		)

		if (!tsString) {
			return 'Julkaistaan heti'
		}

		return `Julkaisuaika ${tsString}`
	}

	const formatExpirationTs = () => {
		const tsString = formatTs(bulletinsSettings.expirationTsInfoscreen)

		if (!tsString) {
			return 'Ei poistumisaikaa'
		}

		return `Poistumisaika ${tsString}`
	}

	const items = [
		{
			icon: 'event',
			text: formatPublishTs(),
		},
		{
			icon: 'event',
			text: formatExpirationTs(),
		},
	]

	return (
		<>
			<h2>Porrasnäyttö (maksuton)</h2>
			<SummaryItems items={items} />
		</>
	)
}

export default compose(injectIntl)(InfoscreenSummary)
