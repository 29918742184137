import type { Header } from './types'

export const settingsCompanyHeaders: Header[] = [
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Nimi',
				description: 'Name',
			}),
		key: 'name',
		type: 'text',
		dataType: 'company',
		editable: false,
		show: true,
		required: false,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Y-tunnus',
				description: 'BusinessId',
			}),
		key: 'businessId',
		type: 'text',
		dataType: 'company',
		editable: false,
		show: true,
		required: false,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Lisätietoja',
				description: 'Additional information',
			}),
		key: 'companyInfo',
		type: 'text',
		editable: true,
		show: true,
		required: false,
	},
]

export const contactInformationHeaders: Header[] = [
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Katuosoite',
				description: 'Street address',
			}),
		key: 'contactStreetAddress',
		type: 'text',
		editable: true,
		show: true,
		required: false,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Postinumero',
				description: 'Zip code',
			}),
		key: 'contactZipCode',
		type: 'text',
		dataType: 'zipCode',
		editable: true,
		show: true,
		required: false,
		maxLength: 5,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Postitoimipaikka',
				description: 'Post office',
			}),
		key: 'contactPostOffice',
		type: 'text',
		editable: true,
		show: true,
		required: false,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Maa',
				description: 'Country',
			}),
		key: 'contactCountry',
		type: 'text',
		dataType: 'country',
		editable: false,
		show: true,
		required: false,
	},
]

export const invoicingInformationHeaders: Header[] = [
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Katuosoite',
				description: 'Street address',
			}),
		key: 'invoicingStreetAddress',
		type: 'text',
		editable: true,
		show: true,
		required: false,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Postinumero',
				description: 'Zip code',
			}),
		key: 'invoicingZipCode',
		type: 'text',
		dataType: 'zipCode',
		editable: true,
		show: true,
		required: false,
		maxLength: 5,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Postitoimipaikka',
				description: 'Post office',
			}),
		key: 'invoicingPostOffice',
		type: 'text',
		editable: true,
		show: true,
		required: false,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Maa',
				description: 'Country',
			}),
		key: 'invoicingCountry',
		type: 'text',
		dataType: 'country',
		editable: false,
		show: true,
		required: false,
	},
]

export const taxHeaders: Header[] = [
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Alv-rekisteröity',
				description: 'Alv registery',
			}),
		key: 'alvRegistered',
		type: 'boolean',
		editable: true,
		show: true,
		required: false,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Alv-rekisteröintipäivä',
				description: 'Alv registered date',
			}),
		key: 'alvRegisteryDate',
		type: 'date',
		editable: true,
		show: true,
		required: false,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Verokauden pituus',
				description: 'Tax period length',
			}),
		key: 'taxPeriodLength',
		type: 'text',
		editable: true,
		show: true,
		required: false,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Kirjaustapa',
				description: 'Accounting method',
			}),
		key: 'accountingMethod',
		type: 'text',
		editable: true,
		show: true,
		required: false,
	},
]

export const taxPaymentInformationHeaders: Header[] = [
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Verohallinnon viitenumero',
				description: 'Tax office reference number',
			}),
		key: 'taxOfficeReferenceNumber',
		type: 'text',
		editable: true,
		show: true,
		required: false,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Verohallinnon tilinumero',
				description: 'Tax office bank account',
			}),
		key: 'taxOfficeBankAccount',
		type: 'text',
		editable: true,
		show: true,
		required: false,
	},
]

export const invoicingHeaders: Header[] = [
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Viitteen etuliite',
				description: 'Reference number prefix',
			}),
		key: 'referenceNumberPrefix',
		type: 'text',
		editable: true,
		show: true,
		required: true,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Laskun etuliite',
				description: 'Invoice prefix',
			}),
		key: 'invoicePrefix',
		type: 'text',
		editable: true,
		show: true,
		required: true,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Laskun eräpäivä',
				description: 'Due date',
			}),
		key: 'dueDate',
		type: 'number',
		editable: true,
		show: true,
		required: true,
		errorMessage: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Syötä eräpäivä 1-31 väliltä.',
				description: 'Error message for due date to be between 1 and 31.',
			}),
		minValue: 1,
		maxValue: 31,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Perintäkulu (€)',
				description: 'Collection fee',
			}),
		key: 'collectionFee',
		type: 'number',
		editable: true,
		show: true,
		required: true,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Laskutusjakso',
				description: 'Invoicing period',
			}),
		key: 'invoicingStartMonth',
		type: 'text',
		editable: true,
		show: true,
		required: true,
	},
]

export const invoicingAdditionalInformationHeaders: Header[] = [
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Laskutuksen yhteystieto',
				description: 'Invoicing contact information',
			}),
		key: 'contactInformation',
		type: 'text',
		editable: true,
		show: true,
		required: false,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Lisätieto paperilaskulle',
				description: 'Additional information on paper invoice',
			}),
		key: 'paperInvoiceAdditionalInformation',
		type: 'text',
		editable: true,
		show: true,
		required: false,
	},
]

export const eInvoiceHeaders: Header[] = [
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Laskutusaiheen koodi',
				description: 'E-invoice code',
			}),
		key: 'e_invoice_code',
		type: 'text',
		editable: true,
		show: true,
		required: false,
	},
]

export const bankAccountHeaders: Header[] = [
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Nimi',
				description: 'bank account name',
			}),
		key: 'name',
		type: 'text',
		editable: true,
		show: true,
		required: false,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'IBAN',
				description: 'bank account IBAN',
			}),
		key: 'iban',
		type: 'text',
		editable: true,
		show: true,
		required: false,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'BIC',
				description: 'bank account BIC',
			}),
		key: 'bic',
		type: 'text',
		editable: true,
		show: true,
		required: false,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Ensisijainen',
				description: 'bank account primary',
			}),
		key: 'primary',
		type: 'boolean',
		editable: true,
		show: true,
		required: false,
	},
]

export const chartOfAccountsHeaders: Header[] = [
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Numero',
				description: 'Chart of account number',
			}),
		key: 'number',
		type: 'number',
		editable: true,
		show: true,
		required: false,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Nimi',
				description: 'Chart of account name',
			}),
		key: 'name',
		type: 'text',
		editable: true,
		show: true,
		required: false,
	},
]

export const webInvoiceHeaders: Header[] = [
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Osoite',
				description: 'Web invoice address',
			}),
		key: 'address',
		type: 'number',
		editable: true,
		show: true,
		required: false,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Operaattori',
				description: 'Web invoice operator',
			}),
		key: 'operator',
		//TODO: CHECK WHAT VALUE WE WANT TO SAVE TO DB IS IT INT OR STRING
		type: 'text',
		editable: true,
		show: true,
		required: false,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Suunta',
				description: 'Web invoice direction',
			}),
		key: 'direction',
		type: 'text',
		editable: true,
		show: true,
		required: false,
	},
]
