import { generateQueryParams } from 'util/queryUtils'
import { callApi } from './api/finance-api'
import type {
	AccountsLedgerDetailsFilters,
	AccountsLedgerFilters,
} from 'ui/ManagerFinance/AccountsLedger/types'
import type { DebtFilters } from 'ui/ManagerFinance/AccountsLedger/useDebt'

export const _getAccountsLedgerList = async (data: AccountsLedgerFilters) => {
	const queryString = generateQueryParams(data)
	return callApi(`/v1/accounts-ledger${queryString}`, {
		method: 'GET',
	})
}

export const _getAccountLedgerDetails = async (
	uuid: string,
	filters: AccountsLedgerDetailsFilters,
) => {
	const queryString = generateQueryParams(filters)
	return callApi(`/v1/accounts-ledger/${uuid}${queryString}`, {
		method: 'GET',
	})
}

export const _getDebt = async (filters: DebtFilters) => {
	const queryString = generateQueryParams(filters)
	return callApi(`/v1/accounts-ledger/debt${queryString}`, {
		method: 'GET',
	})
}
