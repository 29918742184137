import { Button } from 'react-md'
import RootDialog from './RootDialog'

function AlertDialog({ dialog, resolveDialog }) {
	return (
		<RootDialog
			dialog={dialog}
			actions={[
				<Button raised primary onClick={() => resolveDialog(dialog.key)}>
					{dialog.confirmLabel}
				</Button>,
			]}
		>
			<pre
				style={{
					fontFamily: 'Lato',
					whiteSpace: 'pre-wrap',
					marginTop: 0,
					marginBottom: 0,
				}}
			>
				{dialog.message}
			</pre>
		</RootDialog>
	)
}

export default AlertDialog
