import type { Building } from 'types/building'
import type { ApartmentSelectOptions } from '../types'
import naturalSort from 'javascript-natural-sort'
import { makeAparmentLabel } from './apartmentLabel'
import type { Apartment } from 'types/apartment'

export const createApartmentOptions = (
	companyApartments: Apartment[],
	buildings: Building[] = [],
): ApartmentSelectOptions[] => {
	const getApartmentLabel = (identifier: string, buildingUUID?: string) => {
		const building = buildings.find((b) => b.uuid === buildingUUID)
		return makeAparmentLabel(identifier, building?.name)
	}

	return companyApartments
		.map(({ uuid, identifier, buildingUUID }) => ({
			label: getApartmentLabel(identifier, buildingUUID),
			value: uuid,
			identifier,
		}))
		.sort((a, b) => naturalSort(a.label, b.label))
}
