import { FontIcon } from 'react-md'
import styled from 'styled-components'

const StatusLi = styled.li`
	padding: 0 0 16px 16px;
	display: flex;
	align-items: center;

	&:last-child {
		padding-bottom: 0;
	}
`

const StatusDetails = styled.div`
	display: flex;
	flex-direction: column;
	margin-left: 8px;
`

const Time = styled.span`
	font-size: ${(props) => (props.isFirstItem ? '14px' : '12px')};
	font-weight: ${(props) => (props.isFirstItem ? 'bold' : 'normal')};
`

const Text = styled.span`
	font-size: ${(props) => (props.isFirstItem ? '18px' : '16px')};
	font-weight: ${(props) => (props.isFirstItem ? 'bold' : 'normal')};
`

function StatusItem({ labelText, timestamp, icon, iconColor, isFirstItem }) {
	const formattedTime = new Date(timestamp).toLocaleString('fi-FI', {
		dateStyle: 'medium',
		timeStyle: 'short',
	})

	return (
		<StatusLi>
			<FontIcon
				style={{
					color: iconColor,
					fontSize: isFirstItem ? '20px' : '10px',
					width: '32px',
					flexShrink: 0,
				}}
			>
				{icon}
			</FontIcon>
			<StatusDetails>
				<Time isFirstItem={isFirstItem}>{formattedTime}</Time>
				<Text isFirstItem={isFirstItem}>{labelText}</Text>
			</StatusDetails>
		</StatusLi>
	)
}

export default StatusItem
