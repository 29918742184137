import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { Toolbar, Button, Grid, Cell, Checkbox } from 'react-md'

import * as companyActions from 'state/company-actions'
import * as notifyActions from 'state/notifyActions'
import LoadingState from 'ui/components/LoadingState'
import { generateRecipientUUID } from './utils'
import { injectIntl } from 'react-intl'
import { FETCHING_ELECTRONIC_CONSENT } from './translations'
import { GENERIC_ERROR_NOTIFICATION } from 'ui/messages'
import WithSelectedCompany from 'ui/components/WithSelectedCompany'
import ViiluDialog from 'ui/components/ViiluDialog'
import config from 'config'

const GET_APARTMENTS = true
const GET_CONSENTS = true

const getInitialState = () => ({
	processing: false,
	shortcutAddTenants: false,
	shortcutAddShareholders: false,
	shortcutAddBoard: false,
	shortcutAuditorOperations: false,
	shortcutAuditorAccounting: false,
	shortcutAssignedManagerEmployee: false,
	shortcutMaintenanceCompany: false,
})

class ImporterDialogViilu extends Component {
	state = getInitialState()

	getUsersViilu = async () => {
		const {
			onSuccess,
			_usersByRoles,
			_showInfoNotification,
			intl,
			selectedCompany,
		} = this.props
		const {
			shortcutAddTenants,
			shortcutAddShareholders,
			shortcutAddBoard,
			shortcutAuditorOperations,
			shortcutAuditorAccounting,
			shortcutAssignedManagerEmployee,
			shortcutMaintenanceCompany,
		} = this.state

		const r = config.userRoles

		const roles = []
		if (shortcutAddTenants) {
			roles.push(r.userTenant)
		}
		if (shortcutAddShareholders) {
			roles.push(r.userShareholder)
		}
		if (shortcutAddBoard) {
			roles.push(r.adminBoardMember)
			roles.push(r.adminBoardDeputy)
			roles.push(r.adminBoardDirector)
		}
		if (shortcutAuditorOperations) {
			roles.push(r.auditorOperations)
		}
		if (shortcutAuditorAccounting) {
			roles.push(r.auditorAccounting)
		}
		if (shortcutMaintenanceCompany) {
			roles.push(r.maintenanceCompany)
		}

		if (shortcutAssignedManagerEmployee) {
			roles.push('assigned_manager_employee')
		}

		let users = []

		if (roles.length > 0) {
			_showInfoNotification(FETCHING_ELECTRONIC_CONSENT(intl))
			this.setState({ processing: true })
			const result = await _usersByRoles(roles, GET_APARTMENTS, GET_CONSENTS)
			this.setState({ processing: false })

			if (result && result.data && result.data.users) {
				users = result.data.users
			} else {
				_showInfoNotification(GENERIC_ERROR_NOTIFICATION(intl))
			}
		}

		if (shortcutMaintenanceCompany) {
			const maintenanceEmail = selectedCompany.maintenanceInfo?.formEmail
			if (maintenanceEmail)
				users.push({
					zip: null,
					area: null,
					address: null,
					displayName: selectedCompany.maintenanceInfo?.name || 'Huoltoyhtiö',
					email: maintenanceEmail,
					apartments: [],
					digitalDeliveryConsents: {
						viilu: false,
					},
				})
		}

		const recipients = users.map((user) => ({
			...user,
			uuid: generateRecipientUUID(),
		}))

		onSuccess(recipients)
		this.onHide()
	}

	onHide = () => {
		this.setState(getInitialState())
		this.props.onHide()
	}

	renderToolbar = (dialogTitle) => {
		return (
			<Toolbar
				colored
				title={dialogTitle}
				style={{
					background: 'var(--color-secondary-dark)',
					position: 'fixed',
					width: '100%',
					zIndex: 2,
				}}
				nav={
					<Button icon onClick={this.onHide}>
						close
					</Button>
				}
			/>
		)
	}

	render() {
		const {
			processing,
			shortcutAddTenants,
			shortcutAddShareholders,
			shortcutAddBoard,
			shortcutAuditorOperations,
			shortcutAuditorAccounting,
			shortcutAssignedManagerEmployee,
			shortcutMaintenanceCompany,
		} = this.state
		const { intl } = this.props

		const dialogTitle = intl.formatMessage({
			defaultMessage: 'Tuo Viilun käyttäjärekisteristä',
			description:
				'Title of the dialog for importing users from Viilu to the message service recipients table.',
		})

		return (
			<ViiluDialog
				id="responsive-dialog"
				aria-label={dialogTitle}
				visible={true}
				onHide={this.onHide}
				focusOnMount={true}
				containFocus={true}
				dialogClassName="responsive-dialog"
				contentClassName="responsive-dialog-content"
				autosizeContent={false}
				disableScrollLocking
				modal
				paddedContent
				onClick={(e) => {
					e.preventDefault()
					e.stopPropagation()
				}}
				style={{ zIndex: 5000 }}
			>
				{this.renderToolbar(dialogTitle)}
				<section className="md-toolbar-relative" style={{ padding: 24 }}>
					{processing ? (
						<LoadingState />
					) : (
						<div className="flex-column">
							<Grid>
								<Cell size={12}>
									<p>
										{intl.formatMessage({
											defaultMessage:
												'Tuo vastaanottajia Viilun käyttäjärekisteristä.',
											description:
												'Description in the dialog for importing users from Viilu to the message service recipients table.',
										})}
									</p>
								</Cell>
								<Cell
									size={12}
									style={{ display: 'flex', alignItems: 'flex-end' }}
								>
									<Checkbox
										id="checkbox-shortcut-tenants"
										className="checkbox-lean"
										label={
											<p style={{ margin: 0 }}>
												{intl.formatMessage({
													defaultMessage: 'Asukkaat',
													description:
														'Checkbox label for tenants in the dialog for importing users from Viilu to the message service recipients table.',
												})}
											</p>
										}
										name="Asukkaat"
										checked={shortcutAddTenants}
										onClick={() =>
											this.setState({
												shortcutAddTenants: !this.state.shortcutAddTenants,
											})
										}
										onChange={() => {}}
									/>
								</Cell>
								<Cell
									size={12}
									style={{ display: 'flex', alignItems: 'flex-end' }}
								>
									<Checkbox
										id="checkbox-shortcut-shareholders"
										className="checkbox-lean"
										label={
											<p style={{ margin: 0 }}>
												{intl.formatMessage({
													defaultMessage: 'Osakkaat',
													description:
														'Checkbox label for shareholders in the dialog for importing users from Viilu to the message service recipients table.',
												})}
											</p>
										}
										name="Osakkaat"
										checked={shortcutAddShareholders}
										onClick={() =>
											this.setState({
												shortcutAddShareholders:
													!this.state.shortcutAddShareholders,
											})
										}
										onChange={() => {}}
									/>
								</Cell>
								<Cell
									size={12}
									style={{ display: 'flex', alignItems: 'flex-end' }}
								>
									<Checkbox
										id="checkbox-shortcut-board"
										className="checkbox-lean"
										label={
											<p style={{ margin: 0 }}>
												{intl.formatMessage({
													defaultMessage: 'Hallitus',
													description:
														'Checkbox label for board members in the dialog for importing users from Viilu to the message service recipients table.',
												})}
											</p>
										}
										name="Hallitus"
										checked={shortcutAddBoard}
										onClick={() =>
											this.setState({
												shortcutAddBoard: !this.state.shortcutAddBoard,
											})
										}
										onChange={() => {}}
									/>
								</Cell>
								<Cell
									size={12}
									style={{ display: 'flex', alignItems: 'flex-end' }}
								>
									<Checkbox
										id="checkbox-shortcut-auditor-operations"
										className="checkbox-lean"
										label={
											<p style={{ margin: 0 }}>
												{intl.formatMessage({
													defaultMessage: 'Toiminnantarkastajat',
													description:
														'Checkbox label for auditor operations members in the dialog for importing users from Viilu to the message service recipients table.',
												})}
											</p>
										}
										name="Toiminnantarkastajat"
										checked={shortcutAuditorOperations}
										onClick={() =>
											this.setState({
												shortcutAuditorOperations:
													!this.state.shortcutAuditorOperations,
											})
										}
										onChange={() => {}}
									/>
								</Cell>
								<Cell
									size={12}
									style={{ display: 'flex', alignItems: 'flex-end' }}
								>
									<Checkbox
										id="checkbox-shortcut-auditor-accounting"
										className="checkbox-lean"
										label={
											<p style={{ margin: 0 }}>
												{intl.formatMessage({
													defaultMessage: 'Tilintarkastajat',
													description:
														'Checkbox label for auditor accounting members in the dialog for importing users from Viilu to the message service recipients table.',
												})}
											</p>
										}
										name="Tilintarkastajat"
										checked={shortcutAuditorAccounting}
										onClick={() =>
											this.setState({
												shortcutAuditorAccounting:
													!this.state.shortcutAuditorAccounting,
											})
										}
										onChange={() => {}}
									/>
								</Cell>
								<Cell
									size={12}
									style={{ display: 'flex', alignItems: 'flex-end' }}
								>
									<Checkbox
										id="checkbox-shortcut-assigned-manager-employee"
										className="checkbox-lean"
										label={
											<p style={{ margin: 0 }}>
												{intl.formatMessage({
													defaultMessage: 'Kohdeisännöitsijä',
													description:
														'Checkbox label for the assigned manager employee in the dialog for importing users from Viilu to the message service recipients table.',
												})}
											</p>
										}
										name="Kohdeisännöitsijä"
										checked={shortcutAssignedManagerEmployee}
										onClick={() =>
											this.setState({
												shortcutAssignedManagerEmployee:
													!this.state.shortcutAssignedManagerEmployee,
											})
										}
										onChange={() => {}}
									/>
								</Cell>
								<Cell
									size={12}
									style={{ display: 'flex', alignItems: 'flex-end' }}
								>
									<Checkbox
										id="checkbox-shortcut-maintenance-company"
										className="checkbox-lean"
										label={
											<p style={{ margin: 0 }}>
												{intl.formatMessage({
													defaultMessage: 'Kohteen huoltoyhtiö',
													description:
														'Checkbox label for the maintenance company in the dialog for importing users from Viilu to the message service recipients table.',
												})}
											</p>
										}
										name="Huoltoyhtiö"
										checked={shortcutMaintenanceCompany}
										onClick={() =>
											this.setState({
												shortcutMaintenanceCompany:
													!this.state.shortcutMaintenanceCompany,
											})
										}
										onChange={() => {}}
									/>
								</Cell>
							</Grid>

							<div
								className="flex-row margin-top"
								style={{ justifyContent: 'flex-end' }}
							>
								<Button raised secondary onClick={this.getUsersViilu}>
									{intl.formatMessage({
										defaultMessage: 'Tuo valitut',
										description:
											'Label for the button that imports the users from Viilu to the message service recipients table.',
									})}
								</Button>
							</div>
						</div>
					)}
				</section>
			</ViiluDialog>
		)
	}
}

ImporterDialogViilu.propTypes = {
	onHide: PropTypes.func.isRequired,
	onSuccess: PropTypes.func.isRequired,
	company: PropTypes.object.isRequired,
}

const mapState = () => ({})

const mapDispatchToProps = (dispatch) => ({
	_showInfoNotification: bindActionCreators(notifyActions.info, dispatch),
	_usersByRoles: bindActionCreators(companyActions._usersByRoles, dispatch),
})

export default compose(
	injectIntl,
	WithSelectedCompany,
	connect(mapState, mapDispatchToProps),
)(ImporterDialogViilu)
