// eslint-disable-next-line
import React from 'react'
import { combineReducers, applyMiddleware, createStore, compose } from 'redux'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import thunk from 'redux-thunk'
import { createBrowserHistory } from 'history'
import { firebaseReducer, getFirebase } from 'react-redux-firebase'
import { initializeApp } from 'firebase/app'
import firebase from 'firebase/compat/app' // react-redux-firebase doesn't support firebase sdk v9
import 'firebase/compat/auth' // react-redux-firebase doesn't support firebase sdk v9
import 'firebase/compat/firestore' // react-redux-firebase doesn't support firebase sdk v9
import {
	getFirestore,
	connectFirestoreEmulator,
	initializeFirestore,
} from 'firebase/firestore'
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions'
import { getStorage, connectStorageEmulator } from 'firebase/storage'
import { firestoreReducer } from 'redux-firestore'
import { googleAnalytics } from './reactGAMiddlewares'
import { save, load } from 'redux-localstorage-simple'
import config from '../config'

// Reducers
import app from './appReducer'
import taskManager from './taskManagerReducer'
import kanban from './kanbanReducer'
import dimensions from './dimensionsReducer'
import notifications from './notificationsReducer.js'
import ui from './ui'

const EMULATOR_HOST = 'localhost'

const userAgent = navigator.userAgent.toLowerCase()
if (userAgent.indexOf(' electron/') > -1) {
	global.electron = true
}

const firebaseConfig = {
	apiKey: 'AIzaSyAGwI3yTZzUL7BJpqcAutGakGbYagyrpa4',
	authDomain: 'app.vii.lu',
	databaseURL: 'https://taloyhtio-app.firebaseio.com',
	projectId: 'taloyhtio-app',
	storageBucket: 'taloyhtio-app.appspot.com',
	messagingSenderId: global.electron ? undefined : '382850402758', // Needs to be undefine for the electron app, otherwise the default service worker registeration fails
	appId: '1:382850402758:web:cc0edc1f0f4933f70d0ac7',
	measurementId: 'G-VH9KP9HWMQ',
}

// Initialize firebase instance
export const firebaseApp = initializeApp(firebaseConfig)
initializeFirestore(firebaseApp, {
	useFetchStreams: false,
})

firebase.initializeApp(firebaseConfig) // react-redux-firebase doesn't support firebase sdk v9
firebase.firestore().settings({ useFetchStreams: false })

const _functions = getFunctions(firebaseApp, 'europe-west1') // <- needed if using httpsCallable
const _firestore = getFirestore(firebaseApp) // <- needed if using firestore

if (config.emulatorsEnabled.functions) {
	connectFunctionsEmulator(_functions, EMULATOR_HOST, 5001)
}
if (config.emulatorsEnabled.firestore) {
	// v8 emulator (for react-redux-firebase)
	firebase.firestore().useEmulator(EMULATOR_HOST, 8080)
	// v9 emulator
	connectFirestoreEmulator(_firestore, EMULATOR_HOST, 8080)
}

export const getStorageBucket = (bucket) => {
	const storage = getStorage(firebaseApp, bucket || undefined)
	if (config.emulatorsEnabled.storage) {
		connectStorageEmulator(storage, EMULATOR_HOST, 9199)
	}
	return storage
}

export const functions = _functions
export const firestore = _firestore

export const history = createBrowserHistory()
// Build the middleware for intercepting and dispatching navigation actions
const routerMiddleman = routerMiddleware(history)

const reducers = combineReducers({
	router: connectRouter(history),
	app,
	taskManager,
	kanban,
	dimensions,
	notifications,
	firebaseReducer,
	firestoreReducer,
	ui,
})

const persistedReducers = ['app', 'ui', 'taskManager']
const persistedNamespace = 'app'

const store = createStore(
	reducers,
	load({
		states: persistedReducers,
		namespace: persistedNamespace,
		disableWarnings: true,
	}),
	compose(
		applyMiddleware(
			thunk.withExtraArgument(getFirebase), // Pass getFirebase function as extra argument
			routerMiddleman,
		),
		applyMiddleware(
			save({
				states: persistedReducers,
				namespace: persistedNamespace,
				debounce: 500,
			}),
		),
		applyMiddleware(googleAnalytics),
	),
)

global.firebase = firebase

export default store
