import config from 'config'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { InvoicingMethod, InvoiceStatus } from '../enum'
import type { SelectOptions } from '../types'
import { invoicingMethodMessage, invoiceStatusMessage } from '../utils/messages'
import styled from 'styled-components'
import Filters from './Filters'
import { useBuildings } from 'ui/CompanyBuildings/hooks'
import LoadingState from 'ui/components/LoadingState'
import useCompanyApartments from 'util/hooks/useCompanyApartments'
import { createApartmentOptions } from '../utils/apartmentOptions'
import { useState } from 'react'
import type { ConsolidatedInvoiceFilters } from './types'
import ConsolidatedInvoicesTable from './ConsolidatedInvoicesTable'
import useConsolidatedInvoices from './useConsolidatedInvoices'
import ConsolidatedInvoiceDialog from './ConsolidatedInvoiceDialog'
import { push } from 'connected-react-router'

const Container = styled.div`
	padding: 16px;
`

const LoadingContainer = styled.div`
	margin-top: 64px;
`
const HASH_CONSOLIDATED_INVOICE = config.routeHashes.consolidatedInvoice

const ConsolidatedInvoice = () => {
	const intl = useIntl()
	const selectedFinanceCompanyUUID = useSelector(
		({ app }: any) => app?.selectedFinanceCompanyUUID,
	)
	const dispatch = useDispatch()

	const hash = window.location.hash
	const queryParams = new URLSearchParams(hash.split('?')[1])
	const queryParamUUID = queryParams.get('uuid')

	const [filters, setFilters] = useState<ConsolidatedInvoiceFilters>({
		apartmentUUID: null,
		invoiceDate: null,
		dueDate: null,
		invoicingMethod: null,
		status: null,
		searchText: null,
	})

	const { loading, data } = useConsolidatedInvoices(
		selectedFinanceCompanyUUID,
		filters,
	)

	const { buildings } = useBuildings(selectedFinanceCompanyUUID)
	const { companyApartments } = useCompanyApartments(selectedFinanceCompanyUUID)
	const apartmentOptions = createApartmentOptions(companyApartments, buildings)

	const invoicingOptions: SelectOptions[] = Object.values(InvoicingMethod).map(
		(value) => ({
			label: invoicingMethodMessage(intl, value),
			value: value,
		}),
	)

	const statusOptions: SelectOptions[] = Object.values(InvoiceStatus).map(
		(val) => ({
			label: invoiceStatusMessage(intl, val),
			value: val,
		}),
	)

	const renderTable = () => {
		if (loading) {
			return (
				<LoadingContainer>
					<LoadingState />
				</LoadingContainer>
			)
		}

		if (!data || !data.size) {
			return (
				<p>
					{intl.formatMessage({
						defaultMessage: 'Koontilaskuja ei löytynyt.',
						description:
							'Message for empty finance consolidated invoices list.',
					})}
				</p>
			)
		}

		return (
			<ConsolidatedInvoicesTable
				consolidatedInvoices={data}
				//refreshInvoices={() => invoices.refresh()}
				//apartmentOptions={apartmentOptions}
			/>
		)
	}
	return (
		<Container>
			<Filters
				filters={filters}
				setFilters={setFilters}
				apartmentOptions={apartmentOptions}
				invoicingOptions={invoicingOptions}
				statusOptions={statusOptions}
			/>

			{renderTable()}
			{queryParamUUID && (
				<ConsolidatedInvoiceDialog
					companyUUID={selectedFinanceCompanyUUID}
					batchUUID={queryParamUUID}
					onHide={() => {
						dispatch(
							push('/manager/finance/invoices' + HASH_CONSOLIDATED_INVOICE),
						)
					}}
					apartmentOptions={apartmentOptions}
				/>
			)}
		</Container>
	)
}

export default ConsolidatedInvoice
