import { TableRow } from 'react-md/lib/DataTables'
import styled from 'styled-components'
import { breakpoint } from 'ui/StyledComponents/MediaBreakpoints'

// Loan dialog components

export const ContentContainer = styled.div`
	display: flex;
	flex-flow: column nowrap;

	@media ${breakpoint.desktop} {
		flex-flow: row nowrap;
	}
`

export const AsideContentContainer = styled.aside`
	background-color: #fafafa;
	padding: 1.5rem;
	width: 100%;
	height: auto;
	min-height: auto;

	@media ${breakpoint.desktop} {
		width: 30%;
		min-width: 400px;
		min-height: 100vh;
	}
`

export const MainContentContainer = styled.section`
	width: 100%;
	border-left: rgba(0, 0, 0, 0.2) solid 1px;
	height: auto;
	min-height: auto;

	@media ${breakpoint.desktop} {
		width: 70%;
		height: max-content;
		min-height: 100%;
	}
`

export const LoanDetailContainer = styled.div`
	background-color: white;
	border: rgba(0, 0, 0, 0.2) solid 1px;
`

export const LoanDetailRow = styled.div<{ $filledBackground: boolean }>`
	display: grid;
	padding: 0.25rem 0.5rem;
	gap: 0.5rem;
	grid-template-columns: 1fr 1fr;
	border-bottom: rgba(0, 0, 0, 0.2) solid 1px;
	background-color: ${(props) => (props.$filledBackground ? '#FAFAFA' : '')};
`

export const TabsContainer = styled.div`
	border-bottom: rgba(0, 0, 0, 0.2) solid 1px;
`

export const TabContentContainer = styled.div`
	padding: 1rem;
`

// Table components

export const TableRowContainer = styled(TableRow)<{
	$filledBackground: boolean
}>`
	background-color: ${(props) => (props.$filledBackground ? '#FAFAFA' : '')};
`

export const TableRowsNotFound = styled.div`
	display: flex;
	flex-flow: column nowrap;
	gap: 2rem;
	justify-content: center;
	align-items: center;
	height: 200px;
`

export const TableHeaderNameSortable = styled.div`
	display: flex;
	align-items: center;
	gap: 4px;
`
