import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'

import { injectIntl } from 'react-intl'

import * as notifyActions from 'state/notifyActions'
import * as userActions from 'state/user-actions'

import WithoutSelectedCompanyRoleRenderNull from 'ui/components/WithoutSelectedCompanyRoleRenderNull'
import WithLoggedInUser from 'ui/components/WithLoggedInUser'
import LoadingState from 'ui/components/LoadingState'
import ShareholderOnboardingStepConsent from './ShareholderOnboardingStepConsent'
import {
	ONBOARDING_SHAREHOLDER_INFOREQUEST_HEADER,
	ONBOARDING_UPDATE_ERROR,
} from '../messages'
import { GENERIC_ERROR_NOTIFICATION } from '../../messages'
import WithSelectedCompanyRole from 'ui/components/WithSelectedCompanyRole'
import FB_ERRORS from '../../../util/firebaseErrors'
import WithDialogs from 'ui/components/dialogs/WithDialogs'
import ViiluDialog from 'ui/components/ViiluDialog'
import config from 'config'

const roles = config.userRoles

class ShareholderOnboarding extends Component {
	state = {
		processing: false,
		digitalDeliveryConsent:
			this.props.loggedInUser?.digitalDeliveryConsent === undefined
				? true
				: this.props.loggedInUser.digitalDeliveryConsent,
	}

	onUpdateConsent = (digitalDeliveryConsent) =>
		this.setState({ digitalDeliveryConsent })

	processProfileUpdate = async () => {
		const { _updateProfile, _showInfoNotification, intl, alert } = this.props
		const { digitalDeliveryConsent } = this.state

		this.setState({ processing: true })
		try {
			const result = await _updateProfile(
				undefined,
				undefined,
				undefined,
				digitalDeliveryConsent,
				undefined,
				undefined,
				undefined,
			)
			if (!result || result.success === false) {
				if (
					result?.ex &&
					result?.ex?.code === FB_ERRORS.FUNCTIONS_ALREADY_EXISTS
				) {
					if (result?.ex?.message && result.ex.message.includes('email')) {
						const message = intl.formatMessage({
							defaultMessage:
								'Tapahtui virhe. Syöttämäsi sähköpostiosoite on jo toisen käyttäjän käytössä. Vinkki: onko mahdollisesti käynyt niin, että olet jo aiemmin luonut tunnuksen tällä sähköpostiosoitteella? Mikäli näin on käynyt suosittelemme sinua kirjautumaan sisään aiemmin luodulla tunnuksella.',
							description:
								'Error message telling the user that the email address is already in use by another user.',
						})
						await alert(message)
					} else if (
						result?.ex?.message &&
						result.ex.message.includes('phoneNumber')
					) {
						const message = intl.formatMessage({
							defaultMessage:
								'Tapahtui virhe. Syöttämäsi puhelinnumero on jo toisen käyttäjän käytössä. Vinkki: onko mahdollisesti käynyt niin, että olet jo aiemmin luonut tunnuksen tällä puhelinnumerolla? Mikäli näin on käynyt suosittelemme sinua kirjautumaan sisään aiemmin luodulla tunnuksella.',
							description:
								'Error message telling the user that the phone number is already in use by another user.',
						})
						await alert(message)
					} else {
						const message = intl.formatMessage({
							defaultMessage:
								'Tapahtui virhe. Syöttämäsi tieto on jo toisen käyttäjän käytössä.',
							description:
								'Error message telling the user that the information is already in use by another user.',
						})
						await alert(message)
					}
				} else {
					const message = intl.formatMessage({
						defaultMessage:
							'Tapahtui virhe. Tarkistathan sähköpostiosoitteen ja puhelinnumeron muodon. Muista maatunnus puhelinnumerossa.',
						description:
							'Error message telling the user that there was an error creating their account.',
					})
					await alert(message)
				}
				_showInfoNotification('❌ ' + ONBOARDING_UPDATE_ERROR(intl))
				return false
			}
			if (this.props.onHide) {
				this.props.onHide()
			}
			return true
		} catch (ex) {
			console.error(ex)
			_showInfoNotification('❌ ' + GENERIC_ERROR_NOTIFICATION(intl))
			return false
		} finally {
			this.setState({ processing: false })
		}
	}

	render() {
		const { processing, digitalDeliveryConsent } = this.state
		const { loggedInUser, intl, visible, onHide, selectedCompanyRole } =
			this.props

		if (!loggedInUser) {
			return null
		}

		if (!(loggedInUser.digitalDeliveryConsent === undefined || visible)) {
			return null
		}

		// Don't show the dialog if we are an admin (or manager) when we are at the end of
		// the user onboarding, but show it if the user explicitly wants to open the dialog
		if (selectedCompanyRole === 'admin' && visible === undefined) {
			return null
		}

		const element = processing ? (
			<LoadingState />
		) : (
			<ShareholderOnboardingStepConsent
				digitalDeliveryConsent={digitalDeliveryConsent}
				onUpdate={this.onUpdateConsent}
				onNext={this.processProfileUpdate}
				processing={processing}
			/>
		)

		return (
			<ViiluDialog
				id="responsive-dialog"
				aria-label={ONBOARDING_SHAREHOLDER_INFOREQUEST_HEADER(intl)}
				visible
				onHide={() => onHide && onHide()}
				focusOnMount={false}
				containFocus={false}
				dialogClassName="responsive-dialog"
				autosizeContent={false}
				modal={visible === undefined}
				portal
				onClick={(e) => {
					e.preventDefault()
					e.stopPropagation()
				}}
			>
				{element}
			</ViiluDialog>
		)
	}
}

ShareholderOnboarding.propTypes = {}

const mapState = () => ({})

const mapDispatchToProps = (dispatch) => ({
	_showInfoNotification: bindActionCreators(notifyActions.info, dispatch),
	_updateProfile: bindActionCreators(userActions._updateProfile, dispatch),
})

export default compose(
	injectIntl,
	WithDialogs,
	WithSelectedCompanyRole,
	WithoutSelectedCompanyRoleRenderNull([
		roles.userShareholder,
		roles.adminBoardMember,
		roles.adminBoardDirector,
		roles.adminBoardDeputy,
		roles.admin,
	]),
	WithLoggedInUser,
	connect(mapState, mapDispatchToProps),
)(ShareholderOnboarding)
