import { backendFunction } from './actions-util'

export const _generatePreviewMessageService =
	({
		companyUUID,
		processUUID,
		title,
		body,
		attachments,
		postalSettings,
		generator,
		generatorDataUUID,
	}) =>
	async () =>
		backendFunction('postalBatchPreviewMessageService', {
			companyUUID,
			processUUID,
			title,
			body,
			attachments,
			postalSettings,
			generator,
			generatorDataUUID,
		})

export const _statusRefresh =
	({ uuid }) =>
	async () =>
		backendFunction('postalBatchStatusRefresh', {
			uuid,
		})

export const _uploadRetry = (uuid, force) => async () =>
	backendFunction('postalBatchUploadRetry', {
		uuid,
		force,
	})
