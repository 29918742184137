import { useCallback, useEffect, useState } from 'react'
import { _getInvoices } from 'state/finance-invoice-actions'
import type { Invoice, InvoiceFilters } from './types'

type State = {
	loading: boolean
	data: Invoice[]
}

function useInvoices(companyUUID: string, filters?: InvoiceFilters) {
	const [state, setState] = useState<State>({ loading: true, data: [] })

	const doQuery = useCallback(async () => {
		if (!companyUUID) {
			setState({ loading: false, data: [] })
			return
		}

		setState((prev) => ({ ...prev, loading: true }))
		let data: Invoice[] = []
		try {
			const response = await _getInvoices({
				companyUUID,
				...filters,
			})
			if (response?.ok) {
				const result = await response.json()
				if (result.invoices && Array.isArray(result.invoices)) {
					data = result.invoices
				}
			} else {
				throw new Error('Response is not ok.')
			}
		} catch (error) {
			console.error(error)
		}
		setState({
			loading: false,
			data,
		})
	}, [companyUUID, filters])

	useEffect(() => {
		doQuery()
	}, [doQuery])

	return { ...state, refresh: doQuery }
}

export default useInvoices
