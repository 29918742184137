import { TableContainer } from 'ui/StyledComponents/Tables'
import FloatingActionBtn from 'ui/components/FloatingActionBtn'
import { useIntl } from 'react-intl'
import ContractDialog from './ContractDialog'
import {
	TableRow,
	TableColumn,
	DataTable,
	TableHeader,
	TableBody,
	ListItem,
	FontIcon,
	TextField,
} from 'react-md'
import OverflowGradient from 'ui/components/OverflowGradient'
import { contractHeaders } from './constants'
import { useEffect, useState } from 'react'
import DatePickerISO from 'ui/components/DatePickerISO'
import DropdownField from 'ui/components/DropdownField'
import ContractRow from './ContractRow'
import type { ContractFilters, ContractKey } from './types'
import * as notifyActions from 'state/notifyActions'
import useDialogs from 'ui/components/dialogs/useDialogs'
import { _deleteContract } from 'state/contract-actions'
import { GENERIC_ERROR_NOTIFICATION } from 'ui/messages'
import { useDispatch } from 'react-redux'
import type { UseContractsResult } from './useContracts'
import type {
	ApartmentSelectOptions,
	SelectOptions,
	PartySelectOptions,
} from '../types'
import type { Apartment } from 'types/apartment'
import naturalSort from 'javascript-natural-sort'
import { getContractRowValue } from './utils'

type Props = {
	useContracts: UseContractsResult
	filters: ContractFilters
	setFilters: React.Dispatch<React.SetStateAction<ContractFilters>>
	apartmentOptions: ApartmentSelectOptions[]
	invoicingOptions: SelectOptions[]
	selectedFinanceCompanyUUID: string
	companyOptions: SelectOptions[]
	payerOptions: PartySelectOptions[]
	companyApartments: Apartment[]
}

const ContractView = ({
	useContracts,
	filters,
	setFilters,
	apartmentOptions,
	invoicingOptions,
	selectedFinanceCompanyUUID,
	companyOptions,
	payerOptions,
	companyApartments,
}: Props) => {
	const { contracts, refreshContracts } = useContracts
	const intl = useIntl()
	const dispatch = useDispatch()
	const { confirm } = useDialogs()
	const [processing, setProcessing] = useState<boolean>(false)
	const [addContractDialogVisible, setAddContractDialogVisible] =
		useState<boolean>(false)

	const [searchWord, setSearchWord] = useState(filters.searchWord)

	const [sort, setSort] = useState<{
		key: ContractKey
		type: string | null
		order: string
	}>({
		key: 'apartmentUUID',
		type: 'apartmentOptions',
		order: 'asc',
	})

	const changeSort = (key: ContractKey, type?: string) => {
		if (sort.key === key) {
			setSort((prevData) => ({
				...prevData,
				order: sort.order === 'asc' ? 'desc' : 'asc',
			}))
		} else {
			setSort({
				key: key,
				type: type || null,
				order: 'asc',
			})
		}
	}

	useEffect(() => {
		const handler = setTimeout(() => {
			setFilters((prev) => ({
				...prev,
				searchWord,
			}))
		}, 500)

		return () => {
			clearTimeout(handler)
		}
	}, [searchWord, setFilters])

	const showAddContractDialog = () => {
		setAddContractDialogVisible(true)
	}

	const hideAddContractDialog = () => {
		setAddContractDialogVisible(false)
	}

	const renderFAB = () => {
		return (
			<FloatingActionBtn
				iconName={'add'}
				text={intl.formatMessage({
					defaultMessage: 'LISÄÄ SOPIMUS',
					description: 'Add new contract',
				})}
				action={showAddContractDialog}
			/>
		)
	}
	const deleteContract = async (uuid: string) => {
		const confirmMessage = intl.formatMessage({
			defaultMessage: 'Oletko varma, että haluat poistaa sopimuksen?',
			description: 'Confirm message for the delete contract.',
		})
		if (!(await confirm(confirmMessage))) {
			return
		}

		setProcessing(true)

		const result = await _deleteContract(uuid)
		setProcessing(false)

		if (!result || result.ok === false) {
			dispatch(notifyActions.info('❌ ' + GENERIC_ERROR_NOTIFICATION(intl)))
			return false
		}

		dispatch(
			notifyActions.info(
				'✅ ' +
					intl.formatMessage({
						defaultMessage: 'Sopimus on poistettu.',
						description: 'Notification text contract has been deleted',
					}),
			),
		)
		refreshContracts()
		return true
	}

	const renderFilterSelectField = (filterProps) => {
		const { type, value, label, _onOptionSelect, options } = filterProps
		if (type === 'text') {
			return (
				<div style={{ width: 180, marginRight: 5 }} key={label}>
					<TextField
						id={label}
						label={label}
						value={value}
						onChange={_onOptionSelect}
						disabled={false}
					/>
				</div>
			)
		}
		if (type === 'date') {
			return (
				<DatePickerISO
					key={label}
					style={{ marginLeft: '20px' }}
					id={label}
					label={label}
					value={value}
					onChange={_onOptionSelect}
				/>
			)
		}
		if (type === 'dropdown' && Array.isArray(options)) {
			return (
				<div style={{ width: 180, marginRight: 5 }} key={label}>
					<DropdownField {...filterProps} />
				</div>
			)
		}
	}

	const renderActionBar = () => {
		const filterFields = [
			{
				label: intl.formatMessage({
					defaultMessage: 'Huoneisto',
					description: 'Label of apartment',
				}),
				value: filters.apartmentUUID,
				options: apartmentOptions,
				_onOptionSelect: (value) =>
					setFilters((prevData) => ({
						...prevData,
						apartmentUUID: value,
					})),
				hasSelectAll: true,
				nullSelectAll: true,
				itemLabel: 'label',
				itemValue: 'value',
				type: 'dropdown',
			},
			{
				label: intl.formatMessage({
					defaultMessage: 'Laskutustapa',
					description: 'Label of billing method',
				}),
				value: filters.invoicingMethod,
				options: invoicingOptions,
				_onOptionSelect: (value) =>
					setFilters((prevData) => ({
						...prevData,
						invoicingMethod: value,
					})),
				hasSelectAll: true,
				nullSelectAll: true,
				itemLabel: 'label',
				itemValue: 'value',
				type: 'dropdown',
			},
			{
				label: intl.formatMessage({
					defaultMessage: 'Tarkastelupäivämäärä',
					description: 'Label of review date',
				}),
				_onOptionSelect: (value) => {
					if (!value) {
						setFilters((prevData) => ({
							...prevData,
							reviewDate: null,
						}))
					} else {
						setFilters((prevData) => ({
							...prevData,
							reviewDate: value,
						}))
					}
				},
				value: filters.reviewDate,
				nullSelectAll: true,
				itemLabel: 'label',
				itemValue: 'value',
				type: 'date',
			},
			{
				label: intl.formatMessage({
					defaultMessage: 'Hakusana',
					description: 'Label of billing method',
				}),
				value: searchWord,
				_onOptionSelect: (value) => {
					setSearchWord(value)
				},
				hasSelectAll: true,
				nullSelectAll: true,
				itemLabel: 'label',
				itemValue: 'value',
				type: 'text',
			},
		]

		return (
			<div
				className="flex-row margin-bottom--sm margin-right"
				style={{
					justifyContent: 'space-between',
					alignItems: 'flex-end',
				}}
			>
				<div
					className="flex-row full-width"
					style={{ alignItems: 'center', marginLeft: 16, flexWrap: 'wrap' }}
				>
					{filterFields.map(renderFilterSelectField)}
				</div>
			</div>
		)
	}

	const renderTableHeaders = () => {
		return (
			<TableRow>
				{contractHeaders
					.filter((h) => h.show)
					.map((header) => (
						<TableColumn
							key={header.key}
							onClick={() => header.sortable && changeSort(header.key)}
						>
							<div
								style={{ display: 'flex', alignItems: 'center', gap: '4px' }}
							>
								<p className="text-strong text-subtle">{header.title(intl)}</p>
								{sort.key === header.key && (
									<FontIcon style={{ fontSize: 12 }}>
										{sort.order === 'asc' ? 'arrow_upward' : 'arrow_downward'}
									</FontIcon>
								)}
							</div>
						</TableColumn>
					))}
				<TableColumn key={'children-header'}></TableColumn>
			</TableRow>
		)
	}

	const renderTable = () => {
		if (!contracts || !contracts.length) {
			return (
				<p style={{ marginLeft: '1.5rem' }}>
					{intl.formatMessage({
						defaultMessage: 'Sopimuksia ei löytynyt.',
						description: 'Message for empty contracts list.',
					})}
				</p>
			)
		}
		return (
			<TableContainer>
				<TableContainer>
					<DataTable
						plain
						style={{
							background: 'white',
							maxHeight: 'calc(100vh - 205px)',
						}}
					>
						<TableHeader
							style={{
								position: 'sticky',
								top: 0,
								zIndex: 1,
								background: 'white',
							}}
						>
							{renderTableHeaders()}
						</TableHeader>
						<TableBody>
							{contracts
								.sort((a, b) => {
									const aValue =
										sort.type && sort.type !== 'boolean'
											? getContractRowValue(
													a,
													sort.key,
													companyOptions,
													apartmentOptions,
													intl,
													sort.type,
											  )
											: a[sort.key]

									const bValue =
										sort.type && sort.type !== 'boolean'
											? getContractRowValue(
													b,
													sort.key,
													companyOptions,
													apartmentOptions,
													intl,
													sort.type,
											  )
											: b[sort.key]

									return sort.order === 'asc'
										? naturalSort(aValue, bValue)
										: naturalSort(bValue, aValue)
								})
								.map((contract) => (
									<ContractRow
										key={contract.uuid}
										contract={contract}
										menuItems={[
											<ListItem
												key={1}
												primaryText={intl.formatMessage({
													defaultMessage: 'Poista sopimus',
													description: 'Delete contract',
												})}
												leftIcon={<FontIcon>delete</FontIcon>}
												onClick={() => deleteContract(contract.uuid)}
												className="md-divider-border md-divider-border--right"
											/>,
										]}
										children={null}
										processing={processing}
										companyOptions={companyOptions}
										apartmentOptions={apartmentOptions}
									/>
								))
								.concat(
									<tr
										key="empty-row"
										className="full-width"
										style={{ height: 100, background: 'white' }}
									></tr>,
								)}
						</TableBody>
						<OverflowGradient style={{ bottom: 40 }} />
					</DataTable>
				</TableContainer>
			</TableContainer>
		)
	}

	return (
		<div>
			{renderActionBar()}
			{renderTable()}
			{addContractDialogVisible && (
				<ContractDialog
					useContracts={useContracts}
					onHide={hideAddContractDialog}
					selectedFinanceCompanyUUID={selectedFinanceCompanyUUID}
					companyOptions={companyOptions}
					payerOptions={payerOptions}
					apartmentOptions={apartmentOptions}
					invoicingOptions={invoicingOptions}
					companyApartments={companyApartments}
				/>
			)}

			{renderFAB()}
		</div>
	)
}

export default ContractView
