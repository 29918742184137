import styled from 'styled-components'
import truncate from 'truncate'
import MarkdownText from 'ui/components/MarkdownText'

const StyledContainer = styled.div`
	background: white;
	padding: 32px;
`
const StyledBubbleContainer = styled.div`
	padding: 8px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	p {
		font-size: 11px;
		margin-left: 16px;
		color: rgba(0, 0, 0, 0.6);
	}
`
const StyledBubble = styled.div`
	background: #dadada;
	padding-top: 4px;
	padding-bottom: 4px;
	padding-left: 0;
	padding-right: 0;
	border-radius: 16px;
	position: relative;
	pointer-events: none;
	white-space: pre-line;
	:before {
		content: '';
		top: 13px;
		left: -14px;
		position: absolute;
		border: 0px solid;
		display: block;
		width: 15px;
		height: 12px;
		background-color: transparent;
		border-bottom-left-radius: 50%;
		border-bottom-right-radius: 50%;
		box-shadow: 9px 4px 0px 4px #dadada;
	}
	p {
		font-size: 16px;
		padding-right: 16px;
		padding-left: 16px;
		margin: 0 !important;
		color: var(--color-text);
	}
`

function DefaultTemplate({ title, bodyText, viewUrl, attachments }) {
	let message = bodyText || title

	if (message.length > 110 || (attachments && attachments.length)) {
		message =
			truncate(message, 110, { ellipsis: '...' }) +
			' Lue lisää: https://vii.lu/sms/12345678901234'
	}

	return (
		<StyledContainer>
			<StyledBubbleContainer>
				<p>Viilu</p>
				<StyledBubble>
					<MarkdownText text={message} style={{ fontFamily: 'Lato' }} />
				</StyledBubble>
			</StyledBubbleContainer>
		</StyledContainer>
	)
}

export default DefaultTemplate
