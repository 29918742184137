import DOMPurify from 'dompurify'
import styled from 'styled-components'
import { StepContainer } from 'ui/MessageService/MessageServiceProcess/steps/styled'
import { ref, getDownloadURL } from 'firebase/storage'
import { getStorageBucket } from 'state/store'
import { useState } from 'react'
import LoadingState from 'ui/components/LoadingState'
import { compose } from 'redux'
import WithUserCompanyClaims from 'ui/components/WithUserCompanyClaims'

const Content = styled.div`
	max-width: 600px;
	margin: 0 auto;
	margin-bottom: 40px;
`

const Attachments = styled.div`
	display: flex;
	flex-wrap: wrap;
`

const Attachment = styled.div`
	border: 2px dashed #666;
	padding: 8px 32px;
	border-radius: 8px;
	background-color: white;
	text-align: center;
	margin: 4px;
	cursor: pointer;
`

function Message({ messageServiceProcess }) {
	const [processingAttachmentPath, setProcessingAttachmentPath] = useState(null)

	const download = async (attachment) => {
		setProcessingAttachmentPath(attachment.filePath)
		const bucket = getStorageBucket(attachment.bucket)
		const reference = ref(bucket, attachment.filePath)
		const downloadURL = await getDownloadURL(reference)
		setProcessingAttachmentPath(null)

		// Might not work on safari but oh well
		window.open(downloadURL, '_blank')
	}

	return (
		<>
			<StepContainer>
				<Content>
					<h2 style={{ fontSize: 24, marginBottom: 16 }}>
						{messageServiceProcess.title}
					</h2>
					<div
						className="ck-editor__main"
						dangerouslySetInnerHTML={{
							__html: DOMPurify.sanitize(messageServiceProcess.body),
						}}
					></div>

					<h3 style={{ fontSize: 18 }}>Liitteet</h3>
					{messageServiceProcess.attachments?.length > 0 ? (
						<Attachments>
							{messageServiceProcess.attachments.map((a) =>
								processingAttachmentPath === a.filePath ? (
									<Attachment key={a.filename}>
										<LoadingState small />
									</Attachment>
								) : (
									<Attachment key={a.filename} onClick={() => download(a)}>
										{a.filename}
									</Attachment>
								),
							)}
						</Attachments>
					) : (
						<p>Ei liitteitä.</p>
					)}
				</Content>
			</StepContainer>
		</>
	)
}
export default compose(
	WithUserCompanyClaims(
		({ messageServiceProcess: { companyUUID } }) => companyUUID,
	),
)(Message)
