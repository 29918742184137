import { Controller, useFormContext } from 'react-hook-form'
import type { LoanCreateFormInputs } from '../types'
import { SelectField } from 'react-md'
import { useIntl } from 'react-intl'
import type { SelectOptions } from '../../types'
import LoanFormInput from './LoanFormInput'
import { createLoanBasicInfoInputs } from './loanFormBasicInfoInputs'
import { CreateFormGrid, CreateFormGridItem } from './components'

type LoanCreateBasicInfoFormProps = {
	companyOptions: SelectOptions[]
}

function LoanCreateBasicInfoForm(props: LoanCreateBasicInfoFormProps) {
	const { companyOptions } = props
	const { control } = useFormContext<LoanCreateFormInputs>()
	const intl = useIntl()

	return (
		<CreateFormGrid>
			<CreateFormGridItem $fullWidth>
				<Controller
					key="finance-company"
					name="companyUUID"
					control={control}
					render={({ field: { onChange, value } }) => (
						<SelectField
							id="company"
							placeholder={intl.formatMessage({
								defaultMessage: 'Kohde',
								description: 'Placeholder of company',
							})}
							label={intl.formatMessage({
								defaultMessage: 'Kohde',
								description: 'Label of select field for field: company',
							})}
							value={value}
							onChange={onChange}
							menuItems={companyOptions}
							position={SelectField.Positions.BELOW}
							simplifiedMenu={false}
							listHeightRestricted
							disabled
							style={{
								width: '100%',
								marginBottom: '10px',
							}}
						/>
					)}
				/>
			</CreateFormGridItem>
			{createLoanBasicInfoInputs.map((input) => {
				return <LoanFormInput key={input.key} input={input} />
			})}
		</CreateFormGrid>
	)
}

export default LoanCreateBasicInfoForm
