import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import * as loginUIActions from 'state/ui/login-ui-actions'
import WithQueryParams from './components/WithQueryParams'
import LoginDialog from './components/LoginDialog/LoginDialog'
import EmailVerificationDialog from './components/EmailVerificationDialog'

class LoginDialogContainer extends Component {
	componentWillReceiveProps = (props) => {
		// Refresh if we got a email verification
		if (
			this.props.auth &&
			this.props.auth.email &&
			!this.props.auth.emailVerified
		) {
			if (props.auth && props.auth.email && props.auth.emailVerified) {
				window.location.reload(true)
			}
		}
	}

	render() {
		const {
			queryParams: { mode },
			_hideLoginDialog,
			loginReducer,
			auth,
		} = this.props
		const { loginDialogVisible } = loginReducer

		if (auth.email && !auth.emailVerified) {
			return <EmailVerificationDialog auth={auth} onHide={_hideLoginDialog} />
		}

		const visible =
			!!loginDialogVisible || (mode === 'select' && loginDialogVisible === null)
		if (!visible) {
			return null
		}

		if (window.isNativeApp && window.ReactNativeWebView) {
			// Do not display the web login in our own app
			// But display it in the Aarre Easy app
			if (navigator.userAgent && navigator.userAgent.includes('Viilu')) {
				return null
			}
		}

		return <LoginDialog onHide={_hideLoginDialog} />
	}
}

const mapState = ({
	ui: { login: loginReducer },
	firebaseReducer: { auth },
}) => ({
	loginReducer,
	auth,
})

const mapDispatchToProps = (dispatch) => ({
	_hideLoginDialog: bindActionCreators(
		loginUIActions._hideLoginDialog,
		dispatch,
	),
})

export default compose(
	WithQueryParams,
	connect(mapState, mapDispatchToProps),
)(LoginDialogContainer)
