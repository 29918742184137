import { injectIntl } from 'react-intl'
import { bindActionCreators, compose } from 'redux'
import { push } from 'connected-react-router'
import styled from 'styled-components'
import RouteHeader from 'ui/components/RouteHeader'
import MessageService from 'ui/MessageService'
import MassSending from './MassSending'
import WithSelectedManager from 'ui/components/WithSelectedManager'
import WithQueryParams from 'ui/components/WithQueryParams'
import { connect } from 'react-redux'
import { FontIcon } from 'react-md'
import { useEffect } from 'react'
import * as appActions from 'state/appActions'

const ViewSelection = styled.div`
	display: flex;
	padding: 0 8px 8px 8px;
`

const getChipBgColor = (active, disabled) => {
	if (active) {
		return '#096ED6'
	}

	if (disabled) {
		return '#dcdcdc'
	}

	return '#aac4df'
}

const ViewChip = styled.button`
	cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
	font-family: 'Lato';
	font-size: 18px;
	padding: 8px 16px;
	border-radius: 32px;
	border: none;
	margin: 8px;
	color: #fff;
	font-weight: ${(props) => (props.active ? 'bold' : 'normal')};
	background-color: ${(props) => getChipBgColor(props.active, props.disabled)};
	box-shadow: ${(props) =>
		props.active ? '2px 2px 6px rgba(0, 0, 0, 0.33)' : 'none'};
`

function ManagerMessageService({
	intl,
	selectedManager,
	queryParams,
	_push,
	_setSelectedCompany,
}) {
	const view = queryParams.view || 'default'

	useEffect(() => {
		if (selectedManager) {
			_setSelectedCompany({ uuid: selectedManager.uuid })
		}
	}, [selectedManager, _setSelectedCompany])

	const setView = (view) => {
		const URLParams = new URLSearchParams(window.location.search)
		URLParams.set('view', view)

		_push('?' + URLParams.toString())
	}

	const renderView = () => {
		switch (view) {
			case 'default':
				return <MessageService hideRouteHeader={true} />
			case 'mass':
				return <MassSending />
			default:
				return <p style={{ margin: 16 }}>Unknown view.</p>
		}
	}

	const isMassSendingVisible =
		selectedManager?.featuresHiddenManager?.messageServiceMass === false

	const isMassSendingEnabled =
		selectedManager?.featuresManager?.messageServiceMass === true

	return (
		<>
			<RouteHeader
				header={intl.formatMessage({
					defaultMessage: 'Viestintäpalvelu',
					description: 'Header of the manager message service.',
				})}
			/>

			{isMassSendingVisible ? (
				<ViewSelection>
					<ViewChip
						active={view === 'default'}
						onClick={() => {
							setView('default')
						}}
					>
						{intl.formatMessage({
							defaultMessage: 'Lähetykset',
							description:
								'Label for the button that changes the manager message service view to default sending.',
						})}
					</ViewChip>
					<ViewChip
						disabled={!isMassSendingEnabled}
						active={view === 'mass'}
						onClick={() => {
							if (isMassSendingEnabled) {
								setView('mass')
							}
						}}
					>
						{intl.formatMessage({
							defaultMessage: 'Massalähetykset',
							description:
								'Label for the button that changes the manager message service view to mass sending.',
						})}
					</ViewChip>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'center',
							alignItems: 'center',
							marginLeft: 16,
							marginRight: 16,
						}}
					>
						<FontIcon style={{ marginRight: 8 }}>info</FontIcon>
						<p>
							{view === 'default'
								? 'Lähetykset-osion kautta voit tehdä kohteisiin liittymättömiä lähetyksiä.'
								: 'Massalähetykset-osion kautta voit tehdä lähetyksiä valitsemiisi kohteisiin.'}
						</p>
					</div>
				</ViewSelection>
			) : null}

			{renderView()}
		</>
	)
}

const mapDispatchToProps = (dispatch) => ({
	_push: bindActionCreators(push, dispatch),
	_setSelectedCompany: bindActionCreators(
		appActions._setSelectedCompany,
		dispatch,
	),
})

export default compose(
	injectIntl,
	WithQueryParams,
	WithSelectedManager({ hideLoading: false }),
	connect(null, mapDispatchToProps),
)(ManagerMessageService)
