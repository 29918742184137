import styled from 'styled-components'
import type { Contract } from '../Contracts/types'
import { useIntl } from 'react-intl'
import { useEffect, useState } from 'react'
import InvoiceCreateSinglePaymentProductsTable from './InvoiceCreateSinglePaymentProductsTable'
import type { CreateInvoice, InvoicePaymentProduct } from './types'
import type { PaymentProduct } from '../PaymentProducts/types'
import { Button, CircularProgress } from 'react-md'
import {
	GENERIC_ERROR_NOTIFICATION,
	PREVIOUS_BUTTON_LABEL,
	SAVE_BUTTON_LABEL,
} from 'ui/messages'
import { _createInvoice } from 'state/finance-invoice-actions'
import { useDispatch } from 'react-redux'
import { success, error } from 'state/notifyActions'
import { ApartmentSelectOptions, VatSelectOptions } from '../types'
import InvoiceDetailsTable from './InvoiceDetailsTable'
import { push } from 'connected-react-router'
import ViiluFullPageDialog, { Action } from 'ui/components/ViiluFullPageDialog'

const Content = styled.div`
	flex: 1;
	display: flex;
	overflow: auto;
`

const Left = styled.div`
	padding: 16px;
	border-right: 1px solid rgba(0, 0, 0, 0.2);
`

const Right = styled.div`
	padding: 16px;
	flex: 1;
`

const Heading = styled.h2`
	margin: 0;
`

type Props = {
	onHide: () => void
	onBack: () => void
	refreshInvoices: () => void
	contract: Contract
	paymentProducts: PaymentProduct[]
	apartmentOptions: ApartmentSelectOptions[]
	vatOptions: VatSelectOptions[]
}

const InvoiceCreateSingleDialog = ({
	onHide,
	onBack,
	refreshInvoices,
	contract,
	paymentProducts,
	apartmentOptions,
	vatOptions,
}: Props) => {
	const intl = useIntl()
	const dispatch = useDispatch()
	const [invoicePaymentProducts, setInvoicePaymentProducts] = useState<
		Map<string, InvoicePaymentProduct>
	>(new Map())
	const [processing, setProcessing] = useState(false)

	const [contractState, setContract] = useState<
		{ dueDate?: string; invoiceDate?: string } & Contract
	>(contract)

	useEffect(() => {
		const separateInvoicingPaymentProducts = new Map<
			string,
			InvoicePaymentProduct
		>()

		contract.contractPaymentProductLinks?.forEach((link) => {
			const paymentProduct = paymentProducts.find(
				(pp) => pp.uuid === link.paymentProductUUID,
			)
			if (paymentProduct?.separateBilling) {
				const vatPercentage =
					vatOptions.find((v) => v.value === paymentProduct.vatUUID)
						?.percentage || 0
				separateInvoicingPaymentProducts.set(paymentProduct.uuid, {
					price: 0,
					priceWithVat: 0,
					quantity: 0,
					total: 0,
					totalWithVat: 0,
					currency: 'EUR',
					invoiceUUID: '',
					paymentProductUUID: paymentProduct.uuid,
					vatPercentage,
					...paymentProduct,
				})
			}
		})

		setInvoicePaymentProducts(separateInvoicingPaymentProducts)
	}, [contract, paymentProducts, vatOptions])

	const createInvoice = async () => {
		if (!contractState.dueDate || !contractState.invoiceDate) {
			//TODO: ALERT
		} else {
			const paymentProducts = Array.from(invoicePaymentProducts.values()).map(
				(product) => {
					const updatedProduct = { ...product }

					updatedProduct.total = updatedProduct.price * updatedProduct.quantity
					updatedProduct.totalWithVat =
						updatedProduct.priceWithVat * updatedProduct.quantity

					return updatedProduct
				},
			)

			const formData: CreateInvoice = {
				contractUUID: contractState.uuid,
				dueDate: contractState.dueDate,
				invoiceDate: contractState.invoiceDate,
				paymentProducts: paymentProducts,
			}
			setProcessing(true)
			const result: any = await _createInvoice(formData)
			setProcessing(false)
			if (!result || result.ok === false) {
				dispatch(error(GENERIC_ERROR_NOTIFICATION(intl)))
				return false
			}

			dispatch(
				success(
					intl.formatMessage({
						defaultMessage: 'Lasku on tallennettu.',
						description: 'Notification text of invoice has been saved',
					}),
				),
			)

			refreshInvoices()
			const body = await result.json()
			if (body && body.success) {
				const uuid = body.invoice[0].uuid
				let url = '/manager/finance/invoices?uuid=' + uuid
				onHide()
				dispatch(push(url))
				return true
			}
		}
	}
	const renderContent = () => {
		if (processing) {
			return (
				<CircularProgress
					id="invoice-create-single-dialog-progress"
					style={{ marginTop: 64 }}
				/>
			)
		}

		return (
			<Content>
				<Left>
					<InvoiceDetailsTable
						entity={contractState}
						apartmentOptions={apartmentOptions}
						setContract={setContract}
					/>
				</Left>

				<Right>
					<Heading>
						{intl.formatMessage({
							defaultMessage: 'Laskurivit',
							description: 'Heading for invoice payment products.',
						})}
					</Heading>
					<InvoiceCreateSinglePaymentProductsTable
						selectedPaymentProducts={invoicePaymentProducts}
						setPaymentProducts={setInvoicePaymentProducts}
						paymentProducts={paymentProducts}
						vatOptions={vatOptions}
					/>
				</Right>
			</Content>
		)
	}

	const title = intl.formatMessage({
		defaultMessage: 'Luo yksittäislasku',
		description: 'Title for the finance single invoice create dialog.',
	})

	const actions: Action[] = [
		{
			id: 'dialog-previous',
			children: PREVIOUS_BUTTON_LABEL(intl),
			onClick: onBack,
		},
		{
			id: 'dialog-save',
			secondary: true,
			children: SAVE_BUTTON_LABEL(intl),
			onClick: createInvoice,
		},
	]

	return (
		<ViiluFullPageDialog
			id="invoice-create-single-dialog"
			title={title}
			visible
			onHide={onHide}
			focusOnMount={false}
			actions={actions}
			toolbarStyle={{
				background: 'var(--color-secondary-dark)',
			}}
			nav={
				<Button
					icon
					onClick={() => {
						onHide()
					}}
				>
					close
				</Button>
			}
		>
			{renderContent()}
		</ViiluFullPageDialog>
	)
}

export default InvoiceCreateSingleDialog
