import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import ReactGA from 'react-ga4'

function RouterLink({
	href,
	children,
	style,
	className,
	afterOnClick,
	analytics,
	_push,
}) {
	const handleOnClick = (e) => {
		if (analytics) {
			ReactGA.event(analytics)
		}

		if (
			!href ||
			href.startsWith('http') ||
			// Meta, ctrl and mouse middle button click opens the link in a new tab
			e.metaKey ||
			e.ctrlKey ||
			e.button === 1
		) {
			return
		}

		e.preventDefault()
		_push(href)

		if (afterOnClick) {
			afterOnClick()
		}
	}

	return (
		<a href={href} onClick={handleOnClick} style={style} className={className}>
			{children}
		</a>
	)
}

const mapDispatchToProps = (dispatch) => ({
	_push: bindActionCreators(push, dispatch),
})

export default connect(null, mapDispatchToProps)(RouterLink)
