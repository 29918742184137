import type { SelectOptions } from '../types'
import type { Contract } from '../Contracts/types'

export const createContractOptions = (
	contracts: Contract[],
): SelectOptions[] => {
	let contractOptions: SelectOptions[] = []
	if (contracts && contracts.length > 0) {
		contractOptions = contracts.map((c: Contract) => {
			return {
				label: c.apartmentIdentifier + ' \u2013 ' + c.partyName,
				value: c.referenceNumber,
			}
		})
	}
	return contractOptions
}
