import { useIntl } from 'react-intl'
import styled from 'styled-components'
import DatePickerISO from 'ui/components/DatePickerISO'
import DropdownField from 'ui/components/DropdownField'
import type { PaymentFilters } from './types'
import type { SelectOptions, ApartmentSelectOptions } from '../types'
import { YES_NO_MESSAGE } from 'ui/messages'

const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 16px;
	margin-bottom: 16px;
`

const Filter = styled.div`
	min-width: 160px;
`

type Props = {
	filters: PaymentFilters
	setFilters: React.Dispatch<React.SetStateAction<PaymentFilters>>
	apartmentOptions: ApartmentSelectOptions[]
	contractOptions: SelectOptions[]
	partyOptions: SelectOptions[]
}

function Filters({
	filters,
	setFilters,
	apartmentOptions,
	contractOptions,
	partyOptions,
}: Props) {
	const intl = useIntl()

	const yesNoOptions = [
		{ label: YES_NO_MESSAGE(intl, true), value: true },
		{ label: YES_NO_MESSAGE(intl, false), value: false },
	]

	return (
		<Container>
			<Filter>
				<DropdownField
					label={intl.formatMessage({
						defaultMessage: 'Huoneisto',
						description: 'Label for the payment apartment filter.',
					})}
					itemLabel={'label'}
					itemValue={'value'}
					options={apartmentOptions}
					hasSelectAll={true}
					nullSelectAll={true}
					value={filters.apartmentUUID}
					_onOptionSelect={(value) => {
						setFilters((prev) => ({ ...prev, apartmentUUID: value }))
					}}
				/>
			</Filter>

			<Filter>
				<DatePickerISO
					id="payments-payment-date-filter"
					label={intl.formatMessage({
						defaultMessage: 'Maksupäivä',
						description: 'Label for the payments payment date filter.',
					})}
					value={filters.paymentDate || ''}
					onChange={(isoDate: string) => {
						setFilters((prev) => ({ ...prev, paymentDate: isoDate }))
					}}
				/>
			</Filter>
			<Filter>
				<DatePickerISO
					id="payments-recorded-date-filter"
					label={intl.formatMessage({
						defaultMessage: 'Kirjauspäivä',
						description: 'Label for the payments recorded date filter.',
					})}
					value={filters.recordedDate || ''}
					onChange={(isoDate: string) => {
						setFilters((prev) => ({ ...prev, recordedDate: isoDate }))
					}}
				/>
			</Filter>

			<Filter>
				<DropdownField
					label={intl.formatMessage({
						defaultMessage: 'Sopimus',
						description: 'Label for the payments contract filter.',
					})}
					itemLabel={'label'}
					itemValue={'value'}
					options={contractOptions}
					hasSelectAll={true}
					nullSelectAll={true}
					value={filters.contractUUID}
					_onOptionSelect={(value) => {
						setFilters((prev) => ({ ...prev, contractUUID: value }))
					}}
				/>
			</Filter>
			<Filter>
				<DropdownField
					label={intl.formatMessage({
						defaultMessage: 'Maksaja',
						description: 'Label for the payments payer filter.',
					})}
					itemLabel={'label'}
					itemValue={'value'}
					options={partyOptions}
					hasSelectAll={true}
					nullSelectAll={true}
					value={filters.partyUUID}
					_onOptionSelect={(value) => {
						setFilters((prev) => ({ ...prev, partyUUID: value }))
					}}
				/>
			</Filter>
			<Filter>
				<DropdownField
					label={intl.formatMessage({
						defaultMessage: 'Kohdistettu',
						description: 'Label for the payments matched filter.',
					})}
					itemLabel={'label'}
					itemValue={'value'}
					options={yesNoOptions}
					hasSelectAll={true}
					nullSelectAll={true}
					value={filters.matched}
					_onOptionSelect={(value) => {
						setFilters((prev) => ({ ...prev, matched: value }))
					}}
				/>
			</Filter>
			<Filter>
				<DropdownField
					label={intl.formatMessage({
						defaultMessage: 'Viitenumero',
						description: 'Label for the payments referenceNumber filter.',
					})}
					itemLabel={'label'}
					itemValue={'value'}
					options={yesNoOptions}
					hasSelectAll={true}
					nullSelectAll={true}
					value={filters.referenceNumber}
					_onOptionSelect={(value) => {
						setFilters((prev) => ({ ...prev, referenceNumber: value }))
					}}
				/>
			</Filter>
			<Filter>
				<DropdownField
					label={intl.formatMessage({
						defaultMessage: 'Viesti',
						description: 'Label for the payments message filter.',
					})}
					itemLabel={'label'}
					itemValue={'value'}
					options={yesNoOptions}
					hasSelectAll={true}
					nullSelectAll={true}
					value={filters.message}
					_onOptionSelect={(value) => {
						setFilters((prev) => ({ ...prev, message: value }))
					}}
				/>
			</Filter>
		</Container>
	)
}

export default Filters
