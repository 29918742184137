import { IntlShape } from 'react-intl'
import type { Invoice, InvoiceKey } from './types'
import type { ApartmentSelectOptions } from '../types'
import {
	formatCurrency,
	formatDate,
	formatInvoiceNumber,
} from 'util/formatStrings'
import { invoiceStatusMessage, invoicingMethodMessage } from '../utils/messages'

export const getInvoiceRowValue = (
	invoice: Invoice,
	key: InvoiceKey,
	apartmentOptions: ApartmentSelectOptions[],
	intl: IntlShape,
	type?: string,
) => {
	const value = invoice[key]

	switch (type) {
		case 'apartmentOptions':
			return (
				apartmentOptions.find((a) => a.identifier === value)?.label || value
			)
		case 'formatInvoiceNumber':
			return formatInvoiceNumber(invoice)
		case 'date':
			return formatDate(value)
		case 'currency':
			return formatCurrency(value)
		case 'invoicingMethodMessage':
			return invoicingMethodMessage(intl, value)
		case 'invoiceStatusMessage':
			return invoiceStatusMessage(intl, value)
		default:
			return value || ''
	}
}

export const getPaymentsTotalStyle = (invoice: Invoice) => {
	if (!invoice.paymentsTotal) {
		return {}
	}
	if (invoice.paymentsTotal < invoice.total) {
		return { color: 'red', fontWeight: 'bold' }
	}
	if (invoice.paymentsTotal > invoice.total) {
		return { color: 'green', fontWeight: 'bold' }
	}
	return {}
}
