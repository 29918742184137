import config from '../config'

const initialState = {
	width: window.innerWidth,
	height: window.innerHeight,
	isDesktop: window.innerWidth > config.ui.DESKTOP_MIN_WIDTH,
}

function reducer(state = initialState, action) {
	if (action.type === 'DIMENSIONS_UPDATE') {
		return {
			...state,
			width: action.width,
			height: action.height,
			isDesktop: action.width > config.ui.DESKTOP_MIN_WIDTH,
		}
	}
	return state
}

export default reducer
